export default {
  Dashboard: "Panel",
  MyBalances: "Bakiyeler",
  MyWallets: "Cüzdanlar",
  MyWallet: "Cüzdan",
  CryptoWallet: "Kripto Cüzdanı",
  FiatWallet: "Fiat Cüzdanı",
  Transactions: "İşlemler",
  TransferHistory: "Transfer Geçmişi",
  ExchangeHistory: "Döviz Geçmişi",
  Deposit: "Yatırımlar",
  Withdrawals: "Çekimler",
  IDOCyborCoin: "İDO PayCrypt",
  CyborProtectionSystem: "PayCrypt Koruma Sistemi",
  MarketRates: "Piyasa Oranlar",
  PersonalSettings: "Kişisel ayarlar",
  Reports: "Raporlar",
  PaymentReport: "Ödeme Raporu",
  Business: "İşletme",
  BranchManagement: "Şube yönetimi",
  PaymentHistory: "Ödeme geçmişi",
  AdminIDOPanel: "IDO Admin Paneli",
  IDODashboard: "IDO Paneli",
  LockedCyborCoin: "Kilitli PayCrypt Coin",
  AddBankRequest: "Banka Talebi Ekle",
  FiatWithdrawRequest: "Fiat Para Çekim Talebi",
  CompletedTransaction: "Tamamlanan İşlemler",
  PendingTransaction: "Bekleyen işlemler",
  CancelledTransaction: "İptal Edilen İşlemler",
  Oursupportworks: "Desteğimiz sizin için çalışıyor",
  DefiLoans: "Kripto Kredisi",
  BankLoan: "Banka borcu",
  Cryptocards: "Kripto Kartları",
  Buysellcrypto: " Kripto Al/Sat",
  Loanrequests: "Kredi Talepleri",
  Borrow: "Borç almak",
};
