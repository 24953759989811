export default {
  Symbol: "Symbol",
  Price: "Price",
  loading: "Loading",
  Balances: "Balances",
  My: "My",
  ResendOTP: "Resend OTP",
  SignUp: "Sign Up",
  SignIn: "Sign In",
  phone: "Phone",
  emailid: "Email ID",
  enteremailid: "Please enter Email ID",
  password: "Password",
  passwordnotempty: "Password should not be empty",
  rntrpass: "Re-enter Password",
  verify: "Verify",
  FilterPeriode: "Filter Periode",
  gotosignin: "Go to signin",
  create: "Create",
  Darkmode: "Dark Mode",
  Lightmode: "Light Mode",
  downloadforchrome:
    "Download PayCrypt Security protection system for Google Chrome browser",
  security: "PayCrypt Security",
  universal:
    "A universal system for the protection of personal data and crypto assets available to everyone",

  ///////////common er msgs entire platform////////////////
  EMAIL_EMPTY: "Email Empty",
  MOBILE_EMPTY: "Mobile Used",
  PASSWORD_EMPTY: "Password Empty",
  USER_NOT_FOUND: "User Not Found",
  VERI_LINK_EXPIRED: "Verification link expired",
  INVALID_VERI_DETAILS: "Invalid varification details",
  "email should not be empty,email must be an email":
    "Enter a valid email address",

  //payment
  CURRENCY_NOT_SUPPORTED: "Currency not supported",
  COIN_NOT_SUPPORTED: "Coin not supported",
  ACCOUNT_NOT_FOUND: "Account not found",
  WALLET_NOT_FOUND: "Wallet not found",
  UNAUTHRIZED_ACCOUNT: "Unauthorized account",
  LINK_USED: "Link used",
  PAYMENT_LINK_TOKEN_NOT_FOUND: "Payment Link not found",
  LINK_USED_OR_NOT_FOUND: "Link used or not found",
  COMMISSION_NOT_FOUND: "Commision not found",
  EXCHANGE_NOT_FOUND: "Exchange not found",
  BALANCEE_TOO_LOW: "Balance too low",
  TIME_EXCEED: "Time exceed",
  PaymentSuccessfully: "Payment Successfull",

  /////IDO/////////
  TOKENS_NOT_AVAILABLE: "Tokens not available",
  IDO_ROUND_NOT_FOUND: "IDO round not found",
  ADDRESS_DOESNOT_BELONG_TO_CYBORPAY_USER:
    "Address does not belong to PayCrypt user",
  EXCHANGE_RATE_NOT_MATCHING: "Exchange rate not matching",
  IDO_TRANSACTION_NOT_FOUND: "IDO transaction not found",
  TOKENS_ALREADY_CLAIMED: "Tokens alredy claimed",
  LOCKING_PERIOD_IS_NOT_OVER: "Locking period is not over",
  INSUFFICIENT_BALANCE: "Insufficient balance",
  TOKENS_NOT_AVAILABLE: "Tokens not available",
  AMOUNT_TO_EXCHANGE_CANNOT_BE_IN_NEGETIVE:
    "Amount to exchange cannot be in negative",
  TOKEN_ADDED: "Token added",
  TOKEN_TRANSFERRED: "Token transferred",

  //////Fiat/////
  BANK_ACCOUNT_NOT_FOUND: "Bank not found",
  YOUR_ACCOUNT_IS_NOT_ACTIVATED: "Your account is not active",
  FIAT_CURRENCY_NOT_FOUND: "Fiat currency not found",
  ACCOUNT_IS_NOT_ACTIVE: "Account is not active",
  LOW_BALANCE: "Low balance",
  USERS_FIAT_DEPOSIT_REQUEST_NOT_FOUND: "Users fiat deposit request not found",
  AMOUNT_ALREADY_DEPOSITED: "Amount already deposited",
  USER_CURRENCY_NOT_FOUND: "User currency not found",
  BUSINESS_REQUEST_NOT_FOUND: "Business request not found",
  "BUSINESS_USER_WITH_ID_${Id}_NOT_FOUND": "Business user with ID not found",
  INVALID_MERCHANT_ADDRESS: "Invalid merchant address",

  AMOUNT_CANNOT_BE_IN_NEGETIVE: "Amount cannot be in negative",

  ////////////withdraw///////////////
  BALANCEE_TOO_LOW: "Balance too low",

  PLATFORM_ERROR:
    "Platform error occurred due to high amount. Please connect to support team",
  ///////////common er msgs entire platform-----------ends-----------////////////////

  FAILED_WITHDRAWAL_AUTHENTICATOR_VERIFICATION: "Invalid authenticator code",
  Security_verification: "Security verification",
  borrowbankloan: "Borrow Bank Loan",
};
