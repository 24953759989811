export default {
  NO: "Numara",
  AmountBuy: "Alış miktarı",
  Date: "Tarih",
  Rate: "Oran",
  Cost: "Maliyet",
  Round: "Tur",
  Bonus: "Bonus",
  UnlockedDate: "Kilit açma tarihi",
  Action: "Eylem",
  Claim: "Talep et",
  Tokenomics: "Tokenomik",
  Protocol: "Protokol",
  BinanceSmartChain: "Binance Akıllı Zincir",
  Token: "Token",
  Tracker: "İzci",
  TotalAmount: "Toplam tutar",
  Billion: "milyar ",
  TypeOfEmission: "emisyon türü",
  LimitedControlled: "sınırlı kontrollü",
  Download: "İndir",
  Whitepaper: "White paper",
  DownloadTokenomics: "Tokenomikleri İndir",
  DistributionOfCYBOR: "PayCrypt tokenlerinin ilk yıldaki dağılımı",
  TotalNoCoinAvlb: "İlk yılda dolaşımda olan mevcut coinlerin toplam sayısı",
  Percent: "Yüzdelik",
  NumberOfTokens: "Token sayısı",
  CostOf1Token: "1 tokenin tutarı",
  TotalCostOfTokens: "Tokenlerin toplam tutarı",
  BlockingPeriod: "Kilit süresi",
  "Investors closed round No 1": "Yatırımcılar kapalı tur sayısı 1",
  InvestorsClosedRound: "Yatırımcılar kapalı tur sayısı",
  Months: "ay",
  Days: "gün",
  PlacementOnExchange: "Borsaya yerleştirme",
  MiningPool: "Maden havuzu",
  Developers: "Geliştiriciler",
  Marketing: "Pazarlama",
  Partners: "Ortaklar",
  CYBORCOIN: "PayCrypt COIN",
  TR: "Toplam Tur Sayısı",
  TT: "Toplam İşlemler",
  TTS: "Gönderilen Toplam Token Sayısı",
  List: "Liste",
  EnterTokenLimit: "Token limitini girin",
  EnterExchangeRate: "Döviz kurunu girin",
  EnterLockingPeriodInDays: "Gün olarak kilitleme süresini girin",
  CreateNewRound: "Yeni bir Tur oluştur",
  CreatingRoundMsg: "Yeni tur oluşturuluyor. Lütfen bekleyin!",
  ROUNDNO: "TUR NO",
  EXCHANGERATE: "DÖVİZ KURU",
  LOCKINGPERIOD: "KİLİTLEME SÜRESİ",
  TOKENLIMIT: "TOKEN LİMİTİ",
  TOKENDISTRIBUTED: "DAĞITILAN TOKENLER",
  TOKENCLAIMED: "TALEP EDİLMİŞ TOKENLER",
  OpenWhitepapers: "White paper'i Aç",
  OpenTokenomics: "Tokenomikleri Aç",
  DownloadWhitepapers: "White paper'i İndir",
  DownloadTokenonmics: "Tokenomikleri İndir",
  TotalBlockedTokens: "Toplam kilitlenen tokenler",
  TokenUnlockingPeriod: "Token kilidinin açma süresi",
  years: "yıl",
  FirstYear: "İlk yıl",
  SecondYear: "İkinci yıl",
  ThirdYear: "Üçüncü yıl",
  FourthYear: "Dördüncü yıl",
  FifthYear: "Beşinci yıl",
  SixthYear: "Altıncı Yıl",
  QualityTokens: "Tokenlerin miktarı",
  Priceofonetoken: "Bir tokenin maliyeti",
  Totalcosttokens: "Tokenlerin toplam maliyeti",
  Blockingperiod: "Kilitleme süresi",
  DistributionForSixYears: "Altı yıl boyunca PayCrypt tokenlerinin dağıtımı",
  Year: "Yıl",
  Users: "Kullanıcılar",
  Marketing: "Pazarlama",
  Partners: "Ortaklar",
  tokensissuedsixyears: "6 yılda verilen toplam kullanılabilir token sayısı",
  TokenCybor: "PayCrypt Token",
  SaleoftokensCYBOR: "PayCrypt tokenlerin satışı",
  Saleoftokens: "token satışı",
  Blockingperiod: "Kilitleme süresi",
  ClosedroundNo1: "Kapalı tur No1",
  ClosedroundNo2: "Kapalı tur No2",
  ClosedroundNo3: "Kapalı tur No3",
  costof1tokenonthedateoflisting: "Listeleme tarihinde 1 tokenin maliyeti",
  Termofblockingonthestockexchange: "Borsada Kilitleme süresi",
  minimumamounttopurchasetokens:
    "1 hesaptan token satın almak için minimum miktar",
  maximumamountofpurchasetokens:
    "1 hesaptan token satın almak için maximum miktar",
  notlimited: "sınırsız",
  Bonusrewardforparticipation: "Kapalı IDO turlarına katılım için bonus ödülü",
  Bonusrewardfortokenblocking: "Token kilitleme için bonus ödülü",
  byparticipatinginround: "tura katılarak",
  Totalpercentageofremunerationfor6months:
    "İlk tura katılırken 6 aylık toplam ücret yüzdesi (6 ay boyunca PayCrypt tokenlerin kilitlenmesi)",
  "Investors closed round #1": "Yatırımcılar kapalı turu #1.",
  "Investors closed round #2": "Yatırımcılar kapalı turu #2.",
  "Investors closed round #3": "Yatırımcılar kapalı turu #3.",
  "Market Placement": "Pazar Yeri",
  "Mining pool	": "Maden Havuzu",
  "Mining pool": "Maden Havuzu",
  "6 months (180 days)": "6 ay (180 gün)",
  "3 months (90 days)": "3 ay (90 gün)",
  "First Year": "İlk yıl",
  "Second Year": "İkinci yıl",
  "Third Year": "Üçüncü yıl",
  "Fourth Year": "Dördüncü yıl",
  "Fifth Year": "Beşinci sene",
  "Sixth Year": "Altıncı Yıl",
  "Number of tokens issued / total coin supply": "Verilen token sayısı / toplam token arzı",
  " Number of blocked PayCrypt tokens": "Kilitlenen PayCrypt tokenlerin sayısı",
  "Term of blocking PayCrypt tokens": "PayCrypt tokenlerin kilitleme süresi",
  "6 Years": "6 yıl",
  Potocol: "Protokol",
  "Name Token": "Token Adı",
  Tracker: "İzci",
  "Total Amount": "Toplam tutar",
  "Types of emision": "Emisyon türleri",
  "limied controlled": "Sınırlı kontrollü",
  Privatesalestage2: "Özel satış aşaması 2",
  Claimed: "hak talebinde bulunuldu",
  IDO: "IDO",
  "Seed Round": "tohum yuvarlak",
  "Private sale 1": "Özel satış 1",
  "Private sale 2": "Özel satış 2",
  "Public sale": "Halk indirimi",
  "Liquidity": "Likidite",
  "Team": "Takım",
  "Partners and Advisors": "Ortaklar ve Danışmanlar",
};
