import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import InputField from "@components/input-field/InputField";

import CustomButton from "@components/custom-button/CustomButton";
import Loader from "@components/loader/Loader";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import "@components/issue-card-popup/IssueCardPopupStyle.scss";

const IssueCardPopup = ({
  show,
  setShowModal,
  setShowSuccess,
  createCardData,
  createNftCardLoading,
  skip,
  limit
}) => {
  const [name, setName] = useState("");
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation();

  const onSendmsg = (event) => {
    event.preventDefault();
    console.log("submit");
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      setName("");
      let data = { cardName: name, openSuccess: () => setShowSuccess(true), skip , limit  };
      createCardData(data);
      setShowModal(false);
    }
  };

  useEffect(() => {
    setValidated(false);
  }, [name]);

  const onClose = () => {
    setShowModal(false);
    setName("");
  };

  return (
    <>
    {createNftCardLoading && <Loader/>}
      <Modal
        className="IssueCardPopup"
        show={show}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          onClose();
        }}
        centered
      >
        <Form noValidate validated={validated} onSubmit={onSendmsg}>
          <Modal.Header>
            <Modal.Title>
              <span className="exchangetitle">{t("Issue Card")}</span>
            </Modal.Title>
            <div className="closeicon">
              <CloseIcon
                className="closebtn"
                onClick={() => {
                  onClose();
                }}
              />
            </div>

            <div
              className="back"
              onClick={() => {
                onClose();
              }}
            >
              <ExpandIcon className="closeicon1" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg={12} className="colspace">
                  <div className="titlepass">
                    <span className="titleText">{t("Name")}</span>
                  </div>

                  <div className="inputboxpass">
                    <InputField
                      type="text"
                      value={name}
                      required
                      error={"Name cannot be empty"}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </Col>

                <Col lg={6} sm={6} xs={6}>
                  <div className="confirm">
                    <CustomButton
                      type="submit"
                      name={t("Confirm")}
                      filled={true}
                      primary={true}
                    />
                  </div>
                </Col>
                <Col lg={6} sm={6} xs={6}>
                  <div className="cancel">
                    <CustomButton
                      name={t("Cancel")}
                      filled={true}
                      primary={true}
                      onClick={(e) => {
                        onClose();
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const createNftCardLoading = _.get(user, "createNfcCardLoading", false);
  const createNftCard = _.get(user, "createNfcCard", undefined);
  const createNftCardError = _.get(user, "createNfcCardFailure", undefined);
  return {
    createNftCardLoading,
    createNftCard,
    createNftCardError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCardData: (data) =>
    dispatch({ type: "CreateCardCalled", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(IssueCardPopup));
