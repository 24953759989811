import React from "react";
import SearchBox from "@components/search-box/SearchBox";
import CoinListItem from "src/components/coin-list-item/CoinListItem";
import "@components/coin-list-layout/CoinListLayoutStyles.scss";
function CoinListLayout({
  getplatformTokensinfo,
  getBal,
  value,
  setvalue,
  setcoinItem,
}) {
   return (
    <div className="coinlistlayout">
      <div className="topspacese">
        <SearchBox className="search-align-for-deposit" value={value} setvalues={setvalue} />
      </div>
      <div className="coinlistscrollable">
      {getplatformTokensinfo &&
        getplatformTokensinfo
          .filter((item) => item.tokenName.toLowerCase().includes(value.toLowerCase()))
          .map((items, index) => (
            <div
              className="topspace"
              key={`coinlist${index}`}
              onClick={() => setcoinItem(items)}
            >
              <CoinListItem
              type={items.type}
                balance={getBal(items.id)}
                symbol={items.exchangeSymbol}
                logo={items.colouredLogo}
                name={items.tokenName}
                logoBgColor={items.logoBgColor}
              />
            </div>
          ))}
          </div>
    </div>
  );
}
export default CoinListLayout;
