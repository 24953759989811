import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import "@features/deposit-withdraw/DepositWithdrawStyles.scss";
import Table from "react-bootstrap/esm/Table";
import BigNumber from "bignumber.js";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import deposit from "@images/deposit.svg";
import withdraw from "@images/withdraw.svg";
import pending from "@images/pending.svg";
import { ReactComponent as SquareIcon } from "@images/tcheck.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import Filter from "@components/filter/Filter.js";
import CoinList from "@components/coin-list/CoinList";
import ScreenLayout from "src/components/layout/ScreenLayout";
import { Row, Col, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DetailedPopup from "@components/detailed-popup/DetailedPopup";
import FooterCommon from "@components/footer-common/FooterCommon";
import CustomButton from "src/components/custom-button/CustomButton";
import { ReactComponent as PrinterIcon } from "@images/printer.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportSheet } from "react-xlsx-sheet";
import Loader from "src/components/loader/Loader";
import { ClearSansBase64 } from "src/custom-fonts-base64/index";

function DepositWithdraw(props) {
  const {
    getDepwithHistory,
    depositWithHistory,
    depositWithhistoryCount,
    getDepwithHistoryCount,
    myusertransactionloading,
    getFiatDepwithHistory,
    getFiatDepwithHistoryCount,
    fiatTransaction,
    FiatTransactionCount,
    fiatTransactionLoading,
    thememode,
    getAllDepwithHistory,
    getAllDepwithHistoryCount,
    AllTransactionCount,
    AllTransaction,
    hideLoaderOnRefresh,
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("first");
  let [show, setshow] = useState(false);
  let [Idata, setIdata] = useState({});
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [isFilterSet, setIsFilterSet] = useState(false);
  const [transferType, setTransferType] = useState("Crypto");
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [dataListCount, setdataListCount] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [language, setLanguage] = useState("en");
  const [hideSpec, setHideSpec] = useState(true);
  const [compRef, setCompRef] = useState();
  const componentRef = useRef();
  useEffect(() => {
    setCompRef(componentRef);
    settableno("first")
  }, []);
  useEffect(() => {
    if (hideSpec === false) {
      setCompRef(componentRef);
      handlePrint();
      setHideSpec(true);
    }
  }, [hideSpec]);

  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });

  useEffect(() => {
    getDepwithHistory({ skip: pages, limit, currencyType: "Crypto" });
    getDepwithHistoryCount({ currencyType: "Crypto" });
    // filterDepositInfoForExcelExport();
  }, []);
  useEffect(() => {
    setLanguage(localStorage.lang);
    filterDepositInfoForExcelExport();
  }, [localStorage.lang]);

  const { t } = useTranslation();
  // useEffect(() => {
  //   if (tableno == "first") {
  //     setdataListCount(depositWithhistoryCount);
  //   }
  //   if (tableno == "third") {
  //     setdataListCount(AllTransactionCount);
  //   }
  //   if (tableno == "second") {
  //     setdataListCount(FiatTransactionCount);
  //   }
  // }, [depositWithhistoryCount, FiatTransactionCount, AllTransactionCount]);
  useEffect(() => {
    setdataListCount(depositWithhistoryCount);
    filterDepositInfoForExcelExport();
  }, [depositWithHistory, depositWithhistoryCount]);

  // useEffect(() => {
  //   if (tableno == "first") {
  //     setdataList(depositWithHistory);
  //   }
  //   if (tableno == "third") {
  //     setdataList(AllTransaction);
  //   }

  //   if (tableno == "second") {
  //     setdataList(fiatTransaction);
  //   }
  // }, [tableno, depositWithHistory, fiatTransaction, AllTransaction]);

  useEffect(() => {
    setdataList(depositWithHistory);
  }, [depositWithHistory]);

  const filterDepositInfoForExcelExport = () => {
    let filtered = [];
    let headerList = [];
    if (dataList && dataList.length !== 0) {
      for (let i of dataList) {
        let newOBJ = {
          TransactionID: i.id,
          Date:
            i.createdOn !== undefined
              ? moment(i.createdOn).local().format("MM/DD/YYYY hh:mm A")
              : "",

          From: i.from !== undefined ? i.from : "",
          To: i.to !== undefined ? i.to : "",
          Coin: i.tOrCName !== undefined ? i.tOrCName : "",

          Amount: i.amount !== undefined ? i.amount : "",
          Status: t(i.status),
        };

        filtered.push(newOBJ);
      }

      for (let i of Object.keys(filtered[0])) {
        let newOBJ = {
          title: t(i),
          dataIndex: i,
        };
        headerList.push(newOBJ);
      }

      let pack = {
        data: filtered,
        head: headerList,
      };

      setExcelHeader(pack.head);
      setExcelData(pack.data);

      return pack;
    }
    return filtered;
  };

  // const generatePdf = () => {
  //   var doc = new jsPDF();

  //   doc.addFileToVFS("ClearSans-Regular.ttf", ClearSansBase64);
  //   doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
  //   doc.setFont("ClearSans", "Regular");
  //   var col = [
  //     `${t("TransactionID")}`,
  //     `${t("Date")}`,
  //     `${t("From")}`,
  //     `${t("To")}`,
  //     `${t("Coin")}`,
  //     `${t("Amount")}`,
  //     `${t("Status")}`,
  //   ];

  //   var rows = [];

  //   if (dataList && dataList.length !== 0) {
  //     dataList.forEach((element) => {
  //       var temp = [
  //         element.id,
  //         moment(element.createdOn).local().format("MM/DD/YYYY hh:mm A"),
  //         // moment(element.createdOn).local().format("MM/DD/YYYY hh:mm A"),
  //         element.from,
  //         element.to,
  //         element.tOrCName,

  //         BigNumber(element.amount).toFixed(6),
  //         t(element.status),
  //       ];

  //       rows.push(temp);
  //     });
  //   }
  //   // doc.autoTable(col, rows, {
  //   //   margin: { right: 0, left: 0 },
  //   //   tableWidth: "auto",
  //   //   // overflow: "visible",
  //   //   //  styles: {overflow: 'ellipsize'},
  //   //   headerStyles: { textColor: 255, halign: "center" },
  //   // });
  //   doc.autoTable(col, rows, {
  //     startY: 5,
  //     // orientation: "landscape",
  //     // headerStyles: { textColor: 255, halign: "center" },
  //     styles: {
  //       overflow: "ellipsize",
  //       font: "ClearSans",
  //       fontSize: 8,
  //     },
  //   });
  //   doc.save(`${t("Withdrawals")}.pdf`);
  // };
  const generatePdf = (data) => {
    var doc = new jsPDF();

    doc.addFileToVFS("ClearSans-Regular.ttf", ClearSansBase64);
    doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
    doc.setFont("ClearSans", "Regular");
    var col = [
      `${t("TransactionID")}`,
      `${t("Date")}`,
      `${t("From")}`,
      `${t("To")}`,
      `${t("Coin")}`,
      `${t("Amount")}`,
      `${t("Status")}`,
      `${t("TType")}`,
    ];

    var rows = [];

    if (data && data.length !== 0) {
      data.forEach((element) => {
        var temp = [
          element.id,
          moment(element.createdOn).local().format("MM/DD/YYYY hh:mm A"),
          element.from,
          element.to,
          element.tOrCName,

          Number(
            element && element.coin && element.coin.decimals
              ? element.amount / 10 ** element.coin.decimals
              : element.amount
          ).toFixed(5),
          t(element.status),
          t(element.transactionType),
        ];

        rows.push(temp);
      });
    }

    doc.autoTable(col, rows, {
      startY: 5,

      styles: {
        font: "ClearSans",
        fontSize: 6,
      },
    });
    doc.save(`${t("Withdrawals")}.pdf`);
  };
  const getdownload = () => {
    generatePdf(dataList);
  };

  useEffect(() => {
    console.log("datalisttt", dataList);
  }, [dataList]);
  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      loaderOnRefresh={hideLoaderOnRefresh}
    >
      <div id="depositwithdraw">
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="trasactiontitle">
                  <span>
                    {t("Deposit")}/{t("Withdrawals")}
                  </span>
                </div>
                <div className="filtersection">
                  <Filter
                    onApplyDate={(picker) => {
                      setIsFilterSet(true);
                      getDepwithHistory({
                        skip: 0,
                        limit,
                        startDate: picker.startDate,
                        endDate: picker.endDate,
                        currencyType: transferType,
                        orderBy: orderBy,
                        sort: sortBy,
                      });
                      getDepwithHistoryCount({
                        currencyType: transferType,
                        startDate: picker.startDate,
                        endDate: picker.endDate,
                        orderBy: orderBy,
                        sort: sortBy,
                      });
                    }}
                    oncancel={() => {
                      setStartDate({});
                      setEndDate({});
                      setIsFilterSet(false);
                      getDepwithHistory({
                        skip: 0,
                        limit,
                        currencyType: transferType,
                        orderBy: orderBy,
                        sort: sortBy,
                      });
                      getDepwithHistoryCount({ currencyType: transferType });
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={(e) => setStartDate(e)}
                    setEndDate={(e) => setEndDate(e)}
                  />
                </div>
              </div>
              <div className="rightsection">
                <div className="thethreebutton">
                  <div className="buttoncontainersm">
                    <ReactToPrint
                      trigger={() => (
                        <CustomButton
                          type="submit"
                          name={t("Print")}
                          testvalue={
                            <PrinterIcon className="iconcolor mleft" />
                          }
                          text={true}
                          image={true}
                        />
                      )}
                      content={() => componentRef.current}
                      onBeforeGetContent={() => {
                        orientation: "landscape";
                        setHideSpec(false);
                      }}
                    />
                  </div>

                  <div className="buttoncontainersm">
                    <CustomButton
                      type="submit"
                      name="PDF"
                      testvalue={<PdfIcon className="mleft" />}
                      text={true}
                      image={true}
                      onClick={() => {
                        // generatePdf();
                        getdownload();
                      }}
                    />
                  </div>

                  <div className="buttoncontainersm mr-0">
                    <ExportSheet
                      header={excelHeader}
                      fileName={`download`}
                      dataSource={excelData}
                      xlsx={XLSX}
                    >
                      <CustomButton
                        type="submit"
                        name={t("Excel")}
                        testvalue={<ExcelIcon className="mleft" />}
                        text={true}
                        image={true}
                        onClick={() => {
                          filterDepositInfoForExcelExport();
                        }}
                      />
                    </ExportSheet>
                  </div>
                </div>
              </div>
              <div className="btmmargin">
                <CurrencyTab
                  page={"depositewithdraw"}
                  changeTab={(e) => {
                    setStartDate({});
                    setEndDate({});
                    setIsFilterSet(false);
                    setpages(0);
                    settableno(e);
                    if (e == "second") {
                      setTransferType("Fiat");
                      getFiatDepwithHistory({ skip: 0, limit });
                      getFiatDepwithHistoryCount({});
                      getDepwithHistory({
                        skip: 0,
                        limit,
                        currencyType: "Fiat",
                      });
                      getDepwithHistoryCount({ currencyType: "Fiat" });
                    }
                    if (e == "third") {
                      setTransferType("");
                      getAllDepwithHistory({ skip: 0, limit });
                      getAllDepwithHistoryCount({ type: "" });
                      getDepwithHistory({ skip: 0, limit, currencyType: "" });
                      getDepwithHistoryCount({ currencyType: "" });
                    }
                    if (e == "first") {
                      setTransferType("Crypto");
                      getDepwithHistory({
                        skip: 0,
                        limit,
                        currencyType: "Crypto",
                      });
                      getDepwithHistoryCount({ currencyType: "Crypto" });
                    }
                  }}
                  tableno={tableno}
                />
              </div>

              <DetailedPopup setShowModal={setshow} show1={show} data={Idata} />
              <Table
                responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless
                ref={componentRef}
              >
                {(myusertransactionloading || fiatTransactionLoading) && (
                  <Loader />
                )}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading">{/* <SquareIcon /> */}</th>
                    <th className="trtbleheading">
                      <span className="heading">{t("TransactionID")}</span>{" "}
                      <span className="sorticon">
                        <SortupIcon
                          className="uparrow"
                          onClick={() => {
                            setOrderBy("id");
                            setSortBy("ASC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "id",
                                sort: "ASC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "id",
                                sort: "ASC",
                              });
                            }
                          }}
                        />

                        <SortdownIcon
                          className="downarrow"
                          onClick={() => {
                            setOrderBy("id");
                            setSortBy("DESC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "id",
                                sort: "DESC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "id",
                                sort: "DESC",
                              });
                            }
                          }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("Date")}</span>{" "}
                      <span className="sorticon">
                        <SortupIcon
                          className="uparrow"
                          onClick={() => {
                            setOrderBy("createdOn");
                            setSortBy("ASC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "createdOn",
                                sort: "ASC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "createdOn",
                                sort: "ASC",
                              });
                            }
                          }}
                        />

                        <SortdownIcon
                          className="downarrow"
                          onClick={() => {
                            setOrderBy("createdOn");
                            setSortBy("DESC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "createdOn",
                                sort: "DESC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "createdOn",
                                sort: "DESC",
                              });
                            }
                          }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading ">
                      <span className="heading">{t("From")}</span>{" "}
                      <span className="sorticon">
                        <SortupIcon
                          className="uparrow"
                          onClick={() => {
                            setOrderBy("from");
                            setSortBy("ASC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "from",
                                sort: "ASC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "from",
                                sort: "ASC",
                              });
                            }
                          }}
                        />

                        <SortdownIcon
                          className="downarrow"
                          onClick={() => {
                            setOrderBy("from");
                            setSortBy("DESC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "from",
                                sort: "DESC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "from",
                                sort: "DESC",
                              });
                            }
                          }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading ">
                      <span className="heading">{t("To")}</span>{" "}
                      <span className="sorticon">
                        <SortupIcon
                          className="uparrow"
                          onClick={() => {
                            setOrderBy("to");
                            setSortBy("ASC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "to",
                                sort: "ASC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "to",
                                sort: "ASC",
                              });
                            }
                          }}
                        />

                        <SortdownIcon
                          className="downarrow"
                          onClick={() => {
                            setOrderBy("to");
                            setSortBy("DESC");
                            if (isFilterSet) {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                startDate: picker.startDate,
                                endDate: picker.endDate,
                                currencyType: transferType,
                                orderBy: "to",
                                sort: "DESC",
                              });
                            } else {
                              getDepwithHistory({
                                skip: pages * limit,
                                limit,
                                currencyType: transferType,
                                orderBy: "to",
                                sort: "DESC",
                              });
                            }
                          }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("Coin")}</span>
                    </th>
                    <th className="trtbleheading centeralign">
                      <span className="heading">{t("Amount")}</span>
                    </th>
                    <th className="trtbleheading  centeralign">
                      <span className="heading">{t("Status")}</span>
                    </th>
                    <th className="trtbleheading"></th>
                  </tr>
                </thead>

                {
                  <tbody>
                    {dataList &&
                      dataList.map((item, index) => (
                        <tr key={`trans${index}`}>
                          <td>
                            <div className="innerTd">
                              {item.transactionType == "deposit" ? (
                                <img src={deposit} className="imgstatus" />
                              ) : (
                                <img src={withdraw} className="imgstatus" />
                              )}
                            </div>
                          </td>
                          <td className="amt">
                            <div className="innerTd idtr">
                              <span className="id idtxt">#{item.id}</span>
                              <p className="hidden-for-desktop date">
                                {moment(item.createdOn)
                                  .local()
                                  .format("MM/DD/YYYY hh:mm A")}
                              </p>
                            </div>
                          </td>
                          <td className="amt hidden-for-mobile ">
                            <div className="innerTd id datewidth">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:m A")}
                            </div>
                          </td>
                          <td className="amt hidden-for-mobile">
                            <div className="innerTd id fromwidth ">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 1000 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {item.from !== null && item.from}
                                    {item && item.TypeCurrency == "Crypto"
                                      ? `${item.from}`
                                      : `${
                                          item.transactionType == "withdraw"
                                            ? "Cyborpay"
                                            : item.bankAccount !== null &&
                                              item.bankAccount.bankAccountNumber
                                        }`}
                                  </Tooltip>
                                )}
                              >
                                 <span>
                                  {item !== undefined  && item.TypeCurrency === "Crypto" && item.from !== null
                                    ? `${
                                        
                                        item.from !== null
                                         &&
                                        item.from?.substring(0, 5)
                                      }...
                                  ${
                                    item !== undefined &&
                                    item.from !== null 
                                    &&
                                    item.from?.substring(
                                      item !== null &&
                                        item.from !== null &&
                                        item.from?.length - 4,
                                      item !== null &&
                                        item.from !== null &&
                                        item.from.length
                                    )
                                  }`
                                    : item !== undefined && item.transactionType === "withdraw"
                                    ? "Cyborpay"
                                    :item !== undefined && item.bankAccount !== null ?
                                     `${
                                        item &&
                                        item.bankAccount &&
                                        item.bankAccount?.bankAccountNumber?.substring(
                                          0,
                                          2
                                        )
                                      }...
                                  ${
                                    item &&
                                    item.bankAccount &&
                                    item.bankAccount?.bankAccountNumber?.substring(
                                      item &&
                                        item.bankAccount &&
                                        item.bankAccount?.bankAccountNumber
                                          .length - 4,
                                      item &&
                                        item.bankAccount &&
                                        item.bankAccount.bankAccountNumber
                                          .length
                                    )
                                  }` 
                                  :item !== undefined && item.TypeCurrency ==="Fiat" && item.IBANSelected !== null ? item.IBANSelected :'-'}
                                </span>
                                {/* <span>
                                  {item !== undefined  && item.TypeCurrency === "Crypto"
                                    ? `${
                                        
                                        item.from !== null
                                         &&
                                        item.from?.substring(0, 5)
                                      }...
                                  ${
                                    item !== undefined &&
                                    item.from !== null 
                                    &&
                                    item.from?.substring(
                                      item !== null &&
                                        item.from !== null &&
                                        item.from?.length - 4,
                                      item !== null &&
                                        item.from !== null &&
                                        item.from.length
                                    )
                                  }`
                                    : item !== undefined && item.transactionType === "withdraw"
                                    ? "Cyborpay"
                                    :item !== undefined && item.bankAccount !== null ?
                                     `${
                                        item &&
                                        item.bankAccount &&
                                        item.bankAccount?.bankAccountNumber?.substring(
                                          0,
                                          2
                                        )
                                      }...
                                  ${
                                    item &&
                                    item.bankAccount &&
                                    item.bankAccount?.bankAccountNumber?.substring(
                                      item &&
                                        item.bankAccount &&
                                        item.bankAccount?.bankAccountNumber
                                          .length - 4,
                                      item &&
                                        item.bankAccount &&
                                        item.bankAccount.bankAccountNumber
                                          .length
                                    )
                                  }` : '-'}
                                </span> */}
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td className="amt hidden-for-mobile">
                            <div className="innerTd id">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 1000 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {item && item.TypeCurrency == "Crypto"
                                      ? `${item.to}`
                                      : item.transactionType == "withdraw"
                                      ? item &&
                                        item.bankAccount &&
                                        item.bankAccount.bankAccountNumber
                                      : "Cyborpay"}
                                  </Tooltip>
                                )}
                              >
                                <span>
                                {
                              item !== undefined && item.TypeCurrency === "Crypto" && item.to !== null ?   
                              `${item.to.substring(0, 5)}...
                              ${item.to.substring(
                                item.to.length - 4,
                                item.to.length
                              )}` : item !== undefined && item.TypeCurrency === "Fiat" && item.withdraw === true  && item.bankAccount !== null ? item.bankAccount.userName :item.TypeCurrency === "Fiat" && item.withdraw === false && item.bankAccount !== null ? item.bankAccount.userName : 'Cyborpay'
                             }
                                </span>
                                {/* <span>
                                  {item && item.TypeCurrency == "Crypto"
                                    ? `${item.to.substring(0, 5)}...
                                  ${item.to.substring(
                                    item.to.length - 4,
                                    item.to.length
                                  )}`
                                    : item.transactionType == "withdraw"
                                    ? `${
                                        item &&
                                        item.bankAccount &&
                                        item.bankAccount.bankAccountNumber.substring(
                                          0,
                                          2
                                        )
                                      }...
                                ${
                                  item &&
                                  item.bankAccount &&
                                  item.bankAccount.bankAccountNumber.substring(
                                    item &&
                                      item.bankAccount &&
                                      item.bankAccount.bankAccountNumber
                                        .length - 4,
                                    item &&
                                      item.bankAccount &&
                                      item.bankAccount.bankAccountNumber.length
                                  )
                                }`
                                    : "Cyborpay"}
                                </span> */}
                              </OverlayTrigger>
                            </div>
                          </td>
                          {hideSpec ? (
                            <td className="amt">
                              <div className="innerTd specialTd id">
                                <span className="hidden-for-desktop numbers ">
                                  {Number(
                                    item && item.coin && item.coin.decimals
                                      ? item.amount / 10 ** item.coin.decimals
                                      : item.amount
                                  ).toFixed(5)}
                                </span>

                                <CoinList
                                  name={
                                    item && item.TypeCurrency == "Crypto"
                                      ? item.tOrCName
                                      : item.currencyName
                                  }
                                  logoBgColor={
                                    item && item.TypeCurrency == "Crypto"
                                      ? item &&
                                        item.coin &&
                                        item.coin.logoBgColor
                                      : item &&
                                        item.currency &&
                                        item.currency.logoBgColor
                                  }
                                  logo={
                                    item && item.TypeCurrency == "Crypto"
                                      ? item &&
                                        item.coin &&
                                        item.coin.nonColouredLogo
                                      : item &&
                                        item.currency &&
                                        item.currency.nonColouredLogo
                                  }
                                />
                              </div>
                            </td>
                          ) : (
                            <td className="amt">
                              <div className="innerTd specialTd id">
                                <span className="hidden-for-desktop numbers ">
                                  {Number(
                                    item && item.coin && item.coin.decimals
                                      ? item.amount / 10 ** item.coin.decimals
                                      : item.amount
                                  ).toFixed(5)}
                                </span>

                                <p>
                                  {item && item.TypeCurrency == "Crypto"
                                    ? item.tOrCName
                                    : item.currencyName}
                                </p>
                              </div>
                            </td>
                          )}

                          <td className="amount ">
                            <div className="innerTd id hidden-for-mobile">
                              {Number(
                                item && item.coin && item.coin.decimals
                                  ? item.amount / 10 ** item.coin.decimals
                                  : item.amount
                              ).toFixed(5)}
                            </div>
                          </td>

                          {item.status == "completed"
                            ? item &&
                              item.TypeCurrency == "Crypto" && (
                                <td className="status completed hidden-for-mobile">
                                  <div className="innerTd id">
                                    {t(`${item.status}`)}
                                  </div>
                                </td>
                              )
                            : item.status == "failed"
                            ? item &&
                              item.TypeCurrency == "Crypto" && (
                                <td className="status cancel hidden-for-mobile">
                                  <div className="innerTd id">
                                    {t(`${item.status}`)}
                                  </div>
                                </td>
                              )
                            : item &&
                              item.TypeCurrency == "Crypto" && (
                                <td className="status pending hidden-for-mobile">
                                  <div className="innerTd id">
                                    {t(`${item.status}`)}
                                  </div>
                                </td>
                              )}

                          {item.fiatTransactionStatus == "Successfull"
                            ? item &&
                              item.TypeCurrency != "Crypto" && (
                                <td className="status completed hidden-for-mobile">
                                  <div className="innerTd id">
                                    {t(`completed`)}
                                  </div>
                                </td>
                              )
                            : item.status == "failed"
                            ? item &&
                              item.TypeCurrency != "Crypto" && (
                                <td className="status cancel hidden-for-mobile">
                                  <div className="innerTd id">
                                    {t(`${item.fiatTransactionStatus}`)}
                                  </div>
                                </td>
                              )
                            : item &&
                              item.TypeCurrency != "Crypto" && (
                                <td className="status pending hidden-for-mobile">
                                  <div className="innerTd id">
                                    {t(`${item.fiatTransactionStatus}`)}
                                  </div>
                                </td>
                              )}
                          {hideSpec ? (
                            <td className="amt">
                              <div className="innerTd">
                                <div className="vertical">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      className="threedot"
                                      id="dropdown-basic"
                                    >
                                      <VerticleIcon className="arrowclass hidden-for-mobile" />
                                      <Replacementof3dots
                                        className=" arrow hidden-for-desktop"
                                        onClick={() => {
                                          setIdata(item);
                                          setshow(true);
                                        }}
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="menu">
                                      <Dropdown.Item
                                        onClick={() => {
                                          setIdata(item);
                                          setshow(true);
                                        }}
                                      >
                                        {t("Details")}
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item
                                    // onClick={() => {
                                    //   openModal(), setname("Delete");
                                    // }}
                                    >
                                    Cancel
                                    </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                }
              </Table>

              {dataListCount > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("from")}{" "}
                      {Math.ceil(dataListCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      forcePage={pages}
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={dataListCount / limit}
                      // pageCount={2000}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        if (isFilterSet) {
                          getDepwithHistory({
                            skip: e.selected * limit,
                            limit,
                            startDate: picker.startDate,
                            endDate: picker.endDate,
                            currencyType: transferType,
                            orderBy: orderBy,
                            sort: sortBy,
                          });
                        } else {
                          getDepwithHistory({
                            skip: e.selected * limit,
                            limit,
                            currencyType: transferType,
                            orderBy: orderBy,
                            sort: sortBy,
                          });
                        }
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, wallet = {}, fiat = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const depositWithHistory = _.get(wallet, "depositWithHistory", []);
  const fiatTransaction = _.get(fiat, "fiatTransaction", []);
  const FiatTransactionCount = _.get(fiat, "FiatTransactionCount.count", 0);
  const fiatTransactionLoading = _.get(fiat, "fiatTransactionLoading", false);

  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const myusertransactionloading = _.get(
    wallet,
    "depositWithHistoryloading",
    false
  );
  const depositWithhistoryCount = _.get(wallet, "depositWithCount.count", 10);
  const AllTransaction = _.get(fiat, "AllTransaction", []);
  const AllTransactionCount = _.get(fiat, "AllTransactionCount.count", 0);

  return {
    thememode,
    mywalletsinfo,
    depositWithHistory,
    myaccountandbalance,
    depositWithhistoryCount,
    myusertransactionloading,
    fiatTransaction,
    FiatTransactionCount,
    fiatTransactionLoading,
    AllTransactionCount,
    AllTransaction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDepwithHistoryCount: (data) =>
    dispatch({ type: "getDepwithHistoryCountCalled", payload: data }),
  getDepwithHistory: (data) =>
    dispatch({ type: "getDepwithHistoryCalled", payload: data }),
  getFiatDepwithHistoryCount: (data) =>
    dispatch({ type: "getFiatTransactionCountCalled", payload: data }),
  getFiatDepwithHistory: (data) =>
    dispatch({ type: "getFiatTransactionCalled", payload: data }),
  getAllDepwithHistoryCount: (data) =>
    dispatch({ type: "getAllTransactionCountCalled", payload: data }),
  getAllDepwithHistory: (data) =>
    dispatch({ type: "getAllTransactionCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositWithdraw);
