import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  getCurrencyFailure,
  getCurrencyLoading,
  getCurrencySuccess,
  getPairsFailure,
  getPairsLoading,
  getPairsSuccess,
  getEstimateFailure,
  getEstimateLoading,
  getEstimateSuccess,
} from "./bityredux";
import { getAccessToken } from "./utilities";
export function* getCurrency(api, { payload = {} }) {
  yield put(getCurrencyLoading(true));
  try {
    const result = yield call(Api.callServer, api.getCurrency, {}, true);
    yield put(getCurrencySuccess(result?.currencies || []));
  } catch (error) {
    console.log(error);
    yield put(getCurrencyFailure(error));
  } finally {
    yield put(getCurrencyLoading(false));
  }
}
export function* getPairs(api, { payload = {} }) {
  yield put(getPairsLoading(true));
  try {
    const result = yield call(Api.callServer, api.getPair, {}, true);
    yield put(getPairsSuccess(result?.pairs || []));
  } catch (error) {
    console.log(error);
    yield put(getPairsFailure(error));
  } finally {
    yield put(getPairsLoading(false));
  }
}

export function* getEstimate(api, { payload = {} }) {
  yield put(getEstimateLoading(true));
  try {
    const result = yield call(
      Api.callServer,
      api.getestimate,
      { ...payload },
      true
    );
    yield put(getEstimateSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(getEstimateFailure(error));
  } finally {
    yield put(getEstimateLoading(false));
  }
}
