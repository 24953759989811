import React from 'react';
import { Container, Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "@components/issue-card-success/IssueCardSuccessPopupStyle.scss";

const IssueCardSuccessPopup = ({show, setShowModal}) => {
    const { t } = useTranslation();
  return (
    <Modal
    className="IssueCardSuccess"
    show={show}
    onHide={() => {
      setShowModal(false)
    }}
    dialogClassName="modal-90w"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body>
      <CloseIcon
        className="closebtn"
        onClick={() => {
          setShowModal(false)
        }}
      />
      <Container>
        <Row>
          <Col lg={12}>
            <div className="deletetext">
             <p>{t("Card Issued Successfully")} !</p> 
              <span>Your card will be delivered soon. To set up your card, please use the mobile app.</span>
            </div>
          </Col>
          <Col lg={12}>
            <div className="confirm">
              <CustomButton
                type="submit"
                name={t("OK")}
                filled={true}
                primary={true}
                onClick={() => {
                    setShowModal(false)
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
  </Modal>
  )
}

export default React.memo(IssueCardSuccessPopup);