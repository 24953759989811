import profile from "@images/profile.svg";
import "@components/profile/ProfileStyles.scss";
import { ReactComponent as Tick } from "@images/tick.svg";
import { ReactComponent as Cross } from "@images/cross.svg";
import { useTranslation } from "react-i18next";
function Profile({ MyProfileInfo, classname,showverified, showusername, showverifiedonmobile,showoccupation,classname2 }) {
  // console.log(MyProfileInfo.profileImage)
  const { t } = useTranslation();
  return (
    <div className={`profile ${classname2}`}>
        <div className="profilecircle">
          {
            MyProfileInfo && MyProfileInfo.profileImage && MyProfileInfo.profileImage.xs && !MyProfileInfo.profileImage.xs.includes("profile-16") &&
            <img src={`${process.env.REACT_APP_IMGLINK}${MyProfileInfo.profileImage.xs}`} className="realprofileimg2" /> ||
          <img src={profile} className="profileimg" />
          }
          { showverifiedonmobile == true && (showverified == true &&
            <span className="verifiedonmobile verifiedornotcircle green"><Tick className="tickclass" /></span> ||
            <span className="verifiedonmobile verifiedornotcircle red"><Cross className="tickclass" /></span>)
          }
        </div>
        <div className="profilearea">
          <div className={`${classname}`}>
            {/* {
              showverified == true &&
              <p className="verified-or-not verified"><span className="verifiedornotcircle green"><Tick className="tickclass" /></span>{t("VerifiedAccount")}</p> ||
              showverified == false &&
              <p className="verified-or-not unverified"><span className="verifiedornotcircle red"><Cross className="tickclass" /></span>{t("UnverifiedAccount")}</p>
            } */}
            <p className="user-name">
              <span className="firstname">{MyProfileInfo && MyProfileInfo.name}{" "}</span>
              <span>{MyProfileInfo && MyProfileInfo.subName}</span>
            </p>
            {/* {
              showusername == true &&
              <p className="usernametext">@username</p>
            } */}
            {/* {
              showoccupation &&
              <div className="profiletag d-block d-md-none">
                chief
              </div>
            } */}
          </div>
        </div>
    </div>
  );
}
export default Profile;
