export default {
  My: "My",
  Balances: "Balances",
  Deposit: "Deposit",
  Withdraw: "Withdraw",
  Exchange: "Exchange",
  Balance: "Balance",
  Price: "Price",
  Changes: "Change",
  Chart: "Chart",
  ExchangeRate: " Exchange rate",
  Commission: "Commission",
  InsufficientBalance: "Insufficient Balance",
  From: "From",
  Cancel: "Cancel",
  Confirm: "Confirm",
  To: "To",
  BankTransfer: "Bank Transfer",
  SelectAmountToWithdraw: "Select Account to Withdraw",
  TLWallet: "TL Wallet",
  List1:
    "You can deposit Turkish Lira into your BtcTurk account anytime from your Akbank, Denizbank, Fibabanka, Vakıfbank, Yapı Kredi or Ziraat Bank.",
  List2:
    " You can deposit Turkish Lira from your accounts at other banks to our accounts at Akbank, Denizbank, Fibabanka, Yapı Kredi and Ziraat Bank during EFT days and hours. Transactions you make outside of EFT business hours will take place within the first business day thereafter.",
  List3:
    "With the FAST (Instant and Continuous Funds Transfer) payment system commissioned by the Central ATM of the Republic of Turkey (CBRT), you can deposit Turkish Lira into BtcTurk from your bank accounts entered into the FAST payment system 7 days and 24 hours. With FAST, you can contact your ATM about money transfer limits and transaction fees.",
  List4:
    "We remind you that when making a TL deposit, the information about the name, surname and identification number of the sender must match the data of the BtcTurk account.",
  Click: "Click",
  DetailsOnTL: "for details on TL deposit and withdrawal transactions",
  Receive: "Receive",
  Share: "Share",
  Copied: "Copied",
  Amount:"Amount",
  Copy: "Copy",
  SelectCoin: "Select Coin",
  AddressToWithdraw: "Address to Withdraw",
  MAX: "MAX",
  SearchHere: "Search here",
  Withdrawal: "Withdrawal",
  to: "to",
  Wallet: "wallet",
  Continue: "Continue",
  SelectCurrency: "Select Currency",
  SelectAmountToWithdraw: "Select Account to Withdraw",
  ServiceFee: "Service Fee",
  AddNewAccount: "Add New Account",
  WrittenAmount :"5000 PayCrypt = $124,811",
  Function: "Function",
  Functions: "Functions",
  OnlyCyborCoin: "Send only PayCrypt Coin (CYBOR) to this address. Sending any other coins may result in their irrecoverable loss.",
  PendingApproval: "(Pending Approval)",
  Withdrawalfees: "Withdrawal fees",
  withdrawal: "withdrawal",
  limit: "limit",
  is: "is",
  Or: "Or",
  YouWillRecieve: "You will recieve",
  MyAccounts: "My Accounts",
  MyBalances: "My Balances",
  MyWallets: "My Wallets",
  VerifiedAccount: "Verified Account",
  UnverifiedAccount: "Unverified Account",
};
