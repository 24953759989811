import * as _ from "lodash";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackIcon } from "@images/back.svg";
import "@components/edit-branchpopup/EditBranchpopup.scss";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import FooterCommon from "@components/footer-common/FooterCommon";
import moment from "moment";
import { editBranchStart } from "@store/branchmanagementredux";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import pprofile from "@images/pprofile.svg";

const EditBranchpopup = ({
  userdata,
  setShowModal,
  data,
  show1,
  uploadProfileImage,
  updateProfileLoading,
  userProfileImageFailed,
  branchId,
  userAccessToken,
  branchDetails,
  editBranchST,
  allBranchesLoading,
  editBranchError,
  editBranchStart,
}) => {
  const [accountno, setaccountno] = useState("");
  const [fileEmptyError, setFileEmptyError] = useState("");
  const [validated, setValidated] = useState(false);
  const [phoneNoError, setPhoneNoError] = useState("");
  const [branch, setbranch] = useState(" ");
  const [phone, setPhone] = useState(userdata.mobile);
  const [code, setcode] = useState("91");
  const [address, setaddress] = useState("");
  const [showeye, setshoweye] = useState(true);
  const [image, setImage] = useState("");
  const [imageurl, setImageurl] = useState("");
  const [readerImage, setReaderImage] = useState(false);
  const [imageAdded, setimageAdded] = useState(false);
  const [file, setFile] = useState();

  useEffect(() => {
    setbranch(branchDetails.branchName);
    setcode(branchDetails.phone);
    setaddress(branchDetails.address);
    setaccountno(branchDetails.accountName);
    setImageurl(branchDetails.logo);
  }, [branchDetails]);

  const sendEditRequest = (form) => {
    if (form.checkValidity() === true) {
      if (imageurl === "") {
        setFileEmptyError("ImageRequired");
      }
      if (imageurl !== undefined) {
        setFileEmptyError("");
      }
      if (code.length < 3) {
        setPhoneNoError("PhoneEmpty");
      }
      if (imageurl == "") {
        setFileEmptyError("ImageRequired");
      } else {
        setFileEmptyError("");
        setPhoneNoError("");

        const data = {
          image: image,
          branchName: branch,
          address: address,
          phone: code,
          status: "Active",
          accountName: accountno,
          branchId: branchDetails.id,
        };
        if (readerImage) {
          let formdata = new FormData();
          formdata.append("image", image);
          data.imageAdded = true;
          data.formdata = formdata;
        }
        editBranchST(data);
        setReaderImage(false);
      }
    } else {
    }
  };

  const editBranch = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      if (imageurl == "") {
        setFileEmptyError("ImageRequired");
      }
      if (imageurl !== undefined) {
        setFileEmptyError("");
      }
      if (code.length < 3) {
        setPhoneNoError("PhoneEmpty");
      } else {
        if (code.length < 12) {
          setPhoneNoError("Please enter a valid phone no");
        } else {
          setPhoneNoError("");
        }
      }
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();

      sendEditRequest(form);
    }
  };

  useEffect(() => {
    if (editBranchError === undefined) {
      setShowModal({
        show: false,
        branchId: "",
        branchDetails: "",
      });
    }
  }, [allBranchesLoading]);

  const uploadImage = () => {
    const data = new FormData();
    data.append("image", image);
    uploadProfileImage({ data });
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    reader.onloadend = () => {
      setImage(file);
      setImageurl(reader.result);
      setReaderImage(true);
    };
    reader && reader.readAsDataURL(file);
  };

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="EditBranchpopup"
      show={show1}
      onHide={() =>
        setShowModal({
          show: false,
          branchId: "",
          branchDetails: "",
        })
      }
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modalPrimaryHeader"></div>
        <CloseIcon
          className="closebtn"
          onClick={() =>
            setShowModal({
              show: false,
              branchId: "",
              branchDetails: "",
            })
          }
        />
        <Container>
          <Form noValidate onSubmit={editBranch} validated={validated}>
            <div className="outercontainer">
              <div className="edittitle hidden">
                {" "}
                <BackIcon
                  className="back"
                  onClick={() =>
                    setShowModal({
                      show: false,
                      branchId: "",
                      branchDetails: "",
                    })
                  }
                />
                <span className="editspan">
                  {t("Edit")} {t("Branch")}
                </span>
              </div>
              <div className="titles">
                <span>{t("Logo")}</span>
              </div>

              <div className="outercls">
                <div className="displayasinline imgouter">
                  {readerImage == true ? (
                    <img src={imageurl} className="imgsize integratedimage" />
                  ) : imageurl == "" ? (
                    <img src={pprofile} className="imgsize integratedimage" />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_IMGLINK}${imageurl}`}
                      className="imgsize integratedimage"
                    />
                  )}
                </div>
                <div className="displayasinline2">
                  <div className="marginlft">
                    <div className="spanwidth">
                      <span className="spanmsg">{t("thesizeoftheimage")}</span>
                    </div>
                    <div className="d-flex margintop">
                      <div className="topbuttons btntop">
                        <label htmlFor="inputfile" className="inputbuttonmain">
                          {t("Change")}
                        </label>
                        <input
                          onChange={(e) => handleImageChange(e)}
                          type="file"
                          id="inputfile"
                          className="inputfilebtn"
                        />
                      </div>
                      <div className="bottombuttons btntop">
                        <CustomButton
                          name={t("Exclude")}
                          filled={true}
                          type="normal"
                          primary={true}
                          onClick={(e) => {
                            e.preventDefault();

                            setImageurl("");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="fileErrorCustom">{t(`${fileEmptyError}`)}</p>
              </div>

              {editBranchStart && <Loader />}

              <div className="titles">
                <span>{t("Account")}</span>
                <div className="inputbox">
                  <InputField
                    type="text"
                    required
                    onChange={(e) => setaccountno(e.target.value)}
                    value={accountno}
                    isInvalid={validated === false}
                    error={
                      accountno === "" ? "Please enter an account name" : ""
                    }
                  />
                </div>
              </div>
              <div className="titles">
                <span>{t("Branch")}</span>
                <div className="inputbox">
                  <InputField
                    type="text"
                    onChange={(e) => setbranch(e.target.value)}
                    value={branch}
                    required
                    isInvalid={validated === false}
                    error={branch === "" ? "Please enter a branch name" : ""}
                  />
                </div>
              </div>
              <div className="titles">
                <span>{t("Phone")}</span>
                <div className="inputphone">
                  <InputField
                    phone
                    type="Phone"
                    onChange={(e) => setcode(e)}
                    value={code}
                    required
                    isInvalid={validated === true}
                    error={
                      code && code.length === 0
                        ? "Phone no cannot be empty"
                        : code && code.length < 12
                        ? "Please enter a valid phone no"
                        : ""
                    }
                  />
                </div>
                <p className="fileErrorCustom">{t(`${phoneNoError}`)}</p>
              </div>

              <div className="titlepass">
                <span>{t("Address")}s</span>
              </div>
              <div className="outerpass">
                <div className="inputboxpass">
                  <InputField
                    type="text"
                    onChange={(e) => setaddress(e.target.value)}
                    value={address}
                  />
                </div>
              </div>

              <div className="btmbtnoutercontainer">
                <div className="topbuttons">
                  <CustomButton
                    type="submit"
                    name={t("Save")}
                    filled={true}
                    primary={true}
                    //onClick={() => editBranch()}
                  />
                </div>
                <div className="bottombuttons">
                  <CustomButton
                    name={t("Cancel")}
                    filled={true}
                    primary={true}
                    type="button"
                    onClick={() =>
                      setShowModal({
                        show: false,
                        branchId: "",
                        branchDetails: "",
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </Form>
        </Container>

        <p className="error-msg">{editBranchError !== undefined ? editBranchError : ""}</p>
        <div className="hidden">
          <FooterCommon />
        </div>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ user = {}, branchmanagementredux = {} }) => {
  const userdata = _.get(user, "MyProfileInfo", false);
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const updateProfileLoading = _.get(user, "updateProfileLoading", false);
  const userProfileImageFailed = _.get(user, "userProfileImageFailed", false);
  const allBranchesLoading = _.get(
    branchmanagementredux,
    "listAllBranchesLoading",
    false
  );
  const editBranchError = _.get(
    branchmanagementredux,
    "editBranchError",
    undefined
  );
  const editBranchStart = _.get(
    branchmanagementredux,
    "editBranchStart",
    false
  );
  return {
    userdata,
    updateProfileLoading,
    userAccessToken,
    allBranchesLoading,
    editBranchError,
    editBranchStart,
  };
};
const mapDispatchToProps = (dispatch) => ({
  uploadProfileImage: (data) =>
    dispatch({ type: "updateProfileImage", payload: data }),
  editBranchST: (data) => dispatch({ type: "editBranchStart", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBranchpopup);
