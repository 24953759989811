export default {
  Symbol: "Sembol",
  Price: "Ücret",
  loading: "Yükleniyor",
  Balances: "Bakiyeler",
  My: "",
  ResendOTP: "OTP'yi yeniden gönder",
  SignUp: "Kayıt ol",
  SignIn: "Giriş yap",
  phone: "Telefon numarası",
  phocannotemp: "Telefon numarası alanı boş olmamalıdır",
  "Phone must be a valid": "Telefon numarası geçerli olmalıdır",
  "Phone cannot be empty": "Telefon numarası alanı boş olmamalıdır",
  emailid: "E-mail",
  enteremailid: "Lütfen E-mail adresinizi girin",
  password: "Şifre",
  passwordnotempty: "Şifre alanı boş olmamalıdır",
  verify: "Doğrula",
  "Link expired": "Bağlantının süresi doldu",
  FilterPeriode: "Dönem Filtresi",
  rntrpass: "Şifrenizi tekrar girin",
  gotosignin: "Giriş yap",
  create: "Oluştur",
  Darkmode: "Karanlık Mod",
  Lightmode: "Işık Modu",
  downloadforchrome:
    "Google Chrome tarayıcısı için PayCrypt Security koruma sistemini indirin",
  security: "PayCrypt Güvenliği",
  universal:
    "Kişisel verilerin ve kripto varlıklarının korunması için herkese açık evrensel bir sistem",

  ///////////common er msgs entire platform////////////////
  EMAIL_EMPTY: "E-mail adresi alanı boş olmamalıdır",
  MOBILE_EMPTY: "Telefon numarası alanı boş olmamalıdır",
  PASSWORD_EMPTY: "Şifre alanı boş olmamalıdır",
  USER_NOT_FOUND: "Kullanıcı bulunamadı",
  VERI_LINK_EXPIRED: "Doğrulama bağlantısının süresi doldu",
  INVALID_VERI_DETAILS: "Doğrulama bilgileri geçersizdir",
  "email should not be empty,email must be an email":
    "Geçerli bir e-posta adresi girin",

  //payment
  CURRENCY_NOT_SUPPORTED: "Para birimi desteklenmiyor",
  COIN_NOT_SUPPORTED: "Coin desteklenmiyor",
  ACCOUNT_NOT_FOUND: "Hesap bulunamadı",
  WALLET_NOT_FOUND: "Cüzdan bulunamadı",
  UNAUTHRIZED_ACCOUNT: "Yetkisiz hesap",
  LINK_USED: "Bağlantı kullanıldı",
  PAYMENT_LINK_TOKEN_NOT_FOUND: "Ödeme Bağlantısı bulunamadı",
  LINK_USED_OR_NOT_FOUND: "Bağlantı kullanılmış veya bulunamadı",
  COMMISSION_NOT_FOUND: "Komisyon bulunamadı",
  EXCHANGE_NOT_FOUND: "Döviz bulunamadı",
  BALANCEE_TOO_LOW: "Bakiye yetersiz",
  TIME_EXCEED: "Zaman doldu",
  PaymentSuccessfully: "Ödeme Başarılı",

  /////IDO/////////
  TOKENS_NOT_AVAILABLE: "Tokenler mevcut değil",
  IDO_ROUND_NOT_FOUND: "IDO turu bulunamadı",
  ADDRESS_DOESNOT_BELONG_TO_CYBORPAY_USER:
    "Adres PayCrypt kullanıcısına ait değil",
  EXCHANGE_RATE_NOT_MATCHING: "Döviz kuru eşleşmiyor",
  IDO_TRANSACTION_NOT_FOUND: "IDO işlemi bulunamadı",
  TOKENS_ALREADY_CLAIMED: "Tokenler talep edildi",
  LOCKING_PERIOD_IS_NOT_OVER: "Kilitleme süresi dolmadı",
  INSUFFICIENT_BALANCE: "Yetersiz bakiye",
  TOKENS_NOT_AVAILABLE: "Tokenler mevcut değil",
  AMOUNT_TO_EXCHANGE_CANNOT_BE_IN_NEGETIVE:
    "Döviz edilecek tutar negatif olamaz",
  TOKEN_ADDED: "Tokenler eklendi",
  TOKEN_TRANSFERRED: "Tokenler aktarıldı",

  //////Fiat/////
  BANK_ACCOUNT_NOT_FOUND: "Banka bulunamadı",
  YOUR_ACCOUNT_IS_NOT_ACTIVATED: "Hesabınız aktif değil",
  FIAT_CURRENCY_NOT_FOUND: "Fiat para birimi bulunamadı",
  ACCOUNT_IS_NOT_ACTIVE: "Hesap aktif değil",
  LOW_BALANCE: "Düşük bakiye",
  USERS_FIAT_DEPOSIT_REQUEST_NOT_FOUND:
    "Kullanıcıların fiat para yatırma talebi bulunamadı",
  AMOUNT_ALREADY_DEPOSITED: "Yatırım gerçekleştirildi",
  USER_CURRENCY_NOT_FOUND: "Kullanıcının para birimi bulunamadı",
  BUSINESS_REQUEST_NOT_FOUND: "İşletme talebi bulunamadı",
  "BUSINESS_USER_WITH_ID_${Id}_NOT_FOUND":
    "İD numaralı işletme kullanıcısı bulunamadı",
  INVALID_MERCHANT_ADDRESS: "Geçersiz satıcı adresi",

  AMOUNT_CANNOT_BE_IN_NEGETIVE: "Tutar negatif olamaz",

  BALANCEE_TOO_LOW: "Bakiye çok düşük",
  PLATFORM_ERROR:
    "Yüksek tutar nedeniyle platform hatası oluştu. Lütfen destek ekibi ile iletişime geçin",

  ///////////common er msgs entire platform-----------ends-----------////////////////

  FAILED_WITHDRAWAL_AUTHENTICATOR_VERIFICATION:
    "Geçersiz kimlik doğrulayıcı kodu",
  Security_verification: "Güvenlik doğrulaması",
  borrowbankloan: "Banka Kredisi Al",
};
