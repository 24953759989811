import React, { useState, Component, useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { ReactComponent as LogoutImg } from "@images/logout.svg";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import Logout from "src/components/log-out/Logout";
import { Row, Col } from "react-bootstrap";
import "@components/mobile-settings/MobileSettings.scss";
import MyProfile from "../my-profile/MyProfile";
import MyAccounts from "../my-accounts/MyAccounts";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
function MobileSettings({ thememode, tab, logouts }) {
  let history = useHistory();
  const [showProfile, setShowProfileTab] = useState(false);
  const [showAccount, setShowAccountTab] = useState(false);
  const [show, setShowLogout] = useState(false);
  useEffect(() => {
    if (tab == "first") {
      setShowProfileTab(true);
    }
    if (tab == "second") {
      setShowAccountTab(true);
    }
  }, []);

  const { t } = useTranslation();
  return (
    <div id="mobilesettings" className="d-lg-none d-block">
      {!showProfile && !showAccount && (
        <Col lg={9}>
          <div className="container">
            <div className="container1">
              <div className="titlecontainer">
                <div className="title">{t("Settings")}</div>
              </div>
              <div className="optioncontainer">
                <div
                  className="box"
                  onClick={() => {
                    setShowProfileTab(true);
                  }}
                >
                  <div className="optiontitle">
                    <div className="optiontext"> {t("MyProfile")}</div>
                  </div>
                  <div className="icon2">
                    <Arrow className={"Arrow"} />
                  </div>
                </div>
                <div className="stroke" />
                <div
                  className="box"
                  onClick={() => {
                    setShowAccountTab(true);
                  }}
                >
                  <div className="optiontitle">
                    <div className="optiontext"> {t("Myaccounts")}</div>
                  </div>
                  <div className="icon2">
                    <Arrow className={"Arrow"} />
                  </div>
                </div>
                <div className="stroke" />
              </div>
            </div>

            <div className="container1 mt-4">
              <div className="titlecontainer">
                <div className="title">{t("terms")}</div>
              </div>
              <div className="optioncontainer">
                <a href="https://cyborholding.gitbook.io/cybor-holding/">
                  <div className="box">
                    <div className="optiontitle">
                      <div className="termstext"> {t("FAQs")}</div>
                    </div>
                    <div className="icon2">
                      <Arrow className={"Arrow"} />
                    </div>
                  </div>
                </a>
                <div className="stroke" />
                <a href="#">
                  <div className="box">
                    <div className="optiontitle">
                      <div className="termstext">{t("CommissionsFees")}</div>
                    </div>
                    <div className="icon2">
                      <Arrow className={"Arrow"} />
                    </div>
                  </div>
                </a>

                <div className="stroke" />
                <a
                  href="https://cyborholding.com/ru/company-regulation/
"
                >
                  <div className="box">
                    <div className="optiontitle">
                      <div className="termstext">{t("TermsCondition")}</div>
                    </div>
                    <div className="icon2">
                      <Arrow className={"Arrow"} />
                    </div>
                  </div>
                </a>
                <div className="stroke" />
                <a href="https://cyborholding.com/ru/privacy-policy/">
                  <div className="box">
                    <div className="optiontitle">
                      <div className="termstext">{t("PrivacyPolicy")}</div>
                    </div>
                    <div className="icon2">
                      <Arrow className={"Arrow"} />
                    </div>
                  </div>
                </a>
                <div className="stroke" />
              </div>
            </div>
          </div>
          <div className="logout">
            <div className="icon">
            <LogoutImg className={"Logout"} />
            </div>
            <div
              className="text"
              onClick={() => {
                setShowLogout(true);
              }}
            >
              {t("LogOut")}
            </div>
          </div>
          <Logout
            onClick={() => logouts({ history })}
            show1={show}
            setShowModal={(e) => setShowLogout(e)}
          />
        </Col>
      )}

      {showProfile && (
        <div>
          <MyProfile
            showBackIcon={true}
            setShowProfileTab={() => setShowProfileTab(false)}
          />
        </div>
      )}
      {showAccount && (
        <div>
          <MyAccounts
            showBackIcon={true}
            setShowAccontTab={() => setShowAccountTab(false)}
          />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = ({ app = {} }) => {
  const thememode = _.get(app, "thememode", false);

  return {
    thememode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logouts: (data) => dispatch({ type: "logoutCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSettings);
