import Table from "react-bootstrap/esm/Table";
import ReactPaginate from "react-paginate";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import "@components/ido-table/IdoTableStyle.scss";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import CustomButton from "@components/custom-button/CustomButton";
import Loader from "@components/loader/Loader";
import BigNumber from "bignumber.js";
import { connect } from "react-redux";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";

function IdoTable({
  getidoList,
  getRountido,
  idotransaction,
  claimIdo,
  claimTransactionLoading,
  thememode,
  getroundLoading,
}) {
  let [pages, setpages] = useState(0);
  const [limit, setlimit] = useState(3);
  const [orderBy, setOrderBy] = useState('')
  const [sortBy, setSortBy] = useState('')

  useEffect(() => {
    getidoList({ skip: pages, limit });
    getRountido();
  }, []);
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="idotable">
      <Table
        responsive="sm"
        className={`tablestyle ${thememode == "light" ? "idotableinlight" : "idotaleindark"
          }`}
        borderless
      >
        {/* {claimTransactionLoading && <Loader />} */}
        {getroundLoading && <Loader />}
        <thead>
          <tr>
            <th className="tableheading">
              {t("NO")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow"
                  onClick={() => {
                    // console.log('I am clicked')
                    setOrderBy('id')
                    setSortBy('ASC')

                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'id',
                        sort: 'ASC',
                      })

                  }}
                />

                <SortdownIcon className="downarrow"

                  onClick={() => {
                    setOrderBy('id')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                         orderBy: 'id',
                        sort: 'DESC'

                      })

                  }} />
              </span>
            </th>
            <th className="tableheading">
              {t("AmountBuy")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow"

                  onClick={() => {

                    // setOrderBy('amount')
                    setSortBy('ASC')

                    getidoList(
                      {
                        skip: pages,
                        limit,
                        //  orderBy: '',
                        sort: 'ASC',
                      })

                  }}
                />

                <SortdownIcon className="downarrow"

                  onClick={() => {
                    // setOrderBy('amount')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'id',
                        sort: 'DESC'

                      })

                  }}
                />
              </span>
            </th>
            <th className="tableheading">
              {t("Date")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow"
                  onClick={() => {
                    setOrderBy('date')
                    setSortBy('ASC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'date',
                        sort: 'ASC'

                      })

                  }}
                />

                <SortdownIcon className="downarrow"
                  onClick={() => {
                    setOrderBy('date')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'date',
                        sort: 'DESC'

                      })

                  }}

                />
              </span>
            </th>
            <th className="tableheading">
              {t("Rate")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow"
                  onClick={() => {
                    setOrderBy('exchangeRate')
                    setSortBy('ASC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'exchangeRate',

                        sort: 'ASC'

                      })

                  }}
                />

                <SortdownIcon className="downarrow"
                  onClick={() => {
                    setOrderBy('exchangeRate')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'exchangeRate',
                        sort: 'DESC'

                      })

                  }}
                />
              </span>
            </th>
            <th className="tableheading">
              {t("Cost")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow"
                  onClick={() => {
                    setOrderBy('cost')
                    setSortBy('ASC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        //  orderBy: 'id',
                        sort: 'ASC'

                      })

                  }}
                />

                <SortdownIcon className="downarrow"
                  onClick={() => {
                    setOrderBy('cost')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: '',
                        sort: 'DESC'

                      })

                  }}
                />
              </span>
            </th>
            <th className="tableheading">
              {t("Round")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow"
                  onClick={() => {
                    setOrderBy('roundName')
                    setSortBy('ASC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'roundName',
                        sort: 'ASC'

                      })

                  }}
                />

                <SortdownIcon className="downarrow"
                  onClick={() => {
                    setOrderBy('roundName')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'roundName',
                        sort: 'DESC'

                      })

                  }}
                />
              </span>
            </th>
            <th className="tableheading">
              {t("Bonus")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow"

                  onClick={() => {
                    setOrderBy('bonusReceived')
                    setSortBy('ASC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'bonusReceived',
                        sort: 'ASC'

                      })

                  }}
                />

                <SortdownIcon className="downarrow"

                  onClick={() => {
                    setOrderBy('bonusReceived')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'bonusReceived',
                        sort: 'DESC'

                      })

                  }} />
              </span>
            </th>
            <th className="tableheading">
              {t("UnlockedDate")}{" "}
              <span className="sorticon">
                <SortupIcon className="uparrow" 
                                  onClick={() => {
                                    setOrderBy('unlockdate')
                                    setSortBy('ASC')
                                    getidoList(
                                      {
                                        skip: pages,
                                        limit,
                                        orderBy: 'unlockdate',
                                        sort: 'ASC'
                
                                      })
                
                                  }}
                />

                <SortdownIcon className="downarrow"
                  onClick={() => {
                    setOrderBy('unlockdate')
                    setSortBy('DESC')
                    getidoList(
                      {
                        skip: pages,
                        limit,
                        orderBy: 'unlockdate',
                        sort: 'DESC'

                      })

                  }}
                />
              </span>
            </th>
            <th className="tableheading idoaction">{t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {idotransaction &&
            idotransaction
              .slice(pages * limit, pages * limit + limit)
              .map((i) => (
                <tr className="maintdwrapper" key={`Tokens${i}`}>
                  <td className="colspace">
                    <div className="innerTd">
                      <div className="moreInnerTd no">{i.id}</div>
                    </div>
                  </td>

                  <td className="colspace">
                    <div className="innerTd">
                      <div className="moreInnerTd">
                        {BigNumber(i.tokensallocated)
                          .dividedBy(10 ** i.coin2.decimals)
                          .toFixed(4)}{" "}
                        <span className="exhCurr">
                          {i.coin2.exchangeSymbol}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td className="colspace">
                    <div className="innerTd">
                      <div className="moreInnerTd date">
                        {" "}
                        {moment.unix(i.date).format("MM/DD/YYYY hh:mm A")}
                      </div>
                    </div>
                  </td>

                  <td className="colspace">
                    <div className="innerTd">
                      <div className="moreInnerTd">
                        {i.exchangeRate}
                        <span className="exhCurr">USD</span>
                      </div>
                    </div>
                  </td>

                  <td className="colspace">
                    <div className="innerTd">
                      <div className="moreInnerTd">
                        {BigNumber(i.Amountpaid)
                          .dividedBy(10 ** i.coin1.decimals)
                          .toFixed(4)}{" "}
                        <span className="exhCurr">
                          {i.coin1.exchangeSymbol}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td className="colspace">
                    <div className="innerTd">
                      <div className="round moreInnerTd">
                        {t(`${i.roundName}`)}
                      </div>
                    </div>
                  </td>

                  <td className="colspace">
                    <div className="innerTd">
                      <div className="moreInnerTd">
                        {i.bonusReceived}{" "}
                        <span className="exhCurr">
                          {i.coin2.exchangeSymbol}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="colspace">
                    <div className="innerTd">
                      <div className="moreInnerTd date">
                        {moment.unix(i.unlockdate).format("MM/DD/YYYY hh:mm A")}
                      </div>
                    </div>
                  </td>
                  <td className="colspace">
                    {i.claimStatus !== "Successfull" ? (
                      <div className="innerTd">
                        {moment().unix() < i.unlockdate ? (
                          <div className="innerTd">
                            <div className="moreInnerTd date">
                              {moment
                                .unix(i.unlockdate)
                                .format("MM/DD/YYYY hh:mm A")}
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`claimbtn ${moment().unix() < i.unlockdate && "claimbtnfalse"
                              }`}
                          >
                            <CustomButton
                              disable={moment().unix() < i.unlockdate}
                              name={t("Claim")}
                              filled={true}
                              primary={true}
                              onClick={() => claimIdo(i.id)}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="innerTd">
                        <div className="moreInnerTd date align">
                          {t("Claimed")}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      {idotransaction.length > 10 && (
        <div className="paginationsectionforidotable">
          <div className="paginationallignmentfortable">
            <ReactPaginate
              previousLabel={<PreviousIcon className="paginationicon" />}
              nextLabel={<NextIcon className="paginationicon" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={idotransaction && idotransaction.length / limit}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={(e) => {
                getidoList({
                  skip: e.selected * limit,
                  limit,

                  orderBy: orderBy,
                  sort: sortBy,

                })
                setpages(e.selected)
              }}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ ido, wallet, app }) => {
  const idotransaction = _.get(ido, "idotransaction", []);

  const claimTransactionLoading = _.get(ido, "claimTransactionLoading", false);
  const thememode = _.get(app, "thememode", false);
  const getroundLoading = _.get(ido , "getroundLoading" , false)

  return {
    idotransaction,
    thememode,
    claimTransactionLoading,
    getroundLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  claimIdo: (data) => dispatch({ type: "claimIdoCalled", payload: data }),
  getidoList: (data) =>
    dispatch({ type: "getidoListbyaddresscalled", payload: data }),

  getRountido: (data) => dispatch({ type: "getRountidocalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdoTable);
