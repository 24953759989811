import React, { useState , useEffect } from "react";
import "@components/wallet-big-coin-card/WalletBigCoinCardStyles.scss";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Row, Col, Dropdown } from "react-bootstrap";
import { ResponsivePie } from "@nivo/pie";
import { ReactComponent as DropdownIcon } from "@images/dropdown.svg";
import { ReactComponent as HideEye } from "@images/hideeye.svg";
import { ReactComponent as HideEye2 } from "@images/eye.svg";
import { ReactComponent as SetIcon } from "@images/settingic.svg";
import DropdownforCurrency from "@components/dropdownforcurrency/DropdownforCurrency";
import SmallButton from "@components/small-button/SmallButton";
import CoinListBalance from "@components/coin-list-balance/CoinListBalance";
import BigNumber from "bignumber.js";
import { Spinner } from 'react-bootstrap'
import { getCSymbol, getESymbol } from "src/utils/Currencies";

const WalletBigCoinCard = ({
  mywalletsinfo,
  bnbdata,
  ethdata,
  btcdata,
  busddata,
  trxdata,
  trycdata,
  setExchangeModal,
  setShowDeposit,
  setwithdrawModal,
  displayedCurrencies,
  role,
  getAmount,
  setselectedCoin,
  PreferredCurrency,
  hideZeroBalance,
  circularLoader,
  getplatformTokensinfo,
  sethideZero,
  fiatrate,
  myEstimatedBal,
}) => {
  const history = useHistory();
  const [tokens , setTokens] =useState([]);
  const [piedata,setPiedata] = useState()
  const [colourdata,setColourdata] = useState()
  const [newTotal,setNewTotal] = useState(0)
  // const [hideZeroBalance, sethideZero] = useState(false);
  
  // useEffect(()=>{
  //   console.log('clickedddddddd',mywalletsinfo.map(item =>{

  //     const items = item.coin; 
  //     if (
  //       _.findIndex(displayedCurrencies.crpto, function (chr) {
  //         return chr == items.id;
  //       }) !== -1
  //     ){return item}


  //   } ))
  // },[displayedCurrencies])




  useEffect(() => {
    filteredTokens();
      
     
  } , [displayedCurrencies.crpto])
  const { t, i18n, ready } = useTranslation();
  const [activeKey, setActiveKey] = useState(
    mywalletsinfo &&
      mywalletsinfo[0] &&
      mywalletsinfo[0].coin &&
      mywalletsinfo[0].coin.id
  );
  let filteredTokens =()=>{
  let data = []
  for(let i of mywalletsinfo){
    if(displayedCurrencies.crpto.includes(i.coin.id)){
      data.push(i)
    }
  }
   setTokens(data)
}
    let pieadata = [],
    colorsdata = [],
    total = 0,
    mybal = "0",
    l = "";
  mywalletsinfo &&
    mywalletsinfo.map((items) => {
      mybal = 0;
      let bal = items && items.amount;
      let dec = items && items.coin && items.coin.decimals;
      let val = bal / 10 ** dec;
      if (items.coin.tokenName == "Ethereum") {
        mybal = Number(val * Number(ethdata && ethdata.p).toFixed(2));
      }
      if (items.coin.tokenName == "Bitcoin") {
        mybal = Number(val * Number(btcdata && btcdata.p).toFixed(2));
      }
      if (items.coin.tokenName == "BSC") {
        mybal = Number(val * Number(bnbdata && bnbdata.p).toFixed(2));
      }
      if (items.coin.tokenName == "CyborCoin") {
        mybal = Number(val * Number("1").toFixed(2));
      }
      if (items.coin.tokenName == "Tron") {
        mybal = Number(val * Number(trxdata && trxdata.p).toFixed(2));
      }
      if (items.coin.tokenName == "Tether") {
        mybal = Number(val * Number(busddata && busddata.p).toFixed(2));
      } // Number("1.00")}
      if (items.coin.tokenName == "TRYCoin") {
        if(trycdata && trycdata.p!=undefined)
        mybal = Number(val *  BigNumber(1).dividedBy(trycdata && trycdata.p));
      }
      colorsdata.push(items.coin.logoBgColor);
      l = l + " ";
      pieadata.push({
        id: `${items.coin.tokenName}${l}`,
        label: items.coin.tokenName,
        value: mybal,
        color: items.coin.logoBgColor,
        labeltype: `${items.coin.tokenName} ${
            items.coin.type == "TRC20" ||
            items.coin.type == "BEP20" ||
            items.coin.type == "ERC20"
              ? items.coin.type
              : ""}`,
      });
      total += mybal;
    });
    // setPiedata(pieadata);
    // setColourdata(colorsdata);
    // setNewTotal(total)

 
  return (
    <div className={`WalletBigCoinCard`}>
      <Row className="maindashrow">
        <Col lg={6} xl={8}>
          <div className="text-start allignMobile">
            <span className="balance">{t("CryptoBalance")}</span>
            <span
              className="hideTag"
              onClick={() => sethideZero(!hideZeroBalance)}
            >
              {hideZeroBalance ? (
                <HideEye2 className="hideeye" />
              ) : (
                <HideEye className="hideeye" />
              )}
              {hideZeroBalance ? t("showzerobalance") : t("Hidezerobalance")}
            </span>
            <div className="displayedcurrencies d-none d-lg-inline-block">
              <Dropdown className="downbtn" autoClose="outside" align="end">
                <Dropdown.Toggle className="dropdown-toggle::after">
                  <SetIcon className="droparrowss rightspace" />
                  <DropdownIcon className="droparrow" />
                </Dropdown.Toggle>
                <div className="sidebarmenu">
                  <DropdownforCurrency page="dashboard" />
                </div>
              </Dropdown>
            </div>
          </div>
        </Col>
        <Col lg={6} xl={4} className="actions">
          <div className={`options optionposition`}>
            <div className="mainbuttonswrapper">
              {role !== "Business" && (
                <SmallButton
                  type="deposit"
                  onclick={() => {
                    setShowDeposit();
                  }}
                />
              )}
              <SmallButton
                type="withdraw"
                onclick={() => {
                  setwithdrawModal();
                }}
              />
              <SmallButton
                type="exhange"
                onclick={() => {
                  setExchangeModal();
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* <hr /> */}
      <Row className="mt-5">
        <Col lg={5}>
          <div className="piechartsection">
          {circularLoader && 
          <div className="loaderWrap">
            <Spinner animation="grow" className="spincolor" />
          </div>}
            <ResponsivePie
              data={pieadata}
              margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
              innerRadius={0.76}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#fff"
              arcLinkLabelsThickness={0}
              enableArcLabels={false}
              isInteractive={false}
              enableArcLinkLabels={false}
              // tooltip={function(e){var t=e.datum;return a.createElement(i,{style:{color:t.color}},a.createElement(s,null,"id"),a.createElement(u,null,t.id),a.createElement(s,null,"value"),a.createElement(u,null,t.value),a.createElement(s,null,"formattedValue"),a.createElement(u,null,t.formattedValue),a.createElement(s,null,"color"),a.createElement(u,null,t.color))}}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              colors={colorsdata}
              fill={[
                {
                  match: {
                    id: "ETH",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "CYBOR",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "BTC",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "USDT",
                  },
                  id: "dots",
                },
              ]}
            ></ResponsivePie>
            
            {circularLoader !== true ? <div className="mybalinpie">
              
              <span className="pietxt">
               {" "}
               {getCSymbol(PreferredCurrency)}{" "}
               { BigNumber(total)
                 .multipliedBy(
                   (fiatrate && fiatrate[getESymbol(PreferredCurrency)]&& fiatrate[getESymbol(PreferredCurrency)].value) || 1
                 )
                 .toFixed(2, BigNumber.ROUND_FLOOR)}
               
                
               {/* {Number(total).toFixed(2)} */}
             </span>
        
             <span className="pietxt1">{t("TotalBalances")}</span>
           </div> :  <div className="mybalinpie">
              
              {/* <span className="pietxt">
               {" "}
               {getCSymbol(PreferredCurrency)}{" "}
               { BigNumber(total)
                 .multipliedBy(
                   (fiatrate && fiatrate[getESymbol(PreferredCurrency)]) || 1
                 )
                 .toFixed(2, BigNumber.ROUND_FLOOR)} */}
               
                
               {/* {Number(total).toFixed(2)} */}
             {/* </span> */}
        
             {/* <span className="pietxt1">{t("TotalBalances")}</span> */}
           </div>
            

                   }
          </div>
                   
                   
          <div className="legendarea">
            {pieadata &&
              pieadata.map((o) => (
                <div className="legend">
                  <span
                    className="legendcolor"
                    style={{ "background-color": o.color }}
                  ></span>
                  <span className="legendname">{o.labeltype}</span>
                </div>
              ))}
          </div>
        </Col>
        <Col lg={7} className="dashCoinListColMain">
          <Row>
            {tokens &&
              tokens.map((item) => {
                const items = item.coin; 
                // if (
                //   _.findIndex(displayedCurrencies.crpto, function (chr) {
                //     return chr == items.id;
                //   }) !== -1
                // )
                  if (hideZeroBalance && getAmount(items.id) != 0) {
                    return (
                      <Col lg={12} className="coinListCol d-none d-lg-block ">
                        <div className="coinListWrapperD">
                          <CoinListBalance
                            logo={items.nonColouredLogo}
                            name={items.tokenName}
                            balance={getAmount(items.id)}
                            decimals={items.decimals}
                            symbol={items.exchangeSymbol}
                            bgcolor={items.logoBgColor}
                            coinkey={items.id}
                            tag={items.type}
                            tableno={"first"}
                            onClick={() => {
                              setselectedCoin(item);
                              setActiveKey(items.id);
                            }}
                            fromwallet={true}
                            activekey={activeKey}
                            showtagontop={true}
                          />
                        </div>
                      </Col>
                    );
                  } 
                  if(!hideZeroBalance) {
                    return (
                      <Col lg={12} className="coinListCol d-none d-lg-block ">
                        <div className="coinListWrapperD">
                          <CoinListBalance
                            logo={items.nonColouredLogo}
                            name={items.tokenName}
                            balance={getAmount(items.id)}
                            decimals={items.decimals}
                            symbol={items.exchangeSymbol}
                            bgcolor={items.logoBgColor}
                            coinkey={items.id}
                            tag={items.type}
                            tableno={"first"}
                            onClick={() => {
                              setselectedCoin(item);
                              setActiveKey(items.id);
                            }}
                            fromwallet={true}
                            activekey={activeKey}
                            showtagontop={true}
                          />
                        </div>
                      </Col>
                    );
                  }
              })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default WalletBigCoinCard;
