import * as _ from "lodash";
import React from "react";
import "@components/users-details-popup/UserDetailsPopupStyles.scss";
import { Modal, Container } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
const UserDetailsPopup = ({ setShowModal, data, show1 }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="usersdetailspopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>

      <CloseIcon
        className="closebtn hidden-for-mobile"
        onClick={() => setShowModal(false)}
      />
      <div
        className="back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
        <div className="transectiontype">
          <span className="transectiontext uppercase">{t("UserDetails")}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("UserID")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.id}</span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Name")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data.name}</span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("SubName")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data.subName}</span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Email")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.email}</span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CreatedOn")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">
                {moment(data?.createdOn).local().format("MM/DD/YYYY hh:mm A")}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Status")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">{t(`${data.status}`)}</span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Role")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{t(`${data.role}`)}</span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Region")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.region}</span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Language")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.language}</span>
            </div>
          </div>
          <div className="detail hidden-for-desktop">
            <div className="label">
              <span className="labeltext">{t("Action")}</span>
            </div>
            <div className="value">
              <div className="btnwidth">
                <div className="approve">
                  <CustomButton
                    name={t("Disable")}
                    filled={true}
                    primary={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(UserDetailsPopup);
