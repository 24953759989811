import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import QRCode from "react-qr-code";
import qronsignin from "@images/qronsignin.png";
import CustomButton from "src/components/custom-button/CustomButton";
import "@components/signin-qrpopup/SigninqrPopup.scss";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux'
const SigninqrPopup = ({
  setConfirmcodeModal,
  setShowModal,
  data,
  thememode,
  settings,
  show1,
  googleAuthData,
  googleAuth,
  googleAuthLoading
}) => {
  let { coin } = data || {};
  let { decimals, exchangeSymbol, explorerLink } = coin || {};
  const [googleAuthState, setGoogleAuthState] = useState({})

  const { t, i18n, ready } = useTranslation();
  // useEffect(() => {
  //   console.log('Google auth data -> ', googleAuth)
  //   setGoogleAuthState(googleAuthData)
  // }, [])

  // useEffect(() => {
  //   console.log('Google aut data changing :) -> ', googleAuth)
  // }, [googleAuth])

  // useEffect(() => {
  //   console.log('Google data loading -> ', googleAuthLoading)
  // }, [googleAuthLoading])
  return (
    <Modal
      className="SigninqrPopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn hidebtn"
          onClick={() => setShowModal(false)}
        />
        <Container>
          <div className="two-factor">
            <span className="text-twofact">{t("Twofactorauthentication")}</span>
          </div>

          <div className="descriptiontext">
            <span className="text-description">{t("pleaseusegoogleauth")}</span>
          </div>

          <div className="qrcode-section">
            <QRCode
              size="200"
              value={`${googleAuthData.otpUrl}`}
              bgColor={
                thememode == "light"
                  ? settings &&
                  settings.lightTheme &&
                  settings.lightTheme.bgwhite
                  : settings && settings.darkTheme && settings.darkTheme.bgwhite
              }
              fgColor={
                thememode == "light"
                  ? settings &&
                  settings.lightTheme &&
                  settings.lightTheme.textdarker
                  : settings &&
                  settings.darkTheme &&
                  settings.darkTheme.textdarker
              }
            />
            <div className="borderimg borderimg-1"></div>
            <div className="borderimg borderimg-2"></div>
            <div className="borderimg borderimg-3"></div>
            <div className="borderimg borderimg-4"></div>
          </div>

          <div className="addressofqr">
            <span className="addressqrtext">{googleAuth.base32}</span>
          </div>

          <Row>
            <Col lg={6} sm={6} xs={6} className="allignright">
              <div className="cancel">
                <CustomButton
                  name={t("Cancel")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
            <Col lg={6} sm={6} xs={6} className="allignleft">
              <div className="confirm">
                <CustomButton
                  name={t("OK")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                    setConfirmcodeModal(true);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const googleAuth = _.get(user, "googleAuthData", {})
  const googleAuthLoading = _.get(user, "googleAuthLoading", undefined)
  return {
    googleAuth,
    googleAuthLoading
  }
}

export default connect(mapStateToProps)(SigninqrPopup);
