export default {
  IDOTransferHistory: "История переводов IDO",
  TransactionID: "ID Транзакции",
  RoundNo: "Радунд Номер",
  BoughtOn: "Куплено",
  LockedFor: "Заблокировано до",
  UnlockDate: "Дата Разблокировки",
  TokensAllocated: "Выделено токенов",
  Status: "Статус",
  Action: "Действие",
  days: "дней",
  CYBOR: "CYBOR",
};
