import * as _ from "lodash";
import React from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/log-out/LogoutStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "./Forgotpasswordemailsuccess.scss";
import { useTranslation } from "react-i18next";
const Forgotpasswordemailsuccess = ({
  show1,
  setShowModal,
  onPress,
  address,
  amount,
  onClick,
  setHideThis,
  logouts,
}) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="forgotpasswordemailsuccess"
      show={show1}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext passwordtitle">
                {t("PasswordResetEmailSent")}
              </div>
              <hr />
            </Col>
            <Col lg={12}>
              <div className="success-content passcontent">
                <span className="titleText">
                  {t("EmailHasSent")}
                </span>{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="ok-btn">
                <CustomButton
                  name={t("OK")}
                  className="close-btn"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(Forgotpasswordemailsuccess);
