import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import Filter from "@components/filter/Filter.js";
import SearchBox from "@components/search-box/SearchBox";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as RightArrow } from "@images/rightarrow.svg";
import EditBranchpopup from "@components/edit-branchpopup/EditBranchpopup.js";
import "@features/branch-management/BranchManagementStyle.scss";
import EditLogo from "@images/branchmanagement/Edit.svg";
import DeleteLogo from "@images/branchmanagement/Delete.svg";
import "./BranchManagementStyle.scss";
import DeleteConfirmationPopup from "@components/delete-confirmationpopup/DeleteConfirmationPopup";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";

function BranchManagement(props) {
  const {
    settings,
    getcomissionper,
    listAllBranches,
    allBranchesLoading,
    allBranches,
    deleteBranch,
    updateBStatus,
    clearErrorMessage,
    hideLoaderOnRefresh,
  } = props || {};
  const history = useHistory();
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("fourth");
  let [deleteId, setDeleteId] = useState();
  let [searchName, setSearchName] = useState("");
  let [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [orderBy,setOrderBy] = useState('')
  const [sortBy,setSortBy] = useState('')
  const [obj,setObj] = useState('')
  let [editBranchPopup, setEditBranchPopup] = useState({
    show: false,
    branchId: "",
    branchDetails: "",
  });
  let [allFilteredBranches, setAllFilteredBranches] = useState([]);
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});

  useEffect(() => {
    listAllBranches({
      orderBy : 'id',
      sort : 'ASC',
    });
  }, []);
  useEffect(() => {
    listAllFilteredBranches(allBranches);
  }, [searchName]);

  useEffect(() => {
    // console.log("called clear");
    clearErrorMessage();
  }, []);

  useEffect(() => {
    listAllFilteredBranches(allBranches);
  }, [allBranchesLoading]);
 
  const listAllFilteredBranches = (allBranches) => {
    let filteredBranches =
      allBranches &&
      allBranches
        .filter((branch) => {
          if (
            branch.branchName.toLowerCase().includes(searchName.toLowerCase())
          ) {
            return branch;
          } else if (searchName === "") {
            return;
          }
        })
        .map((branch) => branch);

    setAllFilteredBranches(filteredBranches);
  };

  const DeleteBranch = (id) => {
    setShowDeleteConfirmation(true);
    setDeleteId(id);
  };

  const EditBranch = (id, branchDetails) => {
    setEditBranchPopup({
      show: true,
      branchId: id,
      branchDetails: branchDetails,
    });
  };

  const { t } = useTranslation();

  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      loaderOnRefresh={hideLoaderOnRefresh}
    >
      <div id="BranchManagement">
        <DeleteConfirmationPopup
          showDeleteConfirmation={showDeleteConfirmation}
          setShowDeleteConfirmation={setShowDeleteConfirmation}
          onPress={() => {
            setShowDeleteConfirmation(false);

            deleteBranch({ branchId: deleteId });
          }}
          onCancelled={() => {
            setShowDeleteConfirmation(false);
            setDeleteId("");
          }}
        />

        <EditBranchpopup
          setShowModal={setEditBranchPopup}
          show1={editBranchPopup.show}
          branchDetails={editBranchPopup.branchDetails}
        />
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("BranchManagement")}</span>
                  <div className="filtersection d-none d-lg-block">
                    <Filter
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="btmmargin">
                  <SearchBox setvalues={setSearchName} />
                </div>
                <div className="leftsection">
                  <div className="buttoncontainer">
                    <CustomButton
                      type="submit"
                      name={t("CreateBranch")}
                      filled={true}
                      primary={true}
                      onClick={() => history.push("/createbranch")}
                    />
                  </div>
                </div>
                <div className="filtersection d-block d-lg-none">
                  <Filter
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={(e) => setStartDate(e)}
                    setEndDate={(e) => setEndDate(e)}
                  />
                </div>
              </div>

              <Table responsive="sm" className=" tablestyle" borderless>
                {/* {allBranchesLoading && <Loader />}transfertbl */}
                <Loader loading={allBranchesLoading} />
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading paddingleft">{t("NO")}
                    <span className="sorticon">
                        <SortupIcon className="uparrow" onClick={()=>{
                            setOrderBy('id');
                            setSortBy('ASC');
                            listAllBranches({
                              orderBy : 'id',
                              sort : 'ASC',
                            })

                        }} />

                        <SortdownIcon className="downarrow"
                        onClick={()=>{
                          setOrderBy('id');
                          setSortBy('DESC');
                          listAllBranches({
                            orderBy : 'id',
                            sort : 'DESC',
                          })

                      }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      {t("Passive")}/{t("Active")}{" "}
                      <span className="sorticon">
                        <SortupIcon className="uparrow"
                        onClick={()=>{
                          setOrderBy('status');
                          setSortBy('ASC');
                          listAllBranches({
                            orderBy : 'status',
                            sort : 'ASC',
                          })

                      }}
                        />

                        <SortdownIcon className="downarrow"
                        
                        onClick={()=>{
                          setOrderBy('status');
                          setSortBy('DESC');
                          listAllBranches({
                            orderBy : 'status',
                            sort : 'DESC',
                          })

                      }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      {t("Account")}{" "}
                      <span className="sorticon">
                        <SortupIcon className="uparrow"
                         onClick={()=>{
                          setOrderBy('accountName');
                          setSortBy('ASC');
                          listAllBranches({
                            orderBy : 'accountName',
                            sort : 'ASC',
                          })

                      }}
                        />

                        <SortdownIcon className="downarrow"
                        onClick={()=>{
                          setOrderBy('accountName');
                          setSortBy('DESC');
                          listAllBranches({
                            orderBy : 'accountName',
                            sort : 'DESC',
                          })

                      }}
                        
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      {t("Branch")}{" "}
                      <span className="sorticon">
                        <SortupIcon className="uparrow"
                        onClick={()=>{
                          setOrderBy('branchName');
                          setSortBy('ASC');
                          listAllBranches({
                            orderBy : 'branchName',
                            sort : 'ASC',
                          })

                      }}
                        />

                        <SortdownIcon className="downarrow"
                        onClick={()=>{
                          setOrderBy('branchName');
                          setSortBy('DESC');
                          listAllBranches({
                            orderBy : 'branchName',
                            sort : 'DESC',
                          })

                      }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      {t("Phone")}{" "}
                      <span className="sorticon">
                        <SortupIcon className="uparrow"
                        onClick={()=>{
                          setOrderBy('phone');
                          setSortBy('ASC');
                          listAllBranches({
                            orderBy : 'phone',
                            sort : 'ASC',
                          })

                      }}
                        />

                        <SortdownIcon className="downarrow"
                        onClick={()=>{
                          setOrderBy('phone');
                          setSortBy('ASC');
                          listAllBranches({
                            orderBy : 'phone',
                            sort : 'ASC',
                          })

                      }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">{t("Address")}</th>
                    <th className="trtbleheading">{t("Edit")}    <span className="sorticon">
                          {/* <SortupIcon className="uparrow" />

                          <SortdownIcon className="downarrow" /> */}
                        </span></th>
                  </tr>
                </thead>

                <tbody>
                  {allFilteredBranches && allFilteredBranches.length === 0 ? (
                    <p className="trtbleheading">{t("Sorrynoresultsfound")}!</p>
                  ) : (
                    allFilteredBranches.map((branch) => (
                      <>
                        <tr className="trtbleheading maintdwrapper">
                          <td className="innercontent">
                            <div className="innerTd d-lg-flex d-none">
                              {" "}
                              {branch.id}
                            </div>
                            <div className="innerTd d-lg-none d-flex">
                              <p className="mobUpperline">
                                No{branch.id} {branch.accountName}
                              </p>
                              <p className="mobLowerline">
                                {branch.branchName} {branch.phone}
                              </p>
                            </div>
                          </td>
                          <td className="innercontent">
                            <div className="innerTd passive">
                              <Form.Check
                                type="switch"
                                checked={
                                  branch.status === "Active" ? true : false
                                }
                                checkedIcon={false}
                                onChange={(e) => {
                                  updateBStatus({
                                    status:
                                      branch.status === "Active"
                                        ? "Disabled"
                                        : "Active",
                                    branchId: branch.id,
                                  });
                                }}
                              />
                            </div>
                          </td>
                          <td className="amt d-none d-lg-table-cell">
                            <div className="innerTd id smallwidth">
                              {branch.accountName}
                            </div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd smallwidth">{branch.branchName}</div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd">{branch.phone}</div>
                          </td>
                          <td className="addr d-none d-lg-table-cell">
                            <div className="innerTd">{branch.address}</div>
                          </td>
                          <td className="innercontent">
                            <div className="buttonwrapper innerTd d-none d-lg-flex">
                              <img
                                src={EditLogo}
                                alt="Edit Logo"
                                className="editbutton"
                                onClick={() => EditBranch(branch.id, branch)}
                              />
                              <img
                                src={DeleteLogo}
                                alt="Delete Logo"
                                className="editbutton"
                                onClick={() => DeleteBranch(branch.id)}
                              />
                            </div>
                            <div className="innerTd d-flex d-lg-none">
                              <RightArrow
                                className="rightarrow"
                                onClick={() => EditBranch(branch.id, branch)}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))
                  )}
                </tbody>
              </Table>

              {allBranches.length > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("from")}{" "}
                      {Math.ceil(allBranches.length / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allBranches.length / limit}
                      // pageCount={2000}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        
                          
                          
                          listAllBranches({
                            orderBy : orderBy,
                            sort : sort,
                          })

                      
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({
  app = {},
  wallet = {},
  paymentlink = {},
  user = {},
  branchmanagementredux = {},
}) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const paymenthistory = _.get(paymentlink, "paymenthistory", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const myusertransactionloading = _.get(
    paymentlink,
    "paymenthistoryLoading",
    false
  );
  const getpaymenthistoryCount = _.get(
    wallet,
    "getpaymenthistoryCount.count",
    10
  );
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const paymentlinkmodal = _.get(app, "paymentlinkmodal", false);
  const paymentlinksuccmodal = _.get(app, "paymentlinksuccmodal", false);
  const paymentToken = _.get(paymentlink, "paymentToken", false);
  const createloaders = _.get(paymentlink, "createPaymentLoading", false);
  const paymentbytoken = _.get(paymentlink, "paymentbytoken", false);
  const commissionPercentage = _.get(
    paymentlink,
    "commissionPercentage",
    false
  );
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const allBranchesLoading = _.get(
    branchmanagementredux,
    "listAllBranchesLoading",
    false
  );
  const allBranches = _.get(branchmanagementredux, "allBranches", []);
  const deleteBranchStarted = _.get(
    branchmanagementredux,
    "deleteBranchLoading",
    false
  );
  return {
    thememode,
    settings,
    mywalletsinfo,
    paymenthistory,
    myaccountandbalance,
    getpaymenthistoryCount,
    myusertransactionloading,
    getplatformTokensinfo,
    exchangeRates,
    paymentlinkmodal,
    paymentlinksuccmodal,
    paymentToken,
    createloaders,
    paymentbytoken,
    commissionPercentage,
    userAccessToken,
    allBranchesLoading,
    allBranches,
    deleteBranchStarted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),

  updateBStatus: (data) =>
    dispatch({ type: "updateBStatusCalled", payload: data }),

  listAllBranches: (data) => dispatch({ type: "listAllBranchesStart"  , payload: data} ),
  deleteBranch: (data) =>
    dispatch({ type: "deleteBranchStart", payload: data }),
  clearErrorMessage: (data) =>
    dispatch({ type: "clearBrachError", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchManagement);


