import * as _ from "lodash";
import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import cryptowalletqrpng from "@images/cryptowalletqrpng.png";
import moment from "moment";
import QRCode from "react-qr-code";
import copy from "@images/copy1.svg";
import amount from "@images/amount1.svg";
import share from "@images/share1.svg";
import { connect } from "react-redux";
import { useState } from "react";
import "@components/qr-cryptowallet/QrcryptoWalletStyle.scss";
import { useTranslation } from "react-i18next";

const QrcryptoWallet = ({
  setShowModal,
  data,
  thememode,
  settings,
  show1,
  address,
  tokenName,
  setdepositModal,
  setsharem,
  setamountModal,
}) => {
  let { coin } = data || {};
  let { decimals, exchangeSymbol, explorerLink } = coin || {};
  const [copied, setcopied] = useState(false);

  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="QrcryptoWallet"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
        <Container>
          <div className="receivetxt">
            <span className="rcctxt">
              {t("Receive")} {tokenName}
            </span>
          </div>

          <div className="imagesection">
            <QRCode
              size="180"
              value={address}
              bgColor={
                thememode == "light"
                  ? settings.lightTheme.bgwhite
                  : settings.darkTheme.bgwhite
              }
              fgColor={
                thememode == "light"
                  ? settings.lightTheme.textdarker
                  : settings.darkTheme.textdarker
              }
            />
          </div>

          <div className="bottomtext">
            <span className="lineone">
              {t("My")} {tokenName} {t("WalletNumber")}:
            </span>
            <div className="addcopy">
              <div className="address">
                <span className="addresstext">{address}</span>
              </div>
              {/* <div
                className="copy"
                onClick={() => {
                  setcopied(true);
                  navigator.clipboard.writeText(address);
                }}
              >
                <div className="copytext pointer">
                  {copied ? t(`${"Copied"}`) : t(`${"Copy"}`)}
                </div>
              </div> */}
            </div>
            <div className="options">
              <div className="optiontxtholder1">
                <img
                  onClick={() => {
                    setcopied(true);
                    navigator.clipboard.writeText(address);
                  }}
                  src={copy}
                  className="comtxt"
                />
                <div
                  className="comtxt1"
                  onClick={() => {
                    setcopied(true);
                    navigator.clipboard.writeText(address);
                  }}
                >
                  {copied ? t(`${"Copied"}`) : t(`${"Copy"}`)}
                </div>
              </div>
              <div className="optiontxtholder1">
                <img
                  src={amount}
                  className="comtxt"
                  onClick={() => {
                    setdepositModal(false);

                    setamountModal(true);
                  }}
                />
                <div className="comtxt1">{t("Amount")}</div>
              </div>

              <div className="optiontxtholder1">
                <img
                  src={share}
                  className="comtxt"
                  onClick={() => {
                    setdepositModal(false);
                    setsharem(true);
                  }}
                />
                <div className="comtxt1">{t("Share")}</div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ app = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);

  return { thememode, settings };
};

export default connect(mapStateToProps, null)(QrcryptoWallet);
