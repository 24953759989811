import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import axios from "axios";
import Loader from "@components/loader/Loader";
import { connect } from "react-redux";
import NavBar from "@components/navbar/NavBar";
import "@features/onboarding/verify-link/VerifyLinkScreenStyles.scss";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import { useTranslation } from "react-i18next";

const VerifyLinkScreen = (props) => {
  const { exchange, role, thememode } = props;
  const { t, i18n, ready } = useTranslation();
  const [verifyScreenState, setVerifyScreenState] = useState({
    result: "",
    active: true,
    verify: false,
  });

  useEffect(() => {
    let query = props && props.location.search;
    let part = query.split("=");
    if (part[part.length - 1] == "English") {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
    }
    if (part[part.length - 1] == "Russian") {
      localStorage.setItem("lang", "ru");
      i18n.changeLanguage("ru");
    }
    if (part[part.length - 1] == "Turkish") {
      localStorage.setItem("lang", "tr");
      i18n.changeLanguage("tr");
    }
    axios
      .get(
        `${process.env.REACT_APP_APILINK}/manager/getexchangedetail?exchangeUrl=${process.env.REACT_APP_SITELINK}`
      )
      .then((responses) => {
        axios
          .get(
            `${process.env.REACT_APP_APILINK}/auth/user/confirm${query}&exchangeId=${responses.data.exchange.id}`
          )
          .then((res) => {
            setVerifyScreenState({
              result: "accountVerified",
              active: false,
              verify: true,
            });
          })

          .catch((err) => {
            setVerifyScreenState({
              result: "link_expired",
              active: false,
              verify: false,
            });
          });
      });
  }, []);

  return (
    <div id="VerifyLinkScreen">
      <NavBar location={props && props.location} />
      {verifyScreenState.active && <Loader msg={t("Creating_Account")} />}
      {!verifyScreenState.active && (
        <Container>
          <div className="outerbox whitebg">
            {role == "Business" && (
              <>
                {/* <img
            alt=""
            className="img"
            src={
              thememode == "dark"
              ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessDarkmode}`
              : `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessLightmode}`
                }
          /> */}
                <img src={paymentsolutionlogo} className=" img" />
              </>
            )}
            {role != "Business" && (
              <>
                {/* <img
            alt=""
            className="img"
            src={
              thememode == "light"
                ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalLightmode}`
                : `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalDarkmode}`
                }
          /> */}
                <img src={paymentsolutionlogo} className=" img " />
              </>
            )}
            <div className="bottomsection">
              {/* <div> {t(`${result}`)}</div> */}
              {/* {this.state.active && <Loader msg="We are creating cyrpto accounts for you. It may take 1-2 Minutes" />} */}
              <span className="outerconttxt spanmsg ">
                {t(`${verifyScreenState.result}`)}
              </span>
              {verifyScreenState.verify && (
                <div className="outerconforbtn">
                  <CustomButton
                    onClick={() => props.history.push("/signin")}
                    name={t("Sign_In")}
                    filled={true}
                    primary={true}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = ({ app = {}, user = {} }) => {
  const role = _.get(user, "userInfo.role", "");
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  return {
    role,
    exchange,
    thememode,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyLinkScreen);
