import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import { Modal, Container, Form, Col, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as DownIcon } from "@images/down.svg";
import process from "@images/process.gif";
import copy from "@images/copy.svg";
import "@components/upload-popup/UploadPopupStyle.scss";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomButton from "../custom-button/CustomButton";

function UploadPopup({
  show2,
  setShowModal,
  setshow2,
  setShowCreateLink,
  paymentbytoken,
  paymentToken,
  thememode,
  settings,
  currencyToReceive,
  currencyToConvert,
  getPaymentbytokenLink,
  hideicons,
}) {
  const [detectd, setdetectd] = useState(false);
  const [cancelled, setcancelled] = useState(false);

  useEffect(() => {
    setupWS();
  }, []);

  const setupWS = () => {
    let ws = new WebSocket("wss://paymentsolutionapi.sparkrex.com");
    ws.onopen = () => {
      console.log("connected");

      ws.send(JSON.stringify({ paymentId: paymentToken && paymentToken.link }));
      ws.onmessage = (e) => {
        let resp = JSON.parse(e.data);
        if (resp.paymentStatus == "Paid") {
          setdetectd(true);
        }
        if (resp.paymentStatus == "Cancelled") {
          setcancelled(true);
          setdetectd(true);
        }
      };
    };
    ws.onclose = () => {
      console.log("disconnected");
      setupWS();
    };
  };

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="UploadPopup"
      show={show2}
      onHide={() => {
        setshow2(false);
        // setShowModal(true);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {!hideicons && (
        <Modal.Header>
          <Modal.Title>
            <span className="exchangetitle">
              {t("LinkCreatedSuccessfully")}!
            </span>
          </Modal.Title>
        </Modal.Header>
      )}
      {!hideicons && (
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setshow2(false);
            setShowModal(false);
            setShowCreateLink(true);
          }}
        />
      )}
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={6}>
              <div className="qrcode-section">
                {/* <img src={biguploadqr} /> */}
                <QRCode
                  size="250"
                  className={"margin"}
                  value={`${
                    paymentbytoken &&
                    paymentbytoken.paymentaccount &&
                    paymentbytoken.paymentaccount.address
                  }`}
                  bgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.bgwhite
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.bgwhite
                  }
                  fgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.textdarker
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.textdarker
                  }
                />
              </div>
              <div className="qrcode-section-mobile">
                <QRCode
                  size="160"
                  className={"margin"}
                  value={`${
                    paymentbytoken &&
                    paymentbytoken.paymentaccount &&
                    paymentbytoken.paymentaccount.address
                  }`}
                  bgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.bgwhite
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.bgwhite
                  }
                  fgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.textdarker
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.textdarker
                  }
                />
              </div>
              <span className="mt-3">
                {paymentbytoken &&
                  paymentbytoken.paymentaccount &&
                  paymentbytoken.paymentaccount.address}
                <img
                  onClick={() => {
                    navigator.clipboard.writeText(
                      paymentbytoken &&
                        paymentbytoken.paymentaccount &&
                        paymentbytoken.paymentaccount.address
                    );
                    toast.success(t(`${"copied"}`), {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }}
                  src={copy}
                  className="copy"
                />
              </span>

              <div className="detail">
                <div className="label">
                  <span className="labeltext1">{t("Amount")}</span>
                </div>
                  <div className="value">
                    <span className="valuetext1">
                      {" "}
                      {paymentbytoken && paymentbytoken.currencyAmount}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                    </span>
                  </div>
              </div>

              <div className=" detail">
                <div className="label">
                  <span className="labeltext1">{t("Commission")}</span>
                </div>
                  <div className="value">
                    <span className="valuetext1">
                      {paymentbytoken && paymentbytoken.commissionAmount}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                    </span>
                  </div>
              </div>

              <div className=" detail">
                <div className="label">
                  <span className="labeltext1">{t("Total")}</span>
                </div>            
                  <div className="value">
                    <span className="valuetext1">
                      {" "}
                      {paymentbytoken &&
                        paymentbytoken.currencyAmountCommission}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                    </span>
                  </div>
              </div>
              <div className=" detail">
                <div className="label">
                  <span className="labeltext1">{t("AmountToPay")}</span>
                </div>
                  <div className="value">
                    <span className="valuetext1">
                      {" "}
                      {isNaN(currencyToReceive) ? "" : currencyToReceive}{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                    </span>
                  </div>
              </div>
            </Col>

            <Col lg={6}>
              <div className="detail">
                <div className="label">
                  <span className="labeltext">{t("PaymentID")}</span>
                </div>
                  <div className="value">
                    <span className="valuetext">
                      {" "}
                      #{paymentbytoken.paymentLinkToken}
                    </span>
                  </div>
              </div>

              <div className="detail ">
                <div className="label">
                  <span className="labeltext">{t("Date")}</span>
                </div>
                <div className="value">
                  <span className="valuetext">
                    {moment(paymentbytoken.createdOn).format(
                      "MM/DD/YYYY hh:mm A"
                    )}
                  </span>
                </div>
              </div>

              <div className="detail">
                <div className="label">
                  <span className="labeltext">{t("Recepient")}</span>
                </div>
                <div className="value">
                  {paymentbytoken.toPaymentLinkWallet &&
                    paymentbytoken.toPaymentLinkWallet.user && (
                        <span className="valuetext">
                          {" "}
                          {paymentbytoken.toPaymentLinkWallet.user.name}{" "}
                          {paymentbytoken.toPaymentLinkWallet.user.subName}
                        </span>
                    )}
                </div>
              </div>

              <div className="currencytoconvert">
                <div className="box">
                  <div className="link">
                    <span className="linktxt1">
                      {t("PaymentLinkwillbecreated")}:
                    </span>
                    <span className="linktxt">{t("ClientPays")}:</span>
                  </div>
                  <div className="commonsection">
                    <span className="textline1">
                      {" "}
                      {paymentbytoken &&
                        paymentbytoken.currencyAmountCommission}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                    </span>
                    <span className="textline2">
                      {paymentbytoken && paymentbytoken.currency.currencyName}
                    </span>
                  </div>
                  <div className="imgsection1">
                    <DownIcon className="arrowclass11" />
                  </div>
                  <div className="link">
                    <span className="linktxt">{t("CurrencyToConvert")}:</span>
                  </div>
                  {/* </div> */}
                  <div className="commonsection">
                    <span className="textline1">
                      {isNaN(currencyToReceive) ? "" : currencyToReceive}{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                    </span>
                    {/* <span className="textline2">(1 BTC = 500000 TRY)</span> */}
                    <span className="textline2">
                      {" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}{" "}
                      -{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.tokenName}
                    </span>
                  </div>

                  <div className="imgsection1">
                    <DownIcon className="arrowclass11" />
                  </div>
                  <div className="link">
                    <span className="linktxt">{t("CurrencytoReceive")}:</span>
                  </div>
                  <div className="commonsection">
                    <span className="textline1">
                      {isNaN(currencyToConvert) ? "" : currencyToConvert}{" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.exchangeSymbol}
                    </span>
                    {/* <span className="textline2">(1 USDT = 0,00543 BTC)</span> */}
                    <span className="textline2">
                      {" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.exchangeSymbol}{" "}
                      -{" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.tokenName}
                    </span>

                    {/* <span className="textline1">50.00 USDT</span>
                    <span className="textline2">(1 USDT = 0,00543 BTC)</span>
                    <span className="textline2">USDT - Tether</span> */}
                  </div>
                </div>
              </div>
              <div className=" detail3">
                {!detectd && <img src={process} className="progresses"></img>}
                <div className="status">
                  <span className={detectd ? "textofstatus" : "textofstatus2"}>
                    {detectd
                      ? cancelled
                        ? t("Cancelled")
                        : t("PaymentSuccessfully")
                      : t("PAYMENT_REQUESTED")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
export default React.memo(UploadPopup);
