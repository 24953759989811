import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import DarkLightToogle from "../dark-light-toogle/DarkLightToogle";
import SmallDropdown from "@components/small-dropdown/SmallDropdown";
import { useTranslation } from "react-i18next";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import { ReactComponent as CollapseIcon } from "@images/sidebar/collapse.svg";
import { ReactComponent as DashboardIcon } from "@images/sidebar/dashhome.svg";
import { ReactComponent as BalanceIcon } from "@images/sidebar/balance.svg";
import { ReactComponent as MywalletIcon } from "@images/sidebar/mywallet1.svg";
import { ReactComponent as SettingIcon } from "@images/sidebar/psetting.svg";
import { ReactComponent as TransactionIcon } from "@images/sidebar/transaction1.svg";
import { ReactComponent as IdoIcon } from "@images/sidebar/idoicon.svg";
import { ReactComponent as CpsIcon } from "@images/sidebar/cyborprotection.svg";
import { ReactComponent as MarketIcon } from "@images/sidebar/marketplace.svg";
import { ReactComponent as Reports } from "@images/sidebar/reports.svg";
import { ReactComponent as BranchIcon } from "@images/branchmang.svg";
import { ReactComponent as CreatePaymentLink } from "@images/createpaymentlink.svg";
import { ReactComponent as AddBank } from "@images/Addbank.svg";
import { ReactComponent as Business } from "@images/business.svg";
import { ReactComponent as CryptoIcon } from "@images/sidebar/crypto card.svg";
import { ReactComponent as RateIcon } from "@images/sidebar/rate.svg";
import { ReactComponent as FiatLoan } from "@images/sidebar/personal loan.svg";
import { ReactComponent as CryptoLoan } from "@images/sidebar/crypto loan.svg";
import { ReactComponent as Account } from "@images/sidebar/account.svg";

import "@components/left-sidebar/LeftSidebarStyles.scss";

const LeftSidebar = ({
  exchange,
  role,
  thememode,
  updateProfileLanguage,
  branch,
  sidebarbackground,
}) => {
  // const Sidebar = ({ role, thememode }) => {
  // let role = "Business";
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isReportsSubMenuOpen, setIsReportsSubMenuOpen] = useState(
    window.location.pathname === "/paymentreport"
  );

  const [isTransactionSubMenuOpen, setIsTransactionSubMenuOpen] = useState(
    window.location.pathname === "/paymentscreen" ||
      window.location.pathname === "/completedtransaction" ||
      window.location.pathname === "/pendingtransaction" ||
      window.location.pathname === "/cancelledtransaction" ||
      window.location.pathname === "/transferhistory" ||
      window.location.pathname === "/exchangehistory" ||
      window.location.pathname === "/depositwithdraw"
  );
  const [isWalletSubMenuOpen, setIsWalletSubMenuOpen] = useState(
    window.location.pathname === "/cryptowallet" ||
      window.location.pathname === "/fiatwallet"
  );
  const [isBankSubMenuOpen, setIsBankSubMenuOpen] = useState(
    window.location.pathname === "/bankrequest" ||
      window.location.pathname === "/adminallbanks" ||
      window.location.pathname === "/adminfiatdeposit" ||
      window.location.pathname === "/adminfiatwithdraw"
  );
  const [isCryptoLoanSubMenuOpen, setIsCryptoLoanSubMenuOpen] = useState(
    window.location.pathname === "/bankrequest" ||
      window.location.pathname === "/adminLoanManagement" ||
      window.location.pathname === "/loanRequest" ||
      window.location.pathname === "/loan"
  );
  const [isBankLoanSubMenuOpen, setIsBankLoanSubMenuOpen] = useState(
    window.location.pathname === "/adminPersonalLoanManagementt" ||
      window.location.pathname === "/personalLoanRequest" ||
      window.location.pathname === "/personalLoan"
  );

  const [isToogleOn, setIsToggleOn] = useState(
    role == "Personal" ? true : false
  );
  const menuClasses = {
    root: "ps-menu-root",
    menuItemRoot: "ps-menuitem-root",
    subMenuRoot: "ps-submenu-root",
    button: "ps-menu-button",
    prefix: "ps-menu-prefix",
    suffix: "ps-menu-suffix",
    label: "ps-menu-label",
    icon: "ps-menu-icon",
    subMenuContent: "ps-submenu-content",
    SubMenuExpandIcon: "ps-submenu-expand-icon",
    disabled: "ps-disabled",
    active: "ps-active",
    open: "ps-open",
  };
  let location = useLocation();
  const auth = useSelector((state) => state.user);
  let { MyProfileInfo } = auth || {};
  const { t, i18n, ready } = useTranslation();

  const languages = [
    {
      key: "ENG",
      fullLanguageName: "English",
      apiKey: "en",
    },
    {
      key: "TUR",
      fullLanguageName: "Turkish",
      apiKey: "tr",
    },
    {
      key: "RUS",
      fullLanguageName: "Russian",
      apiKey: "ru",
    },
  ];
  const [language, setLanguage] = useState({
    key: "ENG",
    fullLanguageName: "English",
    apiKey: "en",
  });

  let selectedLAngarray = languages.filter(
    (itm) => itm.apiKey === localStorage.getItem("lang")
  );
  let selectLang =
    (selectedLAngarray &&
      selectedLAngarray[0] &&
      selectedLAngarray[0].fullLanguageName) ||
    "English";

  return (
    <div
      className={`Sidebar ${
        thememode == "dark" ? "chgsidebartodark" : "chgsidebartolight"
      }`}
    >
      <nav id="sidebarnav">
        {(role == "Business" && (
          <img src={paymentsolutionlogo} className="sidelogo" />
        )) || <img src={paymentsolutionlogo} className="sidelogo" />}
        <Sidebar backgroundColor={sidebarbackground}>
          <Menu>
            <MenuItem
              component={<Link to="/" />}
              active={window.location.pathname === "/"}
            >
              <span className="item-icon">
                <DashboardIcon className="sidebaricon2 homeicon" />
              </span>
              <span className="item-text">{t("Dashboard")}</span>
            </MenuItem>
            <MenuItem
              component={<Link to="/mybalances" />}
              className={activeMenuItem === "mybalances" ? "active" : ""}
              active={window.location.pathname === "/mybalances"}
            >
              <span className="item-icon">
                <BalanceIcon className="sidebaricon-admin" />
              </span>
              <span className="balances">{t("MyBalances")}</span>
            </MenuItem>
            {role == "Business" && (
              <MenuItem
                component={<Link to="/mywallet" />}
                active={window.location.pathname === "/mywallet"}
              >
                <span className="item-icon">
                  <MywalletIcon className="sidebaricon2 homeicon" />
                </span>
                <span className="item-text">{t("MyWallet")}</span>
              </MenuItem>
            )}

            {role !== "Business" && (
              <SubMenu
                icon={<MywalletIcon className="sidebaricon1" />}
                label={t("MyWallet")}
                open={isWalletSubMenuOpen}
                onClick={() => setIsWalletSubMenuOpen(!isWalletSubMenuOpen)}
              >
                <>
                  <MenuItem
                    component={<Link to="/cryptowallet" />}
                    active={window.location.pathname === "/cryptowallet"}
                  >
                    <span className="item-text">{t("CryptoWallet")}</span>
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/fiatwallet" />}
                    active={window.location.pathname === "/fiatwallet"}
                  >
                    <span className="item-text">{t("FiatWallet")}</span>
                  </MenuItem>
                </>
              </SubMenu>
            )}
            {(role == "Business" || role == "Admin") && (
              <MenuItem
                component={<Link to="/createpaymentlink" />}
                active={window.location.pathname === "/createpaymentlink"}
              >
                <span className="item-icon">
                  <CreatePaymentLink className="sidebaricon2" />
                </span>
                <span className="item-text">{t("CreatePaymentLink")}</span>
              </MenuItem>
            )}

            <SubMenu
              icon={<TransactionIcon className="sidebaricon" />}
              label={t("Transactions")}
              open={isTransactionSubMenuOpen}
              onClick={() =>
                setIsTransactionSubMenuOpen(!isTransactionSubMenuOpen)
              }
            >
              {" "}
              {(role == "Business" || role == "Admin") && (
                <>
                  <MenuItem
                    component={<Link to="/paymentscreen" />}
                    active={window.location.pathname === "/paymentscreen"}
                  >
                    <span className="item-text"> {t("PaymentHistory")}</span>
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/completedtransaction" />}
                    active={
                      window.location.pathname === "/completedtransaction"
                    }
                  >
                    <span className="item-text">
                      {" "}
                      {t("CompletedTransaction")}
                    </span>
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pendingtransaction" />}
                    active={window.location.pathname === "/pendingtransaction"}
                  >
                    <span className="item-text">
                      {" "}
                      {t("PendingTransaction")}
                    </span>
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/cancelledtransaction" />}
                    active={
                      window.location.pathname === "/cancelledtransaction"
                    }
                  >
                    <span className="item-text">
                      {" "}
                      {t("CancelledTransaction")}
                    </span>
                  </MenuItem>
                </>
              )}
              {role == "Personal" && (
                <MenuItem
                  component={<Link to="/transferhistory" />}
                  active={window.location.pathname === "/transferhistory"}
                >
                  <span className="item-text">{t("TransferHistory")}</span>
                </MenuItem>
              )}
              {/* {(role == "Personal" || role == "Business") &&( */}
              {/* <MenuItem
                 component={<Link to="/loan" />}
                 active={window.location.pathname === "/loan"}
               >
                 <span className="item-text"> Borrow Market</span>
               </MenuItem> */}
              {/* ) } */}
              <MenuItem
                component={<Link to="/exchangehistory" />}
                active={window.location.pathname === "/exchangehistory"}
              >
                <span className="item-text"> {t("ExchangeHistory")}</span>
              </MenuItem>
              <MenuItem
                component={<Link to="/depositwithdraw" />}
                active={window.location.pathname === "/depositwithdraw"}
              >
                <span className="item-text">
                  {t("Deposit")}/{t("Withdrawals")}
                </span>
              </MenuItem>
            </SubMenu>
            {role === "Admin" ? (
              <MenuItem
                component={<Link to="/cryptocards" />}
                active={window.location.pathname === "/cryptocards"}
              >
                <span className="item-icon">
                  <CryptoIcon className="sidebaricon1" />
                </span>
                <span className="item-text">{t("Cryptocards")}</span>
              </MenuItem>
            ) : (
              <MenuItem
                component={<Link to="/crypto-cards" />}
                active={window.location.pathname === "/crypto-cards"}
              >
                <span className="item-icon">
                  <CryptoIcon className="sidebaricon1" />
                </span>
                <span className="item-text">{t("Cryptocards")}</span>
              </MenuItem>
            )}
            {(role == "Admin" || role == "Personal") && (
              <SubMenu
                icon={<CryptoLoan className="sidebaricon" />}
                label={t("DefiLoans")}
                open={isCryptoLoanSubMenuOpen}
                onClick={() =>
                  setIsCryptoLoanSubMenuOpen(!isCryptoLoanSubMenuOpen)
                }
              >
                {" "}
                {(role == "Admin" || role == "Personal") && (
                  <>
                    {role == "Admin" && (
                      <MenuItem
                        component={<Link to="/adminLoanManagement" />}
                        active={
                          window.location.pathname === "/adminLoanManagement"
                        }
                      >
                        <span className="item-text"> {t("Loan Types")}</span>
                      </MenuItem>
                    )}
                    <MenuItem
                      component={<Link to="/loanRequest" />}
                      active={window.location.pathname === "/loanRequest"}
                    >
                      <span className="item-text"> {t("Loanrequests")}</span>
                    </MenuItem>
                  </>
                )}
                {role == "Personal" && (
                  <MenuItem
                    component={<Link to="/loan" />}
                    active={window.location.pathname === "/loan"}
                  >
                    <span className="item-text">{t("Borrow")}</span>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {(role == "Admin" || role == "Personal") && (
              <SubMenu
                icon={<FiatLoan className="sidebaricon" />}
                label={t("BankLoan")}
                open={isBankLoanSubMenuOpen}
                onClick={() => setIsBankLoanSubMenuOpen(!isBankLoanSubMenuOpen)}
              >
                {" "}
                {(role == "Admin" || role == "Personal") && (
                  <>
                    {role == "Admin" && (
                      <MenuItem
                        component={<Link to="/adminPersonalLoanManagement" />}
                        active={
                          window.location.pathname ===
                          "/adminPersonalLoanManagement"
                        }
                      >
                        <span className="item-text"> {t("Loan Types")}</span>
                      </MenuItem>
                    )}
                    <MenuItem
                      component={<Link to="/personalLoanRequest" />}
                      active={
                        window.location.pathname === "/personalLoanRequest"
                      }
                    >
                      <span className="item-text"> {t("Loanrequests")}</span>
                    </MenuItem>
                  </>
                )}
                {role == "Personal" && (
                  <MenuItem
                    component={<Link to="/personalLoan" />}
                    active={window.location.pathname === "/personalLoan"}
                  >
                    <span className="item-text">{t("Borrow")}</span>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {(role == "Business" || role == "Admin") && (
              <SubMenu
                icon={
                  <Reports
                    className={`sidebaricon ${
                      isReportsSubMenuOpen ? "open" : ""
                    }`}
                  />
                }
                label={t("Reports")}
                open={isReportsSubMenuOpen}
                onClick={() => setIsReportsSubMenuOpen(!isReportsSubMenuOpen)}
              >
                <MenuItem
                  component={<Link to="/paymentreport" />}
                  active={window.location.pathname === "/paymentreport"}
                >
                  <span className="item-text"> {t("PaymentReport")}</span>
                </MenuItem>
              </SubMenu>
            )}
            {!branch && role == "Business" && (
              <MenuItem
                component={<Link to="/branchmanagement" />}
                active={window.location.pathname === "/branchmanagement"}
              >
                <span className="item-icon">
                  <BranchIcon className="sidebaricon" />
                </span>
                <span className="item-text">{t("BranchManagement")}</span>
              </MenuItem>
            )}

            <MenuItem
              component={<Link to="/marketrates" />}
              active={window.location.pathname === "/marketrates"}
            >
              <span className="item-icon">
                <MarketIcon className="sidebaricon" />
              </span>
              <span className="item-text">{t("MarketRates")}</span>
            </MenuItem>
            <MenuItem
              component={<Link to="/transak" />}
              active={window.location.pathname === "/transak"}
            >
              <span className="item-icon">
                <CpsIcon className="sidebaricon" />
              </span>
              <span className="item-text">{t("Buysellcrypto")}</span>
            </MenuItem>
            <MenuItem
              component={<Link to="/personaldashsetting" />}
              active={window.location.pathname === "/personaldashsetting"}
            >
              <span className="item-icon">
                <SettingIcon className="sidebaricon" />
              </span>
              <span className="item-text">{t("PersonalSettings")}</span>
            </MenuItem>
            {role == "Admin" && (
              <MenuItem
                component={<Link to="/businessrequest" />}
                active={window.location.pathname === "/businessrequest"}
              >
                <span className="item-icon">
                  <Business className="sidebaricon-admin" />
                </span>
                <span className="item-text"> {t("BusinessRequest")}</span>
              </MenuItem>
            )}
            {role == "Admin" && (
              <SubMenu
                icon={<AddBank className="sidebaricon" />}
                label={t("AddBankRequest")}
                open={isBankSubMenuOpen}
                onClick={() => setIsBankSubMenuOpen(!isBankSubMenuOpen)}
              >
                <MenuItem
                  component={<Link to="/bankrequest" />}
                  active={window.location.pathname === "/bankrequest"}
                >
                  <span className="item-text"> {t("BankRequest")}</span>
                </MenuItem>
                <MenuItem
                  component={<Link to="/adminallbanks" />}
                  active={window.location.pathname === "/adminallbanks"}
                >
                  <span className="item-text"> {t("AdminBanks")}</span>
                </MenuItem>
                <MenuItem
                  component={<Link to="/adminfiatdeposit" />}
                  active={window.location.pathname === "/adminfiatdeposit"}
                >
                  <span className="item-text"> {t("FiatDepositRequest")}</span>
                </MenuItem>
                <MenuItem
                  component={<Link to="/adminfiatwithdraw" />}
                  active={window.location.pathname === "/adminfiatwithdraw"}
                >
                  <span className="item-text"> {t("FiatWithdrawRequest")}</span>
                </MenuItem>
              </SubMenu>
            )}
            {role == "Admin" && (
              <MenuItem
                component={<Link to="/userslist" />}
                active={window.location.pathname === "/userslist"}
              >
                <span className="item-icon">
                  <Account className="sidebaricon" />
                </span>
                <span>{t("Users") + " " + t("List")}</span>
              </MenuItem>
            )}
          </Menu>
        </Sidebar>
      </nav>
      <hr className="hrbelownav" />
      <div className="themeswitchwrapper d-flex d-lg-none">
        <DarkLightToogle />
        <div className="d-inline-block d-lg-none">
          <SmallDropdown
            options={languages}
            setSelectedOption={(e) => {
              languages.map((data) => {
                if (data.key == e) {
                  localStorage.setItem("lang", data.apiKey);
                  i18n.changeLanguage(data.apiKey);
                  updateProfileLanguage({ language: data.apiKey });
                  setLanguage(data);
                }
              });
            }}
            selectedOption={
              languages.filter((data) => data.fullLanguageName == selectLang)[0]
                ?.apiKey
            }
          />
        </div>
      </div>
      <div className="themeswitchwrapper textformode d-flex d-lg-none">
        <p className="modetexts">{t("Darkmode")}</p>
        <p className="modetexts">{t("Lightmode")}</p>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user = {}, app = {} }) => {
  const role = _.get(user, "userInfo.role", "");
  const thememode = _.get(app, "thememode", false);
  const branch = _.get(user, "userInfo.branch", false);
  const exchange = _.get(app, "settings.exchange", false);
  const sidebarbackground = _.get(app, "settings.darkTheme.bgdarkblack", false);

  return { role, thememode, branch, exchange, sidebarbackground };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfileLanguage: (data) =>
    dispatch({ type: "updateProfile", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
