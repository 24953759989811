import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import Filter from "@components/filter/Filter.js";
import SearchBox from "@components/search-box/SearchBox";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as RightArrow } from "@images/rightarrow.svg";
import EditBranchpopup from "@components/edit-branchpopup/EditBranchpopup.js";
import "@features/admin-loan-management/AdminLoanManagement.scss";
import EditLogo from "@images/branchmanagement/Edit.svg";
import DeleteLogo from "@images/branchmanagement/Delete.svg";
import VerticleIcon from "@images/Vertical.svg";
import "@features/loan-request/LoanRequest.scss";
import DeleteConfirmationPopup from "@components/delete-confirmationpopup/DeleteConfirmationPopup";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import moment from "moment";
import LoanRequestDetailPopup from "src/components/loan-request-detail-popup/LoanRequestDetailPopup";
import CoinList from "src/components/coin-list/CoinList";

function LoanRequest({
  hideLoaderOnRefresh,
  loanRequestLoad,
  allLoans,
  loanRequestError,
  loanRequestListAsync,
  approvalLoading,
  approvalError,
  approveLoanRequestAsync,
  role,
}) {
  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState("");
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [skip , setskip] = useState(0);

  useEffect(() => {
    loanRequestListAsync({skip , limit});
  }, []);

  const Approve = (id) => {
    setShowDeleteConfirmation(true);
    setDeleteId(1);
  };


  const { t } = useTranslation();

  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      loaderOnRefresh={hideLoaderOnRefresh}
    >
      <div id="LoanRequestManagement">
        <LoanRequestDetailPopup
          show1={showDetail}
          setShowModal={setShowDetail}
          data={data}
          setData = {setData}
        />

        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("Loanrequests")}</span>
                  <div className="filtersection d-none d-lg-block">
                    {/* <Filter
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="actions">
               
                <div className="filtersection d-block d-lg-none">
                
                </div>
              </div>

              <Table responsive="sm" className=" tablestyle" borderless>
                {(loanRequestLoad  || approvalLoading) && <Loader />}
                {/* <Loader loading={allBranchesLoading} /> */}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading paddingleft">{t("LoanID")}</th>
                    <th className="trtbleheading">{t("Name")} </th>
                    <th className="trtbleheading">{t("BorrowedAmount")} </th>
                    <th className="trtbleheading">{t("BorrowedCoin")} </th>
                  
                    <th className="trtbleheading">{t("Tenure")} {(t("InDays"))} </th>
                    
                    <th className="trtbleheading">{t("InitialLTV")} </th>
                    <th className="trtbleheading">{t("LiquidationLTV")} </th>
                    <th className="trtbleheading">{t("CreationDate")} </th>
                    {/* {role === "Admin"  &&
                    <th className="trtbleheading">{t("Approve/Reject")} </th>
                } */}
                    <th className="trtbleheading">{t("Details")}</th>
                  </tr>
                </thead>

                <tbody>
                  <>
                  { allLoans !== undefined && allLoans.count > 0 && allLoans.data.map((item)=>(
                    <tr className="trtbleheading maintdwrapper">
                      <td className="innercontent">
                        <div className="innerTd d-lg-flex d-none">{item.id}</div>
                        <div className="innerTd d-lg-none d-flex">
                              <p className="mobUpperline">
                                No{item?.id} {item?.loanType?.loanName}
                              </p>
                              <p className="mobLowerline">
                              {Math.trunc((item?.loanType?.tenureInHours)/24)} days
                              </p>
                            </div>
                      </td>
                      <td className="amt d-none d-lg-table-cell">
                        <div className="innerTd id smallwidth">{item?.loanType?.loanName}</div>
                      </td>
                      <td className="amt d-none d-lg-table-cell">
                        <div className="innerTd id smallwidth">    {item !== undefined && parseFloat(item?.borrowedAmount).toFixed(4)}</div>
                      </td>
                     
                      <td className="amt d-none d-lg-table-cell">
                        <div className="innerTd table-coin">
                      
                          <CoinList
                            type={item?.borrowedCoin?.type}
                            logo={item?.borrowedCoin?.nonColouredLogo}
                            name={item?.borrowedCoin?.tokenName}
                            logoBgColor={item?.borrowedCoin.logoBgColor}
                          />

                          {/* {
                            <span className="cointag">
                              {item?.borrowedCoin?.type == "ETH"
                                ? "ERC20"
                                : item?.borrowedCoin?.type == "BSC"
                                ? "BEP20"
                                : item.borrowedCoin?.type == "TRON"
                                ? "TRC20"
                                : item?.borrowedCoin?.type}
                            </span>
                          } */}
                          </div>
                      </td>
                      {/* <td className="amt d-none d-lg-table-cell">
                        <div className="innerTd table-coin">
                          {item !== undefined && parseFloat(item.lockedCapitalAmount).toFixed(4)}
                          <CoinList
                            type={item?.collateralCoin?.type}
                            logo={item?.collateralCoin?.nonColouredLogo}
                            name={item?.collateralCoin?.tokenName}
                            logoBgColor={item?.collateralCoin.logoBgColor}
                          />

                         
                          </div>
                      </td> */}
                      <td className="innercontent d-none d-lg-table-cell">
                        <div className="innerTd smallwidth">{Math.trunc((item?.loanType?.tenureInHours)/24)}</div>
                      </td>
                      
                      <td className="innercontent d-none d-lg-table-cell">
                        <div className="innerTd">{item?.initialLTV !== null ? `${item?.initialLTV}%` : "-"}</div>
                      </td>
                      <td className="innercontent d-none d-lg-table-cell">
                        <div className="innerTd">{item?.liquidationLTV !== null ? `${item.liquidationLTV}%` : "-"}</div>
                      </td>
                      <td className="innercontent d-none d-lg-table-cell">
                        <div className="innerTd smallwidth"> {moment(item?.createdOn).local().format("MM/DD/YYYY hh:mm A")}</div>
                      </td>
                      {/* {role !== undefined && role === "Admin" &&
                      <td className="innercontent">
                        <div className="innerTd passive">
                          <Form.Check
                            type="switch"
                            checked={
                              
                              item?.adminApproval=== true ? true : false
                            }
                            checkedIcon={false}
                            onChange={(e) => {
                              approveLoanRequestAsync({
                                // status:
                                //   branch.status === "Active"
                                //     ? "Disabled"
                                //     : "Active",
                                // branchId: branch.id,

                                approve : item?.adminApproval === true? false : true , 
                                id : item?.id
                              });
                            }}
                          />
                        </div>
                      </td>
} */}
                      <td className="innercontent">
                        <div className="buttonwrapper innerTd d-none d-lg-flex">
                          <Dropdown>
                            <Dropdown.Toggle
                              className="threedot"
                              id="dropdown-basic"
                            >
                              <img
                                src={VerticleIcon}
                                alt="Delete Logo"
                                className="editbutton"
                                // onClick={() => DetailsPopup(branch.id)}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="menu">
                              <Dropdown.Item
                                onClick={() => {
                                  //  setcoinItem(item);
                                  setData(item);
                                  setShowDetail(true);

                                }}
                              >
                                {t("Details")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  )) }  {allLoans !== undefined && allLoans.count == 0 && (
                    <p className="trtbleheading">{t("Sorrynoresultsfound")}!</p>
                  )
                    
}
                  </>
                </tbody>
              </Table>
              {allLoans !== undefined && allLoans.count > 10 && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(allLoans.count / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allLoans.count / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={(e) => {
                        setskip(e.selected * limit);
                        loanRequestListAsync({
                          skip: e.selected * limit,
                          limit,
                        });

                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, wallet = {} , user={} }) => {
  const thememode = _.get(app, "thememode", false);
  const role = _.get(user, "userInfo.role", "");
  const settings = _.get(app, "settings", false);
  const loanRequestLoad = _.get(wallet, "loanRequestLoading", false);
  const allLoans = _.get(wallet, "loanRequest", undefined);
  const loanRequestError = _.get(wallet, "loanRequestError", undefined);
  const approvalLoading = _.get(wallet , "approveLoanRequestAdminLoading" , false);
  const approvalError = _.get(wallet , "approveLoanRequestError" , undefined);
  return {
    role , 
    thememode,
    settings,
    loanRequestLoad,
    allLoans,
    loanRequestError,
    approvalLoading,
    approvalError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loanRequestListAsync: (data) =>
    dispatch({ type: "loanRequestListingSagaCalled" , payload : data }),
    approveLoanRequestAsync :(data)=>dispatch({type : "approvalLoanRequestSagaCalled" , payload : data })
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanRequest);
