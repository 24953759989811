import * as _ from 'lodash';
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '@components/filter/FilterStyles.scss';
import { Dropdown, FormControl } from 'react-bootstrap';
import { ReactComponent as FilterIcon } from '@images/filter.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Filter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onApplyDate,
  oncancel
}) => {
  const getDateFromMoment = (data) => {
    return moment(data).format('DD/MM/YYYY');
  };

  const { t, i18n, ready } = useTranslation();

  return (
    <div className="filter">
      <DateRangePicker
        initialSettings={{
          autoUpdateInput: false,
          locale: {
            cancelLabel: 'Clear',
          },
        }}
        onApply={(event, picker) => {
          onApplyDate(picker);
          picker.startDate && setStartDate(picker.startDate);
          picker.endDate && setEndDate(picker.endDate);
        }}
        onCancel={oncancel}
      >
        <Dropdown align="end" autoClose="inside">
          <Dropdown.Toggle>
            <span className="filtericon">
              <FilterIcon />
            </span>
            {/* <span className="filtertext">Filter Periode</span> */}
            <span className="filtertext">
              {(!_.isEmpty(startDate) &&
                !_.isEmpty(endDate) &&
                getDateFromMoment(startDate) +
                  ' - ' +
                  getDateFromMoment(endDate)) ||
                (!_.isEmpty(startDate) && getDateFromMoment(startDate)) ||
                (!_.isEmpty(endDate) && getDateFromMoment(endDate)) ||
                t(`${'FilterPeriode'}`)}
            </span>
          </Dropdown.Toggle>
        </Dropdown>
      </DateRangePicker>
    </div>
  );
};

export default Filter;
