import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { Container, Modal, Row, Col } from "react-bootstrap";
import "@components/add-bank-account-popup1/AddBankAccountPopup1.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import BankListItem from "../bank-list-item/BankListItem";
import AddBankAccountPopup2 from "@components/add-bank-account-popup2/AddBankAccountPopup2";
import Add from "@images/Add.svg";
import { getAllBankAccountsStart } from "@store/fiatredux";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";

const DeleteConfirmationPopup = ({
  showAddBankConfirmation,
  setShowAddBankConfirmation,
  onPress,
  onCancelled,
  toCallBanks,
  activeBankAccounts,
  createBankLoadingCall,
  userAccessToken,
  allBankAccounts,
  getAllBankAccountsLoading,
  setHideThis,
  createBankAccountLoading,
  update,
  banks,
  setShowDeposit,
  scope,
  setSelectedBankAccountId,
}) => {
  const { t, i18n, ready } = useTranslation();
  const [bankAccountStateParagraph, setBankAccountStateParagraph] = useState(
    t(`${"DontHaveLinkedBankAccounts"}`)
  );
  const [showAddBankForm, setShowAddBankForm] = useState(false);
 

  useEffect(() => {
    if (activeBankAccounts.length !== 0) {
      setBankAccountStateParagraph(t(`${"BankAccountsRegistered"}`));
    }
    toCallBanks && toCallBanks();
    // console.log('on cancel add bank' , onCancelled);
  }, []);



  useEffect(() => {
    if (createBankLoadingCall === false) {
      allBankAccounts();
    }
  }, [createBankLoadingCall]);


  

 
  return (
    <>
      {/* {getAllBankAccountsLoading && <Loader />} */}

         <AddBankAccountPopup2
          showAddBankConfirmation={showAddBankForm}
          setShowAddBankConfirmation={setShowAddBankForm}
          onPress={() => {
            //       console.log(t(`${"AddBankConfirmed"}`));
          }}
          onCancelledAsync={() => {
            setShowAddBankForm(false);
            onCancelled();
            setShowAddBankConfirmation(true);
          }}
          onError={() => {
            setShowAddBankForm(true);
          }}
          onSuccessfulBankCreation={() => {
            setShowAddBankConfirmation(false);
          }}
          setHideThis={(e) => setHideThis(e)}
          setShowDeposit={setShowDeposit}
        />
       <Modal
        className="addbankconfirmationpopup"
        show={showAddBankConfirmation}
        onHide={() => {
          setShowAddBankConfirmation(false);
          setHideThis(false);
          onCancelled();
         
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <span className="addbanktitle">{t("BankAccount")}</span>
          </Modal.Title>
          <CloseIcon
            className="closebtn"
            onClick={() => {
              setShowAddBankConfirmation(false);
              setHideThis(false);
              onCancelled();
             
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                {/* {createBankAccountLoading&&<Loader/>} */}
                <div className="addbanktext">{t("MyBankAccount")}</div>

                <div className="subtxt">{bankAccountStateParagraph}</div>

                {banks && banks.length === 0 && banks !== null ? (
                  <div className="AddBankImage">
                    <img
                      src={Add}
                      alt="Add Bank Icon"
                      className="addbanksymbol"
                      onClick={() => {
                        setShowAddBankConfirmation(false);
                        setShowAddBankForm(true);
                      }}
                    />
                    <span className="textblue">{t("AddNeww")}</span>
                  </div>
                ) : (
                  banks && banks !== null &&
                  banks.map((account, index) => {
                    if (scope === "FiatWithdraw") {
                      if (account.Status !== "Pending") {
                        return (
                          <>
                            <BankListItem
                              payload={{
                                colouredLogo: account?.bank?.colouredLogo,
                                bankName: account?.bankName,
                                bankDetails: account,
                              }}
                              fromAccountPopup1Called={true}
                              setShowAddBankConfirmation={() => {
                                setHideThis(false);
                                setShowAddBankConfirmation(false);
                              }}
                              index={index}
                              scope={scope}
                              setHideThis={setHideThis}
                              update={update}
                              setSelectedBankAccountId={
                                setSelectedBankAccountId
                              }
                            />
                          </>
                        );
                      }
                    } else {
                      return (
                        <>
                          <BankListItem
                            payload={{
                              colouredLogo: account.bank.colouredLogo,
                              bankName: account.bankName,
                              bankDetails: account,
                            }}
                            fromAccountPopup1Called={true}
                            setShowAddBankConfirmation={() => {
                              setHideThis(false);
                              setShowAddBankConfirmation(false);
                            }}
                            index={index}
                            scope={scope}
                            setHideThis={setHideThis}
                            update={update}
                            setSelectedBankAccountId={setSelectedBankAccountId}
                          />
                        </>
                      );
                    }
                  })
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {activeBankAccounts && activeBankAccounts.length === 0 ? (
          <></>
        ) : (
          <Row>
            <Col lg={12}>
              <div className="cancel">
                <CustomButton
                  type="submit"
                  name={t("AddNew")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowAddBankForm(true);
                    setShowAddBankConfirmation(false);
                  }}
                />
              </div>
            </Col>
            {/* <Col lg={6}>
                        <div className="confirm">
                            <CustomButton
                                type="submit"
                                name="Confirm"
                                filled={true}
                                primary={true}
                                onClick={onPress}
                            />
                        </div>
                    </Col> */}
          </Row>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({ fiat = {}, user = {} }) => {
  const activeBankAccounts = _.get(fiat, "getAllBankAccountDetails", []);
  const createBankLoadingCall = _.get(fiat, "createBankAccountLoading", false);
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const banks = _.get(fiat, "banks", undefined);
  const getAllBankAccountsLoading = _.get(
    fiat,
    "getAllBankAccountsLoading",
    false
  );

  const createBankAccountLoading = _.get(
    fiat,
    "createBankAccountLoading",
    false
  );

  return {
    activeBankAccounts,
    createBankLoadingCall,
    userAccessToken,
    getAllBankAccountsLoading,
    createBankAccountLoading,
    banks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  allBankAccounts: () => dispatch({ type: "getAllBankAccountsStart" }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteConfirmationPopup);
