import cpen from "./cybor-protection/en/translation";
import cptr from "./cybor-protection/tr/translation";
import cpru from "./cybor-protection/ru/translation";

import marketrateen from "./marketrate/en/translation";
import marketratetr from "./marketrate/tr/translation";
import marketrateru from "./marketrate/ru/translation";

import notifyen from "./notification/en/translation";
import notifytr from "./notification/tr/translation";
import notifyru from "./notification/ru/translation";

import signinen from "./signin/en/translation";
import signintr from "./signin/tr/translation";
import signinru from "./signin/ru/translation";

import commonen from "./common/en/translation";
import commontr from "./common/tr/translation";
import commonru from "./common/ru/translation";

import paymentreporten from "./PaymentReport/en/translation";
import paymentreporttr from "./PaymentReport/tr/transtalion";
import paymentreportru from "./PaymentReport/ru/transtalion";


import branchmanagementen from "./branch-management/en/translation";
import branchmanagementtr from "./branch-management/tr/translation";
import branchmanagementru from "./branch-management/ru/translation";


import claimIdoScreenen from "./claimIdoScreen/en/translation";
import claimIdoScreentr from "./claimIdoScreen/tr/translation";
import claimIdoScreenru from "./claimIdoScreen/ru/translation";

import createbranchen from "./createbranch/en/translation";
import createbranchtr from "./createbranch/tr/translation";
import createbranchru from "./createbranch/ru/translation";


import cryptowalleten from "./cryptoWallet/en/translation";
import cryptowallettr from "./cryptoWallet/tr/translation";
import cryptowalletru from "./cryptoWallet/ru/translation";


import paymenten from "./payment/en/translation";
import paymenttr from "./payment/tr/translation";
import paymentru from "./payment/ru/translation";


import transactionsen from "./transactions/en/translation";
import transactionstr from "./transactions/tr/translation";
import ruansactionsru from "./transactions/ru/translation";

import payscreenen from "./pay-screen/en/translation";
import payscreentr from "./pay-screen/tr/translation";
import payscreenru from "./pay-screen/ru/translation";


import personalsettingsen from "./personal-settings/en/translation";
import personalsettingstr from "./personal-settings/tr/translation";
import personalsettingsru from "./personal-settings/ru/translation";

import currencytaben from "./currency-tab/en/translation";
import currencytabtr from "./currency-tab/tr/translation";
import currencytabru from "./currency-tab/ru/translation";

import businesssignupen from "./business-signup/en/translation";
import businesssignuptr from "./business-signup/tr/translation";
import businesssignupru from "./business-signup/ru/translation";


import balancesen from "./balances/en/translation";
import balancestr from "./balances/tr/translation";
import balancesru from "./balances/ru/translation";


import aboutcyborcarden from "./about-cybor-card/en/translation";
import aboutcyborcardtr from "./about-cybor-card/tr/translation";
import aboutcyborcardru from "./about-cybor-card/ru/translation";


import mybalancesen from "./my-balances/en/translation";
import mybalancestr from "./my-balances/tr/translation";
import mybalancesru from "./my-balances/ru/translation";


import signupen from "./signup/en/translation";
import signuptr from "./signup/tr/translation";
import signupru from "./signup/ru/translation";


import otpscreenen from "./otp-screen/en/translation";
import otpscreentr from "./otp-screen/tr/translation";
import otpscreenru from "./otp-screen/ru/translation";


import verifylinken from "./verify-link/en/translation";
import verifylinktr from "./verify-link/tr/translation";
import verifylinkru from "./verify-link/ru/translation";



import cryptobalanceen from "./cryptobalance/en/translation";
import cryptobalancetr from "./cryptobalance/tr/translation";
import cryptobalanceru from "./cryptobalance/ru/translation";


import bigcoincarden from "./bigCoinCard/en/translation";
import bigcoincardtr from "./bigCoinCard/tr/translation";
import bigcoincardru from "./bigCoinCard/ru/translation";


import sidebaren from "./sidebar/en/translation";
import sidebartr from "./sidebar/tr/translation";
import sidebarru from "./sidebar/ru/translation";


import idoen from "./ido/en/translation";
import idotr from "./ido/tr/translation";
import idoru from "./ido/ru/translation";

import adminen from "./admin/en/translation";
import admintr from "./admin/tr/translation";
import adminru from "./admin/ru/translation";

import footeren from "./footer/en/translation";
import footertr from "./footer/tr/translation";
import footerru from "./footer/ru/translation";

let EN = {
  ...verifylinken,
  ...otpscreenen,
  ...cpen,
  ...marketrateen,
  ...signinen,
  ...signupen,
  ...commonen,
  ...paymentreporten,
  ...branchmanagementen,
  ...claimIdoScreenen,
  ...createbranchen,
  ...cryptowalleten,
  ...paymenten,
  ...transactionsen,
  ...payscreenen,
  ...personalsettingsen,
  ...currencytaben,
  ...businesssignupen,

  ...balancesen,
  ...aboutcyborcarden,
  ...mybalancesen,
  ...cryptobalanceen,
  ...bigcoincarden,
  ...sidebaren,
  ...idoen,
  ...adminen,
  ...footeren,
  ...notifyen
};
let TR = {
  ...verifylinktr,
  ...otpscreentr,
  ...cptr,
  ...marketratetr,
  ...signintr,
  ...signuptr,
  ...commontr,
  ...paymentreporttr,
  ...branchmanagementtr,
  ...claimIdoScreentr,
  ...createbranchtr,
  ...cryptowallettr,
  ...paymenttr,
  ...transactionstr,
  ...payscreentr,
  ...personalsettingstr,
  ...currencytabtr,
  ...businesssignuptr,

  ...balancestr,
  ...aboutcyborcardtr,
  ...mybalancestr,
  ...cryptobalancetr,
  ...bigcoincardtr,
  ...sidebartr,
  ...idotr,
  ...admintr,
  ...footertr,
  ...notifytr
};

let RU = {
  ...verifylinkru,
  ...otpscreenru,
  ...cpru,
  ...marketrateru,
  ...signinru,
  ...signupru,
  ...commonru,
  ...paymentreportru,
  ...branchmanagementru,
  ...claimIdoScreenru,
  ...createbranchru,
  ...cryptowalletru,
  ...paymentru,
  ...ruansactionsru,
  ...payscreenru,
  ...personalsettingsru,
  ...currencytabru,
  ...businesssignupru,

  ...balancesru,
  ...aboutcyborcardru,
  ...mybalancesru,
  ...cryptobalanceru,
  ...bigcoincardru,
  ...sidebarru,
  ...idoru,
  ...adminru,
  ...footerru,
  ...notifyru
};
export { EN, TR,RU };
