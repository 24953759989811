import * as _ from "lodash";
import Profile from "@components/profile/Profile";
import React, { Fragment, Profiler, useState, useEffect } from "react";
import { ReactComponent as Paid } from "@images/deposit.svg";
import { ReactComponent as Withdraw } from "@images/withdraw.svg";
import { ReactComponent as Info } from "@images/infoicon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import "@components/notification-dropdown/NotificationDropdownStyles.scss";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import moment from "moment";

function NotificationDropdown({
  getnotification,
  getnotificationcount,
  notificationsCount,
  notifications,
  auth,
  setShowNotify,
}) {
  const { t, i18n } = useTranslation();


  const history = useHistory();


  return (
    <div
      className="notificationdropdown"
      onMouseLeave={() => {
        setShowNotify(false);
      }}
    >
      <Fragment>
        <Dropdown.Menu>
          <div className="notification">
            {notifications &&
              notifications.map((item) => {
                let amount = "";
                if (item.type == "exchange") {
                  let strings = JSON.parse(item.exchangeAmounts);
                  amount = `${strings[0].amount1} - ${strings[0].amount2} `;
                }
                return (
                  <div key={item.id} className="details">
                    <div className="line1">
                      {item &&
                        item.transaction === null &&
                        item.type === "login" ? (
                        <Info className="infoicon img" />
                      ) : item.transaction &&
                        item.transaction !== null &&
                        item.transaction.TypeCurrency !== "Fiat" ? (
                        item.type == "deposit" ? (
                          <Paid className="img" />
                        ) : item.type == "withdraw" ? (
                          <Withdraw className="img" />
                        ) : (
                          <></>
                        )
                      ) : item.transaction === null ?
                        <Paid className="img" /> :
                        item.transaction !== null &&
                          (item.transaction.fiatTransactionStatus ===
                            "Successfull" || item.transaction.fiatTransactionStatus ===
                            "Pending") ? (
                          <Paid className="img" />
                        ) : (
                          <Withdraw className="img" />
                        )}
                      <div className="datentime">
                        {moment(item.createdOn).format("DD.MM.YYYY hh:mm")}
                      </div>
                    </div>
                    <div className="line2">
                      <div className={item.read === false ? "txt_bold" : "txt"}>
                        {item.type == "login"
                          ? t("notilogin")
                          : item.type == "withdraw"
                            ? item.transaction &&
                              item.transaction.fiatTransactionStatus ===
                              "Successfull"
                              ? t("notiwithdraw")
                              : item.transaction && item.transaction.fiatTransactionStatus === 'Pending' ?
                                t("notipendingwithdraw") :
                                t("notiwithdraw")
                            : item.type == "deposit"
                              ? item.transaction === null ?
                                t("notipendingdeposit") :
                                item.transaction.TypeCurrency == "Crypto"
                                  ? item.transaction &&
                                    item.transaction.status === "completed"
                                    ? t("notideposit")
                                    : t("notierrdeposit")
                                  : item.transaction === null ?
                                    t("notipendingdeposit") :
                                    item.transaction.fiatTransactionStatus ===
                                      "Successfull"
                                      ? t("notideposit")
                                      : t("notierrdeposit")
                              : t("notiexchange")}
                      </div>
                    </div>
                    {item.type != "login" && (
                      <div className="line3">
                        <div
                          className={item.read === false ? "txt_bold" : "txt"}
                        >
                          {t("Amount")}: {""}
                        </div>
                        <div className="amount-text">
                          {item.type == "exchange"
                            ? amount
                            : item && item.amount}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </Dropdown.Menu>
      </Fragment>
    </div>
  );
}

const mapStateToProps = ({ app = {}, user = {}, notification = {} }) => {
  const notificationsCount = _.get(notification, "notificationsCount.count", 0);
  const notifications = _.get(notification, "notifications", []);

  return {
    notificationsCount,
    notifications,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getnotification: (data) =>
    dispatch({ type: "getnotificationcalled", payload: data }),
  getnotificationcount: (data) =>
    dispatch({ type: "getnotificationcountcalled", payload: data }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDropdown);
