import React from "react";
import "@components/round-list/RoundListStyle.scss";
import Table from "react-bootstrap/esm/Table";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";
import CustomButton from "../custom-button/CustomButton";
function RoundList({ idorounds, getroundLoading, EditRoundPrivacy }) {
  const { t } = useTranslation();
  return (
    <div className="roundlist">
      <Table responsive="sm" className="transfertbl">
        {getroundLoading && <Loader />}
        <thead>
          {idorounds && idorounds.length != 0 && (
            <tr>
              <th className="trtbleheading">{t("ROUNDNAME")}</th>
              <th className="trtbleheading">{t("EXCHANGERATE")}</th>
              <th className="trtbleheading">{t("LOCKINGPERIOD")}</th>
              <th className="trtbleheading">{t("TOKENLIMIT")}</th>
              <th className="trtbleheading">{t("BONUS")}</th>

              <th className="trtbleheading">{t("TOKENDISTRIBUTED")}</th>
              <th className="trtbleheading">{t("TOKENCLAIMED")}</th>
              <th></th>
            </tr>
          )}
        </thead>

        <tbody>
          {idorounds.map((item, index) => (
            <tr key={`${index}id0`}>
              <td className="values textallign">{item.roundName}</td>
              <td className="values">1 USDT = {item.exchangeRate} CYBOR</td>
              <td className="values textallign">
                {Number(item.lockingPeriod / 86400).toFixed(0)} DAYS
              </td>
              <td className="values textallign">{item.tokenLimit}</td>
              <td className="values textallign">{item.bonus}</td>

              <td className="values textallign">{item.tokensDistributed}</td>
              <td className="values textallign">{item.claimedTokens}</td>
              <td className="values textallign">
                {!item.active ? (
                  <p>
                    <CustomButton
                      name={t("Activate")}
                      filled={true}
                      primary={true}
                      onClick={() => {
                        EditRoundPrivacy({
                          roundId:item.id,
                          active:'true'
                        })
                      }}
                    />
                  </p>
                ) : (
                  <p>Active</p>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
export default RoundList;
