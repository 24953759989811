import * as _ from "lodash";
import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as BackIcon } from "@images/back.svg";
import { ReactComponent as SecurityPopuplogo } from "@images/paymentsolutionlogo.svg";
import "@components/login-confirmation-popup/LoginConfirmationPopupStyles.scss";
import NoaccessPopup from "@components/no-access-popup/NoaccessPopup";
import Loader from "@components/loader/Loader";
import { useTranslation } from "react-i18next";
import NoaccesspopupSecurity from "@components/no-access-popup-security/NoaccesspopupSecurity";

const LoginConfirmationPopup = ({
  requestEmailCode,
  show1,
  setShowModal,
  setHideThis,
  signInLoading,
  ConfirmEmailCode,
  Error,
  thememode,
  exchange,
  withdraw,
}) => {
  const [validated, setValidated] = useState(false);
  const [code, setcode] = useState("");

  const [getCodeTimeout, setGetCodeTimeout] = useState();

  const [verifyToken, setverifyToken] = useState("");
  const [showmodal, setshowmodalnoaccess] = useState(false);
  let [showmodalsecurity, setshowmodalsecurity] = useState(false);
  let [blockcall, setblockcall] = useState(false);

  const [send, setsend] = useState(false);
  const [counter, setCounter] = useState(120);
  const [triggerTimer, setTriggerTimer] = useState(false);
  let [link, setlink] = useState(true);
  const { t } = useTranslation();

  // useEffect(() => {
  //   console.log('clicked')
  //   const timer =
  //   triggerTimer &&  counter > 0 &&  setInterval(() => setCounter(counter - 1), 1000);
  // return () =>  {
  //   console.log('clear')
  //   clearInterval(timer)};

  // }, [counter , triggerTimer]);
  useEffect(() => {
    setsend(false);
  }, []);
  const startTimerSync = () => {
    setTriggerTimer(true);
    setblockcall(true);
    let tm = 120;
    let intva = setInterval(() => {
      if (tm === 0) {
        setsend(true);
        setTriggerTimer(false);
        setblockcall(false);

        setCounter(120);
      } else {
        tm--;
        setCounter((prevTime) => prevTime - 1);
      }
    }, 1000);

    setGetCodeTimeout(intva);

    setTimeout(() => {
      clearInterval(intva);
    }, 121000);
  };

  useEffect(() => {
    onClose();
  }, []);

  const onSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      ConfirmEmailCode({ code, verifyToken });
      setcode("");
      setverifyToken("");
      setValidated(false);
    }
  };
  useEffect(() => {
    setsend(false);
  }, []);

  const onClose = () => {
    setsend(false);
    setblockcall(false);
    setcode("");
    setverifyToken("");
    setCounter(120);
    clearInterval(getCodeTimeout);
  };
  return (
    <div>
      <Modal
        className="loginconfirmationpopup"
        show={show1}
        onHide={() => {
          onClose();

          setShowModal(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <CloseIcon
            className="closebtn hidebtn"
            onClick={() => {
              onClose();

              setShowModal(false);
            }}
          />
          {signInLoading && <Loader />}
          <Container>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="centeralign">
                    {/* <img
                      alt=""
                      className="img hide"
                      src={
                        thememode == "light"
                          ? exchange &&
                            `${process.env.REACT_APP_IMGLINK}${exchange.lightLogo}`
                          : exchange &&
                            `${process.env.REACT_APP_IMGLINK}${exchange.darkLogo}`
                      }
                    /> */}
                      <SecurityPopuplogo className="securitylogo" />
                    
                  </div>
                  <div className="titletop">
                    <BackIcon
                      className="backbtn"
                      onClick={() => {
                        onClose();
                        setShowModal(false);
                      }}
                    />
                    <span className="titleText">
                      {withdraw
                        ? t("Security_verification")
                        : t("ConfirmTheLogin")}
                    </span>
                  </div>
                  {!withdraw && (
                    <div className="titletop">
                      <span className="titleText">{t("FromNewDevice")}</span>
                    </div>
                  )}

                  <div className="subtitle">
                    <span>{t("CheckProtectYourAccount")}</span>
                  </div>
                  <div className="getcode">
                    {send ? (
                      <InputField
                        required
                        placeholder={t("EmailConfirmationCode")}
                        linktext={!blockcall && t(`${"Resend"}`)}
                        type={"number"}
                        // link={true}
                        link={link}
                        timer={blockcall}
                        value={code}
                        error={t("InvalidEmailCode")}
                        onPress={() => {
                          if (!blockcall) {
                            requestEmailCode();

                            startTimerSync();
                          }
                        }}
                        onChange={(e) => setcode(e.target.value)}
                        counter={counter}
                      />
                    ) : (
                      <InputField
                        required
                        placeholder={t("EmailConfirmationCode")}
                        linktext={!blockcall && t(`${"GetCode"}`)}
                        type={"number"}
                        // link={true}
                        link={link}
                        timer={blockcall}
                        value={code}
                        error={t("InvalidEmailCode")}
                        onPress={() => {
                          if (!blockcall) {
                            requestEmailCode();

                            startTimerSync();
                          }
                        }}
                        onChange={(e) => setcode(e.target.value)}
                        counter={counter}
                      />
                    )}

                    <div className="btmtxt">
                      {" "}
                      <span>{t("Enter6digitCode")}</span>
                      {/* <span className={triggerTimer ? 'subtimer' : 'hidden'}>
                      {counter}
                    </span> */}
                    </div>
                  </div>
                  <div className="subtitle1">
                    <span>{t("AuthenticationCode")}</span>
                  </div>
                  <div className="leftalign">
                    <OtpInput
                      className="otpinput"
                      value={verifyToken}
                      onChange={(otp) => setverifyToken(otp)}
                      numInputs={6}
                      separator={<div className="rightmargin"> </div>}
                      containerStyle="otparea"
                    />
                  </div>

                  <div className="btmtxt">
                    <span>{t("GoogleAuthenticatorCode")}</span>
                  </div>
                  <div
                    className="bluetxt"
                    onClick={() => {
                      setshowmodalnoaccess(true);
                      setShowModal(false);
                    }}
                  >
                    <span>{t("NoAccessSecurityCheck")}</span>
                  </div>
                </Col>
              </Row>
              {t(`${Error}`)}
              <Row>
                <Col lg={12}>
                  <div className="confirm">
                    <CustomButton
                      type="submit"
                      name={t("Send")}
                      filled={true}
                      primary={true}
                      disable={code && verifyToken ? false : true}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      <NoaccessPopup
        shownoacess={showmodal}
        setHideThis={setHideThis}
        setshowmodalnoaccess={setshowmodalnoaccess}
        setshowmodalsecurity={setshowmodalsecurity}
        setShowModal={setShowModal}
      />
      <NoaccesspopupSecurity
        showsecurity={showmodalsecurity}
        setHideThis={setHideThis}
        setShowModal={setShowModal}
        setshowmodalsecurity={setshowmodalsecurity}
      />
    </div>
  );
};

export default LoginConfirmationPopup;
