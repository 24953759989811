import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import Loader from "@components/loader/Loader";
import { Modal, Container, Form, Col, Row } from "react-bootstrap";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import InputField from "@components/input-field/InputField";
import CustomButton from "src/components/custom-button/CustomButton";
import BigNumber from "bignumber.js";
import "@components/create-payment/CreatePayment.scss";
import { useTranslation } from "react-i18next";

const CreatePayment = ({
  getplatformTokensinfo,
  exchangeRates,
  getExchangerate,
  createpaymentLink,
  myaccountandbalance,
  plaformcurrencies,
  loading,
  getcomissionper,
  commissionPercentage,
  settings,
  getFiatExchangerate,
  FiatexchangeRates,
  createfiatpayment,
  createloaders,
  setCurrencyToReceive,
  setCurrencyToConvert,
  paymentbytoken,
  paymentToken,
}) => {
  let [selected, setSelectedIndex] = useState(0);
  let [selected2, setSelectedIndex2] = useState(1);
  let [FiatSelectedIndex, setFiatSelectedIndex] = useState(0);

  let [fiatoptions, setfiatoptions] = useState([]);
  const [totalAmount, settotalAmount] = useState("");
  const [amount, setamount] = useState("");
  const [email, setemail] = useState("");
  let [amtconvt, setamtconvt] = useState("");
  let [amountReceive, setamountReceive] = useState("");
  let [exchangeRatesst, setExchangeRates] = useState(false);
  const [currentLang, setCurrentLang] = useState("en");

  const [validated, setValidated] = useState(false);
  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", 0);

    return address;
  };

  useEffect(() => {
    setCurrentLang(localStorage.lang);
  }, [localStorage.lang]);

  useEffect(() => {
    let allcurrenc = [];
    plaformcurrencies &&
      plaformcurrencies.map((items) => {
        allcurrenc.push({
          tokenName: items.currencyName,
          ...items,
        });
        setfiatoptions(allcurrenc);
        setFiatSelectedIndex(0);
      });
    setExchangeRates(exchangeRates);
    getFiatExchangerate({
      symbol1:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected] &&
        getplatformTokensinfo[selected].exchangeSymbol,

      symbol2:
        plaformcurrencies &&
        plaformcurrencies[FiatSelectedIndex] &&
        plaformcurrencies[FiatSelectedIndex].exchangeSymbol == "USD"
          ? "USDT"
          : plaformcurrencies &&
            plaformcurrencies[FiatSelectedIndex] &&
            plaformcurrencies[FiatSelectedIndex].exchangeSymbol,
    });
    getExchangerate({
      symbol1:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected2] &&
        getplatformTokensinfo[selected2].exchangeSymbol,

      symbol2:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected] &&
        getplatformTokensinfo[selected].exchangeSymbol,
    });
  }, []);

  useEffect(() => {
    setCurrencyToReceive(amountReceive);
  }, [amountReceive]);

  useEffect(() => {
    setCurrencyToConvert(amtconvt);
  }, [amtconvt]);

  useEffect(() => {
    getcomissionper({ id: settings.exchange.id });
    getFiatExchangerate({
      symbol1:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected] &&
        getplatformTokensinfo[selected].exchangeSymbol,

      symbol2:
        plaformcurrencies &&
        plaformcurrencies[FiatSelectedIndex] &&
        plaformcurrencies[FiatSelectedIndex].exchangeSymbol == "USD"
          ? "USDT"
          : plaformcurrencies &&
            plaformcurrencies[FiatSelectedIndex] &&
            plaformcurrencies[FiatSelectedIndex].exchangeSymbol,
    });

    getExchangerate({
      symbol1:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected2] &&
        getplatformTokensinfo[selected2].exchangeSymbol,

      symbol2:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected] &&
        getplatformTokensinfo[selected].exchangeSymbol,
    });
  }, [selected2, selected, FiatSelectedIndex]);

  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const getRates = (symb1) => {
    let { result, sym1, sym2 } = exchangeRates || {};

    if (sym1 == symb1) {
      return result;
    } else {
      return 1 / result;
    }
  };
  useEffect(() => {
    setExchangeRates(exchangeRates);
    setamt(totalAmount);
    setamtc(totalAmount);
  }, [exchangeRates, totalAmount, selected, selected2]);

  // useEffect(() => {
  //  console.log(FiatSelectedIndex)
  // },[FiatSelectedIndex])

  // useEffect(() => {
  //   console.log(selected2)
  // },[selected2])

  const setamt = (total) => {
    let t3 = getRates3(
      getplatformTokensinfo &&
        getplatformTokensinfo[selected2] &&
        getplatformTokensinfo[selected2].exchangeSymbol
    );
    let t2 = getFiatRates(
      fiatoptions &&
        fiatoptions[FiatSelectedIndex] &&
        fiatoptions[FiatSelectedIndex].exchangeSymbol
    );
    let t1 = getRates(
      getplatformTokensinfo &&
        getplatformTokensinfo[selected] &&
        getplatformTokensinfo[selected].exchangeSymbol
    );
    setamountReceive(
      BigNumber(total)
        .multipliedBy(t1)
        .multipliedBy(t2)
        .multipliedBy(t3)
        .toFixed(4, BigNumber.ROUND_FLOOR)
    );
  };
  const setamtc = (total) => {
    setamtconvt(
      BigNumber(total)
        .multipliedBy(
          getFiatRates(
            fiatoptions &&
              fiatoptions[FiatSelectedIndex] &&
              fiatoptions[FiatSelectedIndex].exchangeSymbol
          )
        )
        .multipliedBy(
          getRates2(
            getplatformTokensinfo &&
              getplatformTokensinfo[selected] &&
              getplatformTokensinfo[selected].exchangeSymbol
          )
        )

        .toFixed(6, BigNumber.ROUND_FLOOR)
    );
  };

  const getRates3 = (symb1) => {
    let { result, sym1, sym2 } = exchangeRates || {};

    if (sym1 == symb1) {
      return result;
    } else {
      return 1 / result;
    }
  };
  const getRates2 = (symb1) => {
    let { result, sym1, sym2 } = exchangeRates || {};

    if (sym1 == symb1) {
      return result;
    } else {
      return 1 / result;
    }
  };
  const getFiatRates = (symb1) => {
    let { result, sym1, sym2 } = FiatexchangeRates || {};

    if (sym1 == symb1) {
      return result;
    } else {
      return 1 / result;
    }
  };
  return (
    <div className="createpayment">
      <div className="topspace">
        <div className="outerbox">
          <div className="maintitle">{t("CreatePaymentLink")}</div>
        </div>

        <div className="innerbox">
          <Row className="d-flex justify-content-center">
            <Col md={6} lg={6} xl={4}>
              <div className="heading">
                <span>{t("CurrencyToConvert")}</span>
              </div>
              <div>
                <DropdownSearch
                  options={fiatoptions}
                  setSelectedIndex={(e) => {
                    setamount("");
                    settotalAmount("");
                    setFiatSelectedIndex(e);
                  }}
                  Selected={FiatSelectedIndex}
                  scope="FiatDeposit"
                />
                <div
                  className={
                    lang !== "en"
                      ? "heading topmargin allign"
                      : "heading topmargin"
                  }
                >
                  <span>{t("CryptoCurrencyToPay")}</span>
                </div>
                <DropdownSearch
                  Selected={selected}
                  options={getplatformTokensinfo}
                  setSelectedIndex={(e) => {
                    setamount("");
                    settotalAmount("");
                    setSelectedIndex(e);
                  }}
                />
              </div>
            </Col>
            <Col md={6} lg={6} xl={4}>
              <div className="heading">
                <span>{t("Amount")}</span>
              </div>
              <InputField
                type="number"
                required
                onChange={(e) => {
                  if (Number(e.target.value) >= 0) {
                    setamount(e.target.value);
                    let total = BigNumber(e.target.value)
                      .plus(
                        BigNumber(e.target.value)
                          .multipliedBy(
                            commissionPercentage &&
                              commissionPercentage.commissionPercentage
                          )
                          .toFixed()
                      )
                      .toFixed();

                    settotalAmount(total);
                  }
                }}
                value={amount}
              />

              <div className={lang !== "en" ? "heading  allign" : "heading"}>
                <span>{t("CryptoCurrencyToConvert")}</span>
              </div>
              <DropdownSearch
                Selected={selected2}
                options={getplatformTokensinfo}
                setSelectedIndex={(e) => {
                  setamount("");
                  settotalAmount("");
                  setSelectedIndex2(e);
                }}
              />
            </Col>
            <Col md={6} lg={6} xl={4} className="d-flex">
              <div className="detailspace">
                <div className="detail mx-auto mx-xl-auto mx-lg-0">
                  <div className="value">
                    {amount !== 0 &&
                      amount !== undefined &&
                      amount !== null &&
                      amount !== "" && (
                        <span className="valuetext">
                          {amount}{" "}
                          {fiatoptions &&
                            fiatoptions[FiatSelectedIndex] &&
                            fiatoptions[FiatSelectedIndex].exchangeSymbol}
                        </span>
                      )}
                  </div>
                  <div className="label">
                    <span className="labeltext">{t("Amount")} </span>
                  </div>
                </div>

                {
                  <div className="detail1 mx-auto mx-xl-auto mx-lg-0">
                    <div className="value">
                      {amount !== 0 &&
                        amount !== undefined &&
                        amount !== null &&
                        amount !== "" && (
                          <span className="valuetext">
                            {BigNumber(amount)
                              .multipliedBy(
                                commissionPercentage &&
                                  commissionPercentage.commissionPercentage
                              )
                              .toFixed(4, BigNumber.ROUND_FLOOR)}{" "}
                            {fiatoptions &&
                              fiatoptions[FiatSelectedIndex] &&
                              fiatoptions[FiatSelectedIndex].exchangeSymbol}
                          </span>
                        )}
                    </div>
                    <div className="label">
                      <span className="labeltext"> {t("Commission")}</span>
                    </div>
                  </div>
                }

                {
                  <div className="detail mx-auto mx-xl-auto mx-lg-0 detailtopspace ">
                    <div className="value">
                      {amount !== 0 &&
                        amount !== undefined &&
                        amount !== null &&
                        amount !== "" && (
                          <span className="valuetextblue">
                            {totalAmount}{" "}
                            {fiatoptions &&
                              fiatoptions[FiatSelectedIndex] &&
                              fiatoptions[FiatSelectedIndex].exchangeSymbol}
                          </span>
                        )}
                    </div>
                    <div className="label">
                      <span className="labeltextblue"> {t("Total")}</span>
                    </div>
                  </div>
                }
              </div>
            </Col>
          </Row>
          <div className="boxtop">
            <div className="box">
              <div className="link1">
                <span className="toptxt">{t("PaymentLinkwillbecreated")}:</span>
              </div>
              <div className="commonsection">
                <div className="link2">
                  <span className="linktxt">{t("ClientPays")}:</span>
                </div>
                {amount !== 0 &&
                  amount !== undefined &&
                  amount !== null &&
                  amount !== "" && (
                    <span className="textline1">
                      {totalAmount}{" "}
                      {fiatoptions &&
                        fiatoptions[FiatSelectedIndex] &&
                        fiatoptions[FiatSelectedIndex].exchangeSymbol}
                    </span>
                  )}

                <span className="textline2">
                  {fiatoptions &&
                    fiatoptions[FiatSelectedIndex] &&
                    fiatoptions[FiatSelectedIndex].currencyName}
                </span>
              </div>
              {/* <div className="imgsection">
                    <DownIcon className="arrowclass" />
                  </div> */}
              <div className="commonsection">
                <div className="link2">
                  <span className="linktxt">{t("CurrencytoReceive")}:</span>
                </div>
                {amount !== 0 &&
                  amount !== undefined &&
                  amount !== null &&
                  amount !== "" && (
                    <span className="textline1">
                      {amountReceive}{" "}
                      {getplatformTokensinfo &&
                        getplatformTokensinfo[selected] &&
                        getplatformTokensinfo[selected].exchangeSymbol}
                    </span>
                  )}
                <span className="textline2">
                  {" "}
                  {getplatformTokensinfo &&
                    getplatformTokensinfo[selected] &&
                    getplatformTokensinfo[selected].exchangeSymbol}{" "}
                  -{" "}
                  {getplatformTokensinfo &&
                    getplatformTokensinfo[selected] &&
                    getplatformTokensinfo[selected].tokenName}
                </span>

                {/*    <span className="textline2">
                  1{" "}
                      {getplatformTokensinfo &&
                        getplatformTokensinfo[selected2] &&
                        getplatformTokensinfo[selected2].exchangeSymbol}{" "}
                      /{" "}
                      {Number(
                        getRates(
                          getplatformTokensinfo &&
                            getplatformTokensinfo[selected] &&
                            getplatformTokensinfo[selected].exchangeSymbol
                        )
                      ).toFixed(5)}{" "}
                      {getplatformTokensinfo &&
                        getplatformTokensinfo[selected] &&
                        getplatformTokensinfo[selected].exchangeSymbol}
                      )   */}
                {/* (1 USDT = 0,00543 BTC) 
                </span> */}

                {/* <span className="textline2">
                      {" "}
                      {getplatformTokensinfo &&
                        getplatformTokensinfo[selected] &&
                        getplatformTokensinfo[selected].exchangeSymbol}-  {getplatformTokensinfo &&
                          getplatformTokensinfo[selected] &&
                          getplatformTokensinfo[selected].tokenName}
                    </span> */}
              </div>
              {/* <div className="imgsection">
                    <DownIcon className="arrowclass" />
                  </div> */}
              <div className="commonsection">
                <div className="link2">
                  <span className="linktxt">{t("CurrencytoConvert")}:</span>
                </div>
                {amount !== 0 &&
                  amount !== undefined &&
                  amount !== null &&
                  amount !== "" && (
                    <span className="textline1">
                      {amtconvt}

                      {getplatformTokensinfo &&
                        getplatformTokensinfo[selected2] &&
                        getplatformTokensinfo[selected2].exchangeSymbol}
                    </span>
                  )}

                <span className="textline2">
                  {getplatformTokensinfo &&
                    getplatformTokensinfo[selected2] &&
                    getplatformTokensinfo[selected2].exchangeSymbol}{" "}
                  -{" "}
                  {getplatformTokensinfo &&
                    getplatformTokensinfo[selected2] &&
                    getplatformTokensinfo[selected2].tokenName}
                </span>
              </div>
            </div>
          </div>
          {(loading || createloaders) && <Loader loading={createloaders} />}
          <div className="currencytoconvert">
            <div className="btntop">
              <CustomButton
                type="submit"
                name={t("CreatePaymentLink")}
                filled={true}
                primary={true}
                disable={
                  amount == 0 ||
                  amount == undefined ||
                  amount == null ||
                  amount == "" ||
                  loading
                }
                onClick={() => {
                  createfiatpayment({
                    lang: currentLang,
                    ExpectedAmount: BigNumber(totalAmount)
                      .multipliedBy(
                        getFiatRates(
                          fiatoptions &&
                            fiatoptions[FiatSelectedIndex] &&
                            fiatoptions[FiatSelectedIndex].exchangeSymbol
                        )
                      )
                      .multipliedBy(
                        getRates(
                          getplatformTokensinfo &&
                            getplatformTokensinfo[selected] &&
                            getplatformTokensinfo[selected].exchangeSymbol
                        )
                      )
                      .multipliedBy(
                        getRates3(
                          getplatformTokensinfo &&
                            getplatformTokensinfo[selected2] &&
                            getplatformTokensinfo[selected2].exchangeSymbol
                        )
                      )
                      .toFixed(4, BigNumber.ROUND_FLOOR),
                    amount: amount,
                    acceptAs:
                      getplatformTokensinfo &&
                      getplatformTokensinfo[selected] &&
                      getplatformTokensinfo[selected].id,
                    exchangeTo:
                      getplatformTokensinfo &&
                      getplatformTokensinfo[selected2] &&
                      getplatformTokensinfo[selected2].id,
                    acceptAsType: "COIN",
                    exchangeToType: "COIN",
                    email: "",
                    toAddress: getAdd(
                      getplatformTokensinfo &&
                        getplatformTokensinfo[selected2] &&
                        getplatformTokensinfo[selected2].id
                    ),
                    fiatCurrency:
                      fiatoptions &&
                      fiatoptions[FiatSelectedIndex] &&
                      fiatoptions[FiatSelectedIndex].id,
                    fiatCurrencyType: "CUR",
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CreatePayment);
