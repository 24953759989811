import "@components/smallcard/SmallCardStyle.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
 
function SmallCard({ symbol, title, balance, bgcolor, logo, decimals, type }) {
  return (
    <div className="CoindetailCard">
      <div className="cardarea">
        <span className="coinnm"> {symbol}</span>
        {(type == "TRC20" || type == "BEP20" || type == "ERC20") && (
          <span className="cointag">{type}</span>
        )}
        <OverlayTrigger placement={"top"} overlay={<Tooltip>{title}</Tooltip>}>
          <div className="cointype">{title}</div>
        </OverlayTrigger>
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip>
              {decimals
                ? Number(balance / 10 ** decimals)
                : Number(balance)}
            </Tooltip>
          }
        >
          <div className="amount">
            {decimals
              ? Number(balance / 10 ** decimals)
              : Number(balance).toFixed(4)}
          </div>
        </OverlayTrigger>
      </div>
      <div className="cardcircle" style={{ backgroundColor: bgcolor }}>
        <img src={`${process.env.REACT_APP_IMGLINK}${logo}`} className="logo" />
      </div>
    </div>
  );
}
export default SmallCard;
