import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import "@components/deposit-popup/DepositPopupStyles.scss";
import { useTranslation } from "react-i18next";
import { getAllBankOwnerAccountsStart } from "@store/fiatredux";

const DepositPopup = ({
  closePullRequestModal,
  setHideThis,
  activeBankAccounts,
  show,
  setshow,
  datashown,
  clearMessages,
  createDepositRequestSuccess,
  allBankOnwerAccountNumber,
}) => {
  const { t, i18n, ready } = useTranslation();
  const [bankNum, setbankNum] = useState("");
  useEffect(() => {
     if (datashown) {
      let b = "";
      let filterbank = allBankOnwerAccountNumber.filter(
        (item) => item.currencyType == datashown.CurrencySymbol
      );
     
      let banks = filterbank.filter((item) => item.bankId == datashown.bankId);
       if (banks.length != 0) {
        setbankNum(banks && banks[0] && banks[0].bankAccountNumber);
      } else {
        setbankNum(
          filterbank && filterbank[0] && filterbank[0].bankAccountNumber
        );
      }
    }
  }, [datashown]);
   return (
    <>
      {/* {createDepositRequestSuccess &&  */}
      <Modal
        className="depositpopup"
        // show={createDepositRequestSuccess !== undefined}
        show={show}
        onHide={() => {
          // closePullRequestModal(false)
          setHideThis(false);
          setshow(false);

          clearMessages();
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <span className="exchangetitle">{t("Deposit")}</span>
          </Modal.Title>
          <CloseIcon
            className="closebtn"
            onClick={() => {
              //closePullRequestModal(false)
              setHideThis(false);
              setshow(false);
              clearMessages();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="exchangetext">{t("pleasemakepayment")}:</div>
              </Col>
              <Col lg={6}>
                <div className="exchangetext">{t("Account")} {t("Number")} :</div>
              </Col>
              <Col lg={6}>
                <div className="exchangetext">{bankNum}</div>
              </Col>
              <Col lg={6}>
                <div className="exchangetext">{t("Amount")} :</div>
              </Col>
              <Col lg={6}>
                <div className="exchangetext">
                  {datashown !== undefined && datashown.amount}{" "}
                  {datashown !== undefined && datashown.CurrencySymbol}
                </div>
              </Col>
              {/* <Col lg={12}>
              <div className="exchangebox">
                <span className="address">
                  0x31FA4b90c2aD0cf38E152E4A77413162c2DbaDf6
                </span>
              </div>
            </Col> */}
            </Row>
          </Container>
        </Modal.Body>

        <Row>
          <Col lg={12}>
            <div className="copy">
              <CustomButton
                type="submit"
                name={t("Copy")}
                filled={true}
                primary={true}
                onClick={() => {
                  navigator.clipboard.writeText(bankNum);
                  toast.success(t(`${"copied"}`), {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setshow(false);
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
      {/* } */}
    </>
  );
};

const mapStateToProps = ({ app = {}, fiat = {} }) => {
  const showDepositPopup = _.get(app, "FiatPullRequest", false);
  const createDepositRequestSuccess = _.get(
    fiat,
    "createDepositRequestSuccess",
    undefined
  );
  const allBankOnwerAccountNumber = _.get(fiat, "getAllOwnerBankData", []);
  return {
    showDepositPopup,
    createDepositRequestSuccess,
    allBankOnwerAccountNumber,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closePullRequestModal: (data) =>
    dispatch({ type: "closeFiatPullRequestModal", payload: data }),
  clearMessages: () => dispatch({ type: "clearFiatDepositMessages" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositPopup);
