import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import "@features/borrow-market/BorrowMarket.scss";
import Table from "react-bootstrap/esm/Table";
import ReactPaginate from "react-paginate";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import CoinList from "@components/coin-list/CoinList";
import moment from "moment";
import Loader from "@components/loader/Loader";

import { useTranslation } from "react-i18next";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import PaymentCancelConfirmation from "@components/payment-cancel-confirmation/PaymentCancelConfirmation";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import bitcoin from "@images/bitcoin.svg";
import { ExportSheet } from "react-xlsx-sheet";
import "src/themes/OpenSans-Regular-normal.js";
import "jspdf-autotable";
import { ClearSansBase64 } from "src/custom-fonts-base64/index";
import { useHistory } from "react-router-dom";
function BorrowMarket(props) {
  const {
    thememode,
    loanRequestLoad,
    allLoans,
    loanRequestError,
    allLoanTypes,
    loanRequestListAsync,
    hideLoaderOnRefresh,
  } = props || {};
  let navigate = useHistory();
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [skip, setskip] = useState(0);
  const componentRef = useRef();
  const [extractFirstLoan , setExtractFirstName] = useState();
  const [allLoansTypes , setAllLoans] = useState();

  // console.log(componentRef.current)

  const { t } = useTranslation();

  const handleBorrowPage = (item , index) => {
    navigate.push("/requestLoan", { state: { item , index , extractFirstLoan } });
  };

  useEffect(() => {
    loanRequestListAsync({ skip, limit });
  }, []);

  useEffect(()=>{
    extractFirstCoin()
  },[allLoanTypes])

  const extractFirstCoin = ()=>{
    if(allLoanTypes !== undefined && allLoanTypes.data !== undefined && allLoanTypes.data.length > 0){
      setExtractFirstName(allLoanTypes.data[0])
      setAllLoans(allLoanTypes.data);
    }
  }
  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      loaderOnRefresh={hideLoaderOnRefresh}
    >
      <div id="borrowMarket">
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("Borrow Market")}</span>
                  <div className="filtersection"></div>
                </div>
              </div>

              <Table
                responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless
                ref={componentRef}
              >
                {loanRequestLoad && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    {/* <th className="trtbleheading"><span className="heading"></span></th> */}
                    {/* <th className="trtbleheading">
                      <span className="heading">No</span>
                    </th> */}
                    <th className="trtbleheading">
                      <span className="heading">Name</span>
                    </th>
                    {/* <th className="trtbleheading">
                      <span className="heading">{t("Loanable Coin")}</span>
                    </th> */}
                    {allLoanTypes?.data?.map((items) => (
                      <th className="trtbleheading">
                        <span className="heading">
                          {t(`${items?.loanName}`)}{" "}
                          {/* <span className="yellow">LOW RATES</span> */}
                        </span>
                      </th>
                    ))}
                    {/* <th className="trtbleheading">
                      <span className="heading">
                        {t("7 Days - Stable Rate")}
                      </span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">
                        {t("30 Days - Stable Rate")}
                      </span>
                    </th> */}
                    <th className="trtbleheading">
                      <span className="heading">Click to borrow </span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {allLoans?.data?.map((it, index) => (
                    <tr
                      onClick={() => {
                        // setshow(true);
                      }}
                      className="maintdwrapper"
                    >
                      {/* <td>
                        <div className="innerTd">{index+1}</div>
                      </td> */}
                      {/* <td>
                      <div className="innerTd">
                        Bitcoin
                      </div>
                    </td> */}
                      {/* <td>
                      <div className="innerTd">
                        <img src={bitcoin} />
                      </div>
                    </td> */}
                      <td>
                        <div className="innerTd">
                          <div className="table-coin">
                          <CoinList
                            type={it.type}
                            logo={it.nonColouredLogo}
                            name={it.tokenName}
                            logoBgColor={it.logoBgColor}
                          />

                          {
                            <span className="cointag">
                              {it.type == "ETH"
                                ? "ERC20"
                                : it.type == "BSC"
                                ? "BEP20"
                                : it.type == "TRON"
                                ? "TRC20"
                                : it.type}
                            </span>
                          }
                          </div>
                        </div>
                      </td>

                      {allLoanTypes?.data?.map((inter) => {
                        return (
                          <td>
                            <div className="innerTd passive">
                              {
                                it?.allInterests.filter(
                                  (tp) => inter.id == tp?.loanTypeId
                                )?.[0]?.interest
                              }
                              %
                            </div>
                          </td>
                        );
                      })}

                      <td>
                        <div className="innerTd">
                          <span
                            className="borrow"
                            onClick={() => {
                              handleBorrowPage(it , index);
                            }}
                          >
                            Borrow
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {allLoans !== undefined && allLoans.count > 10 && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(allLoans.count / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allLoans.count / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={(e) => {
                        setskip(e.selected * limit);
                        loanRequestListAsync({
                          skip: e.selected * limit,
                          limit,
                        });

                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, wallet = {} }) => {
  const loanRequestLoad = _.get(wallet, "borrowLoanLoading", false);
  const allLoans = _.get(wallet, "borrowLoan", undefined);
  const loanRequestError = _.get(wallet, "borrowLoanError", undefined);
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const allLoanTypes = _.get(wallet, "allLoanTypes", false);
  return {
    thememode,
    settings,
    loanRequestLoad,
    allLoans,
    loanRequestError,
    allLoanTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loanRequestListAsync: (data) =>
    dispatch({ type: "borrowLoanSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BorrowMarket);
