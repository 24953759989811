import React from "react";
import ReactDOM from "react-dom";
import './i18nextConf';

const loader = document.querySelector('.loaderrr');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

const render = () => {
  const App = require("./App").default;
  ReactDOM.render(<App showLoader={showLoader} hideLoader={hideLoader} />, document.getElementById("root"));
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}
