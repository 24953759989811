export default {
  EMAIL_NOT_FOUND: "E-mail bulunamadı",
  EMAIL_USED: "Bu E-mail kullanılıyor",
  EMAIL_NOT_VERIFIED: "E-mail doğrulanmadı",
  MOBILE_NOT_VERIFIED: "Telefon numarası doğrulanmadı",
  INVALID_PASSWORD: "Geçersiz şifre",
  "pass_token used": "pass_token kullanılmış",
  "pass_token expire": "Pass_token'in süresi doldu",
  "Password should not be empty": "Şifre alanı boş olmamalıdır",
  ConfirmTheLogin: "Girişi onaylayın",
  FromNewDevice: "Yeni cihaz",
  EmailConfirmationCode: "E-mail Onay Kodu",
  Enter6digitCode: "Alınan 6 haneli kodu girin",
  AuthenticationCode: "Kimlik doğrulama kodu",
  GoogleAuthenticatorCode:
    " Google Authenticator'dan alınan 6 haneli kodu girin",
  NoAccessSecurityCheck: "Güvenlik kontrolüne erişim yok mu?",
  Send: "Gönder",
  Resend: "Yeniden gönder",
  GetCode: "Kodu al",
//  undefined: "Tanımsız",
  "exchangeId should not be empty": "Döviz ID alanı boş bırakılmamalıdır",
  WantToLogout: "Oturumu kapatmak istiyor musunuz?",
  Yes: "Evet",
  No: "Hayır",
  Invaild_role: "Geçersiz rol",

  //Newly added
  EmailID: "E-mail adresinizi girin",
  EnterPassword: "Şifre girin",
  Email: "E-mail",
  Password: "Şifre",
  DontHaveAccount: "Hesabınız yok mu?",
  Register: "Kayıt ol",
  SignInTo: "Giriş yap",
  CyborPay: "PayCrypt",
  ForgotPassword: "Şifrenizi unuttunuz mu?",
  SignIn: "Giriş yap",
  SignUp: "Kayıt ol",
  PasswordNotEmpty: "Şifre alanı boş olmamalıdır",
  ForgotPassword: "Şifrenizi unuttunuz mu?",
  ProvideEmail: "Lütfen e-mail adresinizi girin. Şifrenizi güncellemek için bağlantı içeren bir mail alacaksınız",
  YourEmail: "E-mail adresiniz",
  ResetPassword: "Şifreyi yenile",
  PasswordResetEmailSent: "Şifre Sıfırlama maili Gönderildi",
  EmailHasSent: "E-mail adresinize bir mail gönderildi. Şifrenizi sıfırlamak için mail'deki talimatları izleyin.",
  OK: "Tamam",
  CyborPayBusiness:"PayCrypt İş",
  CheckProtectYourAccount: "Hesabınızı korumak için lütfen aşağıdakilerini kontrol edin",
  WelcomeLine1: "PayCrypt ödeme sistemimizi kullandığınız için teşekkür ederiz",
  WelcomeLine2: "Sistem şu anda beta test sürecindedir. Sistemimizin testlerine katılım sağlayabilirsiniz.","Invalid Authentication Code": "Geçersiz Kimlik Doğrulama Kodu",
  InvalidEmailCode: "Geçersiz E-posta Kodu",
  support24: "Desteğimiz sizin için çalışıyor",
  securitytitle1: "Lütfen teknik ekibimizle iletişime geçin",
  securitytitle2: "erişimi geri yüklemek için destek",
  securitytitle3: "hesabınızı e-posta yoluyla",
  optionselection1: "Lütfen seçin",
  optionselection2: "aşağıdaki seçenek",
  buttonselection1: "E-postaya erişiminiz yok",
  buttonselection2: "GA'ya erişiminiz yok",
  MOB_EMPTY: "Cep telefonu numarası boş",
  EMAIL_EMPTY: "E-posta kimliği boş",
  PASS_EMPTY: "Şifre boş",
  EXCHANGE_EMPTY: "Exchange kimliği boş",
  LANG_EMPTY: "Lütfen dil seçin",
  NAME_EMPTY: "Ad boş",
  SUB_EMPTY: "Soyadı boş",
  EMAIL: "Geçersiz e-posta",
  PASS_STRING: "Şifre bir dize olmalıdır",
  MIN_LENGTH: "Şifre minimum 8 karakterden oluşmalıdır",
  MAX_LENGTH: "Şifre 20 karakterden uzun olmamalıdır",
};
