import React, { useEffect, useState } from "react";
import { Container,Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import ScreenLayout from "@components/layout/ScreenLayout";
import CustomButton from "@components/custom-button/CustomButton";
import NFCCard from "@components/nfc-card/NFCCard";
import IssueCardPopup from "@components/issue-card-popup/IssueCardPopup";
import '@features/crypto-card/CryptoCardStyle.scss';
import IssueCardSuccessPopup from "src/components/issue-card-success/IssueCardSuccessPopup";

function CryptoCard({hideLoaderOnRefresh, listtNfcCards, listCards}) {
    const { t } = useTranslation();
    let [show, setshow] = useState(false);
    let [showSuccess, setShowSuccess] = useState(false);
    let [showFailure, setShowFailure] = useState(false);
    let [limit, setlimit] = useState(30);
    let [skip, setskip] = useState(0);

    useEffect(() => {
        listtNfcCards({ skip: 0, limit: 30 })
    }, [])

    console.log("check data", listCards)
  return (
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh} >
        <Container>
        <div id='CryptoCard'>
            <Row>
                <div className="top-btn">
                <CustomButton
                      type="submit"
                      name={t("Issue Card")}
                      filled={true}
                      primary={true}
                      onClick={() => {
                        setshow(true);
                      }}
                    />
                </div>
               <NFCCard cards={listCards !== undefined && listCards.data}/>
            </Row>
            </div>
        </Container>
        <IssueCardPopup
        setShowModal={setshow}
        show={show}
        skip={skip}
        limit={limit}
        setShowSuccess={(e) => setShowSuccess(e)}
        />

        <IssueCardSuccessPopup 
        show={showSuccess}
        setShowModal={setShowSuccess}
        />
    </ScreenLayout>

  )
}

const mapStateToProps = ({ user = {}}) => {
    const listCards = _.get(user, "listNfcCard", [] );

    return { listCards};
}

const mapDispatchToProps = (dispatch) => ({
    listtNfcCards: (data) => 
    dispatch({ type: "listUserNfcCardSageCalled", payload: data})
  });

export default connect(mapStateToProps, mapDispatchToProps)(CryptoCard);