import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/login-welcome-popup/LoginWelcomePopup.scss";
import BusinessSignup from "@features/onboarding/business-signup/BusinessSignup";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import { openBusinessForm } from "@store/userredux";

const LoginWelcomePopup = ({
  exchange,
  thememode,
  show,
  show1,
  setShowModal,
  role,
  businessForm,
  auth,
  branch,
  openBusiness,
}) => {
  const { t } = useTranslation();
  const [showBusinessForm, setShowBusinessForm] = useState(false);

  return (
    <Modal
      className="LoginWelcomePopup"
      show={show}
      onHide={() => setShowModal()}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          {role == "Business" && (
            <>
              <div className="imagesection">
                {/* <img
              alt=""
              className="imgonsignup"
              src={
                thememode == "dark"
                ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessDarkmode}`
                : `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessLightmode}`
              }
            /> */}
                <img src={paymentsolutionlogo} className="imgonsignup" />
              </div>
            </>
          )}
          {role != "Business" && (
            <>
              <div className="imagesection">
                {/* <img
              alt=""
              className="imgonsignup"
              src={
                thememode == "light"
                ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalLightmode}`
                : `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalDarkmode}`
              }
            /> */}
                <img src={paymentsolutionlogo} className="imgonsignup" />
              </div>
            </>
          )}
          <div className="textsection">
            <div className="lineone">
              <span className="text">{t("WelcomeLine1")}</span>
            </div>
            <div className="linetwo">
              <span className="text1">{t("WelcomeLine2")}</span>
            </div>
          </div>

          <div className="outerconforbtn">
            <CustomButton
              type="submit"
              name={t("OK")}
              filled={true}
              primary={true}
              onClick={() => {
                if (!branch && auth && role === "Business") {
                  openBusiness();
                  setShowModal(false);
                } else {
                  setShowModal(false);
                }
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ user = {} }) => {
  const role = _.get(user, "userInfo.role", "");
  const businessForm = _.get(user, "MyProfileInfo.businessForm", false);
  const auth = _.get(user, "auth", false);
  const branch = _.get(user, "userInfo.branch", false);
  return {
    role,
    businessForm,
    auth,
    branch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openBusiness: () => dispatch(openBusinessForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(LoginWelcomePopup));
