import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import CoinListLayout from "src/components/coin-list-layout/CoinListLayot";
import QrToggle from "src/components/qr-toggle/QrToggle";
import "@components/deposit-modal/DepositModalStyles.scss";
import SharePopup from "../share-popup/SharePopup";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import IdoBuyPopup from "@components/ido-buy-popup/IdoBuyPopup";
import DropdownDepositModal from "../dropdown-for-deposit-modal/DropdownDepositModal";
import { connect } from "react-redux";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import { useTranslation } from "react-i18next";
import WithdrawFormWithToggle from "src/components/withdraw-form-with-toggle/WithdrawFormWithToggle";
import FooterCommon from "@components/footer-common/FooterCommon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AmountPoupup from "../amount-popup/AmountPoupup";
const DepositModal = ({
  show,
  setShowWithdraw,
  value,
  setvalue,
  getBal,
  getAdd,
  coinItem,
  setcoinItem,
  setdepositModal,
  showToggle,
  tableno,
  setDepositModal,
  option,
  settableno,

  getplatformTokensinfo,
  myaccountandbalance,
}) => {
  let [showsharem, setshowsharem] = useState(false);
  let [showamountm, setshowamountm] = useState(false);
  let [Selected, setSelectedIndex] = useState(0);
  let [idoModal, setIdoModal] = useState(false);
  const { t, i18n, ready } = useTranslation();
  useEffect(() => {
    // console.log('to know data coming' , getplatformTokensinfo)
    // setSelectedIndex(
    //   getplatformTokensinfo.findIndex((e) => e.id == coinItem && coinItem.id)
    // );
  }, [coinItem]);
  // useEffect(() => {
  //   // getExchangerate({
  //   //   token1: option && option[Selected] && option[Selected].id,
  //   //   id: idorounds && idorounds[0] && idorounds[0].id,
  //   // });
  // }, [Selected]);
  let balances = Number(
    getBal(option && option[Selected] && option[Selected].id)
  ).toFixed(6);
   return (
    <>
      <SharePopup
        coinItem={coinItem}
        setShowModal={setshowsharem}
        show2={showsharem}
        setdepositModal={setdepositModal}
      />
      {idoModal&&<AmountPoupup
        show1={idoModal}
        setShowModal1={(e) => {
          setDepositModal(true)
          setIdoModal(e)}}
        getplatformTokensinfo={getplatformTokensinfo}
        myaccountandbalance={myaccountandbalance}
        selectedCoin={{ coin: coinItem }}
      />}
      <Modal
        className="depositmodal"
        show={show}
        onHide={() => setdepositModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modalPrimaryHeader hidden-for-desktop"></div>
          <CloseIcon
            className="closebtn"
            onClick={() => setdepositModal(false)}
          />
          <div
            className="back hidden-for-desktop"
            onClick={() => setdepositModal(false)}
          >
            <ExpandIcon />
          </div>
          <Container>
            <Row>
              <Col lg={6}>
                <div className="hidetoggle togglespace">
                  <CurrencyTab
                    page={"currency"}
                    tableno={tableno}
                    changeTab={(e) => settableno(e)}
                  />
                </div>
                <CoinListLayout
                  setcoinItem={setcoinItem}
                  value={value}
                  setvalue={setvalue}
                  getplatformTokensinfo={getplatformTokensinfo}
                  getBal={getBal}
                />
                <div className="hidden-for-desktop">
                  <Col lg={4}>
                    <div className="textleft cointop">
                      <span className="labelfont">{t("SelectCoin")}</span>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <DropdownDepositModal
                      setcoinItem={setcoinItem}
                      value={value}
                      setvalue={setvalue}
                      getplatformTokensinfo={getplatformTokensinfo}
                      getBal={getBal}
                      options={getplatformTokensinfo}
                      setSelectedIndex={(e) => {
                          setSelectedIndex(e);
                          setcoinItem(getplatformTokensinfo[e]);
                      }}
                      Selected={Selected}
                       balance={(items)=>getBal(items.id)}
                      // symbol={items.exchangeSymbol}
                    />
                  </Col>
                  <Row>
                    <Col lg={4}>
                      <div className="textleft cointop">
                        <span className="labelfont">{t("SelectCoin")}</span>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <CoinListLayout
                        setcoinItem={setcoinItem}
                        value={value}
                        setvalue={setvalue}
                        getplatformTokensinfo={getplatformTokensinfo}
                        getBal={getBal}
                      />
                    </Col>

                    {/* <Col lg={8}>
              <DropdownSearch
                setcoinItem={setcoinItem}
                options={getplatformTokensinfo}
                coinItem={coinItem}
                setSelectedIndex={(e) => {
                  console.log('index -' ,e )
                  setSelectedIndex(e);
                  setcoinItem(getplatformTokensinfo[e]);
                }}
                Selected={Selected}
              />
              <div className="textleft balancespace">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 500 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {Number(getBal(coinItem.id)).toFixed(6)}{" "}
                      {coinItem.exchangeSymbol}
                    </Tooltip>
                  )}
                >
                  <span className="labelfont balancetext balancehid">
                    {t("Balance")}: {Number(getBal(coinItem.id)).toFixed(6)}{" "}
                    {coinItem.exchangeSymbol}
                  </span>
                </OverlayTrigger>
              </div>
            </Col> */}
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <QrToggle
                  coinItem={coinItem}
                  getAdd={getAdd}
                  showToggle={showToggle}
                  tableNo={tableno}
                  setTableNo={settableno}
                  setsharem={setshowsharem}
                  setamountm={setshowamountm}
                  setIdo={setIdoModal}
                />
              </Col>
            </Row>
          </Container>
          {/* <div className="modalPrimaryHeader hidden-for-desktop"></div> */}
          <div className="hidden-for-desktop">
            <FooterCommon />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ ido, wallet, app, user }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const idoBalance = _.get(ido, "idoBalance", 0);
  const idobuyModal = _.get(app, "idobuyModal", false);
  const buyModal = _.get(app, "buyModal", false);
  const role = _.get(user, "userInfo.role", "");
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const idorounds = _.get(ido, "idorounds", []);

  return {
    getplatformTokensinfo,
    role,
    idoBalance,
    idobuyModal,
    myaccountandbalance,
    buyModal,
    idorounds,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setbuyModal: (data) => dispatch({ type: "buyModalCalled", payload: data }),
  getExchangerate: (data) =>
    dispatch({ type: "getCyborExchangeRateCalled", payload: data }),
  getRountido: (data) => dispatch({ type: "getRountidocalled", payload: data }),
  setDepositModal: (data) =>
    dispatch({ type: "DepositModalCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositModal);
