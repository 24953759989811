import React,{useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as DropdownarrowIcon } from "@images/dropdown.svg";
import { ReactComponent as Dropdownarrow } from "@images/downarrow.svg";
import "@components/small-dropdown/SmallDropdownStyles.scss";

const SmallDropdown = ({
    options,
    selectedOption,
    setSelectedOption,
    middleline,
    useFilledDownArrow
}) => {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <span
          href=""
          ref={ref}
          className="smalltoggletext"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
            <span className="toogleicon">
              {
                middleline &&
                <span className="navvl navline tempdisable"></span>
              }
              {
                useFilledDownArrow == true &&
                <Dropdownarrow className="dropdownarrow" /> ||
                <DropdownarrowIcon className="dropdownarrow" />
              }
              
            </span>
        </span>
    ));

  return (
    <div className="SmallDropdown">
      <Dropdown
        // align="end"
        autoClose="true"
        onSelect={(eventKey, event) => setSelectedOption(eventKey)}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <span className="filtertext">{selectedOption}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options &&
            options.map((data) => (
              <Dropdown.Item eventKey={data.key} active={data.apiKey === localStorage.getItem('lang') ? true : false}>{data.key}</Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SmallDropdown;
