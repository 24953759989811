import React from "react";
import cyborcoin from "@images/cyborcoin.svg";
import "@components/about-cybor-coin-card/AboutCyborCardStyles.scss";
import { ReactComponent as CyborCoin } from '@images/cyborcoin.svg';
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as Download} from '@images/downloadwhitepaper.svg';
import { useTranslation } from "react-i18next";

const AboutCyborCard = ({ exchange, thememode }) => {
  const lang = localStorage.getItem('lang');
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="aboutcybor">
      <div className="iconwithtextinabout">
        <div className="abouttext">{t("AboutCyborCoin")}</div>
      </div>
      <div className="aboutcyborcontent">

        <div className="imagearea">
          <CyborCoin />
        </div>

        <div className="textarea">
          <span className="title">{t("BlockchainBusiness")}</span>
          <p className="para">{t("AboutPara")}</p>
        </div>

        <div className="idobtns">
          <div className='d-flex idoWhiteBtns'>
            <a href={
              lang == "en" ? 
              "https://cyborcoin.com/data/main/docs/white_paper_en.pdf" : 
              lang == 'ru' ? 
              'https://cyborcoin.com/data/main/docs/white_paper_ru.pdf' : 
              'https://cyborcoin.com/data/main/docs/white_paper_tr.pdf' 
              }
              target="_blank">
              <CustomButton
              name={t("OpenWhitepapers")}
              filled={true}
              primary={false}
              className="buttonmain"
              onClick={() => {
              }}
              />
              </a>
              <a href={
                  lang == "en" ? 
                  "https://cyborcoin.com/data/main/docs/tokenomics_en.pdf" : 
                  lang == 'ru' ? 
                  'https://cyborcoin.com/data/main/docs/tokenomics_ru.pdf' : 
                  'https://cyborcoin.com/data/main/docs/tokenomics_tr.pdf' 
                  }
                  target="_blank">
              <CustomButton
              name={t("OpenTokenomics")}
              filled={true}
              primary={false}
              className="buttonmain"
              onClick={() => {
              }}
              />
              </a>
          </div>
          <div  className='d-flex idoBlueBtns'>
          <a href={
              lang == "en" ? 
              "https://cyborcoin.com/data/main/docs/white_paper_en.pdf" : 
              lang == 'ru' ? 
              'https://cyborcoin.com/data/main/docs/white_paper_ru.pdf' : 
              'https://cyborcoin.com/data/main/docs/white_paper_tr.pdf' 
              }
              target="_blank">
              <CustomButton
              name={
              <>
                  <Download />
                  <span className="ms-1">{t("DownloadWhitepapers")}</span>
              </>
              }
              filled={true}
              primary={true}
              className="buttonmain"
              onClick={() => {
              }}
              />
              </a>
              <a href={
                  lang == "en" ? 
                  "https://cyborcoin.com/data/main/docs/tokenomics_en.pdf" : 
                  lang == 'ru' ? 
                  'https://cyborcoin.com/data/main/docs/tokenomics_ru.pdf' : 
                  'https://cyborcoin.com/data/main/docs/tokenomics_tr.pdf' 
                  }
                  target="_blank">
              <CustomButton
              name={
                  <>
                  <Download />
                  <span className="ms-1">{t("DownloadTokenonmics")}</span>
              </>
              }
              filled={true}
              primary={true}
              className="buttonmain"
              onClick={() => {
              }}
              />
              </a>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default React.memo(AboutCyborCard);
