import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Form } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import Branchsuccessful from "@components/branch-successful/Branchsuccessful";
import InputField from "@components/input-field/InputField";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import pprofile from "@images/pprofile.svg";
import { connect } from "react-redux";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import "@features/create-loan-type/CreateLoanType.scss";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import BranchCreatedSuccessfulPopup from "@components/branch-created-successful-popup/BranchCreatedSuccessfulPopup"
import AdminLoanSuccesfulPopup from "src/components/admin-loan-template-successfull-popup/AdminLoanSuccesfulPopup";
// import {useHistory} from 'react-router-dom'
import {clearplatformerroSuccess} from '@store/platformuserredux';
function CreateLoanType({
  createLoanLoading,
  createLoanError,
  createLoanAsyncSagaCalled,
  getplatformTokensinfo,
  clearBackendError,
}) {
  const history = useHistory();
  const [loanTypeName , setLoanTypeName] = useState("")
  const [tenure , setTenure] = useState("")
  const [initialLTV , setInitialLTV] = useState("")
  const [coin , setCoin] = useState("");
  const [liquidationLTV , setLiquidationLTV] = useState("")
  const [validated , setValidated] = useState(false);
  const [coinId , setCoinId] = useState("");
  let [sucess , setShowSuccess] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValid2 , setIsValid2] = useState(true);
  const [isValid3 , setIsValid3] = useState(true);

  useEffect(()=>{
    clearBackendError()
  } , [])
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    setTenure(e.target.value);
    
    // setInputValue(value);
    setIsValid(value >= 0) && setFlag(true); // Check if the value is non-negative
  };
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInitialLTV(e.target.value);
    
    // setInputValue(value);
    setIsValid2(value >= 0) && setFlag(true); // Check if the value is non-negative
  };
  const handleInputChange3 = (e) => {
    const value = e.target.value;
    setLiquidationLTV(e.target.value);
    
    // setInputValue(value);
    setIsValid3(value >= 0) && setFlag(true); // Check if the value is non-negative
  };
  const cancelCreation = () => {
    setLoanTypeName("")
    setTenure("")
    setInitialLTV("")
    setLiquidationLTV("")
    setValidated(false);
    setIsValid(true)
    setIsValid2(true)
    setIsValid3(true)
    clearBackendError()
    history.push('/adminLoanManagement')
  };

  const createLoanType = (form) => {

     const data = {
      loanName : loanTypeName,
      tenureInHours : (tenure * 24).toString(),
      // coinId : Number(coinId),
      initialLTV : initialLTV,
      liquidationLTV : liquidationLTV, 
      openSuccess : ()=>setShowSuccess(true)
     }
     console.log('data' , data)
     createLoanAsyncSagaCalled(data)
  };

  const FormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      // clearErrorMessage();
    } else {
      event.preventDefault();
      event.stopPropagation();
      if(isValid && isValid2 && isValid3){
       createLoanType(form);
      }
      
    }
  };

   // const onSendmsg = (event) => {
  //   event.preventDefault();
  //   if (Image !== null && image !== '') {
  //     uploadImage();
  //   }
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   setValidated(true);
  // };

 
  

  
  
  const { t } = useTranslation();
  return (
    <ScreenLayout className="hidden">
      <div className="modalPrimaryHeader"></div>
      <div id="CreateLoan">
        {/* <BranchCreatedSuccessfulPopup showModal={createbranchsuccesspopup}  /> */}
        <AdminLoanSuccesfulPopup
          setShowModal={setShowSuccess}
          showModal={sucess}
          message={" Loan Type created successfully"}
        />
        <Container className="containerallignm">
          {createLoanLoading && <Loader />}
          <Row>
            <Col lg={12}>
              
              <Form noValidate onSubmit={FormSubmit} validated={validated}>
                <div className="outercontainer">
                  <div className="hideformobile toptitle">
                    <span>{t("Create Loan Type")}</span>
                  </div>
                  <div className="hidefordesktop">
                  <div className="d-flex align-items-center mb-5" onClick={()=>history.goBack()} >
                    <Arrow className="arrowicon" />
                    <p className="addNewText">{t("Create Loan Type")}</p>
                  </div>
                  </div>
                 

                 
                  <div className="titles">
                    <span>{t("Loan Type Name")}</span>
                    <div className="inputbox">
                      <InputField
                        type="text"
                        onChange={(e) => setLoanTypeName(e.target.value)}
                        value={loanTypeName}
                        isInvalid={validated === true}
                        required
                        error={
                          loanTypeName.length === 0
                            ? "Please enter loan type name"
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="titles">
                    <span>{t("Tenure (In Days)")}</span>
                    <div className="inputbox">
                      <InputField
                        type="number"
                        onChange={(e) => handleInputChange(e)}
                        value={tenure}
                        isInvalid={validated === true}
                        required
                        error={
                          tenure.length === 0
                            ? "Please enter tenure"
                            : ""
                        }
                      />
                    </div>
                    {!isValid && 
                  <span className="errortext">Please enter a non-negative number.</span>
                 }
                  </div>

                 
                    {/* <div className="titles">
                    <span>{t("Coin")}</span>
                    <div className="inputbox">
                    <DropdownSearch
                        Selected={coin}
                        options={getplatformTokensinfo}
                        setSelectedIndex={(e) => {setCoin(e)}}
                        setCoinId = {(e)=>setCoinId(e)}
                        scope={"loan"}
                      />
                    </div>
                  </div> */}
                  
                  <div className="titles">
                    <span>{t("Initial LTV")}</span>
                    <div className="inputbox">
                      <InputField
                        type="number"
                        onChange={(e) => handleInputChange2(e)}
                        value={initialLTV}
                        isInvalid={validated === true}
                        required
                        error={
                          initialLTV.length === 0
                            ? "Please enter Initial LTV"
                            : ""
                        }
                      />
                    </div>
                    {!isValid2 && 
                  <span className="errortext">Please enter a non-negative number.</span>
                }
                  </div>
                  <div className="titles">
                    <span>{t("Liquidation LTV")}</span>
                    <div className="inputbox">
                      <InputField
                        type="number"
                        onChange={(e) => handleInputChange3(e)}
                        value={liquidationLTV}
                        isInvalid={validated === true}
                        required
                        error={
                          liquidationLTV.length === 0
                            ? "Please enter Liquidation LTV"
                            : ""
                        }
                      />
                    </div>
                    {!isValid3 && 
                  <span className="errortext">Please enter a non-negative number.</span>
                }
                  </div>
                 

                  <div className="btmbtnoutercontainer">
                    <div className="topbuttons">
                      <CustomButton
                        type="submit"
                        name={t("Create")}
                        filled={true}
                        primary={true}
                        //onClick={createBranch}
                      />
                    </div>
                    <div className="bottombuttons">
                      <CustomButton
                        type="button"
                        name={t("Cancel")}
                        filled={true}
                        primary={true}
                        onClick={cancelCreation}
                      />
                    </div>
                  </div>
                  <p className="errortext">{createLoanError}</p>
                </div>
              </Form>
            </Col>
          </Row>
          {/* <Branchsuccessful
            showDeleteConfirmation={businessModal}
            setShowDeleteConfirmation={(e)=>{
              setbusinessModals(false)
              history.push('/branchmanagement')
            }}
          /> */}
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {} }) => {
  const createLoanLoading = _.get(wallet , "addLoanSupportInPlatformLoading" , false);
  const createLoanError = _.get(wallet , "addLoanSupportInPlatformError" , undefined);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);

  return {
    createLoanLoading,
    createLoanError,
    getplatformTokensinfo
  };
};
const mapDispatchToProps = (dispatch) => ({

    createLoanAsyncSagaCalled : (data)=>dispatch({type : "createLoanSupportInPlatformSagaCalled" , payload : data}),
    clearBackendError : ()=>dispatch(clearplatformerroSuccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoanType);
