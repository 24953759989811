import Table from "react-bootstrap/Table";
import React, { useState, useEffect, useCallback } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import WithdrawalFiat from "../withdrawal-fiat/WithdrawalFiat";
import DepositPopup from "@components/deposit-popup/DepositPopup.js";
import ReactPaginate from "react-paginate";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import DepositFiatModal from "../depositfiat-modal/DepositFiatModal";
import CoinList from "@components/coin-list/CoinList";
import "@components/fiat-table/FiatTable.scss";
import _ from "lodash";
import { connect } from "react-redux";
import SmallButton from "@components/small-button/SmallButton";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";

function FiatTable({
  plaformcurrencies,
  getBal,
  setwithdrawModal,
  setDepositModal,
  setFiatDepositModal,
  fiatDepositModal,
  searchvalue,
  fiatBalance,
  fiatWalletBalance,
  setFiatWithdrawModal,
  FiatWithdrawModal,
  role,
  platformBanks,
  onClick,
  setExchangeModal,
  hideZeroBalance,
  setcoinItem,
  dashboardDisplayCurrencies,
  getPlatformCurrencys,
}) {
  const [allCurrencies, setPlaformCurrencies] = useState([]);
  const [balances, setBalances] = useState([{ balance: 0 }, { balance: 0 }]);
  const [show, setShowDeposit] = useState(false);
  const handleClose = () => setShowDeposit(false);
  const [items, setItem] = useState("");
  let [pages, setpages] = useState(0);
  let [tableno, settableno] = useState("second");
  const [limit, setlimit] = useState(6);
  const [orderBy, setOrderBy] = useState("");
  const [sortBy, setSortBy] = useState("");

  const { t, i18n, ready } = useTranslation();

  useEffect(() => {
    filterAllCurrencies();
  }, [dashboardDisplayCurrencies.fiat, plaformcurrencies]);

  const filterAllCurrencies = useCallback(() => {
    let newData = plaformcurrencies.filter((itm) => {
      // let dt = dashboardDisplayCurrencies.fiat ;
      let dt =
        dashboardDisplayCurrencies && dashboardDisplayCurrencies.fiat
          ? dashboardDisplayCurrencies.fiat
          : "";
      if (dt !== undefined && dt.includes(itm.id)) {
        return itm;
      }
    });
    setPlaformCurrencies(newData);
  }, [dashboardDisplayCurrencies.fiat, plaformcurrencies]);

  useEffect(() => {
    if (
      dashboardDisplayCurrencies &&
      dashboardDisplayCurrencies.fiat &&
      dashboardDisplayCurrencies.fiat.length !== 0
    ) {
      let newData = plaformcurrencies.filter((itm) => {
        let dt = dashboardDisplayCurrencies.fiat;
        if (dt.includes(itm.id)) {
          return itm;
        }
      });
      setPlaformCurrencies(
        newData
          .filter((data) => {
            if (
              data.currencyName
                .toLowerCase()
                .includes(searchvalue.toLowerCase())
            ) {
              return data;
            }
          })
          .map((itm) => itm)
      );
    }
  }, [searchvalue]);

  useEffect(() => {
    filterAllCurrencies();
    fiatBalance();
  }, []);

  const getFiatBal = (cur) => {
    let obj =
      fiatWalletBalance &&
      fiatWalletBalance.filter((item) => item.currencyId == cur);
    let reelbal = _.get(obj, "[0].balance", 0);
    return reelbal;
  };

  const trsection = (items, index) => {
    return (
      <tr key={`Tokens${index}`} onClick={() => onClick && onClick(items)}>
        <td className="colspace">
          <div className="innerTd d-none d-lg-block">
            <CoinList
              logo={items.nonColouredLogo}
              name={items.currencyName}
              logoBgColor={items.logoBgColor}
            />
          </div>
          <div className="innerTd d-flex d-lg-none">
            <CoinList
              type={items.type}
              logo={items.nonColouredLogo}
              name={items.tokenName}
              logoBgColor={items.logoBgColor}
              symbol={items.exchangeSymbol}
              currencyName={items.currencyName}
              shownamewithsymbol={true}
            />
            {(items.type == "TRC20" ||
              items.type == "BEP20" ||
              items.type == "ERC20") && (
              <span className="cointag d-none d-lg-block">{items.type}</span>
            )}
          </div>
        </td>
        <td className="balance colspace d-none d-lg-block">
          <div className="innerTd">
            {BigNumber(getFiatBal(items.id)).toFixed(2, BigNumber.ROUND_FLOOR)}
          </div>
        </td>

        <td
          className={`${
            window.innerWidth <= 992 ? "colspace" : "colspace1"
          } actions`}
        >
          <div className="innerTd">
            <div className="mobilescrbal d-block d-lg-none">
              <p className="mainbal">
                {BigNumber(getFiatBal(items.id)).toFixed(
                  4,
                  BigNumber.ROUND_FLOOR
                )}
              </p>
              <p className="mainsym">{items.exchangeSymbol}</p>
            </div>
            {/* <CustomButton
              type="submit"
              name={t("Deposit")}
              filled={true}
              primary={false}
              onClick={() => {
                setFiatDepositModal(true);
                setItem(items);
              }}
            /> */}
            <div className="smallbuttons d-none d-lg-flex">
              <SmallButton
                type="deposit"
                hideButtonName={true}
                onclick={() => {
                  setFiatDepositModal(true);
                  setItem(items);
                }}
              />
              <SmallButton
                type="withdraw"
                hideButtonName={true}
                onclick={() => {
                  setFiatWithdrawModal(true);
                }}
              />
              <SmallButton
                type="exchange"
                hideButtonName={true}
                onclick={() => {
                  setcoinItem(items);
                  setExchangeModal(true);
                }}
              />
            </div>
          </div>
        </td>

        {/* <td className="colspace1 actions"> */}
        {/* <CustomButton
            type="submit"
            name={t("Withdraw")}
            filled={true}
            primary={false}
            onClick={() => {
              setFiatWithdrawModal(true);
            }}
          /> */}
        {/* </td> */}
      </tr>
    );
  };

  return (
    <>
      <DepositPopup />
      <div className="fiattable">
        {
          <Table responsive="sm" className="tablestyle" borderless>
            <thead>
              <tr>
                <th className="tableheading coincol">
                  {t("Currency")}{" "}
                  <span className="sorticon">
                    <SortupIcon
                      className="uparrow"
                      onClick={() => {
                        setOrderBy("currencyName");
                        setSortBy("ASC");
                        getPlatformCurrencys({
                          skip: pages * limit,
                          limit,
                          orderBy: "currencyName",
                          sort: "ASC",
                        });
                      }}
                    />

                    <SortdownIcon
                      className="downarrow"
                      onClick={() => {
                        setOrderBy("currencyName");
                        setSortBy("DESC");
                        getPlatformCurrencys({
                          skip: pages * limit,
                          limit,
                          orderBy: "currencyName",
                          sort: "DESC",
                        });
                      }}
                    />
                  </span>
                </th>
                <th className="tableheading">
                  {t("Balance")}{" "}
                  {/* <span className="sorticon">
                    <SortupIcon className="uparrow" onClick={() => {
                     
                    }}/>

                    <SortdownIcon className="downarrow" />
                  </span> */}
                </th>
                <th className="tableheading">{t("Functions")}</th>
              </tr>
            </thead>
            <tbody>
              {allCurrencies &&
                allCurrencies
                  .slice(pages * limit, pages * limit + limit)
                  .map((items, index) => {
                    if (hideZeroBalance) {
                      return trsection(items, index);
                    }
                    if (!hideZeroBalance) {
                      return trsection(items, index);
                    }
                  })}
            </tbody>
          </Table>
        }

        <DepositFiatModal
          setShowDeposit={setFiatDepositModal}
          show={fiatDepositModal}
          setTableno={() => {
            setFiatDepositModal(false);
            setDepositModal(true);
          }}
          tableNo={tableno}
          showToggle={true}
          selectedCurrency={items}
          allplatformbanks={platformBanks}
          setcoinItem={setItem}
        />
        <WithdrawalFiat
          setShowWithdraw={setFiatWithdrawModal}
          show1={FiatWithdrawModal}
          showToggle={true}
          setTableno={() => {
            setFiatWithdrawModal(false);
            setwithdrawModal(true);
          }}
          tableNo={tableno}
          selectedCurrency={items}
        />
      </div>
      {plaformcurrencies.length > 10 && (
        <div className="paginationsection">
          <div className="paginationtxtholer">
            <span className="noofpagetxt">
              {t("Showing")} {pages + 1} {t("from")}{" "}
              {Math.ceil(plaformcurrencies && plaformcurrencies.length / limit)}{" "}
            </span>
          </div>

          <div className="paginationallignment">
            <ReactPaginate
              previousLabel={<PreviousIcon className="paginationicon" />}
              nextLabel={<NextIcon className="paginationicon" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={plaformcurrencies && plaformcurrencies.length / limit}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={(e) => {
                getPlatformCurrencys({
                  skip: e.selected * limit,
                  limit,
                  orderBy: orderBy,
                  sort: sortBy,
                });
                setpages(e.selected);
              }}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ fiat = {}, user = {}, app = {} }) => {
  const fiatWalletBalance = _.get(fiat, "fiatBalance", false);
  const FiatWithdrawModal = _.get(app, "FiatWithdrawModal", false);
  const platformBanks = _.get(fiat, "platformBanks", []);
  const dashboardDisplayCurrencies = _.get(
    user,
    "dashboardDisplayCurrencies",
    []
  );
  return {
    fiatWalletBalance,
    FiatWithdrawModal,
    platformBanks,
    dashboardDisplayCurrencies,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fiatBalance: (data) =>
    dispatch({ type: "callFiatBalanceStartCalled", payload: data }),
  setwithdrawModal: (data) =>
    dispatch({ type: "withdramodalcalled", payload: data }),
  setDepositModal: (data) =>
    dispatch({ type: "DepositModalCalled", payload: data }),
  setFiatWithdrawModal: (data) =>
    dispatch({ type: "setFiatWithdrawModalCalled", payload: data }),
  getPlatformCurrencys: (data) =>
    dispatch({ type: "getPlatformCurrencysCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FiatTable);
