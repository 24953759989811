import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import "@components/withdrawal/WithdrawalStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import CoinListLayout from "src/components/coin-list-layout/CoinListLayot";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import FooterCommon from "@components/footer-common/FooterCommon";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import WithdrawFormWithToggle from "src/components/withdraw-form-with-toggle/WithdrawFormWithToggle";

const Withdrawal = ({
  getBal,
  setShowWithdraw,
  value,
  show1,
  setcoinItem,
  coinItem,
  getplatformTokensinfo,
  getAdd,
  setvalue,
  tableno,
  settableno,
  showToggle,
}) => {
  const [hideThis, setHideThis] = useState(false);

  return (
    <Modal
      className={`Withdrawal  ${hideThis && "hidethisexchangemodal"}`}
      show={show1}
      onHide={() => setShowWithdraw(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName={`${hideThis && "mod-exchangebackdrop"}`}
    >
      <div className="modalPrimaryHeader d-lg-none d-bock"></div>

      <Modal.Body>
        <CloseIcon
          className="closebtn hidecls"
          onClick={() => setShowWithdraw(false)}
        />
        <div className="back" onClick={() => setShowWithdraw(false)}>
          <ExpandIcon />
        </div>
        <Container>
          <Row  className="d-lg-flex justify-content-center">
            <Col lg={5}>
              <div className="hidetoggle tgspace">
                <CurrencyTab
                  page={"currency"}
                  tableno={tableno}
                  changeTab={(e) => settableno(e)}
                />
              </div>
              <div className="hidecls">
                <CoinListLayout
                  setcoinItem={setcoinItem}
                  value={value}
                  setvalue={setvalue}
                  getplatformTokensinfo={getplatformTokensinfo}
                  getBal={getBal}
                />
              </div>
            </Col>
            <Col lg={6} className="colborder">
              <WithdrawFormWithToggle
                coinItem={coinItem}
                getAdd={getAdd}
                getBal={getBal}
                setcoinItem={setcoinItem}
                option={getplatformTokensinfo}
                tableNo={tableno}
                setTableNo={settableno}
                showToggle={showToggle}
                setHideThis={(e) => setHideThis(e)}
                setShowWithdraw={setShowWithdraw}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <div className="hidetoggle">
        <FooterCommon />
      </div>
    </Modal>
  );
};

export default React.memo(Withdrawal);
