import React, { useEffect, useState } from "react";
import CurrencyTab from "src/components/currency-tab/CurrencyTab.js";
import InputField from "@components/input-field/InputField";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/transfer-form/TransferFormStyles.scss";
import { Tab } from "react-bootstrap";
import RoundForm from "@components/round-form/RoundForm.js";
import { connect } from "react-redux";
import moment from "moment";
import { Form } from "react-bootstrap";
import Loader from "../loader/Loader";
import { ReactComponent as IdoTickmark } from "@images/idotickmark.svg";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";

function TransferForm({
  createIdoTrans,
  idorounds,
  createTransactionLoading,
  getplatformTokensinfo,
}) {
  let [tableno, settableno] = useState("first");
  let [selected, setSelectedIndex] = useState(0);

  let options = [];

  idorounds &&
    idorounds.map((item) => {
      options.push({
        tokenName: `${item.roundName}`,
        id: item.id,
        exchangerate: item.exchangeRate,
        ...item,
      });
    });
  const getLogo = (cur) => {
    let obj = getplatformTokensinfo.filter((item) => item.tokenName == cur);
    let logo = _.get(obj, "[0].nonColouredLogo", 0);
    return logo;
  };
  const getLogoback = (cur) => {
    let obj = getplatformTokensinfo.filter((item) => item.tokenName == cur);
    let logo = _.get(obj, "[0].logoBgColor", 0);
    return logo;
  };
  const getcoinId = (cur) => {
    let obj = getplatformTokensinfo.filter((item) => item.tokenName == cur);
    let logo = _.get(obj, "[0].id", 0);
    return logo;
  };

  const [address, setaddress] = useState("");
  const [amt1, setamt1] = useState("");
  const [amt2, setamt2] = useState("");

  const [bn, setbn] = useState("");

  const [round, setround] = useState("");
  const [validated, setValidated] = useState(false);
  const onCreateTransaction = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      createIdoTrans({
        amountPaid: amt1,
        tokensAllocated: amt2,
        address,
        idoRoundId: options && options[selected] && options[selected].id,
        currencyType: "Tether",
        date: moment().unix(),
        coinId: getcoinId("Tether"),
      });
      setTimeout(() => {
        setaddress("");
        setamt1("");
        setamt2("");
        setValidated(false);
      }, 1000);
    }
  };

  const { t, i18n, ready } = useTranslation();

  return (
    <div className="transferform">
      <div className="tablecontainer">
        <div className="tabholder">
          <CurrencyTab
            page={"TransferForm"}
            changeTab={(e) => {
              if (e == "second") {
                settableno(e);
              }

              if (e == "first") {
                settableno(e);
              }
            }}
            tableno={tableno}
          />
        </div>

        {tableno === "first" && (
          <Tab.Pane eventKey="first" className="transferpane">
            <div className="fieldssection">
              <div className="labeloffield">
                <span className="label"> {t("SelectRound")}</span>
              </div>
              <div className="dropdondholder">
                <DropdownSearch
                  setSelectedIndex={setSelectedIndex}
                  options={options}
                  Selected={selected}
                />
                <div className="separator" />
              </div>
            </div>
            <Form
              noValidate
              validated={validated}
              onSubmit={onCreateTransaction}
            >
              <div className="fieldssection fieldssectionmargin">
                <div className="labeloffield">
                  <span className="label"> {t("Enterrecipientaddress")}</span>
                </div>
                <div className="formfield">
                  <div className="inputfieldholder">
                    <InputField
                      type="text"
                      classname={"withoutleftborder"}
                      onChange={(e) => setaddress(e.target.value)}
                      value={address}
                      required
                    />
                  </div>
                  <div className="logosection">
                    <div
                      className={`logoholder address  ${
                        address.length != 0 && "valid"
                      }`}
                    >
                      <IdoTickmark className="tickmark" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldssection fieldssectionmargin">
                <div className="labeloffield">
                  <span className="label"> {t("Enteramounttotransfer")}</span>
                </div>
                <div className="formfield">
                  <div className="inputfieldholder">
                    <InputField
                      type="number"
                      classname={"withoutleftborder"}
                      onChange={(e) => {
                        setamt1(e.target.value);
                        let amt =
                          Number(
                            options &&
                              options[selected] &&
                              options[selected].exchangerate
                          ) * Number(e.target.value);
                        let bonus = BigNumber(
                          Number(
                            options &&
                              options[selected] &&
                              options[selected].bonus
                          ) *
                            Number(e.target.value) *
                            Number(
                              options &&
                                options[selected] &&
                                options[selected].exchangerate
                            )
                        )
                          .dividedBy(100)
                          .toFixed(0);
                        setamt2(amt);
                        setbn(`${amt} + ${bonus} (${t("bonus")})`);
                      }}
                      value={amt1}
                    />
                  </div>
                  <div className="logosection">
                    <div
                      className="logoholder"
                      style={{ backgroundColor: getLogoback("Tether") }}
                    >
                      <img
                        src={`${process.env.REACT_APP_IMGLINK}${getLogo(
                          "Tether"
                        )}`}
                        className="coinimg"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldssection fieldssectionmargin">
                <div className="labeloffield">
                  <span className="label">
                    {" "}
                    {t("Amounteqivalenttokentotransfer")}
                  </span>
                </div>
                <div className="formfield">
                  <div className="inputfieldholder">
                    <InputField
                      disabled={true}
                      classname={"withoutleftborder"}
                      onChange={(e) => setamt2(e.target.value)}
                      value={bn}
                    />
                  </div>
                  <div className="logosection">
                    <div
                      className="logoholder"
                      style={{ backgroundColor: getLogoback("CyborCoin") }}
                    >
                      <img
                        src={`${process.env.REACT_APP_IMGLINK}${getLogo(
                          "CyborCoin"
                        )}`}
                        className="coinimg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="defaultext">
                <span className="text">
                  1USDT ={" "}
                  {Number(
                    options &&
                      options[selected] &&
                      options[selected].exchangerate
                  )}{" "}
                  CYBOR
                </span>
                <br/>
                <span className="text ml-5">
                  {"    "}
                  Bonus{"  -  "}
                  {Number(
                    options &&
                      options[selected] &&
                      options[selected].bonus
                  )}{"%"}
                 </span>
              </div>
              {createTransactionLoading && <Loader />}
              <div className="outerconforbtn">
                <CustomButton
                  type="submit"
                  name={t("Transfer")}
                  filled={true}
                  primary={true}
                />
              </div>
            </Form>
          </Tab.Pane>
        )}
        {tableno === "second" && (
          <Tab.Pane eventKey="second" className="roundpane">
            <RoundForm />
          </Tab.Pane>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ ido, wallet }) => {
  const idorounds = _.get(ido, "idorounds", []);
  const createTransactionLoading = _.get(
    ido,
    "createTransactionLoading",
    false
  );
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  return { idorounds, createTransactionLoading, getplatformTokensinfo };
};

const mapDispatchToProps = (dispatch) => ({
  createIdoTrans: (data) =>
    dispatch({ type: "createIdoTranscalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferForm);
