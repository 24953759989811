import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Form } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import Branchsuccessful from "@components/branch-successful/Branchsuccessful";
import InputField from "@components/input-field/InputField";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import pprofile from "@images/pprofile.svg";
import { connect } from "react-redux";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import "@features/create-branch/CreateBranch.scss";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import BranchCreatedSuccessfulPopup from "@components/branch-created-successful-popup/BranchCreatedSuccessfulPopup"

function CreateBranch({
  userdata,
  uploadProfileImage,
  updateProfileLoading,
  userAccessToken,
  userProfileImageFailed,
  createBranchCall,
  createBranchLoading,
  setbusinessModals,
  createBranchError,
  businessModal,
  clearErrorMessage,
  createbranchsuccesspopup,
}) {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [accountno, setaccountno] = useState("");
  const [accountName, setAccountName] = useState("");
  const [branch, setbranch] = useState(" ");
  const [code, setcode] = useState("91");
  const [address, setaddress] = useState("");
  const [validated, setValidated] = useState(false);
  const [showeye, setshoweye] = useState(true);
  const [showeye2, setshoweye2] = useState(true);
  const [image, setImage] = useState("");
  const [imageurl, setImageurl] = useState("");
  const [readerImage, setReaderImage] = useState(false);
  const [error2, seterror2] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [fileEmptyError, setFileEmptyError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [currentLang, setCurrentLang] = useState('en')
  let [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  
  useEffect(() => {
    setCurrentLang(localStorage.lang)
  },[localStorage.lang])

  useEffect(() => {
    setErrorMessage(createBranchError);
  }, [createBranchError]);


  useEffect(() => {
    clearErrorMessage()
  },[])

  // useEffect(() => {
  //   if (file !== undefined) {
  //     setFileEmptyError('')
  //   }
  //   else {
  //     setFileEmptyError('ImageRequired')
  //   }

  // }, [file])

  // useEffect(() => {
  //   if (code.length === 12) {
  //     setPhoneNoError('')
  //   }
  //   else {
  //     setPhoneNoError('Please enter a valid phone no')
  //   }

  // }, [code])

  const cancelCreation = () => {
    setaccountno("");
    setbranch("");
    setaddress("");
    setEmail("");
    setFile("");
    setPassword("");
    setPassword2("");
    setImageurl("");
    setcode("91");
    setAccountName("");
    clearErrorMessage();
  };

  const createBranch = (form) => {
    if (form.checkValidity() === true) {
      if (file === undefined) {
        setFileEmptyError("ImageRequired");
      }
      if (file !== undefined) {
        setFileEmptyError("");
      }
      if (code.length < 3) {
        setPhoneNoError("PhoneEmpty");
      }
      if (code.length < 12) {
        setPhoneNoError("Please enter a valid phone no");
      } else {
        setFileEmptyError("");
        setPhoneNoError("");

        const data = {
          image: file,
          branchName: branch,
          address: address,
          phone: code,
          status: "Active",
          id: userdata.id,
          accountName: accountName,
          email: email,
          exchangeId: parseInt(userdata.exchangeId),
          password: password,
          lang: currentLang
        };
        createBranchCall(data);
        
      }
    } else {
    }
  };

  const FormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      if (file === undefined) {
        setFileEmptyError("ImageRequired");
      }
      if (file !== undefined) {
        setFileEmptyError("");
      }
      if (code.length < 3) {
        setPhoneNoError("PhoneEmpty");
      } else {
        if (code.length < 12) {
          setPhoneNoError("Please enter a valid phone no");
        } else {
          setPhoneNoError("");
        }
      }
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      clearErrorMessage();
    } else {
      event.preventDefault();
      event.stopPropagation();
       createBranch(form);
      
    }
  };

   // const onSendmsg = (event) => {
  //   event.preventDefault();
  //   if (Image !== null && image !== '') {
  //     uploadImage();
  //   }
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   setValidated(true);
  // };

  const uploadImage = () => {
    const data = new FormData();
    data.append("image", image);
    uploadProfileImage({ data });
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    reader.onloadend = () => {
      setImage(file);
      setImageurl(reader.result);
      setReaderImage(true);
    };
    reader && reader.readAsDataURL(file);
  };

  var re = /[^a-zA-Z]+/;
  let history = useHistory()
  const { t } = useTranslation();
  return (
    <ScreenLayout className="hidden">
      <div className="modalPrimaryHeader"></div>
      <div id="CreateBranch">
        {/* <BranchCreatedSuccessfulPopup showModal={createbranchsuccesspopup}  /> */}
        <Container className="containerallignm">
          {(updateProfileLoading || createBranchLoading) && <Loader />}
          <Row>
            <Col lg={12}>
              
              <Form noValidate onSubmit={FormSubmit} validated={validated}>
                <div className="outercontainer">
                  <div className="hideformobile toptitle">
                    <span>{t("CreateBranch")}</span>
                  </div>
                  <div className="hidefordesktop">
                  <div className="d-flex align-items-center mb-5" onClick={()=>history.goBack()} >
                    <Arrow className="arrowicon" />
                    <p className="addNewText">{t("CreateBranch")}</p>
                  </div>
                  </div>
                  <div className="titles">
                    <span>{t("Logo")}</span>
                  </div>

                  <div className="outercls">
                    <div className="display imgouter">
                      {(imageurl !== null &&
                        imageurl !== "" &&
                        ((readerImage == true && (
                          <img
                            src={imageurl}
                            className="imgsize integratedimage"
                          />
                        )) || (
                          <img
                            src={`${process.env.REACT_APP_IMGLINK}${imageurl}`}
                            className="imgsize integratedimage"
                          />
                        ))) || <img src={pprofile} className="imgsize" />}
                    </div>

                    <div className="display">
                      <div className="marginlft">
                        <div className="spanwidth">
                          <span className="spanmsg">
                            {t("Thesizeofimage")} 98 x 98 {t("pixels")} (
                            {t("sizetothefile")} -{t("upto")} 4 MB){" "}
                            {t("inPNGorJPEGformat")}
                          </span>
                        </div>
                        <div className="outerbtn">
                          <div className="bottombuttons btntop">
                            <label
                              htmlFor="inputfile"
                              className="inputbuttonmain"
                            >
                              {t("Change")}
                            </label>
                            <input
                              onChange={(e) => handleImageChange(e)}
                              type="file"
                              id="inputfile"
                              className="inputfilebtn"
                              required
                            />
                          </div>
                          <div className="bottombuttons btntop">
                            <CustomButton
                              name={t("Exclude")}
                              filled={true}
                              primary={true}
                              onClick={() => {
                                setFile("");
                                setImageurl("");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <p className="fileErrorCustom">{t(`${fileEmptyError}`)}</p>
                  </div>
                  {/* 
                <div className='titles'>
                  <span>{t('ID')}</span>
                  <div className='inputbox'>
                    <InputField
                      type='number'
                      required
                      onChange={(e) => setaccountno(e.target.value)}
                      value={accountno}
                    />
                  </div>
                </div> */}
                  <div className="titles">
                    <span>{t("AccountName")}</span>
                    <div className="inputbox">
                      <InputField
                        type="text"
                        onChange={(e) => setAccountName(e.target.value)}
                        value={accountName}
                      />
                    </div>
                  </div>
                  <div className="titles">
                    <span>{t("Branch")}</span>
                    <div className="inputbox">
                      <InputField
                        type="text"
                        onChange={(e) => setbranch(e.target.value)}
                        value={branch}
                        isInvalid={validated === true}
                        required
                        error={
                          branch.length === 0
                            ? "Please enter a branch name"
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="titles">
                    <span>{t("Phone")}</span>
                    <div className="inputphone">
                      <InputField
                        phone
                        type="Phone"
                        onChange={(e) => setcode(e)}
                        value={code}
                        required
                        isInvalid={validated === true}
                        error={
                          code.length === 0
                            ? "Phone no cannot be empty"
                            : code.length < 12
                            ? "Please enter a valid phone no"
                            : ""
                        }
                      />
                    </div>
                    <p className="fileErrorCustom">{t(`${phoneNoError}`)}</p>
                  </div>

                  <div className="titles">
                    <span>{t("EmailID")}</span>
                    <div className="inputbox">
                      <InputField
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                        isInvalid={validated === true}
                        error={
                          email.length === 0
                            ? "Email_empty"
                            : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                email
                              ) === false
                            ? "Please enter a valid email address"
                            : ""
                        }
                      />
                    </div>
                  </div>

                  <div className="titlepass">
                    <span>{t("Address")}</span>
                  </div>
                  <div className="outerpass">
                    <div className="inputboxpass">
                      <InputField
                        type="text"
                        onChange={(e) => setaddress(e.target.value)}
                        value={address}
                      />
                    </div>
                  </div>

                  <div className="titles">
                    <span>{t("Password")}</span>
                    <div className="inputbox">
                      <div className="inputboxpass">
                        <InputField
                          type={showeye ? "password" : "text"}
                          placeholder={t("EnterPassword")}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                          isInvalid={
                            validated === true && password !== password2
                          }
                          error={
                            password !== password2
                              ? "Password and confirm password should match"
                              : password.length < 8
                              ? "Passwordempty"
                              : ""
                          }
                          eye
                          setshow={() => setshoweye(!showeye)}
                          showeye={showeye}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="titles">
                    <span>
                      {t("Confirm")} {t("Password")}
                    </span>
                    <div className="inputbox">
                      <div className="inputboxpass">
                        <InputField
                          placeholder={t("ReEnterPassword")}
                          type={showeye2 ? "password" : "text"}
                          onChange={(e) => setPassword2(e.target.value)}
                          value={password2}
                          required
                          eye
                          setshow={() => setshoweye2(!showeye2)}
                          showeye={showeye2}
                          isInvalid={
                            validated === true && password !== password2
                          }
                          error={
                            password !== password2
                              ? "Password and confirm password should match"
                              : password2.length < 8
                              ? "Passwordempty"
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="btmbtnoutercontainer">
                    <div className="topbuttons">
                      <CustomButton
                        type="submit"
                        name={t("Create")}
                        filled={true}
                        primary={true}
                        //onClick={createBranch}
                      />
                    </div>
                    <div className="bottombuttons">
                      <CustomButton
                        type="submit"
                        name={t("Cancel")}
                        filled={true}
                        primary={true}
                        onClick={cancelCreation}
                      />
                    </div>
                  </div>
                  <p className="error-msg">{t(`${errorMessage}`)}</p>
                </div>
              </Form>
            </Col>
          </Row>
          <Branchsuccessful
            showDeleteConfirmation={businessModal}
            setShowDeleteConfirmation={(e)=>{
              setbusinessModals(false)
              history.push('/branchmanagement')
            }}
          />
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}

const mapStateToProps = ({ user = {}, branchmanagementredux = {},app={} }) => {
  const userdata = _.get(user, "MyProfileInfo", false);
  const createBranchLoading = _.get(
    branchmanagementredux,
    "createBranchLoading",
    false
  );
  const createBranchError = _.get(
    branchmanagementredux,
    "createBranchError",
    ""
  );
  
  const businessModal = _.get(app, "businessModal", false);
  const createbranchsuccesspopup = (app , "createbranchsuccesspopup" , false);
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const updateProfileLoading = _.get(user, "updateProfileLoading", false);
  const userProfileImageFailed = _.get(user, "userProfileImageFailed", false);
 
  return {
    userdata,
    updateProfileLoading,
    userAccessToken,
    createBranchLoading,
    createBranchError,
    businessModal,
    createbranchsuccesspopup,
  };
};
const mapDispatchToProps = (dispatch) => ({
  uploadProfileImage: (data) =>
    dispatch({ type: "updateProfileImage", payload: data }),
  createBranchCall: (data) =>{
     dispatch({ type: "createBranchStart", payload: data })},
    setbusinessModals: (data) =>
    dispatch({ type: "businessModalCalled", payload: data }),
    clearErrorMessage:(data)=> dispatch({type:'clearBrachError' , payload: data})
    
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBranch);
