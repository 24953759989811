export default {
  trademark: "© 2023 Торговая марка PayCrypt",
  CyborexPlatform: "Платформа Cyborex",
  CyborPayPaymentSystem: "Платежная система PayCrypt",
  CyborcoinCryptocurrency: "Криптовалюта PayCrypt Coin",
  OwnedBy: "принадлежит PayCrypt Pİ BİLİŞİM TEKNOLOJİLERİ VE TİCARET ANONİM ŞİRKETİ",
  FooterPara1:
    " / Все права защищены PayCrypt является продуктом компании PayCrypt Инвестиционная деятельность в области криптовалюты, включая покупку PayCrypt Coin (CYBOR), подвержена рыночному риску. PayCrypt предлагает вам простые",
  FooterPara2:
    "и удобные способы моментальной покупки PayCrypt Coin (CYBOR). Мы прилагаем все усилия, чтобы всесторонне информировать пользователей о криптовалютах, предлагаемых на бирже, но мы не несем ответственности за результаты, которые могут возникнуть в результате",
  ResultPurchase: " покупки.",
  Cyborcoin: "PayCrypt Coin",
  cryptocurrency: "криптовалюта",
  RightReserved: " / Все права защищены",
  FAQs: "Часто задаваемые вопросы",
  CommissionsFees: "Комиссии и сборы",
  TermsCondition: "Условия и положения",
  PrivacyPolicy: "Конфиденциальность и политика",
};
