import * as _ from "lodash";
import React from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/log-out/LogoutStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { connect } from "react-redux";
import "@components/forgot-password-email-success/Forgotpasswordemailsuccess.scss";
import { useTranslation } from "react-i18next";
import { closecreateBranchSuccessPoup } from "@store/appredux";
import {useHistory} from 'react-router-dom';
const  AdminLoanSuccesfulPopup = ({
  showModal,
  setShowModal,
  onCancel,
  message,
  closePopup,
  scope,
}) => {
  const { t, i18n, ready } = useTranslation();
  const history = useHistory();
  return (
    <Modal
      className="forgotpasswordemailsuccess"
      show={showModal}
      // onHide={setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon className="closebtn" onClick={()=>{
           if(scope !== undefined && scope==="loanRequest"){
            history.push('/personalLoanRequest')
          }else{
            history.push('/adminLoanManagement')
          }
                    setShowModal(false)
                  }} />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext passwordtitle">
                 Success
              </div>
              <hr />
            </Col>
            <Col lg={12}>
              <div className="success-content passcontent">
                <span className="titleText">
                {message}
                </span>{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="ok-btn">
                <CustomButton
                  name={t("OK")}
                  className="close-btn"
                  filled={true}
                  primary={true}
                  onClick={()=>{
                    if(scope !== undefined && scope==="loanRequestPersonal"){
                   
                    history.push('/personalLoanRequest')
                  }else if(scope !== undefined && scope === "loanRequest"){
                    history.push('/loanRequest')
                  }else{
                    history.push('/adminLoanManagement')
                  }
                    setShowModal(false)

                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closecreateBranchSuccessPoup()),
});

export default connect(
  null,
  mapDispatchToProps
)(React.memo(AdminLoanSuccesfulPopup));
