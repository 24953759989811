import * as _ from "lodash";
import React, { useEffect } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/payment-cancel-confirmation/PaymentCancelConfirmation.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Loader from "@components/loader/Loader";

const PaymentCancelPopup = ({
  show1,
  setShowModal,
  selectedPayment,
  onPress,
  address,
  amount,
  onClick,
  setHideThis,
  logouts,
  cancelPaymentLoading,
  cancelPaymentCall,
  data,
}) => {
  const { t, i18n, ready } = useTranslation();
  const closeModal = () => setShowModal(false);
  useEffect(() => {
    if (cancelPaymentLoading === false) {
      closeModal();
    }
  }, [cancelPaymentLoading]);
  return (
    <>
      {cancelPaymentLoading && <Loader />}
      <Modal
        className="cancelpay"
        show={show1}
        onHide={() => {
          setShowModal(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <CloseIcon
            className="closebtn"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Container>
            <Row>
              <Col lg={12}>
                <div className="exchangetext">{t("WantToCancel")}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={6} xs={6}>
                <div className="confirm">
                  <CustomButton
                    type="submit"
                    name={t("Yes")}
                    filled={true}
                    primary={true}
                    onClick={() =>
                      cancelPaymentCall({
                        paymentID: parseInt(selectedPayment),
                        data: data && data.type ? data.type : '',
                        status: "Cancelled",
                      })
                    }
                  />
                </div>
              </Col>
              <Col lg={6} sm={6} xs={6}>
                <div className="cancel">
                  <CustomButton
                    type="submit"
                    name={t("No")}
                    filled={true}
                    primary={true}
                    onClick={() => {
                      setShowModal(false);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ paymentlink = {} }) => {
  const cancelPaymentLoading = _.get(
    paymentlink,
    "cancelPaymentLoading",
    undefined
  );
  return {
    cancelPaymentLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancelPaymentCall: (data) =>
    dispatch({ type: "cancelPaymentStart", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PaymentCancelPopup));
