import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import ScreenLayout from "src/components/layout/ScreenLayout";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";
import { Row, Col, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import CustomButton from "@components/custom-button/CustomButton";
import BusinessRequestPopup from "@components/business-request-popup/BusinessRequestPopup.js";
import { ToastContainer, toast } from "react-toastify";
import Filter from "@components/filter/Filter.js";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";
import { useTranslation } from "react-i18next";
import PaymentCancelConfirmation from "@components/payment-cancel-confirmation/PaymentCancelConfirmation";
import ApprovalPopup from "src/components/approval-popup/ApprovalPopup";
import RejectionPopup from "src/components/rejection-popup/RejectionPopup";
import "@features/admin-business-request/AdminBusinessRequestStyle.scss";

function AdminBusinessRequest(props) {
  const {
    exchangeRates,
    createpaymentLink,
    paymenthistory,
    getpaymenthistoryCount,
    myusertransactionloading,
    getplatformTokensinfo,
    getExchangerate,
    getPaymentHistory,
    myaccountandbalance,
    getPaymentHistoryyCount,
    setPaymentlink,
    paymentlinkmodal,
    paymentlinksuccmodal,
    setPaymentlinkSucc,
    paymentToken,
    createloaders,
    paymentbytoken,
    settings,
    thememode,
    getcomissionper,
    commissionPercentage,
    createPaymentFail,
    getAllBusinessRequests,
    allBusinessRequest,
    allBusinessAccountLoading,
    callBusinessApproval,
    getBusinessRequestCount,
    businessAccountCount,
    hideLoaderOnRefresh,
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("third");
  let [show, setshow] = useState(false);
  let [Idata, setIdata] = useState({});
  const [isFilterSet, setIsFilterSet] = useState(false);
  let [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  let [selectedPaymentID, setSelectedPaymentID] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [show1, setShowApproval] = useState(false);
  const [show2, setShowRejection] = useState(false);
  const [allAccountRequest, setAllAccountRequests] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedAccountID, setSelectedAccountID] = useState();
  const [apReq, setApReq] = useState("");
  const [documentLink, setDocumentLink] = useState([]);

  useEffect(() => {
    getAllBusinessRequests({ skip: pages, limit, approvalRequest: apReq });
    getBusinessRequestCount();
  }, []);

  const { t } = useTranslation();

  return (
    <div id="bankreq">
      {/* <PaymentCancelConfirmation
        show1={showCancelConfirmation}
        selectedPayment={selectedPaymentID}
        setShowModal={(status) => setShowCancelConfirmation(status)}
      /> */}

      <BusinessRequestPopup
        item={selectedAccount}
        show1={show}
        setShowModal={setshow}
      />

      {/* <NavBar location={useLocation()} /> */}
      <ScreenLayout
        hideLoaderOnStart={true}
        loaderOnRefresh={hideLoaderOnRefresh}
      >
        <Container className="containertp">
          <Row>
            {/* <Col lg={3}>
            <LeftSidebar />
          </Col> */}
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("BusinessRequest")} </span>
                  <div className="filtersection">
                    <Filter
                      onApplyDate={(picker) => {
                        setIsFilterSet(true);
                        getAllBusinessRequests({
                          skip: pages,
                          limit,
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          approvalRequest: apReq,
                        });
                        getBusinessRequestCount({
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          approvalRequest: apReq,
                        });
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                      oncancel={() => {
                        setIsFilterSet(false);
                        setStartDate();
                        setEndDate();
                        getAllBusinessRequests({
                          skip: pages,
                          limit,
                          approvalRequest: apReq,
                        });
                        getBusinessRequestCount({ approvalRequest: apReq });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="btmmargin">
                  <CurrencyTab
                    page={"businessrequest"}
                    changeTab={(e) => {
                      if (e === "second") {
                        setIsFilterSet(false);
                        setStartDate();
                        setEndDate();
                        setApReq("Rejected");
                        getAllBusinessRequests({
                          approvalRequest: "Rejected",
                          skip: pages,
                          limit,
                        });
                        getBusinessRequestCount({
                          approvalRequest: "Rejected",
                        });

                        setpages(0);
                        settableno(e);
                      }
                      if (e === "first") {
                        setIsFilterSet(false);
                        setStartDate();
                        setEndDate();
                        setApReq("Approved");
                        getAllBusinessRequests({
                          approvalRequest: "Approved",
                          skip: pages,
                          limit,
                        });
                        getBusinessRequestCount({
                          approvalRequest: "Approved",
                        });

                        setpages(0);
                        settableno(e);
                      }
                      if (e === "third") {
                        setIsFilterSet(false);
                        setStartDate();
                        setEndDate();
                        setApReq("");
                        getAllBusinessRequests({ skip: pages, limit });
                        getBusinessRequestCount();

                        setpages(0);
                        settableno(e);
                      }
                    }}
                    tableno={tableno}
                  />
                </div>
              </div>

              <Table
                responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless
              >
                {allBusinessAccountLoading && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading width-start">
                      {t("BusinessID")}
                    </th>
                    <th className="trtbleheading">{t("Date")}</th>
                    <th className="trtbleheading width-start">
                      {t("CompanyName")}
                    </th>
                    <th className="trtbleheading adwidth">
                      {t("CompanyAddress")}
                    </th>
                    <th className="trtbleheading">{t("Region")}</th>
                    <th className="trtbleheading width">{t("Action")}</th>
                    <th className="trtbleheading"></th>
                  </tr>
                </thead>

                <tbody>
                  {allBusinessRequest &&
                    allBusinessRequest.map((item, index) => (
                      <tr
                        key={`trans${index}`}
                        onClick={() => {
                          setIdata(item);
                          // setshow(true);
                        }}
                      >
                        <td className="amt">
                          <div className="innerTd idtr ">
                            <span className="id idtxt">{item.id}</span>
                            <p className="hidden-for-desktop date margintop">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </p>
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd  ">
                            {moment(item.createdOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd  ">
                            <span className="id idtxt">{item.companyName}</span>
                            <span className="id idtxt hidden-for-desktop">
                              {item.companyAddress}
                            </span>
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd  hidden-for-mobile">
                            {item.companyAddress}
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd  hidden-for-mobile">
                            {item.region}
                          </div>
                        </td>

                        <td className="claimbtnouter hidden-for-mobile">
                          <div className="innerTd  ">
                            <div className="holderforbutton">
                              {item.businessApproval &&
                              item.businessApproval === "Approved" ? (
                                // <div className="approve">
                                //   <CustomButton
                                //     disable={item?.businessApproval === "Approved" ? true : false}
                                //     name={t("Approve")}
                                //     filled={true}
                                //     primary={true}
                                //     onClick={() => {
                                //       setShowApproval(true);
                                //       setSelectedAccountID(item.id)
                                //     }}
                                //     classname={item?.businessApproval === "Approved" ? 'disabledBtn' : null}
                                //   />
                                // </div>
                                <div className="statustext">
                                  <span>{t("Approved")}</span>
                                </div>
                              ) : item.businessApproval &&
                                item.businessApproval === "Rejected" ? (
                                // <div className="reject">
                                //   <CustomButton
                                //     disable={item?.businessApproval === "Rejected" ? true : false}
                                //     name={t("Reject")}
                                //     filled={true}
                                //     primary={true}
                                //     onClick={() => {
                                //       setShowRejection(true);
                                //       setSelectedAccountID(item.id)
                                //     }}
                                //     classname={item?.businessApproval === "Rejected" ? 'disabledBtn' : null}
                                //   />
                                // </div>
                                <div className=" statustext">
                                  <span>{t("Rejected")}</span>
                                </div>
                              ) : (
                                <>
                                  <div className="approve">
                                    <CustomButton
                                      disable={
                                        item?.businessApproval === "Approved"
                                          ? true
                                          : false
                                      }
                                      name={t("Approve")}
                                      filled={true}
                                      primary={true}
                                      onClick={() => {
                                        setShowApproval(true);
                                        setSelectedAccountID(item.id);
                                      }}
                                      classname={
                                        item?.businessApproval === "Approved"
                                          ? "disabledBtn"
                                          : null
                                      }
                                    />
                                  </div>
                                  <div className="reject">
                                    <CustomButton
                                      disable={
                                        item?.businessApproval === "Rejected"
                                          ? true
                                          : false
                                      }
                                      name={t("Reject")}
                                      filled={true}
                                      primary={true}
                                      onClick={() => {
                                        setShowRejection(true);
                                        setSelectedAccountID(item.id);
                                      }}
                                      classname={
                                        item?.businessApproval === "Rejected"
                                          ? "disabledBtn"
                                          : null
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className="completed">
                          <div className="innerTd  ">
                            <div className="vertical">
                              <Dropdown className="hidden-for-mobile">
                                <Dropdown.Toggle
                                  className="threedot"
                                  id="dropdown-basic"
                                >
                                  <VerticleIcon className="arrowiconclass" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      //  setcoinItem(items);
                                      setSelectedAccount(item);
                                      setshow(true);
                                    }}
                                  >
                                    {t("Details")}
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    // onClick={() => {
                                    //   openModal(), setname("Delete");
                                    // }}
                                    >
                                    Cancel
                                    </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                              <Replacementof3dots
                                className=" arrow hidden-for-desktop"
                                onClick={() => {
                                  setSelectedAccount(item);
                                  setshow(true);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {businessAccountCount > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(businessAccountCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={businessAccountCount / limit}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        if (isFilterSet === true) {
                          getAllBusinessRequests({
                            approvalRequest: apReq,
                            skip: e.selected * limit,
                            limit,
                            startDate: startDate,
                            endDate: endDate,
                          });
                        } else {
                          getAllBusinessRequests({
                            approvalRequest: apReq,
                            skip: e.selected * limit,
                            limit,
                            startDate: startDate,
                            endDate: endDate,
                          });
                        }
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
              <ApprovalPopup
                onClick={() => {
                  callBusinessApproval({
                    id:
                      selectedAccountID !== undefined ? selectedAccountID : "",
                    status: "Approved",
                  });
                  setShowApproval(false);
                }}
                show1={show1}
                setShowModal={(e) => setShowApproval(e)}
              />
              <RejectionPopup
                onClick={() => {
                  callBusinessApproval({
                    id:
                      selectedAccountID !== undefined ? selectedAccountID : "",
                    status: "Rejected",
                  });
                  setShowRejection(false);
                }}
                show1={show2}
                setShowModal={(e) => setShowRejection(e)}
              />
            </Col>
          </Row>
        </Container>
      </ScreenLayout>
    </div>
  );
}
const mapStateToProps = ({
  app = {},
  wallet = {},
  paymentlink = {},
  translationLanguage = {},
  admin = {},
}) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const paymenthistory = _.get(paymentlink, "paymenthistory", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const myusertransactionloading = _.get(
    paymentlink,
    "paymenthistoryLoading",
    false
  );
  const getpaymenthistoryCount = _.get(
    wallet,
    "getpaymenthistoryCount.count",
    10
  );
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const paymentlinkmodal = _.get(app, "paymentlinkmodal", false);
  const paymentlinksuccmodal = _.get(app, "paymentlinksuccmodal", false);
  const paymentToken = _.get(paymentlink, "paymentToken", false);
  const createPaymentFail = _.get(paymentlink, "createPaymentFail", "");
  const createloaders = _.get(paymentlink, "createPaymentLoading", false);

  const paymentbytoken = _.get(paymentlink, "paymentbytoken", false);
  const commissionPercentage = _.get(
    paymentlink,
    "commissionPercentage",
    false
  );
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const currentLang = _.get(translationLanguage, "language", false);
  const allBusinessRequest = _.get(admin, "businessRequest", []);
  const allBusinessAccountLoading = _.get(
    admin,
    "businessRequestLoading",
    false
  );
  const businessAccountCount = _.get(admin, "businessAccountCount", false);
  return {
    thememode,
    settings,
    mywalletsinfo,
    paymenthistory,
    myaccountandbalance,
    getpaymenthistoryCount,
    myusertransactionloading,
    getplatformTokensinfo,
    exchangeRates,
    paymentlinkmodal,
    paymentlinksuccmodal,
    paymentToken,
    createloaders,
    paymentbytoken,
    commissionPercentage,
    currentLang,
    createPaymentFail,
    allBusinessRequest,
    allBusinessAccountLoading,
    businessAccountCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),
  getPaymentHistoryyCount: (data) =>
    dispatch({ type: "getpaymetHistoryCountCalled", payload: data }),
  getPaymentHistory: (data) =>
    dispatch({ type: "getPaymentHistoryCalled", payload: data }),
  createpaymentLink: (data) =>
    dispatch({ type: "createpaymentLinkCalled", payload: data }),
  setPaymentlink: (data) =>
    dispatch({ type: "setPaymentlinkCalled", payload: data }),
  setPaymentlinkSucc: (data) =>
    dispatch({ type: "setPaymentlinkSuccCalled", payload: data }),
  getcomissionper: (data) =>
    dispatch({ type: "getcomissionperCalled", payload: data }),
  getAllBusinessRequests: (data) =>
    dispatch({ type: "businessRequest", payload: data }),
  callBusinessApproval: (data) =>
    dispatch({ type: "ApproveBusinessRequest", payload: data }),
  getBusinessRequestCount: (data) =>
    dispatch({ type: "businessRequestCount", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminBusinessRequest);
