export const char1data2 = [
    {
      id: 1,
      label: "Users",
      value: 0.8,
      color: "#2969BA",
    },
    {
      id: 2,
      label: "Marketing",
      value: 0.1,
      color: "#59C39B",
    },
    {
      id: 3,
      label: "Partners",
      value: 0.1,
      color: "#6ADC7F",
    }
  ]

  export const char1data = [
    {
      id: 1,
      label: "Investors closed round #1",
      value: 0.05,
      color: "#09154E",
    },
    {
      id: 2,
      label: "Investors closed round #2",
      value: 0.10,
      color: "#2969BA",
    },
    {
      id: 3,
      label: "Investors closed round #3",
      value: 0.20,
      color: "#59C39B",
    },
    {
      id: 4,
      label: "Market Placement",
      value: 0.25,
      color: "#6ADC7F",
    },
    {
      id: 5,
      label: "Mining pool	",
      value: 0.15,
      color: "#6ADC7F",
    },
    {
      id: 6,
      label: "Developers",
      value: 0.125,
      color: "#3A89C0",
    },
    {
      id: 7,
      label: "Marketing",
      value: 0.05,
      color: "#0D1F73",
    },
    {
      id: 8,
      label: "Partners",
      value: 0.05,
      color: "#17469F",
    },
  ]
  const graphcolors = {
    "seedroundColor": "#060E33",
    "privatesale1Color": "#0D1F73",
    "privatesale2Color": "#17469F",
    "publicsaleColor": "#2969BA",
    "liquidityColor": "#3A89C0",
    "teamColor": "#4AA7B3",
    "marketingColor": "#59C39B",
    "partnersColor": "#6ADC7F"
  }

  export const chart3singledata = [
    {
      name: "Seed Round",
      color: "#060E33"
    },
    {
      name: "Private sale 1",
      color: "#0D1F73"
    },
    {
      name: "Private sale 2",
      color: "#17469F"
    },
    {
      name: "Public sale",
      color: "#2969BA"
    },
    {
      name: "Liquidity",
      color: "#3A89C0"
    },
    {
      name: "Team",
      color: "#4AA7B3"
    },
    {
      name: "Marketing",
      color: "#59C39B"
    },
    {
      name: "Partners and Advisors",
      color: "#6ADC7F"
    },
  ]

  let country = -2;
  export const chartdata3 = [
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 20,
      "privatesale1": 50,
      "privatesale2": 0,
      "publicsale": 0,
      "liquidity": 0,
      "team": 0,
      "marketing": 0,
      "partners": 0,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 30,
      "privatesale1": 60,
      "privatesale2": 70,
      "publicsale": 0,
      "liquidity": 0,
      "team": 0,
      "marketing": 0,
      "partners": 0,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 70,
      "publicsale": 20,
      "liquidity": 10,
      "team": 10,
      "marketing": 5,
      "partners": 3,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 30,
      "liquidity": 10,
      "team": 10,
      "marketing": 5,
      "partners": 3,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 40,
      "liquidity": 10,
      "team": 10,
      "marketing": 5,
      "partners": 3,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 60,
      "liquidity": 10,
      "team": 10,
      "marketing": 5,
      "partners": 3,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 80,
      "liquidity": 20,
      "team": 20,
      "marketing": 25,
      "partners": 10,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 100,
      "liquidity": 30,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 120,
      "liquidity": 35,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 130,
      "liquidity": 40,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },

    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 160,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 180,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 190,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 200,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 220,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 230,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 250,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 270,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 280,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 300,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },

    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 310,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 320,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 330,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 340,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 350,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 360,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 370,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 380,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 390,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 400,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 410,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 420,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 430,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 440,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 450,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 460,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    {
      "country": `${country < 8 && '0' || ''}${country+=2}`,
      "seedround": 40,
      "privatesale1": 65,
      "privatesale2": 80,
      "publicsale": 470,
      "liquidity": 45,
      "team": 40,
      "marketing": 35,
      "partners": 15,
      ...graphcolors
    },
    
  ]

  export const listdata = [
    { line1: "Potocol", line2: "BEP-20 (Binance Smart Chain)" },
    { line1: "Name Token", line2: "CYBOR COIN" },
    { line1: "Tracker", line2: "CYBOR" },
    { line1: "Total Amount", line2: "1 000 000 000 (1 bilion)" },
    { line1: "Types of emision", line2: "limied controlled" },
  ];

  export const distributiondata = [
    {
      investors: "Investors closed round #1",
      percent: "5 %",
      tokens: "20 000 000",
      onetoken: "0.15 $",
      costtokens: "3 000 000 $",
      blocking: "6 months (180 days)",
    },
    {
      investors: "Investors closed round #2",
      percent: "10 %",
      tokens: "40 000 000",
      onetoken: "0.45 $",
      costtokens: "18 000 000 $",
      blocking: "6 months (180 days)",
    },
    {
      investors: "Investors closed round #3",
      percent: "20 %",
      tokens: "80 000 000",
      onetoken: "0.60 $",
      costtokens: "48 000 000 $",
      blocking: "3 months (90 days)",
    },
    {
      investors: "Market Placement",
      percent: "25 %",
      tokens: "100 000 000",
      onetoken: "1.00 $",
      costtokens: "100 000 000 $",
      blocking: "3 months (90 days)",
    },
    {
      investors: "Mining pool",
      percent: "15 %",
      tokens: "60 000 000",
      onetoken: "-",
      costtokens: "-",
      blocking: "-",
    },
    {
      investors: "Developers",
      percent: "12.5 %",
      tokens: "50 000 000",
      onetoken: "-",
      costtokens: "-",
      blocking: "-",
    },
    {
      investors: "Marketing",
      percent: "5 %",
      tokens: "30 000 000",
      onetoken: "-",
      costtokens: "-",
      blocking: "-",
    },
    {
      investors: "Partners",
      percent: "5 %",
      tokens: "20 000 000",
      onetoken: "-",
      costtokens: "-",
      blocking: "-",
    },
  ];

  export const distributionforsix = [
    {
      checked: true,
      year: "First Year",
      users: { amt: "160 000 000", percent: "80%" },
      marketing: { amt: "20 000 000", percent: "10%" },
      partners: { amt: "20 000 000", percent: "10%" },
    },
    {
      checked: true,
      year: "Second Year",
      users: { amt: "160 000 000", percent: "80%" },
      marketing: { amt: "20 000 000", percent: "10%" },
      partners: { amt: "20 000 000", percent: "10%" },
    },
    {
      checked: true,
      year: "Third Year",
      users: { amt: "120 000 000", percent: "80%" },
      marketing: { amt: "15 000 000", percent: "10%" },
      partners: { amt: "15 000 000", percent: "10%" },
    },
    {
      checked: true,
      year: "Fourth Year",
      users: { amt: "120 000 000", percent: "80%" },
      marketing: { amt: "15 000 000", percent: "10%" },
      partners: { amt: "15 000 000", percent: "10%" },
    },
    {
      checked: true,
      year: "Fifth Year",
      users: { amt: "40 000 000", percent: "80%" },
      marketing: { amt: "5 000 000", percent: "10%" },
      partners: { amt: "5 000 000", percent: "10%" },
    },
    {
      checked: true,
      year: "Sixth Year",
      users: { amt: "40 000 000", percent: "80%" },
      marketing: { amt: "5 000 000", percent: "10%" },
      partners: { amt: "5 000 000", percent: "10%" },
    },
  ];

  export const tokencybor = [
    {
      line1: "Number of tokens issued / total coin supply",
      line2: "1 000 000 000",
    },
    { line1: " Number of blocked CYBOR tokens", line2: "60 000 000" },
    { line1: "Term of blocking CYBOR tokens", line2: "6 Years" },
  ];