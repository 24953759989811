export default {
  EMAIL_USED: "E-mail уже использован",
  MOBILE_USED: "Мобильный номер, который Вы ввели, уже зарегистрирован.",
  NAME_CANNOT_START_WITH_NUMBER:
    "Имя не может начинаться с цифры, пробела или специального символа",
  SECOND_NAME_CANNOT_START_WITH_NUMBER:
    "Фамилия не может начинаться с цифры, пробела или специального символа",
  email_must_email: "Пожалуйста введите корректный e-mail",
  mobile_Incorrect: "Некорректный номер телефона",
  passwordnomatch: "Пароли не совпадает",
  Subscribe: "Подпишитесь на e-mail рассылку новостей",
  Terms: "Я прочитал условия платежной системы PayCrypt и согласен с ними",
  fname: "Имя",
  fms: "Имя слишком короткое",
  in: "Некорректное Имя",
  lname: "Фамилия",
  lms: "Фамилия слишком коротка",
  entpass: "Введите пароль",
  pts: "Пароль слишком короткий",
  "email must be an email": "Электронная почта должна быть адресом электронной почтой",
  "mobile must be a phone number": "Мобильный должен быть номером телефона",
  "email must be an email,mobile must be a phone number":
    "электронная почта должна быть электронной почтой, мобильный должен быть номером телефона",

  //Newly Added
  DoYouHaveAccount: "У Вас уже есть аккаунт?",
  Login: "Авторизоваться",
  SignUpTo: "Зарегистрироваться в",
  Password: "Пароль",
  reentpass: "Повторно введите пароль",
  PhoneMustValid: "Телефон должен быть действительным",
  Term: "Политика конфиденциальности",
};
