import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, Suspense, useState } from "react";
import Container from "react-bootstrap/Container";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import { Row, Col } from "react-bootstrap";
import ScreenLayout from "src/components/layout/ScreenLayout";
import CustomButton from "@components/custom-button/CustomButton";
import MarketRatesComponent from "@components/market-rates/MarketRates";
import moment from "moment";
import "@features/market-rates/MarketRates.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import FooterCommon from "@components/footer-common/FooterCommon";
import SearchBox from "@components/search-box/SearchBox.js";
import AddCoinModal from "@components/add-coin-modal/AddCoinModal.js";
import { setMarketRatesCurrencies } from "@store/userredux";
import Loader from "@components/loader/Loader";
import transakSDK from "@transak/transak-sdk";

function Transak({
  getplatformTokensinfo,
  displayedCurrencies,
  addCurrency,
  fiatrate,
  PreferredCurrency,
  hideLoaderOnRefresh,
}) {
  const { t } = useTranslation();
  const [market, setmarket] = useState([]);
  const [chartsdata, setcharts] = useState([]);
  const [min, setMin] = useState([]);
  const [max, setMax] = useState([]);
  const [value, setvalue] = useState("");
  const [addCoin, setAddCoin] = useState(false);
  const [allFilteredTokens, setFilteredTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  let transak = new transakSDK({
    apiKey: process.env.REACT_APP_TSKKEY, // (Required)
    environment: "STAGING", // (Required)
    widgetHeight: "90%",
    hideMenu: true,
    themeColor: "#8901C7",
    fiatCurrency: "USD",
  });

  useEffect(() => {
    transak.init();
  }, []);

  // To get all the events
  transak.on(transak.ALL_EVENTS, (data) => {
    console.log(data);
  });

  // This will trigger when the user closed the widget
  transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
    transak.close();
  });

  // This will trigger when the user marks payment is made
  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
    console.log(orderData);
    transak.close();
  });
  return (
    <>
      <Container>
        <div id="marketrates">
          {loading && <Loader />}
          <Row></Row>
        </div>
      </Container>
      <FooterCommon />
    </>
  );
}

const mapStateToProps = ({ wallet = {}, user = {}, app = {} }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const displayedCurrencies = _.get(user, "marketRateDisplayCurrencies", []);
  const fiatrate = _.get(app, "fiatrate", false);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");

  return {
    getplatformTokensinfo,
    displayedCurrencies,
    fiatrate,
    PreferredCurrency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addCurrency: (data) => dispatch(setMarketRatesCurrencies(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Transak);
