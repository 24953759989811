import React, {useState, useEffect} from 'react'
import { ReactComponent as Lightmode} from '@images/lightmode.svg'
import { ReactComponent as Darkmode} from '@images/darkmode.svg'
import { connect } from "react-redux";
import Switch from "react-switch";
import '@components/dark-light-toogle/DarkLightToogleStyles.scss';

const DarkLightToogle = ({thememode, updateTheme}) => {
    const [isToogleOn, setIsToggleOn] = useState(thememode == "light" ? true : false)
    useEffect(() => {
        if(thememode == "light"){
            setIsToggleOn(true)
        } else{
          setIsToggleOn(false)
        }
    }, [])
    return (
        <Switch 
            onChange={()=>{
                setIsToggleOn(!isToogleOn)
                updateTheme(thememode == "light" ? "dark" : "light")
            }} 
            checked={isToogleOn} 
            borderRadius={6}
            height={23}
            handleDiameter={23}
            width={67}
            activeBoxShadow="none"
            offColor="var(--bglight)"
            onColor="var(--bglight)"
            offHandleColor="#017FDA"
            onHandleColor="#017FDA"
          
            uncheckedHandleIcon={
                <div className="uncheckedHandleIcon">
                <Darkmode className="darkmode" />
                </div>
            }
            checkedHandleIcon={
                <div className="checkedHandleIcon">
                <Lightmode className="lightmode" />
                </div>
            }
            checkedIcon={
                <div className="checkedIcon">
                    <Darkmode className="darkmode" />
                </div>
            }
            uncheckedIcon={
                <div className="uncheckedIcon">
                    <Lightmode className="lightmode" />
                </div>
            }
            className={`react-switch ${isToogleOn ? 'toogleon' : 'toogleoff'}`}
            id="small-radius-switch"
        />
    )
}

const mapStateToProps = ({ app = {}}) => {
    const thememode = _.get(app, "thememode", false);
    return {
      thememode,
    };
};
  
const mapDispatchToProps = (dispatch) => ({
    updateTheme: (data) => dispatch({ type: "updateTheme", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DarkLightToogle);
