import { call, put } from "redux-saga/effects";
import {
  createBankTrigger,
  createBankSuccess,
  createBankFailure,
  getBankSuccess,
  getFiatTransactionTrigger,
  getFiatTransactionSuccess,
  getFiatTransactionFailure,
  getFiatTransactionCountTrigger,
  getFiatTransactionCountSuccess,
  getFiatTransactionCountFailure,
  getBankTrigger,
  getPlatformBankSuccess,
  depositFiatFailure,
  depositFiatSuccess,
  depositFiatTrigger,
  createFiatWithDrawlSuccess,
  createFiatWithdrawlFailure,
  callFiatBalanceSuccess,
  callFiatBalanceFailure,
  getAllBankAccountsStart,
  getAllBankAccountsSuccess,
  getAllBankAccountsFailure,
  createBankAccountStart,
  createBankAccountSuccess,
  createBankAccountFailure,
  depositFiatClearMessages,
  getALLTransactionCountFailure,
  getALLTransactionCountSuccess,
  getALLTransactionCountTrigger,
  getALLTransactionFailure,
  getALLTransactionTrigger,
  getALLTransactionSuccess,
  getAllBankOwnerAccountsStart,
  getAllOwnerBankAccountsSuccess,
  getAllBankOwnerAccountsFailure,
  deleteBankAccountStart,
  deleteBankAccountFailure,
  deleteBankAccountSuccess,
} from "./fiatredux";
import Api from "./../services/ApiCaller";
import { get, getfiatWalletId, getRole, getWalletId } from "./utilities";
import { toast } from "react-toastify";
import { getAccessToken } from "./utilities";
import {
  setFiatDepositModalSuccess,
  setFiatWithdrawModalSuccess,
  setWithdrawModalSuccess,
} from "./appredux";

export function* createBank(api, { payload = {} }) {
  yield put(createBankTrigger(true));
  try {
    const {} = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.addBank,
      {
        BankName,
        address,
        logoBgColor,
        exchangeSymbol,
        BankDetails,
        bankCode,
        token,
      },
      true
    );
    yield put(createBankSuccess(result));
    const result2 = yield call(
      Api.callServer,
      api.getAllBanks,
      {
        token,
      },
      true
    );
    yield put(getBankSuccess(result2));
  } catch (e) {
    yield put(createBankFailure(e));
  } finally {
    yield put(createBankTrigger(false));
  }
}

export function* getAllBank(api, { payload = {} }) {
  yield put(getBankTrigger());
  const token = yield getAccessToken();
  const result = yield call(
    Api.callServer,
    api.getAllBanks,
    {
      token,
    },
    true
  );

  yield put(getBankSuccess(result));
}

export function* getPlatformBank(api, { payload = {} }) {
  const token = yield getAccessToken();
  const result = yield call(
    Api.callServer,
    api.getBank,
    {
      token,
    },
    true
  );

  yield put(getPlatformBankSuccess(result));
}

export function* depositFiatcurrency(api, { payload }) {
  yield put(depositFiatTrigger());
  try {
    const { CurrencyId, bankaccountId, amount } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.depositFiat,
      {
        CurrencyId,
        bankaccountId,
        amount,
        token,
      },
      true
    );
    yield put(depositFiatSuccess(result));
  } catch (e) {
    yield put(depositFiatFailure(e.message));
  }
  // finally {
  //   yield put(depositFiatTrigger());
  // }
}

export function* createFiatWithDrawlRequestAsync(api, { payload }) {
  try {
    const data = yield call(
      Api.callServer,
      api.CreateFiatWithDrawlRequest,
      payload,
      true
    );
    let id = yield getfiatWalletId();
    let token = yield getAccessToken();
    const result = yield call(Api.callServer, api.CallFiatBalance, {
      id,
      token,
    });
    yield put(callFiatBalanceSuccess(result));
    yield put(createFiatWithDrawlSuccess(data));
    yield put(setFiatWithdrawModalSuccess(false));
    yield put(setWithdrawModalSuccess(false));
  } catch (error) {
    yield put(createFiatWithdrawlFailure(error.message));
  }
}

export function* getFiatTransaction(api, { payload = {} }) {
  yield put(getFiatTransactionTrigger(true));
  try {
    const { skip, limit, startDate, endDate, type } = payload;
    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getfiattransaction,
      {
        token,
        id,
        skip,
        limit,
        type,
        startDate,
        endDate,
      },
      true
    );
    yield put(getFiatTransactionSuccess(result));
  } catch (error) {
    yield put(getFiatTransactionFailure(error.message));
  }
}
export function* getFiatTransactionCount(api, { payload = {} }) {
  yield put(getFiatTransactionCountTrigger(true));
  const { type } = payload;

  try {
    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getfiattransactioncount,
      {
        token,
        id,
        type,
      },
      true
    );
    yield put(getFiatTransactionCountSuccess(result));
  } catch (error) {
    yield put(getFiatTransactionCountFailure(error.message));
  }
}

export function* callFiatBalanceAsync(api, { payload }) {
  try {
    let id = yield getfiatWalletId();
    let token = yield getAccessToken();
    const data = yield call(Api.callServer, api.CallFiatBalance, { id, token });
    yield put(callFiatBalanceSuccess(data));
  } catch (error) {
    yield put(callFiatBalanceFailure(error.message));
  }
}

export function* getAllBankAccountsAsync(api, { payload }) {
  yield put(getAllBankAccountsStart());
  try {
    const token = yield getAccessToken();
    const data = yield call(
      Api.callServer,
      api.GetAllBankAccounts,
      { token },
      false
    );
    yield put(getAllBankAccountsSuccess(data));
  } catch (error) {
    console.log(error.message);
    yield put(getAllBankAccountsFailure(error.message));
  }
}

export function* createBankAccountAsync(api, { payload }) {
  yield put(createBankAccountStart());
  try {
    const {
      bankAccountNumber,
      BankId,
      BankAccountType,
      BranchUniqueId,
      IFSC,
      IBANs,
      SWIFT,
      exchangeId,
      accountHolderName,
      curencyType,
    } = payload;
    const token = yield getAccessToken();
    const data = yield call(Api.callServer, api.CreateBankAccount, {
      bankAccountNumber,
      BankId,
      BankAccountType,
      BranchUniqueId,
      IFSC,
      IBANs,
      SWIFT,
      exchangeId,
      accountHolderName,
      curencyType,
      token,
    });

    const data2 = yield call(
      Api.callServer,
      api.GetAllBankAccounts,
      { token },
      false
    );
    yield put(getAllBankAccountsSuccess(data2));

    yield put(createBankAccountSuccess());
  } catch (error) {
    yield put(createBankAccountFailure(error.message));
  }
}

export function* depositFiatClearMessagesAsync(api, { payload = {} }) {
  yield put(depositFiatClearMessages());
}

export function* getALLTransaction(api, { payload = {} }) {
  yield put(getALLTransactionTrigger(true));
  try {
    const { skip, limit, startDate, endDate, type } = payload;
    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getalltransaction,
      {
        token,
        id,
        skip,
        limit,
        type,
        startDate,
        endDate,
      },
      true
    );
    yield put(getALLTransactionSuccess(result));
  } catch (error) {
    yield put(getALLTransactionFailure(error.message));
  }
}
export function* getALLTransactionCount(api, { payload = {} }) {
  yield put(getALLTransactionCountTrigger(true));
  const { type } = payload;

  try {
    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getalltransactioncount,
      {
        token,
        id,
        type,
      },
      true
    );
    yield put(getALLTransactionCountSuccess(result));
  } catch (error) {
    yield put(getALLTransactionCountFailure(error.message));
  }
}

// get all owner bank accounts

export function* getAllOwnerBankAccounts(api, { payload }) {
  // console.log('inside saga of getAll bank owner line 1')
  yield put(getAllBankOwnerAccountsStart());
  try {
    const token = yield getAccessToken();
    const data = yield call(
      Api.callServer,
      api.GetAllOwnersBankAccount,
      { token },
      false
    );
    // console.log('Data from bank owner saga' , data);
    yield put(getAllOwnerBankAccountsSuccess(data));
  } catch (error) {
    console.log(error.message);
    yield put(getAllBankOwnerAccountsFailure(error.message));
  }
}
// get all owner bank accounts

export function* deleteBankAccountAsync(api, { payload }) {
  yield put(deleteBankAccountStart());
  try {
    const token = yield getAccessToken();
    const { id } = payload;
    const data = yield call(Api.callServer, api.deleteBankAccountFiat, {
      token,
      id,
    });
    yield put({ type: "getAllBankAccountsStart" });
    yield put(deleteBankAccountSuccess());
  } catch (error) {
    yield put(deleteBankAccountFailure(error));
  }
}
