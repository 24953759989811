
export default {
  Logo: "Logo",
  Thesizeofimage: "The size of the image can be taken up to",
  pixels: "pixels",
  sizetothefile: "size to the file",
  upto: " up to",
  inPNGorJPEGformat: "in PNG or JPEG format",
  Change: "Change",
  Exclude: "Exclude",
  Operator: "Operator",
  Edit: "Edit",
  Delete: "Delete",
  Commission: "Commission",
  Name: "Name",
  FirstName: "First Name",
  LastName: "Last Name",
  Email: "Email",
  Phone: "Phone",
  Login: "Login",
  Password: "Password",
  Save: "Save",
  Cancel: "Cancel",
  ChangePassword: "Change a Password",
  Settings: "Settings",
  LogOut: "Log out",
  OldPassword: "Old Password",
  NewPassword: "New Password",
  ConfirmPassword: "Confirm Password",
  ResetPassword: "Reset Password",
  MyProfile: "My Profile",
  Myaccounts: "My accounts",
  MyBalance: "My Balance",
  MyBankAccount: "My Bank Account",
  BankAccountsRegistered:
    "The bank accounts registered to your account are as follows",
  AddNewBankAccountt: "Add new bank account...",
  CreditCardMessage:
    "* Credit Card Deposit's and Withdrawal's are subject to Fiat limits.",
  MyCryptoAddress: "My Crypto Address",
  CryptoAddressAsFollows: "The crypto address of your account are as follows",
  AddNeww: "Add New...",
  AddNewCryptoAddress: "Add new crypto address...",
  Photo: "Photo",
  "Old password cannot be empty": "Old password cannot be empty",
  "Password and confirm password should match": "Password and confirm password should match",
  "Password cannot be empty": "Password cannot be empty",
  "Password and confirm password should match": "Password and confirm password should match",
  "Confirm password cannot be empty": "Confirm password cannot be empty",
  Merchants: "Merchants",
  terms: "Terms",
  InvalidCredentials:'Invalid Credentials',
  Passwordof8characters:"Password must be at least 8 characters",
  Swiftnumber: "Swift number",
  Ifscnumber: "Ifsc number",
  Ibannumber: "Iban number",
  MOBILE_USED: "Mobile Number You have Entered is already registered.",
  KINDLY_ENTER_VALID_NAME: "Kindly Enter Valid Name",
  Nametooshort: "Name is too short",
  InvalidPassword: "Invalid Password",
  PasswordchangedSuccessfully: "Password changed Successfully !",

};
