import { createSlice } from "@reduxjs/toolkit";

const BranchSlice = createSlice({
  name: "Branch Management",
  initialState: {
    createBranchLoading: undefined,
    createBranchError: undefined,
    listAllBranchesLoading: undefined,
    listAllBranchesError: undefined,
    deleteBranchLoading: undefined,
    deleteBranchError: undefined,
    editBranchStart: undefined,
    editBranchError: undefined,
    allBranches: [],
  },
  reducers: {
    createBranchStart: (state, action) => {
      return {
        ...state,
        createBranchLoading: true,
        createBranchError: undefined,
      };
    },

    createBranchSuccess: (state, action) => {
      return {
        ...state,
        createBranchLoading: false,
        createBranchError: undefined,
      };
    },

    createBranchFailure: (state, action) => {
      return {
        ...state,
        createBranchLoading: false,
        createBranchError: action.payload,
      };
    },

    listAllBranchesStart: (state) => {
      return {
        ...state,
        listAllBranchesLoading: true,
      };
    },
    listAllBranchesSuccess: (state, action) => {
      return {
        ...state,
        listAllBranchesLoading: false,
        allBranches: action.payload,
      };
    },

    listAllBranchesFailure: (state, action) => {
      return {
        ...state,
        listAllBranchesLoading: false,
        listAllBranchesError: action.payload,
        allBranches: [],
      };
    },

    updateStatustrigger: (state, action) => {
      return {
        ...state,
        listAllBranchesLoading: action.payload,
       };
    },
    updateStatusSuccess: (state, action) => {
      return {
        ...state,
        listAllBranchesLoading: false,
        allBranches: action.payload,
      };
    },

    deleteBranchStart: (state, action) => {
      return {
        ...state,
        deleteBranchLoading: true,
      };
    },

    deleteBranchSuccess: (state, action) => {
      return {
        ...state,
        deleteBranchStart: false,
      };
    },

    deleteBranchFailure: (state, action) => {
      return {
        ...state,
        deleteBranchStart: false,
        deleteBranchError: action.payload,
      };
    },

    editBranchStart: (state, action) => {
      return {
        ...state,
        editBranchStart: true,
        editBranchError: undefined,
      };
    },

    editBranchSuccess: (state, action) => {
      return {
        ...state,
        editBranchStart: false,
        editBranchError: undefined,
      };
    },

    editBranchFailure: (state, action) => {
      return {
        ...state,
        editBranchStart: false,
        editBranchError: action.payload,
      };
    },

    getBranchpaymentListSuccess: (state, action) => {
      return {
        ...state,
        BranchpaymentList: action.payload,
       };
    },

    

    clearBrnachReduxSuccuss: (state, action) => {
      return {};
    },

    clearBranchReduxAllErrors :(state, action)=>{
      return{
        ...state,
        createBranchError:'',
        listAllBranchesError:'',
        deleteBranchError:'',
        editBranchError:'',
      }
    }
  },
});

export default BranchSlice.reducer;

export const {
  createBranchStart,
  createBranchSuccess,
  createBranchFailure,
  listAllBranchesStart,
  listAllBranchesLoading,
  listAllBranchesSuccess,
  listAllBranchesFailure,
  deleteBranchStart,
  deleteBranchSuccess,
  deleteBranchFailure,
  editBranchStart,
  editBranchSuccess,
  editBranchFailure,
  clearBrnachReduxSuccuss,
  updateStatusSuccess,
  updateStatustrigger,
  clearBranchReduxAllErrors,
  getBranchpaymentListSuccess
} = BranchSlice.actions;
