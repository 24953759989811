export default {
  TotalValueLocked: "Total value locked",
  Current: "Current",
  Bonus: "Bonus",
  Today: "today",
  MarkerRate: "Marker Rate",
  Buy: "Buy",
  BuyCyborCoin: "Buy PayCrypt Coin",
  BuyWithCurrent: "Buy with using your current balances",
  CyborCoin: "PayCrypt Coin",
  With: "With",
  ExchangeRates: "Exchange rates",
  Cybor: "Cybor",
  InsufficientBalance: "Insufficient Balance",
  Lessthenminimumamount: "Less then minimum value",
  Minimum: "Minimum",
  Fees: "Fees",
  Value: "value",
  My: "My",
  Balances: "Balances",
  TransactionWaitMessage: "The transaction may take 1-3 Minutes. Please wait!",
  FiatBalances: "Fiat Balances",
  CurrencyPair: "Currency Pair",
  Withdrawisdeactivated: "Withdraw is deactivated for this token",
};
