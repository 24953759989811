export default {
  NO: "No",
  AmountBuy: "Amount buy",
  Date: "Date",
  Rate: "Rate",
  Cost: "Cost",
  Round: "Round",
  Bonus: "Bonus",
  UnlockedDate: "Unlocked date",
  Action: "Action",
  Claim: "Claim",
  Tokenomics: "Tokenomics",
  Protocol: "Protocol",
  BinanceSmartChain: "Binance Smart Chain",
  Token: "Token",
  Tracker: "Tracker",
  TotalAmount: "Total amount",
  Billion: " billion",
  TypeOfEmission: "Type of emission",
  LimitedControlled: " limited controlled",
  Download: "Download",
  Whitepaper: "Whitepaper",
  DownloadTokenomics: "Download Tokenomics",
  DistributionOfCYBOR: "Distribution of PayCrypt tokens in the first year",
  TotalNoCoinAvlb:
    "The total number of available coins in circulation in the first year",
  Percent: "Percent",
  NumberOfTokens: "Number of tokens",
  CostOf1Token: "The cost of 1 token",
  TotalCostOfTokens: "The total cost of tokens",
  BlockingPeriod: "Blocking period",
  InvestorsRBNo1: "Investors closed round No 1 ",
  InvestorsClosedRound: "Investors closed round No",
  Months: "months",
  Days: "days",
  PlacementOnExchange: "Placement on the exchange",
  MiningPool: "Mining pool",
  Developers: "Developers",
  Marketing: "Marketing",
  Partners: "Partners",
  CYBORCOIN: "PayCrypt COIN",
  TR: "Total Rounds",
  TT: "Total Transactions",
  TTS: "Total Tokens Sent",
  List: "List",
  
  EnterTokenLimit: "Enter token limit",
  EnterExchangeRate: "Enter exchange rate",
  EnterLockingPeriodInDays: "Enter locking period in days",
  CreateNewRound: "Create a new Round",
  CreatingRoundMsg: "Creating new round. Please wait!",
  ROUNDNO: "ROUND NO",
  EXCHANGERATE: "EXCHANGE RATE",
  LOCKINGPERIOD: "LOCKING PERIOD",
  TOKENLIMIT: "TOKEN LIMIT",
  TOKENDISTRIBUTED: "TOKEN DISTRIBUTED",
  TOKENCLAIMED: "TOKEN CLAIMED",
  ROUNDNAME:"Round Name",
  Bonusinpercent:'Bonus in percent',
  bonus:"bonus",
  Activate:"Activate",
  OpenWhitepapers: "Open Whitepapers",
  OpenTokenomics: "Open Tokenomics",
  DownloadWhitepapers: "Download Whitepapers",
  DownloadTokenonmics: "Download Tokenonmics",
  TotalBlockedTokens: "Total blocked tokens",
  TokenUnlockingPeriod: "Token unlocking period",
  years: "years",
  FirstYear: "First Year",
  SecondYear: "Second Year",
  ThirdYear: "Third Year",
  FourthYear: "Fourth Year",
  FifthYear: "Fifth Year",
  SixthYear: "Sixth Year",
  QualityTokens: "Quality tokens",
  Priceofonetoken: "Price of one token",
  Totalcosttokens: "Total cost tokens",
  Blockingperiod: "Blocking period",
  DistributionForSixYears: "Distribution of PayCrypt tokens for six year",
  Year: "Year",
  Users: "Users",
  Marketing: "Marketing",
  Partners: "Partners",
  tokensissuedsixyears: "The total number of available tokens issued in 6 years",
  TokenCybor: "Token Cybor",
  SaleoftokensCYBOR: "Sale of tokens CYBOR",
  Saleoftokens: "Sale of tokens",
  Blockingperiod: "Blocking period",
  ClosedroundNo1: "Closed round No1",
  ClosedroundNo2: "Closed round No2",
  ClosedroundNo3: "Closed round No3",
  costof1tokenonthedateoflisting: "The cost of 1 token on the date of listing",
  Termofblockingonthestockexchange: "Term of blocking on the stock exchange",
  minimumamounttopurchasetokens: "The minimum amount to purchase tokens from 1 account",
  maximumamountofpurchasetokens: "The maximum amount of purchase of tokens from 1 account",
  notlimited: "not limited",
  Bonusrewardforparticipation: "Bonus reward for participation in closed IDO rounds",
  Bonusrewardfortokenblocking: "Bonus reward for token blocking",
  byparticipatinginround: "by participating in round",
  Totalpercentageofremunerationfor6months: "Total percentage of remuneration for 6 months when participating in the first round (blocking PayCrypt tokens for 6 months)",
  "Investors closed round #1": "Investors closed round #1",
  "Investors closed round #2": "Investors closed round #2",
  "Investors closed round #3": "Investors closed round #3",
  "Market Placement": "Market Placement",
  "Mining pool	": "Mining pool",
  "Mining pool": "Mining pool",
  "6 months (180 days)": "6 months (180 days)",
  "3 months (90 days)": "3 months (90 days)",
  "First Year": "First Year",
  "Second Year": "Second Year",
  "Third Year": "Third Year",
  "Fourth Year": "Fourth Year",
  "Fifth Year": "Fifth Year",
  "Sixth Year": "Sixth Year",
  "Number of tokens issued / total coin supply": "Number of tokens issued / total coin supply",
  " Number of blocked PayCrypt tokens": "Number of blocked PayCrypt tokens",
  "Term of blocking PayCrypt tokens": "Term of blocking PayCrypt tokens",
  "6 Years": "6 Years",
  "Potocol": "Protocol",
  "Name Token": "Name Token",
  "Tracker": "Tracker",
  "Total Amount": "Total Amount",
  "Types of emision": "Types of emision",
  "limied controlled": "limited controlled",
  Privatesalestage2: "Private sale stage 2",
  Claimed:'Claimed',
  IDO: "IDO",
  "Seed Round": "Seed Round",
  "Private sale 1": "Private sale 1",
  "Private sale 2": "Private sale 2",
  "Public sale": "Public sale",
  "Liquidity": "Liquidity",
  "Team": "Team",
  "Partners and Advisors": "Partners and Advisors",
};
