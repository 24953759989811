import React, { useState, useEffect } from "react";
import "@components/coin-list-balance/CoinListBalance.scss";
import { ReactComponent as CollapseIcon } from "@images/sidebar/collapse.svg";
import { ReactComponent as VerticleDotsIcon } from "@images/Vertical.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SmallButton from "@components/small-button/SmallButton";
import { connect } from "react-redux";
import BigNumber from "bignumber.js";
const CoinListBalance = ({
  logo,
  name,
  bgcolor,
  symbol,
  balance,
  decimals,
  coinkey,
  activekey,
  tag,
  showbuttons,
  hidesymbol,
  showtagontop,
  fromdashboard,
  tableno,
  setDepositModal,
  setExchangeModal,
  setwithdrawModal,
  onClick,
  thememode,
  fromwallet,
  setcoinItem,
  coin,
  getFiatBal
}) => {
  
  return (
    <div
      className={`CoinListBalance pointer ${
        activekey === coinkey && "activeCoinBal"
      } ${thememode != "dark" ? "darkcoinlist" : null}`}
      onClick={onClick}
    >
      <div className="coinlistleftcontent">
        <div className="coinImageWrapflex">
          <div className="coinimgwrapper" style={{ backgroundColor: bgcolor }}>
            <img
              className="logoSize"
              src={`${process.env.REACT_APP_IMGLINK}${logo}`}
            />
          </div>
        </div>
        {/* <div className="coinNameSymbolTag">
          {
            <div
              className={`${
                showtagontop == true ? "maketagabsolute" : null
              } tagwrapper`}
            >
              <p>
                {tag == "ETH"
                  ? "ERC20"
                  : tag == "BSC"
                  ? "BEP20"
                  : tag == "TRON"
                  ? "TRC20"
                  : tag}
              </p>
            </div>
          }
          {(fromdashboard && <p className="coinname">{name}</p>) || (
            <>
              <p className="coinSymbolbelowname">
                {symbol}
                {!fromwallet && showtagontop && (
                  <div className={`tagwrapper tagforlargescreen`}>
                    <p>
                    {tag == "ETH"
                  ? "ERC20"
                  : tag == "BSC"
                  ? "BEP20"
                  : tag == "TRON"
                  ? "TRC20"
                  : tag}
                    </p>
                  </div>
                )}
              </p>
              <p className="coinname">
                {name}{" "}
                {fromwallet && showtagontop && (
                  <div className={`tagwrapper tagforlargescreen`}>
                    <p>
                    {tag == "ETH"
                  ? "ERC20"
                  : tag == "BSC"
                  ? "BEP20"
                  : tag == "TRON"
                  ? "TRC20"
                  : tag}
                    </p>
                  </div>
                )}
              </p>
            </>
          )}
        </div> */}
           <div className="coinNameSymbolTag">
          {
            showtagontop == true ?
            <div
            className= "maketagabsolute tagwrapper"
          >
            <p>
              {tag == "ETH"
                ? "ERC20"
                : tag == "BSC"
                ? "BEP20"
                : tag == "TRON"
                ? "TRC20"
                : tag}
            </p>
          </div> :
          <div
              // className={`${
              //   showtagontop == true ? "maketagabsolute" : null
              // } tagwrapper`}
            >
              {/* <p>
                {tag == "ETH"
                  ? "ERC20"
                  : tag == "BSC"
                  ? "BEP20"
                  : tag == "TRON"
                  ? "TRC20"
                  : tag}
              </p> */}
            </div>
            
          }
          {(fromdashboard && <p className="coinname">{name}</p>) || (
            <>
              <p className="coinSymbolbelowname">
                {symbol}
                {!fromwallet && showtagontop && (
                  <div className={`tagwrapper tagforlargescreen`}>
                    <p>
                    {tag == "ETH"
                  ? "ERC20"
                  : tag == "BSC"
                  ? "BEP20"
                  : tag == "TRON"
                  ? "TRC20"
                  : tag}
                    </p>
                  </div>
                )}
              </p>
              <p className="coinname">
                {name}{" "}
                {fromwallet && showtagontop && (
                  <div className={`tagwrapper tagforlargescreen`}>
                    <p>
                    {tag == "ETH"
                  ? "ERC20"
                  : tag == "BSC"
                  ? "BEP20"
                  : tag == "TRON"
                  ? "TRC20"
                  : tag}
                    </p>
                  </div>
                )}
              </p>
            </>
          )}
        </div>
        <div className="coinnameandsymbol">
          {tableno == "first" ? (
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 500 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {/* { balance !=undefined ?Number(balance / 10 ** decimals).toFixed(4) : 0} */}
                  {/* {(balance && Number(balance / 10 ** decimals).toFixed(4)) || 0} */}

                  {balance != undefined
                    ? BigNumber(balance / 10 ** decimals).toFixed(
                        4,
                        BigNumber.ROUND_FLOOR
                      )
                    : 0}
                </Tooltip>
              )}
            >
              {(tableno == "first" && (
                <p>
                  {BigNumber(balance / 10 ** decimals).toFixed(
                    4,
                    BigNumber.ROUND_FLOOR
                  )}
                </p>
              )) || <p>{balance}</p>}
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 500 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                    <p>{BigNumber(balance).toFixed(4, BigNumber.ROUND_FLOOR)}</p>
                </Tooltip>
              )}
            >
              {(tableno == "first" && (
                <p>
                  {BigNumber(balance / 10 ** decimals).toFixed(
                    4,
                    BigNumber.ROUND_FLOOR
                  )}
                </p>
              )) 
              ||
              //  <p>{balance}</p>
               <p>{BigNumber(balance).toFixed(4, BigNumber.ROUND_FLOOR)}</p>
              }
            </OverlayTrigger>
          )}

          <p
            className={`coinSymbol ${hidesymbol == true ? "hidesymbol" : null}`}
          >
            {symbol}
          </p>
        </div>
        {showbuttons && (
          <div className="smallbuttons">
            <SmallButton
              type="deposit"
              hideButtonName={true}
              onclick={() => {
                setDepositModal();
              }}
            />
            <SmallButton
              type="withdraw"
              hideButtonName={true}
              onclick={() => {
                setwithdrawModal();
              }}
            />
            <SmallButton
              type="exhange"
              hideButtonName={true}
              onclick={() => {
                //setcoinItem(coin)
                setExchangeModal();
              }}
            />
          </div>
        )}
        <div
          className={`rightnav ${activekey === coinkey && "activecoinlist"}`}
          onClick={onClick}
        >
          {(activekey === coinkey && (
            <CollapseIcon className="collapseicon" />
          )) || (
            <>
              <VerticleDotsIcon className="verticledotsicon" />
              <div className="onhoverimg">
                <CollapseIcon className="collapseicon" />
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className="coinlistrightcontent">
                
               
            </div> */}
    </div>
  );
};
const mapStateToProps = ({ app = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  return {
    thememode,
  };
};
export default connect(mapStateToProps, null)(CoinListBalance);
