import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import uparrowgreen from "@images/grrenarrow.svg";
import uparrowred from "@images/redarrow.svg";
import { connect } from "react-redux";
import Graphwallet from "@images/Graphwallet.svg";
import FiatwalletDatedropdown from "@components/fiatwallet-datedropdown/FiatwalletDatedropdown.js";
import "@components/currencypair-wallet/CurrencyPairWalletStyle.scss";
import FiatwalletcurrencyDropdown from "@components/fiatwallet-currencydropdown/FiatwalletcurrencyDropdown.js";
import FiatwalletcurrencyNewDropdown from "@components/fiatwallet-currencynewdropdown/FiatwalletcurrencyNewDropdown.js";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { ResponsiveStream } from "@nivo/stream";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as FilterIcon } from "@images/filter.svg";
import { ReactComponent as DownArrow } from "@images/dropdown.svg";
import { ReactComponent as Info } from "@images/info.svg";
import { getCSymbol, getESymbol } from "src/utils/Currencies";
const Tokenomics = (props) => {
  const { t, i18n, ready } = useTranslation();
  const {
    crypto,
    marketdata,
    selectedCoin,
    chartdata,
    coin,
    currency,
    currencies,
    startDate,
    endDate,
    setStartDate,
    min,
    max,
    setEndDate,
    thememode,
    selectedSymbol,
    fiatrate,
    PreferredCurrency,
  } = props || {};
  const [logoBgColor, setLogoBgColor] = useState("");
 
 
  useEffect(() => {
     let logobgcolor
    if(selectedCoin&&selectedCoin.coin&&selectedCoin.coin.logoBgColor){
     logobgcolor = selectedCoin.coin.logoBgColor.includes("\r")
      ? selectedCoin.coin.logoBgColor.split(`\r`)[0]
      : selectedCoin.coin.logoBgColor;
    }else{
      logobgcolor = selectedCoin.logoBgColor.includes("\r")
      ? selectedCoin.logoBgColor.split(`\r`)[0]
      : selectedCoin.logoBgColor;
    }
      setLogoBgColor(logobgcolor);
    // console.log('token name' , selectedCoin.tORcName)
  }, [selectedCoin]);

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };
  useEffect(() => {
    // console.log('i am theme' , thememode)
  } , [])
  let name = crypto ? "" : marketdata[`${moment().format("YYYY-MM-DD")}`];
  return (
    <div className="currencypair">
      <div className="tokensection">
        <div className="toptitle">
          {(crypto && (
            <span>
              {" "}
              {selectedCoin && selectedCoin.tORcName}{" "}
              {selectedCoin && selectedCoin.coin && selectedCoin.coin.type}
            </span>
          )) || (
            <span>
              {" "}
              {t("CurrencyPair")}{" "}
              {/* {selectedCoin && selectedCoin.coin && selectedCoin.coin.type} */}
            </span>
          )}
          <div className="filterbtns d-inline-block d-lg-none float-end">
            <div className="d-flex">
              {/* <CustomButton
                name={
                  <>
                    <Info className="infoIcon" />
                    <DownArrow className="calenderIcon ps-1" />
                  </>
                }
                filled={true}
                primary={false}
                className="buttonmain"
                onClick={() => {}}
              /> */}
              <div className="mobileDateDropdown">
                <FiatwalletDatedropdown
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    formobile={true}
                  />
              </div>
              {/* <CustomButton
                name={
                  <>
                    USD
                    <DownArrow className="calenderIcon ps-1" />
                  </>
                }
                filled={true}
                primary={false}
                className="buttonmain"
                onClick={() => {}}
              /> */}
              {/* currency dropdown */}
              <div className="mobileCurrDropdown">
                <FiatwalletcurrencyDropdown
                  coin={coin}
                  pair={`${currency}`}
                  currency={currency}
                  currencies={currencies}
                  setCurrency={props.setCurrency}
                  formobile={true}
                />
              </div>

            </div>
          </div>
        </div>
        <div className="toptitle1 d-none d-lg-block">
          <span className="txt1">{t("BasedOn")}</span>
          <span className="txt2">
            {" "}
            {crypto ? "binance.com" : "morningstar.com"}
          </span>
        </div>
        <hr className="hrline d-none d-lg-block" />
        <div className="currpaircontent">
          <div className="middlesection">
            <div className="rightside d-lg-block d-none">
              <div className="firstdropdown">
                <FiatwalletDatedropdown
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
              {crypto ?
              <div className="seconddropdown crypto-dropdown">
                <FiatwalletcurrencyDropdown
                  coin={coin}
                  pair={`${coin}/${currency}`}
                  currency={currency}
                  currencies={currencies}
                  setCurrency={props.setCurrency}
                />
              </div> :
              <div className="seconddropdown fiat-dropdown">
                <FiatwalletcurrencyNewDropdown
                  coin={coin}
                  pair={`${coin}/${currency}`}
                  currency={currency}
                  currencies={currencies}
                  setCurrency={props.setCurrency}
                />
              </div>
                  }
            </div>
          </div>
          {!crypto && (
            <div className="statictextsection">
              <div className="statictextone">
                <span className="staticlineone">{t("Price")}</span>
                <span className="staticlinetwo">
                  {" "}
                  {/* 1 {coin} = {(marketdata && marketdata[`${currency}`]) || 1} {currency} */}
                  1 {coin} =
                   {(marketdata !== undefined && Number(marketdata[`${currency}`]&&marketdata[`${currency}`].value).toFixed(2)) || 1} {currency}
                </span>
              </div>

              <div className="statictexttwo">
                <span className="staticlineone">{t("Date")}</span>
                <span className="staticlinetwo">
                  {" "}
                  {moment().format("DD MMMM YYYY")}
                </span>
              </div>
              <div className="statictextthree d-none d-lg-block">
                <span className="staticlineone">24h% {t("Change")}</span>
                <div className="value">
                  <div className="greentext">1.64%</div>

                  <div className="uparrow">
                    <img src={uparrowgreen} className="updown"></img>
                  </div>
                </div>
              </div>
            </div>
          )}
          {crypto && (
            <div className="statictextsection d-none d-lg-block">
              <div className="staticlinepartone">
                <span className="staticlineone ">{t("Price")}</span>
                {selectedCoin && selectedCoin.tORcName !== "TRYCoin" ?
                <span className="staticlinetwo">
                  {" "}
                  {BigNumber(marketdata && marketdata.bidPrice).toFixed(2)}{" "}
                  
                  {currency}
                </span>
                 :<span className="staticlinetwo">
                 {" "}
                 
                 {BigNumber(1)
                     .dividedBy(marketdata && marketdata.bidPrice)
                     .multipliedBy(
                       (fiatrate && fiatrate[getESymbol(PreferredCurrency)]&& fiatrate[getESymbol(PreferredCurrency)].value) ||
                         1
                     )
                     .toFixed(2, BigNumber.ROUND_FLOOR)} { " "}
                 {currency}
               </span>
              }
                
              </div>

              <div className="statictextthree">
                <span className="staticlineone">24h% {t("Change")}</span>
                <div className="value">
                  <div
                    className={`${
                      marketdata && marketdata.priceChangePercent.includes("-")
                        ? "redtext"
                        : "greentext"
                    }`}
                  >
                    {marketdata && marketdata.priceChangePercent}%
                  </div>

                  <div className="uparrow">
                    <img
                      src={
                        marketdata &&
                        marketdata.priceChangePercent.includes("-")
                          ? uparrowred
                          : uparrowgreen
                      }
                      className="updown"
                    ></img>
                  </div>
                </div>
              </div>

              <div className="statictextfour">
                <div className="aligntxt">
                  <span className="volume">{t("Volume")} (24h)</span>
                </div>
                <div className="alligntxt-one">
                  <span className="valuetext">
                    {formatCash(
                      BigNumber(marketdata && marketdata.quoteVolume).toFixed(2)
                    )}{" "}
                    {currency}
                  </span>
                </div>
              </div>

              <div className="statictextfive">
                <div className="aligntxt">
                  <span className="volume">{t("MarketCap")}</span>
                </div>
                <div className="alligntxt-one">
                  <span className="valuetext">378.15B {currency}</span>
                </div>
              </div>
            </div>
          )}

          {crypto && (
            <div className=" text-start d-block d-lg-none">
              <span className="staticlineone ">{t("Price")}</span>
              <span className="staticlinetwo ">
                {" "}
                {BigNumber(marketdata && marketdata.bidPrice).toFixed(2)}{" "}
                {currency}
              </span>
              <span
                className={`percenttextmob ${
                  marketdata && marketdata.priceChangePercent.includes("-")
                    ? "redtext"
                    : "greentext"
                }`}
              >
                {marketdata && marketdata.priceChangePercent}%
              </span>
            </div>
          )}

          <div className="grapharea">
            <ResponsiveLine
              data={[
                {
                  id: "japan",
                  color: `linear-gradient(94.22deg,${logoBgColor} 0.7%, ${logoBgColor}80)`,
                  data: chartdata,
                },
              ]}
              // colors={[logoBgColor]}
              
              // colors={thememode && thememode=="light" && logoBgColor ? logoBgColor :logoBgColor && logoBgColor !="#000000" || logoBgColor !="#393939" ? logoBgColor:{ scheme: 'pastel2' }}
              colors={thememode && thememode=="light" ? logoBgColor : logoBgColor !="#000000" ? logoBgColor :{ scheme: 'pastel2' }}
              keys={["y"]}
              margin={{ top: 20, right: 10, bottom: 40, left: 40 }}
               yScale={{
                type: "linear",
                min,
                max,
                stacked: true,
                reverse: false,
              }}
              enableArea={true}
              useMesh={true}
               enablePoints={false}
              enableCrosshair={true}
              axisTop={null}
              areaBaselineValue={min}
              areaOpacity={0.2}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -30,
                }}
              isInteractive={true}
              pointLabelYOffset={-40}

              axisLeft={{
                tickSize: 2,
                tickValues: 10,
                orient: "left",
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
              }}
              enableStackTooltip={true}
              enableGridX={false}
              curve="cardinal"
              offsetType="none"
              borderColor={`${logoBgColor}`}
              borderWidth={1}
              dotSize={8}
              dotColor={{ from: "color" }}
              dotBorderWidth={1}
              dotBorderColor={{ from: "color", modifiers: [["darker", 0.7]] }}
              animate={true}
            />
          </div>
        </div>
      </div>

      {/* the below design is for crypto wallet on click graph change */}
    </div>
    // </div>
  );
};


const mapStateToProps = ({ app = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const fiatrate = _.get(app, "fiatrate", false);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");
  return {
    thememode ,
    fiatrate,
    PreferredCurrency,
  };
};
export default connect(mapStateToProps , null)(Tokenomics);
