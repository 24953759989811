export default {
  trademark: "© 2023 Ticari Marka PayCrypt",
  CyborexPlatform: "Cyborex platformu",
  CyborPayPaymentSystem: "PayCrypt ödeme sistemi",
  CyborcoinCryptocurrency: "Cyborcoin kripto para birimi",
  OwnedBy: "PayCrypt Pİ BİLİŞİM TEKNOLOJİLERİ VE TİCARET ANONİM ŞİRKETİ aittir.",
  FooterPara1:
    " / Tüm hakları saklıdır. PayCrypt, PayCrypt şirketinin bir ürünüdür ve PayCrypt Coin (CYBOR) alımı da dahil olmak üzere Cryptocurrency yatırım faaliyetleri piyasa riskine tabidir.",
  FooterPara2:
    "PayCrypt, size anında PayCrypt Coin (CYBOR) satın almanın kolay ve kullanışlı yollarını sunar. Kullanıcıları borsada sunulan kripto para birimleri hakkında kapsamlı bir şekilde bilgilendirmek için her türlü çabayı gösteriyoruz,",
  ResultPurchase: "ancak satın alma sonucunda ortaya çıkabilecek sonuçlardan sorumlu değiliz",
  Cyborcoin: "Cyborcoin",
  cryptocurrency: "kripto para",
  RightReserved: " / Tüm hakları saklıdır",
  FAQs: "SSS",
  CommissionsFees: "Komisyonlar ve Ücretler",
  TermsCondition: "Şartlar ve koşullar",
  PrivacyPolicy: "Gizlilik Politikası",
};
