import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import facebook from "@images/footer/facebook.svg";
import linkedin from "@images/footer/linkedin.svg";
import insta from "@images/footer/insta.svg";
import youtube from "@images/footer/youtube.svg";
import twitter from "@images/footer/twitter.svg";
import second from "@images/footer/second.svg";
import telegram from "@images/footer/telegram.svg";
import cyborcoin from "@images/footer/cyborcoin.svg";
import cyborholding from "@images/footer/cyborholding.svg";
import cyborpay from "@images/footer/cyborpay.svg";
import cyborexchange from "@images/footer/cyborexchange.svg";
import cyborexchangedark from "@images/cyborexchangedark.svg";
import cyborcoindark from "@images/cyborcoindark.svg";
import cyborpaydark from "@images/cyborpaydark.svg";
import cyborholdingdark from "@images/cyborholdingdark.svg";
import "@components/footer/Footer.scss";
import _ from "lodash";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const lang = localStorage.getItem("lang");
  const { theme, thememode } = props;
  const { t } = useTranslation();
  return (
    <div className="footer">
      {/* <div className="alignment">
        <div className="socialicon1">
          <a  href={`${lang == "ru" ? 'https://cyborholding.com/ru' : lang == "tr"? 'https://cyborholding.com/tr' : 'https://cyborholding.com/en'}`}>
            {" "}
            <img src={thememode == "light" ? cyborholding : cyborholdingdark} />
          </a>
        </div>
        <div className="socialicon1">
          <a href={`${lang == "ru" ? 'https://cyborcoin.com/ru' : lang == "tr"? 'https://cyborcoin.com/tr' : 'https://cyborcoin.com/en'}`}>
            {" "}
            <img src={thememode == "light" ? cyborcoin : cyborcoindark} />
          </a>
        </div>
        <div className="socialicon1">
          <a href={`${lang == "ru" ? 'https://cyborpay.com/ru' : lang == "tr"? 'https://cyborpay.com/tr' : 'https://cyborpay.com/en'}`}>
            {" "}
            <img src={thememode == "light" ? cyborpay : cyborpaydark} />
          </a>
        </div>
        <div className="socialicon1">
          <a href="https://cyborex.io/">
            {" "}
            <img
              src={thememode == "light" ? cyborexchange : cyborexchangedark}
            />
          </a>
        </div>
      </div> */}
      {/* <div className="align">
        <p className="txt">
          {t("trademark")}{" "}
          <a href={`${lang == "ru" ? 'https://cyborholding.com/ru' : lang == "tr"? 'https://cyborholding.com/tr' : 'https://cyborholding.com/en'}`}>
            {" "}
            <span className="applink"> www.cyborholding.com</span>
          </a>{" "}
          , {t("CyborexPlatform")}
          <a href="https://cyborex.io/">
            {" "}
            <span className="applink"> www.cyborex.io </span>
          </a>{" "}
          , {t("CyborPayPaymentSystem")}{" "}
          <a href={`${lang == "ru" ? 'https://cyborpay.com/ru' : lang == "tr"? 'https://cyborpay.com/tr' : 'https://cyborpay.com/en'}`}>
            {" "}
            <span className="applink"> www.cyborpay.com </span>
          </a>
          ,{t("CyborcoinCryptocurrency")}
          <a href={`${lang == "ru" ? 'https://cyborcoin.com/ru' : lang == "tr"? 'https://cyborcoin.com/tr' : 'https://cyborcoin.com/en'}`}>
            {" "}
            <span className="applink">www.cyborcoin.com </span>
          </a>
          {t("OwnedBy")}
          <br />
          {t("FooterPara1")} <br />
          {t("FooterPara2")} <br />
          {t("ResultPurchase")}
        </p>
      </div> */}

      <div className="alignment">
        <div className="socialicon">
          <a
          // href="https://www.facebook.com/cyborholding.ru"
          // target="_blank"
          >
            {" "}
            <img src={facebook} />
          </a>
        </div>
        <div className="socialicon">
          <a
            href="#"
            //  href="https://www.linkedin.com/in/cybor-holding-18602a211/"
            //  target="_blank"
          >
            {" "}
            <img src={linkedin} />
          </a>
        </div>
        <div className="socialicon">
          <a
            href="#"
            // href="https://twitter.com/Cybor_Holding"
            //  target="_blank"
          >
            {" "}
            <img src={twitter} />
          </a>
        </div>
        <div className="socialicon">
          <a
            href="#"
            // href="https://www.instagram.com/cyborholding/" target="_blank"
          >
            {" "}
            <img src={insta} />
          </a>
        </div>
        <div className="socialicon">
          <a
            href="#"
            // href="https://www.youtube.com/channel/UCPh4S2IgwSVQh1L7Fr5fKhw"
            // target="_blank"
          >
            {" "}
            <img src={youtube} />
          </a>
        </div>
        <div className="socialicon">
          <a
            href="#"
            //  href="https://vk.com/cyborholding" target="_blank"
          >
            {" "}
            <img src={second} />
          </a>
        </div>
        <div className="socialicon">
          <a
            href="#"
            //  href="https://t.me/CyborHolding" target="_blank"
          >
            {" "}
            <img src={telegram} />
          </a>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ app = {} }) => {
  const thememode = _.get(app, "thememode", false);
  return {
    thememode,
  };
};

export default connect(mapStateToProps, null)(Footer);
