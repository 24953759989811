export default {
  TransferHistory: 'Transfer Geçmişi',
  TransactionDetails: "Transfer Detayları",
  TransactionId: 'İşlem İD numarası',
  Date: 'Tarih',
  From: 'Satış',
  To: 'Alış',
  Coin: 'Coin',
  Amount: 'Miktar',
  Status: 'Durum',
  Showing: 'gösteriliyor',
  from: 'itibaren',
  Deposit: 'Yatırım',
  Withdrawals: 'Çekim',
  ExchangeID: 'Döviz İD numarası',
  AmountReceived: 'Alınan Tutar',
  completed: 'Tamamlandı',
  ExchangeHistory: 'Döviz Geçmişi',
  COMPLETED: "TAMAMLANDI",
  FAILED: "BAŞARISIZ OLDU",
  PENDING: "BEKLİYOR",
  ReplenishmentBankTransfer: 'Banka havalesi ile yatırım',
  ReceiverName: 'Alıcı adı',
  RecipientOfMoneyTransfers: 'EFT ile yapılan havalelerin alıcısıdır.',
  Remaining24hourDepositLimit: 'Kalan 24 saatlik yatırım limitiniz',
  Remaining30dayDepositLimit: 'Kalan 30 günlük yatırım limiti',
  TransferLimitMessage: 'Alt limitsiz günlük para yatırma limiti dahilinde PayCrypt banka hesaplarına TL havale yapabilirsiniz.',
  TransferPossible: 'Havale yapılabilir',
  Days: 'gün',
  Hours: 'saat',
  Next: 'İleri',
  OnWeekdays: 'haftaiçi',
  ReceiveSMS: 'Banka bilgilerini SMS ile alın',
  AddBankConfirmed: 'Banka Ekleme Talebiniz Onaylandı!',
  BankAccountFromWhichDeposit: 'Para yatıracağınız banka hesabı',
  SelectBankAccount: 'Bir banka hesabı seçin',
  
  SelectBankError:"Lütfen bir banka seçin",
  DefineNewBankAccount: 'Yeni bir banka hesabı tanımlayın',
  AmountToDeposit: 'Yatırmak istediğiniz tutar',
  TransactionFee: 'İşlem ücreti',
  Included: 'dahil',
  DepositAmount: 'Depozito miktarı',
  Cryptocurrency:"Crypto Currency",
  СheckWithBankTransfer: 'Banka havalesi ile kontrol edin',
  SeeFullBalance: 'Bakiyenizin tamamını göremiyorsanız,',
  OpenOrders: 'Açık emirler.',
  Remaining24hourWithdrawalLimit: 'Kalan 24 saatlik çekim limitiniz',
  Remaining30dayWithdrawalLimit: 'Kalan 30 günlük çekim limitiniz',
  BankTransferValidForWithdrawals: 'Banka havalesi yalnızca çekim işlemleri için geçerlidir.',
  OrMore: 'yada daha fazla',
  IBANInfromation: "PayCrypt'e Türk Lirası yatırdığınız banka hesabınızın IBAN bilgileri otomatik olarak eklenecektir.",
  SubmitPullRequest: 'Çekim talebi gönderin',
  BankAccount: 'Banka hesabı',
  MyBankAccount: 'Banka hesabım',
  AddNew: 'Yeni ekle...',
  BankAccountsRegistered:'Hesabınıza kayıtlı banka hesapları',
  DontHaveLinkedBankAccounts:"Henüz bağlı banka hesabınız yok.",
  AddNewBankAccount: 'Yeni Banka Hesabı Ekle',
  SelectBank: 'Banka Seçin',
  AccountNumber: 'Hesap numarası',
  EnterAccountNumber:'Lütfen hesap numarasını girin',
  AccountHolderName: "Hesap Sahibinin Adı",
  Ibannumber:"IBAN Numara",
  Swiftnumber:"SWIFT Numara",
  Ifscnumber:"IFSC Numara",
  EnterAccountHolderName: "Lütfen hesap sahibinin adını girin",
  EnterIBAN:"Lütfen IBAN numarasını giriniz",
  EnterSWIFT:"Lütfen SWIFT numarasını giriniz",
  EnterIFSC : "Lütfen IFSC numarasını giriniz",
  Number: 'Numara',
  Submit: 'Gönder',
  BankTransferLine1: "Kalan 24 saatlik çekim limitiniz",
  BankTransferLine2: "Kalan 30 günlük çekim limitiniz",
  BankTransferLine3: "Banka havalesi yalnızca çekim işlemleri için geçerlidir.",
  BankTransferLine4: "PayCrypt'e Türk Lirası yatırdığınız banka hesabınızın IBAN bilgileri otomatik olarak TL çekebileceğiniz bankalar listesine eklenecektir." ,
  BankTransferLine4_1:"Para yatırdığınız banka hesabınızın IBAN bilgileri",
  BankTransferLine4_2: "BtcTurk'te para çekebileceğiniz bankalar listesine otomatik olarak eklenecektir.",
  SelectNewBankAccount: "Yeni banka hesabı seçin",
  ExchangedCoins: "Değiştirilen Paralar",
  ExchangedAmount: "Değiştirilen Tutar",
  Link: "Bağlantı",
  AmountPaid:"Ödenen miktar",
  AccountNumber: " Hesap Numarasını Girin ",
  AccountHolderNumber:"Hesap Sahibi Numarasını Girin",
  IBANNumber:"IBAN Numarasını Girin",
  SWIFTNumber:"SWIFT Numarasını Girin",
  IFSCNumber:" IFSC Numarasını Girin ",
  AccountCreatedSuccessfully:"Hesap başarıyla oluşturuldu",
  MonthlyDepositlimit: "Kalan 30 günlük para yatırma limitiniz",
  BankTransferValidForDeposit: "Banka havalesi sadece depozito için geçerlidir ",
  FiatWithdrawalRequest: "Fiat Para Çekme Talebi",
  Fiatwithdrawapplicationsent: "Fiat para çekme başvurusu gönderildi.",
  SelectCurrencyError:"Lütfen bir para birimi seçin",
  SelectBankError:"Lütfen bir banka seçin",
  SelectCurrencyError:"Lütfen bir para birimi seçin",
  SelectedBank: "Seçilen Banka",
  Surname: "Soyadı",
  TType:"İşlem Türü",
  Sender:"Gönderen",
  Receiver:"Ahize",
}
