import "@components/big-coin/BigCoinCardStyles.scss";
import { ReactComponent as DropdownIcon } from "@images/dropdown.svg";
import { Row, Col, Dropdown } from "react-bootstrap";
import { ResponsivePie } from "@nivo/pie";
import { ReactComponent as HideEye } from "@images/hideeye.svg";
import { ReactComponent as HideEye2 } from "@images/eye.svg";
import { ReactComponent as SetIcon } from "@images/settingic.svg";
import DropdownforCurrency from "@components/dropdownforcurrency/DropdownforCurrency";
import SmallButton from "@components/small-button/SmallButton";
import CoinListBalance from "@components/coin-list-balance/CoinListBalance";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCSymbol, getESymbol } from "src/utils/Currencies";
import BigNumber from "bignumber.js";
import { Spinner } from "react-bootstrap";
import CircularLoader from "@components/circular-loader/CircularLoader";
import Loader from "@components/loader/Loader";

function BigCoinCard({
  mywalletsinfo,
  bnbdata,
  ethdata,
  btcdata,
  busddata,
  trxdata,
  trycdata,
  setcoinItem,
  setExchangeModal,
  setShowDeposit,
  setwithdrawModal,
  plaformcurrencies,
  getplatformTokensinfo,
  displayedCurrencies,
  role,
  circularLoader,
  getAmount,
  sethideZero,
  hideZeroBalance,
  PreferredCurrency,
  storeEstimated,
  getFiatBal,
  myEstimatedBal,
  fiatrate,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  let [totalNew, setTotalNew] = useState();
  let [pieadata, setpiechat] = useState([]);
  let [colorsdata, setcolorsdata] = useState([]);

  useEffect(() => {
    getGraph();
  }, [ethdata]);

  useEffect(() => {
    setTotalNew(
      BigNumber(myEstimatedBal)
        .multipliedBy(
          (fiatrate &&
            fiatrate[getESymbol(PreferredCurrency)] &&
            fiatrate[getESymbol(PreferredCurrency)].value) ||
            1
        )
        .toFixed(2, BigNumber.ROUND_FLOOR)
    );
    getGraph();
  }, []);

  useEffect(() => {
    setTotalNew(
      BigNumber(myEstimatedBal)
        .multipliedBy(
          (fiatrate &&
            fiatrate[getESymbol(PreferredCurrency)] &&
            fiatrate[getESymbol(PreferredCurrency)].value) ||
            1
        )
        .toFixed(2, BigNumber.ROUND_FLOOR)
    );
  }, [myEstimatedBal]);

  // useEffect(() => {
  //   // console.log('Total New -> ',totalNew);
  // },[totalNew])

  useEffect(() => {
    setTotalNew(
      BigNumber(myEstimatedBal)
        .multipliedBy(
          (fiatrate &&
            fiatrate[getESymbol(PreferredCurrency)] &&
            fiatrate[getESymbol(PreferredCurrency)].value) ||
            1
        )
        .toFixed(2, BigNumber.ROUND_FLOOR)
    );
  }, [PreferredCurrency]);

  let getGraph = () => {
    let pieadatas = [],
      colorsdatas = [],
      total = 0,
      mybal = "0",
      l = "";
    mywalletsinfo &&
      mywalletsinfo.map((items) => {
        mybal = 0;
        let bal = items && items.amount;
        let dec = items && items.coin && items.coin.decimals;
        let val = bal / 10 ** dec;
        if (items.coin.tokenName == "Ethereum") {
          mybal = Number(val * Number(ethdata && ethdata.p));
        }
        if (items.coin.tokenName == "Bitcoin") {
          mybal = Number(val * Number(btcdata && btcdata.p));
        }
        if (items.coin.tokenName == "BSC") {
          mybal = Number(val * Number(bnbdata && bnbdata.p));
        }
        if (items.coin.tokenName == "CyborCoin") {
          mybal = Number(val * Number(0.15));
        }
        if (items.coin.tokenName == "Tron") {
          mybal = Number(val * Number(trxdata && trxdata.p));
        }
        if (items.coin.tokenName == "Tether") {
          mybal = Number(val * Number(busddata && busddata.p));
        }
        if (items.coin.tokenName == "TRYCoin") {
          if (trycdata && trycdata.p != undefined)
            mybal = Number(
              val * BigNumber(1).dividedBy(trycdata && trycdata.p)
            );
        }
        colorsdatas.push(items.coin.logoBgColor);
        l = l + " ";
        pieadatas.push({
          id: `${items.coin.tokenName}${l}`,
          label: items.coin.tokenName,
          value: mybal,
          color: items.coin.logoBgColor,
          labeltype: `${items.coin.tokenName}
          
          
          ${
            items.coin.type == "ETH"
              ? "ERC20"
              : items.coin.type == "BSC"
              ? "BEP20"
              : items.coin.type == "TRON"
              ? "TRC20"
              : items.coin.type
          }`,
        });
        total += mybal;
      });
    plaformcurrencies &&
      plaformcurrencies.map((items) => {
        mybal = 0;
        let bal = getFiatBal(items.id);
        colorsdatas.push(items.logoBgColor);

        if (items.exchangeSymbol == "USD") {
          if (PreferredCurrency == "USD")
            mybal = BigNumber(bal)
              .multipliedBy(
                (fiatrate &&
                  fiatrate[`${PreferredCurrency}`] &&
                  fiatrate[`${PreferredCurrency}`].value) ||
                  1
              )
              .toFixed(4, BigNumber.ROUND_FLOOR);
        }
        if (items.exchangeSymbol == "TRY") {
          mybal = BigNumber(bal)
            .multipliedBy(
              (fiatrate &&
                fiatrate[`${items.exchangeSymbol}`] &&
                fiatrate[`${items.exchangeSymbol}`].value) ||
                1
            )
            .toFixed(4, BigNumber.ROUND_FLOOR);
        }
        if (items.exchangeSymbol == "GBP") {
          mybal = BigNumber(bal)
            .multipliedBy(
              (fiatrate &&
                fiatrate[`${items.exchangeSymbol}`] &&
                fiatrate[`${items.exchangeSymbol}`].value) ||
                1
            )
            .toFixed(4, BigNumber.ROUND_FLOOR);
        }
        if (items.exchangeSymbol == "EUR") {
          mybal = BigNumber(bal)
            .multipliedBy(
              (fiatrate &&
                fiatrate[`${items.exchangeSymbol}`] &&
                fiatrate[`${items.exchangeSymbol}`].value) ||
                1
            )
            .toFixed(4, BigNumber.ROUND_FLOOR);
        }
        pieadatas.push({
          id: items.exchangeSymbol,
          label: items.currencyName,
          value: mybal,
          color: items.logoBgColor,
          labeltype: items.currencyName,
        });
        total += Number(mybal);
      });

    setcolorsdata(() => [...colorsdatas]);
    setpiechat(() => [...pieadatas]);
    storeEstimated(total);
  };
  return (
    <div className={"BigCoinCard"}>
      <Row className="maindashrow">
        <Col lg={7}>
          <div className="text-start formobflex">
            <span className="balance">{t("EstimatedBalances")}</span>
            <span
              className="hideTag"
              onClick={() => sethideZero(!hideZeroBalance)}
            >
              {hideZeroBalance ? (
                <HideEye2 className="hideeye" />
              ) : (
                <HideEye className="hideeye" />
              )}
              {hideZeroBalance ? t("showzerobalance") : t("Hidezerobalance")}
            </span>
          </div>
        </Col>
        <Col lg={5} className="actions">
          <div className={"options optionposition"}>
            <div className="mainbuttonswrapper">
              <SmallButton
                type="deposit"
                onclick={() => {
                  let defaultcoin =
                    mywalletsinfo &&
                    mywalletsinfo.filter(
                      (item) => item.tORcName == "CyborCoin"
                    );
                  setcoinItem(
                    defaultcoin && defaultcoin[0] && defaultcoin[0].coin
                  );
                  setShowDeposit();
                }}
              />
              <SmallButton
                type="withdraw"
                onclick={() => {
                  setcoinItem(
                    mywalletsinfo && mywalletsinfo[0] && mywalletsinfo[0].coin
                  );
                  setwithdrawModal();
                }}
              />
              <SmallButton
                type="exhange"
                onclick={() => {
                  setExchangeModal();
                }}
              />
            </div>
            <div className="displayedcurrencies d-none d-lg-flex">
              <Dropdown className="downbtn" autoClose="outside" align="end">
                <Dropdown.Toggle className="dropdown-toggle::after">
                  <SetIcon className="droparrowss rightspace" />
                  <DropdownIcon className="droparrow" />
                </Dropdown.Toggle>
                <div className="sidebarmenu">
                  <DropdownforCurrency page="dashboard" />
                </div>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
      {/* <hr /> */}
      <Row>
        <Col lg={12} xl={4} className="ps-0 pe-0">
          <div className="piechartsection">
            {circularLoader && (
              <div className="loaderWrap">
                <Spinner animation="grow" className="spincolor" />
              </div>
            )}

            <ResponsivePie
              data={totalNew==0?[]: pieadata}
              margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
              innerRadius={0.76}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#fff"
              arcLinkLabelsThickness={0}
              enableArcLabels={false}
              isInteractive={false}
              enableArcLinkLabels={false}
              // tooltip={function(e){var t=e.datum;return a.createElement(i,{style:{color:t.color}},a.createElement(s,null,"id"),a.createElement(u,null,t.id),a.createElement(s,null,"value"),a.createElement(u,null,t.value),a.createElement(s,null,"formattedValue"),a.createElement(u,null,t.formattedValue),a.createElement(s,null,"color"),a.createElement(u,null,t.color))}}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              colors={colorsdata}
              fill={[
                {
                  match: {
                    id: "ETH",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "CYBOR",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "BTC",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "USDT",
                  },
                  id: "dots",
                },
              ]}
            ></ResponsivePie>
            {circularLoader != true && (
              <div className="mybalinpie">
                <span className="pietxt">
                  {" "}
                  {getCSymbol(PreferredCurrency)}{" "}
                  {/* {BigNumber(myEstimatedBal)
                    .multipliedBy(
                      (fiatrate && fiatrate[getESymbol(PreferredCurrency)]) || 1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)} */}
                  {totalNew}
                </span>
                <span className="pietxt1">{t("TotalBalances")}</span>
              </div>
            )}
          </div>
          <div>
            <div className="legendarea">
              {pieadata &&
                pieadata.map((o) => (
                  <div className="legend">
                    <span
                      className="legendcolor"
                      style={{ "background-color": o.color }}
                    ></span>
                    <span className="legendname">{o.labeltype}</span>
                  </div>
                ))}
            </div>
          </div>
        </Col>

        <Col lg={12} xl={8} className="dashCoinListColMain">
          <Row>
            {getplatformTokensinfo &&
              getplatformTokensinfo.map((items) => {
                // console.log('item from dashboard' , items);
                if (
                  _.findIndex(displayedCurrencies.crpto, function (chr) {
                    return chr == items.id;
                  }) !== -1
                ) {
                  if (hideZeroBalance && getAmount(items.id) != 0) {
                    return (
                      <Col lg={6} className="coinListCol d-none d-lg-block ">
                        <div className="coinListWrapperD">
                          <CoinListBalance
                            logo={items.nonColouredLogo}
                            name={items.tokenName}
                            balance={getAmount(items.id)}
                            decimals={items.decimals}
                            symbol={items.exchangeSymbol}
                            bgcolor={items.logoBgColor}
                            coinkey={items.id}
                            tag={items.type}
                            tableno={"first"}
                            onClick={() => {
                              history.push(`/mybalances/${items.id}`);
                            }}
                            fromdashboard={true}
                          />
                        </div>
                      </Col>
                    );
                  }
                  if (!hideZeroBalance) {
                    return (
                      <Col lg={6} className="coinListCol d-none d-lg-block ">
                        <div className="coinListWrapperD">
                          <CoinListBalance
                            logo={items.nonColouredLogo}
                            name={items.tokenName}
                            balance={getAmount(items.id)}
                            decimals={items.decimals}
                            symbol={items.exchangeSymbol}
                            bgcolor={items.logoBgColor}
                            coinkey={items.id}
                            tag={items.type}
                            tableno={"first"}
                            onClick={() => {
                              history.push(`/mybalances/${items.id}`);
                            }}
                            fromdashboard={true}
                          />
                        </div>
                      </Col>
                    );
                  }
                }
              })}
            {plaformcurrencies &&
              plaformcurrencies.map((items) => {
                if (
                  _.findIndex(displayedCurrencies.fiat, function (chr) {
                    return chr == items.id;
                  }) !== -1
                ) {
                  if (hideZeroBalance && getFiatBal(items.id) != 0) {
                    return (
                      <Col lg={6} className="coinListCol d-none d-lg-block ">
                        <div className="coinListWrapperD">
                          <CoinListBalance
                            logo={items.nonColouredLogo}
                            name={items.currencyName}
                            balance={getFiatBal(items.id)}
                            decimals={items.decimals}
                            symbol={items.exchangeSymbol}
                            bgcolor={items.logoBgColor}
                            coinkey={items.id}
                            tag={items.type}
                            tableno={"second"}
                            onClick={() => {
                              history.push(`/mybalances/F${items.id}`);
                            }}
                            fromdashboard={true}
                          />
                        </div>
                      </Col>
                    );
                  }
                  if (!hideZeroBalance) {
                    return (
                      <Col lg={6} className="coinListCol d-none d-lg-block ">
                        <div className="coinListWrapperD">
                          <CoinListBalance
                            logo={items.nonColouredLogo}
                            name={items.currencyName}
                            balance={getFiatBal(items.id)}
                            decimals={items.decimals}
                            symbol={items.exchangeSymbol}
                            bgcolor={items.logoBgColor}
                            coinkey={items.id}
                            tag={items.type}
                            tableno={"second"}
                            onClick={() => {
                              history.push(`/mybalances/F${items.id}`);
                            }}
                            fromdashboard={true}
                          />
                        </div>
                      </Col>
                    );
                  }
                }
              })}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default BigCoinCard;
