import CoinTable from "@components/coin-table/CoinTable.js";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import "@components/navbar/NavBarStyles.scss";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "@components/main-dashboard/MainDashboardStyles.scss";
import BigNumber from "bignumber.js";
const Binance = require("binance-api-node").default;
import moment from "moment";
import { getESymbol } from "src/utils/Currencies";

const CoinTableWrapper = (props) => {

    let {
        getplatformTokensinfo,
        myaccountandbalance,
        withdramodal,
        setwithdrawModal,
        coinItem,
        setcoinItem,
        exchangemodal,
        setExchangeModal,
        getFiatBalanceinfo,
        setFiatDepositModal,
        fiatDepositModal,
        setDepositModal,
        depositModal,
        role,
        dashboardDisplayCurrencies,
        PreferredCurrency,
        onClick,
        selectedCoin,
        searchvalue,
        platformBanks,
        hideZeroBalance,
        gpt
      } = props;
      const [bnbdata, setbnbdata] = useState("");
      const [btcdata, setbtcdata] = useState("");
      const [ethdata, setethdata] = useState("");
      const [bnbpdata, setbnbpdata] = useState("");
      const [btcpdata, setbtcpdata] = useState("");
      const [ethpdata, setethpdata] = useState("");
      const [busddata, setbusddata] = useState("");
      const [busdpdata, setbusdpdata] = useState("");
      const [trxdata, settrxdata] = useState("");
      const [trxpdata, settrxpdata] = useState("");
      const [trycdata, settrycdata] = useState("");
      const [trycpdata, settrycpdata] = useState("");
      let [tableno, settableno] = useState("first");
      const [market, setmarket] = useState([]);
      const [chartsdata, setcharts] = useState([]);
      const [min, setMin] = useState([]);
      const [max, setMax] = useState([]);
      const [filteredTokens, setFilteredTokens] = useState([]);

    const client = Binance();
  useEffect(() => {
    
    filterTokens()
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    let prefSymbol = getESymbol(PreferredCurrency).toLocaleLowerCase()
     const msg = {
      method: "SUBSCRIBE",
      params: [
        `eth${prefSymbol}@aggTrade`,
        `btc${prefSymbol}@aggTrade`,
        `bnb${prefSymbol}@aggTrade`,
        `busd${prefSymbol}@aggTrade`,
        `eth${prefSymbol}@ticker`,
        `btc${prefSymbol}@ticker`,
        `bnb${prefSymbol}@ticker`,
        `busd${prefSymbol}@ticker`,
        `trx${prefSymbol}@ticker`,
        `trx${prefSymbol}@aggTrade`,
        `${prefSymbol}try@ticker`,
        `${prefSymbol}try@aggTrade`,
      ],
      id: 1,
    };

    const msg2 = {
      method: `UNSUBSCRIBE`,
      params: [
        `eth${prefSymbol}@aggTrade`,
        `btc${prefSymbol}@aggTrade`,
        `bnb${prefSymbol}@aggTrade`,
        `busd${prefSymbol}@aggTrade`,
        `trx${prefSymbol}@aggTrade`,
        `eth${prefSymbol}@ticker`,
        `btc${prefSymbol}@ticker`,
        `bnb${prefSymbol}@ticker`,
        `busd${prefSymbol}@ticker`,
        `trx${prefSymbol}@ticker`,
        `${prefSymbol}try@ticker`,
        `${prefSymbol}try@aggTrade`,
      ],
      id: 1,
    };
    ws.onopen = () => {
      ws.send(JSON.stringify(msg));
      setTimeout(() => ws.send(JSON.stringify(msg2)), 2000);
    };
    let cSymbol = getESymbol(PreferredCurrency)
    ws.onmessage = (e) => {
      let newdata = JSON.parse(e.data);
      if (!_.isUndefined(e.data)) {
        if (newdata.e == "24hrTicker") {
          if (newdata.s === `BNB${cSymbol}`) setbnbpdata(newdata);
          if (newdata.s === `ETH${cSymbol}`) setethpdata(newdata);
          if (newdata.s === `BTC${cSymbol}`) setbtcpdata(newdata);
          if (newdata.s === `BUSD${cSymbol}`) setbusdpdata(newdata);
          if (newdata.s === `TRX${cSymbol}`) settrxpdata(newdata);
          if (newdata.s === `${cSymbol}TRY`) settrycpdata(newdata);
        } else {
          if (newdata.s === `BNB${cSymbol}`) setbnbdata(newdata);
          if (newdata.s === `ETH${cSymbol}`) setethdata(newdata);
          if (newdata.s === `BTC${cSymbol}`) setbtcdata(newdata);
          if (newdata.s === `BUSD${cSymbol}`) setbusddata(newdata);
          if (newdata.s === `TRX${cSymbol}`) settrxdata(newdata);
          if (newdata.s === `${cSymbol}TRY`) settrycdata(newdata);
        }
      }
    };
  }, []);

  useEffect(() => {
    filterTokens();
  }, [dashboardDisplayCurrencies.crpto,searchvalue]);

  const [Showaddcointmodal, setShowaddcoinmodal] = useState(false);
  const [value, setvalue] = useState("");

  const filterTokens = () => {
    if (dashboardDisplayCurrencies.length !== 0) {
      let filteredTkn = getplatformTokensinfo.map((item) =>
        _.findIndex(
          [...dashboardDisplayCurrencies.crpto].sort(),
          (chr) => chr === item.id
        )
      );
      if (filteredTkn.includes(-1)) {
        filteredTkn = filteredTkn.map((itm) =>
          itm === 0 || itm === -1 ? itm : itm + 1
        );
      }

      let filTKN = [];
      let dataForChecking = [];

      for (let i of [...dashboardDisplayCurrencies.crpto].sort()) {
        let data = getplatformTokensinfo.filter((itm) => itm.id === i)[0];
        let dataWithItem = {
          ...data,
          itemIndex: getplatformTokensinfo.indexOf(data),
        };
        dataForChecking.push(data);
        if(dataWithItem.tokenName.toLowerCase().includes(searchvalue.toLowerCase())&&searchvalue!='')
        filTKN.push(dataWithItem);
        if(searchvalue=="")
        filTKN.push(dataWithItem);
      }
      setFilteredTokens(filTKN);
      dataForChecking &&
        dataForChecking.map((i) => {
          if (i && i.exchangeSymbol !== "CYBOR") {
            getMarketdata(
              i.exchangeSymbol,
              getESymbol(PreferredCurrency),
              getplatformTokensinfo.indexOf(i)
            );
          }
        });
    }
  };

  const getMarketdata = (exchangeSymbol, Currency, index) => {
    try {
      let CurrencyT = Currency  
      if (exchangeSymbol == "USDT") {
        client
          .dailyStats({
            symbol: `BUSD${CurrencyT}`,
          })
          .then((time) => {
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `BUSD${CurrencyT}`,
            interval: "1d",
            limit: 20,
            // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
            // endTime: moment(new Date()).valueOf(),
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
          })
          .catch((er) => {
          });
      } else if (exchangeSymbol == "TRYC") {
        client
          .dailyStats({
            symbol: `${CurrencyT}TRY`,
          })
          .then((time) => {
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `${CurrencyT}TRY`,
            interval: "1d",
            limit: 20,
            // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
            // endTime: moment(new Date()).valueOf(),
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
          })
          .catch((er) => {
          });
      } else {
        client
          .dailyStats({
            symbol: `${exchangeSymbol}${CurrencyT}`,
          })
          .then((time) => {
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `${exchangeSymbol}${CurrencyT}`,
            interval: "1d",
            limit: 20,
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
          })
          .catch((er) => {});
      }
    } catch (err) {}
  };
  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  const getLockBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].lockBalance", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", " ");

    return address;
  };

  return (
    <CoinTable
        getplatformTokensinfo={getplatformTokensinfo}
        gpt={gpt}
        filteredTokens={filteredTokens}
        getBal={getBal}
        getAdd={getAdd}
        bnbdata={bnbdata}
        bnbpdata={bnbpdata}
        ethdata={ethdata}
        getLockBal={getLockBal}
        ethpdata={ethpdata}
        btcdata={btcdata}
        btcpdata={btcpdata}
        trxdata={trxdata}
        trxpdata={trxpdata}
        busddata={busddata}
        withdramodal={withdramodal}
        setwithdrawModal={(e) => setwithdrawModal(e)}
        busdpdata={busdpdata}
        setcoinItem={setcoinItem}
        coinItem={coinItem}
        exchangemodal={exchangemodal}
        depositModal={depositModal}
        setDepositModal={setDepositModal}
        setExchangeModal={(e) => setExchangeModal(e)}
        searchvalue={searchvalue}
        role={role}
        max={max}
        min={min}
        chartData={chartsdata}
        percentChanges={market}
        activeSelectedCoin={selectedCoin && selectedCoin.coinId}
        onClick={onClick}
        platformBanks={platformBanks}
        fiatDepositModal={fiatDepositModal}
        setFiatDepositModal={(e)=>setFiatDepositModal(e)}
        trycpdata={trycpdata}
        trycdata={trycdata}
        PreferredCurrency={PreferredCurrency}
        hideZeroBalance={hideZeroBalance}
    />
  )
};

const mapStateToProps = ({ wallet = {}, app = {}, user = {}, fiat = {} }) => {
    const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
    const withdramodal = _.get(app, "withdramodal", false);
    const exchangemodal = _.get(app, "exchangemodal", false);
    const myaccountandbalance = _.get(
      wallet,
      "myaccountandbalancebywalletidinfo",
      []
    );
    const fiatDepositModal = _.get(app, "FiatDepositModal", false);
    const role = _.get(user, "userInfo.role", "");
    const depositModal = _.get(app, "DepositModal", false);
  
    const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
    const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);
  
    const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
    const displayedCurrencies = _.get(user, "displayedCurrencies", []);
    const dashboardDisplayCurrencies = _.get(
      user,
      "dashboardDisplayCurrencies",
      []
    );
    const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");
    const platformBanks = _.get(fiat, "platformBanks", []);
  
    return {
      mywalletsinfo,
      myaccountandbalance,
      getplatformTokensinfo,
      withdramodal,
      exchangemodal,
      plaformcurrencies,
      fiatDepositModal,
      getFiatBalanceinfo,
      depositModal,
      displayedCurrencies,
      role,
      dashboardDisplayCurrencies,
      PreferredCurrency,
      platformBanks
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    setwithdrawModal: (data) =>
      dispatch({ type: "withdramodalcalled", payload: data }),
    setExchangeModal: (data) =>
      dispatch({ type: "setExchangeModalcalled", payload: data }),
    setFiatDepositModal: (data) =>
      dispatch({ type: "FiatDepositModalCalled", payload: data }),
    setDepositModal: (data) =>
      dispatch({ type: "DepositModalCalled", payload: data }),
      gpt: (data) =>
      dispatch({ type: "getPlatformTokensCalled", payload: data })
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(CoinTableWrapper);

