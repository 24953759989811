export default {
  Payment: "Платеж",
  Merchant: "Отделение",
  Payer: "Плательщик",
  AmountToPay: "Сумма к оплате",
  InsufficientBalance: "Недостаточный баланс",
  PaymentSuccessfull: "Платеж выполнен успешно!",
  PaymentLinkNotValid: "Ссылка для оплаты больше не действительна",
  PaymentLinkDetails: "Детали платежной ссылки",
  Close: "Закрыть",
  Total: "Всего",
  Pending: "Ожидает",
  Paid: "Оплачено",
  Cancelled: "Отменено",
};
