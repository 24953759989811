import CustomButton from "@components/custom-button/CustomButton";
import NavBar from "@components/navbar/NavBar";
import moment from "moment";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";
import { connect } from "react-redux";
import "@features/exchange-history/ExchangeHistoryStyles.scss";
import Table from "react-bootstrap/esm/Table";
import BigNumber from "bignumber.js";
import DetailedExchangePopup from "@components/DetailedPopupForExchange/DetailedExchangePopup";
import { ReactComponent as SquareIcon } from "@images/tcheck.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import deposit from "@images/deposit.svg";
import newexchange from "@images/newexchange.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import Filter from "@components/filter/Filter.js";
import CoinList from "@components/coin-list/CoinList";


import { ReactComponent as PrinterIcon } from "@images/printer.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "@components/footer-common/FooterCommon";
import { Row, Col, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Loader from '@components/loader/Loader';
import { useTranslation } from "react-i18next";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import ReactToPrint  ,{useReactToPrint} from "react-to-print";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import "jspdf-autotable";
import { ClearSansBase64 } from 'src/custom-fonts-base64/index'

function ExchangeHistory(props) {
  const {
    ExhangeHistoryinfo,
    getExchange,
    exchangehistoryCount,
    getExchangeHistoryCount,
    myusertransactionloading,
    thememode,
    hideLoaderOnRefresh
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [isFilterSet, setIsFilterSet] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let [show, setshow] = useState(false);
  let [Idata, setIdata] = useState({});
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [orderBy,setOrderBy] = useState('')
  const [sortBy,setSortBy] = useState('')

  const [hideSpec,setHideSpec] = useState(true)
  const [compRef,setCompRef] = useState()
  const [language , setLanguage] = useState('en');
  const componentRef = useRef()
  useEffect(() => {
    setCompRef(componentRef)
  },[])
  useEffect(() => {
    if(hideSpec === false){
      setCompRef(componentRef)
      handlePrint();
      setHideSpec(true);

    }
  },[hideSpec])
 

  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });


  useEffect(() => {
    getExchange({ skip: pages, limit });
    getExchangeHistoryCount();
    
  //  filterExchangeInfoForExcelExport()
   
  }, []);
  useEffect(()=>{
    // console.log('Exchange history info' , ExhangeHistoryinfo)
  } , [])

  useEffect(() => {
    setLanguage(localStorage.lang);
    filterExchangeInfoForExcelExport();
  }, [localStorage.lang]);

  useEffect(()=>{ filterExchangeInfoForExcelExport() } , [ExhangeHistoryinfo , exchangehistoryCount])

  const filterExchangeInfoForExcelExport = () => {
    let filtered = [];
    let headerList = [];
    if (ExhangeHistoryinfo && ExhangeHistoryinfo.length !== 0) {
      for (let i of ExhangeHistoryinfo) {
        let newOBJ = {
          "ExchangeID": i.id,
           "Date":
            i.createdOn !== undefined
              ? moment(i.createdOn).local().format("MM/DD/YYYY hh:mm A")
              : "",
          // 'From':
          // i.fromCoin !== undefined && i.fromCoin !== null && i.fromCoin.tokenName != undefined
          // ? fromCoin.tokenName
          // :
          // i.fromCurrency !== undefined && i.fromCurrency !== null && i.fromCurrency.currencyName != undefined ?
          // i.fromCurrency.currencyName :
          // "",
          // 'To':
          // i.toCoin !== undefined && i.toCoin !== null && i.toCoin.tokenName != undefined
          // ? i.toCoin.tokenName
          // :
          // i.toCurrency !== undefined && i.toCurrency !== null && i.toCurrency.currencyName != undefined ?
          // i.toCurrency.currencyName : 
          // "",
          "Amount": `${Number(
            i && i.fromCoin && i.fromCoin.decimals
              ? i.amount / 10 ** i.fromCoin.decimals
              : i.amount
          ).toFixed(5)} ${i.fromCoinSymbol}`,
          'Status': t(i.status),
          "Received": `${Number(
            i && i.toCoin && i.toCoin.decimals
              ? i.total / 10 ** i.toCoin.decimals
              : i.total
          ).toFixed(5)} ${i.toCoinSymbol}`,
        };

        filtered.push(newOBJ);
      }

      for (let i of Object.keys(filtered[0])) {
         let newOBJ = {
          title: t(i),
          dataIndex: i,
        };
        headerList.push(newOBJ);
      }

      let pack = {
        data: filtered,
        head: headerList,
      };

      setExcelHeader(pack.head);
      setExcelData(pack.data);

      return pack;
    }
    return filtered;
  };

  const generatePdf = () => {
    var doc = new jsPDF();
    doc.addFileToVFS("ClearSans-Regular.ttf",ClearSansBase64)
    doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
    doc.setFont("ClearSans","Regular");
    var col = [
      `${t("ExchangeID")}`,
      `${t("Date")}`,
      `${t("From")}`,
      `${t("To")}`,
      `${t("Amount")}`,
      `${t("Status")}`,
      `${t("Received")}`,
    ];
 
    var rows = [];

    if ( ExhangeHistoryinfo && ExhangeHistoryinfo.length !== 0) {
      ExhangeHistoryinfo.forEach((element) => {
        var temp = [
          element.id,
          element.createdOn,
          element.fromCoin !== undefined && element.fromCoin !== null && element.fromCoin.tokenName != undefined
          ? element.fromCoin.tokenName
          :
          element.fromCurrency !== undefined && element.fromCurrency !== null && element.fromCurrency.currencyName != undefined ?
          element.fromCurrency.currencyName :
          "",
          element.toCoin !== undefined && element.toCoin !== null && element.toCoin.tokenName != undefined
      ? element.toCoin.tokenName
      :
      element.toCurrency !== undefined && element.toCurrency !== null && element.toCurrency.currencyName != undefined ?
      element.toCurrency.currencyName : 
      "",
          element.amount,
          t(element.status),
          element.total,
        ];

        rows.push(temp);
      });
    }
    doc.autoTable(col, rows, { startY: 5,
      styles: {
        font: 'ClearSans',
        fontSize: 8
      },
    });

    doc.save(`${t("ExchangeHistory")}.pdf`);
  };

  const { t } = useTranslation();

  return (
    <ScreenLayout hideLoaderOnStart={true}  loaderOnRefresh={hideLoaderOnRefresh} >
    <DetailedExchangePopup setShowModal={setshow} show1={show} data={Idata} />
      <div id="exchangehistory">
        <Container className="containerex">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="trasactiontitle">
                  <span>{t("ExchangeHistory")}</span>
                </div>
                <div className="filtersection ">
                  <Filter
                    onApplyDate={(picker) => {
                      setIsFilterSet(true);
                      getExchange({
                        skip: 0,
                        limit,
                        startDate: picker.startDate,
                        endDate: picker.endDate,
                        orderBy: orderBy,
                        sort: sortBy 
                      });
                      getExchangeHistoryCount({
                        startDate: picker.startDate,
                        endDate: picker.endDate,
                        orderBy: orderBy,
                        sort: sortBy 
                      });
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={(e) => setStartDate(e)}
                    setEndDate={(e) => setEndDate(e)}
                    oncancel={() => {
                      setStartDate({});
                      setEndDate({});
                      setIsFilterSet(false);
                      getExchange({ skip: pages, limit });
                      getExchangeHistoryCount();
                    }}
                  />
                </div>
              </div>
              <div className="rightsection">
                <div className="thethreebutton">
                  <div className="buttoncontainersm">
                    <ReactToPrint
                      trigger={() => (
                        <CustomButton
                          type="submit"
                          name={t("Print")}
                          testvalue={
                            <PrinterIcon className="iconcolor mleft" />
                          }
                          text={true}
                          image={true}
                          //   onClick={() => {
                          //     setcoinItem(items);
                          //     setShowDeposit(true)
                          //   }}
                        />
                      )}
                      content={() => componentRef.current}
                      onBeforeGetContent={() => {
                        orientation :'landscape'
                        setHideSpec(false)
                      }}
                    />
                  </div>

                  <div className="buttoncontainersm">
                    <CustomButton
                      type="submit"
                      name="PDF"
                      testvalue={<PdfIcon className="mleft" />}
                      text={true}
                      image={true}
                      onClick={() => {
                        generatePdf();
                      }}
                    />
                  </div>

                  <div className="buttoncontainersm mr-0">
                    <ExportSheet
                      header={excelHeader}
                      fileName={`download`}
                      dataSource={excelData}
                      xlsx={XLSX}
                    >
                      <CustomButton
                        type="submit"
                        name={t("Excel")}
                        testvalue={<ExcelIcon className="mleft" />}
                        text={true}
                        image={true}
                        onClick={() => {
                          filterExchangeInfoForExcelExport()
                        }}
                        //   onClick={() => {
                        //     setcoinItem(items);
                        //     setShowDeposit(true)
                        //   }}
                      />
                    </ExportSheet>
                  </div>
                </div>
              </div>

              <Table responsive="sm" className={`transfertbl ${thememode == "light" ? 'tableinlight' : 'tableindark'}`} borderless ref={componentRef}>
                {myusertransactionloading && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading"></th>
                    <th className="trtbleheading"><span className="heading">{t("ExchangeID")}</span>       <span className="sorticon">
                          <SortupIcon className="uparrow" onClick={() => {
                                setOrderBy('id')
                                setSortBy('ASC') 
                             if (isFilterSet) {
                              getExchange({
                                skip: pages * limit,
                                limit,
                                startDate,
                                endDate,
                                orderBy: 'id',
                                sort: 'ASC'
                              });
                            } else {
                              getExchange({ 
                                skip: pages * limit, 
                                limit,
                                orderBy: 'id',
                                sort: 'ASC' 
                              });
                            }
                          }}/>

                          <SortdownIcon className="downarrow" onClick={() => {
                              setOrderBy('id')
                              setSortBy('DESC') 
                           if (isFilterSet) {
                            getExchange({
                              skip: pages * limit,
                              limit,
                              startDate,
                              endDate,
                              orderBy: 'id',
                              sort: 'ASC'
                            });
                          } else {
                            getExchange({ 
                              skip: pages * limit, 
                              limit,
                              orderBy: 'id',
                              sort: 'DESC' 
                            });
                          }
                          }}/>
                        </span></th>
                    <th className="trtbleheading"><span className="heading">{t("Date")}</span>      <span className="sorticon">
                          <SortupIcon className="uparrow" onClick={() => {
                             setOrderBy('createdOn')
                             setSortBy('ASC') 
                              if (isFilterSet) {
                              getExchange({
                                skip: pages * limit,
                                limit,
                                startDate,
                                endDate,
                                orderBy: 'createdOn',
                                sort: 'ASC'
                              });
                            } else {
                              getExchange({ 
                                skip: pages * limit, 
                                limit,
                                orderBy: 'createdOn',
                                sort: 'ASC' 
                              });
                            }
                          }}/>

                          <SortdownIcon className="downarrow" onClick={() => {
                            setOrderBy('createdOn')
                            setSortBy('DESC') 
                            if (isFilterSet) {
                              getExchange({
                                skip: pages * limit,
                                limit,
                                startDate,
                                endDate,
                                orderBy: 'createdOn',
                                sort: 'DESC'
                              });
                            } else {
                              getExchange({ 
                                skip: pages * limit, 
                                limit,
                                orderBy: 'createdOn',
                                sort: 'DESC' 
                              });
                            }
                          }}/>
                        </span></th>
                    <th className="trtbleheading"><span className="heading">{t("From")}</span><span className="sorticon">
                          <SortupIcon className="uparrow" onClick={() => {
                            setOrderBy('fromCoinSymbol')
                            setSortBy('ASC') 
                              if (isFilterSet) {
                              getExchange({
                                skip: pages * limit,
                                limit,
                                startDate,
                                endDate,
                                orderBy: 'fromCoinSymbol',
                                sort: 'ASC'
                              });
                            } else {
                              getExchange({ 
                                skip: pages * limit, 
                                limit,
                                orderBy: 'fromCoinSymbol',
                                sort: 'ASC' 
                              });
                            }
                          }}/>

                          <SortdownIcon className="downarrow" onClick={() => {
                              setOrderBy('fromCoinSymbol')
                              setSortBy('DESC') 
                                if (isFilterSet) {
                                getExchange({
                                  skip: pages * limit,
                                  limit,
                                  startDate,
                                  endDate,
                                  orderBy: 'fromCoinSymbol',
                                  sort: 'DESC'
                                });
                              } else {
                                getExchange({ 
                                  skip: pages * limit, 
                                  limit,
                                  orderBy: 'fromCoinSymbol',
                                  sort: 'DESC' 
                                });
                              }
                          }}/>
                        </span></th>
                    <th className="trtbleheading"><span className="heading">{t("To")}</span><span className="sorticon">
                          <SortupIcon className="uparrow" onClick={() => {
                          setOrderBy('toCoinSymbol')
                          setSortBy('ASC') 
                            if (isFilterSet) {
                            getExchange({
                              skip: pages * limit,
                              limit,
                              startDate,
                              endDate,
                              orderBy: 'toCoinSymbol',
                              sort: 'ASC'
                            });
                          } else {
                            getExchange({ 
                              skip: pages * limit, 
                              limit,
                              orderBy: 'toCoinSymbol',
                              sort: 'ASC' 
                            });
                          }
                          }}/>

                          <SortdownIcon className="downarrow" onClick={() => {
                            setOrderBy('toCoinSymbol')
                            setSortBy('DESC') 
                              if (isFilterSet) {
                              getExchange({
                                skip: pages * limit,
                                limit,
                                startDate,
                                endDate,
                                orderBy: 'toCoinSymbol',
                                sort: 'DESC'
                              });
                            } else {
                              getExchange({ 
                                skip: pages * limit, 
                                limit,
                                orderBy: 'toCoinSymbol',
                                sort: 'DESC' 
                              });
                            }
                          }}/>
                        </span></th>
                    <th className="trtbleheading"><span className="heading">{t("Amount")}</span></th>
                    <th className="trtbleheading"><span className="heading">{t("Status")}</span></th>
                     <th className="trtbleheading"><span className="heading">{t("Received")}</span></th>
                    <th className="trtbleheading"></th>
                  </tr>
                </thead>

                <tbody>
                  {ExhangeHistoryinfo &&
                    ExhangeHistoryinfo.map((item, index) => (
                      <tr className="maintdwrapper" key={`trans${index}`}>
                        <td>
                          {hideSpec &&
                          <div className="innerTd  id">
                            <img src={newexchange} className="imgstatus"/>
                          </div>
}
                        </td>
                        <td className="amt">
                          <div className="innerTd idtr firstinnertd">
                            <span className="id idtxt">#{item.id}</span>
                         {hideSpec &&
                            <p className="hidden-for-desktop date">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </p>
}
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd id datewidth">
                            {moment(item.createdOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </td>
                        {hideSpec ?<td className="amt hidden-for-mobile">
                          <div className="innerTd  id">
                            <CoinList
                              name={item.fromCoinSymbol}
                              logoBgColor={
                                item &&
                                item.fromCoin &&
                                item.fromCoin.logoBgColor||
                                item &&
                                item.fromCurrency &&
                                item.fromCurrency.logoBgColor
                              }
                              logo={
                                item &&
                                item.fromCoin &&
                                item.fromCoin.nonColouredLogo||
                                item &&
                                item.fromCurrency &&
                                item.fromCurrency.nonColouredLogo
                              }
                            />
                            {/* <span>USDT</span> */}
                          </div>
                        </td> :<td className="amt hidden-for-mobile">
                          <div className="innerTd  id">
                          <p>{item.fromCoinSymbol}</p>
                            {/* <span>USDT</span> */}
                          </div>
                        </td>}
                        {hideSpec ?<td className="amt hidden-for-mobile">
                          <div className="innerTd id">
                            <CoinList
                              name={item.toCoinSymbol}
                              logoBgColor={
                                item && item.toCoin && item.toCoin.logoBgColor||
                                item && item.toCurrency && item.toCurrency.logoBgColor
                              }
                              logo={
                                item &&
                                item.toCoin &&
                                item.toCoin.nonColouredLogo||
                                item && item.toCurrency && item.toCurrency.nonColouredLogo
                              }
                            />
                          </div>
                        </td> :<td className="amt hidden-for-mobile">
                          <div className="innerTd id">
                            <p>{item.toCoinSymbol}</p>
                          </div>
                        </td>}
                        

                        <td className="amount hidden-for-mobile">
                          <div className="innerTd id  hidden-for-mobile">
                            <div>
                              {Number(
                                item && item.fromCoin && item.fromCoin.decimals
                                  ? item.amount / 10 ** item.fromCoin.decimals
                                  : item.amount
                              ).toFixed(5)}

                              <span className="amttxt">
                                {" "}
                                {item.fromCoinSymbol}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="status completed hidden-for-mobile">
                          <div className="innerTd id">{t("completed")}</div>
                        </td>
                            {hideSpec ? <td className="amt  ">
                          <div className="innerTd idtr id">
                            <span className="amttxt">
                              {Number(
                                item && item.toCoin && item.toCoin.decimals
                                  ? item.total / 10 ** item.toCoin.decimals
                                  : item.total
                              ).toFixed(5)}
                              &nbsp;&nbsp;
                              {item.toCoinSymbol}
                            </span>
                            <p className="hidden-for-desktop">
                              <CoinList
                                name={item.fromCoinSymbol}
                                logoBgColor={
                                  item &&
                                  item.fromCoin &&
                                  item.fromCoin.logoBgColor||
                                  item &&
                                  item.fromCurrency &&
                                  item.fromCurrency.logoBgColor
                                }
                                logo={
                                  item &&
                                  item.fromCoin &&
                                  item.fromCoin.nonColouredLogo||
                                  item &&
                                  item.fromCurrency &&
                                  item.fromCurrency.nonColouredLogo
                                }
                              />
                            </p>
                          </div>
                        </td> : <td className="amt  ">
                          <div className="innerTd idtr id">
                            <span className="amttxt">
                              {Number(
                                item && item.toCoin && item.toCoin.decimals
                                  ? item.total / 10 ** item.toCoin.decimals
                                  : item.total
                              ).toFixed(5)}
                              &nbsp;&nbsp;
                              {item.toCoinSymbol}
                            </span>
                            <p className="hidden-for-desktop">
                              {item.fromCoinSymbol}
                            </p>
                          </div>
                        </td>}
                       

                        {/* need to check */}
                    {hideSpec  ?  <td className="completed ">
                            <div className="vertical innerTd rightspace">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="threedot"
                                  id="dropdown-basic"
                                >
                                  <VerticleIcon className="arrowclass hidden-for-mobile" />
                                  <Replacementof3dots
                                    className=" arrow hidden-for-desktop"
                                    onClick={() => {
                                      if(item.fromCoin && item.toCoin){
                                      setIdata(item);
                                      }
                                      setshow(true);
                                    }}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      //console.log(item)
                                      // if(item.fromCoin === null){
                                      //   console.log('From coin null')
                                      // }
                                      // else{
                                      //   if(item.toCoin === null){
                                      //     console.log('To coin null')
                                      //   }
                                      // }
                                      // if(item.fromCoin && item.toCoin){
                                      // setIdata(item);
                                      // }
                                      setIdata(item)
                                      setshow(true);
                                      
                                    }}
                                  >
                                    {t("Details")}
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    // onClick={() => {
                                    //   openModal(), setname("Delete");
                                    // }}
                                    >
                                    Cancel
                                    </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td> : <td></td>}
                      
                      </tr>
                    ))}
                </tbody>
              </Table>
              {exchangehistoryCount > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("from")}{" "}
                      {Math.ceil(exchangehistoryCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={exchangehistoryCount / limit}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        if (isFilterSet) {
                          getExchange({
                            skip: e.selected * limit,
                            limit,
                            startDate,
                            endDate,
                            orderBy: orderBy,
                            sort: sortBy
                          });
                        } else {
                          getExchange({ 
                            skip: e.selected * limit, 
                            limit,
                            orderBy: orderBy,
                            sort: sortBy 
                          });
                        }
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, wallet = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const ExhangeHistoryinfo = _.get(wallet, "ExhangeHistoryinfo", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const myusertransactionloading = _.get(
    wallet,
    "ExhangeHistoryloading",
    false
  );
  const exchangehistoryCount = _.get(wallet, "exchangehistoryCount.count", 10);

  return {
    mywalletsinfo,
    ExhangeHistoryinfo,
    myaccountandbalance,
    exchangehistoryCount,
    myusertransactionloading,
    thememode
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchange: (data) =>
    dispatch({ type: "getExchangehistoryCalled", payload: data }),
  getExchangeHistoryCount: (data) =>
    dispatch({ type: "getExchangeHistoryCountCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeHistory);
