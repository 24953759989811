import * as _ from "lodash";
import React, { useState } from "react";
import "@components/currency-dropdown/CurrencyDropdownStyles.scss";
import { Dropdown, FormControl } from "react-bootstrap";
import { ReactComponent as DropdownarrowIcon } from "@images/downarrow.svg";
import { ReactComponent as CryptocurrencyIcon } from "@images/btcoin.svg";
import { ReactComponent as FiatcurrencyIcon } from "@images/dollar1.svg";
import { useTranslation } from "react-i18next";
import CurrencyIcon from "@images/ccurrency.svg";

const CurrencyDropdown = (props) => {
  
  const { type, setType } = props || {};
  const options = [
    {
      name: "Cryptocurrency",
      img: { CurrencyIcon },
    },
    {
      name: "Fiat",
      img: { CurrencyIcon },
    },
  ];
  const [value, setValue] = useState("");
  const [dropdownshow, setShow] = useState(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="dropdownlink"
      ref={ref}
      onClick={(e) => {
        onClick(e);
        setShow(!dropdownshow);
      }}
      show={dropdownshow}
    >
      {children}
      <span className="toogleicon">
        <DropdownarrowIcon className="dropdownarrow1" />
      </span>
    </div>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div ref={ref} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.value.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const { t } = useTranslation();
  return (
    <div className="currencydropdown">
      <Dropdown align="end" autoClose="inside" onSelect={(a) => setType(a)}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {type == "Fiat" ? (
            <FiatcurrencyIcon className="currency" />
          ) : (
            <CryptocurrencyIcon className="currency" />
          )}
          <span className="optiontxt">{t(`${type}`)}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {options &&
            options.map((option, index) => {
              return (
                <Dropdown.Item eventKey={option.name}>
                  {option.name == "Fiat" ? (
                    <FiatcurrencyIcon className="currency" />
                  ) : (
                    <CryptocurrencyIcon className="currency" />
                  )}{" "}
                  <span className="ms-2">{t(`${option.name}`)}</span>
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CurrencyDropdown;
