export default {
  Logo: "Логотип",
  Thesizeofimage: "Размер изображения должен быть до",
  pixels: "пикселей",
  sizetothefile: "размер файла",
  upto: " до",
  inPNGorJPEGformat: "в PNG или JPEG формате",
  Change: "Изменить",
  Exclude: "Удалить",
  Operator: "Оператор",
  Edit: "Изменить",
  Commission: "Комиссия",
  Name: "Имя",
  FirstName: "Имя",
  LastName: "Фамилия",
  Email: "Электронная почта",
  Phone: "Телефон",
  Login: "Войти",
  Password: "Пароль",
  Save: "Сохранить",
  Cancel: "Отменить",
  ChangePassword: "Изменить пароль",
  Settings: "Настройки",
  LogOut: "Выйти",
  OldPassword: "Старый пароль",
  NewPassword: "Новый пароль",
  InvalidCredentials:'Неверные учетные данные',
  Passwordof8characters:'Пароль должен содержать не менее 8 символов',
  ConfirmPassword: "Подтвердите пароль",
  ResetPassword: "Сброс пароля",
  Delete:'Удалить',
  MyProfile: "Мой профиль",
  Myaccounts: "Мои аккаунты",
  MyBalance: "Мой баланс",
  MyBankAccount: "Мои банковские счета",
  BankAccountsRegistered:
    "Банковские счета, привязаные к вашей учетной записи:",
  AddNewBankAccountt: "Добавить новый банковский счет...",
  CreditCardMessage:
    "* Депозиты и снятие средств с кредитной карты подлежат ограничениям в отношении фиатных сумм.",
  MyCryptoAddress: "Мои крипто-адреса",
  CryptoAddressAsFollows: "Крипто-адреса, привязаные к вашей учетной записи:",
  AddNeww: "Добавить новое...",
  AddNewCryptoAddress: "Добавить новый крипто-адрес...",
  Photo: "Фото",
  "Old password cannot be empty": "Старый пароль не может быть пустым",
  "Password and confirm password should match": "Пароль и пароль для подтверждения должны совпадать",
  "Password cannot be empty": "Пароль не может быть пустым",
  "Password and confirm password should match": "Пароль и пароль для подтверждения должны совпадать",
  "Confirm password cannot be empty": "Подтверждение пароля не может быть пустым",
  Merchants: "Отделения",
  terms: "Условия",
  "First name is too short": "Имя слишком короткое",
  "Invalid First Name": "Недопустимое имя",
  "Last name is too short": "Фамилия слишком короткая",
  "Invalid Last Name": "Неверная фамилия",
  "Name is too short": "Имя слишком короткое",
  "Invalid name": "Неправильное имя",
  Swiftnumber: "Swift номер",
  Ifscnumber: "Ifsc номер",
  Ibannumber: "Iban номер",
  MOBILE_USED: "Мобильный номер, который Вы ввели, уже зарегистрирован.",
  KINDLY_ENTER_VALID_NAME: "Пожалуйста, введите действительное имя",
  Nametooshort: "Имя слишком короткое",
  InvalidPassword: "неправильный пароль",
  PasswordchangedSuccessfully: "Пароль успешно изменен !",

};