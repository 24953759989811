import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import Loader from "@components/loader/Loader";
import { Modal, Container, Form, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import InputField from "@components/input-field/InputField";
import CustomButton from "src/components/custom-button/CustomButton";
import BigNumber from "bignumber.js";
import { ReactComponent as DownIcon } from "@images/down.svg";
import "@components/payment-popup/PaymentPopup.scss";
import { useTranslation } from "react-i18next";

const PaymentPopup = ({
  getplatformTokensinfo,
  setShowModal,
  data,
  show1,
  exchangeRates,
  getExchangerate,
  createpaymentLink,
  myaccountandbalance,
  loading,
  commissionPercentage,
  createPaymentFail,
}) => {
  let [selected, setSelectedIndex] = useState(0);
  let [selected2, setSelectedIndex2] = useState(1);
  const [amount, setamount] = useState(0);
  const [email, setemail] = useState("");
  const [currentLang, setCurrentLang] = useState('en')

  useEffect(() => {
    setCurrentLang(localStorage.lang)
  },[localStorage.lang])

 
  const [validated, setValidated] = useState(false);
  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", 0);

    return address;
  };
  const onCreateTransaction = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      createpaymentLink({
        lang: currentLang,
        amount,
        acceptAs:
          getplatformTokensinfo &&
          getplatformTokensinfo[selected] &&
          getplatformTokensinfo[selected].id,
        exchangeTo:
          getplatformTokensinfo &&
          getplatformTokensinfo[selected2] &&
          getplatformTokensinfo[selected2].id,
        acceptAsType: "COIN",
        exchangeToType: "COIN",
        email,
        toAddress: getAdd(
          getplatformTokensinfo &&
            getplatformTokensinfo[selected2] &&
            getplatformTokensinfo[selected2].id
        ),
      });
      setTimeout(() => {
        setamount("");
        setValidated(false);
      }, 1000);
    }
  };
  useEffect(() => {
    getExchangerate({
      symbol1:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected2] &&
        getplatformTokensinfo[selected2].exchangeSymbol,

      symbol2:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected] &&
        getplatformTokensinfo[selected].exchangeSymbol,
    });
  }, [selected2, selected]);

  let { result, sym1, sym2 } = exchangeRates || {};
  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="payment"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
        <Container>
          <Form noValidate validated={validated} onSubmit={onCreateTransaction}>
            <div className="currencytoconvert">
              <div className="textholder">
                <span className="text">{t("Acceptas")}</span>
              </div>
              <DropdownSearch
                Selected={selected}
                options={getplatformTokensinfo}
                setSelectedIndex={(e) => {
                  setSelectedIndex(e);
                }}
              />
            </div>

            <div className="currencytoconvert">
              <div className="textholder">
                {" "}
                <span className="text">{t("Convertto")}</span>
              </div>
              <DropdownSearch
                Selected={selected2}
                options={getplatformTokensinfo}
                setSelectedIndex={setSelectedIndex2}
              />
            </div>

            <div className="currencytoconvert">
              <div className="textholder">
                {" "}
                <span className="text"> {t("Amount")}</span>
              </div>
              <InputField
                type="number"
                required
                onChange={(e) => setamount(e.target.value)}
                value={amount}
              />
            </div>
            {amount !== 0 &&
              amount !== undefined &&
              amount !== null &&
              amount !== "" && (
                <div className="text-left">
                  <p>
                    {" "}
                    {t("YouwillReceive")} {amount} -{" "}
                    {BigNumber(amount)
                      .multipliedBy(
                        commissionPercentage &&
                          commissionPercentage.commissionPercentage
                      )
                      .dividedBy(100)
                      .toFixed()}{" "}
                    ({t("PlatformCommission")}) ={" "}
                    <strong>
                      {" "}
                      {BigNumber(amount)
                        .minus(
                          BigNumber(amount)
                            .multipliedBy(
                              commissionPercentage &&
                                commissionPercentage.commissionPercentage
                            )
                            .dividedBy(100)
                            .toFixed()
                        )
                        .toFixed()}{" "}
                      {getplatformTokensinfo &&
                        getplatformTokensinfo[selected2] &&
                        getplatformTokensinfo[selected2].exchangeSymbol}
                    </strong>
                  </p>
                </div>
              )}

            <div className="currencytoconvert">
              <div className="textholder">
                {" "}
                <span className="text">{t("Sendlinkto")}</span>
              </div>
              <DropdownSearch
                options={[{ tokenName: "Email" }]}
                Selected={0}
                setSelectedIndex={() => {}}
              />
            </div>

            <div className="currencytoconvert">
              <div className="textholder">
                {" "}
                <span className="text"> {t("EnterEmail")}</span>
              </div>
              <InputField
                type="email"
                required
                onChange={(e) => setemail(e.target.value)}
                value={email}
              />
            </div>

            <div className="currencytoconvert">
              <div className="box">
                <div clas="link">
                  <span className="linktxt">
                    {t("Paymentlinkwillbecreated")}
                  </span>
                </div>
                <div className="commonsection">
                  <span className="textline1">
                    {amount}{" "}
                    {getplatformTokensinfo &&
                      getplatformTokensinfo[selected2] &&
                      getplatformTokensinfo[selected2].exchangeSymbol}
                  </span>
                  <span className="textline2">
                    {getplatformTokensinfo &&
                      getplatformTokensinfo[selected2] &&
                      getplatformTokensinfo[selected2].tokenName}{" "}
                  </span>
                </div>
                <div className="imgsection">
                  <DownIcon className="arrowclass" />
                </div>
                <div className="commonsection">
                  <span className="textline1">
                    {Number(amount * result).toFixed(5)}
                  </span>
                  <span className="textline2">
                    (1{" "}
                    {getplatformTokensinfo &&
                      getplatformTokensinfo[selected2] &&
                      getplatformTokensinfo[selected2].exchangeSymbol}{" "}
                    / {Number(result).toFixed(5)}{" "}
                    {getplatformTokensinfo &&
                      getplatformTokensinfo[selected] &&
                      getplatformTokensinfo[selected].exchangeSymbol}
                    )
                  </span>
                  <span className="textline2">
                    {getplatformTokensinfo &&
                      getplatformTokensinfo[selected] &&
                      getplatformTokensinfo[selected].exchangeSymbol}
                    -
                    {getplatformTokensinfo &&
                      getplatformTokensinfo[selected] &&
                      getplatformTokensinfo[selected].tokenName}
                  </span>
                </div>
              </div>
            </div>
            {loading && <Loader />}
            <span>{t(`${createPaymentFail.message}`)}</span>
            <div className="currencytoconvert">
              <div className="buttoncontainer">
                <CustomButton
                  type="submit"
                  name={t("Create")}
                  filled={true}
                  primary={true}
                  disable={loading}
                />
              </div>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(PaymentPopup);
