import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";

import axios from "axios";
import moment from "moment";
import SmallButton from "@components/small-button/SmallButton";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { ResponsiveLine } from "@nivo/line";

import "@components/coin-balance-details/CoinBalanceDetails.scss";
import { getCSymbol, getESymbol } from "src/utils/Currencies";

function CoinBalanceDetails({
  getplatformTokensinfo,
  getAdd,
  getBal,
  fiat,
  logo,
  name,
  balance,
  decimals,
  bgcolor,
  bnbdata,
  bnbpdata,
  ethdata,
  ethpdata,
  btcdata,
  btcpdata,
  busddata,
  busdpdata,
  trxdata,
  trycpdata,
  trycdata,
  trxpdata,
  setDepositModal,
  setExchangeModal,
  setwithdrawModal,
  withdramodal,
  exchangemodal,
  depositModal,
  coin,
  coinItem,
  setcoinItem,
  PreferredCurrency,
  isModal,
  coinsymbol,
  currTableno,
  total,
  max,
  min,
  chartPoinst,
  percentChanges,
  fiatrate,
}) {
  const [value, setvalue] = useState("");
  let [tableNo, setTableno] = useState("first");
  const [prctChg, setPrctChg] = useState("");
  const [Currency, setCurrency] = useState("USD");

  useEffect(() => {}, [PreferredCurrency]);
  useEffect(() => {}, [coinsymbol]);

  useEffect(() => {
    if (percentChanges !== undefined) {
      setPrctChg(percentChanges);
    }
    if (fiat) {
      getData(coinsymbol);
    }
  }, [percentChanges]);

  const gettextcolor = (tokenName) => {
    let cond = true;
    if (tokenName == "Ethereum") {
      if (ethpdata && ethpdata.P.includes("-")) cond = false;
    }
    if (tokenName == "Ethereum") {
      if (ethpdata && ethpdata.P.includes("-")) cond = false;
    }
    if (tokenName == "Bitcoin") {
      if (btcpdata && btcpdata.P.includes("-")) cond = false;
    }
    if (tokenName == "BSC") {
      if (bnbpdata && bnbpdata.P.includes("-")) cond = false;
    }
    if (tokenName == "Tether") {
      if (busdpdata && busdpdata.P.includes("-")) cond = false;
    }
    if (tokenName == "Tron") {
      if (trxpdata && trxpdata.P.includes("-")) cond = false;
    }
    if (tokenName === "TRYCoin") {
      if (trycpdata && trycpdata.P.includes("-")) cond = false;
    }

    return cond;
  };
  const [marketdata, setmarketdata] = useState("");
  const [chartdata, setchartdata] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(28, "days"));
  const [endDate, setEndDate] = useState(new Date());
  const [ApiDATA, setApiDATA] = useState([]);
  const [sq, setsq] = useState([]);
  const { t } = useTranslation();
  const getData = (exchangeSymbol, picker) => {
    let stdt = picker?.startDate || startDate;
    let eddt = picker?.endDate || endDate;
    let charts = [];
    axios
      .get(
        `https://api.currencyapi.com/v3/range?apikey=${
          process.env.REACT_APP_FIATKEY
        }&base_currency=${exchangeSymbol}&datetime_start=${moment(stdt).format(
          "YYYY-MM-DD"
        )}&datetime_end=${moment(eddt).format("YYYY-MM-DD")}&accuracy=day `
      )
      .then((res) => {
        setApiDATA(res.data && res.data.data && res.data.data);
        res.data && res.data.data && res.data.data.map(it=> {
          charts.push({
            x: moment(it.datetime).format("MM/DD"),
            y:
              it.currencies[Currency] !== undefined
                ? it.currencies[Currency].value
                : exchangeSymbol == Currency
                ? 1
                : "",
          });
        })
         setchartdata(charts);
      });
  };

  const fiatBalanceFromApi =
    ApiDATA && ApiDATA[`${moment().format("YYYY-MM-DD")}`];
  // console.log('Fiat balance from' , fiatBalanceFromApi)
  const fiatCurrency =
    fiatBalanceFromApi && fiatBalanceFromApi[`${PreferredCurrency}`];
  // console.log('fiat currency' , fiatCurrency)

  return (
    <div id="mybalancesdetails">
      {isModal && currTableno != "second" && (
        <div className="coinprices">
          <p className="coinpricetitle">Price($)</p>
          <p className="coinpricemain">
            {getCSymbol(PreferredCurrency)}
            {name == "Ethereum"
              ? Number(ethdata && ethdata.p).toFixed(2)
              : name == "Bitcoin"
              ? Number(btcdata && btcdata.p).toFixed(2)
              : name == "BSC"
              ? Number(bnbdata && bnbdata.p).toFixed(2)
              : name == "Tether"
              ? Number(busddata && busddata.p).toFixed(2)
              : name == "Tron"
              ? Number(trxdata && trxdata.p).toFixed(2)
              : name == "TRYCoin"
              ? BigNumber(1)
                  .dividedBy(trycdata && trycdata.p)
                  .toFixed(2)
              : Number("0.15").toFixed(2)}
          </p>
          <p className="coinpricetitle">Change</p>
          {/* <p className="coinpricemain">-3.79%</p> */}
          <p
            className={`coinpricemain ${
              gettextcolor(name) ? "completed" : "cancel"
            }`}
          >
            {gettextcolor(name) ? "+" : ""}
            {name == "Ethereum"
              ? Number(ethpdata && ethpdata.P).toFixed(2)
              : name == "Bitcoin"
              ? Number(btcpdata && btcpdata.P).toFixed(2)
              : name == "BSC"
              ? Number(bnbpdata && bnbpdata.P).toFixed(2)
              : name == "Tether"
              ? Number(busdpdata && busdpdata.P).toFixed(2)
              : name == "Tron"
              ? Number(trxpdata && trxpdata.P).toFixed(2)
              : name == "TRYCoin"
              ? Number(trycpdata && trycpdata.p).toFixed(2)
              : Number("0.10").toFixed(2)}
            %
          </p>
        </div>
      )}

      <div className="imgwrapper" style={{ backgroundColor: bgcolor }}>
        <img src={`${process.env.REACT_APP_IMGLINK}${logo}`} alt="" />
      </div>
      {isModal && currTableno == "second" && (
        <>
          <p className="coinBalAndSmb">
            {getCSymbol(PreferredCurrency)} {Number(balance).toFixed(4)}{" "}
          </p>
        </>
      )}
      {(isModal && currTableno != "second" && (
        <>
          <p className="coinBalAndSmb">
            {Number(balance / 10 ** decimals).toFixed(4)}
            {coinsymbol}
          </p>
          <p className="coinBalModal">
            {fiat ? (
              <div>
                {getCSymbol(PreferredCurrency)}{" "}
                {Number(
                  BigNumber(1).dividedBy(
                    (fiatrate && fiatrate[`${coinsymbol}`]) || 1
                  )
                ).toFixed(2)}
              </div>
            ) : (
              <div>
                {getCSymbol(PreferredCurrency)}{" "}
                {name == "Ethereum"
                  ? Number(ethdata && ethdata.p).toFixed(2)
                  : name == "Bitcoin"
                  ? Number(btcdata && btcdata.p).toFixed(2)
                  : name == "BSC"
                  ? Number(bnbdata && bnbdata.p).toFixed(2)
                  : name == "Tether"
                  ? Number(busddata && busddata.p).toFixed(2)
                  : name == "Tron"
                  ? Number(trxdata && trxdata.p).toFixed(2)
                  : name == "TRYCoin"
                  ? BigNumber(1)
                      .dividedBy(trycdata && trycdata.p)
                      .toFixed(2)
                  : Number("0.15").toFixed(2)}
              </div>
            )}
          </p>
        </>
      )) || (
        <>
          <p className="coinname">{name}</p>
          <p className="coinnamesymbol">{coinsymbol}</p>
        </>
      )}

      <hr />
      <div className="buttonsholder">
        <SmallButton
          type="deposit"
          onclick={() => {
            setDepositModal(true);
          }}
        />
        <SmallButton
          type="withdraw"
          onclick={() => {
            setwithdrawModal(true);
          }}
        />
        {currTableno != "second" && (
          <SmallButton
            type="exhange"
            onclick={() => {
              setExchangeModal();
            }}
          />
        )}
      </div>
      <hr />
      {isModal && (
        <div>
          <p className="notransactiontext">No Transaction</p>
        </div>
      )}
      <div className="balancewrapper">
        <div className="singlebalanceunit">
          <p className="balanceunitheading">{t("Balance")}</p>
          <p className="balanceunitcontent">
            {fiat
              ? Number(balance).toFixed(4)
              : Number(balance / 10 ** decimals).toFixed(4)}
          </p>
        </div>
        <div className="singlebalanceunit">
          <p className="balanceunitheading">
            {t("Price")}({PreferredCurrency})
          </p>
          <p className="balanceunitcontent">
            {fiat ? (
              <div>
                {getCSymbol(PreferredCurrency)}{" "}
                {PreferredCurrency === coinsymbol
                  ? // Number(BigNumber(1).dividedBy(fiatrate && fiatrate[getESymbol(PreferredCurrency)]) || 1).toFixed(2)
                    Number(BigNumber(1) * 1 || 1).toFixed(2)
                  : // Number(BigNumber(fiatCurrency).dividedBy(fiatrate && fiatrate[`${coinsymbol}`]) || 1).toFixed(2)
                    // Number((fiatCurrency) /(fiatrate && fiatrate[`${coinsymbol}`]) || 1).toFixed(2)
                    Number(fiatCurrency || 1).toFixed(2)}
              </div>
            ) : (
              <div>
                {getCSymbol(PreferredCurrency)}{" "}
                {name == "Ethereum"
                  ? BigNumber(ethdata && ethdata.p)
                      .multipliedBy(
                        (fiatrate &&
                          fiatrate[getESymbol(PreferredCurrency)] &&
                          fiatrate[getESymbol(PreferredCurrency)].value) ||
                          1
                      )
                      .toFixed(2, BigNumber.ROUND_FLOOR)
                  : name == "Bitcoin"
                  ? BigNumber(btcdata && btcdata.p)
                      .multipliedBy(
                        (fiatrate &&
                          fiatrate[getESymbol(PreferredCurrency)] &&
                          fiatrate[getESymbol(PreferredCurrency)].value) ||
                          1
                      )
                      .toFixed(2, BigNumber.ROUND_FLOOR)
                  : name == "BSC"
                  ? BigNumber(bnbdata && bnbdata.p)
                      .multipliedBy(
                        (fiatrate &&
                          fiatrate[getESymbol(PreferredCurrency)] &&
                          fiatrate[getESymbol(PreferredCurrency)].value) ||
                          1
                      )
                      .toFixed(2, BigNumber.ROUND_FLOOR)
                  : name == "Tether"
                  ? BigNumber(busddata && busddata.p)
                      .multipliedBy(
                        (fiatrate &&
                          fiatrate[getESymbol(PreferredCurrency)] &&
                          fiatrate[getESymbol(PreferredCurrency)].value) ||
                          1
                      )
                      .toFixed(2, BigNumber.ROUND_FLOOR)
                  : name == "Tron"
                  ? BigNumber(trxdata && trxdata.p)
                      .multipliedBy(
                        (fiatrate &&
                          fiatrate[getESymbol(PreferredCurrency)] &&
                          fiatrate[getESymbol(PreferredCurrency)].value) ||
                          1
                      )
                      .toFixed(2, BigNumber.ROUND_FLOOR)
                  : name == "TRYCoin"
                  ? BigNumber(1)
                      .dividedBy(trycdata && trycdata.p)
                      .multipliedBy(
                        (fiatrate &&
                          fiatrate[getESymbol(PreferredCurrency)] &&
                          fiatrate[getESymbol(PreferredCurrency)].value) ||
                          1
                      )
                      .toFixed(2, BigNumber.ROUND_FLOOR)
                  : BigNumber(0.15)
                      .multipliedBy(
                        (fiatrate &&
                          fiatrate[getESymbol(PreferredCurrency)] &&
                          fiatrate[getESymbol(PreferredCurrency)].value) ||
                          1
                      )
                      .toFixed(2, BigNumber.ROUND_FLOOR)}
              </div>
            )}
          </p>
        </div>
        {!fiat && (
          <div className="singlebalanceunit">
            <p className="balanceunitheading">{t("Changes")}</p>
            <p
              className={`balanceunitcontent ${
                gettextcolor(name) ? "completed" : "cancel"
              }`}
            >
              {gettextcolor(name) ? "+" : ""}
              {name == "Ethereum"
                ? Number(ethpdata && ethpdata.P).toFixed(2)
                : name == "Bitcoin"
                ? Number(btcpdata && btcpdata.P).toFixed(2)
                : name == "BSC"
                ? Number(bnbpdata && bnbpdata.P).toFixed(2)
                : name == "Tether"
                ? Number(busdpdata && busdpdata.P).toFixed(2)
                : name == "Tron"
                ? Number(trxpdata && trxpdata.P).toFixed(2)
                : name == "TRYCoin"
                ? Number(trycpdata && trycpdata.p).toFixed(2)
                : Number("0.00").toFixed(2)}
              %
            </p>
          </div>
        )}
        <div
          className={`${
            percentChanges && percentChanges.includes("-")
              ? "redgraph"
              : "greengraph"
          } singlebalanceunit`}
        >
          <p className="balanceunitheading">{t("Chart")}</p>
          {chartPoinst && chartPoinst.length !== 0 ? (
            <ResponsiveLine
              data={[
                {
                  id: "japan",
                  color: "hsl(295, 70%, 50%)",
                  data: chartPoinst || [],
                },
              ]}
              enableGridY={false}
              keys={["close"]}
              margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              enableArea={true}
              useMesh={false}
               enablePoints={false}
              enableCrosshair={false}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              isInteractive={false}
              axisLeft={null}
              enableStackTooltip={false}
              enableGridX={false}
              curve="cardinal"
              offsetType="none"
              colors={{ scheme: "paired" }}
              borderColor={"#637EEB"}
              borderWidth={1}
              dotSize={0}
              dotColor={{ from: "color" }}
              dotBorderWidth={1}
              dotBorderColor={{
                from: "color",
                modifiers: [["darker", 0.7]],
              }}
              animate={true}
            />
          ) : (
            <ResponsiveLine
              data={[
                {
                  id: "japan",
                  color: "hsl(295, 70%, 50%)",
                  data: chartdata || [],
                },
              ]}
              enableGridY={false}
              keys={["close"]}
              margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              enableArea={true}
              useMesh={false}
               enablePoints={false}
              enableCrosshair={false}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              isInteractive={false}
              axisLeft={null}
              enableStackTooltip={false}
              enableGridX={false}
              curve="cardinal"
              offsetType="none"
              colors={{ scheme: "paired" }}
              borderColor={"#637EEB"}
              borderWidth={1}
              dotSize={0}
              dotColor={{ from: "color" }}
              dotBorderWidth={1}
              dotBorderColor={{
                from: "color",
                modifiers: [["darker", 0.7]],
              }}
              animate={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ wallet = {}, app = {}, user = {}, fiat = {} }) => {
  const fiatrate = _.get(app, "fiatrate", false);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");
  return {
    PreferredCurrency,
    fiatrate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTheme: (data) => dispatch({ type: "updateTheme", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoinBalanceDetails);
