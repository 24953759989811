import { createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";

const userslice = createSlice({
  name: "user",
  initialState: {
    userInfo: null,
    signInLoading: false,
    signInError: undefined,
    signUpLoading: false,
    signUpError: "",
    forgotPasswordLoading: false,
    forgotPasswordSuccess: undefined,
    forgotPasswordError: "",
    createCryptoWalletInfo: null,
    createCryptoWalletLoading: false,
    createCryptoWalletError: "",
    createFiatWalletInfo: null,
    createFiatWalletLoading: false,
    createFiatWalletError: "",
    //changePasswordloading: false,
    marketRateDisplayCurrencies: {
      crpto: [],
      fiat: [],
    },
    dashboardDisplayCurrencies: {
      crpto: [],
      fiat: [],
    },
    googleAuthLoading: undefined,
    userProfileImageFailed: "",
    updateProfileLoading: false,
    updateProfileImageLoading: false,
    updateProfileFailed: "",

    superManagerInfo: null,
    superManagererror: "",
    MyProfileInfo: null,
    MyProfileloading: false,
    businessRequest: [],
    businessRequestLoading: false,
    businessFormInfo: null,
    businessFormError: "",
    businessFormLoading: false,
    giveApprovalloading: false,
    giveApprovalError: "",

    changePasswordloading: false,
    changePasswordErrorMessage: undefined,
    changePasswordSuccess: undefined,

    displayedCurrencies: [],
    googleAuthData: {},
    updateProfileImageError: undefined,
    removeprofileimagetrigger: false,
    removeprofileimageerror: false,

    // Get Address Bookings
    getAddressLoading: false,
    getAddressError: undefined,
    getAllAddresses: [],

    // Add Address Booking
    addAddressBookingLoading: false,
    addAddressBookingError: undefined,

    // Edit Address Booking
    editAddressBookingLoading: false,
    editAddressBookingError: undefined,

    // Delete Address Booking
    deleteAddressBookingLoading: false,
    deleteAddressBookingError: undefined,
    showBusinessForm: false,

    //Get NFC Cards
    getNfcCardLoading: false,
    getNfcCardSuccess: [],
    getNfcCardCount: 0,
    getNfcCardFailure: undefined,

    //create NFC Card
    createNfcCardLoading: false,
    createNfcCard: undefined,
    createNfcCardFailure: undefined,

    //Get User NFC Cards
    listNfcCardLoading: false,
    listNfcCard: [],
    listNfcCardFailure: undefined,
  },
  reducers: {
    setDisplayedCurrencies: (state, action) => {
      return {
        ...state,
        displayedCurrencies: action.payload,
      };
    },

    setMarketRatesCurrencies: (state, action) => {
      return {
        ...state,
        marketRateDisplayCurrencies: action.payload,
      };
    },

    setDashboardCurrencies: (state, action) => {
      return {
        ...state,
        dashboardDisplayCurrencies: action.payload,
      };
    },

    UserloginSuccess: (state, action) => {
      return {
        ...state,
        userInfo: action.payload.userInfo,
        signInError: undefined,
        auth: action.payload.auth,
      };
    },

    ManagerloginSuccess: (state, action) => {
      return {
        ...state,
        userInfo: action.payload,
        auth: true,
        signInError: "",
      };
    },
    loginTrigger: (state, action) => {
      return {
        ...state,
        signInLoading: action.payload,
      };
    },
    loginFail: (state, action) => {
      return {
        ...state,
        signInError: action.payload,
      };
    },
    logoutSuccess: (state, action) => {
      return {};
    },
    signUpTrigger: (state, action) => {
      return {
        ...state,
        signUpLoading: action.payload,
        signupfinish: false,
      };
    },
    clearErrorMessageSuccess: (state, action) => {
      return {
        ...state,
        signInError: "",
        signUpError: "",
        ConfirmcodeModalError: "",
        signInLoading: false,
      };
    },
    clearResetPopupErrorSuccess: (state, action) => {
      // //console.log("redux clear popup called")
      return {
        ...state,
        changePasswordErrorMessage: undefined,
      };
    },
    UsersignupSuccess: (state, action) => {
      return {
        ...state,
        userInfo: { ...action.payload },
        signInError: "",
      };
    },
    signupFail: (state, action) => {
      return {
        ...state,
        signUpError: action.payload,
      };
    },

    resendotpTrigger: (state, action) => {
      return {
        ...state,
        resendotplaoding: action.payload,
      };
    },
    OTPSuccess: (state, action) => {
      return {
        ...state,
        signupfinish: action.payload,
      };
    },

    otpverifyTrigger: (state, action) => {
      return {
        ...state,
        otpverifyloading: action.payload,
      };
    },

    changePasswordTrigger: (state, action) => {
      return {
        ...state,
        changePasswordloading: action.payload,
      };
    },

    changePasswordSuccess: (state, action) => {
      return {
        ...state,
        changePasswordloading: false,
        changePasswordErrorMessage: undefined,
        changePasswordSuccess: true,
        userInfo: {
          ...state.userInfo,
          accessToken: action.payload.accessToken.accessToken,
        },
      };
    },
    changePasswordFail: (state, action) => {
      return {
        ...state,
        changePasswordError: action.payload,
      };
    },

    otpverifyFail: (state, action) => {
      return {
        ...state,
        otpverifyerror: action.payload,
      };
    },
    forgotPasswordSuccess: (state, action) => {
      //console.log("forgot password popup called");
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordError: undefined,
        forgotPasswordSuccess: true,
        userInfo: {
          ...state.userInfo,
          // accessToken: action.payload.accessToken.accessToken,
        },
      };
    },
    // commented to check some functionality
    // forgotPasswordSuccess: (state, action) => {
    //   return {
    //     ...state,
    //     forgetdone: true,
    //     userInfo: action.payload,
    //   };
    // },
    //
    forgotdone: (state, action) => {
      return {
        ...state,
        forgetdone: false,
      };
    },

    createCryptoWalletSuccess: (state, action) => {
      return {
        ...state,
        createCryptoWalletInfo: action.payload,
      };
    },
    createCryptoWalletTrigger: (state, action) => {
      return {
        ...state,
        createCryptoWalletLoading: action.payload,
      };
    },
    createCryptoWalletFail: (state, action) => {
      return {
        ...state,
        createCryptoWalletError: action.payload,
      };
    },

    createFiatWalletSuccess: (state, action) => {
      return {
        ...state,
        createFiatWalletInfo: action.payload,
      };
    },
    createFiatWalletTrigger: (state, action) => {
      return {
        ...state,
        createFiatWalletLoading: action.payload,
      };
    },
    createFiatWalletFail: (state, action) => {
      return {
        ...state,
        createFiatWalletError: action.payload,
      };
    },

    addSuperManagerSuccess: (state, action) => {
      return {
        ...state,
        superManagerInfo: { ...action.payload },
        signInError: "",
      };
    },
    addSuperManagerFail: (state, action) => {
      return {
        ...state,
        superManagererror: action.payload,
      };
    },

    forgotPasswordTrigger: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: action.payload,
        forgotPasswordError: undefined,
        forgotPasswordSuccess: undefined,
        signupfinish: false,
      };
    },
    forgotPasswordFail: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordError: action.payload,
      };
    },

    forgotPasswordErrorRemove: (state, action) => {
      return {
        ...state,
        forgotPasswordError: undefined,
      };
    },

    getMyprofileSuccess: (state, action) => {
      return {
        ...state,
        MyProfileInfo: { ...state.MyProfileInfo, ...action.payload },
      };
    },
    getMyprofileTrigger: (state, action) => {
      return {
        ...state,
        MyProfileloading: action.payload,
      };
    },

    getBusinessUserRequestSuccess: (state, action) => {
      return {
        ...state,
        businessRequest: action.payload,
      };
    },

    BusinessRequestTrigger: (state, action) => {
      return {
        ...state,
        businessRequestLoading: action.payload,
      };
    },

    createBusinessFormSuccess: (state, action) => {
      return { ...state, businessFormInfo: action.payload };
    },
    createBusinessFormTrigger: (state, action) => {
      return {
        ...state,
        businessFormLoading: action.payload,
      };
    },
    createBusinessFormFail: (state, action) => {
      return { ...state, businessFormError: action.payload };
    },

    giveApprovalSuccess: (state, action) => {
      return {
        ...state,
        businessFormInfo: { ...state.businessFormInfo, ...action.payload },
      };
    },
    giveApprovalTrigger: (state, action) => {
      return {
        ...state,
        giveApprovalloading: action.payload,
      };
    },
    giveApprovalFail: (state, action) => {
      return {
        ...state,
        giveApprovalError: action.payload,
      };
    },
    userDashboardListSuccess: (state, action) => {
      return {
        ...state,
        userDashboardList: action.payload,
      };
    },
    profileImageUploadSuccess: (state, action) => {
      return {
        ...state,
        MyProfileInfo: action.payload,
      };
    },
    profileImageRemoveSuccess: (state, action) => {
      return {
        ...state,
        MyProfileInfo: action.payload,
        // MyProfileInfo: undefined,
      };
    },
    profileImageUploadFail: (state, action) => {
      return {
        ...state,
        userProfileImageFailed: action.payload,
      };
    },
    updateProfileImageTrigger: (state, action) => {
      return {
        ...state,
        updateProfileImageLoading: action.payload,
      };
    },
    updateProfileIamgeTriggerFailure: (state, action) => {
      return {
        ...state,
        updateProfileImageError: action.payload,
      };
    },
    updateProfileTrigger: (state, action) => {
      return {
        ...state,
        updateProfileLoading: action.payload,
      };
    },
    updateProfileSuccess: (state, action) => {
      return {
        ...state,
        MyProfileInfo: action.payload,
      };
    },
    updateProfileFail: (state, action) => {
      return {
        ...state,
        updateProfileFailed: action.payload,
      };
    },

    /* Change Password Redux Start */
    changePasswordStart: (state, action) => {
      return {
        ...state,
        changePasswordloading: true,
        changePasswordSuccess: undefined,
        changePasswordErrorMessage: undefined,
      };
    },

    storeAuthSuccess: (state, action) => {
      return {
        ...state,
        auth: true,
      };
    },
    changePasswordFailure: (state, action) => {
      return {
        ...state,
        changePasswordloading: false,
        changePasswordSuccess: undefined,
        changePasswordErrorMessage: action.payload,
        // userInfo: {
        //   ...state.userInfo,
        //   accessToken: undefined,
        // },
      };
    },
    setConfirmcodeModalError: (state, action) => {
      return {
        ...state,
        ConfirmcodeModalError: action.payload,
      };
    },
    setgoogleAuthLoading: (state, action) => {
      //console.log("Google auth loading called");
      return {
        ...state,
        googleAuthLoading: true,
      };
    },
    setgoogleAuthSuccess: (state, action) => {
      //console.log("Redux data -> ", action.payload);
      return {
        ...state,
        googleAuthData: action.payload,
        googleAuthLoading: false,
      };
    },

    setChangePasswordMessageClear: (state, action) => {
      return {
        ...state,
        changePasswordloading: false,
        changePasswordSuccess: undefined,
        changePasswordErrorMessage: undefined,
      };
    },

    /* Change Password Redux End */
    // new redux for image change
    removeProfileImageTrigger: (state, action) => {
      //console.log("removeProfileImageTrigger", action.payload);
      return {
        ...state,
        removeprofileimagetrigger: action.payload,
      };
    },
    removeProfileImageFailure: (state, action) => {
      //console.log("removeprofileimage redux called", action.payload);
      return {
        ...state,
        removeprofileimageerror: action.payload,
      };
    },
    /* Get Address Booking Redux */

    getAddressBookingsTrigger: (state, action) => {
      return {
        ...state,
        getAddressLoading: true,
        getAddressError: undefined,
      };
    },

    getAddressBookingsSuccess: (state, action) => {
      return {
        ...state,
        getAddressLoading: false,
        getAddressError: undefined,
        getAllAddresses: action.payload,
      };
    },

    getAddressBookingsFailure: (state, action) => {
      return {
        ...state,
        getAddressLoading: false,
        getAddressError: action.payload,
      };
    },

    addAddressBookingTrigger: (state, action) => {
      return {
        ...state,
        addAddressBookingLoading: true,
        addAddressBookingError: undefined,
      };
    },

    addAddressBookingSuccess: (state, action) => {
      return {
        ...state,
        addAddressBookingLoading: false,
        addAddressBookingError: undefined,
      };
    },

    addAddressBookingFailure: (state, action) => {
      return {
        ...state,
        addAddressBookingLoading: false,
        addAddressBookingError: action.payload,
      };
    },

    editAddressBookingTrigger: (state, action) => {
      return {
        ...state,
        editAddressBookingLoading: true,
        editAddressBookingError: undefined,
      };
    },

    editAddressBookingSuccess: (state, action) => {
      return {
        ...state,
        editAddressBookingLoading: false,
        editAddressBookingError: undefined,
      };
    },

    editAddressBookingFailure: (state, action) => {
      return {
        ...state,
        editAddressBookingLoading: false,
        editAddressBookingError: action.payload,
      };
    },

    deleteAddressBookingTrigger: (state, action) => {
      return {
        ...state,
        deleteAddressBookingLoading: true,
        deleteAddressBookingError: undefined,
      };
    },

    deleteAddressBookingSuccess: (state, action) => {
      return {
        ...state,
        deleteAddressBookingLoading: false,
        deleteAddressBookingError: undefined,
      };
    },

    deleteAddressBookingFailure: (state, action) => {
      return {
        ...state,
        deleteAddressBookingLoading: false,
        deleteAddressBookingError: action.payload,
      };
    },

    storeMyBalanceSuccess: (state, action) => {
      return {
        ...state,
        myEstimatedBal: action.payload,
      };
    },
    storeMyBalanceFailure: (state, action) => {
      return {
        ...state,
        myEstimatedBal: 0,
      };
    },
    storePreferredCurrencySuccess: (state, action) => {
      return {
        ...state,
        PreferredCurrency: action.payload,
      };
    },

    openBusinessForm: (state, action) => {
      return {
        ...state,
        showBusinessForm: true,
      };
    },

    closeBusinessForm: (state, action) => {
      return {
        ...state,
        showBusinessForm: false,
      };
    },

    allNfcCardLoadingStart: (state, action) => {
      return {
        ...state,
        getNfcCardLoading: true,
        getNfcCardSuccess: [],
        getNfcCardCount: 0,
        getNfcCardFailure: undefined,
      };
    },

    allNfcCardSuccess: (state, action) => {
      return {
        ...state,
        getNfcCardLoading: false,
        getNfcCardSuccess: action.payload,
        getNfcCardCount: action.payload.count,
        getAddressBookingsFailure: undefined,
      }
    },

    allNfcCardFail: (state, action) => {
      return {
        ...state,
        getNfcCardLoading: false,
        getNfcCardSuccess: [],
        getNfcCardCount: 0,
        getNfcCardFailure: action.payload,
      }
    },

    createNfcCardLoadingStart: (state, acton) => {
      return {
        ...state,
        createNfcCardLoading: true,
        createNfcCard: undefined,
        createNfcCardFailure: undefined,
      }
    },

    createNfcCardSuccess: (state, action) => {
      return {
        ...state,
        createNfcCardLoading: false,
        createNfcCard: action.payload,
        createNfcCardFailure: undefined,
      }
    },

    createNfcCardFailed: (state, action) => {
      return {
        ...state,
        createNfcCardLoading: false,
        createNfcCard: undefined,
        createNfcCardFailure: action.payload,
      }
    },

    listNfcCardLoadingStart: (state, action) => {
      return {
        ...state,
        listNfcCardLoading: true,
        listNfcCard: [],
        listNfcCardFailure: undefined
      }
    },

    listNfcCardSuccess: (state, action) => {
      return {
        ...state,
        listNfcCardLoading: false,
        listNfcCard: action.payload,
        listNfcCardFailure: undefined,
      }
    },

    listNfcCardFailed: (state, action) => {
      return{
        ...state,
        listNfcCardLoading: false,
        listNfcCard: [],
        listNfcCardFailure: action.payload,
      }
    }
  },
});

export default userslice.reducer;
export const {
  UserloginSuccess,
  signIn,
  saveRoleSucess,
  logoutSuccess,
  loginFail,
  UsersignupSuccess,
  changePasswordTrigger,

  signupFail,
  forgotPasswordSuccess,
  forgotPasswordFail,
  forgotPasswordTrigger,
  profileSuccess,
  forgotdone,
  profileFail,
  signUpTrigger,
  signupFulfill,
  loginTrigger,
  resendotpTrigger,
  otpverifyTrigger,
  otpverifyFail,
  otpverifyerror,
  OTPSuccess,
  createCryptoWalletSuccess,
  createCryptoWalletTrigger,
  createCryptoWalletError,
  createCryptoWalletFail,
  createFiatWalletSuccess,
  createFiatWalletTrigger,
  createFiatWalletError,
  createFiatWalletFail,
  addSuperManagerSuccess,
  addSuperManagerFail,

  getMyprofileSuccess,
  profileImageRemoveSuccess,
  getMyprofileTrigger,
  clearErrorMessageSuccess,
  profileImageUploadSuccess,

  profileImageUploadFail,
  updateProfileTrigger,
  updateProfileImageTrigger,
  updateProfileIamgeTriggerFailure,
  updateProfileSuccess,
  updateProfileFail,

  getBusinessUserRequestSuccess,
  BusinessRequestTrigger,
  createBusinessFormSuccess,
  createBusinessFormTrigger,
  createBusinessFormFail,
  giveApprovalSuccess,
  giveApprovalTrigger,
  giveApprovalFail,
  userDashboardListSuccess,
  storeAuthSuccess,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,

  setDisplayedCurrencies,
  setgoogleAuthSuccess,
  setgoogleAuthLoading,
  setConfirmcodeModalError,
  setChangePasswordMessageClear,
  removeProfileImageTrigger,
  removeProfileImageFailure,
  forgotPasswordErrorRemove,
  setMarketRatesCurrencies,
  setDashboardCurrencies,

  getAddressBookingsTrigger,
  getAddressBookingsSuccess,
  getAddressBookingsFailure,
  addAddressBookingTrigger,
  addAddressBookingSuccess,
  addAddressBookingFailure,
  editAddressBookingTrigger,
  editAddressBookingSuccess,
  editAddressBookingFailure,
  deleteAddressBookingTrigger,
  deleteAddressBookingSuccess,
  deleteAddressBookingFailure,
  storeMyBalanceSuccess,
  storeMyBalanceFailure,
  storePreferredCurrencySuccess,
  clearResetPopupErrorSuccess,

  openBusinessForm,
  closeBusinessForm,

  allNfcCardLoadingStart,
  allNfcCardSuccess,
  allNfcCardFail,

  createNfcCardLoadingStart,
  createNfcCardSuccess,
  createNfcCardFailed,

  listNfcCardLoadingStart,
  listNfcCardSuccess,
  listNfcCardFailed,
} = userslice.actions;
