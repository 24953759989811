import { createSlice } from "@reduxjs/toolkit";

const paymentlinkslice = createSlice({
  name: "paymentlink",
  initialState: {
    createRoundLoading: false,
    getroundLoading: false,
    idorounds: [],
    paymentReportCallLoading: false,
    paymentReportCallError: undefined,
    paymentReports:[],
    paymentCount: undefined,
  },
  reducers: {
    createPaymentSuccess: (state, action) => {
      return {
        ...state,
        paymentToken: action.payload,
        createPaymentLoading: false,
      };
    },
    createPaymentTrigger: (state, action) => {
      return {
        ...state,
        createPaymentLoading: action.payload,
      };
    },
    createPaymentFailure: (state, action) => {
      return {
        ...state,
        createPaymentFail: action.payload,
      };
    },
    createFiatPaymentSuccess: (state, action) => {
      return {
        ...state,
        paymentToken: action.payload,
        createPaymentLoading: false,
      };
    },
    createFiatPaymentTrigger: (state, action) => {
      return {
        ...state,
        createPaymentLoading: action.payload,
      };
    },
    createFiatPaymentFailure: (state, action) => {
      return {
        ...state,
        createPaymentFail: action.payload,
      };
    },
    getpaymenthistoryFailure: (state, action) => {
      return {
        ...state,
        paymenthistoryFail: action.payload,
      };
    },
    getpaymenthistorySuccess: (state, action) => {
      return {
        ...state,
        paymenthistory: action.payload,
      };
    },
    getpaymenthistoryDownloadSuccess : (state, action) => {
      return{
        ...state , 
        paymentHistoryDownload : action.payload,
      };
    },
    getpaymenthistoryTrigger: (state, action) => {
      return {
        ...state,
        paymenthistoryLoading: action.payload,
      };
    },
    getpaymenthistoryCountSuccess: (state, action) => {
      return {
        ...state,
        getpaymenthistoryCount: action.payload,
      };
    },
    getPaymentbytokenLinkSuccess: (state, action) => {
      return {
        ...state,
        paymentbytoken: action.payload,
      };
    },
    getcommissionPercentageSuccess: (state, action) => {
      return {
        ...state,
        commissionPercentage: action.payload,
      };
    },
    cancelPaymentStart: (state, action) => {
       return {
        ...state,
        cancelPaymentLoading: true,
      };
    },
    cancelPaymentSuccess: (state, action) => {
       return {
        ...state,
        cancelPaymentLoading: false,
        cancelPaymentSuccess: "Sucessfully Cancelled",
      };
    },
    cancelPaymentFailure: (state, action) => {
       return {
        ...state,
        cancelPaymentLoading: false,
        cancelPaymentFailure: action.payload,
      };
    },
    getcommissionSuccess: (state, action) => {
      return {
       ...state,
       cancelPaymentLoading: false,
       commission: action.payload,
     };
   },
    clearPaymentLinkReduxSuccuss: (state, action) => {
      return {};
    },

    paymentReportTrigger:(state,action)=>{
       return {
         ...state,
         paymentReportCallLoading: true,
         paymentReportCallError: undefined
       }
    },
    paymentReportSucess: (state,action) => {
      return{
        ...state,
        paymentReportCallLoading: false,
        paymentReports: action.payload,
        paymentReportCallError: undefined
      }
    },

    paymentReportFailure:(state,action) => {
      return {
        ...state,
        paymentReportCallLoading: false,
         paymentReportCallError: action.payload
      }
    },

    paymentCountSave: (state,action) => {
      return {
        ...state,
        paymentCount: action.payload
      }
    },
    clearPaymentLinkError: (state, action) => {
      return{
        ...state,
        paymentReportCallError:"",
        cancelPaymentFailure:"",
        createPaymentFail:"",
        paymenthistoryFail:"",
        createPaymentFail:"",

      };
    },
  },
});
export default paymentlinkslice.reducer;

export const {
  createPaymentFailure,
  createPaymentTrigger,
  createPaymentSuccess,
  getpaymenthistoryFailure,
  getpaymenthistorySuccess,
  getpaymenthistoryTrigger,
  getpaymenthistoryCountSuccess,
  getPaymentbytokenLinkSuccess,
  getcommissionPercentageSuccess,
  clearPaymentLinkReduxSuccuss,
  cancelPaymentStart,
  cancelPaymentSuccess,
  cancelPaymentFailure,
  createFiatPaymentFailure,
  createFiatPaymentSuccess,
  createFiatPaymentTrigger,
  getcommissionSuccess,
  paymentReportTrigger,
  paymentReportSucess,
  paymentReportFailure,
  paymentCountSave,
  clearPaymentLinkError,
  getpaymenthistoryDownloadSuccess,
} = paymentlinkslice.actions;
