import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import CreatePaylinkpopup from "@components/create-paylinkpopup/CreatePaylinkpopup";
import CreatePayment from "@components/create-payment/CreatePayment";
import "@features/create-payment-link/CreatePaymentLink.scss";
import CustomButton from "@components/custom-button/CustomButton";
import UploadPopup from "@components/upload-popup/UploadPopup";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "@components/footer-common/FooterCommon";
import PrintPopup from "@components/print-popup/PrintPopup";
import { useReactToPrint } from 'react-to-print';



function CreatePaymentLink({
  getplatformTokensinfo,
  plaformcurrencies,
  getcomissionper,
  settings,
  commissionPercentage,
  getExchangerate,
  getFiatExchangerate,
  FiatexchangeRates,
  exchangeRates,
  createfiatpayment,
  paymentbytoken,
  myaccountandbalance,
  setPaymentlinkSucc,
  paymentlinksuccmodal,
  paymentToken,
  createloaders,
  thememode,
  hideLoaderOnRefresh
}) {
  const { t } = useTranslation();
  let [show, setshow] = useState(false);
  let [show2, setshow2] = useState(false);
  let [showi, setshowi] = useState(false);
  let [currencyToReceive,setCurrencyToReceive] = useState('');
  let [currencyToConvert,setCurrencyToConvert] = useState('');
  let [paymentByToken , setPaymentByToken] = useState("");
  let activetokens = [];
  getplatformTokensinfo &&
    getplatformTokensinfo.map((items) => {
      if (items.transferStatus != "Disabled") activetokens.push(items);
    });
  const componentRef = useRef();
  
  useEffect(()=>{
    setPaymentByToken(paymentbytoken);
  } , [paymentbytoken])

  return (
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh} >
      <div id="createpaymentlink">
        <Container className="containerallignm">
          <Row>
            <Col lg={12}>
              <CreatePayment
                setCurrencyToReceive={setCurrencyToReceive}
                setCurrencyToConvert={setCurrencyToConvert}
                getplatformTokensinfo={activetokens}
                plaformcurrencies={plaformcurrencies}
                getcomissionper={getcomissionper}
                settings={settings}
                commissionPercentage={commissionPercentage}
                getExchangerate={getExchangerate}
                FiatexchangeRates={FiatexchangeRates}
                exchangeRates={exchangeRates}
                getFiatExchangerate={getFiatExchangerate}
                createfiatpayment={createfiatpayment}
                myaccountandbalance={myaccountandbalance}
                createloaders={createloaders}
                paymentbytoken={paymentbytoken}
                paymentToken={paymentToken}
              />
              <CreatePaylinkpopup
                currencyToReceive={currencyToReceive}
                currencyToConvert={currencyToConvert}
                setShowModal={setPaymentlinkSucc}
                setshowi={setshowi}
                paymentbytoken={paymentByToken}
                paymentToken={paymentToken}
                show1={paymentlinksuccmodal}
                showi={showi}
                setshow2={(e) => setshow2(e)}
                componentRef={componentRef}
               
              />
              <UploadPopup
                setshow2={setshow2}
                show2={show2}
                setShowModal={setshow}
                setShowCreateLink={setPaymentlinkSucc}
                paymentbytoken={paymentbytoken}
                paymentToken={paymentToken}
                thememode={thememode}
                settings={settings}
              />

             


{/* 
              <PrintPopup
                setShowModal={setshow}
                showi={showi}
                setshowi={setshowi}
                paymentbytoken={paymentbytoken}
                paymentToken={paymentToken}
                thememode={thememode}
                settings={settings}
                ref={componentRef}
              /> */}
              {/* <div className="button-holder">
                  <CustomButton
                    type="submit"
                    name={t("create")}
                    filled={true}
                    primary={true}
                    onClick={() => {
                      setshow(true);
                    }}
                  />
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}

const mapStateToProps = ({
  app = {},
  wallet = {},
  paymentlink = {},
  translationLanguage = {},
}) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const paymenthistory = _.get(paymentlink, "paymenthistory", []);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const myusertransactionloading = _.get(
    paymentlink,
    "paymenthistoryLoading",
    false
  );
  const getpaymenthistoryCount = _.get(
    wallet,
    "getpaymenthistoryCount.count",
    10
  );
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const FiatexchangeRates = _.get(wallet, "FiatexchangeRates", 1);
  const paymentlinkmodal = _.get(app, "paymentlinkmodal", false);
  const paymentlinksuccmodal = _.get(app, "paymentlinksuccmodal", false);
  const paymentToken = _.get(paymentlink, "paymentToken", false);
  const createloaders = _.get(paymentlink, "createPaymentLoading", false);
  const paymentbytoken = _.get(paymentlink, "paymentbytoken", false);
  const commissionPercentage = _.get(
    paymentlink,

    "commissionPercentage[0]",
    false
  );
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const currentLang = _.get(translationLanguage, "language", false);
  return {
    thememode,
    settings,
    mywalletsinfo,
    paymenthistory,
    myaccountandbalance,
    getpaymenthistoryCount,
    myusertransactionloading,
    getplatformTokensinfo,
    exchangeRates,
    paymentlinkmodal,
    paymentlinksuccmodal,
    paymentToken,
    createloaders,
    paymentbytoken,
    commissionPercentage,
    currentLang,
    plaformcurrencies,
    FiatexchangeRates,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),
  getFiatExchangerate: (data) =>
    dispatch({ type: "getFiatExchangeratecalled", payload: data }),
  createfiatpayment: (data) =>
    dispatch({ type: "FiatPaymentLink", payload: data }),
  setPaymentlink: (data) =>
    dispatch({ type: "setPaymentlinkCalled", payload: data }),
  setPaymentlinkSucc: (data) =>
    dispatch({ type: "setPaymentlinkSuccCalled", payload: data }),
  getcomissionper: (data) =>
    dispatch({ type: "getcomissionperCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaymentLink);
