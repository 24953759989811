import { Row, Col, Container } from "react-bootstrap";
import "@components/market-rates/MarketRates.scss";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { ReactComponent as GraphIncreased } from "@images/graphincreased.svg";
import eye from "@images/eye.svg";
import { useTranslation } from "react-i18next";
import { ResponsiveLine } from "@nivo/line";
import { ReactComponent as REDGraph } from "@images/redgraph.svg";
import { getCSymbol, getESymbol } from "src/utils/Currencies";
import BigNumber from "bignumber.js";

function MarketRatesComponent({
  bgcolor,
  logo,
  coinName,
  symbol,
  balance,
  percentChanges,
  chartPoinst,
  min,
  max,
  marketlink,
  fiatrate,
  PreferredCurrency,
}) {
  const [prctChg, setPrctChg] = useState("");
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setCurrentWidth(window.innerWidth);
  });

  useEffect(() => {
    if (percentChanges !== undefined && percentChanges !== "undefined") {
      setPrctChg(percentChanges);
    }
  }, [percentChanges]);
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="marketratess">
      <div className="coindetailssection">
        <div className="coinlogosection">
          <div
            className="coinimagewrapper"
            style={{ backgroundColor: bgcolor }}
          >
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="coinnameandsymbol">
          <p className="coinsymbol">{symbol}</p>
          <p className="coinname">{coinName}</p>
        </div>
      </div>
      {/*<div>
             {
                currentWidth <= 992 ? <div className="" className={`${prctChg.includes("-") ? 'redgraph' : 'greengraph'} coingraphsection`}>
                {chartPoinst && (
                    <ResponsiveLine
                    data={[
                        {
                        id: "japan",
                        color: "hsl(295, 70%, 50%)",
                        data: chartPoinst || [],
                        },
                    ]}
                    enableGridY={false}
                    keys={["close"]}
                    margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
                    yScale={{
                        type: "linear",
                        min: 'auto',
                        max: 'auto',
                        stacked: false,
                        reverse: false,
                    }}
                    enableArea={true}
                    useMesh={false}
                    curve="basis"
                    enablePoints={false}
                    enableCrosshair={false}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    isInteractive={false}
                    axisLeft={null}
                    enableStackTooltip={false}
                    enableGridX={false}
                    curve="cardinal"
                    offsetType="none"
                    colors={{ scheme: "paired" }}
                    borderColor={"#637EEB"}
                    borderWidth={1}
                    dotSize={0}
                    dotColor={{ from: "color" }}
                    dotBorderWidth={1}
                    dotBorderColor={{
                        from: "color",
                        modifiers: [["darker", 0.7]],
                    }}
                    animate={true}
                    />
                )}
                </div> : <div className="rateandpercentchange">
                {
                    balance !== undefined &&
                    <p className="coinrate">${Number(balance).toFixed(4)}</p>
                }
                {
                    prctChg &&
                    <div className="percentchangedetails">
                    {prctChg.includes("-") ? (
                        <REDGraph className="graphincreaseicon" />
                    ) : (
                        <GraphIncreased className="graphincreaseicon" />
                    )}

                    <span
                        className={`coinpercentchange ${prctChg.includes("-") ? "redtext" : "greentext"
                        }`}
                    >
                        {prctChg}%
                    </span>
                    <span className="thisweek">{t("ThisWeek")}</span>
                    </div>
                }
                </div>

                } 
                </div>*/}

      <div className="rateandpercentchange">
        <div className="d-none d-lg-block">
          {balance !== undefined && (
            <p className="coinrate">
              {getCSymbol(PreferredCurrency)}{" "}
              {symbol == "TRYC"
                ? BigNumber(1)
                    .dividedBy(balance)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[`${getESymbol(PreferredCurrency)}`]) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)
                : BigNumber(balance)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[`${getESymbol(PreferredCurrency)}`]) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)}
            </p>
          )}
          {prctChg && (
            <div className="percentchangedetails">
              {prctChg.includes("-") ? (
                <REDGraph className="graphincreaseicon" />
              ) : (
                <GraphIncreased className="graphincreaseicon" />
              )}

              <span
                className={`coinpercentchange ${
                  prctChg.includes("-") ? "redtext" : "greentext"
                }`}
              >
                {prctChg}%
              </span>
              {/* <span className="thisweek">{t("ThisWeek")}</span> */}
            </div>
          )}
        </div>
        <div className="d-block d-lg-none">
          {prctChg && (
            <div className="percentchangedetails">
              {prctChg.includes("-") ? (
                <REDGraph className="graphincreaseicon" />
              ) : (
                <GraphIncreased className="graphincreaseicon" />
              )}

              <span
                className={`coinpercentchange ${
                  prctChg.includes("-") ? "redtext" : "greentext"
                }`}
              >
                {prctChg}%
              </span>
              <span className="thisweek">{t("ThisWeek")}</span>
            </div>
          )}
          {balance !== undefined && (
            <p className="coinrate">${Number(balance).toFixed(4)}</p>
          )}
        </div>
      </div>

      <div
        className={`${
          prctChg.includes("-") ? "redgraph" : "greengraph"
        } coingraphsection`}
      >
        {chartPoinst && (
          <ResponsiveLine
            data={[
              {
                id: "japan",
                color: "hsl(295, 70%, 50%)",
                data: chartPoinst || [],
              },
            ]}
            enableGridY={false}
            keys={["close"]}
            margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            enableArea={true}
            useMesh={false}
            curve="basis"
            enablePoints={false}
            enableCrosshair={false}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            isInteractive={false}
            axisLeft={null}
            enableStackTooltip={false}
            enableGridX={false}
            curve="cardinal"
            offsetType="none"
            colors={{ scheme: "paired" }}
            borderColor={"#637EEB"}
            borderWidth={1}
            dotSize={0}
            dotColor={{ from: "color" }}
            dotBorderWidth={1}
            dotBorderColor={{
              from: "color",
              modifiers: [["darker", 0.7]],
            }}
            animate={true}
          />
        )}
      </div>
      <a className="eyeflexwrapper" href={marketlink} target={"_blank"}>
      {/* <div className="eyeiconwrapper" > */}
        <img src={eye} alt="" ></img>
      {/* </div> */}
      </a>
    </div>
  );
}
export default MarketRatesComponent;
