export default {
  All: "Все",
  Received: "Полученные",
  Sent: "Отправленные",
  Deposits: "Пополнения",
  Withdrawals: "Выводы",
  Captured: "Завершенные",
  Pending: "Ожидающие",
  Cancelled: "Отмененные",
  Transfer: "Перевод",
  Round: "Раунд",
  Crypto: "Крипто",
  Fiat: "Фиат",
  Rejected: "Отклонено",
  Approved: "Подтверждено",
  Business: "Бизнес",
  Personal: "Личный",
  "Business": "Бизнес",
  "Personal": "Личный",
  MyProfile: "Мой профиль",
  Myaccounts: "Мои аккаунты",
};
