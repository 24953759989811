import * as _ from "lodash";
import React, { useEffect } from "react";
import "./PersonalLoanRequestDetailPopup.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
import { useTranslation } from "react-i18next";

const PersonalLoanRequestDetailPopup = ({ setShowModal, show1, data, setData }) => {
  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="PersonalLoanRequestDetailPopup"
      show={show1}
      // onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      <div
        className=" back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
        <div className="transectiontype">
          <span className="transectiontext uppercase">
            {t(`Bank Loan Request Details`)}{" "}
          </span>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Request Status")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {/* {data !== "" && data.adminApproval === true
                  ? "Approved"
                  : data !== "" && data.sataus === "Rejected"? "Rejected"
                  : data.sataus
                  } */}
                  {data !=="" && data.status}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Borrowed Amount")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.borrowedLoanAmount !== undefined &&
                  `${data.borrowedLoanAmount} ${data.personalLoanRequestCurrency.currencyName
                  }`}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Interest Rate %")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.borrowedInterestRate !== undefined &&
                  data.borrowedInterestRate !== undefined && data.borrowedInterestRate !== undefined &&
                  `${data.borrowedInterestRate}`}
              </span>
            </div>
          </div>
          {data !== undefined && data.user !== undefined && (data.user.name !== null) &&
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Borrower Name")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" && data.user !== undefined &&
                  `${data.user.name} ${data.user.subName !== null && data.user.subName}`  }
              </span>
            </div>
          </div>
}
          <div className="detail">
            <div className="label">

              <span className="labeltext">{t("Loan Name")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.borrowedLoan !== undefined &&
                  data.borrowedLoan.loanName !== undefined &&
                  `${data.borrowedLoan.loanName}`}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Tenure (In Months)")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.borrowedLoan !== undefined &&
                  data.borrowedLoan.tenureInMonths !== undefined &&
                  data.borrowedLoan.tenureInMonths}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Requested Date")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  (data.createdOn).substring(0,10)}
              </span>
            </div>
          </div>
          <div className="confirm">
            <CustomButton
              type="button"
              name={t("Close")}
              filled={true}
              primary={true}
              onClick={() => {
                setData("");
                setShowModal(false);
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(PersonalLoanRequestDetailPopup);
