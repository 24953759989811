import React from "react";
import "@components/video-card/VideoCardStyles.scss";
const VideoCard = ({}) => {
  return (
    <div className="videocard">
      <div className="imgholder">
        <iframe
          className="img"
          src="https://www.youtube.com/embed/9HaU8NjH7bI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>{" "}
      </div>
    </div>
  );
};
export default React.memo(VideoCard);
