import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { connect } from "react-redux";
import { Container, Modal, Row, Col } from "react-bootstrap";
import "@components/delete-confirmationpopup/DeleteConfirmationPopupStyles.scss";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DeleteConfirmationPopup = ({
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  onPress,
  onCancelled,
  allBranchesLoading,
}) => {
  useEffect(() => {
    onCancelled();
  }, [allBranchesLoading]);

  const { t, i18n, ready } = useTranslation();


  return (
    <Modal
      className="deleteconfirmationpopup"
      show={showDeleteConfirmation}
      onHide={() => setShowDeleteConfirmation(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <span className="deletetitle">{t("DeleteConfirmation")}</span>
        </Modal.Title>
        <CloseIcon
          className="closebtn"
          onClick={() => setShowDeleteConfirmation(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="deletetext">
                {t("Doyouwanttodeletethisbranch")} ?
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col lg={6} sm={6} xs={6}>
            <div className="cancel">
              <CustomButton
                type="submit"
                name={t("Cancel")}
                filled={true}
                primary={true}
                onClick={onCancelled}
              />
            </div>
          </Col>
          <Col  lg={6} sm={6} xs={6}>
            <div className="confirm">
              <CustomButton
                type="submit"
                name={t("Confirm")}
                filled={true}
                primary={true}
                onClick={onPress}
              />
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ branchmanagementredux = {} }) => {
  const allBranchesLoading = _.get(
    branchmanagementredux,
    "listAllBranchesLoading",
    false
  );
  return {
    allBranchesLoading,
  };
};

export default connect(mapStateToProps)(DeleteConfirmationPopup);
