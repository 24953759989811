import React from "react";
import "@components/cybor-tokenomics/CyborTokenomicsStyles.scss";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../custom-button/CustomButton";
import { ReactComponent as Download } from "@images/downloadwhitepaper.svg";
import year66 from "@images/year66.svg";
import year11 from "@images/year11.svg";
import { useTranslation } from "react-i18next";
import Table from "react-bootstrap/esm/Table";
import { connect } from "react-redux";
import { ReactComponent as TokenCybor } from "@images//tokencybor.svg";
import cyborLogo from '@images/cyborcoinlogoforgraph.png'
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from '@nivo/bar'
import { char1data, chartdata3, char1data2, listdata, distributiondata, distributionforsix, tokencybor, chart3singledata } from '../../utils/IdoData.js';

const CyborTokenomics = ({ thememode }) => {
   const lang = localStorage.getItem("lang");

  const { t } = useTranslation();
  return (
    <div
      id="cyborTokenomics"
      className={`${thememode == "light" ? "idoinlight" : "idoindark"}`}
    >
      <div className="tokenomicswrapper">
        <p>{t("Tokenomics")}</p>
      </div>
      <div className="tokenomicsContentWrap">
        <Row>
          <Col xl={6} lg={12}>
            <div className="listContentwrapper">
              {listdata.map((data) => (
                <div className="listWrapeer">
                  <div className="listDot" />
                  <div className="listContent">
                    <p>{t(`${data.line1}`)}</p>
                    <div className="listUnderlined"></div>
                  </div>
                  <div className="listBoldContent">
                    <p>{t(`${data.line2}`)}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={8} xl={6} className="mt-lg-3 mt-xl-0">
            <div className="btnsinido">
              <div className={`d-flex idoWhiteBtns`}>
                <a
                  href={
                    lang == "en"
                      ? "https://cyborcoin.com/data/main/docs/white_paper_en.pdf"
                      : lang == "ru"
                      ? "https://cyborcoin.com/data/main/docs/white_paper_ru.pdf"
                      : "https://cyborcoin.com/data/main/docs/white_paper_tr.pdf"
                  }
                  target="_blank"
                >
                  <CustomButton
                    name={t("OpenWhitepapers")}
                    filled={true}
                    primary={false}
                    className="buttonmain"
                    onClick={() => {}}
                  />
                </a>
                <a
                  href={
                    lang == "en"
                      ? "https://cyborcoin.com/data/main/docs/tokenomics_en.pdf"
                      : lang == "ru"
                      ? "https://cyborcoin.com/data/main/docs/tokenomics_ru.pdf"
                      : "https://cyborcoin.com/data/main/docs/tokenomics_tr.pdf"
                  }
                  target="_blank"
                >
                  <CustomButton
                    name={t("OpenTokenomics")}
                    filled={true}
                    primary={false}
                    className="buttonmain"
                    onClick={() => {}}
                  />
                </a>
              </div>
              <div className="d-flex idoBlueBtns">
                <a
                  href={
                    lang == "en"
                      ? "https://cyborcoin.com/data/main/docs/white_paper_en.pdf"
                      : lang == "ru"
                      ? "https://cyborcoin.com/data/main/docs/white_paper_ru.pdf"
                      : "https://cyborcoin.com/data/main/docs/white_paper_tr.pdf"
                  }
                  target="_blank"
                >
                  <CustomButton
                    name={
                      <>
                        <Download />
                        <span className="ms-1">{t("DownloadWhitepapers")}</span>
                      </>
                    }
                    filled={true}
                    primary={true}
                    className="buttonmain"
                  />
                </a>
                <a
                  href={
                    lang == "en"
                      ? "https://cyborcoin.com/data/main/docs/tokenomics_en.pdf"
                      : lang == "ru"
                      ? "https://cyborcoin.com/data/main/docs/tokenomics_ru.pdf"
                      : "https://cyborcoin.com/data/main/docs/tokenomics_tr.pdf"
                  }
                  target="_blank"
                >
                  <CustomButton
                    name={
                      <>
                        <Download />
                        <span className="ms-1">{t("DownloadTokenonmics")}</span>
                      </>
                    }
                    filled={true}
                    primary={true}
                    className="buttonmain"
                    onClick={() => {}}
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        {/* ******************section 2********************** */}
        <div className="tableandBoldtext">
          <p className="section2Headtext">
            {t("TotalBlockedTokens")}: 600 000 000 CYBOR
          </p>
          <p className="section2Headtext">
            {t("TokenUnlockingPeriod")}: 6 {t("years")}
          </p>
        </div>
        <Table
          responsive="sm"
          className="tablestyle specialforbordercolor"
          borderless
        >
          <thead>
            <tr>
              <th>{t("FirstYear")}</th>
              <th>{t("SecondYear")}</th>
              <th>{t("ThirdYear")}</th>
              <th>{t("FourthYear")}</th>
              <th>{t("FifthYear")}</th>
              <th>{t("SixthYear")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="oddTrStyle">
              <td>400 000 000</td>
              <td>200 000 000</td>
              <td>150 000 000</td>
              <td>150 000 000</td>
              <td>50 000 000</td>
              <td>50 000 000</td>
            </tr>
          </tbody>
        </Table>
        {/* ********************section 4***************** */}
        <div className="distribution">
          <p className="distributionheading">{t("DistributionOfCYBOR")}</p>
          <p classname="normalheading">
            {t("TotalNoCoinAvlb")}: <b>40 000 000</b>
          </p>
          <Row className="mt-4 tableNchart">
            <Col lg={12} xl={12} xxl={8} className={`tablecol ${(lang == "ru"||lang == "tr")&&'tablecol2'} `}>
              <div>
                <Table
                  responsive="sm"
                  className="tablestyle texttostart bodybordertoremove "
                  borderless
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t("Percent")}</th>
                      <th>{t("QualityTokens")}</th>
                      <th>{t("Priceofonetoken")}</th>
                      <th>{t("Totalcosttokens")}</th>
                      <th>{t("Blockingperiod")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {distributiondata.map((data, index) => (
                      <tr
                        className={`${index % 2 == 0 && "oddTrStyle"}`}
                        key={index + "dist"}
                      >
                        <td className="fontbold">{t(`${data.investors}`)}</td>
                        <td>{t(`${data.percent}`)}</td>
                        <td>{data.tokens}</td>
                        <td>{data.onetoken}</td>
                        <td>{data.costtokens}</td>
                        <td>{t(`${data.blocking}`)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col lg={12} xl={12} xxl={4} className="graphcol">
              <div className="chartWrapper">
                {/* Diagram Area */}
                {/* <img src={year66} className="pieimg" /> */}
                <div className="chartnlogowrap">
                <ResponsivePie
                  data={char1data}
                  margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
                  valueFormat="<-0.0p"
                  innerRadius={0.6}
                  padAngle={2}
                  cornerRadius={4}
                  activeOuterRadiusOffset={2}
                  borderWidth={1}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#fff"
                  arcLinkLabelsThickness={2}
                  enableArcLabels={true}
                  isInteractive={false}
                  enableArcLinkLabels={false}
                  arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                  }}
                  colors={[
                    "#09154E",
                    "#2969BA",
                    "#59C39B",
                    "#6ADC7F",
                    "#6ADC7F",
                    "#3A89C0",
                    "#0D1F73",
                    "#17469F",
                  ]}
                  fill={[
                    {
                      match: {
                        id: "ETH",
                      },
                      id: "dots",
                    },
                  ]}
                >
                </ResponsivePie>
                <img className="cyborlogoingraph" src={cyborLogo} alt="cybor logo" />
                </div>
                <div className="labels">
                  {
                    char1data.map((data)=>(
                      <div key={data.id} className="d-flex mb-2 align-items-center text-start">
                        <div className="colorarea me-2" style={{backgroundColor: data.color}} ></div>
                        <p>{t(`${data.label}`)}</p>
                      </div>
                    ))
                  }
                </div>
                
              </div>
            </Col>
          </Row>
        </div>
        {/* ******************* Section 5 ******************* */}
        <div className="distribution distributionSection5">
          <p className="distributionheading">{t("DistributionForSixYears")}</p>
          <Row className="mt-4 tableNchart">
            <Col lg={12} xl={12} xxl={8} className="tablecol">
              <div>
                <Table
                  responsive="sm"
                  className="tablestyle texttostart bodybordertoremove"
                  borderless
                >
                  <thead>
                    <tr>
                      {/* <th>
                        <input type="checkbox" name="" id="" checked />
                      </th> */}
                      <th>{t("Year")}</th>
                      <th>{t("Users")}</th>
                      <th>{t("Marketing")}</th>
                      <th>{t("Partners")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {distributionforsix.map((data, index) => (
                      <tr
                        className={`${index % 2 == 0 && "oddTrStyle"}`}
                        key={index + "dist"}
                      >
                        {/* <td className="fontbold">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={data.checked}
                          />
                        </td> */}
                        <td
                          className={`${
                            data.checked == true
                              ? "checkedyear"
                              : "uncheckedyear"
                          }`}
                        >
                          {t(`${data.year}`)}
                        </td>
                        <td>
                          <span>{data.users.amt}</span>{" "}
                          <p>{data.users.percent}</p>
                        </td>
                        <td>
                          <span>{data.marketing.amt}</span>{" "}
                          <p>{data.marketing.percent}</p>
                        </td>
                        <td>
                          <span>{data.partners.amt}</span>{" "}
                          <p>{data.partners.percent}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col lg={12} xl={12} xxl={4} className="graphcol">
              <div className="chartWrapper">
                <div className="toppie toptxt">
                  <span>{t("tokensissuedsixyears")}</span>
                </div>
                {/* Diagram Area */}
                {/* <img src={year66} className="pieimg" /> */}
                <div className="chartnlogowrap">
                <ResponsivePie
                  data={char1data2}
                  margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
                  valueFormat="<-0.0p"
                  innerRadius={0.6}
                  padAngle={2}
                  cornerRadius={4}
                  activeOuterRadiusOffset={2}
                  borderWidth={1}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#fff"
                  arcLinkLabelsThickness={2}
                  enableArcLabels={true}
                  isInteractive={false}
                  enableArcLinkLabels={false}
                  arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                  }}
                  colors={[
                    "#2969BA",
                    "#59C39B",
                    "#6ADC7F"
                  ]}
                  fill={[
                    {
                      match: {
                        id: "ETH",
                      },
                      id: "dots",
                    },
                  ]}
                >
                </ResponsivePie>
                <img className="cyborlogoingraph1" src={cyborLogo} alt="cybor logo" />
                </div>
                <div className="labels">
                  {
                    char1data2.map((data)=>(
                      <div key={data.id} className="d-flex mb-2 align-items-center text-start">
                        <div className="colorarea me-2" style={{backgroundColor: data.color}} ></div>
                        <p>{t(`${data.label}`)}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* section 6 */}
        <div className="tokencybor">
          <p className="distributionheading">{t("TokenCybor")}</p>
          <Row>
            <Col lg={10} xl={7}>
              {tokencybor.map((data) => (
                <div className="listWrapeer">
                  <div className="listDot" />
                  <div className="listContent">
                    <p>{t(`${data.line1}`)}</p>
                    <div className="listUnderlined"></div>
                  </div>
                  <div className="listBoldContent">
                    <p>{t(`${data.line2}`)}</p>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
          {/* graph area */}
          <div className="nivobarwrapper">
          <ResponsiveBar
            data={chartdata3}
            keys={[
                'seedround',
                'privatesale1',
                'privatesale2',
                'publicsale',
                'liquidity',
                'team',
                'marketing',
                'partners',
            ]}
            indexBy="country"
            margin={{ top: 50, right: 0, bottom: 50, left: 30 }}
            padding={0.3}
            innerPadding={1}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={[
              "#060E33",
              "#0D1F73",
              "#17469F",
              "#2969BA",
              "#3A89C0",
              "#4AA7B3",
              "#59C39B",
              "#6ADC7F",
            ]}
            enableGridX={true}
            enableLabel={false}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: {
                        id: 'fries'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'sandwich'
                    },
                    id: 'lines'
                }
            ]}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            role="application"
            isInteractive={true}
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
          />
          </div>
          <div className="labels incybortoken">
            {
              chart3singledata.map((data,i)=>(
                <div key={i} className="d-flex mb-2 align-items-center text-start singledata">
                  <div className="colorarea me-2" style={{backgroundColor: data.color}} ></div>
                  <p>{t(`${data.name}`)}</p>
                </div>
              ))
            }
          </div>
          {/* graph area ends */}
        </div>
        {/* section 7 */}
        <div className="saletokencybor">
          <p className="distributionheading">{t("SaleoftokensCYBOR")}</p>
          <Row>
            <Col lg={6}>
              <Table
                responsive="sm"
                className="tablestyle texttostart"
                borderless
              >
                <thead>
                  <tr>
                    <th>{t("Saleoftokens")}</th>
                    <th>{t("Percent")}</th>
                    <th>{t("Blockingperiod")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="oddTrStyle">
                    <td>{t("ClosedroundNo1")}</td>
                    <td>0.15$</td>
                    <td>
                      <span>6 {t("Months")}</span>
                      <span className="lighttext">180 {t("Days")}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("ClosedroundNo2")}</td>
                    <td>0.45$</td>
                    <td>
                      <span>6 {t("Months")}</span>
                      <span className="lighttext">180 {t("Days")}</span>
                    </td>
                  </tr>
                  <tr className="oddTrStyle">
                    <td>{t("ClosedroundNo2")}</td>
                    <td>0.60$</td>
                    <td>
                      <span>3 {t("Months")}</span>
                      <span className="lighttext">90 {t("Days")}</span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={6}>
              <Table
                responsive="sm"
                className="tablestyle texttostart"
                borderless
              >
                <tbody>
                  <tr>
                    <td>{t("costof1tokenonthedateoflisting")}</td>
                    <td>
                      <b>1$</b>
                    </td>
                  </tr>
                  <tr className="oddTrStyle">
                    <td>{t("Termofblockingonthestockexchange")}</td>
                    <td>
                      <b>3 {t("Months")}</b>
                      <span className="lighttext">90 {t("Days")}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("minimumamounttopurchasetokens")}</td>
                    <td>
                      <b>1000$</b>
                    </td>
                  </tr>
                  <tr className="oddTrStyle">
                    <td>{t("maximumamountofpurchasetokens")}</td>
                    <td>
                      <b>{t("notlimited")}</b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        {/* section 8 */}
        <div className="bonus">
          <p className="bonusHeading">{t("Bonusrewardforparticipation")}</p>
          <p className="bonusHeading mb-2">
            {t("Bonusrewardfortokenblocking")}
          </p>
          <Table responsive="sm" className="tablestyle texttostart" borderless>
            <thead>
              <tr>
                <th>
                  6 {t("Months")} (180 {t("Days")}){" "}
                  {t("byparticipatinginround")} №1
                </th>
                <th>
                  6 {t("Months")} (180 {t("Days")}){" "}
                  {t("byparticipatinginround")} №2
                </th>
                <th>
                  3 {t("Months")} (90 {t("Days")}) {t("byparticipatinginround")}{" "}
                  №3
                </th>
                <th>{t("Totalpercentageofremunerationfor6months")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="oddTrStyle">
                <td>9%</td>
                <td>4%</td>
                <td>1.5%</td>
                <td>9%</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app = {} }) => {
  const thememode = _.get(app, "thememode", false);

  return {
    thememode,
  };
};

export default connect(mapStateToProps, null)(React.memo(CyborTokenomics));
