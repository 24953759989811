import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import CustomButton from "@components/custom-button/CustomButton";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import ScreenLayout from "src/components/layout/ScreenLayout";
import { Row, Col, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import UserDetailsPopup from "@components/users-details-popup/UserDetailsPopup.js";
import Filter from "@components/filter/Filter.js";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import { useTranslation } from "react-i18next";
import ApprovalPopup from "src/components/approval-popup/ApprovalPopup";
import RejectionPopup from "src/components/rejection-popup/RejectionPopup";
import { ReactComponent as PrinterIcon } from "@images/printer.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportSheet } from "react-xlsx-sheet";
import "@features/users-list/UsersListStyles.scss";
import { ClearSansBase64 } from "src/custom-fonts-base64/index";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";

function UsersList(props) {
  const {
    usersList,
    usersListLoading,
    getUsersList,
    setUsersStatus,
    verifyUserWithdrawRequestLoading,
    usersListCount,
    getUsersListCount,
    setUsersStatusLoading,
    hideLoaderOnRefresh,
    thememode,
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("first");
  let [show, setshow] = useState(false);
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [data, setData] = useState({});
  const [show1, setShowApproval] = useState(false);
  const [show2, setShowRejection] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [isFilterSet, setIsFilterSet] = useState(false);
  const [language, setLanguage] = useState("en");
  const [hideSpec, setHideSpec] = useState(true);
  const [compRef, setCompRef] = useState();
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const componentRef = useRef();
  useEffect(() => {
    setCompRef(componentRef);
    filterUserInfoForExcelExport();
  }, []);
  useEffect(() => {
    if (hideSpec === false) {
      setCompRef(componentRef);
      handlePrint();
      setHideSpec(true);
    }
  }, [hideSpec]);

  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });

  useEffect(() => {
    setLanguage(localStorage.lang);
    filterUserInfoForExcelExport();
  }, [localStorage.lang]);

  useEffect(() => {
    getUsersList({
      limit,
      skip: 0,
      role: statusType,
    });
    getUsersListCount({ role: statusType });
  }, []);

  const filterUserInfoForExcelExport = () => {
    let filtered = [];
    let headerList = [];
    if (usersList && usersList.length !== 0) {
      for (let i of usersList) {
        let newOBJ = {
          UserID: i.id,
          Name: i.name + " " + i.subName,

          Email: i.email !== undefined ? i.email : "",
          Phone: i.mobile !== undefined ? i.mobile : "",
          CreatedOn:
            i.createdOn !== undefined
              ? moment(i.createdOn).local().format("MM/DD/YYYY hh:mm A")
              : "",

          Status: t(i.status),
        };

        filtered.push(newOBJ);
      }

      for (let i of Object.keys(filtered[0])) {
        let newOBJ = {
          title: t(i),
          dataIndex: i,
        };
        headerList.push(newOBJ);
      }

      let pack = {
        data: filtered,
        head: headerList,
      };

      setExcelHeader(pack.head);
      setExcelData(pack.data);

      return pack;
    }
    return filtered;
  };

  const generatePdf = () => {
    var doc = new jsPDF();
    doc.addFileToVFS("ClearSans-Regular.ttf", ClearSansBase64);
    doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
    doc.setFont("ClearSans", "Regular");
    var col = [
      `${t("UserID")}`,
      `${t("Name")}`,
      `${t("Email")}`,
      `${t("Phone")}`,
      `${t("CreatedOn")}`,
      `${t("Status")}`,
    ];

    var rows = [];

    if (usersList && usersList.length !== 0) {
      usersList.forEach((element) => {
        var temp = [
          element.id,
          element.name + " " + element.subName,

          element.email,
          element.mobile,
          moment(element.createdOn).local().format("MM/DD/YYYY hh:mm A"),

          t(element.status),
        ];

        rows.push(temp);
      });
    }

    doc.autoTable(col, rows, {
      startY: 5,
      orientation: "landscape",
      // headerStyles: { textColor: 255, halign: "center" },
      // styles: {overflow: 'ellipsize'},
      styles: {
        font: "ClearSans",
        fontSize: 8,
      },
    });
    doc.save(`${t("UsersList")}.pdf`);
  };

  const { t } = useTranslation();

  return (
    <div id="usersListt">
      <UserDetailsPopup show1={show} setShowModal={setshow} data={data} />
      <ScreenLayout
        hideLoaderOnStart={true}
        loaderOnRefresh={hideLoaderOnRefresh}
      >
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("UsersList")}</span>
                  <div className="filtersection">
                    <Filter
                      onApplyDate={(picker) => {
                        setIsFilterSet(true);
                        getUsersList({
                          skip: 0,
                          limit,
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          role: statusType,
                        });
                        getUsersListCount({
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          role: statusType,
                        });
                        setpages(0);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                      oncancel={() => {
                        setStartDate({});
                        setEndDate({});
                        setIsFilterSet(false);
                        getUsersList({
                          limit,
                          skip: 0,
                          role: statusType,
                        });
                        getUsersListCount({ role: statusType });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="rightsection">
                <div className="thethreebutton">
                  <div className="buttoncontainersm">
                    <ReactToPrint
                      trigger={() => (
                        <CustomButton
                          type="submit"
                          name={t("Print")}
                          testvalue={
                            <PrinterIcon className="iconcolor mleft" />
                          }
                          text={true}
                          image={true}
                        />
                      )}
                      content={() => componentRef.current}
                      onBeforeGetContent={() => {
                        orientation: "landscape";
                        setHideSpec(false);
                      }}
                    />
                  </div>

                  <div className="buttoncontainersm">
                    <CustomButton
                      type="submit"
                      name="PDF"
                      testvalue={<PdfIcon className="mleft" />}
                      text={true}
                      image={true}
                      onClick={() => {
                        generatePdf();
                      }}
                    />
                  </div>

                  <div className="buttoncontainersm mr-0">
                    <ExportSheet
                      header={excelHeader}
                      fileName={`download`}
                      dataSource={excelData}
                      xlsx={XLSX}
                    >
                      <CustomButton
                        type="submit"
                        name={t("Excel")}
                        testvalue={<ExcelIcon className="mleft" />}
                        text={true}
                        image={true}
                        onClick={() => {
                          filterUserInfoForExcelExport();
                        }}
                      />
                    </ExportSheet>
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="btmmargin">
                  <CurrencyTab
                    page={"userslist"}
                    changeTab={(e) => {
                      setStartDate({});
                      setEndDate({});
                      setIsFilterSet(false);
                      setpages(0);
                      settableno(e);
                      if (e == "second") {
                        getUsersList({ role: "Personal", limit, skip: 0 });
                        setStatusType("Personal");
                        getUsersListCount({ role: "Personal" });
                      }
                      if (e == "third") {
                        getUsersList({ role: "Business", limit, skip: 0 });
                        setStatusType("Business");
                        getUsersListCount({ role: "Business" });
                      }
                      if (e == "first") {
                        getUsersList({ limit, skip: 0 });
                        setIsFilterSet("");
                        getUsersListCount();
                      }
                      if (e == "fourth") {
                        getUsersList({ role: "Branch", limit, skip: 0 });
                        setStatusType("Branch");
                        getUsersListCount({ role: "Branch" });
                      }
                    }}
                    tableno={tableno}
                  />
                </div>
              </div>

              <Table responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless ref={componentRef}>
                {(usersListLoading || setUsersStatusLoading) && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading">{t("UserID")}</th>
                    <th className="trtbleheading">{t("Name")}</th>
                    <th className="trtbleheading">{t("Email")}</th>
                    <th className="trtbleheading">{t("Phone")}</th>
                    <th className="trtbleheading">{t("CreatedOn")}</th>
                    <th className="trtbleheading">{t("Status")}</th>
                    <th className="trtbleheading thwidth">{t("Action")}</th>
                    <th className="trtbleheading"></th>
                  </tr>
                </thead>

                <tbody>
                  {usersList &&
                    usersList.map((item, index) => (
                      <tr key={`trans${index}`}>
                        <td className="amt">
                          <div className="innerTd idtr ">
                            <span className="id idtxt">{item.id}</span>
                            <p className="hidden-for-desktop date margintop">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </p>
                          </div>
                        </td>

                        <td className="amt">
                          <div className="innerTd idtr">
                            <div className="link">
                              <span className=" numbers ">
                                {item.name + " " + item.subName}
                              </span>
                              <div className="hidden-for-desktop id">
                                <div
                                  className={`link ${
                                    item.status == "active"
                                      ? "activeStatus"
                                      : "disabledStatus"
                                  }`}
                                >
                                  {t(`${item?.status}`)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd hidden-for-mobile ">
                            <div className="link">{item.email}</div>
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd  ">{item.mobile}</div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd  ">
                            {moment(item.createdOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </td>
                        {hideSpec ? (
                          <td className="amt hidden-for-mobile">
                            <div className="innerTd  ">
                              <div
                                className={`link ${
                                  item.status == "active"
                                    ? "activeStatus"
                                    : "disabledStatus"
                                }`}
                              >
                                {t(`${item?.status}`)}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="amt"></td>
                        )}

                        <td>
                          <div className="innerTd hidden-for-mobile ">
                            <div className="btnwidth">
                              {item?.status != "active" && (
                                <div className="approve">
                                  <CustomButton
                                    name={
                                      item?.status == "active"
                                        ? t(`${"Active"}`)
                                        : t(`${"Activate"}`)
                                    }
                                    filled={true}
                                    primary={true}
                                    disable={
                                      item?.status == "active" ? true : false
                                    }
                                    classname={
                                      item?.status == "active"
                                        ? "disabledBtn"
                                        : null
                                    }
                                    onClick={() => {
                                      setData(item);
                                      setShowApproval(true);
                                    }}
                                  />
                                </div>
                              )}
                              {item?.status != "disabled" && (
                                <div className="reject">
                                  <CustomButton
                                    name={
                                      item?.fiatTransactionStatus == "disable"
                                        ? t(`${"Disabled"}`)
                                        : t(`${"Disable"}`)
                                    }
                                    filled={true}
                                    primary={true}
                                    disable={
                                      item?.fiatTransactionStatus == "disable"
                                        ? true
                                        : false
                                    }
                                    classname={
                                      item?.fiatTransactionStatus == "disable"
                                        ? "disabledBtn"
                                        : null
                                    }
                                    onClick={() => {
                                      setData(item);
                                      setShowRejection(true);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        {hideSpec ? (
                          <td className="threedotspace">
                            <div className="innerTd ">
                              <div className="vertical">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="threedot"
                                    id="dropdown-basic"
                                  >
                                    <VerticleIcon className="arrowiconclass hidden-for-mobile" />
                                    <Replacementof3dots
                                      className=" arrow hidden-for-desktop"
                                      onClick={() => {
                                        setData(item);
                                        setshow(true);
                                      }}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="menu">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setData(item);
                                        setshow(true);
                                      }}
                                    >
                                      {t("Details")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="threedotspace"></td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
              {usersListCount > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("from")}{" "}
                      {Math.ceil(usersListCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={usersListCount / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        if (isFilterSet == true) {
                          getUsersList({
                            startDate: startDate,
                            endDate: endDate,
                            skip: e.selected * limit,
                            limit,
                            role: statusType,
                          });
                        } else {
                          getUsersList({
                            skip: e.selected * limit,
                            limit,
                            role: statusType,
                          });
                        }
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}

              <ApprovalPopup
                onClick={() => {
                  setUsersStatus({ id: data.id, status: "active" });
                  setShowApproval(false);
                }}
                show1={show1}
                setShowModal={(e) => setShowApproval(e)}
                msg={t("Doyouwanttoactivatethisuser")}
              />
              <RejectionPopup
                onClick={() => {
                  setUsersStatus({ id: data.id, status: "disabled" });
                  setShowRejection(false);
                }}
                show1={show2}
                setShowModal={(e) => setShowRejection(e)}
                msg={t("Doyouwanttodisablethisuser")}
              />
            </Col>
          </Row>
        </Container>
      </ScreenLayout>
    </div>
  );
}
const mapStateToProps = ({ admin = {}, app = {} }) => {
  const usersList = _.get(admin, "usersList", []);
  const usersListLoading = _.get(admin, "usersListLoading", false);
  const verifyUserWithdrawRequestLoading = _.get(
    admin,
    "verifyUserWithdrawRequestLoading",
    false
  );
  const setUsersStatusLoading = _.get(admin, "setUsersStatusLoading", false);
  const usersListCount = _.get(admin, "usersListCount.count", 0);
  const thememode = _.get(app, "thememode", false);
  return {
    usersList,
    usersListLoading,
    verifyUserWithdrawRequestLoading,
    usersListCount,
    setUsersStatusLoading,
    thememode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsersList: (data) => dispatch({ type: "usersList", payload: data }),
  setUsersStatus: (data) => dispatch({ type: "setUsersStatus", payload: data }),
  getUsersListCount: (data) =>
    dispatch({ type: "usersListCount", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
