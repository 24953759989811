import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { connect } from "react-redux";
import { Container, Modal, Row, Form, Col } from "react-bootstrap";
import InputField from "@components/input-field/InputField";
import "@components/add-bank-account-popup3/AddBankAccountPopup3Styles.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FooterCommon from "@components/footer-common/FooterCommon";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import { Dropdown, FormControl } from "react-bootstrap";
import Accountsuccessful from "@components/account-successful/Accountsuccessful";
import Loader from "@components/loader/Loader";
import { clearBankError } from "@store/fiatredux";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import { removeCurrentBankAccount } from "@store/fiatredux";
import { ReactComponent as Addlogo } from "@images/addlogo.svg";
import editlogo from "@images/editlogo.svg";
import dollar from "@images/dollar.png";
import { clearEditAddError } from "@store/adminredux";

const AddBankAccountPopup2 = ({
  showAddBankConfirmation,

  setShowAddBankConfirmation,
  onPress,
  onCancelledAsync,
  onError,
  fiatBanks,
  getAllBanksCall,
  createBankAccountCall,
  createBankError,
  exchangeId,
  fiatBalance,
  userAccessToken,
  setHideThis,
  createBankAccountLoading,
  createBankAccSuccess,
  clearError,
  plaformcurrencies,
  onSuccessfulBankCreation,
  selectedBankRedux,
  openBankAccountAddModal,
  removeCurrentBankAccountAsync,
  selectedFiatCurrencyInfo,
  setShowDeposit,
  scope,
  setScope,
  setCurrentBankSelected,
  currentBankSelected,
  addAdminBankSuccess,
  addAdminBankError,
  addAdminBankLoading,
  editAdminBank,
  editAdminBankLoading,
  editAdminBankSuccess,
  editAdminBankError,
  clearEditAddErrorAsync,
  editAdminBankImagesLoading,
  thememode,
  editBankImagesAsync,
  editAdminBankImagesError,

  editColouredLogoLoading,
  editColouredLogoError,
  editNonColouredLogoLoading,
  editNonColouredLogoError,
  editNonColouredLogoSuccess,
  editAdminBankImagesSuccess,
  editNonColouredLogoCall,
}) => {
  const { t, i18n, ready } = useTranslation();
  const [selectedBank, setSelectedBank] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [accountHolderName, setAccountHolderName] = useState();
  const [bankName, setBankName] = useState();
  const [valid, setValid] = useState(true);
  const [iban, setIban] = useState();
  const [swift, setSwift] = useState();
  const [ifsc, setIfsc] = useState();
  const [bankOptions, setBankOptions] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [goBack, setGoBack] = useState(true);
  let [Selected, setSelectedIndex] = useState("");
  let [supportedCurrency, setSupportedCurrencies] = useState();
  let [selectedCurrency, setSelectedCurrency] = useState(
    plaformcurrencies && plaformcurrencies[0]
  );
  let [showSuccesful, setShowSuccessful] = useState(false);
  let [showDropDownError1, setShowDropDownError1] = useState(false);
  let [showDropDownError2, setShowDropDownError2] = useState(false);
  const [currencyDropDownMapper, setCurrencyDropDownMapper] = useState({});
  const [imageUrl, setImageurl] = useState();
  const [imageUrl2, setImageurl2] = useState();
  const [image, setImage] = useState();
  const [fl, setFile] = useState();
  const [fl2, setFile2] = useState();
  const [darkModeIconError, setDarkModeThemeError] = useState(false);
  const [lightModeIconError, setlightModeThemeError] = useState(false);

  const fileRef = React.createRef();
  const fileRef2 = React.createRef();

  useEffect(() => {
    getAllBanksCall();
  }, []);

  useEffect(() => {
    if (imageUrl === undefined || imageUrl === "") {
      setlightModeThemeError(true);
    } else {
      setlightModeThemeError(false);
    }
  }, [imageUrl]);
  useEffect(() => {
    if (imageUrl2 === undefined || imageUrl2 === "") {
      setDarkModeThemeError(true);
    } else {
      setDarkModeThemeError(false);
    }
  }, [imageUrl2]);

  useEffect(() => {
    if (currentBankSelected && scope === "BankEdit") {
      if (
        currentBankSelected.ownersBankAccount &&
        currentBankSelected.ownersBankAccount !== 0
      ) {
        let currencyDrop = {};
        if (plaformcurrencies && plaformcurrencies.length !== 0) {
          plaformcurrencies.forEach((itm) => {
            currencyDrop[itm.id] = {
              checked: false,
              IBAN: "",
            };
          });
          Object.keys(currencyDrop).forEach((itm) => {
            if (
              currentBankSelected["ownersBankAccount"].filter(
                (itm2) => itm === itm2.currencyId
              )[0]
            ) {
              currencyDrop[itm] = {
                checked: currentBankSelected["ownersBankAccount"].filter(
                  (itm2) => itm === itm2.currencyId
                )[0]?.IBANs
                  ? true
                  : false,
                IBAN: currentBankSelected["ownersBankAccount"].filter(
                  (itm2) => itm === itm2.currencyId
                )[0]?.IBANs,
              };
            }
          });
          setCurrencyDropDownMapper(currencyDrop);
          setImageurl(
            `${process.env.REACT_APP_IMGLINK}${currentBankSelected?.nonColouredLogo}`
          );
          setImageurl2(
            `${process.env.REACT_APP_IMGLINK}${currentBankSelected?.colouredLogo}`
          );
          setBankName(currentBankSelected?.bankName);
          setAccountHolderName(currentBankSelected?.accountHoldersName);
        }
      } else {
        let currencyDrop = {};
        if (plaformcurrencies && plaformcurrencies.length !== 0) {
          plaformcurrencies.forEach((itm) => {
            currencyDrop[itm.id] = {
              checked: false,
              IBAN: "",
            };
          });

          setCurrencyDropDownMapper(currencyDrop);
        }
      }
    } else {
      let currencyDrop = {};
      if (plaformcurrencies && plaformcurrencies.length !== 0) {
        plaformcurrencies.forEach((itm) => {
          currencyDrop[itm.id] = {
            checked: false,
            IBAN: "",
          };
        });

        setCurrencyDropDownMapper(currencyDrop);
      }
    }
  }, [scope]);

  useEffect(() => {
    if (editNonColouredLogoSuccess !== undefined) {
      if (currentBankSelected && scope === "BankEdit") {
        if (
          currentBankSelected.ownersBankAccount &&
          currentBankSelected.ownersBankAccount !== 0
        ) {
          let currencyDrop = {};
          if (plaformcurrencies && plaformcurrencies.length !== 0) {
            plaformcurrencies.forEach((itm) => {
              currencyDrop[itm.id] = {
                checked: false,
                IBAN: "",
              };
            });
            Object.keys(currencyDrop).forEach((itm) => {
              if (
                currentBankSelected["ownersBankAccount"].filter(
                  (itm2) => itm === itm2.currencyId
                )[0]
              ) {
                currencyDrop[itm] = {
                  checked: true,
                  IBAN: currentBankSelected["ownersBankAccount"].filter(
                    (itm2) => itm === itm2.currencyId
                  )[0]?.IBANs,
                };
              }
            });
            setCurrencyDropDownMapper(currencyDrop);
            setImageurl(
              `${process.env.REACT_APP_IMGLINK}${editNonColouredLogoSuccess?.nonColouredLogo}`
            );
            setImageurl2(
              `${process.env.REACT_APP_IMGLINK}${editNonColouredLogoSuccess?.colouredLogo}`
            );
            setBankName(currentBankSelected?.bankName);
            setAccountHolderName(currentBankSelected?.accountHoldersName);
          }
        } else {
          let currencyDrop = {};
          if (plaformcurrencies && plaformcurrencies.length !== 0) {
            plaformcurrencies.forEach((itm) => {
              currencyDrop[itm.id] = {
                checked: false,
                IBAN: "",
              };
            });

            setCurrencyDropDownMapper(currencyDrop);
          }
        }
      } else {
        let currencyDrop = {};
        if (plaformcurrencies && plaformcurrencies.length !== 0) {
          plaformcurrencies.forEach((itm) => {
            currencyDrop[itm.id] = {
              checked: false,
              IBAN: "",
            };
          });

          setCurrencyDropDownMapper(currencyDrop);
        }
      }
    }
  }, [editNonColouredLogoSuccess]);

  useEffect(() => {
    if (editAdminBankImagesSuccess !== undefined) {
      if (currentBankSelected && scope === "BankEdit") {
        if (
          currentBankSelected.ownersBankAccount &&
          currentBankSelected.ownersBankAccount !== 0
        ) {
          let currencyDrop = {};
          if (plaformcurrencies && plaformcurrencies.length !== 0) {
            plaformcurrencies.forEach((itm) => {
              currencyDrop[itm.id] = {
                checked: false,
                IBAN: "",
              };
            });
            Object.keys(currencyDrop).forEach((itm) => {
              if (
                currentBankSelected["ownersBankAccount"].filter(
                  (itm2) => itm === itm2.currencyId
                )[0]
              ) {
                currencyDrop[itm] = {
                  checked: true,
                  IBAN: currentBankSelected["ownersBankAccount"].filter(
                    (itm2) => itm === itm2.currencyId
                  )[0]?.IBANs,
                };
              }
            });
            setCurrencyDropDownMapper(currencyDrop);
            setImageurl(
              `${process.env.REACT_APP_IMGLINK}${editAdminBankImagesSuccess?.nonColouredLogo}`
            );
            setImageurl2(
              `${process.env.REACT_APP_IMGLINK}${editAdminBankImagesSuccess?.colouredLogo}`
            );
            setBankName(currentBankSelected?.bankName);
            setAccountHolderName(currentBankSelected?.accountHoldersName);
          }
        } else {
          let currencyDrop = {};
          if (plaformcurrencies && plaformcurrencies.length !== 0) {
            plaformcurrencies.forEach((itm) => {
              currencyDrop[itm.id] = {
                checked: false,
                IBAN: "",
              };
            });

            setCurrencyDropDownMapper(currencyDrop);
          }
        }
      } else {
        let currencyDrop = {};
        if (plaformcurrencies && plaformcurrencies.length !== 0) {
          plaformcurrencies.forEach((itm) => {
            currencyDrop[itm.id] = {
              checked: false,
              IBAN: "",
            };
          });

          setCurrencyDropDownMapper(currencyDrop);
        }
      }
    }
  }, [editAdminBankImagesSuccess]);

  // useEffect(() => {
  //   console.log(currencyDropDownMapper)
  // }, [currencyDropDownMapper])

  const checkCurrentBankAndSet = () => {
    if (bankOptions !== undefined) {
      if (selectedBankRedux !== undefined && bankOptions.length !== 0) {
        for (let i = 0; i < bankOptions.length; i++) {
          if (bankOptions[i].id === selectedBankRedux.id) {
            setSelectedIndex(i);
          }
        }
      }
    }
  };

  const checkCurrentFiatAndSet = (supportedCurrencies) => {
    if (
      supportedCurrencies !== undefined &&
      supportedCurrencies.length !== 0 &&
      selectedFiatCurrencyInfo !== undefined &&
      selectedFiatCurrencyInfo.id !== undefined
    ) {
      for (let i = 0; i < supportedCurrencies.length; i++) {
        if (supportedCurrencies[i].id === selectedFiatCurrencyInfo.id) {
          if (i != selectedCurrency) setSelectedCurrency(i);
        }
      }
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();

    let file = e.target.files[0];
    setFile(file);
    reader.onloadend = () => {
      setImage(file);
      setImageurl(reader.result);
      //setReaderImage(true);
    };
    reader && reader.readAsDataURL(file);
  };

  const handleImageChange2 = (e) => {
    e.preventDefault();
    let reader = new FileReader();

    let file = e.target.files[0];
    setFile2(file);
    reader.onloadend = () => {
      setImage(file);
      setImageurl2(reader.result);
      //setReaderImage(true);
    };
    reader && reader.readAsDataURL(file);
  };
  // useEffect(() => {
  //   console.log('Selected bank list item -> ',selectedBankRedux)
  //   console.log('Open modal -> ',openBankAccountAddModal)
  // },[selectedBankRedux])

  // useEffect(() => {
  //   if (addAdminBankLoading === false && addAdminBankError) {
  //     if (addAdminBankError.length !== 0) {
  //       setErrorMessage(addAdminBankError);
  //       //setGoBack(false);
  //     }
  //   }
  //   else if(editAdminBankLoading === false && editAdminBankError) {
  //     if (editAdminBankError.length !== 0) {
  //       setErrorMessage(editAdminBankError);
  //       //setGoBack(false);
  //     }
  //   }
  //   else {
  //     if (addAdminBankLoading === false && addAdminBankError === undefined) {
  //       closeModalModified();
  //     }
  //     else{
  //       if (editAdminBankLoading === false && editAdminBankError === undefined) {
  //         closeModalModified();
  //       }
  //     }
  //   }
  // }, [addAdminBankLoading,editAdminBankLoading,addAdminBankError,editAdminBankError]);

  useEffect(() => {
    if (editAdminBankLoading === false && editAdminBankError) {
      setErrorMessage(editAdminBankError);
    } else {
      if (editAdminBankLoading === false && editAdminBankError === undefined) {
        closeModalModified();
      }
    }
  }, [editAdminBankLoading]);

  useEffect(() => {
    if (editNonColouredLogoLoading === false && editNonColouredLogoError) {
      setErrorMessage(editNonColouredLogoError);
    }
    // else {
    //   if (editNonColouredLogoLoading === false && editNonColouredLogoError === undefined) {
    //     closeModalModified();
    //   }
    // }
  }, [editNonColouredLogoLoading]);

  useEffect(() => {
    if (editAdminBankImagesLoading === false && editAdminBankImagesError) {
      setErrorMessage(editAdminBankImagesError);
    }
    // else {
    //   if (editAdminBankImagesLoading === false && editAdminBankImagesError === undefined) {
    //     closeModalModified();
    //   }
    // }
  }, [editAdminBankImagesLoading]);

  useEffect(() => {
    if (addAdminBankLoading === false && addAdminBankError) {
      setErrorMessage(addAdminBankError);
    } else {
      if (addAdminBankLoading === false && addAdminBankError === undefined) {
        closeModalModified();
      }
    }
  }, [addAdminBankLoading]);

  useEffect(() => {
    let allBanks =
      fiatBanks &&
      fiatBanks.map((bank) => ({
        id: bank.id,
        tokenName: bank.bankName,
      }));
    if (openBankAccountAddModal) {
      setHideThis(true);
    }
    if (allBanks && allBanks.length != 0) setBankOptions(allBanks);
    let supportedCurrencies = plaformcurrencies.map((currency) => ({
      id: currency.id,
      tokenName: currency.exchangeSymbol,
    }));
    checkCurrentFiatAndSet(supportedCurrencies);
    setSupportedCurrencies(supportedCurrencies);
  }, [fiatBanks, selectedBankRedux]);

  // useEffect(() => {
  //   let allBanks = fiatBanks.map((bank) => ({
  //     id: bank.id,
  //     tokenName: bank.bankName,
  //   }));
  //   setBankOptions(allBanks);
  //   checkCurrentBankAndSet()
  // },[selectedBankRedux])

  useEffect(() => {
    let supportedCurrencies = plaformcurrencies.map((currency) => ({
      id: currency.id,
      tokenName: currency.exchangeSymbol,
    }));
    if (openBankAccountAddModal) {
      setHideThis(true);
    }
    checkCurrentFiatAndSet(supportedCurrencies);
    setSupportedCurrencies(supportedCurrencies);
  }, [plaformcurrencies, selectedFiatCurrencyInfo]);
  // useEffect(() => {console.log('support',supportedCurrency)},[])

  const FormOnSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      //if (scope !== "BankEdit") {
      if (imageUrl === undefined || imageUrl === "") {
        setlightModeThemeError(true);
      } else {
        setlightModeThemeError(false);
      }

      if (imageUrl2 === undefined || imageUrl2 === "") {
        setDarkModeThemeError(true);
      } else {
        setDarkModeThemeError(false);
      }
      // }

      // if (Selected === "") {
      //   setShowDropDownError1(true);
      // } else {
      //   setShowDropDownError1(false);
      // }
      event.preventDefault();
      event.stopPropagation();
      setValid(false);
    } else {
      event.preventDefault();
      event.stopPropagation();
      //if (scope !== "BankEdit") {
      if (imageUrl === undefined || imageUrl === "") {
        setlightModeThemeError(true);
      } else {
        setlightModeThemeError(false);
      }

      if (imageUrl2 === undefined || imageUrl2 === "") {
        setDarkModeThemeError(true);
      } else {
        setDarkModeThemeError(false);
      }

      if (
        (imageUrl !== undefined || imageUrl !== "") &&
        (imageUrl2 !== undefined || imageUrl2 !== "")
      ) {
        setValid(true);
        createBankAccount(form);
      }
      // } else {
      //   setValid(true);
      //   createBankAccount(form);
      // }
      //fiatBalance();
    }
  };

  const createBankAccount = (form) => {
    if (valid === true || form.checkValidity() === true) {
      let currencyIbans = [];
      Object.keys(currencyDropDownMapper).forEach((key) => {
        if (currencyDropDownMapper[key].checked) {
          let obj = {
            IBANs: currencyDropDownMapper[key].IBAN,
            currencyId: parseInt(key),
          };
          currencyIbans.push(obj);
        } else {
          if (
            currencyDropDownMapper[key].IBAN &&
            currencyDropDownMapper[key].IBAN !== ""
          ) {
            let obj = {
              IBANs: "",
              currencyId: parseInt(key),
            };
            currencyIbans.push(obj);
          }
        }
      });

      const data = {
        BankName: bankName,
        accountHoldersName: accountHolderName,
        AddOwnersBankAccountDto: currencyIbans,
      };

      const frmData = new FormData();
      frmData.append("BankName", bankName);
      if (scope !== "BankEdit") {
        frmData.append("colouredIcon", fl2);
        frmData.append("nonColouredIcon", fl);
      }
      frmData.append("accountHoldersName", accountHolderName);
      frmData.append("AddOwnersBankAccountDto", JSON.stringify(currencyIbans));

      if (scope === "BankEdit") {
        //console.log('All currency -> ', currencyIbans)
        editAdminBank({ id: currentBankSelected?.id, formData: data });
      } else {
        // console.log('Form Data -> ', Array.from(frmData))
        // console.log('All IBANs -> ', currencyIbans)
        createBankAccountCall({ formData: frmData });
      }

      //console.log('Form Data -> ',data)
    } else {
      if (Selected === "") {
        setShowDropDownError1(true);
      }
    }
    // else {
    //   //console.log(t(`${"NotValid"}`));
    // }
  };

  const closeDetection = () => {
    if (goBack === true) {
      closeModal();
    }
  };

  const handleMe = (e) => {
    var target = e.target,
      position = target.selectionEnd,
      length = target.value.length;

    target.value = target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;
  };
  const closeModalModified = () => {
    if (openBankAccountAddModal) {
      removeCurrentBankAccountAsync();
      setCurrentBankSelected && setCurrentBankSelected();
      setScope();
      setErrorMessage("");
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setSelectedIndex("");
      setSelectedCurrency("");
      setAccountHolderName("");
      setBankName("");
      setCurrencyDropDownMapper({});
      setShowDropDownError1 && setShowDropDownError1(false);
      setImageurl();
      setImageurl2();
      setFile2();
      setFile();
      setlightModeThemeError("");
      setDarkModeThemeError("");
      //setHideThis(false)
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      setShowAddBankConfirmation && setShowAddBankConfirmation(false);
      clearEditAddErrorAsync();
      clearError();
    } else {
      setErrorMessage("");
      setCurrentBankSelected && setCurrentBankSelected();
      setScope();
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setSelectedIndex("");
      setSelectedCurrency("");
      setAccountHolderName("");
      setBankName("");
      setCurrencyDropDownMapper({});
      setShowDropDownError1 && setShowDropDownError1(false);
      setImageurl();
      setImageurl2();
      setFile2();
      setFile();
      setlightModeThemeError("");
      setDarkModeThemeError("");
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      //setHideThis(false)
      setShowAddBankConfirmation && setShowAddBankConfirmation(false);
      clearEditAddErrorAsync();
      clearError();
      onCancelledAsync && onCancelledAsync();
    }
  };

  const closeModal = () => {
    if (openBankAccountAddModal) {
      removeCurrentBankAccountAsync();
      setErrorMessage("");
      setCurrentBankSelected && setCurrentBankSelected();
      setScope();
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setSelectedIndex("");
      setSelectedCurrency("");
      setAccountHolderName("");
      setBankName("");
      setCurrencyDropDownMapper({});
      setHideThis(false);
      setShowDropDownError1 && setShowDropDownError1(false);
      setImageurl();
      setImageurl2();
      setFile2();
      setFile();
      setlightModeThemeError("");
      setDarkModeThemeError("");
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      setShowAddBankConfirmation && setShowAddBankConfirmation(false);
      clearEditAddErrorAsync();
      clearError();
    } else {
      setErrorMessage("");
      setCurrentBankSelected && setCurrentBankSelected();
      setScope();
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setSelectedIndex("");
      setSelectedCurrency("");
      setAccountHolderName("");
      setBankName("");
      setCurrencyDropDownMapper({});
      setShowDropDownError1 && setShowDropDownError1(false);
      setImageurl();
      setImageurl2();
      setFile2();
      setFile();
      setlightModeThemeError("");
      setDarkModeThemeError("");
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      //setHideThis(false)
      setShowAddBankConfirmation && setShowAddBankConfirmation(false);
      clearEditAddErrorAsync();
      clearError();
      onCancelledAsync && onCancelledAsync();
    }
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid var(--borderdark) !important",
      backgroundColor: "var(--bgwhite)",
      fontSize: "13px",
      color: state.isSelected ? "blue" : "var(--textlight)",
    }),

    // control: (_, { selectProps: { width }}) => ({
    //   width: width
    // }),

    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    //   return { ...provided, opacity, transition };
    // }
  };
  useEffect(() => {
    createBankAccSuccess && onSuccessfulBankCreation();
    //  createBankAccSuccess && onCancelled();
  }, [createBankAccSuccess]);

  return (
    <>
      <Accountsuccessful
        show={
          currentBankSelected && scope === "BankEdit"
            ? editAdminBankSuccess
            : addAdminBankSuccess
        }
        //setShowDeposit={setShowDeposit}
        //setHideThis={setHideThis}
      />

      <Modal
        className="addbankadmin"
        show={showAddBankConfirmation || openBankAccountAddModal}
        onHide={() => {
          //onCancelled();
          closeModal();
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {scope && scope === "BankEdit" ? (
              <span className="deletetitle">{t("EditBankAccount")}</span>
            ) : (
              <span className="deletetitle">{t("AddNewBankAccount")}</span>
            )}
          </Modal.Title>
          <CloseIcon
            className="closebtn"
            onClick={() => {
              //onCancelled();
              closeModal();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            {currentBankSelected && scope === "BankEdit"
              ? (editAdminBankLoading ||
                  editAdminBankImagesLoading ||
                  editColouredLogoLoading ||
                  editNonColouredLogoLoading) && <Loader />
              : addAdminBankLoading && <Loader />}
            <Row>
              <Col lg={12}>
                <Form noValidate onSubmit={FormOnSubmit}>
                  <div className="outercontainer">
                    <div className="hide">
                      <div
                        className="d-flex align-items-center mb-5"
                        onClick={() => {
                          //onCancelled();
                          closeModal();
                        }}
                      >
                        <Arrow className="arrowicon" />
                        {scope && scope === "BankEdit" ? (
                          <p className="addNewText">{t("EditBankAccount")}</p>
                        ) : (
                          <p className="addNewText">{t("AddNewBankAccount")}</p>
                        )}
                      </div>
                    </div>
                    <div className="titles">
                      {/* <span>{t("Selectlogo")}</span> */}
                      <span>{t("Logoforlighttheme")}</span>
                      <div className="outerbx">
                        {imageUrl ? (
                          <img src={imageUrl} className="iconImage" />
                        ) : (
                          <>
                            <input
                              hidden
                              onChange={(e) => handleImageChange(e)}
                              type="file"
                              id="inputfile"
                              className="inputfilebtn"
                              ref={fileRef}
                              //required={scope && scope !== "BankEdit"}
                              required
                              //isInvalid={valid === false}
                              //error={fl && fl === '' ? "Please select a logo for light theme" : ""}
                            />
                            <Addlogo
                              className="addlogo"
                              onClick={() => {
                                fileRef.current.click();
                              }}
                            />
                            {/* <img src={editlogo} /> */}

                            <p className="addtext"> {t("Addlogo")}</p>
                            <p className="addtext1"> {t("Logosize")}</p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="editButtons">
                      {scope === "BankEdit" && (
                        <div className="topbuttons btntop">
                          <label
                            //htmlFor="inputfile"
                            className={
                              thememode == "dark"
                                ? "inputbuttonmaindark"
                                : "inputbuttonmain"
                            }
                            onClick={() => {
                              if (fl && fl !== "") {
                                if (imageUrl === undefined || imageUrl === "") {
                                  setlightModeThemeError(true);
                                } else {
                                  setlightModeThemeError(false);
                                }
                                let frmData = new FormData();
                                //frmData.append('colouredIcon', file)
                                frmData.append("nonColouredIcon", fl);
                                let dt = {
                                  id: currentBankSelected?.id,
                                  formData: frmData,
                                };

                                editNonColouredLogoCall(dt);
                              } else {
                                if (imageUrl === undefined || imageUrl === "") {
                                  setlightModeThemeError(true);
                                } else {
                                  setlightModeThemeError(false);
                                }
                              }
                            }}
                          >
                            {t("Change")}
                          </label>
                          {/* <input
                            hidden
                            onChange={(e) => {
                              console.log('Clicked')
                              // e.preventDefault();
                              // let file = e.target.files[0];
                              // let frmData = new FormData()
                              // frmData.append('colouredIcon', file)
                              // //frmData.append('nonColouredIcon', imageUrl)
                              // let dt = {
                              //   id: currentBankSelected?.id,
                              //   formData: frmData
                              // }

                              // editBankImagesAsync(dt)
                              //handleImageChange(e);
                              //setIsFieldChanged(true);
                              e.preventDefault();
                              let file = e.target.files[0];

                            }}
                            type="file"
                            //id="inputfile"
                            className="inputfilebtn"
                          /> */}
                        </div>
                      )}
                      <div className="topbuttons btntop">
                        <div
                          className={
                            thememode == "dark"
                              ? "btnexcludedark"
                              : "btnexclude"
                          }
                        >
                          <CustomButton
                            name={t("Exclude")}
                            filled={true}
                            primary={true}
                            type={"button"}
                            onClick={() => {
                              //e.preventDefault();
                              // //let file = e.target.files[0];
                              // let frmData = new FormData()
                              // frmData.append('colouredIcon', '')
                              // //frmData.append('nonColouredIcon', imageUrl)
                              // let dt = {
                              //   id: currentBankSelected?.id,
                              //   formData: frmData
                              // }

                              //editBankImagesAsync(dt)
                              // if (!readerImage) {
                              //   removeImage();
                              // } else {
                              //   e.preventDefault();
                              //   setImageurl("");
                              // }
                              setImageurl();
                              setFile();
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {lightModeIconError && (
                      <p className="logoErrorMessages">{t("ErrorLightLogo")}</p>
                    )}

                    <div className="titles">
                      {/* <span>{t("Selectlogo")}</span> */}
                      <span>{t("Logofordarktheme")}</span>
                      <div className="outerbx">
                        {imageUrl2 ? (
                          <img src={imageUrl2} className="iconImage" />
                        ) : (
                          <>
                            <input
                              hidden
                              onChange={(e) => handleImageChange2(e)}
                              type="file"
                              id="inputfile2"
                              className="inputfilebtn"
                              ref={fileRef2}
                              //required={scope && scope !== "BankEdit"}
                              required
                            />
                            <Addlogo
                              className="addlogo"
                              onClick={() => {
                                fileRef2.current.click();
                              }}
                            />
                            {/* <img src={editlogo} /> */}

                            <p className="addtext"> {t("Addlogo")}</p>
                            <p className="addtext1"> {t("Logosize")}</p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="editButtons">
                      {scope === "BankEdit" && (
                        <div className="topbuttons btntop">
                          <label
                            //htmlFor="inputfile2"
                            className={
                              thememode == "dark"
                                ? "inputbuttonmaindark"
                                : "inputbuttonmain"
                            }
                            onClick={() => {
                              if (fl2 && fl2 !== "") {
                                if (
                                  imageUrl2 === undefined ||
                                  imageUrl2 === ""
                                ) {
                                  setDarkModeThemeError(true);
                                } else {
                                  setDarkModeThemeError(false);
                                }
                                let frmData = new FormData();
                                frmData.append("colouredIcon", fl2);
                                //frmData.append('nonColouredIcon', imageUrl)
                                let dt = {
                                  id: currentBankSelected?.id,
                                  formData: frmData,
                                };

                                editBankImagesAsync(dt);
                              } else {
                                if (
                                  imageUrl2 === undefined ||
                                  imageUrl2 === ""
                                ) {
                                  setDarkModeThemeError(true);
                                } else {
                                  setDarkModeThemeError(false);
                                }
                              }
                            }}
                          >
                            {t("Change")}
                          </label>

                          {/* <input
                            // hidden
                            onChange={(e) => {
                              //handleImageChange(e);
                              //setIsFieldChanged(true);
                              e.preventDefault();
                              let file = e.target.files[0];
                              let frmData = new FormData()
                              frmData.append('colouredIcon', file)
                              //frmData.append('nonColouredIcon', imageUrl)
                              let dt = {
                                id: currentBankSelected?.id,
                                formData: frmData
                              }

                              editBankImagesAsync(dt)
                            }}
                            type="file"
                            //id="inputfile2"
                            className="inputfilebtn"
                          /> */}
                        </div>
                      )}
                      <div className="topbuttons btntop">
                        <div
                          className={
                            thememode == "dark"
                              ? "btnexcludedark"
                              : "btnexclude"
                          }
                        >
                          <CustomButton
                            name={t("Exclude")}
                            filled={true}
                            primary={true}
                            type={"button"}
                            onClick={() => {
                              // if (!readerImage) {
                              //   removeImage();
                              // } else {
                              //   e.preventDefault();
                              //   setImageurl("");
                              // }
                              // e.preventDefault();
                              // //let file = e.target.files[0];
                              // let frmData = new FormData()
                              // //frmData.append('colouredIcon', '')
                              // frmData.append('nonColouredIcon', null)
                              // let dt = {
                              //   id: currentBankSelected?.id,
                              //   formData: frmData
                              // }

                              // editBankImagesAsync(dt)
                              setImageurl2();
                              setFile2();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {darkModeIconError && (
                      <p className="logoErrorMessages">{t("ErrorDarkLogo")}</p>
                    )}
                    {/* <div className="titles">
                      <span>{t("SelectBank")}</span>
                      <div className="inputbox1">
                        <DropdownSearch
                          options={bankOptions}
                          setSelectedIndex={(e) => {
                            setSelectedIndex(e);
                          }}
                          Selected={Selected}
                        />
                        {showDropDownError1 && (
                          <p className="errormessage">
                            {t(`${"SelectBankError"}`)}
                          </p>
                        )}
                      </div>
                    </div> */}
                    <div className="titles">
                      <span>{t("BankName")}</span>
                      <div className="inputbox tpspace">
                        <InputField
                          type="text"
                          onChange={(e) => setBankName(e.target.value)}
                          value={bankName}
                          required
                          isInvalid={valid === false}
                          error={
                            bankName && bankName !== ""
                              ? ""
                              : t("Pleaseenterbanksname")
                          }
                          //placeholder={t(`${"AccountHolderName"}`)}
                          placeholder={t("BankName")}
                        />
                      </div>
                    </div>

                    {/* <div className="titles">
                      <span>{t("AccountNumber")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          value={accountNumber}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterAccountNumber"}`)}
                          placeholder={t(`${"AccountNumber"}`)}
                        />
                      </div>
                    </div> */}

                    {/* <div className="titles">
                      <span>{t("SelectCurrency")}</span>
                      <div className="inputbox1">
                        <DropdownSearch
                          options={supportedCurrency}
                          setSelectedIndex={(e) => {
                            setSelectedCurrency(e);
                          }}
                          Selected={selectedCurrency}
                        />
                      </div>
                    </div> */}
                    <div className="titles">
                      <span>IBAN/Swift/Bic {t("Number")}</span>
                      <p className="addtext">{t("Iban")}</p>
                      {/* {console.log(currencyDropDownMapper)} */}
                      {plaformcurrencies &&
                        plaformcurrencies.length !== 0 &&
                        plaformcurrencies.map((itm) => (
                          <div className="displaytoo">
                            <div className="outer checkdiv1">
                              <Form.Check
                                type="checkbox"
                                className="check"
                                onChange={(e) => {
                                  //if (scope !== 'BankEdit') {
                                  setCurrencyDropDownMapper((prevState) => {
                                    return {
                                      ...prevState,
                                      [itm.id]: {
                                        checked: e.target.checked,
                                        IBAN: prevState[itm.id]?.IBAN,
                                      },
                                    };
                                  });
                                  //}
                                }}
                                checked={
                                  currencyDropDownMapper[itm.id]?.checked
                                }
                              />

                              <Form.Label>
                                <img
                                  src={`${process.env.REACT_APP_IMGLINK}${itm?.colouredLogo}`}
                                  className="curimg"
                                />
                                <span className="cname spantext">
                                  {itm?.exchangeSymbol}
                                </span>
                              </Form.Label>
                            </div>

                            <div className="inputbox checkdiv">
                              <InputField
                                disabled={
                                  currencyDropDownMapper[itm.id]?.checked ===
                                  true
                                    ? false
                                    : true
                                }
                                type="text"
                                onChange={(e) => {
                                  setCurrencyDropDownMapper((prevState) => {
                                    return {
                                      ...prevState,
                                      [itm.id]: {
                                        checked: prevState[itm.id]?.checked,
                                        IBAN: e.target.value,
                                      },
                                    };
                                  });

                                  handleMe(e);
                                  setIban(e.target.value);
                                }}
                                value={currencyDropDownMapper[itm.id]?.IBAN}
                                required={
                                  //scope !== "BankEdit" &&
                                  currencyDropDownMapper[itm.id]?.checked
                                    ? true
                                    : false
                                }
                                isInvalid={
                                  //scope !== "BankEdit" &&
                                  currencyDropDownMapper[itm.id]?.checked &&
                                  valid === false
                                }
                                error={
                                  currencyDropDownMapper[itm.id]?.IBAN ===
                                    undefined ||
                                  currencyDropDownMapper[itm.id]?.IBAN === ""
                                    ? t(`${"EnterIBAN"}`)
                                    : ""
                                }
                                placeholder={
                                  currencyDropDownMapper[itm.id]?.checked ===
                                  true
                                    ? t(`${"IBANNumber"}`)
                                    : ""
                                }
                                minlength={32}
                              />
                            </div>
                          </div>
                        ))}

                      {/* <div className="displaytoo">
                        <div className="outer checkdiv1">
                          <Form.Check type="checkbox" className="check" />

                          <Form.Label>
                            <img src={dollar} className="curimg" />
                            <span className="cname spantext">USD</span>
                          </Form.Label>
                        </div>

                        <div className="inputbox checkdiv">
                          <InputField
                            type="text"
                            onChange={(e) => {
                              handleMe(e);
                              setIban(e.target.value);
                            }}
                            value={iban}
                            required
                            isInvalid={valid === false}
                            error={t(`${"EnterIBAN"}`)}
                            placeholder={t(`${"IBANNumber"}`)}
                            minlength={34}
                          />
                        </div>
                      </div> */}
                      {/* 
                      <div className="displaytoo">
                        <div className="outer checkdiv1">
                          <Form.Check type="checkbox" className="check" />

                          <Form.Label>
                            <img src={dollar} className="curimg" />
                            <span className="cname spantext">USD</span>
                          </Form.Label>
                        </div>

                        <div className="inputbox checkdiv">
                          <InputField
                            type="text"
                            onChange={(e) => {
                              handleMe(e);
                              setIban(e.target.value);
                            }}
                            value={iban}
                            required
                            isInvalid={valid === false}
                            error={t(`${"EnterIBAN"}`)}
                            placeholder={t(`${"IBANNumber"}`)}
                            minlength={34}
                          />
                        </div>
                      </div>

                      <div className="displaytoo">
                        <div className="outer checkdiv1">
                          <Form.Check type="checkbox" className="check" />

                          <Form.Label>
                            <img src={dollar} className="curimg" />
                            <span className="cname spantext">USD</span>
                          </Form.Label>
                        </div>

                        <div className="inputbox checkdiv">
                          <InputField
                            type="text"
                            onChange={(e) => {
                              handleMe(e);
                              setIban(e.target.value);
                            }}
                            value={iban}
                            required
                            isInvalid={valid === false}
                            error={t(`${"EnterIBAN"}`)}
                            placeholder={t(`${"IBANNumber"}`)}
                            minlength={34}
                          />
                        </div>
                      </div> */}
                    </div>

                    <div className="titles">
                      <span>{t("AccountHolderName")}</span>
                      <div className="inputbox tpspace">
                        <InputField
                          type="text"
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          value={accountHolderName}
                          required
                          isInvalid={valid === false}
                          error={
                            accountHolderName && accountHolderName !== ""
                              ? ""
                              : t(`${"EnterAccountHolderName"}`)
                          }
                          placeholder={t(`${"AccountHolderName"}`)}
                        />
                      </div>
                    </div>

                    {/* <div className="titles">
                      <span>SWIFT {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setSwift(e.target.value)}
                          value={swift}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterSWIFT"}`)}
                          placeholder={t(`${"SWIFTNumber"}`)}
                        />
                      </div>
                    </div> */}

                    {/* <div className="titles">
                      <span>IFSC {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setIfsc(e.target.value)}
                          value={ifsc}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterIFSC"}`)}
                          placeholder={t(`${"IFSCNumber"}`)}
                        />
                      </div>
                    </div> */}
                    {t(`${errorMessage}`)}
                    <div className="btmbtnoutercontainer">
                      <div className="topbuttons">
                        <CustomButton
                          type="submit"
                          name={t("Submit")}
                          filled={true}
                          primary={true}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <div className="hidefordeskop">
          <FooterCommon />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  fiat = {},
  app = {},
  user = {},
  wallet = {},
  admin = {},
}) => {
  const fiatBanks = _.get(fiat, "fiatBank", []);
  const createBankError = _.get(fiat, "createBankAccountError", undefined);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const createBankAccountLoading = _.get(
    fiat,
    "createBankAccountLoading",
    undefined
  );
  const addAdminBankLoading = _.get(admin, "addAdminBankLoading", false);
  const addAdminBankSuccess = _.get(admin, "addAdminBankSuccess", false);
  const addAdminBankError = _.get(admin, "addAdminBankError", undefined);

  const editAdminBankLoading = _.get(admin, "editAdminBankLoading", undefined);
  const editAdminBankSuccess = _.get(admin, "editAdminBankSuccess", undefined);
  const editAdminBankError = _.get(admin, "editAdminBankError", undefined);
  const editAdminBankImagesError = _.get(
    admin,
    "editAdminBankImagesError",
    undefined
  );

  const editNonColouredLogoSuccess = _.get(
    admin,
    "editNonColouredLogoSuccess",
    undefined
  );
  const editAdminBankImagesSuccess = _.get(
    admin,
    "editAdminBankImagesSuccess",
    undefined
  );
  const editColouredLogoLoading = _.get(
    admin,
    "editColouredLogoLoading",
    false
  );
  const editColouredLogoError = _.get(
    admin,
    "editColouredLogoError",
    undefined
  );

  const editNonColouredLogoLoading = _.get(
    admin,
    "editNonColouredLogoLoading",
    false
  );
  const editNonColouredLogoError = _.get(
    admin,
    "editNonColouredLogoError",
    undefined
  );

  const createBankAccSuccess = _.get(fiat, "createBankAccSuccess", false);
  const selectedBankRedux = _.get(fiat, "selectedBank", undefined);
  const openBankAccountAddModal = _.get(fiat, "openBankAccountAddModal", false);
  const editAdminBankImagesLoading = _.get(
    admin,
    "editAdminBankImagesLoading",
    false
  );
  const thememode = _.get(app, "thememode", false);
  const selectedFiatCurrencyInfo = _.get(
    fiat,
    "selectedFiatCurrencyInfo",
    undefined
  );
  return {
    fiatBanks,
    createBankError,
    exchangeId,
    userAccessToken,
    createBankAccountLoading,
    createBankAccSuccess,
    plaformcurrencies,
    selectedBankRedux,
    openBankAccountAddModal,
    selectedFiatCurrencyInfo,
    addAdminBankSuccess,
    addAdminBankError,
    addAdminBankLoading,
    editAdminBankLoading,
    editAdminBankSuccess,
    editAdminBankError,
    editAdminBankImagesLoading,
    thememode,
    editAdminBankImagesError,
    editColouredLogoLoading,
    editColouredLogoError,
    editNonColouredLogoLoading,
    editNonColouredLogoError,
    editNonColouredLogoSuccess,
    editAdminBankImagesSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllBanksCall: () => dispatch({ type: "getBankTrigger" }),
  createBankAccountCall: (data) =>
    dispatch({ type: "addAdminBank", payload: data }),
  editAdminBank: (data) => dispatch({ type: "editAdminBank", payload: data }),
  fiatBalance: (data) =>
    dispatch({ type: "callFiatBalanceStartCalled", payload: data }),
  clearError: () => dispatch(clearBankError()),
  removeCurrentBankAccountAsync: () => dispatch(removeCurrentBankAccount()),
  clearEditAddErrorAsync: () => dispatch(clearEditAddError()),
  editBankImagesAsync: (data) =>
    dispatch({ type: "editBankImages", payload: data }),
  editNonColouredLogoCall: (data) =>
    dispatch({ type: "editNonColouredIcon", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBankAccountPopup2);
