export default {
  otp: "OTP",
  OTP_NOT_MATCHED: "OTP does not match",
  OTP_EXPIRED: "OTP expired",
  OTP_NOT_GENERATED: "Otp not generated for this user,first generate otp",
  LIMIT_EXCEED:
    "You are not able to enter otp at the moment due to multiple attempts,try after some time",
  ocbe: "otp cannot be empty",
  nv: "not valid",
  "mobile should not be empty": "mobile should not be empty",
};
