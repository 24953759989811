import * as _ from "lodash";
import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  getMywalletsTrigger,
  getMywalletsSuccess,
  getAccountByWalletIdTrigger,
  getAccountByWalletIdSuccess,
  getAccountAndBalanceByWalletIdTrigger,
  getAccountAndBalanceByWalletIdSuccess,
  getUserTransactionTrigger,
  getUserTransactionSuccess,
  getUserTransactionSuccessDownload,
  getCoinOrTokenBalanceTrigger,
  getCoinOrTokenBalanceSuccess,
  activateCoinOrTokenTrigger,
  activateCoinOrTokenSuccess,
  activateCoinOrTokenFail,
  transferBalanceOrTokenTrigger,
  transferBalanceOrTokenSuccess,
  transferBalanceOrTokenFail,
  selectRegionAndLanguageTrigger,
  selectRegionAndLanguageSuccess,
  selectRegionAndLanguageFail,
  exchangeTokenorCoinSuccess,
  exchangeTokenorCoinTrigger,
  exchangeTokenorCoinFail,
  addLogoSuccess,
  addLogoFail,
  addLogoTrigger,
  addExchangeColorTrigger,
  addExchangeColorSuccess,
  addExchangeColorFail,
  ExhangeHistorySuccess,
  ExhangeHistoryTrigger,
  addPlatformTokensTrigger,
  addPlatformTokensSuccess,
  addPlatformTokensFail,
  addExchangeSuperManagerTrigger,
  addExchangeSuperManagerSuccess,
  addExchangeSuperManagerFail,
  getPlatformTokensTrigger,
  getPlatformTokensSuccess,
  getFiatBalanceSuccess,
  getFiatBalanceTrigger,
  activateFiatCurrencySuccess,
  activateFiatCurrencyTrigger,
  activateFiatCurrencyFail,
  updatewsdataSuccess,
  getExchangerateSuccess,
  getExchangerateTrigger,
  getExchangerateFail,
  transferHistoryCountSuccess,
  exchangeHistoryCountSuccess,
  getTransferhistoryCountTrigger,
  getPlatformCurrencysSuccess,
  getPlatformCurrencysTrigger,
  getDepositWithHistoryTrigger,
  getDepositWithHistorySuccess,
  getDepositWithCountSuccess,
  getFiatExchangerateTrigger,
  getFiatExchangerateFail,
  getFiatExchangerateSuccess,
  getDepositWithHistorySuccessDownload,
  ExhangeHistoryDownloadSuccess,
  getDectExchangePriceSuccess,
  getDectExchangePriceTrigger,
  getDectExchangePriceFail,
  postDectExchangePriceSuccess,
  postDectExchangePriceTrigger,
  loanSupportedInPlatformLoadingStart,
  loanSupportedSuccess,
  loanSupportedFailure,
  borrowLoanLoadingStart,
  borrowLoanSuccss,
  borrowLoanFailure,
  createLoanSupportLoading,
  createLoanSupportSuccess,
  createLoanSupportFailure,
  editLoanSupportLoading,
  editLoanSupportSuccess,
  editLoanSupportFailure,
  deleteLoanSupportLoading,
  deleteLoanSupportSuccess,
  deleteLoanSupportFailure,
  allListingOfAdminLoanLoading,
  allListingOfAdminLoanSuccess,
  allListingofAdminFailure,
  loanRequestLoadingStart,
  loanRequestSuccess,
  loanRequestFailure,
  addLoanRequestLoadingStart,
  addLoanRequestSuccess,
  addLoanRequestFailure,
  allListingOfLoanSuccess,

  collaterConvoLoadingStart,
  collateralConvoSuccess,
  collateralConvoFailure,

  approveLoanRequestLoadingStart,
  approveLonRequestSuccess,
  approveLoanRequestFailure,
  createPersonalLoanLoading,
  createPersonalLoanSuccess,
  createPersonalLoanFailure,
  
  adminAllPersonalLoanLoadingStart,
  adminAllPersonalLoanSuccess,
  adminAllPersonalLoanFailure,

  editPersonalLoanLoadingStart,
  editPersonalLoanSuccess,
  editPersonalLoanFailure,
  
  deletePersonalLoanLoadingStart,
  deletePersonalLoanSuccess,
  deletePersonalLoanFailure,
  publicAllPersonalLoanLoadingStart,
  publicAllPersonalLoanSuccess,
  publicAllPersonalLoanFailure,

  addPersonalLoanRequestLoadingStart,
  addPersonalLoanRequestSuccess,
  addPersonalLoanRequestFailure,

  getInterestRateForPersonalLoadingStart,
  getInterestRateForPersonalSuccess,
  getInterestRateForPersonalFailure,

  personalLoanRequestLoadingStart,
  personalLoanRequestSuccess,
  personalLoanRequestFailure,

  approvePersonalLoanRequestLoadingStart,
  approvePersonalLonRequestSuccess,
  approvePersonalLoanRequestFailure,
} from "./platformuserredux";
import {
  getAccessToken,
  getAccountId,
  getfiatWalletId,
  getTokenDeciamls,
  getWalletId,
} from "./utilities";
import { toast } from "react-toastify";
import {
  setExchangeModalSuccess,
  setWithdrawModalSuccess,
  setbuyModalSuccess,
} from "./appredux";
import { callFiatBalanceSuccess } from "./fiatredux";

export function* LoanRequestApprovalSaga(api , {payload}){
  yield put(approveLoanRequestLoadingStart())
  try{
  
    const token = yield getAccessToken()
    
    const result = yield call(Api.callServer , api.approveLoanRequest ,{details : payload  , token} , true)
    yield put(approveLonRequestSuccess(result))
    // openSuccess()
    yield call(LoanRequestListingSaga , api, {
      payload: { skip: 0, limit: 10 },
    })
  }catch(e){
    yield put(approveLonRequestSuccess(e.message))
  }
}

export function* CollateralConversionSaga(api , {payload}){
  yield put(collaterConvoLoadingStart())
  try{
    const result = yield call(Api.callServer , api.BorrowToCollateralConversion , payload , true)
    yield put(collateralConvoSuccess(result))
  }catch(e){
    yield put(collateralConvoFailure(e.message))
  }
}
// borrow loan saga // exact page of borrow
export function* AddLoanRequestSaga(api, { payload }) {
  yield put(addLoanRequestLoadingStart());
  try {
    console.log('payload' , payload)
    const {openSuccess} = payload;
    delete payload["openSuccess"]
    let token = yield getAccessToken()
    const result = yield call(Api.callServer, api.CreateBorrowRequestToAdmin,{details : payload , token}, true);
    yield put(addLoanRequestSuccess(result));
    openSuccess();
  } catch (e) {
    yield put(addLoanRequestFailure(e.message));
  }
}
export function* LoanRequestListingSaga(api, { payload }) {
  yield put(loanRequestLoadingStart());
  console.log('payload' , payload)
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.LoanRequestLoanSupport,
      { details: payload, token },
      true
    );
    yield put(loanRequestSuccess(result));
  } catch (e) {
    yield put(loanRequestFailure(e.message));
  }
}

export function* LoanSupportedInPlatformSaga(api, { payload }) {
  yield put(loanSupportedInPlatformLoadingStart());
  try {
    const result = yield call(Api.callServer, api, payload, true);
    yield put(loanSupportedSuccess(result));
  } catch (e) {
    yield put(loanSupportedFailure(e.message));
  }
}

export function* ListLoanSupportedByAdminSaga(api, { payload }) {
  yield put(allListingOfAdminLoanLoading());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetDefiLoanAdmin,
      { details: payload, token },
      true
    );
    yield put(allListingOfAdminLoanSuccess(result));
  } catch (e) {
    yield put(allListingofAdminFailure(e.message));
  }
}

export function* CreateLoanSupportInPlatformSaga(api, { payload }) {
  yield put(createLoanSupportLoading());
  try {
    const token = yield getAccessToken();
    console.log("payload", payload);
    const { openSuccess } = payload;
    delete payload["openSuccess"];
    const result = yield call(
      Api.callServer,
      api.CreateDefiLoanAdmin,
      { details: payload, token },
      true
    );

    yield put(createLoanSupportSuccess(result));
    openSuccess();
    // yield call(ListLoanSupportedByAdminSaga, api , {payload : {skip : 0 , limit : 10} })
  } catch (e) {
    yield put(createLoanSupportFailure(e.message));
  }
}

export function* EditLoanSupportSupportInPlatformSaga(api, { payload }) {
  yield put(editLoanSupportLoading());
  try {
    const { openSuccess } = payload;
    delete payload["openSuccess"];
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.EditDefiLoanAdmin,
      { details: payload, token },
      true
    );

    yield put(editLoanSupportSuccess(result));
    openSuccess();
    yield call(ListLoanSupportedByAdminSaga, api, {
      payload: { skip: 0, limit: 10 },
    });
  } catch (e) {
    yield put(editLoanSupportFailure(e.message));
  }
}
export function* DeleteLoanSupportInPlatformSaga(api, { payload }) {
  yield put(deleteLoanSupportLoading());
  try {
    const { openSuccess } = payload;
    delete payload["openSuccess"];

    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.DeleteDefiLoanAdmin,
      { details: payload, token },
      true
    );
    yield put(deleteLoanSupportSuccess(result));
    openSuccess();
    yield call(ListLoanSupportedByAdminSaga, api, {
      payload: { skip: 0, limit: 10 },
    });
  } catch (e) {
    yield put(deleteLoanSupportFailure(e.message));
  }
}
export function* BorrowLoanSaga(api, { payload }) {
  yield put(borrowLoanLoadingStart());
  console.log("borrow loan", payload);
  try {
    let token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.DefiLoanBorrowMarket,
      { details: payload, token },
      true
    );
    yield put(borrowLoanSuccss(result));
      
    const result2 = yield call(
      Api.callServer,
      api.getAllLoanType,
      { details: {skip :  0  , limit : 10}, token },
      true
    );
    
    yield put(allListingOfLoanSuccess(result2));
  } catch (e) {
    yield put(borrowLoanFailure(e.message));
  }
}
export function* getMywallets(api, { payload = {} }) {
  yield put(getMywalletsTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getMywallets,
      { token },
      true
    );
    yield put(getMywalletsSuccess(result));
  } catch (error) {
    ////////////console.log(error);
  } finally {
    yield put(getMywalletsTrigger(false));
  }
}

export function* getAccountByWalletId(api, { payload = {} }) {
  yield put(getAccountByWalletIdTrigger(true));
  try {
    let { id } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getAccountByWalletId,
      { token, id },
      true
    );
    yield put(getAccountByWalletIdSuccess(result));
  } catch (error) {
    ////////////console.log(error);
  } finally {
    yield put(getAccountByWalletIdTrigger(false));
  }
}

export function* getAccountAndBalanceByWalletId(api, { payload = {} }) {
  yield put(getAccountAndBalanceByWalletIdTrigger(true));
  try {
    const token = yield getAccessToken();
    const id = yield getWalletId();

    const result = yield call(
      Api.callServer,
      api.getAccountAndBalanceByWalletId,
      { token, id, payload },
      true
    );
    yield put(getAccountAndBalanceByWalletIdSuccess(result));
  } catch (error) {
  } finally {
    yield put(getAccountAndBalanceByWalletIdTrigger(false));
  }
}

export function* getCoinOrTokenBalance(api, { payload = {} }) {
  try {
    const { tokenName, address, walletId } = payload;

    yield put(getCoinOrTokenBalanceTrigger(true));
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getCoinOrTokenBalance,
      { token, tokenId, address, walletId },
      true
    );
    yield put(getCoinOrTokenBalanceSuccess(result));
  } catch (error) {
    ////////////console.log(error);
    alert(error.message);
  } finally {
    getCoinOrTokenBalanceTrigger(false);
  }
}

export function* activateCoinOrToken(api, { payload = {} }) {
  yield put(activateCoinOrTokenTrigger(true));
  try {
    const { coinId, walletId, address, name } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.activateCoinOrToken,
      {
        coinId,
        walletId,
        address,
        name,
        token,
      },
      true
    );
    yield put(activateCoinOrTokenSuccess(result));
  } catch (e) {
    ////////////console.log(e);
    yield put(activateCoinOrTokenFail(e));
  } finally {
    activateCoinOrTokenTrigger(false);
  }
}

export function* transferBalanceOrToken(api, { payload = {} }) {
  yield put(transferBalanceOrTokenTrigger(true));
  try {
    const { from, to, amount, tokenName, emailCode, authenticatorCode } =
      payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.transferBalanceOrToken,
      {
        from,
        to,
        amount,
        tokenId: tokenName,
        token,
        emailCode,
        authenticatorCode,
      },
      true
    );

    yield put(transferBalanceOrTokenSuccess(result));
    yield put(setWithdrawModalSuccess(false));
    toast.success("Transferred Successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
    const id = yield getWalletId();

    const result2 = yield call(
      Api.callServer,
      api.getAccountAndBalanceByWalletId,
      { token, id, payload },
      true
    );
    yield put(getAccountAndBalanceByWalletIdSuccess(result2));
  } catch (e) {
    ////////////console.log(e);
    yield put(transferBalanceOrTokenFail(e));
  } finally {
    transferBalanceOrTokenTrigger(false);
  }
}

export function* selectRegionAndLanguage(api, { payload = {} }) {
  yield put(selectRegionAndLanguageTrigger(true));
  try {
    const { region, language, currency } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.selectRegionAndLanguage,
      {
        region,
        language,
        currency,
      },
      true
    );
    yield put(selectRegionAndLanguageSuccess(result));
  } catch (e) {
    ////////////console.log(e);
    yield put(selectRegionAndLanguageFail(e));
  } finally {
    selectRegionAndLanguageTrigger(false);
  }
}

export function* exchangeTokenOrCoin(api, { payload = {}, callback }) {
  yield put(exchangeTokenorCoinTrigger(true));
  try {
    const {
      amount,
      amount2,
      tokenName1,
      tokenName2,
      exchangeRate,
      type,
      t1,
      t2,
    } = payload;
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const walletId2 = yield getfiatWalletId();
    const fromAddress = yield getAccountId(tokenName1);
    const toAddress = yield getAccountId(tokenName2);
    const decimals = yield getTokenDeciamls(tokenName1);
    const decimals2 = yield getTokenDeciamls(tokenName2);
    let final = `${amount}`;
    let amount21 = `${amount2}`;
    let data = {
      walletId,
      amount: final,
      tokenId1: tokenName1,
      tokenId2: tokenName2,
      amount2: amount21,
      exchangeRate: `${exchangeRate}`,
      token,
      walletId2,
    };
    if (t1 == "crypto" && t2 == "crypto") {
      data.type = "cToc";
      data.fromAddress = fromAddress;
      data.toAddress = toAddress;
    }
    if (t1 == "fiat" && t2 == "crypto") {
      data.toAddress = toAddress;
      data.type = "fToc";
    }
    if (t1 == "crypto" && t2 == "fiat") {
      data.type = "cTof";
      data.fromAddress = fromAddress;
    }
    if (t1 == "fiat" && t2 == "fiat") {
      data.type = "fTof";
    }
    const result = yield call(Api.callServer, api.exchange, data, true);

    yield put(exchangeTokenorCoinSuccess(result));
    yield put(setExchangeModalSuccess(false));
    if (type == "buy") {
      toast.success("Buy Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setbuyModalSuccess(false));
    } else
      toast.success("Exchanged Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    const id = yield getWalletId();
    const result2 = yield call(
      Api.callServer,
      api.getAccountAndBalanceByWalletId,
      { token, id },
      true
    );
    yield put(getAccountAndBalanceByWalletIdSuccess(result2));
    const result3 = yield call(Api.callServer, api.CallFiatBalance, {
      id: walletId2,
      token,
    });
    yield put(callFiatBalanceSuccess(result3));
  } catch (e) {
    ////////////console.log(e);
    yield put(exchangeTokenorCoinFail(e));
  } finally {
    yield put(exchangeTokenorCoinTrigger(false));
  }
}

export function* addLogo(api, { payload = {} }) {
  yield put(addLogoTrigger(true));
  try {
    const { logoType, file, id } = payload;

    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.addLogo,
      {
        logoType,
        token,
        file,
        id,
      },
      true
    );
    yield put(addLogoSuccess(result));
  } catch (e) {
    yield put(addLogoFail(e));
  } finally {
    yield put(addLogoTrigger(false));
  }
}

export function* addingExchangeInfoColors(api, { payload = {} }) {
  yield put(addExchangeColorTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.addexchangeinfoColors,
      {
        payload,
        token,
      },
      true
    );
    yield put(addExchangeColorSuccess(result));
  } catch (e) {
    yield put(addExchangeColorFail(e));
  } finally {
    yield put(addExchangeColorTrigger(false));
  }
}

export function* getUserTransaction(api, { payload = {} }) {
  ////////////console.log('payload for table' , payload)
  yield put(getUserTransactionTrigger(true));
  try {
    const { skip, limit, type, startDate, endDate } = payload;
    const token = yield getAccessToken();
    const id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.gettransferhistory,
      {
        token,
        id,
        skip,
        limit,
        type,
        startDate,
        endDate,
        orderBy: payload.orderBy,
        sort: payload.sort,
        obj: payload.obj,
      },
      true
    );
    yield put(getUserTransactionSuccess(result));
  } catch (error) {
    ////////////console.log(error);
    alert(error.message);
  } finally {
    yield put(getUserTransactionTrigger(false));
  }
}
export function* getUserTransactionDownload(api, { payload = {} }) {
  yield put(getUserTransactionTrigger(true));
  try {
    const { type, startDate, endDate } = payload;
    const token = yield getAccessToken();
    const id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.gettransferhistory,
      {
        token,
        id,
        skip: 0,

        type,
        startDate,
        endDate,
        orderBy: payload.orderBy,
        sort: payload.sort,
        obj: payload.obj,
      },
      true
    );
    yield put(getUserTransactionSuccessDownload(result));
  } catch (error) {
    ////////////console.log(error);
    alert(error.message);
  } finally {
    yield put(getUserTransactionTrigger(false));
  }
}
export function* getExchangehistory(api, { payload = {} }) {
  yield put(ExhangeHistoryTrigger(true));
  try {
    let { skip, limit, startDate, endDate, orderBy, sort } = payload;
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getExchangeHistory,
      { token, walletId, skip, limit, startDate, endDate, orderBy, sort },
      true
    );
    yield put(ExhangeHistorySuccess(result));
  } catch (error) {
    ////////////console.log(error);
  } finally {
    yield put(ExhangeHistoryTrigger(false));
  }
}

export function* getExchangehistoryDownload(api, { payload = {} }) {
  yield put(ExhangeHistoryTrigger(true));
  try {
    let { startDate, endDate, orderBy, sort } = payload;
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getExchangeHistory,
      { skip: 0, token, walletId, startDate, endDate, orderBy, sort },
      true
    );

    yield put(ExhangeHistoryDownloadSuccess(result));
  } catch (error) {
    ////////////console.log(error);
  } finally {
    yield put(ExhangeHistoryTrigger(false));
  }
}

export function* getDepWithdrawhistory(api, { payload = {} }) {
  yield put(getDepositWithHistoryTrigger(true));
  try {
    let { type, startDate, endDate, orderBy, sort } = payload;
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.depositwithHistory,
      {
        token,
        walletId,
        skip: payload.skip,
        limit: payload.limit,
        type,
        startDate,
        endDate,
        currencyType: payload.currencyType,
        orderBy,
        sort,
      },
      true
    );
    yield put(getDepositWithHistorySuccess(result));
  } catch (error) {
    ////////////console.log(error);
  } finally {
    yield put(getDepositWithHistoryTrigger(false));
  }
}

// to handle pdf and excel download for deposit
export function* getDepWithdrawhistoryDownload(api, { payload = {} }) {
  yield put(getDepositWithHistoryTrigger(true));
  try {
    let { type, startDate, endDate, orderBy, sort } = payload;
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.depositwithHistory,
      {
        token,
        walletId,
        type,
        skip: 0,
        startDate,
        endDate,
        currencyType: payload.currencyType,
        orderBy,
        sort,
      },
      true
    );
    payload.callback(result);
    yield put(getDepositWithHistorySuccessDownload(result));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(getDepositWithHistoryTrigger(false));
  }
}
// to handle download

export function* getTranferHistoryCount(api, { payload = {} }) {
  yield put(getTransferhistoryCountTrigger(true));
  try {
    let { type, startDate, endDate } = payload || {};
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.gettransferhistorycount,
      {
        id: walletId,
        type,
        startDate,
        endDate,
        token,
      },
      true
    );
    yield put(transferHistoryCountSuccess(result));
  } catch (e) {
    ////////////console.log(e);
  } finally {
    yield put(getTransferhistoryCountTrigger(false));
  }
}

export function* getExchangeHistoryCount(api, { payload = {} }) {
  yield put(getTransferhistoryCountTrigger(true));
  try {
    let { type, startDate, endDate } = payload || {};
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.exchangeHistoryCount,
      {
        walletId,
        type,
        startDate,
        endDate,
        token,
      },
      true
    );
    yield put(exchangeHistoryCountSuccess(result));
  } catch (e) {
  } finally {
    yield put(getTransferhistoryCountTrigger(false));
  }
}

export function* getDepositWithHistoryCount(api, { payload = {} }) {
  try {
    let { type, startDate, endDate, currencyType } = payload || {};
    const token = yield getAccessToken();
    const walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.depositwithHistoryCount,
      {
        walletId,
        type,
        token,
        startDate,
        endDate,
        currencyType,
      },
      true
    );
    //////////console.log(result)
    yield put(getDepositWithCountSuccess(result));
  } catch (e) {}
}

export function* addPlatformTokens(api, { payload = {} }) {
  yield put(addPlatformTokensTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.addPlatformToken,
      {
        payload,
        token,
      },
      true
    );

    const result2 = yield call(
      Api.callServer,
      api.getPlatformToken,
      { token },
      true
    );
    yield put(addPlatformTokensSuccess(result));
    yield put(getPlatformTokensSuccess(result2));
  } catch (e) {
    yield put(addPlatformTokensFail(e));
  } finally {
    yield put(addPlatformTokensTrigger(false));
  }
}

export function* addExchangeSuperManager(api, { payload = {} }) {
  yield put(addExchangeSuperManagerTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.addExchangeBySuperManager,
      {
        payload,
        token,
      },
      true
    );
    yield put(addExchangeSuperManagerSuccess(result));
  } catch (e) {
    yield put(addExchangeSuperManagerFail(e));
  } finally {
    yield put(addExchangeSuperManagerTrigger(false));
  }
}

export function* getPlatformTokens(api, { payload = {} }) {
  yield put(getPlatformTokensTrigger(true));
  try {
    const token = yield getAccessToken();
    const { skip, limit, orderBy, sort } = payload;

    const result = yield call(
      Api.callServer,
      api.getPlatformToken,
      { token, skip, limit, orderBy, sort },
      true
    );

    yield put(getPlatformTokensSuccess(result));
  } catch (error) {
  } finally {
    yield put(getPlatformTokensTrigger(false));
  }
}

export function* getPlatformCurrencys(api, { payload = {} }) {
  yield put(getPlatformCurrencysTrigger(true));
  try {
    const token = yield getAccessToken();
    const { skip, limit, orderBy, sort } = payload;
    //////////console.log(payload)
    const result = yield call(
      Api.callServer,
      api.getPlatformCurrency,
      { token, skip, limit, orderBy, sort },
      true
    );

    yield put(getPlatformCurrencysSuccess(result));
  } catch (error) {
  } finally {
    yield put(getPlatformCurrencysTrigger(false));
  }
}

export function* getFiatBalance(api, { payload = {} }) {
  yield put(getFiatBalanceTrigger(true));
  try {
    const token = yield getAccessToken();
    let walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getFiatBalanceByWalletId,
      { token, walletId: walletId },
      true
    );

    yield put(getFiatBalanceSuccess(result));
  } catch (error) {
  } finally {
    yield put(getFiatBalanceTrigger(false));
  }
}

export function* activateFiatCurrency(api, { payload = {} }) {
  yield put(activateFiatCurrencyTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.ActivateFiatCurrency,
      {
        payload,
        token,
      },
      true
    );
    yield put(activateFiatCurrencySuccess(result));
  } catch (e) {
    yield put(activateFiatCurrencyFail(e));
  } finally {
    yield put(activateFiatCurrencyTrigger(false));
  }
}

export function* getExchangerate(api, { payload = {} }) {
  yield put(getExchangerateTrigger(true));
  try {
    let { symbol1, symbol2 } = payload || {};
    const token = yield getAccessToken();
    if (symbol1 != undefined && symbol2 != undefined) {
      const result = yield call(
        Api.callServer,
        api.exchangeRate,
        {
          symbol1,
          symbol2,
          token,
        },
        true
      );
      yield put(getExchangerateSuccess(result));
    }
  } catch (e) {
    yield put(getExchangerateFail(e));
  } finally {
    yield put(getExchangerateTrigger(false));
  }
}

export function* getFiatExchangerate(api, { payload = {} }) {
  yield put(getFiatExchangerateTrigger(true));
  try {
    let { symbol1, symbol2 } = payload || {};
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.exchangeRate,
      {
        symbol1,
        symbol2,
        token,
      },
      true
    );
    yield put(getFiatExchangerateSuccess(result));
  } catch (e) {
    yield put(getFiatExchangerateFail(e));
  } finally {
    yield put(getFiatExchangerateTrigger(false));
  }
}
export function* getWsdata(api, { payload = {} }) {
  let newdata = payload;

  if (newdata && newdata.e) {
    if (newdata.e == "24hrTicker") {
      if (newdata.s === "BNBUSDT")
        yield put(updatewsdataSuccess({ bnbpdata: newdata }));
      if (newdata.s === "ETHUSDT")
        yield put(updatewsdataSuccess({ ethpdata: newdata }));
      if (newdata.s === "BTCUSDT")
        yield put(updatewsdataSuccess({ btcpdata: newdata }));
      if (newdata.s === "BUSDUSDT")
        yield put(updatewsdataSuccess({ busdpdata: newdata }));
    } else {
      if (newdata.s === "BNBUSDT")
        yield put(updatewsdataSuccess({ bnbdata: newdata }));
      if (newdata.s === "ETHUSDT")
        yield put(updatewsdataSuccess({ ethdata: newdata }));
      if (newdata.s === "BTCUSDT")
        yield put(updatewsdataSuccess({ btcdata: newdata }));
      if (newdata.s === "BUSDUSDT")
        yield put(updatewsdataSuccess({ busddata: newdata }));
    }
  }
}

export function* getDectExchangerate(api, { payload = {} }) {
  yield put(getDectExchangePriceTrigger(true));
  try {
    let { sAmount, sTID, sToken, bTID, bToken } = payload || {};
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getDExchangePrice,
      {
        token,
        sAmount,
        sTID,
        sToken,
        bTID,
        bToken,
      },
      true
    );
    yield put(getDectExchangePriceSuccess(result));
  } catch (e) {
    yield put(getDectExchangePriceFail(e?.message));
  } finally {
    yield put(getDectExchangePriceTrigger(false));
  }
}

export function* postDectExchangerate(api, { payload = {} }) {
  yield put(postDectExchangePriceTrigger(true));
  try {
    let { sAmount, sTID, sToken, bTID, bToken } = payload || {};
    let walletId = yield getWalletId();
    console.log("ddd", sAmount, sTID, sToken, bTID, bToken, walletId);
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.postDExchangePrice,
      {
        data: {
          sellToken: sToken,
          buyToken: bToken,
          sellAmount: sAmount,
          sellTokenId: sTID,
          buyTokenId: bTID,
          walletId: walletId,
        },
        token,
      },
      true
    );
    yield put(postDectExchangePriceSuccess(result));
  } catch (e) {
    yield put(getDectExchangePriceFail(e?.message));
  } finally {
    yield put(postDectExchangePriceTrigger(false));
  }
}

// personal loan
export function* CreatePersonalLoanAdminSaga(api, { payload }) {
  yield put(createPersonalLoanLoading());
  try {
    const token = yield getAccessToken();
    const { openSuccess } = payload;
    delete payload["openSuccess"];
    const result = yield call(
      Api.callServer,
      api.createPersonalLoanAdmin,
      { details: payload, token },
      true
    );

    yield put(createPersonalLoanSuccess(result));
    openSuccess();
  } catch (e) {
    yield put(createPersonalLoanFailure(e.message));
  }
}

export function* AdminPersonalLoanListSaga(api, { payload }) {
  yield put(adminAllPersonalLoanLoadingStart());
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.GetPersonalLoanRequestLoanAdmin,
      { details: payload, token },
      true
    );
    yield put(adminAllPersonalLoanSuccess(result));
  } catch (e) {
    yield put(adminAllPersonalLoanFailure(e.message));
  }
}


export function* EditPersonalLoanAdminSaga(api, { payload }) {
  yield put(editPersonalLoanLoadingStart());
  try {
    const { openSuccess } = payload;
    delete payload["openSuccess"];
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.EditPersonalLoanAdmin,
      { details: payload, token },
      true
    );

    yield put(editPersonalLoanSuccess(result));
    openSuccess();
    yield call(AdminPersonalLoanListSaga, api, {
      payload: { skip: 0, limit: 10 },
    });
  } catch (e) {
    yield put(editPersonalLoanFailure(e.message));
  }
}

export function* DeletePersonalLoanAdminSaga(api, { payload }) {
  yield put(deletePersonalLoanLoadingStart());
  const { openSuccess,setDeleteLoanError } = payload;
  try {
    delete payload["openSuccess"];
    delete payload['setDeleteLoanError']

    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.DeletePersonalLoanAdmin,
      { details: payload, token },
      true
    );
    yield put(deletePersonalLoanSuccess(result));
    openSuccess();
    yield call(AdminPersonalLoanListSaga, api, {
      payload: { skip: 0, limit: 10 },
    });
  } catch (e) {
    setDeleteLoanError(e.message)
    yield put(deletePersonalLoanFailure(e.message));
  }
}


export function* GetPersonalLoanPublicSaga(api, { payload }) {
  yield put(publicAllPersonalLoanLoadingStart());
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.GetPersonalLoanRequestLoanPublic,
      { details: payload, token },
      true
    );
    yield put(publicAllPersonalLoanSuccess(result));
  } catch (e) {
    yield put(publicAllPersonalLoanFailure(e.message));
  }
}



export function* GetDynamicInterestRateSaga(api, { payload }) {
  yield put(getInterestRateForPersonalLoadingStart());
  const {setBorrowLoanError} = payload;
  delete payload['setBorrowLoanError']
  try {
    console.log('payload' , payload)
    let token = yield getAccessToken()
    const result = yield call(Api.callServer, 
      api.GetDynamicInterestRateforPersonalLoan,{details : payload , token}, true);
    yield put(getInterestRateForPersonalSuccess(result));
  } catch (e) {
    setBorrowLoanError(e.message)
    yield put( getInterestRateForPersonalFailure(e.message));
  }
}

export function* BorrowPersonalLoanSaga(api, { payload }) {
  yield put(addPersonalLoanRequestLoadingStart());
  const {openSuccess, setBorrowLoanError} = payload;
  delete payload['setBorrowLoanError']
  try {
    delete payload["openSuccess"]
    let token = yield getAccessToken()
    const result = yield call(Api.callServer, api.CreateBorrowPersonalLoanRequest,{details : payload , token}, true);
    yield put(addPersonalLoanRequestSuccess(result));
    openSuccess();
  } catch (e) {
    setBorrowLoanError(e.message)
    yield put( addPersonalLoanRequestFailure(e.message));
  }
}

export function* PersonalLoanRequestListSaga(api, { payload }) {
  yield put(personalLoanRequestLoadingStart());
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getAllPersonalLoanRequest,
      { details: payload, token },
      true
    );
    yield put(personalLoanRequestSuccess(result));
  } catch (e) {
    yield put(personalLoanRequestFailure(e.message));
  }
}

export function* PersonalLoanRequestAdminApprovalSaga(api , {payload}){
  yield put(approvePersonalLoanRequestLoadingStart())
  const {setLoanApproveError,setShowSuccess,setActionMessage} = payload
  try{
    delete payload['setLoanApproveError']
    delete payload['setShowSuccess']
    delete payload['setActionMessage']

  
    const token = yield getAccessToken()
    
    const result = yield call(Api.callServer , api.approvePersonalLoanRequestByAdmin, {details : payload  , token} , true)
    yield put(approvePersonalLonRequestSuccess(result))
    setShowSuccess(true)
    yield call( PersonalLoanRequestListSaga , api, {
      payload: { skip: 0, limit: 10 },
    })
  }catch(e){
    if(e.message.includes("Insufficient balance")){
      let message = "You do not have sufficient funds to approve this request."
      setLoanApproveError(true)
      setShowSuccess(true)
      setActionMessage(message)
      yield put(approvePersonalLoanRequestFailure(message))

    }else{
      setLoanApproveError(true)
      setShowSuccess(true)
      setActionMessage(e.message)
      yield put(approvePersonalLoanRequestFailure(e.message))
    }
   
    
  }
}
