import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import copy from "@images/copy.svg";
import { Modal, Container, Form, Col } from "react-bootstrap";
import QRCode from "react-qr-code";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import CustomButton from "src/components/custom-button/CustomButton";
import { ReactComponent as DownIcon } from "@images/down.svg";
import moment from "moment";
import { ReactComponent as EllipseIcon } from "@images/Ellipse.svg";
import { ReactComponent as linkqrIcon } from "@images/linkqr.svg";
import { ReactComponent as FacebookIcon } from "@images/Facebook.svg";
import { ReactComponent as WhatsApp } from "@images/WhatsApp.svg";
import { ReactComponent as Gmail } from "@images/Gmail.svg";
import { ReactComponent as Mail } from "@images/Mail.svg";
import { ReactComponent as Telegram } from "@images/Telegram.svg";
import { ReactComponent as Viber } from "@images/Viber.svg";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import "@components/paymentSuccess-popup/paymentSuccessStyle.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PaymentPopup = ({
  getplatformTokensinfo,
  setShowModal,
  data,
  show1,
  paymentbytoken,
  getExchangerate,
  createpaymentLink,
  myaccountandbalance,
  paymentToken,
  thememode,
  exchangeRates,
  settings,
}) => {
  let [selected, setSelectedIndex] = useState(0);
  let [selected2, setSelectedIndex2] = useState(1);
  const [amount, setamount] = useState("");
  const [email, setemail] = useState("");
  let { result, sym1, sym2 } = exchangeRates || {};
  const [validated, setValidated] = useState(false);
  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter(
      (item) => item.coin.exchangeSymbol == cur
    );
    let address = _.get(obj, "[0].address", 0);

    return address;
  };
  useEffect(() => {
    getExchangerate({
      symbol1:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected] &&
        getplatformTokensinfo[selected].exchangeSymbol,
      symbol2:
        getplatformTokensinfo &&
        getplatformTokensinfo[selected2] &&
        getplatformTokensinfo[selected2].exchangeSymbol,
    });
  }, [selected2, selected]);

  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="paymentSuccess"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
        <Container>
          <div className="status">
            <span className="textofstatus">{t("Status")}:</span>
          </div>

          <div className="compete-section">
            <div className="greenicon">
              <EllipseIcon className="dotclass" />
            </div>
            <div className="completed">
              <span className="textofcomplete">{t("Completed")}</span>
            </div>
          </div>

          <div className="qrcode-section">
            <QRCode
              size="200"
              value={`${window.location.origin}/pay/${
                paymentToken && paymentToken.link
              }`}
              bgColor={
                thememode == "light"
                  ? settings.lightTheme.bgwhite
                  : settings.darkTheme.bgwhite
              }
              fgColor={
                thememode == "light"
                  ? settings.lightTheme.textdarker
                  : settings.darkTheme.textdarker
              }
            />
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("PaymentID")}</span>
            </div>
            <div className="value">
              <span className="valuetext transectionid">
                #{paymentbytoken.paymentLinkToken}
              </span>
            </div>
          </div>

          <div className="detail ">
            <div className="label">
              <span className="labeltext">{t("Date")}</span>
            </div>
            <div className="value">
              <span className="valuetext transectionid">
                {moment(paymentbytoken.createdOn).format("MM/DD/YYYY hh:mm A")}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Recepient")}</span>
            </div>
            <div className="value">
              {paymentbytoken.toPaymentLinkWallet &&
                paymentbytoken.toPaymentLinkWallet.user && (
                  <span className="valuetext">
                    {paymentbytoken.toPaymentLinkWallet.user.name}{" "}
                    {paymentbytoken.toPaymentLinkWallet.user.subName}
                  </span>
                )}
            </div>
          </div>

          <div className="currencytoconvert">
            <div className="box">
              <div className="link">
                <span className="linktxt">{t("Paymentlinkwillbecreated")}</span>
              </div>
              <div className="commonsection">
                <span className="textline1">
                  {paymentbytoken.amount}{" "}
                  {paymentbytoken.toPaymentLinkCoin &&
                    paymentbytoken.toPaymentLinkCoin.exchangeSymbol}
                </span>
                <span className="textline2">{paymentbytoken.exchangeTo} </span>
              </div>
              <div className="imgsection">
                <DownIcon className="down" />
              </div>
              <div className="commonsection">
                <span className="textline1">
                  {Number(result * paymentbytoken.amount).toFixed(5)}
                </span>
                <span className="textline2">
                  ( 1{" "}
                  {paymentbytoken.toPaymentLinkCoin &&
                    paymentbytoken.toPaymentLinkCoin.exchangeSymbol}{" "}
                  / {Number(result).toFixed(5)}{" "}
                  {paymentbytoken.fromPaymentLinkCoin &&
                    paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                  )
                </span>
                <span className="textline2">{paymentbytoken.acceptAs}</span>
              </div>
            </div>
          </div>

          <div className="detail1">
            <div className="label">
              <span className="labeltext">{t("Copylink")}</span>
            </div>
            <div className="value">
              <div className="textclass">
                <span className="valuetext tcolor transectionid">
                  {` ${window.location.origin}/pay/${
                    paymentToken && paymentToken.link
                  }`}
                </span>
              </div>
              <div className="imgcontainer">
                <img
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/pay/${
                        paymentToken && paymentToken.link
                      }`
                    );
                    toast.success(t(`${'copied'}`), {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }}
                  src={copy}
                  className="copy"
                />
              </div>
            </div>
          </div>

          <div className="detail1">
            <div className="label">
              <span className="labeltext">{t("ShareLink")}</span>
            </div>
            <div className="value">
              <div className="socialicon">
                <FacebookShareButton
                  url={`${window.location.origin}/pay/${
                    paymentToken && paymentToken.link
                  }`}
                >
                  <FacebookIcon className="arrowclass11" />
                </FacebookShareButton>
              </div>
              <div className="socialicon">
                <WhatsappShareButton
                  url={`${window.location.origin}/pay/${
                    paymentToken && paymentToken.link
                  }`}
                >
                  <WhatsApp className="arrowclass11" />
                </WhatsappShareButton>
              </div>
              <div className="socialicon">
                <EmailShareButton
                  url={`${window.location.origin}/pay/${
                    paymentToken && paymentToken.link
                  }`}
                >
                  <Gmail className="arrowclass11" />
                </EmailShareButton>
              </div>
              <div className="socialicon">
                <EmailShareButton
                  url={`${window.location.origin}/pay/${
                    paymentToken && paymentToken.link
                  }`}
                >
                  <Mail className="arrowclass11" />
                </EmailShareButton>
              </div>
              <div className="socialicon">
                <TelegramShareButton
                  url={`${window.location.origin}/pay/${
                    paymentToken && paymentToken.link
                  }`}
                >
                  <Telegram className="arrowclass11" />
                </TelegramShareButton>
              </div>
              <div className="socialicon">
                <ViberShareButton
                  url={`${window.location.origin}/pay/${
                    paymentToken && paymentToken.link
                  }`}
                >
                  <Viber className="arrowclass11" />
                </ViberShareButton>
              </div>
            </div>
          </div>

          <div className="buttonsection">
            <div className="buttoncontainer pl">
              <CustomButton
                type="submit"
                name={t("Print")}
                filled={true}
                primary={true}
                onClick={() => setShowModal(false)}
              />
            </div>

            <div className="buttoncontainer pr">
              <CustomButton
                type="submit"
                name={t("Close")}
                filled={true}
                primary={true}
                onClick={() => setShowModal(false)}
              />
            </div>
          </div>
          {/* <div className="currencytoconvert">
            <div  >
              <p> </p>
              <img
                onClick={() => {
                   navigator.clipboard.writeText(
                    `${window.location.origin}/pay/${paymentToken&&paymentToken.link}`
                  );
                }}
                src={copy}
                className="copy"
              />
            </div>
            <div className="buttoncontainer">
              <CustomButton
                type="submit"
                name="Clsoe"
                filled={true}
                primary={true}
                onClick={() => setShowModal(false)}
              />
            </div>
          </div> */}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(PaymentPopup);
