import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import Loader from "@components/loader/Loader";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import select from "@images/select.svg";
import "@components/withdraw-form/WithdrawFormStyles.scss";
import WithdrawSmallpopup from "../withdraw-smallpopup/WithdrawSmallpopup";
import BigNumber from "bignumber.js";
import { ReactComponent as PersonIcon } from "@images/personal.svg";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddressDropdown from "@components/address-dropdown/AddressDropdown";
import MaxDropdown from "@components/max-dropdown/MaxDropdown";
import LoginConfirmationPopup from "../login-confirmation-popup/LoginConfirmationPopup";

function WithdrawForm(props) {
  let {
    coinItem,
    getBal,
    option,
    setcoinItem,
    transferbalanceortokenloading,
    withdraw,
    getAdd,
    transferBalanceOrTokenerror,
    setHideThis,
    setShowWithdraw,
    exchange,
    MyProfileInfo,
    thememode,
  } = props || {};
  const [validated, setValidated] = useState(false);
  const [address, setaddress] = useState("");
  const [amount, setamount] = useState("");
  const [showmodal, setshowmodal] = useState(false);
  const [showErrRed, setShowErrRed] = useState(false);
  const [showConfirm, setshowConfirm] = useState(false);
  let [Selected, setSelectedIndex] = useState(0);
  const [currentLang, setCurrentlang] = useState("en");

  useEffect(() => {
    setCurrentlang(localStorage.lang);
  }, [localStorage.lang]);

  useEffect(() => {
    
    setSelectedIndex(option.findIndex((e) => e.id == coinItem.id));
  }, [coinItem]);
  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      setHideThis(true);
      setshowmodal(true);
    }
  };
  const { t, i18n, ready } = useTranslation();

  // function addressdropdown(classs){
  //   return(
  //     <Dropdown className={`addressdropdown ${classs}`} align="end">
  //       <Dropdown.Toggle>
  //         <div>
  //           <div className="topblock">
  //             <PersonIcon className="personicon" />
  //           </div>
  //           <div className="select-address d-none d-lg-inline-block">
  //             <span className="selectaddresstext">Select from Address Book...</span>
  //           </div>
  //         </div>
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu>
  //         <Dropdown.Item><img src={select} className="dropdownimg" /><span>Name 1</span></Dropdown.Item>
  //         <Dropdown.Item><img src={select} className="dropdownimg" /><span>Name 2</span></Dropdown.Item>
  //           <Dropdown.Item><img src={select} className="dropdownimg"  /><span>Name 3</span></Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   )
  // }

  return (
    <div className="withdraw">
      <Container>
        <div className="withdrawspace">
          <span className="withtitle">
            {t("Withdraw")} {coinItem.tokenName}
          </span>
        </div>
        <LoginConfirmationPopup
          show1={showConfirm}
          setShowModal={(e) => {
            setshowConfirm(false);
          }}
          withdraw
          thememode={thememode}
          exchange={exchange}
          requestEmailCode={() =>
            props.requestEmailCode({
              withdrawal: true,
              lang: currentLang,
            })
          }
          // signInLoading={props.signInLoading}

          ConfirmEmailCode={(e) => {
            withdraw({
              from: getAdd(coinItem.id),
              to: address,
              amount: `${amount}`,
              tokenName: Number(coinItem.id),
              emailCode: e.code,
              authenticatorCode: e.verifyToken,
            });
            setshowConfirm(false);
            setHideThis(false);
          }}
          Error={t(`${props.ConfirmcodeModalError}`)}
        />

        <WithdrawSmallpopup
          show1={showmodal}
          setHideThis={setHideThis}
          from={getAdd(coinItem.id)}
          setShowModal={setshowmodal}
          MyProfileInfo={MyProfileInfo}
          address={address}
          amount={`${amount - Number(coinItem.fees)} ${
            coinItem.exchangeSymbol
          }`}
          onPress={() => {
            // code without authenticator
            withdraw({
              from: getAdd(coinItem.id),
              to: address,
              amount: `${amount}`,
              tokenName: Number(coinItem.id),
            });
            setHideThis(false);
            setshowmodal(false);

            ///code with authenticator

            // setshowConfirm(true);

            // setshowmodal(false);
          }}
        />
        <Form
          noValidate
          validated={validated}
          onSubmit={onSendmsg}
          className="withdrawspace"
        >
          <Row>
            <Col lg={4}>
              <div className="textleft cointop">
                <span className="labelfont">{t("SelectCoin")}</span>
              </div>
            </Col>

            <Col lg={8}>
              <DropdownSearch
                setcoinItem={setcoinItem}
                options={option}
                coinItem={coinItem}
                setSelectedIndex={(e) => {
                  setSelectedIndex(e);
                  setcoinItem(option[e]);
                }}
                Selected={Selected}
              />
              <div className="textleft balancespace">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 500 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {Number(getBal(coinItem.id)).toFixed(6)}{" "}
                      {coinItem.exchangeSymbol}
                    </Tooltip>
                  )}
                >
                  <span className="labelfont balancetext">
                    {t("Balance")}: {Number(getBal(coinItem.id)).toFixed(6)}{" "}
                    {coinItem.exchangeSymbol}
                  </span>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
          <Row className="mt-lg-5">
            <Col lg={4}>
              <div className="textleft cointop">
                <span className="labelfont">{t("Amount")}</span>
              </div>
            </Col>
            <Col lg={8}>
              <div className="marginbtm amountmax area1">
                <InputField
                  value={amount}
                  // linktext={t("MAX")}
                  onChange={(e) => {
                    setamount(e.target.value);
                    if (
                      BigNumber(e.target.value).isLessThan(
                        coinItem.minimumvalue
                      ) &&
                      e.target.value !== ""
                    )
                      setShowErrRed(true);
                    else setShowErrRed(false);
                  }}
                  required
                  type={"number"}
                  className={"roundedborderfield"}
                  link={true}
                  linktext={
                    <span className="addressbtnwrapper hidden">
                      <MaxDropdown
                        setamount={setamount}
                        bal={getBal(coinItem.id)}
                      />
                    </span>
                  }
                  // onPress={() => setamount(getBal(coinItem.id)).toFixed(6)}
                />
              </div>
              <div className="percentage d-none d-lg-block">
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(
                      BigNumber(getBal(coinItem.id)).dividedBy(3).toFixed(6)
                    )
                  }
                >
                  25%
                </span>
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(
                      BigNumber(getBal(coinItem.id)).dividedBy(2).toFixed(6)
                    )
                  }
                >
                  50%
                </span>
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(
                      BigNumber(getBal(coinItem.id)).dividedBy(1.33).toFixed(6)
                    )
                  }
                >
                  {" "}
                  75%
                </span>
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(BigNumber(getBal(coinItem.id)).toFixed(6))
                  }
                >
                  100%
                </span>
              </div>
              {/* {BigNumber(amount).isLessThan(coinItem.minimumvalue) ? ( */}
              <div
                className={`textleft balancespace ${
                  (showErrRed && ` withdrawallimitred`) || `withdrawallimit`
                }`}
              >
                <span className="labelfont lablesmallfont">
                  {t("Minimum") +
                    " " +
                    t("withdrawal") +
                    " " +
                    t("limit") +
                    " " +
                    t("is")}{" "}
                  : {Number(coinItem.minimumvalue)} {coinItem.exchangeSymbol}
                </span>
              </div>
              {/* ) : null} */}
            </Col>
          </Row>
          <Row className="mt-lg-4">
            <Col lg={4}>
              <div className="textleft addrtitle">
                <span className="labelfont">{t("AddressToWithdraw")}</span>
              </div>
            </Col>
            <Col lg={8}>
              <div className="marginbtm addr area2">
                <InputField
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  required
                  className={"roundedborderfield"}
                  // link={true}
                  // linktext={
                  //   <span className="addressbtnwrapper d-flex d-lg-none">
                  //     <AddressDropdown classs={"d-none"} />
                  //   </span>
                  // }
                />
              </div>
              {/* <div className="d-none d-lg-block">
                <AddressDropdown classs={"d-inline-block"} />
              </div> */}
            </Col>
          </Row>
          <div className="outercontainer">
            {transferbalanceortokenloading && (
              <Loader msg={t("TransactionWaitMessage")} />
            )}
            <Row>
              <Col lg={6} xs={6}>
                <div className="textleft addrtitle">
                  <span className="labelfont">{t("Withdrawalfees")}</span>
                </div>
              </Col>
              <Col lg={6} xs={6}>
                <div className="textleft addrtitle">
                  <span className="labelfont">
                    {coinItem.fees} {coinItem.exchangeSymbol}
                  </span>
                </div>
              </Col>
            </Row>
            {
              <div>
                <>
                  {BigNumber(amount).isGreaterThanOrEqualTo(
                    coinItem.minimumvalue
                  ) && (
                    <Row>
                      <Col lg={6} xs={6}>
                        <div className="textleft addrtitle recievebal">
                          <span className="labelfont">
                            {t("YouWillRecieve")}
                          </span>
                        </div>
                      </Col>
                      <Col lg={6} xs={6}>
                        <div className="textleft addrtitle  recievebal">
                          <span className="labelfont">
                            {amount - Number(coinItem.fees)}{" "}
                            {coinItem.exchangeSymbol}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {amount != "" &&
                    !BigNumber(getBal(coinItem.id)).isGreaterThanOrEqualTo(
                      amount
                    ) && (
                      <div className="insufficientbal">
                        <span>{t("InsufficientBalance")}</span>
                      </div>
                    )}

                  {
                    <div className="mt-lg-5">
                      <CustomButton
                        disable={
                          amount == "" ||
                          !BigNumber(amount).isGreaterThan(0) ||
                          amount - Number(coinItem.fees) < 0 ||
                          !BigNumber(amount).isGreaterThanOrEqualTo(
                            coinItem.minimumvalue
                          ) ||
                          !BigNumber(
                            getBal(coinItem.id)
                          ).isGreaterThanOrEqualTo(amount)
                        }
                        type="submit"
                        name={t("Continue")}
                        filled={true}
                        primary={true}
                      />
                    </div>
                  }
                </>
              </div>
            }
            {coinItem.withdrawStatus &&
            coinItem.withdrawStatus === "Disable" ? (
              <span className="deactivationMessage">
                {t("Withdrawisdeactivated")}
              </span>
            ) : (
              <></>
            )}
            <div className="insufficientbalerr">
              <span>{t(`${transferBalanceOrTokenerror}`)}</span>
            </div>
          </div>
        </Form>
      </Container>
    </div>
  );
}

const mapStateToProps = ({ app = {}, user = {}, wallet = {} }) => {
  const transferBalanceOrTokenerror = _.get(
    wallet,
    "transferBalanceOrTokenerror.message",
    ""
  );
  const signInError = _.get(user, "signInError", false);
  const MyProfileInfo = _.get(user, "MyProfileInfo", {});
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const transferbalanceortokenloading = _.get(
    wallet,
    "transferbalanceortokenloading",
    false
  );
  const ConfirmcodeModalError = _.get(user, "ConfirmcodeModalError", {});
  const emailIDS = _.get(user, "MyProfileInfo.email", "");

  return {
    exchange,
    transferBalanceOrTokenerror,
    signInError,
    exchangeId,
    transferbalanceortokenloading,
    MyProfileInfo,
    thememode,
    ConfirmcodeModalError,
    emailIDS,
  };
};

const mapDispatchToProps = (dispatch) => ({
  withdraw: (data) =>
    dispatch({ type: "transferBalanceOrTokenCalled", payload: data }),
  requestEmailCode: (data) =>
    dispatch({ type: "requestEmailCodeCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawForm);
