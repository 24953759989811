import { ReactComponent as ModeIcon } from "@images/m1.svg";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import Container from "react-bootstrap/Container";
import React, { Fragment, useState, useEffect } from "react";
import "@components/navbar/OnboardingNavbarStyles.scss";
import { ReactComponent as Lightmode } from "@images/lightmode.svg";
import { ReactComponent as Darkmode } from "@images/darkmode.svg";
import { ReactComponent as DropdownarrowIcon } from "@images/dropdown.svg";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../store/translatelanguageredux";
import BusinessSignup from "src/features/onboarding/business-signup/BusinessSignup";
import SignIn from "src/features/onboarding/signin/SignIn";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import RequestSent from "../request-sent/RequestSent";
import SmallDropdown from "../small-dropdown/SmallDropdown";
import PersonalBusinessToogle from "../personal-business-toogle/PersonalBusinessToogle";

function OnboardingNavbar(props) {
  const {
    thememode,
    updateTheme,
    auth,
    exchange,
    businessApproval,
    businessForm,
    updateProfileLanguage,
    getMyprofile,
    role,
    changerole,
    defaultrole,
    setimageChange,
    setimageChange1,
    branch,
    showBusinessForm,
  } = props || {};
  const [isToogleOn, setIsToggleOn] = useState(
    defaultrole == "Personal" ? true : false
  );
  const [shownotify, setShowNotify] = useState(true); // set false to hide noifications
  // useEffect(() => {
  //   if (thememode == "light") {
  //     setIsToggleOn(true);
  //   } else {
  //     setIsToggleOn(false);
  //   }
  // }, []);

  const languages = [
    {
      key: "ENG",
      fullLanguageName: "English",
      apiKey: "en",
    },
    {
      key: "TUR",
      fullLanguageName: "Turkish",
      apiKey: "tr",
    },
    {
      key: "RUS",
      fullLanguageName: "Russian",
      apiKey: "ru",
    },
  ];

  const [language, setLanguage] = useState({
    key: "ENG",
    fullLanguageName: "English",
    apiKey: "en",
  });

  const currencies = [
    {
      key: "USD",
    },
    {
      key: "TUR",
    },
  ];
  const [currency, setCurrency] = useState({
    key: "USD",
  });

  const history = useHistory();
  const { t, i18n } = useTranslation();
  let selectedLAngarray = languages.filter(
    (itm) => itm.apiKey === localStorage.getItem("lang")
  );
  let selectLang =
    (selectedLAngarray &&
      selectedLAngarray[0] &&
      selectedLAngarray[0].fullLanguageName) ||
    "English";

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="circleAndArrow"
      ref={ref}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <div className="mobCircleDrp">{children}</div>
      <DropdownarrowIcon className="mobCircleDrpArrow" />
    </div>
  ));

  return (
    <div
      className={`onboardingmainwrapper ${
        thememode == "dark" ? "darknavwrapper" : null
      }`}
    >
      <div className="NavBar">
        <Fragment>
          <Navbar sticky="top" fixed="top" expand="lg" className="navwhitebg">
            <Container className="navbarcontainer">
              <h5 class="apptitle d-md-block d-none">
                {(isToogleOn && (
                  // <img
                  //   alt=""
                  //   src={
                  //     thememode == "light"
                  //     ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalLightmode}`
                  //   : `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalDarkmode}`
                  //   }
                  //   onClick={() => history.push("/")}
                  // />
                  <img src={paymentsolutionlogo} />
                )) || (
                  // <img
                  //   alt=""
                  //   className="cyborbusinesslogo"
                  //   src={
                  //     thememode == "dark"
                  //     ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessDarkmode}`
                  //     : `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessLightmode}`
                  //   }
                  //   onClick={() => history.push("/")}
                  // />
                  <img src={paymentsolutionlogo} />
                )}
              </h5>
              <Nav className="contentatend">
                <Nav.Item className="langDrp d-block d-md-none">
                  <div>
                    <Dropdown
                      autoclose
                      onSelect={(eventKey, event) => {
                        languages.map((data) => {
                          if (data.key == eventKey) {
                            localStorage.setItem("lang", data.apiKey);
                            i18n.changeLanguage(data.apiKey);

                            updateProfileLanguage({ language: data.apiKey });
                            setLanguage(data);
                          }
                        });
                      }}
                    >
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        {
                          languages.filter((data) => {
                            if (data.fullLanguageName == selectLang) {
                              return data;
                            }
                          })[0]?.apiKey
                        }
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Dropdown.Item eventKey="1">Red</Dropdown.Item> */}
                        {languages.map((data, index) => (
                          <Dropdown.Item
                            eventKey={data.key}
                            key={`drp${index}`}
                          >
                            {data.fullLanguageName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Nav.Item>
                <Nav.Item>
                  <div className="switchwrapper personalbusinessswitch">
                    <PersonalBusinessToogle
                      isToogleOn={isToogleOn}
                      setIsToogleOn={() => {
                        changerole(isToogleOn ? "Business" : "Personal");
                        setIsToggleOn(!isToogleOn);
                        setimageChange(!isToogleOn);
                        // setimageChange1(prevstate => !prevstate)
                      }}
                      uncheckedHandleIcon="Business"
                      checkedHandleIcon="Personal"
                      checkedIcon="Business"
                      uncheckedIcon="Personal"
                      // setimageChange={setimageChange}
                    />
                  </div>
                </Nav.Item>

                <Nav.Item className="d-none d-md-block">
                  {(thememode != "dark" && (
                    <ModeIcon
                      className="widthofimgmoon"
                      onClick={() =>
                        updateTheme(thememode == "light" ? "dark" : "light")
                      }
                    />
                  )) || (
                    <Lightmode
                      className="widthofimgmoon"
                      onClick={() =>
                        updateTheme(thememode == "light" ? "dark" : "light")
                      }
                    />
                  )}
                </Nav.Item>

                <Nav.Item className="modeIcon d-block d-md-none">
                  {(thememode == "dark" && (
                    <div
                      onClick={() =>
                        updateTheme(thememode == "light" ? "dark" : "light")
                      }
                    >
                      <Lightmode className="halfmoon" />
                      <p className="modetext">{t("Lightmode")}</p>
                    </div>
                  )) || (
                    <div
                      onClick={() =>
                        updateTheme(thememode == "light" ? "dark" : "light")
                      }
                    >
                      <Darkmode className="halfmoon" />
                      <p className="modetext">{t("Darkmode")}</p>
                    </div>
                  )}
                </Nav.Item>
                <Nav.Item className="d-none d-md-flex">
                  <div>
                    <SmallDropdown
                      options={languages}
                      middleline={true}
                      useFilledDownArrow={true}
                      setSelectedOption={(e) => {
                        languages.map((data) => {
                          if (data.key == e) {
                            localStorage.setItem("lang", data.apiKey);
                            i18n.changeLanguage(data.apiKey);
                            if (auth) {
                              updateProfileLanguage({ language: data.apiKey });
                            }
                            setLanguage(data);
                          }
                        });
                      }}
                      selectedOption={selectLang}
                    />
                  </div>
                </Nav.Item>
              </Nav>
            </Container>
          </Navbar>
          {auth && role == "Business" && businessForm && (
            <RequestSent
              businessApproval={businessApproval}
              show1={
                businessApproval == "Requested" ||
                businessApproval == "Rejected"
              }
              getMyprofile={getMyprofile}
              thememode={thememode}
              exchange={exchange}
            />
          )}

          {!branch && auth && role == "Business" && (
            <BusinessSignup show={showBusinessForm} />
          )}
        </Fragment>
      </div>
    </div>
  );
}
const mapStateToProps = ({ app = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const MyProfileInfo = _.get(user, "MyProfileInfo", false);
  const auth = _.get(user, "auth", false);
  const role = _.get(user, "userInfo.role", false);
  const exchange = _.get(app, "settings.exchange", false);
  const businessForm = _.get(user, "userInfo.businessForm", false);
  const businessApproval = _.get(user, "MyProfileInfo.businessApproval", false);
  const showBusinessForm = _.get(user, "showBusinessForm", false);
  const defaultrole = _.get(app, "role", "Business");
  const branch = _.get(user, "userInfo.branch", false);
  return {
    businessForm,
    thememode,
    role,
    MyProfileInfo,
    auth,
    exchange,
    businessApproval,
    role,
    defaultrole,
    branch,
    showBusinessForm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTheme: (data) => dispatch({ type: "updateTheme", payload: data }),
  changeLang: (lang) => dispatch(changeLanguage(lang)),
  updateProfileLanguage: (data) =>
    dispatch({ type: "updateProfile", payload: data }),
  getMyprofile: (data) =>
    dispatch({ type: "getMyprofilecalled", payload: data }),
  changerole: (data) => dispatch({ type: "changerolecalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingNavbar);
