import { persistor, store } from "@store";
import React, { useState, useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import SignIn from "@features/onboarding/signin/SignIn.js";
import SignUp from "@features/onboarding/signup/SignUp.js";
import OtpScreen from "@features/onboarding/otp-screen/OtpScreen.js";
import HomeScreen from "./features/HomeScreen/HomeScreen";
import "./App.scss";
import "react-phone-input-2/lib/style.css";
import ConnectedApplyTheme from "@components/themes/Applytheme";
import DashboardScreen from "@features/dashboard/DashboardScreen";
import DepositWithdraw from "@features/deposit-withdraw/DepositWithdraw.js";
import ExchangeHistory from "@features/exchange-history/ExchangeHistory";
import TransferHistory from "@features/transfer-history/TransferHistory.js";
import IdoScreens from "@features/ido-screens/IdoScreens.js";
import IdoNew from "@features/ido-screens/ido-new/IdoNew.js";
import CryptoWallet from "@features/crypto-wallet/CryptoWallet.js";
import BusinessCryptoWallet from "@features/business-cypto-wallet/BusinessCryptoWallet";
import FiatWallet from "@features/fiat-wallet/FiatWallet.js";
import Payment from "@features/payment/Payment.js";
// import PayMentRecScreen from "@features/paymentrec-screen/PayMentRecScreen";
import ClaimIdoScreen from "@features/claimido-screen/ClaimIdoScreen";
import PayScreen from "@features/pay-screen/PayScreen";
import CyborProtection from "@features/cybor-protection/CyborProtection";
import PersonalSettings from "@features/personal-settings/PersonalSettings";
import PaymentReports from "@features/reports/PaymentReports";
import MyBalances from "@features/my-balances/MyBalances";
import MarketRates from "@features/market-rates/MarketRates";
import BranchManagement from "@features/branch-management/BranchManagement";
import CreateBranch from "@features/create-branch/CreateBranch.js";
import { ToastContainer } from "react-toastify";
import VerificationSignUp from "@features/onboarding/verification-signup/VerificationSignUp";
import VerifyLinkScreen from "@features/onboarding/verify-link/VerifyLinkScreen";
import CreatePaymentLink from "@features/create-payment-link/CreatePaymentLink";
import AdminBusinessRequest from "@features/admin-business-request/AdminBusinessRequest";
import AdminFiatDepositRequest from "@features/admin-fiat-deposit-request/AdminFiatDepositRequest";
import AdminFiatWithdrawRequest from "@features/admin-fiat-withdraw-request/AdminFiatWithdrawRequest.js";
import AdminAllBanks from "@features/admin-all-banks/AdminAllBanks";
import AdminBankapprovalRequest from "@features/admin-bankapproval-request/AdminBankapprovalRequest";
import UsersList from "@features/users-list/UsersList";
import CompletedTransaction from "@features/completed-transaction/CompletedTransaction";
import PendingTransaction from "@features/pending-transaction/PendingTransaction";
import CancelledTransaction from "@features/cancelled-transaction/CancelledTransaction";
import SetNewPassword from "@features/onboarding/set-new-password/SetNewPassword";
import AddNewBankAccount from "@features/add-new-bank-acount/AddNewBankAccount";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import ErrorWrapper from "@components/ErrorBoundary/ErrorWrapper";
import BityScreen from "./features/bity/BityScreen";
import Transak from "./features/transak/Transak";
import CryptoCard from "@features/crypto-card/CryptoCard";
import BorrowMarket from "@features/borrow-market/BorrowMarket";
import RequestLoan from "@features/request-loan/RequestLoan";
import AdminLoanManagement from "./features/admin-loan-management/AdminLoanManagement";
import LoanRequest from "./features/loan-request/LoanRequest";
import CreateLoanType from "./features/create-loan-type/CreateLoanType";
import AdminCryptoCards from "@features/admin-crypto-cards/AdminCryptoCards";
import AdminPersonalLoanManagement from "@features/admin-personal-loan-management/AdminPersonalLoanManagement";
import CreatePersonalLoanType from "@features/create-personal-loan-type/CreatePersonalLoanType";
import PersonalLoanRequest from "@features/personal-loan-request/PersonalLoanRequest";
import BorrowPersonalLoan from "@features/borrow-personal-loan/BorrowPersonalLoan";
import RequestPersonalLoan from "./features/request-personal-loan/RequestPersonalLoan";


let loading = 0;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.user);
  let Authorized = auth && auth.auth;
  const [darkTheme, setDarkTheme] = useState(true);

  return (
    <Route
      {...rest}
      render={(props) =>
        Authorized ? (
          <Component {...props} hideLoaderOnRefresh={loading++} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

const PreventSignIn = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.user);
  let Authorized = auth && auth.auth;

  return (
    <Route
      {...rest}
      render={(props) =>
        !Authorized ? (
          <Component {...props} hideLoaderOnRefresh={loading++} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const App = ({ hideLoader, showLoader }) => {
  useEffect(() => {
    hideLoader();

    if (window.location.href.includes("/pay/")) {
      localStorage.setItem("pay", window.location.pathname);
    }
  }, []);

  return (
    <ErrorWrapper>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <ScrollToTop>
              <Switch>
                <ConnectedApplyTheme>
                  <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={true}
                    closeOnClick={false}
                    rtl={false}
                    limit={1}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                  />
                  <Route exact path="/home" component={HomeScreen} />
                  <PrivateRoute exact path="/" component={DashboardScreen} />
                  <PrivateRoute exact path="/bity" component={BityScreen} />
                  <PrivateRoute
                    exact
                    path="/exchangehistory"
                    component={ExchangeHistory}
                  />
                  <PrivateRoute
                    exact
                    path="/depositwithdraw"
                    component={DepositWithdraw}
                  />
                  <PrivateRoute
                    exact
                    path="/transferhistory"
                    component={TransferHistory}
                  />
                  <PrivateRoute exact path="/ido" component={IdoScreens} />
                  <PrivateRoute exact path="/transak" component={Transak} />
                  <PrivateRoute exact path="/idoscreen" component={IdoNew} />
                  <PrivateRoute
                    exact
                    path="/paymentscreen"
                    component={Payment}
                  />
                  <PrivateRoute
                    exact
                    path="/cyborprotection"
                    component={CyborProtection}
                  />
                  <PrivateRoute
                    exact
                    path="/paymentreport"
                    component={PaymentReports}
                  />
                  <PrivateRoute
                    exact
                    path="/cryptowallet"
                    component={CryptoWallet}
                  />
                  <PrivateRoute
                    exact
                    path="/mywallet"
                    component={BusinessCryptoWallet}
                  />
                  <PrivateRoute
                    exact
                    path="/fiatwallet"
                    component={FiatWallet}
                  />
                  <PrivateRoute
                    exact
                    path="/branchmanagement"
                    component={BranchManagement}
                  />
                  {/* Adding reset new password component  */}
                  <Route
                    exact
                    path="/resetpassword"
                    component={SetNewPassword}
                  />
                  {/* Adding new component for setting new password  */}
                  <PrivateRoute
                    exact
                    path="/createbranch"
                    component={CreateBranch}
                  />
                  <PreventSignIn exact path="/signin" component={SignIn} />
                  <PrivateRoute
                    exact
                    path="/claimtoken"
                    component={ClaimIdoScreen}
                  />
                  <Route exact path="/signup" component={SignUp} />
                  <PrivateRoute
                    exact
                    path="/personaldashsetting"
                    component={PersonalSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/addnewbankaccount"
                    component={AddNewBankAccount}
                  />
                  <Route exact path="/otp" component={OtpScreen} />
                  <Route exact path="/pay/:id" component={PayScreen} />
                  {/* <Route exact path="/paymentrec/:id" component={PayMentRecScreen} /> */}
                  <PrivateRoute
                    exact
                    path="/mybalances/:id"
                    component={MyBalances}
                  />
                  <PrivateRoute
                    exact
                    path="/mybalances"
                    component={MyBalances}
                  />
                  <PrivateRoute
                    exact
                    path="/marketrates"
                    component={MarketRates}
                  />
                  <Route
                    exact
                    path="/verification"
                    component={VerificationSignUp}
                  />

                  <Route exact path="/verify" component={VerifyLinkScreen} />
                  <Route
                    exact
                    path="/createpaymentlink"
                    component={CreatePaymentLink}
                  />
                  <PrivateRoute
                    exact
                    path="/businessrequest"
                    component={AdminBusinessRequest}
                  />
                  <PrivateRoute
                    exact
                    path="/adminallbanks"
                    component={AdminAllBanks}
                  />
                  <PrivateRoute
                    exact
                    path="/bankrequest"
                    component={AdminBankapprovalRequest}
                  />
                  <PrivateRoute
                    exact
                    path="/adminfiatdeposit"
                    component={AdminFiatDepositRequest}
                  />
                  <PrivateRoute
                    exact
                    path="/adminfiatwithdraw"
                    component={AdminFiatWithdrawRequest}
                  />
                  <PrivateRoute exact path="/userslist" component={UsersList} />
                  <Route
                    exact
                    path="/completedtransaction"
                    component={CompletedTransaction}
                  />
                  <Route
                    exact
                    path="/pendingtransaction"
                    component={PendingTransaction}
                  />

                  <Route
                    exact
                    path="/cancelledtransaction"
                    component={CancelledTransaction}
                  />

                  <PrivateRoute exact path="/crypto-cards" component={CryptoCard} />
                  <PrivateRoute exact path="/cryptocards" component={AdminCryptoCards} />
                  <PrivateRoute exact path="/loan" component={BorrowMarket} />
                  <PrivateRoute exact path="/requestLoan" component={RequestLoan} />
                  <PrivateRoute exact path="/adminLoanManagement" component={AdminLoanManagement} />
                  <PrivateRoute exact path="/loanRequest" component={LoanRequest} />
                  <PrivateRoute exact path="/createLoanType" component={CreateLoanType} />

                  {/* personal loan routes */}
                  <PrivateRoute exact path="/adminPersonalLoanManagement" component={AdminPersonalLoanManagement} />
                  <PrivateRoute exact path="/createPersonalLoanType" component={CreatePersonalLoanType} />
                  <PrivateRoute exact path="/personalLoanRequest" component={PersonalLoanRequest} />
                  <PrivateRoute exact path="/personalLoan" component={BorrowPersonalLoan} />
                  <PrivateRoute exact path="/requestPersonalLoan" component={RequestPersonalLoan} />




                </ConnectedApplyTheme>
              </Switch>
            </ScrollToTop>
          </Router>
        </PersistGate>
      </Provider>
    </ErrorWrapper>
  );
};

export default App;
