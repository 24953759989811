import { createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";

const platformuserslice = createSlice({
  name: "platformuser",
  initialState: {
    mywalletsinfo: [],
    mywalletsloading: false,

    myaccountsbywalletidinfo: [],
    myaccountsbywalletidloading: false,

    myaccountandbalancebywalletidinfo: [],
    myaccountandbalancebywalletidloading: false,

    myusertransactioninfo: [],
    myusertransactionloading: false,

    mycoinOrtokenbalanceinfo: null,
    mycoinortokenbalanceloading: false,

    activatecoinortokeninfo: null,
    activatecoinortokenloading: false,
    activatecoinortokenerror: "",

    transferbalanceortokeninfo: null,
    transferbalanceortokenloading: false,
    transferBalanceOrTokenerror: "",

    selectregionandlanguageinfo: null,
    selectregionandlanguageloading: false,
    selectRegionAndLanguageerror: "",

    exchangeTokeninfo: null,
    exchangeTokenloading: false,
    exchangeTokenerror: "",

    generatePaymentLinkinfo: null,
    generatePaymentLinkloading: false,
    generatePaymentLinkerror: "",

    getDetailPaymentLinkinfo: null,
    getDetailPaymentLinkloading: false,
    getDetailPaymentLinkerror: "",

    transferTroughPayLinkinfo: null,
    transferTroughPayLinkloading: false,
    transferTroughPayLinkerror: "",

    addLogoinfo: null,
    addLogoloading: false,
    addLogoFail: "",

    exchangeColorinfo: null,
    exchangeColorloading: false,
    exchangeColorerror: "",
    ExhangeHistoryinfo: null,
    ExhangeHistoryloading: false,

    addTokensinfo: null,
    addTokensloading: false,
    addTokenserror: "",

    addExchangesinfo: null,
    addExchangesloading: false,
    addExchangeserror: "",
    getplatformTokensinfo: [],
    getPlatformTokensloading: false,

    getFiatBalanceinfo: null,
    getFiatBalanceloading: false,

    activateFiatinfo: null,
    activateFiatloading: false,
    activateFiaterror: "",

    transferhistoryCount: null,
    exchangehistoryCount: null,
    transferCountLoading: false,
    depositWithHistoryDownload: [],

    loanSupportedInPlatformLoading: false,
    loanSupportedInPlatform: undefined,
    loanSupportedInPlatformError: undefined,

    borrowLoanLoading: false,
    borrowLoan: undefined,
    borrowLoanError: undefined,

    addLoanSupportInPlatformLoading: false,
    addLoanSupportInPlatformError: undefined,

    editLoanSupportInPlatformLoading: false,
    editLoanSupportInPlatformError: undefined,

    deleteLoanSupportInPlatformLoading: false,
    deleteLoanSupportInPlatformError: undefined,

    allLoanTypeInAdminLoading: false,
    allLoanTypesInAdmin: undefined,
    allLoanTypesInAdminError: undefined,

    loanRequestLoading: false,
    loanRequest: undefined,
    loanRequestError: undefined,

    addLoanRequestLoading: false,
    addLoanRequestError: undefined,

    collateralConversionLoading : false,
    collateralConversion : undefined,
    collateralConversionError : undefined,

    approveLoanRequestAdminLoading : false,
    approveLoanRequestError : undefined,

    addPersonalLoanLoading: false,
    addPersonalLoanError: undefined,
    adminAllPersonalLoanLoading: false,
    adminAllPersonalLoanList: undefined,
    adminAllPersonalLloanError: undefined,
    editPersonalLoanLoading: false,
    editPersonalLoanError: undefined,

    deletePersonalLoanLoading: false,
    deletePersonalLoanError: undefined,

    allPersonalLoanLoadingPublic: false,
    allPersonalLoanListPublic: undefined,
    allPersonalLloanErrorPublic: undefined,

    addPersonalLoanRequestLoading: false,
    addPersonalLoanRequestError: undefined,

    getDynamicInterestRateLoading: false,
    dynamicInterestRateForPersonalLoan:undefined,
    getDynamicInterestRateError:undefined,

    personalLoanRequestListLoading: false,
    personalLoanRequestList: undefined,
    personalLoanRequestListError: undefined,

    personalLoanAdminApproveLoading : false,
    personalLoanAdminApproveError : undefined,

  },
  reducers: {
    approveLoanRequestLoadingStart : (state,action)=>{
      return{
        ...state,
        approveLoanRequestAdminLoading : true,
        approveLoanRequestError : undefined,
      }
    },
    approveLonRequestSuccess : (state,action)=>{
      return{
        ...state,
        approveLoanRequestAdminLoading : false,
        approveLoanRequestError : undefined,
      }
    },
    approveLoanRequestFailure : (state,action)=>{
      return{
        ...state,
        approveLoanRequestAdminLoading : false,
        approveLoanRequestError : action.payload,
      }
    },
    clearConversionRedux : (state,action)=>{
      return{
        ...state,
        collateralConversionLoading : false,
        collateralConversion : undefined,
        collateralConversionError : undefined,
        addLoanRequestError: undefined,
      }
    },
    collaterConvoLoadingStart : (state,action)=>{
      return{
        ...state,
        collateralConversionLoading : true,
        collateralConversion : undefined,
        collateralConversionError : undefined,
      }
    },
    collateralConvoSuccess : (state,action)=>{
      return{
        ...state,
        collateralConversionLoading : false,
        collateralConversion : action.payload,
        collateralConversionError : undefined,
      }
    },
    collateralConvoFailure : (state,action)=>{
      return{
        ...state,
        collateralConversionLoading : false,
        collateralConversion : undefined,
        collateralConversionError : action.payload,
      }
    },
    addLoanRequestLoadingStart: (state, action) => {
      return {
        ...state,
        addLoanRequestLoading: true,
        addLoanRequestError: undefined,
      };
    },
    addLoanRequestSuccess: (state, action) => {
      return {
        ...state,
        addLoanRequestLoading: false,
        addLoanRequestError: undefined,
      };
    },
    addLoanRequestFailure: (state, action) => {
      return {
        ...state,
        addLoanRequestLoading: false,
        addLoanRequestError: action.payload,
      };
    },
    loanRequestLoadingStart: (state, action) => {
      return {
        ...state,
        loanRequestLoading: true,
        loanRequest: undefined,
        loanRequestError: undefined,
      };
    },
    loanRequestSuccess: (state, action) => {
      return {
        ...state,
        loanRequestLoading: false,
        loanRequest: action.payload,
        loanRequestError: undefined,
      };
    },
    loanRequestFailure: (state, action) => {
      return {
        ...state,
        loanRequestLoading: false,
        loanRequest: undefined,
        loanRequestError: action.payload,
      };
    },
    allListingOfLoanSuccess: (state, action) => {
      return {
        ...state,
        allLoanTypes: action.payload,
        allLoanTypesInAdminError: undefined,
      };
    },
    allListingOfAdminLoanLoading: (state, action) => {
      return {
        ...state,
        allLoanTypeInAdminLoading: true,
        allLoanTypesInAdmin: undefined,
        allLoanTypesInAdminError: undefined,
      };
    },
    allListingOfAdminLoanSuccess: (state, action) => {
      return {
        ...state,
        allLoanTypeInAdminLoading: false,
        allLoanTypesInAdmin: action.payload,
        allLoanTypesInAdminError: undefined,
      };
    },
    allListingofAdminFailure: (state, action) => {
      return {
        ...state,
        allLoanTypeInAdminLoading: false,
        allLoanTypesInAdmin: undefined,
        allLoanTypesInAdminError: action.payload,
      };
    },
    createLoanSupportLoading: (state, action) => {
      return {
        ...state,
        addLoanSupportInPlatformLoading: true,
        addLoanSupportInPlatformError: undefined,
      };
    },
    createLoanSupportSuccess: (state, action) => {
      return {
        ...state,
        addLoanSupportInPlatformLoading: false,
        addLoanSupportInPlatformError: undefined,
      };
    },
    createLoanSupportFailure: (state, action) => {
      return {
        ...state,
        addLoanSupportInPlatformLoading: false,
        addLoanSupportInPlatformError: action.payload,
      };
    },

    editLoanSupportLoading: (state, action) => {
      return {
        ...state,
        editLoanSupportInPlatformLoading: true,
        editLoanSupportInPlatformError: undefined,
      };
    },
    editLoanSupportSuccess: (state, action) => {
      return {
        ...state,
        editLoanSupportInPlatformLoading: false,
        editLoanSupportInPlatformError: undefined,
      };
    },
    editLoanSupportFailure: (state, action) => {
      return {
        ...state,
        editLoanSupportInPlatformLoading: false,
        editLoanSupportInPlatformError: action.payload,
      };
    },
    deleteLoanSupportLoading: (state, action) => {
      return {
        ...state,
        deleteLoanSupportInPlatformLoading: true,
        deleteLoanSupportInPlatformError: undefined,
      };
    },
    deleteLoanSupportSuccess: (state, action) => {
      return {
        ...state,
        deleteLoanSupportInPlatformLoading: false,
        deleteLoanSupportInPlatformError: undefined,
      };
    },
    deleteLoanSupportFailure: (state, action) => {
      return {
        ...state,
        deleteLoanSupportInPlatformLoading: false,
        deleteLoanSupportInPlatformError: action.payload,
      };
    },
    loanSupportedInPlatformLoadingStart: (state, action) => {
      return {
        ...state,
        loanSupportedInPlatformLoading: true,
        loanSupportedInPlatformError: undefined,
      };
    },
    loanSupportedSuccess: (state, action) => {
      return {
        ...state,
        loanSupportedInPlatformLoading: false,
        loanSupportedInPlatform: action.payload,
        loanSupportedInPlatformError: undefined,
      };
    },
    loanSupportedFailure: (state, action) => {
      return {
        ...state,
        loanSupportedInPlatformLoading: false,
        loanSupportedInPlatformError: action.payload,
      };
    },

    borrowLoanLoadingStart: (state, action) => {
      return {
        ...state,
        borrowLoanLoading: true,
        borrowLoanError: undefined,
      };
    },
    borrowLoanSuccss: (state, action) => {
      return {
        ...state,
        borrowLoanLoading: false,
        borrowLoan: action.payload,
        borrowLoanError: undefined,
      };
    },
    borrowLoanFailure: (state, action) => {
      return {
        ...state,
        borrowLoanLoading: false,
        borrowLoanError: action.payload,
      };
    },

    // personal loan
    createPersonalLoanLoading: (state, action) => {
      return {
        ...state,
        addPersonalLoanLoading: true,
        addPersonalLoanError: undefined,
      };
    },
    createPersonalLoanSuccess: (state, action) => {
      return {
        ...state,
        addPersonalLoanLoading: false,
        addPersonalLoanError: undefined,
      };
    },
    createPersonalLoanFailure: (state, action) => {
      return {
        ...state,
        addPersonalLoanLoading: false,
        addPersonalLoanError: action.payload,
      };
    },

    adminAllPersonalLoanLoadingStart: (state, action) => {
      return {
        ...state,
        adminAllPersonalLoanLoading: true,
        adminAllPersonalLoanList: undefined,
        adminAllPersonalLloanError: undefined,
      };
    },
    adminAllPersonalLoanSuccess: (state, action) => {
      return {
        ...state,
        adminAllPersonalLoanLoading: false,
        adminAllPersonalLoanList: action.payload,
        adminAllPersonalLloanError: undefined,
      };
    },
    adminAllPersonalLoanFailure: (state, action) => {
      return {
        ...state,
        adminAllPersonalLoanLoading: false,
        adminAllPersonalLoanList: undefined,
        adminAllPersonalLloanError: action.payload,
      };
    },


    editPersonalLoanLoadingStart: (state, action) => {
      return {
        ...state,
        editPersonalLoanLoading: true,
        editPersonalLoanError: undefined,
      };
    },
    editPersonalLoanSuccess: (state, action) => {
      return {
        ...state,
        editPersonalLoanLoading: false,
        editPersonalLoanError: undefined,
      };
    },
    editPersonalLoanFailure: (state, action) => {
      return {
        ...state,
        editPersonalLoanLoading: false,
        editPersonalLoanError: action.payload,
      };
    },
    deletePersonalLoanLoadingStart: (state, action) => {
      return {
        ...state,
        deletePersonalLoanLoading: true,
        deletePersonalLoanError: undefined,
      };
    },
    deletePersonalLoanSuccess: (state, action) => {
      return {
        ...state,
        deletePersonalLoanLoading: false,
        deletePersonalLoanError: undefined,
      };
    },
    deletePersonalLoanFailure: (state, action) => {
      return {
        ...state,
        deletePersonalLoanLoading: false,
        deletePersonalLoanError: action.payload,
      };
    },

    publicAllPersonalLoanLoadingStart: (state, action) => {
      return {
        ...state,
        allPersonalLoanLoadingPublic: true,
        allPersonalLoanListPublic: undefined,
        allPersonalLloanErrorPublic: undefined,
      };
    },

    publicAllPersonalLoanSuccess: (state, action) => {
      return {
        ...state,
        allPersonalLoanLoadingPublic: false,
        allPersonalLoanListPublic: action.payload,
        allPersonalLloanErrorPublic: undefined,
      };
    },
    publicAllPersonalLoanFailure: (state, action) => {
      return {
        ...state,
        allPersonalLoanLoadingPublic: false,
        allPersonalLoanListPublic: undefined,
        allPersonalLloanErrorPublic: action.payload
      }
    },


    addPersonalLoanRequestLoadingStart: (state, action) => {
      return {
        ...state,
        addPersonalLoanRequestLoading: true,
        addPersonalLoanRequestError: undefined,
      };
    },
    addPersonalLoanRequestSuccess: (state, action) => {
      return {
        ...state,
        addPersonalLoanRequestLoading: false,
        addPersonalLoanRequestError: undefined,
      };
    },
    addPersonalLoanRequestFailure: (state, action) => {
      return {
        ...state,
        addPersonalLoanRequestLoading: false,
        addPersonalLoanRequestError: action.payload,
      };
    },

    getInterestRateForPersonalLoadingStart: (state, action) => {
      return {
        ...state,
        getDynamicInterestRateLoading: true,
        dynamicInterestRateForPersonalLoan: undefined,
        getDynamicInterestRateError:undefined,
      };
    },
    getInterestRateForPersonalSuccess: (state, action) => {
      return {
        ...state,
        getDynamicInterestRateLoading: false,
        dynamicInterestRateForPersonalLoan:action.payload,
        getDynamicInterestRateError: undefined,
      };
    },
    getInterestRateForPersonalFailure: (state, action) => {
      return {
        ...state,
        getDynamicInterestRateLoading: false,
        getDynamicInterestRateError: action.payload,
        dynamicInterestRateForPersonalLoan:undefined,
      };
    },

    cleargetInterestRateForPersonal: (state, action) => {
      return {
        ...state,
        getDynamicInterestRateLoading: false,
        dynamicInterestRateForPersonalLoan: undefined,
        getDynamicInterestRateError:undefined,
      };
    },

    
    
    
    personalLoanRequestLoadingStart: (state, action) => {
      return {
        ...state,
        personalLoanRequestListLoading: true,
        personalLoanRequestList: undefined,
        personalLoanRequestListError: undefined,
      };
    },
    personalLoanRequestSuccess: (state, action) => {
      return {
        ...state,
        personalLoanRequestListLoading: false,
        personalLoanRequestList: action.payload,
        personalLoanRequestListError: undefined,
      };
    },
    personalLoanRequestFailure: (state, action) => {
      return {
        ...state,
        personalLoanRequestListLoading: false,
        personalLoanRequestList: undefined,
        personalLoanRequestListError: action.payload,
      };
    },

    approvePersonalLoanRequestLoadingStart : (state,action)=>{
      return{
        ...state,
        personalLoanAdminApproveLoading : true,
        personalLoanAdminApproveError : undefined,
      }
    },
    approvePersonalLonRequestSuccess : (state,action)=>{
      return{
        ...state,
        personalLoanAdminApproveLoading : false,
        personalLoanAdminApproveError : undefined,
      }
    },
    approvePersonalLoanRequestFailure : (state,action)=>{
      return{
        ...state,
        personalLoanAdminApproveLoading : false,
        personalLoanAdminApproveError : action.payload,
      }
    },



    getMywalletsSuccess: (state, action) => {
      return { ...state, mywalletsinfo: action.payload };
    },
    getMywalletsTrigger: (state, action) => {
      return { ...state, mywalletsloading: action.payload };
    },

    getAccountByWalletIdSuccess: (state, action) => {
      return { ...state, myaccountsbywalletidinfo: action.payload };
    },
    getAccountByWalletIdTrigger: (state, action) => {
      return { ...state, myaccountsbywalletidloading: action.payload };
    },

    getAccountAndBalanceByWalletIdSuccess: (state, action) => {
      return { ...state, myaccountandbalancebywalletidinfo: action.payload };
    },
    getAccountAndBalanceByWalletIdTrigger: (state, action) => {
      return { ...state, myaccountandbalancebywalletidloading: action.payload };
    },

    getUserTransactionSuccess: (state, action) => {
      return { ...state, myusertransactioninfo: action.payload };
    },
    getUserTransactionSuccessDownload: (state, action) => {
      return {
        ...state,
        myusertransactioninfoDownload: action.payload,
      };
    },
    getUserTransactionTrigger: (state, action) => {
      return { ...state, myusertransactionloading: action.payload };
    },

    getBalanceSuccess: (state, action) => {
      return { ...state, mycoinOrtokenbalanceinfo: action.payload };
    },
    getBalanceTrigger: (state, action) => {
      return { ...state, mycoinortokenbalanceloading: action.payload };
    },

    activateCoinOrTokenSuccess: (state, action) => {
      return { ...state, activatecoinortokeninfo: action.payload };
    },
    activateCoinOrTokenTrigger: (state, action) => {
      return { ...state, activatecoinortokenloading: action.payload };
    },
    activateCoinOrTokenFail: (state, action) => {
      return {
        ...state,
        activatecoinortokenerror: action.payload,
      };
    },

    transferBalanceOrTokenSuccess: (state, action) => {
      return {
        ...state,
        transferbalanceortokenloading: false,
        transferBalanceOrTokenerror: "",
      };
    },
    transferBalanceOrTokenTrigger: (state, action) => {
      return {
        ...state,
        transferbalanceortokenloading: action.payload,
        transferBalanceOrTokenerror: "",
      };
    },
    transferBalanceOrTokenFail: (state, action) => {
      return {
        ...state,
        transferBalanceOrTokenerror: action.payload,
        transferbalanceortokenloading: false,
      };
    },

    selectRegionAndLanguageSuccess: (state, action) => {
      return {
        ...state,
        selectregionandlanguageinfo: action.payload,
      };
    },
    updatewsdataSuccess: (state, action) => {
      return { ...state, ...action.payload };
    },

    selectRegionAndLanguageTrigger: (state, action) => {
      return { ...state, selectregionandlanguageloading: action.payload };
    },

    selectRegionAndLanguageFail: (state, action) => {
      return {
        ...state,
        selectRegionAndLanguageerror: action.payload,
      };
    },

    exchangeTokenorCoinSuccess: (state, action) => {
      return {
        ...state,
        exchangeTokeninfo: action.payload,
        exchangeTokenloading: false,
      };
    },

    exchangeTokenorCoinTrigger: (state, action) => {
      return { ...state, exchangeTokenloading: action.payload };
    },

    exchangeTokenorCoinFail: (state, action) => {
      return {
        ...state,
        exchangeTokenerror: action.payload,
      };
    },
    clearExchangeTokenError: (state, action) => {
      return {
        ...state,
        exchangeTokenerror: "",
      };
    },
    generatePaymentLinkSuccess: (state, action) => {
      return { ...state, generatePaymentLinkinfo: action.payload };
    },

    generatePaymentLinkTrigger: (state, action) => {
      return { ...state, generatePaymentLinkloading: action.payload };
    },

    generatePaymentLinkFail: (state, action) => {
      return {
        ...state,
        generatePaymentLinkerror: action.payload,
      };
    },

    getDetailPaymentLinkSuccess: (state, action) => {
      return { ...state, getDetailPaymentLinkinfo: action.payload };
    },

    getDetailPaymentLinkTrigger: (state, action) => {
      return { ...state, getDetailPaymentLinkloading: action.payload };
    },

    getDetailPaymentLinkFail: (state, action) => {
      return {
        ...state,
        getDetailPaymentLinkerror: action.payload,
      };
    },

    transferTroughPayLinkSuccess: (state, action) => {
      return { ...state, transferTroughPayLinkinfo: action.payload };
    },

    transferTroughPayLinkTrigger: (state, action) => {
      return { ...state, transferTroughPayLinkloading: action.payload };
    },

    transferTroughPayLinkFail: (state, action) => {
      return {
        ...state,
        transferTroughPayLinkerror: action.payload,
      };
    },

    addLogoSuccess: (state, action) => {
      return { ...state, addLogoinfo: action.payload };
    },

    addLogoTrigger: (state, action) => {
      return { ...state, addLogoloading: action.payload };
    },

    addLogoFail: (state, action) => {
      return {
        ...state,
        addLogoerror: action.payload,
      };
    },

    addExchangeColorSuccess: (state, action) => {
      return { ...state, exchangeColorinfo: action.payload };
    },

    addExchangeColorTrigger: (state, action) => {
      return { ...state, exchangeColorloading: action.payload };
    },

    addExchangeColorFail: (state, action) => {
      return {
        ...state,
        exchangeColorerror: action.payload,
      };
    },

    ExhangeHistorySuccess: (state, action) => {
      return { ...state, ExhangeHistoryinfo: action.payload };
    },
    ExhangeHistoryDownloadSuccess: (state, action) => {
      return {
        ...state,
        ExhangeHistoryinfoDownload: action.payload,
      };
    },

    ExhangeHistoryTrigger: (state, action) => {
      return { ...state, ExhangeHistoryloading: action.payload };
    },

    addPlatformTokensSuccess: (state, action) => {
      return { ...state, addTokensinfo: action.payload };
    },

    addPlatformTokensTrigger: (state, action) => {
      return { ...state, addTokensloading: action.payload };
    },

    addPlatformTokensFail: (state, action) => {
      return {
        ...state,
        addTokenserror: action.payload,
      };
    },

    addExchangeSuperManagerSuccess: (state, action) => {
      return { ...state, addExchangesinfo: action.payload };
    },

    addExchangeSuperManagerTrigger: (state, action) => {
      return { ...state, addExchangesloading: action.payload };
    },

    addExchangeSuperManagerFail: (state, action) => {
      return {
        ...state,
        addExchangeserror: action.payload,
      };
    },

    getPlatformTokensSuccess: (state, action) => {
      return { ...state, getplatformTokensinfo: action.payload };
    },
    getPlatformTokensTrigger: (state, action) => {
      return { ...state, getPlatformTokensloading: action.payload };
    },

    getFiatBalanceSuccess: (state, action) => {
      return { ...state, getFiatBalanceinfo: action.payload };
    },
    getFiatBalanceTrigger: (state, action) => {
      return { ...state, getFiatBalanceloading: action.payload };
    },

    activateFiatCurrencySuccess: (state, action) => {
      return { ...state, activateFiatinfo: action.payload };
    },

    activateFiatCurrencyTrigger: (state, action) => {
      return { ...state, activateFiatloading: action.payload };
    },

    activateFiatCurrencyFail: (state, action) => {
      return {
        ...state,
        activateFiaterror: action.payload,
      };
    },

    getExchangerateSuccess: (state, action) => {
      return {
        ...state,
        exchangeRates: action.payload,
      };
    },
    getExchangerateTrigger: (state, action) => {
      return {
        ...state,
        loadingRate: action.payload,
      };
    },
    getFiatExchangerateSuccess: (state, action) => {
      return {
        ...state,
        FiatexchangeRates: action.payload,
      };
    },
    getFiatExchangerateTrigger: (state, action) => {
      return {
        ...state,
        loadingRate: action.payload,
      };
    },
    getFiatExchangerateFail: (state, action) => {
      return {
        ...state,
        ErrorExchange: action.payload,
      };
    },
    clearplatformerroSuccess: (state, action) => {
      return {
        ...state,
        ErrorExchange: "",
        activatecoinortokenerror: "",
        transferBalanceOrTokenerror: "",
        selectRegionAndLanguageerror: "",
        exchangeTokenerror: "",
        generatePaymentLinkerror: "",
        getDetailPaymentLinkerror: "",
        transferTroughPayLinkerror: "",
        addLogoFail: "",
        exchangeColorerror: "",
        addTokenserror: "",
        addExchangeserror: "",
        activateFiaterror: "",
        addLoanSupportInPlatformError : undefined,
        editLoanSupportInPlatformError : undefined,
        deleteLoanSupportInPlatformError : undefined,
        editPersonalLoanError : undefined,
      };
    },
    clearcacheSuccess: (state, action) => {
      return {};
    },
    getExchangerateFail: (state, action) => {
      return {
        ...state,
        ErrorExchange: action.payload,
      };
    },

    transferHistoryCountSuccess: (state, action) => {
      return {
        ...state,
        transferhistoryCount: action.payload,
      };
    },

    exchangeHistoryCountSuccess: (state, action) => {
      return {
        ...state,
        exchangehistoryCount: action.payload,
      };
    },

    getTransferhistoryCountTrigger: (state, action) => {
      return {
        ...state,
        transferCountLoading: action.payload,
      };
    },

    getPlatformCurrencysSuccess: (state, action) => {
      return {
        ...state,
        plaformcurrencies: action.payload,
      };
    },

    getPlatformCurrencysTrigger: (state, action) => {
      return {
        ...state,
        plaformcurrencyloading: action.payload,
      };
    },

    getDepositWithHistoryTrigger: (state, action) => {
      return {
        ...state,
        depositWithHistoryloading: action.payload,
      };
    },
    getDepositWithHistorySuccess: (state, action) => {
      return {
        ...state,
        depositWithHistory: action.payload,
      };
    },

    getDepositWithHistorySuccessDownload: (state, action) => {
      return {
        ...state,
        depositWithHistoryDownload: action.payload,
      };
    },
    getDepositWithCountSuccess: (state, action) => {
      return {
        ...state,
        depositWithCount: action.payload,
      };
    },

    getDectExchangePriceTrigger: (state, action) => {
      let d = state?.decentraliseEPrice;
      let decentraliseEPriceFail = state?.decentraliseEPriceFail;
      if (action.payload) {
        d = 0;
        decentraliseEPriceFail = "";
      }
      return {
        ...state,
        decentraliseEPriceLoad: action.payload,
        decentraliseEPrice: d,
        decentraliseEPriceFail,
      };
    },
    getDectExchangePriceSuccess: (state, action) => {
      return {
        ...state,
        decentraliseEPrice: action.payload,
      };
    },
    getDectExchangePriceFail: (state, action) => {
      return {
        ...state,
        decentraliseEPriceFail: action.payload,
      };
    },
    postDectExchangePriceSuccess: (state, action) => {
      return {
        ...state,
        decentraliseEPriceFail: "Exchange successful",
      };
    },
    postDectExchangePriceTrigger: (state, action) => {
      return {
        ...state,
        exchangeTokenloading: action.payload,
      };
    },
  },
});

export default platformuserslice.reducer;
export const {
  getMywalletsSuccess,
  getMywalletsTrigger,

  getAccountByWalletIdTrigger,
  getAccountByWalletIdSuccess,

  getAccountAndBalanceByWalletIdTrigger,
  getAccountAndBalanceByWalletIdSuccess,

  getUserTransactionTrigger,
  getUserTransactionSuccess,

  getCoinOrTokenBalanceTrigger,
  getCoinOrTokenBalanceSuccess,

  activateCoinOrTokenTrigger,
  activateCoinOrTokenSuccess,
  activateCoinOrTokenFail,

  transferBalanceOrTokenTrigger,
  transferBalanceOrTokenSuccess,
  transferBalanceOrTokenFail,

  selectRegionAndLanguageTrigger,
  selectRegionAndLanguageSuccess,
  selectRegionAndLanguageFail,

  exchangeTokenorCoinSuccess,
  exchangeTokenorCoinTrigger,
  exchangeTokenorCoinFail,

  generatePaymentLinkSuccess,
  generatePaymentLinkTrigger,
  generatePaymentLinkFail,

  getDetailPaymentLinkSuccess,
  getDetailPaymentLinkTrigger,
  getDetailPaymentLinkFail,

  transferTroughPayLinkSuccess,
  transferTroughPayLinkTrigger,
  transferTroughPayLinkFail,

  addLogoSuccess,
  addLogoFail,
  addLogoTrigger,

  addExchangeColorTrigger,
  addExchangeColorSuccess,
  addExchangeColorFail,

  ExhangeHistorySuccess,
  ExhangeHistoryTrigger,

  addPlatformTokensTrigger,
  addPlatformTokensSuccess,
  addPlatformTokensFail,

  addExchangeSuperManagerTrigger,
  addExchangeSuperManagerSuccess,
  addExchangeSuperManagerFail,

  getPlatformTokensTrigger,
  getPlatformTokensSuccess,

  getFiatBalanceSuccess,
  getFiatBalanceTrigger,

  activateFiatCurrencySuccess,
  activateFiatCurrencyTrigger,
  activateFiatCurrencyFail,
  updatewsdataSuccess,

  getExchangerateSuccess,
  getExchangerateTrigger,
  getExchangerateFail,

  transferHistoryCountSuccess,
  exchangeHistoryCountSuccess,
  getTransferhistoryCountTrigger,
  getPlatformCurrencysSuccess,
  getPlatformCurrencysTrigger,
  getDepositWithHistoryTrigger,
  getDepositWithHistorySuccess,
  getDepositWithCountSuccess,

  getFiatExchangerateTrigger,
  getFiatExchangerateFail,
  getFiatExchangerateSuccess,
  clearcacheSuccess,
  clearplatformerroSuccess,
  clearExchangeTokenError,
  getDepositWithHistorySuccessDownload,
  ExhangeHistoryDownloadSuccess,
  getUserTransactionSuccessDownload,
  getDectExchangePriceSuccess,
  getDectExchangePriceTrigger,
  getDectExchangePriceFail,
  postDectExchangePriceSuccess,
  postDectExchangePriceTrigger,
  loanSupportedInPlatformLoadingStart,
  loanSupportedSuccess,
  loanSupportedFailure,
  borrowLoanLoadingStart,
  borrowLoanSuccss,
  borrowLoanFailure,
  createLoanSupportLoading,
  createLoanSupportSuccess,
  createLoanSupportFailure,
  editLoanSupportLoading,
  editLoanSupportSuccess,
  editLoanSupportFailure,
  deleteLoanSupportLoading,
  deleteLoanSupportSuccess,
  deleteLoanSupportFailure,
  allListingOfAdminLoanLoading,
  allListingOfAdminLoanSuccess,
  allListingOfLoanSuccess,
  allListingofAdminFailure,
  loanRequestLoadingStart,
  loanRequestSuccess,
  loanRequestFailure,

  addLoanRequestLoadingStart,
  addLoanRequestSuccess,
  addLoanRequestFailure,

  collaterConvoLoadingStart,
  collateralConvoSuccess,
  collateralConvoFailure,
  clearConversionRedux,
  approveLoanRequestLoadingStart,
  approveLonRequestSuccess,
  approveLoanRequestFailure,

  createPersonalLoanLoading,
  createPersonalLoanSuccess,
  createPersonalLoanFailure,

  adminAllPersonalLoanLoadingStart,
  adminAllPersonalLoanSuccess,
  adminAllPersonalLoanFailure,

  editPersonalLoanLoadingStart,
  editPersonalLoanSuccess,
  editPersonalLoanFailure,
  
  deletePersonalLoanLoadingStart,
  deletePersonalLoanSuccess,
  deletePersonalLoanFailure,

  publicAllPersonalLoanLoadingStart,
  publicAllPersonalLoanSuccess,
  publicAllPersonalLoanFailure,
  addPersonalLoanRequestLoadingStart,
  addPersonalLoanRequestSuccess,
  addPersonalLoanRequestFailure,
  getInterestRateForPersonalLoadingStart,
  getInterestRateForPersonalSuccess,
  getInterestRateForPersonalFailure,
  personalLoanRequestLoadingStart,
  personalLoanRequestSuccess,
  personalLoanRequestFailure,
  approvePersonalLoanRequestLoadingStart,
  approvePersonalLonRequestSuccess,
  approvePersonalLoanRequestFailure,
  cleargetInterestRateForPersonal,


} = platformuserslice.actions;
