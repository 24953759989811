export default {
  NO: "Номер",
  AmountBuy: "Сумма к покупке",
  Date: "Дата",
  Rate: "Курс",
  Cost: "Стоимость",
  Round: "Раунд",
  Bonus: "Бонус",
  UnlockedDate: "Дата разблокировки",
  Action: "Действие",
  Claim: "Запросить",
  Tokenomics: "Токеномика",
  Protocol: "Протокол",
  BinanceSmartChain: "Binance Smart Chain",
  Token: "Токен",
  Tracker: "Трекер",
  TotalAmount: "Общая сумма",
  Billion: " миллион",
  TypeOfEmission: "Тип эмиссии",
  LimitedControlled: " ограниченная, контролируемая",
  Download: "Скачать",
  Whitepaper: "Whitepaper",
  DownloadTokenomics: "Скачать Токеномику",
  DistributionOfCYBOR: "Распределение токенов PayCrypt в первый год",
  TotalNoCoinAvlb:
    "Общее количество доступных монет выпущенных в оборот в первый год",
  Percent: "Процент",
  NumberOfTokens: "Количество токенов",
  CostOf1Token: "Цена одного токена",
  TotalCostOfTokens: "Общая стоимость токенов",
  BlockingPeriod: "Срок блокировки",
  InvestorsRBNo1: "Инвесторы закрытый раунд No 1 ",
  InvestorsClosedRound: "Инвесторы закрытый раунд No",
  Months: "месяцев",
  Days: "дней",
  PlacementOnExchange: "Рыночное размещение",
  MiningPool: "Майнинг пул",
  Developers: "Разработчики",
  Marketing: "Маркетинг",
  Partners: "Партнеры",
  CYBORCOIN: "PayCrypt COIN",
  TR: "Всего Раундов",
  TT: "Всего Транзакций",
  TTS: "Всего Токенов Отправлено",
  List: "Списки",
  EnterTokenLimit: "Введите лимит токенов",
  EnterExchangeRate: "Введите обменный курс",
  EnterLockingPeriodInDays: "Введите срок блокировки в днях",
  CreateNewRound: "Создать новый раунд",
  CreatingRoundMsg: "Новый раунд создается. Пожалуйста, подождите!",
  ROUNDNO: "Раунд номер",
  EXCHANGERATE: "ОБМЕННЫЙ КУРС",
  LOCKINGPERIOD: "ПЕРИОД БЛОКИРОВКИ",
  TOKENLIMIT: "ЛИМИТ ТОКЕНОВ",
  TOKENDISTRIBUTED: "ТОКЕНОВ ДОСТАВЛЕНО",
  TOKENCLAIMED: "ТОКЕНОВ ЗАПРОШЕНО",
  ROUNDNAME:"Название раунда ",
  Bonusinpercent:'Бонус в процентах',
  bonus:"бонус",
  Activate:"Активировать",
  OpenWhitepapers: "Открыть Whitepaper",
  OpenTokenomics: "Открыть Токеномику",
  DownloadWhitepapers: "Скачать Whitepaper",
  DownloadTokenonmics: "Скачать Токеномику",
  TotalBlockedTokens: "Всего заблокированных токенов",
  TokenUnlockingPeriod: "Период разблокировки токенов",
  years: "лет",
  FirstYear: "Первый год",
  SecondYear: "Второй год",
  ThirdYear: "Третий год",
  FourthYear: "Четвертый год",
  FifthYear: "Пятый год",
  SixthYear: "Шестой год",
  QualityTokens: "Количество токенов",
  Priceofonetoken: "Цена одного токена",
  Totalcosttokens: "Общая стоимость токенов",
  Blockingperiod: "Срок блокировки",
  DistributionForSixYears: "Распределение токенов PayCrypt на шесть лет",
  Year: "Год",
  Users: "Пользователи",
  Marketing: "Маркетинг",
  Partners: "Партнеры",
  tokensissuedsixyears: "Общее количество доступных токенов, выпущенных за 6 лет",
  TokenCybor: "Токен CYBOR",
  SaleoftokensCYBOR: "Продажа токенов CYBOR",
  Saleoftokens: "Продажа токенов",
  Blockingperiod: "Период блокировки",
  ClosedroundNo1: "Закрытый раунд №1",
  ClosedroundNo2: "Закрытый раунд №2",
  ClosedroundNo3: "Закрытый раунд №3",
  costof1tokenonthedateoflisting: "Стоимость 1 токена на дату листинга",
  Termofblockingonthestockexchange: "Срок блокировки на бирже",
  minimumamounttopurchasetokens: "Минимальная сумма для покупки токенов с 1 аккаунта",
  maximumamountofpurchasetokens: "Максимальная сумма покупки токенов с 1 аккаунта",
  notlimited: "не ограничена",
  Bonusrewardforparticipation: "Бонусное вознаграждение за участие в закрытых раундах IDO",
  Bonusrewardfortokenblocking: "Бонусное вознаграждение за блокировку токена",
  byparticipatinginround: "приняв участие в раунде",
  Totalpercentageofremunerationfor6months: "Общий процент вознаграждения за 6 месяцев при участии в первом раунде (блокировка токенов PayCrypt на 6 месяцев)",
  "Investors closed round #1": "Инвесторы закрытый раунд №1",
  "Investors closed round #2": "Инвесторы закрытый раунд №2",
  "Investors closed round #3": "Инвесторы закрытый раунд №3",
  "Market Placement": "Размещение на биржах",
  "Mining pool	": "Пул для майнинга",
  "Mining pool": "Пул для майнинга",
  "6 months (180 days)": "6 месяцев (180 дней)",
  "3 months (90 days)": "3 месяца (90 дней)",
  "First Year": "Первый год",
  "Second Year": "Второй год",
  "Third Year": "Третий год",
  "Fourth Year": "Четвертый год",
  "Fifth Year": "Пятый год",
  "Sixth Year": "Шестой год",
  "Number of tokens issued / total coin supply": "Количество выпущенных токенов / общее количество монет",
  " Number of blocked PayCrypt tokens": "Количество заблокированных токенов CYBOR",
  "Term of blocking PayCrypt tokens": "Срок блокировки токенов CYBOR",
  "6 Years": "6 лет",
  "Potocol": "Протокол",
  "Name Token": "Название токена",
  "Tracker": "Трекер",
  "Total Amount": "Общее количество",
  "Types of emision": "Вид эмиссии",
  "limied controlled": "Ограниченная контролируемая",
  Privatesalestage2: "Private Sale этап 2",
  Claimed:'Заявлено',
  IDO: "Участвовать в IDO",
  "Seed Round": "Семенной раунд",
  "Private sale 1": "Частная продажа 1",
  "Private sale 2": "Частная продажа 2",
  "Public sale": "Открытая продажа",
  "Liquidity": "Ликвидность",
  "Team": "Команда",
  "Partners and Advisors": "Партнеры и советники",

};
