export default {
  EMAIL_NOT_FOUND: "E-mail не найден",
  EMAIL_NOT_VERIFIED: "E-mail не верифицирован",
  MOBILE_NOT_VERIFIED: "Номер телефона не верифицирован",
  INVALID_PASSWORD: "Неверный Пароль",
  pass_token_used: "Токен пропуска использован",
  pass_token_expire: "Токен пропуска просрочен",
  password_not_Empty: "Пароль не может быть пустым",
  ConfirmTheLogin: "Подтвердите вход",
  FromNewDevice: "с нового устройства",
  EmailConfirmationCode: "Код подтверждения через E-mail",
  Enter6digitCode: "Введите полученный 6-значный код",
  AuthenticationCode: "Аутентификационный Код",
  GoogleAuthenticatorCode:
    " Введите 6-значный код, полученный в Google Authenticator",
  NoAccessSecurityCheck: "Нет доступа к проверке безопасности?",
  Send: "Отправить",
  Resend: "Отправить повторно",
  GetCode: "Получить код",
 // undefined: "Undefined",
  exchangeId_empty: "ID обмена не должен быть пустым",
  WantToLogout: "Хотите выйти?",
  Yes: "Да",
  No: "Нет",
  Invaild_role: "Недопустимая роль",

  //Newly added
  EmailID: "E-mail",
  EnterPassword: "Введите пароль",
  Email: "E-mail",
  Password: "Пароль",
  DontHaveAccount: "У Вас нет аккаунта?",
  Register: "Регистрация",
  SignInTo: "Войти в",
  CyborPay: "PayCrypt",
  CyborPayBusiness:"PayCrypt Бизнес",
  ForgotPassword: "Забыли пароль?",
  SignIn: "Войти",
  SignUp: "Регистрация",
  PasswordNotEmpty: "Введите пароль.",
  ForgotPassword: "Забыли пароль",
  ProvideEmail: "Укажите свой адрес электронной почты. Вы получите электронное письмо со ссылкой для обновление пароля.",
  YourEmail: "Ваш адрес электронной почты",
  ResetPassword: "Сброс пароля",
  PasswordResetEmailSent: "Электронное письмо для сброса пароля отправлено",
  EmailHasSent: "На ваш адрес электронной почты было отправлено письмо. Следуйте инструкциям, чтобы сбросить пароль.",
  OK: "Ок",
  CheckProtectYourAccount: "Пожалуйста, пройдите етап подтверждения, чтобы защитить свою учетную запись",
  WelcomeLine1: "Благодарим вас за использование платёжной системы PayCrypt",
  WelcomeLine2: "На сегодняшний день система находится на стадии бета тестирования. Вы можете принять участие в тестировании нашей системы.",
  "Invalid Authentication Code": "Неверный код аутентификации",
  InvalidEmailCode: "Неверный код электронной почты",
  support24: "Наша поддержка работает для вас",
  securitytitle1: "Пожалуйста, свяжитесь с нашим техническим",
  securitytitle2: "поддержка для восстановления доступа к",
  securitytitle3: "ваш аккаунт по электронной почте",
  optionselection1: "Пожалуйста, выберите из",
  optionselection2: "вариант ниже",
  buttonselection1: "Нет доступа к электронной почте",
  buttonselection2: "Нет доступа к GA",
  MOB_EMPTY: "Мобильный номер пуст",
  EMAIL_EMPTY: "Идентификатор электронной почты пуст",
  PASS_EMPTY: "Пароль пуст",
  EXCHANGE_EMPTY: "Идентификатор биржи пуст.",
  LANG_EMPTY: "Выберите язык",
  NAME_EMPTY: "Имя пусто",
  SUB_EMPTY: "Фамилия пуста",
  EMAIL: "Неверный адрес электронной почты",
  PASS_STRING: "Пароль должен быть строкой",
  MIN_LENGTH: "Пароль должен состоять минимум из 8 символов",
  MAX_LENGTH: "Пароль не должен превышать 20 символов",
}
