import React, { useState } from "react";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import WithdrawFormFiat from "@components/withdrawform-fiat/WithdrawFormFiat";
import "@components/withdrawfiat-toggle/WithdrawFiatToggleStyles.scss";
function WithdrawFiatToggle({ showToggle, tableNo, setTableNo, setHideThis, selectedCurrency }) {
  let [tableno, settableno] = useState("first");

  return (
    <div className="withdrawfiattoggle">
      {showToggle == true && (
        <div className="topspaceformodal">
          <div className="hidetgl1">
          <CurrencyTab
            page={"currency"}
            tableno={tableNo}
            changeTab={(e) => setTableNo(e)}
          />
          </div>
        </div>
      )}
      <div className="formtpspace">
        <WithdrawFormFiat setHideThis={setHideThis} selectedCurrency={selectedCurrency} />
      </div>
    </div>
  );
}
export default WithdrawFiatToggle;
