import * as _ from "lodash";
import React, { useEffect } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/rejection-popup/RejectionPopupStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux'
const AppovalPopup = ({
  show1,
  setShowModal,
  onPress,
  address,
  amount,
  onClick,
  setHideThis,
  logouts,
  id,
  verifyUserAccountSuccess,
  verifyUserAccountRequestLoading,
  approveBusinessRequestLoading,
  approveBusinessRequestSuccess,
  msg
}) => {


  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="rejection"
      show={show1}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext">{msg && msg || t(`${'DoyouwanttoReject'}`)}</div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name={t("Yes")}
                  filled={true}
                  primary={true}
                  onClick={onClick}
                />
              </div>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <div className="cancel">
                <CustomButton
                  type="submit"
                  name={t("No")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ admin = {} }) => {
  const verifyUserAccountSuccess = _.get(admin, "verifyUserAccountSuccess", false)
  const verifyUserAccountRequestLoading = _.get(admin, "verifyUserAccountRequestLoading", false)
  const approveBusinessRequestLoading = _.get(admin, "approveBusinessRequestLoading", false)
  const approveBusinessRequestSuccess = _.get(admin, "approveBusinessRequestSuccess", false)
  return {
    verifyUserAccountSuccess,
    verifyUserAccountRequestLoading,
    approveBusinessRequestLoading,
    approveBusinessRequestSuccess
  }
}


export default connect(mapStateToProps)(React.memo(AppovalPopup));
