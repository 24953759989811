import * as _ from "lodash";
import React from "react";
import "@components/admin-fiat-deposit-popup/AdminFiatDepositPopupStyles.scss";
import { Modal, Container } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
const AdminFiatDepositPopup = ({ setShowModal, data, show1 }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="fiatdeposit"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
     

<div className="modalPrimaryHeader hidden-for-desktop"></div>
<CloseIcon className="closebtn hidden-for-mobile" onClick={() => setShowModal(false)} />
        <div className="back hidden-for-desktop" onClick={() => setShowModal(false)}>

          <ExpandIcon />
        </div>
        <Modal.Header>
        <div className="transectiontype">
            <span className="transectiontext uppercase">
              {t("FiatDepositDetails")}
            </span>
          </div>
      </Modal.Header>
     
        
      <Modal.Body>
        
        <Container>
         

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("TransactionID")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.id}</span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("From")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {(data && data.from && data.from) || "00000"}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("To")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {(data && data.to && data.to) || "00000"}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("email")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.user?.email}</span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CreatedOn")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">
                {moment(data?.createdOn).local().format("MM/DD/YYYY hh:mm A")}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("TransactionType")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{t(`${data?.transactionType}`)}</span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CurrencyName")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.currencyName}</span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("UserName")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data?.user?.name} {data?.user?.subname}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BankName")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.bankAccount?.bankName}</span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BankAccountNumber")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data?.bankAccount?.bankAccountNumber}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BankIFSCNumber")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data?.bankAccount?.IFSC}</span>
            </div>
          </div>
          <div className="detail hidden-for-desktop">
            <div className="label">
              <span className="labeltext">{t("Action")}</span>
            </div>
            <div className="value">
              <div className="btnwidth">
                    <div className="approve">
                      <CustomButton
                        name={t("Approve")}
                        filled={true}
                        primary={true}
                      />
                    </div>

                    <div className="reject">
                      <CustomButton
                        name={t("Reject")}
                        filled={true}
                        primary={true} 
                      />
                    </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(AdminFiatDepositPopup);
