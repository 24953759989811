import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import bitcoin from "@images/bitcoin.svg";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import pending from "@images/pending.svg";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import CurrencyDropdown from "src/components/currency-dropdown/CurrencyDropdown";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayedCurrencies } from "@store/userredux";

import "@components/dropdownforcurrency/DropdownforCurrencyStyles.scss";

function DropdownforCurrency({
  getplatformTokensinfo,
  plaformcurrencies,
  displayedCurrencies,
  page,
  myaccountandbalance
}) {
  const [checked, setChecked] = useState(false);
  let [isPresent, setIsPresent] = useState("");
  const [data, setData] =useState([])
  const dispatch = useDispatch();
  const [type, setType] = useState("Cryptocurrency");

  useEffect(() => {
   if(page == 'cryptowallet'){
     let temp = myaccountandbalance.map((data)=>data.coin && data.coin )
     setData(temp)
   } else{
    setData(getplatformTokensinfo)
   }
  }, [getplatformTokensinfo,myaccountandbalance])

  const selectCryptos = (e, id) => {
   // console.log('dropdowncurrency', e)
  //  console.log('dropdowncurrency', id)
    if (type === "Cryptocurrency") {
      let temp = _.findIndex(displayedCurrencies.crpto, function (chr) {
         return chr === id;
      });
       let cryptolist;
      if (temp !== -1) {
        cryptolist = displayedCurrencies.crpto.filter((data) => {
          if (data !== id) {
            return data;
          }
        });
         dispatch(
          setDisplayedCurrencies({
            crpto: cryptolist,
            fiat: displayedCurrencies.fiat,
          })
        );
      } else {
        dispatch(
          setDisplayedCurrencies({
            crpto: [...displayedCurrencies.crpto, id],
            fiat: displayedCurrencies.fiat,
          })
        );
      }
    } else {
      let temp = _.findIndex(displayedCurrencies.fiat, function (chr) {
        return chr === id;
      });
      let cryptolist;
      if (temp !== -1) {
        cryptolist = displayedCurrencies.fiat.filter((data) => {
          if (data !== id) {
            return data;
          }
        });
        dispatch(
          setDisplayedCurrencies({
            crpto: displayedCurrencies.crpto,
            fiat: cryptolist,
          })
        );
      } else {
        dispatch(
          setDisplayedCurrencies({
            crpto: displayedCurrencies.crpto,
            fiat: [...displayedCurrencies.fiat, id],
          })
        );
      }
    }
  };

  return (
    <div className="dropdowncurrency">
      <Dropdown.Menu className="dropdownmenu">
        <div className="tophead">
          {
            page !== "cryptowallet" &&
            <CurrencyDropdown type={type} setType={setType} />
          }
        </div>
        <div className="currencyscrollable">
          {type == "Cryptocurrency" &&
            data &&
            data.map((items, index) => {
              return (
                <li className="lispace" key={index+"cryptoCurr"}>
                  <div className="outer">
                    <Form.Check
                      type="checkbox"
                      className="check"
                      id={`coincheck${index}`}
                      defaultChecked={
                        _.findIndex(displayedCurrencies.crpto, function (chr) {
                          return chr == items.id;
                        }) === -1
                          ? false
                          : true
                      }
                      onChange={(e) => selectCryptos(e, items.id)}
                    />
                    <Form.Label
                      htmlFor={`coincheck${index}`}
                      className="coincircle"
                    >
                      {/* <div> */}
                      <img
                        src={`${process.env.REACT_APP_IMGLINK}${items.colouredLogo}`}
                        className="coinimg"
                      />
                      <span className="cname "> {items.tokenName}</span>
                      {/* </div> */}
                    </Form.Label>
                  </div>
                </li>
              );
            })}
          {type != "Cryptocurrency" && 
            plaformcurrencies &&
            plaformcurrencies.map((items, index) => (
              <li className="lispace" key={`FiatTokens${items.id}`}>
                <div className="outer">
                  <Form.Check
                    type="checkbox"
                    className="check"
                    id={`fiatcheck${index}`}
                    defaultChecked={
                      _.findIndex(displayedCurrencies.fiat, function (chr) {
                        return chr === items.id;
                      }) === -1
                        ? false
                        : true
                    }
                    onChange={(e) => selectCryptos(e, items.id)}
                  />
                  <Form.Label
                    htmlFor={`fiatcheck${index}`}
                    className="coincircle"
                  >
                    <img
                      src={`${process.env.REACT_APP_IMGLINK}${items.colouredLogo}`}
                      className="coinimg"
                    />
                    <span className="cname "> {items.currencyName}</span>
                  </Form.Label>
                </div>
              </li>
            ))}
        </div>
      </Dropdown.Menu>
    </div>
  );
}
const mapStateToProps = ({ wallet = {}, user = {} }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const displayedCurrencies = _.get(user, "displayedCurrencies", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );

  return { getplatformTokensinfo, plaformcurrencies, displayedCurrencies, myaccountandbalance };
};

const mapDispatchToProps = (dispatch) => ({
  logouts: (data) => dispatch({ type: "logoutCalled", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownforCurrency);
