export default {
  EMAIL_USED: "Email already used",
  MOBILE_USED: "Mobile Number You have Entered is already registered.",
  NAME_CANNOT_START_WITH_NUMBER:
    "First name cannot start with number space and special character",
  SECOND_NAME_CANNOT_START_WITH_NUMBER:
    "Last name cannot start with number number space and special character",
  email_must_email: "Please enter valid email address",
  mobile_Incorrect: "Incorrect phone number",
  passwordnomatch: "Password does not match",
  Subscribe: "Subscribe to e-mail updates",
  Terms: "I have read and agree to PayCrypt’s",
  Term: "Terms of Service",
  fname: "First Name",
  //fname:'Name',
  fms: "First name is too short",
  in: "Invalid name",
  lname: "Last Name",
  lms: "Last name is too short",
  entpass: "Enter Password",
  pts: "Password is too short",
  "email must be an email": "Email must be an email",
  "mobile must be a phone number": "Mobile must be a phone number",
  "email must be an email,mobile must be a phone number":
    "Email must be an email, Mobile must be a phone number",

  //Newly Added
  DoYouHaveAccount: "Do you have account?",
  Login: "Login",
  SignUpTo: "Sign Up to",
  Password: "Password",
  reentpass: "Re-Enter Password",
  PhoneMustValid: "Phone must be a valid",
  Term: "Term",
};
