import * as _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import copy from "@images/copy.svg";
import { Modal, Container, Form, Col, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as EllipseIcon } from "@images/Ellipse.svg";
import { ReactComponent as FacebookIcon } from "@images/Facebook.svg";
import { ReactComponent as WhatsApp } from "@images/WhatsApp.svg";
import { ReactComponent as Gmail } from "@images/Gmail.svg";
import { ReactComponent as Telegram } from "@images/Telegram.svg";
import { ReactComponent as Viber } from "@images/Viber.svg";
import UploadPopup from "@components/upload-popup/UploadPopup";
import ReactToPrint from "react-to-print";
import moment from "moment";
import PrintPopup from "@components/print-popup/PrintPopup";
import CustomButton from "@components/custom-button/CustomButton";
import { useReactToPrint } from "react-to-print";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "@components/create-paylinkpopup/CreatePaylinkpopupStyle.scss";
import { divide } from "lodash";
import { useHistory } from "react-router-dom";

function CreatePaylinkpopup({
  show1,
  setShowModal,
  getplatformTokensinfo,
  data,
  paymentbytoken,
  getExchangerate,
  createpaymentLink,
  myaccountandbalance,
  paymentToken,
  thememode,
  exchangeRates,
  setshow2,
  setshowi,
  showi,
  settings,
  currencyToReceive,
  currencyToConvert,
  getPaymentbytokenLink
  //componentRef,
}) {
  const { t, i18n, ready } = useTranslation();
  let [show, setshow] = useState(false);
  const componentRef = useRef();
  let history = useHistory();
  useEffect(() => {
    setupWS();
  }, []);
  useEffect(() => {
    setupWS();
  }, [paymentToken && paymentToken.link]);
  const setupWS = () => {
    let ws = new WebSocket("wss://paymentsolutionapi.sparkrex.com");
    ws.onopen = () => {
      ws.send(JSON.stringify({ paymentId: paymentToken && paymentToken.link }));
      ws.onmessage = (e) => {
        let resp = JSON.parse(e.data);
        if (resp.paymentStatus == "Paid") {
          getPaymentbytokenLink({ tokenLink: paymentToken && paymentToken.link });
        }
        if (resp.paymentStatus == "Cancelled") {
          getPaymentbytokenLink({ tokenLink: paymentToken && paymentToken.link });
        }
      };
    };
    ws.onclose = () => {
      setupWS();
    };
  };

  useEffect(()=>{
    console.log('paymentby token' , paymentbytoken)
  } , [paymentbytoken])
  return (
    <Modal
      className="CreatePaylinkpopup"
      show={show1}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <span className="exchangetitle">{t("LinkCreatedSuccessfully")}!</span>
        </Modal.Title>
      </Modal.Header>
      <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="status">
                <span className="textofstatus">{t("Status")}:</span>
              </div>

              <div className="compete-section">
                <div className="greenicon">
                  <EllipseIcon className="dotclass" />
                </div>
                <div className="completed">
                  <span className="textofcomplete">
                    {t(`${paymentbytoken && paymentbytoken.paymentStatus}`)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="qrcode-section">
                <QRCode
                  size="250"
                  className={"margin"}
                  value={`${
                    paymentbytoken &&
                    paymentbytoken.paymentaccount &&
                    paymentbytoken.paymentaccount.address
                  }`}
                  bgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.bgwhite
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.bgwhite
                  }
                  fgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.textdarker
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.textdarker
                  }
                />
              </div>

              <div className="qrcode-section-mobile">
                <QRCode
                  size="160"
                  className={"margin"}
                  value={`${
                    paymentbytoken &&
                    paymentbytoken.paymentaccount &&
                    paymentbytoken.paymentaccount.address
                  }`}
                  bgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.bgwhite
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.bgwhite
                  }
                  fgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.textdarker
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.textdarker
                  }
                />
              </div>

              <div className="detail2">
                <div className="label">
                  <span className="labeltext">{t("Amount")}</span>
                </div>
                <div className="value">
                  <span className="valuetext2">
                    {" "}
                    {paymentbytoken && paymentbytoken.currencyAmount}{" "}
                    {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                  </span>
                </div>
              </div>

              <div className=" detail2">
                <div className="label">
                  <span className="labeltext">{t("Commission")}</span>
                </div>
                <div className="value">
                  <span className="valuetext2">
                    {paymentbytoken && paymentbytoken.commissionAmount}{" "}
                    {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                  </span>
                </div>
              </div>

              <div className=" detail2">
                <div className="label">
                  <span className="labeltext">{t("Total")}</span>
                </div>
                <div className="value">
                  <span className="valuetext2">
                    {" "}
                    {paymentbytoken &&
                      paymentbytoken.currencyAmountCommission}{" "}
                    {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                  </span>
                </div>
              </div>
              <div className=" detail2">
                <div className="label">
                  <span className="labeltext">{t("AmountToPay")}</span>
                </div>
                <div className="value">
                  <span className="valuetext2">
                    {" "}
                    {isNaN(currencyToReceive) ? "" : currencyToReceive}{" "}
                    {paymentbytoken.fromPaymentLinkCoin &&
                      paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                  </span>
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <div className="detail">
                <div className="label">
                  <span className="labeltext1">{t("PaymentID")}</span>
                </div>
                <div className="value">
                  <span className="valuetext">
                    {" "}
                    #{paymentbytoken.paymentLinkToken}
                  </span>
                </div>
              </div>

              <div className="detail ">
                <div className="label">
                  <span className="labeltext1">{t("Date")}</span>
                </div>
                <div className="value">
                  <span className="valuetext">
                    {moment(paymentbytoken.createdOn).format(
                      "MM/DD/YYYY hh:mm A"
                    )}
                  </span>
                </div>
              </div>

              <div className="detail">
                <div className="label">
                  <span className="labeltext1">{t("Recepient")}</span>
                </div>
                <div className="value">
                  {paymentbytoken.toPaymentLinkWallet &&
                    paymentbytoken.toPaymentLinkWallet.user && (
                      <span className="valuetext">
                        {" "}
                        {paymentbytoken.toPaymentLinkWallet.user.name}{" "}
                        {paymentbytoken.toPaymentLinkWallet.user.subName}
                      </span>
                    )}
                </div>
              </div>

              <div className="currencytoconvert">
                <div className="box">
                  <div className="link">
                    <span className="linktxt">{t("ClientPays")}:</span>
                  </div>
                  <div className="commonsection">
                    <span className="textline1">
                      {" "}
                      {paymentbytoken &&
                        paymentbytoken.currencyAmountCommission}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                    </span>
                    <span className="textline2">
                      {paymentbytoken && paymentbytoken.currency.currencyName}
                    </span>
                  </div>
                  {/* <div className="imgsection">
                    <DownIcon className="arrowclass11-1" />
                  </div> */}

                  <div className="commonsection">
                    <div className="link">
                      <span className="linktxt">{t("CurrencytoReceive")}:</span>
                    </div>
                    <span className="textline1">
                      {isNaN(currencyToReceive) ? "" : currencyToReceive}{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                    </span>
                    {/* <span className="textline2">
                      (1{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}{" "}
                      = 500000 TRY)
                    </span> */}

                    <span className="textline2">
                      {" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}{" "}
                      -{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.tokenName}{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                      paymentbytoken.fromPaymentLinkCoin.type == "ETH"
                        ? "ERC20"
                        : paymentbytoken.fromPaymentLinkCoin &&
                          paymentbytoken.fromPaymentLinkCoin.type == "BSC"
                        ? "BEP20"
                        : paymentbytoken.fromPaymentLinkCoin &&
                          paymentbytoken.fromPaymentLinkCoin.type == "TRON"
                        ? "TRC20"
                        : paymentbytoken.fromPaymentLinkCoin &&
                          paymentbytoken.fromPaymentLinkCoin.type}
                    </span>
                  </div>

                  {/* <div className="imgsection">
                    <DownIcon className="arrowclass11-1" />
                  </div> */}

                  <div className="commonsection">
                    <div className="link">
                      <span className="linktxt">{t("CurrencytoConvert")}:</span>
                    </div>
                    <span className="textline1">
                      {" "}
                      {/* {Number(paymentbytoken.amount).toFixed(5)}{" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.exchangeSymbol} */}
                      {isNaN(currencyToConvert) ? "" : currencyToConvert}
                    </span>
                    {/* <span className="textline2">(1 USDT = 0,00543 BTC)</span> */}
                    <span className="textline2">
                      {" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.exchangeSymbol}{" "}
                      -{" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.tokenName}{" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                      paymentbytoken.toPaymentLinkCoin.type == "ETH"
                        ? "ERC20"
                        : paymentbytoken.toPaymentLinkCoin &&
                          paymentbytoken.toPaymentLinkCoin.type == "BSC"
                        ? "BEP20"
                        : paymentbytoken.toPaymentLinkCoin &&
                          paymentbytoken.toPaymentLinkCoin.type == "TRON"
                        ? "TRC20"
                        : paymentbytoken.toPaymentLinkCoin &&
                          paymentbytoken.toPaymentLinkCoin.type}
                    </span>
                  </div>
                </div>
              </div>

              <div className="detail1">
                <div className="label">
                  <span className=" label-color">{t("Copylink")}</span>
                </div>
                <div className="value-copy">
                  <div className="textclass">
                    <span className="valuetext1 tcolor transectionid">
                      {` ${window.location.origin}/pay/${
                        paymentToken && paymentToken.link
                      }`}
                    </span>
                  </div>
                  <div className="imgcontainer">
                    <img
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/pay/${
                            paymentToken && paymentToken.link
                          }`
                        );
                        toast.success(t(`${"copied"}`), {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }}
                      src={copy}
                      className="copy"
                    />
                  </div>
                </div>
              </div>

              <div className="detail1">
                <div className="label">
                  <span className=" label-color">{t("CopyAddress")}</span>
                </div>
                <div className="value-copy">
                  <div className="textclass">
                    <span className="valuetext1 tcolor transectionid">
                      {`${
                        paymentbytoken &&
                        paymentbytoken.paymentaccount &&
                        paymentbytoken.paymentaccount.address
                      }`}
                    </span>
                  </div>
                  <div className="imgcontainer">
                    <img
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${
                            paymentbytoken &&
                            paymentbytoken.paymentaccount &&
                            paymentbytoken.paymentaccount.address
                          }`
                        );
                        toast.success(t(`${"copied"}`), {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }}
                      src={copy}
                      className="copy"
                    />
                  </div>
                </div>
              </div>

              <div className="detail1 ">
                <div className="label">
                  <span className=" label-color">{t("ShareLink")}</span>
                </div>
                <div className="value allignicon">
                  <div className="socialicon">
                    <FacebookShareButton
                      url={`${window.location.origin}/pay/${
                        paymentToken && paymentToken.link
                      }`}
                    >
                      <FacebookIcon className="arrowclass111" />
                    </FacebookShareButton>
                  </div>
                  <div className="socialicon">
                    <WhatsappShareButton
                      url={`${window.location.origin}/pay/${
                        paymentToken && paymentToken.link
                      }`}
                    >
                      <WhatsApp className="arrowclass111" />
                    </WhatsappShareButton>
                  </div>
                  <div className="socialicon">
                    <EmailShareButton
                      url={`${window.location.origin}/pay/${
                        paymentToken && paymentToken.link
                      }`}
                    >
                      <Gmail className="arrowclass111" />
                    </EmailShareButton>
                  </div>

                  <div className="socialicon">
                    <TelegramShareButton
                      url={`${window.location.origin}/pay/${
                        paymentToken && paymentToken.link
                      }`}
                    >
                      <Telegram className="arrowclass111" />
                    </TelegramShareButton>
                  </div>
                  <div className="socialicon">
                    <ViberShareButton
                      url={`${window.location.origin}/pay/${
                        paymentToken && paymentToken.link
                      }`}
                    >
                      <Viber className="arrowclass111" />
                    </ViberShareButton>
                  </div>
                </div>
              </div>

              <div className="buttonsection">
                <div className="buttoncontainer pl">
                  <ReactToPrint
                    trigger={() => (
                      <CustomButton
                        type="submit"
                        name={t("Print")}
                        filled={true}
                        primary={true}
                        onClick={() => {
                          setShowModal(false);
                          setshowi(true);
                        }}
                      />
                    )}
                    content={() => componentRef.current}
                  />
                  <div style={{ display: "none" }}>
                    <PrintPopup
                      ref={componentRef}
                      setShowModal={setshow}
                      showi={true}
                      setshowi={setshowi}
                      paymentbytoken={paymentbytoken}
                      paymentToken={paymentToken}
                      thememode={thememode}
                      settings={settings}
                      currencyToReceive={currencyToReceive}
                      currencyToConvert={currencyToConvert}
                    />
                  </div>
                </div>
                <div className="buttoncontainer pr">
                  <CustomButton
                    type="submit"
                    name={t("Showonscreen")}
                    filled={true}
                    primary={true}
                    onClick={() => {
                      setShowModal(false);
                      setshow2(true);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
export default React.memo(CreatePaylinkpopup);
