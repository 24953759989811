export default {
  My: "Мои",
  Balances: "Балансы",
  Deposit: "Пополнение",
  Withdraw: "Вывод",
  Exchange: "Обмен",
  Balance: "Баланс",
  SelectAmountToWithdraw: "Введите сумму для вывода",
  Price: "Цена",
  Changes: "Изменения",
  Chart: "График",
  ExchangeRate: " Обменный курс",
  Commission: "Комиссия",
  InsufficientBalance: "Недостаточный баланс",
  From: "От",
  Cancel: "Отменить",
  Confirm: "Подтвердить",
  To: "Кому",
  BankTransfer: "Банковский перевод",
  TLWallet: "TL кошелек",
  List1:
    "Вы можете внести турецкую лиру на свой счет PayCrypt в любое время со своего аккаунта Akbank, Denizbank, Fibabanka, Vakıfbank, Yapı Kredi или Ziraat Bank.",
  List2:
    " Вы можете внести турецкую лиру со своих счетов в других банках на наши счета в Akbank, Denizbank, Fibabanka, Yapı Kredi или Ziraat Bank в дни и часы EFT. Транзакции, которые вы совершаете в нерабочее время для системы банковских переводов, будут происходить в течение первого рабочего дня после этого.",
  List3:
    "С платежной системой FAST (Instant and Continuous Funds Transfer), заказанной Центральным банкоматом Турецкой Республики (CBRT), вы можете вносить турецкие лиры в PayCrypt со своих банковских счетов, введенных в платежную систему FAST в течение 7 дней и 24 часов. С помощью FAST вы можете связаться со своим банком по поводу лимитов денежных переводов и комиссий за транзакции.",
  List4:
    "Напоминаем, что при внесении TL-депозита информация об имени, фамилии и идентификационном номере отправителя должна совпадать с данными учетной записи PayCrypt.",
  Click: "Нажмите",
  DetailsOnTL: "для получения подробной информации о транзакциях ввода и вывода TL",
  Receive: "Получить",
  Share: "Поделиться",
  Copied: "Скопировано",
  Amount: "Количество",
  Copy: "Копировать",
  SelectCoin: "Выбрать монету",
  AddressToWithdraw: "Адрес для вывода",
  MAX: "MAX",
  SearchHere: "Поиск",
  Withdrawal: "Вывод",
  to: "на",
  Wallet: "кошелек",
  Continue: "Продолжить",
  SelectCurrency: "Выберите Валюту",
  SelectAmountToWithdraw: "Введите сумму для вывода",
  ServiceFee: "Сервисная оплата",
  AddNewAccount: "Добавить новый аккаунт",
  WrittenAmount :"5000 PayCrypt = 124,811 долларов США",
  Function: "Действия",
  Functions: "Функции",
  OnlyCyborCoin: "Отправляйте на этот адрес только PayCrypt Coin (CYBOR). Отправка любых других монет может привести к их безвозвратной потере.",
  PendingApproval: "(В ожидании утверждения)",
  Withdrawalfees: "Комиссия за снятие денег",
  withdrawal: "для снятия",
  limit: "",
  is: "",
  Or: "Или",
  YouWillRecieve: "Вы получите",
  MyAccounts: "Мои счета",
  MyBalances: "Мои балансы",
  MyWallets: "Мои кошельки",
  VerifiedAccount: "Подтвержденный аккаунт",
  UnverifiedAccount: "Неподтвержденный аккаунт",
  
};
