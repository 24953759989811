import React, { useState } from "react";
import "@components/search-box/SearchBoxStyle.scss";
import { ReactComponent as Search } from "@images/search.svg";
import { ReactComponent as Close } from "@images/close2.svg";
import { useTranslation } from "react-i18next";
function SearchBox({ value,setvalues, onChangeValue, hideplaceholder}) {
  const [val, setvalue] = useState("");
  const [closeIcon, setCloseIcon] = useState(false);
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="searcharea">
      <div className="searchbox">
        <input
          type="text"
          value={val}
          onChange={(e) => {
            e.target.value.length > 0
              ? setCloseIcon(true)
              : setCloseIcon(false);
            setvalue(e.target.value);
            // setvalue(value);
            if(onChangeValue !== undefined){
              onChangeValue(e.target.value)
            }
            setvalues && setvalues(e.target.value);
          }}
          placeholder={hideplaceholder == true ? '' : t("SearchHere")}
          className="innerinputfield"
          maxlength={27}
        />
        <span className="searchinside">
          {closeIcon == true && (
            <Close
              onClick={() => {
                setvalue("");
                setvalues && setvalues("");
                setCloseIcon(false);
              }}
              className="pointer closeicon"
            />
          )}
          <Search className="pointer" />
        </span>
      </div>
    </div>
  );
}
export default SearchBox;
