import React from 'react';
import Switch from "react-switch";
import { connect } from "react-redux";
import '@components/personal-business-toogle/PersonalBusinessToogleStyles.scss';
import { useTranslation } from "react-i18next";

const PersonalBusinessToogle = ({isToogleOn, setIsToogleOn,uncheckedHandleIcon, checkedHandleIcon, checkedIcon, uncheckedIcon,thememode , setimageChange}) => {
    const { t } = useTranslation();
    
    return (
        <Switch 
            checked={isToogleOn} 
            onChange={setIsToogleOn} 
            
            borderRadius={6}
            height={33}
            // handleDiameter={23}
            width={308}
            activeBoxShadow="none"
            offColor="var(--bglight)"
            onColor="var(--bglight)"
            offHandleColor="#017FDA"
            onHandleColor="#017FDA"
            uncheckedHandleIcon={
                <div className={`uncheckedHandleIcon ${thememode != 'dark' ? 'lighttoogle' : null}`}>
                    {t(`${uncheckedHandleIcon}`)}
                </div>
            }
            checkedHandleIcon={
                <div className={`checkedHandleIcon ${thememode != 'dark' ? 'lighttoogle' : null}`}>
                    {t(`${checkedHandleIcon}`)}
                </div>
            }
            checkedIcon={
                <div className={`checkedIcon ${thememode != 'dark' ? 'lighttoogle' : null}`}>
                    {t(`${checkedIcon}`)}
                </div>
            }
            uncheckedIcon={
                <div className={`uncheckedIcon ${thememode != 'dark' ? 'lighttoogle' : null}`}>
                    {t(`${uncheckedIcon}`)}
                </div>
            }
            className={`react-switch ${isToogleOn ? 'toogleon' : 'toogleoff'} ${thememode != 'dark' ? 'lightreactswitch' : null}`}
            id="small-radius-switch"
        />
    )
}
const mapStateToProps = ({ app = {} }) => {
    const thememode = _.get(app, "thememode", false);
    return {
      thememode,
    };
  };
  export default connect(mapStateToProps, null)(PersonalBusinessToogle);
