import * as _ from "lodash";
import React, { useEffect } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/delete-admin-bank-popup/DeleteAdminBankStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const DeleteAdminBank = ({ show1, setShowModal, onClick, msg }) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="approval"
      show={show1}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext">
                {msg !== undefined && t(`${msg}`)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name={t("Yes")}
                  filled={true}
                  primary={true}
                  onClick={onClick}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="cancel">
                <CustomButton
                  type="submit"
                  name={t("No")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ admin = {} }) => {
  const verifyUserAccountSuccess = _.get(
    admin,
    "verifyUserAccountSuccess",
    false
  );
  const verifyUserAccountRequestLoading = _.get(
    admin,
    "verifyUserAccountRequestLoading",
    false
  );
  const approveBusinessRequestLoading = _.get(
    admin,
    "approveBusinessRequestLoading",
    false
  );
  const approveBusinessRequestSuccess = _.get(
    admin,
    "approveBusinessRequestSuccess",
    false
  );
  return {
    verifyUserAccountSuccess,
    verifyUserAccountRequestLoading,
    approveBusinessRequestLoading,
    approveBusinessRequestSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  approveBankAccount: (data) =>
    dispatch({ type: "VerifyUserAccountRequest", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DeleteAdminBank));
