import { call, put } from "redux-saga/effects";
import {
  createIdoSuccess,
  createIdoTrigger,
  createIdoFailure,
  getroundIdoFailure,
  getroundIdoSuccess,
  getroundIdoTrigger,
  createIdoTransTrigger,
  createIdoTransFailure,
  createIdoTransSuccess,
  getidolistSuccess,
  claimIdoTransFailure,
  claimIdoTransSuccess,
  claimIdoTransTrigger,
  getRoundcountSuccess,
  gettransactionCountSuccess,
  gettclaimtokenSuccess,
  getsendtokenCountSuccess,
  gettIdoBalanceSuccess,
  getCyborExchangeRateSuccess,
  getCyborExchangeRateTrigger,
} from "./idoredux";
import Api from "./../services/ApiCaller";
import { getAccessToken, getRole, getWalletId } from "./utilities";
import { toast } from "react-toastify";
import { setIdobuyModalSuccess } from "./appredux";
import {
  getAccountAndBalanceByWalletIdSuccess,
  getCoinOrTokenBalanceSuccess,
} from "./platformuserredux";

export function* createRoundIdo(api, { payload = {} }) {
  yield put(createIdoTrigger(true));
  try {
    const {
      roundName,
      bonus,
      lockingPeriod,
      tokenLimit,
      exchangeRate,
      roundN,
    } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.createIDORound,
      {
        lockingPeriod,
        tokenLimit,
        exchangeRate,
        roundN,
        token,
        roundName,
        bonus,
      },
      true
    );
    yield put(createIdoSuccess(result));
    // console.log('Within ido saga' , result);
    const result2 = yield call(
      Api.callServer,
      api.getIDOround,
      {
        token,
      },
      true
    );
    yield put(getroundIdoSuccess(result2));
  } catch (e) {
    console.log(e);
    yield put(createIdoFailure(e));
  } finally {
    yield put(createIdoTrigger(false));
  }
}

export function* createIdoTrans(api, { payload = {} }) {
  yield put(createIdoTransTrigger(true));
  try {
    const {
      date,
      amountPaid,
      tokensAllocated,
      address,
      idoRoundId,
      currencyType,
      coinId,
    } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.createIDOTrans,
      {
        amountPaid,
        tokensAllocated,
        address,
        idoRoundId,
        currencyType,
        date,
        token,
        coinId,
      },
      true
    );
    toast.success("Transferred Successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(createIdoTransSuccess(result));
  } catch (e) {
    yield put(createIdoTransFailure(e));
  } finally {
    yield put(createIdoTransTrigger(false));
  }
}

export function* getRoundIdo(api, { payload = {} }) {
  yield put(getroundIdoTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getIDOround,
      {
        token,
      },
      true
    );
    yield put(getroundIdoSuccess(result));
  } catch (error) {
    yield put(getroundIdoFailure(e));
  } finally {
    yield put(getroundIdoTrigger(false));
  }
}

export function* getidoListbyaddress(api, { payload = {} }) {
  // console.log('Payload from ido' , payload)
  yield put(getroundIdoTrigger(true));
  try {
    const token = yield getAccessToken();
    let result;
    const role = yield getRole();
    if (role == "Admin") {
      result = yield call(
        Api.callServer,
        api.allTransactions,
        {
          token,
          skip: payload.skip,
          limit: payload.limit,
          orderBy: payload.orderBy,
          sort: payload.sort,
        },
        true
      );
    } else {
      result = yield call(
        Api.callServer,
        api.getidolistbyaddress,
        {
          token,
        },
        true
      );
    }
    const result2 = yield call(
      Api.callServer,
      api.getIDOBalance,
      {
        token,
      },
      true
    );
    yield put(gettIdoBalanceSuccess(result2));
    yield put(getidolistSuccess(result));
  } catch (error) {
    yield put(getroundIdoFailure(error));
  } finally {
    yield put(getroundIdoTrigger(false));
  }
}

export function* claimIdoTrans(api, { payload = {} }) {
   yield put(claimIdoTransTrigger(true));
  try {
    const id = yield getWalletId();
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.claimiodTrans,
      {
        id: payload,
        token,
      },
      true
    );
    toast.success("Claimed Successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
    const result3 = yield call(
      Api.callServer,
      api.getIDOBalance,
      {
        token,
      },
      true
    );
    yield put(gettIdoBalanceSuccess(result3));
    const result4 = yield call(
      Api.callServer,
      api.getAccountAndBalanceByWalletId,
      { token, id },
      true
    );
    yield put(getAccountAndBalanceByWalletIdSuccess(result4));
    const result2 = yield call(
      Api.callServer,
      api.getidolistbyaddress,
      {
        token,
      },
      true
    );
    yield put(getidolistSuccess(result2));
  } catch (e) {
    console.log(e);
    // yield put(claimIdoTransFailure(e));
  } finally {
    yield put(claimIdoTransTrigger(false));
  }
}

export function* getRoundcount(api, { payload = {} }) {
  const token = yield getAccessToken();
  const result = yield call(
    Api.callServer,
    api.getroundcount,
    {
      token,
    },
    true
  );

  yield put(getRoundcountSuccess(result));
}
export function* getTransactionCount(api, { payload = {} }) {
  const token = yield getAccessToken();
  const result = yield call(
    Api.callServer,
    api.gettransactionCount,
    {
      token,
    },
    true
  );
  yield put(gettransactionCountSuccess(result));
}

export function* getclaimtokenCount(api, { payload = {} }) {
  const token = yield getAccessToken();
  const result = yield call(
    Api.callServer,
    api.claimtokenCount,
    {
      token,
    },
    true
  );

  yield put(gettclaimtokenSuccess(result));
}

export function* getCyborExchangeRate(api, { payload = {} }) {
  const token = yield getAccessToken();
  try {
    yield put(getCyborExchangeRateTrigger(true));

    let { token1, id } = payload;
    if (token1 && id) {
      const result = yield call(
        Api.callServer,
        api.getcyborExchangeRate,
        {
          token1,
          id,
          token,
        },
        true
      );

      yield put(getCyborExchangeRateSuccess(result));
    }
  } catch (e) {
  } finally {
    yield put(getCyborExchangeRateTrigger(false));
  }
}

export function* getsendtokenCount(api, { payload = {} }) {
  const token = yield getAccessToken();
  const result = yield call(
    Api.callServer,
    api.sendtokenCount,
    {
      token,
    },
    true
  );

  yield put(getsendtokenCountSuccess(result));
}

export function* BuyCyborcoinFromIdo(api, { payload = {} }) {
  yield put(createIdoTransTrigger(true));
  try {
    const { idoRoundId, token2, amount } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.buyCyborcoinFromIdo,
      {
        idoRoundId,
        token2,
        amount,
        token,
      },
      true
    );
    toast.success("Token Allocated Successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
    let result2 = yield call(
      Api.callServer,
      api.getidolistbyaddress,
      {
        token,
      },
      true
    );
    yield put(getidolistSuccess(result2));
    const result3 = yield call(
      Api.callServer,
      api.getIDOBalance,
      {
        token,
      },
      true
    );
    yield put(gettIdoBalanceSuccess(result3));
    yield put(setIdobuyModalSuccess(false));
  } catch (e) {
    yield put(createIdoTransFailure(e));
  } finally {
    yield put(createIdoTransTrigger(false));
  }
}

export function* EditRoundPrivacy(api, { payload = {} }) {
  yield put(createIdoTransTrigger(true));
  try {
    const { roundId, active } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.editRoundPrivacy,
      {
        roundId,
        active,
        token,
      },
      true
    );
    const result2 = yield call(
      Api.callServer,
      api.getIDOround,
      {
        token,
      },
      true
    );
    yield put(getroundIdoSuccess(result2));
  } catch (e) {
    yield put(createIdoTransFailure(e));
  } finally {
    yield put(createIdoTransTrigger(false));
  }
}
