export default {
  Dashboard: "Дашборд",
  MyBalances: "Мои балансы",
  MyWallets: "Мои кошельки",
  MyWallet: "Мой кошелек",
  CryptoWallet: "Крипто кошелек",
  FiatWallet: "Фиатный кошелек",
  Transactions: "Транзакции",
  TransferHistory: "История переводов",
  ExchangeHistory: "История обменов",
  Deposit: " Депозит",
  Withdrawals: "Снятие",
  IDOCyborCoin: "IDO PayCrypt",
  CyborProtectionSystem: "PayCrypt Protection System",
  MarketRates: "Рыночные ставки",
  PersonalSettings: "Настройки",
  Reports: "Отчеты",
  PaymentReport: "Отчет об оплате",
  Business: "Бизнесс",
  BranchManagement: "Управление отделениями",
  PaymentHistory: "История платежей",
  AdminIDOPanel: "Панель администратора IDO",
  IDODashboard: "Dashboard IDO",
  LockedCyborCoin: "Заблокированные токены Cybor",
  AddBankRequest: "Банковские запросы",
  FiatWithdrawRequest: "Запрос на вывод средств Fiat",
  CompletedTransaction: "Завершенная транзакция",
  PendingTransaction: "Ожидающая транзакция",
  CancelledTransaction: "Отмененная транзакция",
  Oursupportworks: "Наша служба поддержки доступна",
  DefiLoans: "Крипто кредит",
  BankLoan: "Банковский кредит",
  Cryptocards: "Криптовалюты",
  Buysellcrypto: "Купить/Продать Криптовалюту",
  Loanrequests: "Запросы на кредит",
  Borrow: "Занимать",
};
