export default {
  All: "All",
  Received: "Received",
  Sent: "Sent",
  Deposits: "Deposits",
  Withdrawals: "Withdrawals",
  Captured: "Captured",
  Pending: "Pending",
  Cancelled: "Cancelled",
  Transfer: "Transfer",
  Round: "Round",
  Crypto: "Crypto",
  Fiat: "Fiat",
  Rejected: "Rejected",
  Approved: "Approved",
  Business: "Business",
  Personal: "Personal",
  "Business": "Business",
  "Personal": "Personal",
  MyProfile: "My Profile",
  Myaccounts: "My accounts",
};
