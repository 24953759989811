import * as _ from "lodash";
import React from "react";
import CustomButton from "@components/custom-button/CustomButton";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "@components/send-popup/SendPopupStyles.scss";

const SendPopup = ({ show1, setShowModal }) => {
  return (
    <Modal
      className="sendpopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <span className="exchangetitle">Send</span>
        </Modal.Title>
        <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext">Send: 2 ETH to wallet</div>
            </Col>
            <Col lg={12}>
              <div className="exchangebox">
                <span className="address">
                  0x31FA4b90c2aD0cf38E152E4A77413162c2DbaDf6
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col lg={6}>
            <div className="cancel">
              <CustomButton
                type="submit"
                name="Cancel"
                filled={true}
                primary={true}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="confirm">
              <CustomButton
                type="submit"
                name="Confirm"
                filled={true}
                primary={true}
              />
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(SendPopup);
