export default {
  WelcometoCyborPayfrombusiness: "Добро пожаловать в PayCrypt Business",
  Tostartuseallthefeatures:
    "Чтобы начать использовать все возможности платформы, подтвердите свою организацию",
  UploadyourDocuments: "Загрузите Ваши Документы",
  documensconfirmingyouractivities:
    "Документы, подтверждающие вашу деятельность в соответствии с действующим законодательством. Документ должен быть в форматах PDF, DOCX, JPEG.",
  wehavesentyouonetimepassword:
    "Мы отправили вам одноразовый пароль на номер телефона, указанный на предыдущей странице. Пожалуйста, введите его здесь, чтобы подтвердить",

  verificationmailsent:
    "Письмо с подтверждением отправлено на ваш адрес электронной почты. Пожалуйста, проверьте свой почтовый ящик, спам, а также папку нежелательной почты и откройте письмо с подтверждением. Нажмите на",
  link: "ссылку",
  inmailtoVerify: "в письме для Верификации",
  or: "или",
  button: "кнопку",
  upload: " Загрузить...",

  companyname: "Название компании",
  validname: "Пожалуйста, введите название компании",
  address: "Адрес",
  enteraddress: "Подалуйста, введите адрес",
  contactperson: "Контактное лицо",
  contactname: "Пожалуйста, введите имя",
  "Select Document": "Выберите документ",
  Requestsent: "Запрос отправлен!",
  Thankyouprovidinginfo: "Спасибо за предоставленную информацию. Мы свяжемся с вами в ближайшее время!",
  DoyouwanttoApprove: "Вы хотите одобрить?",
};
