import * as _ from "lodash";
import React, { useState, useEffect, Suspense } from "react";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import "@components/reset-password-popup/ResetPasswordPopup.scss";
import PasswordSuccess from "src/components/password-success/PasswordSuccess";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Loader from "@components/loader/Loader";

const ResetPasswordPopup = ({
  show1,
  setShowModal,
  changePasswordStart,
  changePasswordloading,
  changePasswordErrorMessage,
  userAccessToken,
  changePasswordSuccess,
  clearErrorMessage,
}) => {
  const [showeye, setshoweye] = useState(true);
  const [showeye2, setshoweye2] = useState(true);
  const [showeye3, setshoweye3] = useState(true);
  const [showPasswordErr, setShowPasswordErr] = useState(true);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [password1, setPassword1] = useState("");
  const [validated, setValidated] = useState(true);
  const { t, i18n, ready } = useTranslation();
  const [show, setShowPass] = useState(false);
  const [currentLang,setCurrentlang] = useState('en')

  useEffect(() => {
    setCurrentlang(localStorage.lang)
  }, [localStorage.lang]);


  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    }
    else if(form.checkValidity()===true) {
      setShowPasswordErr(true);
      if(password2 !== password1){
        event.preventDefault();
        event.stopPropagation();
        setValidated(false);
      }else{
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const data = {
          OldPassword: password,
          password: password2,
          token: userAccessToken,
          lang: currentLang
        };
        changePasswordStart(data);
      }
    }

    // if (form.checkValidity() === true) {
    //   setShowPasswordErr(true);
    //   if (password2 !== password1) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setValidated(true);
    //   } else {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setValidated(true);
    //     const data = {
    //       OldPassword: password,
    //       password: password2,
    //       token: userAccessToken,
    //     };
    //     changePasswordStart(data);
        
    //   }
    // }
  };

  useEffect(() => {    
    clearErrorMessage()
  } ,[])
  useEffect(() => {
    if (changePasswordSuccess !== undefined) {
      setShowModal(false);
      setShowPass(true);
      setPassword("");
      setPassword1("");
      setPassword2("");
      setValidated(true);
      setShowPasswordErr(false);
      clearErrorMessage()
    }
  }, [changePasswordSuccess]);


  const onClose = ()=>{
    setShowModal(false);
   
    setPassword("");
    setPassword1("");
    setPassword2("");
    setValidated(true);
    setShowPasswordErr("");
    clearErrorMessage()
  }

  return (
    <>
      {changePasswordloading && <Loader />}
      <PasswordSuccess
        show1={show}
        setShowPass={(e) => setShowPass()}
        setShowModal={(e) => setShowPass(e)}
      />
      <Modal
        className="ResetPasswordPopup"
        show={show1}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() =>{ 
          onClose()
         
          }}
        centered
      >
        <Form noValidate onSubmit={(event) => onSendmsg(event)}>
          <Modal.Header>
            <Modal.Title>
              <span className="exchangetitle">{t("ResetPassword")}</span>
            </Modal.Title>
            <div className="closeicon">
              <CloseIcon
                className="closebtn"
                onClick={() => {
                onClose()

                }}
              />
            </div>

            <div
              className="back"
              onClick={() => {
                onClose();
              }}
            >
              <ExpandIcon className="closeicon1" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg={12} className="colspace">
                  <div className="titlepass">
                    <span className="titleText">{t("OldPassword")}</span>
                  </div>

                  <div className="inputboxpass">
                    <InputField
                      type={showeye ? "password" : "text"}
                      onChange={(e) => {
                        setShowPasswordErr(false);
                        setPassword(e.target.value);
                      }}
                      value={password}
                      eye
                      required
                      setshow={() => setshoweye(!showeye)}
                      showeye={showeye}
                      isInvalid={validated === false}
                      error={
                        password === "" ? "Old password cannot be empty" : ""
                      }
                    />
                  </div>

                  <div className="titlepass">
                    <span className="titleText">{t("NewPassword")}</span>
                  </div>
                  <div className="inputboxpass">
                    <InputField
                      type={showeye2 ? "password" : "text"}
                      onChange={(e) =>{
                        // setShowPasswordErr(false)
                        setPassword2(e.target.value)}}
                      value={password2}
                      eye
                      required
                      setshow={() => {
                        setShowPasswordErr(false);
                        setshoweye2(!showeye2);
                      }}
                      showeye={showeye2}
                      isInvalid={
                        validated === false ||
                        (password1 && password2 && password1 !== password2)
                      }
                      error={
                        password2 && password1 !== password2
                          ? "Password and confirm password should match"
                          : password2.length < 8
                          ? "Password cannot be empty"
                          : ""
                      }
                    />
                  </div>

                  <div className="titlepass">
                    <span className="titleText">{t("ConfirmPassword")}</span>
                  </div>
                  <div className="inputboxpass">
                    <InputField
                      na
                      type={showeye3 ? "password" : "text"}
                      onChange={(e) => setPassword1(e.target.value)}
                      value={password1}
                      eye
                      required
                      setshow={() => {
                        setShowPasswordErr(false);
                        setshoweye3(!showeye3);
                      }}
                      showeye={showeye3}
                      isInvalid={
                        validated === false ||
                        (password1 && password1 !== password2)
                      }
                      error={
                        password1 && password1 !== password2
                          ? "Password and confirm password should match"
                          : password1.length < 8
                          ? "Confirm password cannot be empty"
                          : ""
                      }
                    />
                  </div>
                </Col>

                <Col lg={6} sm={6} xs={6}>
                  <div className="confirm">
                    <CustomButton
                      type="submit"
                      name={t("Confirm")}
                      filled={true}
                      primary={true}
                    />
                  </div>
                </Col>
                <Col lg={6} sm={6} xs={6}>
                  <div className="cancel">
                    <CustomButton
                      name={t("Cancel")}
                      filled={true}
                      primary={true}
                      onClick={(e) =>{
                        // console.log("i am called close",clearErrorMessage()) 
                        e.preventDefault();
                        onClose() }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
        {showPasswordErr && (
          <p className="red">
            {changePasswordErrorMessage !== undefined ? t(`${"InvalidPassword"}`) :""}
            {/* {changePasswordErrorMessage !== undefined
              ? changePasswordErrorMessage 
                ? "Invalid Password"
                : ""
              : ""} */}
          </p>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const changePasswordloading = _.get(user, "changePasswordloading", false);
  const changePasswordErrorMessage = _.get(
    user,
    "changePasswordErrorMessage",
    undefined
  );
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const changePasswordSuccess = _.get(user, "changePasswordSuccess", undefined);
  return {
    changePasswordloading,
    changePasswordErrorMessage,
    userAccessToken,
    changePasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePasswordStart: (data) =>
    dispatch({ type: "changePasswordStart", payload: data }),
    clearErrorMessage:()=>
    dispatch({ type: "clearErrorMessagePopup" }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ResetPasswordPopup));
