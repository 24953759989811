import * as _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  requestedAccountVerificationSuccess,
  requestedAccountVerificationTrigger,
  requestedAccountVerificationError,
  usersFiatDepositRequestSuccess,
  usersFiatDepositRequestTrigger,
  usersFiatWithdrawRequestSuccess,
  usersFiatWithdrawRequestTrigger,
  businessRequestSuccess,
  businessRequestTrigger,
  businessRequestFailure,
  businessAccountCountSuccess,
  businessAccountCountTrigger,
  verifyUserAccountRequestSuccess,
  verifyUserAccountRequestError,
  verifyUserAccountRequestTrigger,
  verifyUserDepositRequest,
  verifyUserDepositRequestTrigger,
  verifyUserWithdrawRequest,
  verifyUserWithdrawRequestTrigger,
  approveBusinessRequestTrigger,
  approveBusinessRequestSuccess,
  approveBusinessRequestFailure,
  withdrawRequestCountTrigger,
  withdrawRequestCount,
  depositRequestCountTrigger,
  depositRequestCount,
  bankAccountRequestCountTrigger,
  bankAccountRequestCountSuccess,
  usersListTrigger,
  usersListSuccess,
  usersListCountTrigger,
  usersListCountSuccess,
  setUsersStatusTriggered,
  setUsersStatusSuccess,
  callAllPlatFormBanksStart,
  callAllPlatFormBanksSuccess,
  callAllPlatFormBanksFailure,
  editAdminBankStart,
  editAdminBankSuccess,
  editAdminBankFailure,
  addAdminBankStart,
  addAdminBankSuccess,
  addAdminBankFailure,
  deleteAdminBankStart,
  deleteAdminBankSuccess,
  deleteAdminBankFailure,
  editBankImagesStart,
  editBankImagesSuccess,
  editBankImagesFailure,

  editColouredLogoStart,
  editColouredLogoSuccess,
  editColouredLogoFailure,

  editNonColouredLogoStart,
  editNonColouredLogoSuccess,
  editNonColouredLogoFailure,
  usersListSuccessDownload,
} from "./adminredux";
import { getAccessToken } from "./utilities";

export function* requestedAccountVerification(api, { payload = {} }) {
  const { skip, limit, status, startDate, endDate } = payload
  yield put(requestedAccountVerificationTrigger(true));
  try {
    const token = yield getAccessToken();
    let data = {
      token,
      skip,
      limit,
      status,
      startDate,
      endDate
    };
    const result = yield call(
      Api.callServer,
      api.requestedAccountVerification,
      data,
      true
    );
    yield put(requestedAccountVerificationSuccess(result));
  } catch (error) {
    yield put(requestedAccountVerificationError(error.message));
  } finally {
    yield put(requestedAccountVerificationTrigger(false));
  }
}

export function* usersFiatDepositRequest(api, { payload = {} }) {
  yield put(usersFiatDepositRequestTrigger(true));
  try {
    const { type, bankName, AccountNumber, truee, status, limit, skip, startDate, endDate } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      type,
      bankName,
      AccountNumber,
      truee,
      status,
      limit,
      skip,
      startDate,
      endDate
    };
    const result = yield call(
      Api.callServer,
      api.usersFiatDepositRequest,
      data,
      true
    );
    yield put(usersFiatDepositRequestSuccess(result));
  } catch (error) {
  } finally {
    yield put(usersFiatDepositRequestTrigger(false));
  }
}

export function* businessRequest(api, { payload = {} }) {
  yield put(businessRequestTrigger(true));
  try {
    const { approvalRequest, startDate, endDate, skip, limit } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      approvalRequest: approvalRequest,
      startDate: startDate,
      endDate: endDate,
      skip: skip,
      limit: limit
    };
    const result = yield call(Api.callServer, api.businessRequest, data, true);
    yield put(businessRequestSuccess(result));
  } catch (error) {
    businessRequestFailure(error.message)
  } finally {
    yield put(businessRequestTrigger(false));
  }
}

export function* usersFiatWithdrawRequest(api, { payload = {} }) {
  yield put(usersFiatWithdrawRequestTrigger(true));
  try {
    const { bankName, AccountNumber, status, limit, skip, startDate, endDate } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      AccountNumber,
      bankName,
      status,
      limit,
      skip,
      startDate,
      endDate
    };
    const result = yield call(
      Api.callServer,
      api.usersFiatWithdrawRequest,
      data,
      true
    );
    yield put(usersFiatWithdrawRequestSuccess(result));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(usersFiatWithdrawRequestTrigger(false));
  }
}

export function* VerifyUserAccountRequest(api, { payload = {} }) {
  yield put(verifyUserAccountRequestTrigger(true));
  try {
    const { status, id } = payload;
    const token = yield getAccessToken();
    let data = { token, status, id };
    const result = yield call(
      Api.callServer,
      api.VerifyUserAccountRequest,
      data,
      true
    );
    yield put(verifyUserAccountRequestSuccess(result));
    yield put({ type: "requestedAccountVerification" })
  } catch (error) {
    yield put(verifyUserAccountError(error.message))
    yield put({ type: "requestedAccountVerification" })
  } finally {
    yield put(verifyUserAccountRequestTrigger(false));
  }
}

export function* UserDepositRequestVerify(api, { payload = {} }) {
  yield put(verifyUserDepositRequestTrigger(true));
  try {
    const { relations, id, status, note, callAll } = payload;
    const token = yield getAccessToken();
    let rel = relations
    if (relations == undefined || relations == '' || relations == null) rel = ["currency", "user"];
    let data = {
      token,
      rel,
      id,
      status,
      note,
    };
    const result = yield call(
      Api.callServer,
      api.VerifyUserDepositRequest,
      data,
      true
    );
    yield callAll()

    yield put(verifyUserDepositRequest(result));
    yield put(verifyUserDepositRequestTrigger(false))
  } catch (error) {
    yield put(verifyUserDepositRequestTrigger(false))
  }
  // } finally {
  //   yield put(verifyUserDepositRequestTrigger(false));
  // }
}

export function* VerifyUserWithdrawRequest(api, { payload = {} }) {
  yield put(verifyUserWithdrawRequestTrigger(true));
  try {
    const { relations, id, status, note, callAll } = payload;
    const token = yield getAccessToken();
    let rel = relations
    if (relations == undefined || relations == '' || relations == null) rel = ["currency", "user"];
    let data = {
      token,
      rel,
      id,
      status,
      note,
    };
    const result = yield call(
      Api.callServer,
      api.VerifyUserWithdrawRequest,
      data,
      true
    );
    yield callAll()
    yield put(verifyUserWithdrawRequest(result));
  } catch (error) {
    yield put(verifyUserWithdrawRequestTrigger(false))
  }
  finally {
    yield put(verifyUserWithdrawRequestTrigger(false));
  }
}

export function* ApproveBusinessRequest(api, { payload = {} }) {
  yield put(approveBusinessRequestTrigger(true));
  try {
    const { id, status } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      id,
      status,
    };
    const result = yield call(
      Api.callServer,
      api.ApproveBusinessRequest,
      data,
      true
    );
    yield put(approveBusinessRequestSuccess(result));
    yield put({ type: 'businessRequest' })
  } catch (error) {
    yield put(approveBusinessRequestFailure(error.message))
    yield put({ type: 'businessRequest' })
  } finally {
    yield put(approveBusinessRequestTrigger(false));
  }
}

export function* BusinessCountAsync(api, { payload = {} }) {
  yield put(businessAccountCountTrigger())
  let token = yield getAccessToken();
  const { approvalRequest, startDate, endDate, skip, limit } = payload
  try {
    const data = yield call(Api.callServer, api.businessRequestCount, {
      token,
      approvalRequest,
      startDate,
      endDate,
      skip,
      limit
    })
    yield put(businessAccountCountSuccess(data.count))
  } catch (error) {
    console.log(error.message)
  }
}

export function* WithdrawRequestCount(api, { payload = {} }) {
  yield put(withdrawRequestCountTrigger(true));
  try {
    const { startDate, endDate, skip, limit, type, status } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      startDate,
      endDate,
      skip,
      limit,
      type,
      status
    };
    const result = yield call(
      Api.callServer,
      api.withdrawRequestCount,
      data,
      true
    );
    yield put(withdrawRequestCount(result));
  } catch (error) {
    yield put(withdrawRequestCountTrigger(false));
  } finally {
    yield put(withdrawRequestCountTrigger(false));
  }
}

export function* DepositRequestCount(api, { payload = {} }) {
  yield put(depositRequestCountTrigger(true));
  try {
    const { startDate, endDate, type, skip, limit, status } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      startDate,
      endDate,
      limit,
      type,
      skip,
      status
    };
    const result = yield call(
      Api.callServer,
      api.depositRequestCount,
      data,
      true
    );
    yield put(depositRequestCount(result));
  } catch (error) {
    yield put(depositRequestCountTrigger(false));
  } finally {
    yield put(depositRequestCountTrigger(false));
  }
}


export function* BankAccountRequestCountAsync(api, { payload }) {
  yield put(bankAccountRequestCountTrigger())
  try {
    const token = yield getAccessToken();
    const { startDate, endDate, status } = payload
    let data = {
      token,
      startDate,
      endDate,
      status
    }
    const result = yield call(Api.callServer, api.bankAccountCount, data)
    yield put(bankAccountRequestCountSuccess(result.count))
  } catch (error) {
    console.log(error.message)
  }
}

export function* usersList(api, { payload = {} }) {
  yield put(usersListTrigger(true));
  try {
    const { startDate, endDate, skip, limit, role } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      startDate,
      endDate,
      skip,
      limit,
      role
    };
    const result = yield call(
      Api.callServer,
      api.getUsersList,
      data,
      true
    );
    yield put(usersListSuccess(result))
  } catch (error) {
    yield put(usersListTrigger(false));
  } finally {
    yield put(usersListTrigger(false));
  }
}

export function* usersListDownload(api, { payload = {} }) {
  yield put(usersListTrigger(true));
  try {
    const { startDate, endDate, limit, role } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      startDate,
      endDate,
      skip:0,
      limit,
      role
    };
    const result = yield call(
      Api.callServer,
      api.getUsersList,
      data,
      true
    );
    yield put(usersListSuccessDownload(result))
  } catch (error) {
    yield put(usersListTrigger(false));
  } finally {
    yield put(usersListTrigger(false));
  }
}



export function* usersListCount(api, { payload = {} }) {
  yield put(usersListCountTrigger(true))
  try {
    const token = yield getAccessToken();
    const { startDate, endDate, role } = payload;
    let data = {
      token,
      startDate,
      endDate,
      role
    }
    const result = yield call(Api.callServer, api.getUsersListCount, data)
    yield put(usersListCountSuccess(result))
  } catch (error) {
    yield put(usersListCountTrigger(false))
  } finally {
    yield put(usersListCountTrigger(false))
  }
}

export function* setUsersStatus(api, { payload = {} }) {
  yield put(setUsersStatusTriggered(true));
  try {
    const { id, status } = payload;
    const token = yield getAccessToken();
    let data = {
      token,
      id,
      status
    };
    const result = yield call(
      Api.callServer,
      api.setUsersStatus,
      data,
      true
    );
    yield put(setUsersStatusSuccess(result));
  } catch (error) {
    yield put(setUsersStatusTriggered(false));
  } finally {
    yield put(setUsersStatusTriggered(false));
  }
}


export function* allAdminBanks(api, { payload }) {
  yield put(callAllPlatFormBanksStart())
  try {
    const token = yield getAccessToken();
    let data = yield call(Api.callServer, api.getAllBanks, { token })
    yield put(callAllPlatFormBanksSuccess(data))
  } catch (err) {
    yield put(callAllPlatFormBanksFailure(err.message))
  }
}


export function* editAdminBankAsync(api, { payload }) {
  yield put(editAdminBankStart())
  try {
    const token = yield getAccessToken();
    const { id, formData } = payload
    let data = yield call(Api.callServer, api.editOwnerBankAccount, { token, id, formData })
    yield put({ type: "callAllAdminBanksAsync" })
    yield put(editAdminBankSuccess())
  } catch (error) {
    yield put(editAdminBankFailure(error.message))
  }
}

export function* addAdminBankAsync(api, { payload }) {
  yield put(addAdminBankStart())
  try {
    const token = yield getAccessToken();
    const { formData } = payload
    let data = yield call(Api.callServer, api.addAdminBank, { token, formData })
    yield put({ type: "callAllAdminBanksAsync" })
    yield put(addAdminBankSuccess())
  } catch (err) {
    yield put(addAdminBankFailure(err.message))
  }
}

export function* deleteAdminBankAsync(api, { payload }) {
  yield put(deleteAdminBankStart())
  try {
    const token = yield getAccessToken();
    let data = yield call(Api.callServer, api.deleteAdminBank, { token, ...payload })
    yield put({ type: "callAllAdminBanksAsync" })
    yield put(deleteAdminBankSuccess())
  } catch (err) {
    yield put(deleteAdminBankFailure(err.message))
  }
}

export function* editBankImagesAsync(api, { payload }) {
  yield put(editBankImagesStart())
  try {
    const token = yield getAccessToken();
    const { id, formData } = payload
    let data = yield call(Api.callServer, api.editBankIcons, { token, id, formData })
    yield put({ type: "callAllAdminBanksAsync" })
    yield put(editBankImagesSuccess(data))
  } catch (err) {
    yield put(editBankImagesFailure(err.message))
  }
}


export function* editNonColouredIconAsync(api, { payload }) {
  yield put(editNonColouredLogoStart())
  try {
    const token = yield getAccessToken();
    const { id, formData } = payload
    let data = yield call(Api.callServer, api.editNonColouredIcon, { token, id, formData })
    yield put({ type: "callAllAdminBanksAsync" })
    yield put(editNonColouredLogoSuccess(data))
  } catch (err) {
    yield put(editNonColouredLogoFailure(err.message))
  }
}