export default {
  Logo: "Logo",
  Thesizeofimage: "Kabul edilen resmin boyutu",
  pixels: "piksel",
  sizetothefile: "dosya boyutu",
  upto: " maksimum",
  inPNGorJPEGformat: "PNG veya JPEG formatında olmalıdır",
  Change: " Yükle",
  Exclude: "Sil",
  Operator: "Operatör",
  Edit: "Düzenle",
  Delete: "Sil",
  Commission: "Komisyon",
  Name: "İsim",
  FirstName: "İlk adı",
  LastName: "Soy isim",
  Email: "e-posta",
  Phone: "Telefon",
  Login: "Kullanıcı adı",
  Password: "Şifre",
  Save: "Kaydet",
  Cancel: "İptal",
  ChangePassword: "Şifreyi değiştir",
  Settings: "Ayarlar",
  LogOut: "Çıkış Yap",
  ResetPassword: "Şifreyi yenile",
  OldPassword: "Eski şifre",
  NewPassword: "Yeni Şifre",
  ConfirmPassword: "Şifreyi Onayla",
  MyProfile: "Profilim",
  Myaccounts: "Hesaplarım",
  MyBalance: "Bekiyem",
  MyBankAccount: "Banka hesabım",
  BankAccountsRegistered:
    "Hesabınıza kayıtlı banka hesapları",
  AddNewBankAccountt: "Yeni banka hesabı ekle...",
  CreditCardMessage:
    "* Kredi Kartı Para Yatırma ve Çekme işlemleri Fiat limitlerine tabidir.",
  MyCryptoAddress: "Kripto Adresim",
  CryptoAddressAsFollows: "Hesabınızın kripto adresi",
  AddNeww: "Yeni ekle...",
  AddNewCryptoAddress: "Yeni kripto adresi ekle...",
  Photo: "Fotoğraf",
  "Old password cannot be empty": "Eski şifre alanı boş olamaz",
  "Password and confirm password should match": "Şifre ve şifreyi onayla bölümleri eşleşmelidir",
  "Password cannot be empty": "Şifre alanı boş olamaz",
  "Password and confirm password should match": "Şifre ve şifreyi onayla bölümleri eşleşmelidir",
  "Confirm password cannot be empty": "Şifreyi onayla alanı boş olamaz",
  Merchants: "Pazarlamacı",
  terms: "şartlar",
  MOBILE_USED: "Girdiğiniz telefon numarası kullanılıyor.",
  INVALID_PASSWORD:'Şifre Geçersiz',
  InvalidCredentials:'Geçersiz Kimlik Bilgileri',
  Passwordof8characters:"Parola en az 8 karakter olmalıdır",
  KINDLY_ENTER_VALID_NAME: "Lütfen Geçerli Bir Ad Girin",
  Nametooshort: "İsim çok kısa",
  InvalidPassword: "geçersiz şifre",
  PasswordchangedSuccessfully: "Parola başarıyla değiştirildi !",

};
  
