import * as _ from "lodash";
import React, { useState, useEffect, Suspense } from "react";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "@components/reset-password-popup/ResetPasswordPopup.scss";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Loader from "@components/loader/Loader";
import "@components/forgot-password-popup/ForgotpasswordStyles.scss";
import Forgotpasswordemailsuccess from "@components/forgot-password-email-success/Forgotpasswordemailsuccess";
import { forgotPasswordErrorRemove } from "@store/userredux";

const Forgotpasswordpopup = ({
  show1,
  setShowModal,
  changePasswordStart,
  changePasswordloading,
  changePasswordErrorMessage,
  userAccessToken,
  changePasswordSuccess,
  forgotPasswordLoading,
  forgotPasswordSuccess,
  forgotPasswordError,
  forgotPasswordStart,
  forgotPasswordErrorRemoved,
  exchangeId,
}) => {
  const [validated, setValidated] = useState(true);
  const { t, i18n, ready } = useTranslation();

  const [email, setEmail] = useState("");
  let [show, setshow] = useState(false);
  const [currentLang,setCurrentlang] = useState('en')

  useEffect(() => {
    setCurrentlang(localStorage.lang)
  }, [localStorage.lang]);

  // const onSendmsg = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     setValidated(false);
  //   }

  //   if (form.checkValidity() === true) {
  //     setShowPasswordErr(true);
  //     if (password2 !== password1) {
  //       event.preventDefault();
  //       event.stopPropagation();
  //       setValidated(false);
  //     } else {
  //       event.preventDefault();
  //       event.stopPropagation();
  //       setValidated(true);
  //       const data = {
  //         OldPassword: password,
  //         password: password2,
  //         token: userAccessToken,
  //       };
  //       changePasswordStart(data);
  //     }
  //   }
  // };

  const onSendmsg = (event) => {
    event.preventDefault();
    submitForm();
  };
  const submitForm = () => {
    forgotPasswordStart({
      email: email,
      loginType: "email",
      exchangeId: exchangeId,
      lang: currentLang
    });
    // setEmail('')
  };

  useEffect(() => {
    if (changePasswordSuccess !== undefined) {
      setShowModal(false);
      setShowPass(true);
      setPassword("");
      setPassword1("");
      setPassword2("");
    }
  }, [changePasswordSuccess]);

  useEffect(() => {
    if (forgotPasswordSuccess === true) {
      setShowModal(false);
      setEmail("");
    }
  }, [forgotPasswordSuccess]);

  return (
    <>
      {forgotPasswordLoading && <Loader />}
      {forgotPasswordSuccess && (
        <Forgotpasswordemailsuccess setShowModal={setshow} show1={show} />
      )}

      <Modal
        className="forgotpasswordpopup"
        show={show1}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          forgotPasswordErrorRemoved();
          setShowModal(false);
          setEmail("");
        }}
        centered
      >
        <Form noValidate onSubmit={(event) => onSendmsg(event)}>
          <Modal.Body>
            <CloseIcon
              className="closebtn"
              onClick={() => {
                setShowModal(false);
                setEmail("");
                forgotPasswordErrorRemoved();
              }}
            />
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="maintitle">
                    {" "}
                    <span className="title">{t("ForgotPassword")}</span>
                  </div>
                </Col>
                <hr />
                <Col lg={12}>
                  <div className="txttp">
                    <span className="inner-text">{t("ProvideEmail")}</span>
                  </div>
                  <div className="titlepass">
                    <span className="inputheader">{t("YourEmail")}</span>
                  </div>

                  <div className="inputbox">
                    <InputField
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder={t("Email")}
                      required
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="reset-password-btn">
                    {forgotPasswordError !== undefined && (
                      <p>{t(`${forgotPasswordError}`)}</p>
                    )}
                    <CustomButton
                      name={t("ResetPassword")}
                      filled={true}
                      primary={true}
                      onClick={() => {
                        {
                          setshow(true);
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ app = {}, user = {} }) => {
  const changePasswordloading = _.get(user, "changePasswordloading", false);
  const changePasswordErrorMessage = _.get(
    user,
    "changePasswordErrorMessage",
    undefined
  );
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const changePasswordSuccess = _.get(user, "changePasswordSuccess", undefined);
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const forgotPasswordSuccess = _.get(user, "forgotPasswordSuccess", undefined);
  const forgotPasswordLoading = _.get(user, "forgotPasswordLoading", undefined);
  const forgotPasswordError = _.get(user, "forgotPasswordError", undefined);
  return {
    changePasswordloading,
    changePasswordErrorMessage,
    userAccessToken,
    changePasswordSuccess,
    forgotPasswordLoading,
    forgotPasswordSuccess,
    forgotPasswordError,
    exchangeId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePasswordStart: (data) =>
    dispatch({ type: "changePasswordStart", payload: data }),
  forgotPasswordStart: (data) =>
    dispatch({ type: "forgotPasswordpopupCalled", payload: data }),

  forgotPasswordErrorRemoved: () => dispatch(forgotPasswordErrorRemove()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Forgotpasswordpopup));
