import React from 'react';
import Cards from 'react-credit-cards-2';
import uniqolor from 'uniqolor';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import "@components/nfc-card/NFCCardStyle.scss";


const NFCCard = ({ cards }) => {
  console.log(cards);
  return (
    <div className="NFCCard">
        {cards !== undefined &&
          cards?.length > 0 &&
          cards.map((item, index) => {
            const color = uniqolor(item.cardNumber).color;
            const cardStyle = {
              backgroundColor: color,
            };
            return (
                <div className="App-cards-list" style={cardStyle}>
                <Cards
                  name={item.cardName}
                  number={item.decryptedCardNumber  !== "" ? item.decryptedCardNumber : item.cardNumber}
                  expiry=""
                  cvc=""
                />
                </div>
            );
          })}
    </div>
  );
};

export default NFCCard;
