import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import ScreenLayout from "src/components/layout/ScreenLayout";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import tcheck from "@images/tcheck.svg";
import deposit from "@images/deposit.svg";
import withdraw from "@images/withdraw.svg";
import pending from "@images/pending.svg";
import { ReactComponent as CopyIcon } from "@images/copy.svg";
import { ReactComponent as SquareIcon } from "@images/tcheck.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import CoinList from "@components/coin-list/CoinList";
import { Row, Col, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import BusinessRequestPopup from "@components/business-request-popup/BusinessRequestPopup.js";
import AdminFiatDepositPopup from "@components/admin-fiat-deposit-popup/AdminFiatDepositPopup.js";
import ApprovalPopup from "src/components/approval-popup/ApprovalPopup";
import RejectionPopup from "src/components/rejection-popup/RejectionPopup";
import { ToastContainer, toast } from "react-toastify";
import Filter from "@components/filter/Filter.js";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";
import { useTranslation } from "react-i18next";
import PaymentCancelConfirmation from "@components/payment-cancel-confirmation/PaymentCancelConfirmation";
import "@features/admin-fiat-deposit-request/AdminFiatDepositRequest.scss";

function AdminfiatDepositRequest(props) {
  const {
    usersFiatDepositRequestLoading,
    fiatDepositRequest,
    getFiatDepositRequest,
    setFiatDepositRequest,
    verifyUserDepositRequestLoading,
    depositRequestCount,
    setDepositRequestCount,
    thememode,
  } = props || {};
  const [data, setData] = useState({});
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("third");
  let [show, setshow] = useState(false);
  let [Idata, setIdata] = useState({});
  let [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  let [selectedPaymentID, setSelectedPaymentID] = useState();
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [isFilterSet, setIsFilterSet] = useState(false);
  const [show1, setShowApproval] = useState(false);
  const [show2, setShowRejection] = useState(false);
  const [statusType, setStatusType] = useState("");
  useEffect(() => {
    getFiatDepositRequest({
      type: "deposit",
      skip: 0,
      limit,
      status: statusType,
    });
    setDepositRequestCount({ type: "deposit", status: statusType });
  }, []);

  const { t } = useTranslation();

  return (
    <div id="AdminFiatDepositRequest">
      <PaymentCancelConfirmation
        show1={showCancelConfirmation}
        selectedPayment={selectedPaymentID}
        setShowModal={(status) => setShowCancelConfirmation(status)}
      />

      <AdminFiatDepositPopup show1={show} setShowModal={setshow} data={data} />

      {/* <NavBar location={useLocation()} /> */}
      <ScreenLayout hideLoaderOnStart={true}>
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("FiatDepositRequest")} </span>
                  <div className="filtersection">
                    <Filter
                      onApplyDate={(picker) => {
                        setIsFilterSet(true);
                        getFiatDepositRequest({
                          type: "deposit",
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          skip: 0,
                          limit,
                          status: statusType,
                        });
                        setDepositRequestCount({
                          type: "deposit",
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          status: statusType,
                        });
                        setpages(0);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                      oncancel={() => {
                        setIsFilterSet(false);
                        setStartDate({});
                        setEndDate({});
                        getFiatDepositRequest({
                          type: "deposit",
                          skip: 0,
                          limit,
                          status: statusType,
                        });
                        setDepositRequestCount({
                          type: "deposit",
                          status: statusType,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="btmmargin">
                  <CurrencyTab
                    page={"businessrequest"}
                    changeTab={(e) => {
                      if (e == "second") {
                        setIsFilterSet(false);
                        setStartDate({});
                        setEndDate({});
                        setStatusType("Cancelled");
                        getFiatDepositRequest({
                          type: "deposit",
                          status: "Cancelled",
                          skip: 0,
                          limit,
                        });
                        setDepositRequestCount({
                          type: "deposit",
                          status: "Cancelled",
                        });
                        setpages(0);
                        settableno(e);
                      }
                      if (e == "first") {
                        setIsFilterSet(false);
                        setStartDate({});
                        setEndDate({});
                        setStatusType("Successfull");
                        getFiatDepositRequest({
                          type: "deposit",
                          status: "Successfull",
                          skip: 0,
                          limit,
                        });
                        setDepositRequestCount({
                          type: "deposit",
                          status: "Successfull",
                        });
                        setpages(0);
                        settableno(e);
                      }
                      if (e == "third") {
                        setIsFilterSet(false);
                        setStartDate({});
                        setEndDate({});
                        setStatusType("");
                        getFiatDepositRequest({
                          type: "deposit",
                          skip: 0,
                          limit,
                        });
                        setDepositRequestCount({ type: "deposit" });
                        setpages(0);
                        settableno(e);
                      }
                    }}
                    tableno={tableno}
                  />
                </div>
              </div>

              <Table
                responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless
              >
                {(usersFiatDepositRequestLoading ||
                  verifyUserDepositRequestLoading) && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading">
                      <span className="heading">{t("TransactionID")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("From")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("To")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("Amount")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("CreatedOn")}</span>
                    </th>
                    <th className="trtbleheading thwidth">
                      <span className="heading">{t("Action")}</span>
                    </th>
                    <th className="trtbleheading"></th>
                  </tr>
                </thead>

                <tbody>
                  {fiatDepositRequest &&
                    fiatDepositRequest.map((item, index) => (
                      <tr
                        key={`trans${index}`}
                        onClick={() => {
                          setIdata(item);
                          // setshow(true);
                        }}
                      >
                        <td className="amt">
                          <div className="innerTd idtr ">
                            <span className="id idtxt">{item.id}</span>
                            <p className="hidden-for-desktop date margintop">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </p>
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd">
                            <div className="link">
                              {(item && item.from && item.from) || "F000000"}
                            </div>
                            <div className="link hidden-for-desktop">
                              {(item && item.to && item.to) || "T000000"}
                            </div>
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd">
                            <div className="link hidden-for-mobile">
                              {(item && item.to && item.to) || "T000000"}
                            </div>
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd">{item.amount}</div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd">
                            {moment(item.createdOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd">
                            <div className="btnwidth">
                              {item?.fiatTransactionStatus == "Successfull" ? (
                                <div className=" statustext">
                                  <span>{t("Approved")}</span>
                                </div>
                              ) : item?.fiatTransactionStatus == "Cancelled" ? (
                                <div className=" statustext">
                                  <span>{t("Rejected")}</span>
                                </div>
                              ) : (
                                <>
                                  <div className="approve">
                                    <CustomButton
                                      name={
                                        item?.fiatTransactionStatus ==
                                        "Successfull"
                                          ? t("Approved")
                                          : t("Approve")
                                      }
                                      filled={true}
                                      primary={true}
                                      disable={
                                        item?.fiatTransactionStatus ==
                                        "Successfull"
                                          ? true
                                          : false
                                      }
                                      classname={
                                        item?.fiatTransactionStatus ==
                                        "Successfull"
                                          ? "disabledBtn"
                                          : null
                                      }
                                      onClick={() => {
                                        setData(item);
                                        // getOwnerIBANAccordingToCurrency()
                                        setShowApproval(true);
                                      }}
                                    />
                                  </div>

                                  <div className="reject">
                                    <CustomButton
                                      name={
                                        item?.fiatTransactionStatus ==
                                        "Cancelled"
                                          ? t("Rejcted")
                                          : t("Reject")
                                      }
                                      filled={true}
                                      primary={true}
                                      disable={
                                        item?.fiatTransactionStatus ==
                                        "Cancelled"
                                          ? true
                                          : false
                                      }
                                      classname={
                                        item?.fiatTransactionStatus ==
                                        "Cancelled"
                                          ? "disabledBtn"
                                          : null
                                      }
                                      onClick={() => {
                                        setData(item);
                                        setShowRejection(true);
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className="threedotspace">
                          <div className="vertical innerTd">
                            <Dropdown className="hidden-for-mobile">
                              <Dropdown.Toggle
                                className="threedot"
                                id="dropdown-basic"
                              >
                                <VerticleIcon className="arrowiconclass " />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="menu">
                                <Dropdown.Item
                                  onClick={() => {
                                    setData(item);
                                    setshow(true);
                                  }}
                                >
                                  {t("Details")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Replacementof3dots
                              className=" arrow hidden-for-desktop"
                              onClick={() => {
                                setData(item);
                                setshow(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {depositRequestCount > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(depositRequestCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={depositRequestCount / limit}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        if (isFilterSet == true) {
                          getFiatDepositRequest({
                            type: "deposit",
                            startDate: startDate,
                            endDate: endDate,
                            skip: e.selected * limit,
                            limit,
                            status: statusType,
                          });
                        } else {
                          getFiatDepositRequest({
                            type: "deposit",
                            skip: e.selected * limit,
                            limit,
                            status: statusType,
                          });
                        }
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
              <ApprovalPopup
                onClick={() => {
                  setFiatDepositRequest({
                    id: data.id,
                    status: "Successfull",
                    note: "test",
                  });
                  setShowApproval(false);
                }}
                show1={show1}
                setShowModal={(e) => setShowApproval(e)}
              />
              <RejectionPopup
                onClick={() => {
                  setFiatDepositRequest({
                    id: data.id,
                    status: "Cancelled",
                    note: "test",
                  });
                  setShowRejection(false);
                }}
                show1={show2}
                setShowModal={(e) => setShowRejection(e)}
              />
            </Col>
          </Row>
        </Container>
      </ScreenLayout>
    </div>
  );
}
const mapStateToProps = ({ admin = {}, app = {} }) => {
  const fiatDepositRequest = _.get(admin, "usersFiatDepositRequest", []);
  const usersFiatDepositRequestLoading = _.get(
    admin,
    "usersFiatDepositRequestLoading",
    false
  );
  const verifyUserDepositRequestLoading = _.get(
    admin,
    "verifyUserDepositRequestLoading",
    false
  );
  const depositRequestCount = _.get(admin, "depositRequestCount.count", 0);
  const thememode = _.get(app, "thememode", false);
  return {
    fiatDepositRequest,
    usersFiatDepositRequestLoading,
    verifyUserDepositRequestLoading,
    depositRequestCount,
    thememode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFiatDepositRequest: (data) =>
    dispatch({ type: "usersFiatDepositRequest", payload: data }),
  setFiatDepositRequest: (data) =>
    dispatch({ type: "userDepositRequestVerify", payload: data }),
  setDepositRequestCount: (data) =>
    dispatch({ type: "depositRequestCount", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminfiatDepositRequest);
