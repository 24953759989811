import CustomButton from '@components/custom-button/CustomButton';
import InputField from '@components/input-field/InputField';
import Loader from '@components/loader/Loader';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DropdownSearch from 'src/components/dropdown-search/DropdownSearch';
import Filter from 'src/components/filter/Filter';
import CoinListItem from 'src/components/coin-list-item/CoinListItem';
import BigCoinCard from '@components/big-coin/BigCoinCard.js';
import SmallCard from 'src/components/smallcard/SmallCard';
import ExchangeSmallpopup from 'src/components/exchange-confirmationpopup/ExchangeConfirmationPopup';
import WithdrawSmallpopup from 'src/components/withdraw-smallpopup/WithdrawSmallpopup';
import DepositPopup from 'src/components/deposit-popup/DepositPopup';

import SendPopup from 'src/components/send-popup/SendPopup';

import BankListItem from 'src/components/bank-list-item/BankListItem';
import '@features/HomeScreen/HomeScreenStyles.scss';

function HomeScreen(props) {
  const [bnbdata, setbnbdata] = useState('');
  const [btcdata, setbtcdata] = useState('');
  const [ethdata, setethdata] = useState('');
  const { t, i18n, ready } = useTranslation();
  const [data, setdata] = useState([]);

  const [show, setShowExchange] = useState(false);
  const [show2, setShowWithdraw] = useState(false);
  const [show3, setShowDeposit] = useState(false);
  const [show4, setShowSend] = useState(false);

  // const ws = new WebSocket("wss://stream.binance.com:9443/ws");
  // const msg = {
  //   method: "SUBSCRIBE",
  //   params: ["ethusdt@aggTrade", "btcusdt@aggTrade", "bnbusdt@aggTrade"],
  //   id: 1,
  // };
  // const msg2 = {
  //   method: "UNSUBSCRIBE",
  //   params: ["btcusdt@aggTrade"],
  //   id: 1,
  // };
  //   const msg = {
  //     "method": "LIST_SUBSCRIPTIONS",
  //     "id": 3
  //   }
  // ws.onopen = () => {
  //   ws.send(JSON.stringify(msg));
  // };
  // ws.onmessage = (e) => {
  //   console.log(e);
  //   let newdata = JSON.parse(e.data);
  //   if (!_.isUndefined(e.data)) {
  //     if (newdata.s === "BNBUSDT") setbnbdata(newdata);
  //     if (newdata.s === "ETHUSDT") setethdata(newdata);

  //     if (newdata.s === "BTCUSDT") setbtcdata(newdata);
  //   }
  //   if (_.isArray(newdata)) {
  //     setdata(newdata);
  //   }
  //};

  // setTimeout(() => {
  //   ws.onopen = () => {
  //     ws.send(JSON.stringify(msg2));
  //   };
  // }, 4000);
  if (ready) {
    return (
      <div id='homescreen'>
        <Container>
          <div>
            {' '}
            <Filter />
          </div>

          <DropdownSearch />
          <InputField phone={false} link={false} btn={true} />
          <ExchangeSmallpopup
            show1={show}
            setShowModal={(e) => setShowExchange(e)}
          />
          <WithdrawSmallpopup
            show1={show2}
            setShowModal={(e) => setShowWithdraw(e)}
          />

          <DepositPopup show1={show3} setShowModal={(e) => setShowDeposit(e)} />
          <SendPopup show1={show4} setShowModal={(e) => setShowSend(e)} />
          <div className='btnwidth'>
            <CustomButton
              type='submit'
              name='click here to open Exchange'
              filled={true}
              primary={true}
              onClick={() => {
                setShowExchange(true);
              }}
            />
          </div>
          <div className='btnwidth'>
            <CustomButton
              type='submit'
              name='click here to open Withdraw'
              filled={true}
              primary={true}
              onClick={() => {
                setShowWithdraw(true);
              }}
            />
          </div>
          <div className='btnwidth'>
            <CustomButton
              type='submit'
              name='click here to open Deposit'
              filled={true}
              primary={true}
              onClick={() => {
                setShowDeposit(true);
              }}
            />
          </div>
          <div className='btnwidth'>
            <CustomButton
              type='submit'
              name='click here to open Send'
              filled={true}
              primary={true}
              onClick={() => {
                setShowSend(true);
              }}
            />
          </div>
          <Row>
            <Col lg='12'>
              <div className='boxwidth'>
                <BankListItem />
              </div>
              <div className='boxwidth'>
                <BankListItem />
              </div>
              <div className='boxwidth'>
                <BankListItem />
              </div>
              <div className='boxwidth'>
                <BankListItem />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg='3'></Col>
          </Row>
          <Button onClick={() => props.updateTheme('dark')}>Dark </Button>
          <Button onClick={() => props.updateTheme('light')}>light </Button>
          <Button onClick={() => i18n.changeLanguage('en')}>English</Button>
          <Button onClick={() => i18n.changeLanguage('tr')}>Turkish</Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className='cm'>{t('Symbol')}</th>
                <th className='cm'>{t('Price')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{bnbdata && bnbdata.s}</td>
                <td>{bnbdata && bnbdata.p}</td>
              </tr>
              <tr>
                <td>{btcdata && btcdata.s}</td>
                <td>{btcdata && btcdata.p}</td>
              </tr>
              <tr>
                <td>{ethdata && ethdata.s}</td>
                <td>{ethdata && ethdata.p}</td>
              </tr>
              {data &&
                data.forEach((item, index) => (
                  <tr>
                    <td>{index}</td>
                    <td>{item && item.s}</td>
                    <td>{item && item.p}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
  return <span>{t('loading')}...</span>;
}
const mapStateToProps = ({ tracker = {}, user = {} }) => {
  const signUpLoading = _.get(user, 'signUpLoading', false);

  const signUpError = _.get(user, 'signUpError', '');
  const rooles = _.get(user, 'userInfo.role', '');

  return { rooles, signUpLoading, signUpError };
};

const mapDispatchToProps = (dispatch) => ({
  updateTheme: (data) => dispatch({ type: 'updateTheme', payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
