import { createSlice } from "@reduxjs/toolkit";

const appslice = createSlice({
  name: "app",
  initialState: {
    thememode: "light",
    settings: {},
    version: "",
    role: "Personal",
    hideZeroBalance: false,
    betapopup: false,
    createbranchsuccesspopup: false,
  },
  reducers: {
    appSettingsSuccess: (state, action) => {
      return {
        ...state,
        settings: action.payload,
      };
    },
    updateVersionSuccess: (state, action) => {
      return {
        ...state,
        version: action.payload,
      };
    },
    changeThemeSuccess: (state, action) => {
      return {
        ...state,
        thememode: action.payload,
      };
    },

    setWithdrawModalSuccess: (state, action) => {
      return {
        ...state,
        withdramodal: action.payload,
      };
    },
    setExchangeModalSuccess: (state, action) => {
      return {
        ...state,
        exchangemodal: action.payload,
      };
    },
    setPaymentlinkModalSuccess: (state, action) => {
      return {
        ...state,
        paymentlinkmodal: action.payload,
      };
    },
    setPaymentlinkSuccModalSuccess: (state, action) => {
      return {
        ...state,
        paymentlinksuccmodal: action.payload,
      };
    },
    setDepositModalSuccess: (state, action) => {
      return {
        ...state,
        DepositModal: action.payload,
        // DepositModalError : ""
      };
    },
    // clearDepositModalError:(state, action)=>{
    //   return{
    //     ...state ,

    //   };
    // },
    setFiatDepositModalSuccess: (state, action) => {
      return {
        ...state,
        FiatDepositModal: action.payload,
      };
    },
    setbuyModalSuccess: (state, action) => {
      return {
        ...state,
        buyModal: action.payload,
      };
    },
    setIdobuyModalSuccess: (state, action) => {
      return {
        ...state,
        idobuyModal: action.payload,
      };
    },
    setConfirmcodeModalSuccess: (state, action) => {
      return {
        ...state,
        ConfirmcodeModal: action.payload,
      };
    },
    setAuthenticatorModalSuccess: (state, action) => {
      return {
        ...state,
        AuthenticatorModal: action.payload,
      };
    },

    // Deposit fiat pull request popup
    setFiatPullRequestOpen: (state, action) => {
      return {
        ...state,
        FiatPullRequest: action.payload,
      };
    },

    setFiatPullRequestClose: (state, action) => {
      return {
        ...state,
        createDepositRequestSuccess: action.payload,
      };
    },

    setFiatWithdrawModalSuccess: (state, action) => {
      return {
        ...state,
        FiatWithdrawModal: action.payload,
      };
    },
    changeRoleSuccess: (state, action) => {
      return {
        ...state,
        role: action.payload,
      };
    },

    hideZeroBalanceSuccess: (state, action) => {
      return {
        ...state,
        hideZeroBalance: action.payload,
      };
    },
    businessSuceessSuccess: (state, action) => {
      return {
        ...state,
        businessModal: action.payload,
      };
    },
    createBranchSuccessPoup: (state, action) => {
      return {
        ...state,
        createbranchsuccesspopup: action.payload,
      };
    },
    closecreateBranchSuccessPoup: (state, action) => {
      return {
        ...state,
        createbranchsuccesspopup: false,
      };
    },
    closeBetaSuccess: (state, action) => {
      return {
        ...state,
        betapopup: true,
      };
    },
    setFiatrateSuccess: (state, action) => {
      return {
        ...state,
        fiatrate: action.payload,
      };
    },
    clearappcacheSuccess: (state, action) => {
      return {
        ...state,
        betapopup: false,
      };
    },
    setCreateBankAccountPopupClose: (state, action) => {
      return {
        ...state,
        bankAccountPopupClose: action.payload,
      };
    },

    setbnbdata: (state, action) => {
      return {
        ...state,
        bnbdata: action.payload,
      };
    },
    setbtcdata: (state, action) => {
      return {
        ...state,
        btcdata: action.payload,
      };
    },
    setethdata: (state, action) => {
      return {
        ...state,
        ethdata: action.payload,
      };
    },
    setbnbpdata: (state, action) => {
      return {
        ...state,
        bnbpdata: action.payload,
      };
    },
    setbtcpdata: (state, action) => {
      return {
        ...state,
        btcpdata: action.payload,
      };
    },
    setethpdata: (state, action) => {
       return {
        ...state,
        ethpdata: action.payload,
      };
    },
    setbusddata: (state, action) => {
      return {
        ...state,
        busddata: action.payload,
      };
    },
    setbusdpdata: (state, action) => {
      return {
        ...state,
        busdpdata: action.payload,
      };
    },
    settrxpdata: (state, action) => {
      return {
        ...state,
        trxpdata: action.payload,
      };
    },
    settrxdata: (state, action) => {
      return {
        ...state,
        trxdata: action.payload,
      };
    },
    settrycdata: (state, action) => {
      return {
        ...state,
        trycdata: action.payload,
      };
    },
    settrycpdata: (state, action) => {
      return {
        ...state,
        trycpdata: action.payload,
      };
    },
  },
});
export default appslice.reducer;

export const {
  appSettingsSuccess,
  changeThemeSuccess,
  updateVersionSuccess,
  setWithdrawModalSuccess,
  setExchangeModalSuccess,
  setPaymentlinkSuccModalSuccess,
  setPaymentlinkModalSuccess,
  setFiatDepositModalSuccess,
  setDepositModalSuccess,
  setIdobuyModalSuccess,
  setConfirmcodeModalSuccess,
  setAuthenticatorModalSuccess,
  setFiatPullRequestClose,
  setFiatPullRequestOpen,
  setFiatWithdrawModalSuccess,
  setbuyModalSuccess,
  changeRoleSuccess,
  hideZeroBalanceSuccess,
  businessSuceessSuccess,
  closeBetaSuccess,
  clearappcacheSuccess,
  setFiatrateSuccess,
  setCreateBankAccountPopupClose,
  createBranchSuccessPoup,
  setbnbdata,
  setbnbpdata,
  setbtcdata,
  setbtcpdata,
  setethdata,
  setethpdata,
  setbusddata,
  setbusdpdata,
  settrxdata,
  settrxpdata,
  settrycdata,
  settrycpdata,
  closecreateBranchSuccessPoup,
  
  // clear all error redux states
} = appslice.actions;
