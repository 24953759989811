import apisauce from "apisauce";
import _ from "lodash";
const baseUrl = "https://exchange.api.bity.com/v2/";
const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,

    headers: {
      "Cache-Control": "no-cache",
      "sec-fetch-mode": "cors",
      "content-type": "application/json",
    },
    timeout: 200000,
  });
  const getCurrency = (data) => apis.get(`/currencies`);

  const singleCurrency = (data) => {
    return apis.get(`/currencies/${currency_code}`);
  };

  const getPair = (data) => {
    return apis.get(`/pairs`);
  };
  const getestimate = (data) => {
    return apis.post("/orders/estimate", data);
  };
  return {
    getCurrency,
    singleCurrency,
    getPair,
    getestimate,
  };
};
export default {
  create,
};
