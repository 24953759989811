import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import "@components/withdrawal-fiat/WithdrawalFiatStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import BankListLayout from "src/components/bank-list-layout/BankListLayout";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import FooterCommon from "@components/footer-common/FooterCommon";
import WithdrawFiatToggle from "src/components/withdrawfiat-toggle/WithdrawFiatToggle";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const WithdrawalFiat = ({
  setShowWithdraw,
  show1,
  tableNo,
  setTableno,
  showToggle,
  selectedCurrency,
  coinItem,
}) => {
  const [hideThis, setHideThis] = useState(false);
  const { t, i18n, ready } = useTranslation();

  useEffect(() => {
    console.log(hideThis)
  },[hideThis])

  return (
    <Modal
      className={`Withdrawalfiat ${hideThis && "hidethisexchangemodal"}`}
      show={show1}
      onHide={() => setShowWithdraw(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName={`${hideThis && "mod-exchangebackdrop"}`}
      centered
    >
      <div className="modalPrimaryHeader desk"></div>

      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => setShowWithdraw(false)}
        />
        <div className="rightnav desk" onClick={() => setShowWithdraw(false)}>
          <ExpandIcon />
        </div>
        <Container>
          <Row className="d-lg-flex justify-content-center">
            <Col lg={6}>
              {/* <div className="hidetoggle1 btmspacefottoggle">
                <CurrencyTab
                  page={"currency"}
                  tableno={tableNo}
                  changeTab={(e) => setTableno(e)}
                />
              </div>
              <BankListLayout scope="FiatWithdraw" /> */}
              <div className="boxtitle">
                <span className="boxtitletext">
                  {t("СheckWithBankTransfer")}
                </span>
              </div>
              <div className="currencytoconvert">
                <div className="box">
                  <ul>
                    {/* <li>
              {t("SeeFullBalance")}
              <span className="bold">{t("OpenOrders")}</span>
            </li> */}
                    <li>
                      {t("BankTransferLine1")}:
                      <span className="bold">
                      {" "}{coinItem && coinItem.maxWithdrawLimitaDay && coinItem.maxWithdrawLimitaDay}{" "}
                        {coinItem && coinItem.exchangeSymbol && coinItem.exchangeSymbol}
                      </span>
                    </li>
                    <li>
                      {t("BankTransferLine2")}:
                      <span className="bold">
                      {" "}{coinItem && coinItem.maxLimit && coinItem.maxLimit}{" "}
                        {coinItem && coinItem.exchangeSymbol && coinItem.exchangeSymbol}
                      </span>
                    </li>
                    <li>
                      {t("BankTransferLine3")}
                      <span className="bold">
                      {" "}{coinItem && coinItem.minimumvalue && coinItem.minimumvalue}{" "}
                        {coinItem && coinItem.exchangeSymbol && coinItem.exchangeSymbol} {t("OrMore")}
                      </span>
                    </li>
                    {/* <li>{t("BankTransferLine4")}</li> */}
                    <li>{t("BankTransferLine4_1")}{" "}{t(coinItem && coinItem.currencyName && coinItem['currencyName'].trim())} {t("BankTransferLine4_2")}{" "}{coinItem && coinItem.exchangeSymbol && coinItem.exchangeSymbol}</li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={6} className="colborder">
              <WithdrawFiatToggle
                tableNo={tableNo}
                setTableNo={setTableno}
                showToggle={showToggle}
                setHideThis={setHideThis}
                selectedCurrency={selectedCurrency}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <div className="hide-for-desktop">
        <FooterCommon />
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ fiat }) => {
  const coinItem = _.get(fiat, "dynamicallyChangedFiatCurrencies", undefined);
  return {
    coinItem,
  };
};

export default connect(mapStateToProps)(React.memo(WithdrawalFiat));
