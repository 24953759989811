import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import { ReactComponent as FilterIcon } from "@images/filter.svg";
import "@components/fiatwallet-currencydropdown/FiatwalletcurrencyDropdown.scss";
import {ReactComponent as DropdownIcon} from '@images/dropdown.svg'
import { useTranslation } from "react-i18next";

const FiatwalletcurrencyNewDropdown = (props) => {
  let { currencies, setCurrency, formobile } = props || {};
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      className="filtertext"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <DropdownIcon className="ms-2 drpicon" />
    </span>
  ));

  return (
    <div className="filterfiatcurrency">
      <Dropdown
        align="end"
        autoClose="true"
        onSelect={(eventKey, event) => setCurrency(eventKey)}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {/* <span className="filtertext">{props.pair}</span> */}
          {props.pair}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          
          {currencies &&
            currencies.map((data) => (
              data === "USDT" ? <Dropdown.Item eventKey={"USD"}>{props.coin}/{"USD"}</Dropdown.Item> :
              <Dropdown.Item eventKey={data}>{props.coin}/{data}</Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* dropdown which will be shown on graph section in crypto wallet */}

      {/* <Dropdown align="end" autoClose="inside">
        <Dropdown.Toggle>
       
          
          <span className="filtertext">USD ($ US Dollar)</span>
     
        </Dropdown.Toggle>
      </Dropdown> */}
    </div>
  );
};

export default FiatwalletcurrencyNewDropdown;
