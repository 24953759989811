import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as ExchangeIcon } from "@images/exchange1.svg";
import ExchangeFrom from "src/components/exchange-from/ExchangeFrom";
import ExchangeTo from "src/components/exchange-to/ExchangeTo";
import { connect } from "react-redux";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "@components/exchange-popup/ExchangePopupStyles.scss";
import Loader from "@components/loader/Loader";
import ExchangeConfirmationPopup from "../exchange-confirmationpopup/ExchangeConfirmationPopup";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { Spinner, Tab } from "react-bootstrap";
import {
  clearExchangeTokenError,
  getDectExchangePriceFail,
} from "@store/platformuserredux";
import CurrencyTab from "src/components/currency-tab/CurrencyTab.js";

function ExchangePopup({
  option,
  coinItem,
  getExchangerate,
  exchangeRates,
  exchangeTokenloading,
  exchangeTokenOrCoin,
  setExchangeModal,
  setHideThis,
  loadingRate,
  getcomissionper,
  settings,
  exchangeTokenerror,
  commission,
  getFiatBalanceinfo,
  myaccountandbalance,
  clearError,
  bity,
  pairs,
  callbity,
  getEstimate,
  estimate,
  getDectExchangerate,
  decentraliseEPrice,
  decentraliseEPriceFail,
  clearError1,
  postDectExchangerate,
}) {
  // let [sel1, setsel1] = useState(
  //   coinItem && coinItem.id ? option.findIndex((e) => e.id == coinItem.id) : 0
  // );
  let [tableno, settableno] = useState("first");

  let [sel1, setsel1] = useState(
    bity
      ? 0
      : coinItem && coinItem.id
      ? Math.abs(option.findIndex((e) => e.id == coinItem.id)) <
        option.length - 1
        ? option.findIndex((e) => e.id == coinItem.id)
        : 0
      : 1
  );

  let [sel2, setsel2] = useState(
    bity
      ? 1
      : coinItem && coinItem.id
      ? Math.abs(option.findIndex((e) => e.id == coinItem.id)) <
        option.length - 1
        ? option.findIndex((e) => e.id == coinItem.id) + 1
        : 1
      : 1
  );
  let [disable, setdisable] = useState(false);

  let [amt1, setamt1] = useState("");
  let [amt2, setamt2] = useState("");
  useEffect(() => {
    if (bity) {
      setamt1(estimate?.input?.amount);
      setamt2(estimate?.output?.amount);
    }
  }, [estimate]);

  let [commissionperc, setcommissionperc] = useState("");
  let [t1, sett1] = useState("");
  let [t2, sett2] = useState("");
  let [showmodal, setshowmodal] = useState(false);
  let [ConfirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    getcomissionper();
    clearError();
    clearError1();
  }, []);
  useEffect(() => {
    let rate =
      commission && commission.filter((item) => item.type == "exchange");
    setcommissionperc((rate && rate[0] && rate[0].commissionPercentage) || 0.1);
  }, [commission]);

  useEffect(() => {
    if (!bity && tableno == "first")
      getExchangerate({
        symbol1: option && option[sel1] && option[sel1].exchangeSymbol,
        symbol2: option && option[sel2] && option[sel2].exchangeSymbol,
      });
    if (tableno == "second") {
      if (amt1 != "") {
        getDectExchangerate({
          sAmount: amt1,
          sTID: option && option[sel1] && option[sel1].id,
          sToken: option && option[sel1] && option[sel1].exchangeSymbol,
          bTID: option && option[sel2] && option[sel2].id,
          bToken: option && option[sel2] && option[sel2].exchangeSymbol,
        });
      }
    }
    // console.log(option && option[sel1] && option[sel1].exchangeSymbol);
    // sett1(option && option[sel1] && option[sel1].tokenType);
    // sett2(option && option[sel2] && option[sel2].tokenType);
  }, []);
  useEffect(() => {
    if (!bity) {
      if (tableno == "first") {
        getExchangerate({
          symbol1: option && option[sel1] && option[sel1].exchangeSymbol,
          symbol2: option && option[sel2] && option[sel2].exchangeSymbol,
        });
      } else {
        if (amt1 != "") {
          getDectExchangerate({
            sAmount: amt1,
            sTID: option && option[sel1] && option[sel1].id,
            sToken: option && option[sel1] && option[sel1].exchangeSymbol,
            bTID: option && option[sel2] && option[sel2].id,
            bToken: option && option[sel2] && option[sel2].exchangeSymbol,
          });
        }
      }
      sett1(option && option[sel1] && option[sel1].tokenType);
      sett2(option && option[sel2] && option[sel2].tokenType);
      clearError();
    } else {
      let code1 = option?.[sel1]?.code;
      let code2 = option?.[sel2]?.code;

      let selpair = pairs?.filter((i) => {
        if (i.input == code1 && i.output == code2) {
          return i;
        }
      });
      if (selpair?.length == 0) {
        setdisable(true);
      }
    }
  }, [sel1, sel2]);

  const getRates = (symb1) => {
    let { result, sym1, sym2 } = exchangeRates || {};
    if (sym1 == symb1) {
      return result;
    } else {
      return 1 / result;
    }
  };
  // useEffect(() => {
  //   console.log("option array prop", option);
  //   console.log("coin selected", coinItem);
  // }, []);

  const getBal = (cur) => {
    let obj2 =
      getFiatBalanceinfo &&
      getFiatBalanceinfo.filter((item) => item.currencyId == cur);
    let reelbal = _.get(obj2, "[0].balance", 0);
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal2 = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return t1 == "fiat"
      ? reelbal
      : BigNumber(reelbal2).dividedBy(10 ** decimals);
  };

  let balances = BigNumber(
    getBal(option && option[sel1] && option[sel1].id)
  ).toFixed(6, BigNumber.ROUND_FLOOR);

  const { t } = useTranslation();
  // useEffect(() => {
  //   console.log("coin", coinItem);
  //   console.log("option", option);
  // }, []);

  return (
    <div className="exchangepopup">
      {/* <ConfirmSuccess
        show1={com}
        onClick={() => {
          setConfirmModal(false);
        }}
      /> */}
      <Container className="blockarea">
        <CurrencyTab
          page={"exchge"}
          className="currencyarea"
          changeTab={(e) => {
            if (e == "second") {
              settableno(e);
            }

            if (e == "first") {
              settableno(e);
            }
          }}
          tableno={tableno}
        ></CurrencyTab>
        <ExchangeConfirmationPopup
          show1={showmodal}
          tableno={tableno}
          setShowModal={setshowmodal}
          setHideThis={setHideThis}
          decentraliseEPrice={decentraliseEPrice}
          setExchangeModal={setExchangeModal}
          amt1={`${amt1} ${
            bity
              ? option?.[sel1]?.code
              : option && option[sel1] && option[sel1].exchangeSymbol
          }`}
          amt2={`${amt2} ${
            bity
              ? option?.[sel2]?.code
              : option && option[sel2] && option[sel2].exchangeSymbol
          }`}
          Commission={`${BigNumber(commissionperc)
            .multipliedBy(100)
            .toFixed(0)}% =   ${BigNumber(amt2)
            .multipliedBy(commissionperc)
            .toFixed(6, BigNumber.ROUND_FLOOR)} ${
            option && option[sel2] && option[sel2].exchangeSymbol
          }`}
          Total={`${BigNumber(amt2)
            .minus(BigNumber(amt2).multipliedBy(commissionperc).toFixed(6))
            .toFixed(6)} ${
            option && option[sel2] && option[sel2].exchangeSymbol
          }`}
          onPress={() => {
            if (tableno == "second") {
              postDectExchangerate({
                sAmount: amt1,
                sTID: option && option[sel1] && option[sel1].id,
                sToken: option && option[sel1] && option[sel1].exchangeSymbol,
                bTID: option && option[sel2] && option[sel2].id,
                bToken: option && option[sel2] && option[sel2].exchangeSymbol,
              });
            } else {
              exchangeTokenOrCoin(
                {
                  t1,
                  t2,
                  type: "exchange",
                  amount: amt1,
                  amount2: amt2,
                  tokenName1: Number(option && option[sel1] && option[sel1].id),
                  tokenName2: Number(option && option[sel2] && option[sel2].id),
                  exchangeRate: getRates(
                    option && option[sel1] && option[sel1].exchangeSymbol
                  ),
                },
                () => {
                  setConfirmModal(true);
                }
              );
            }

            setshowmodal(false);
            setHideThis(false);
          }}
        />
        <div className="from">
          <ExchangeFrom
            option={option}
            bity={bity}
            coinItem={coinItem}
            loadingRate={loadingRate && tableno == "first"}
            Selected={sel1}
            onBlur={() => {
              if (bity) {
                getEstimate({
                  input: {
                    currency: option?.[sel1]?.code,
                    amount: amt1,
                  },
                  output: {
                    currency: option?.[sel2]?.code,
                  },
                });
              }
            }}
            setSelectedIndex={(q) => {
              setamt1("");
              setamt2("");
              setsel1(q);
            }}
            val={amt1}
            onChange={(e) => {
              setamt1(e.target.value);

              if (tableno == "second") {
                getDectExchangerate({
                  sAmount: e.target.value,
                  sTID: option && option[sel1] && option[sel1].id,
                  sToken: option && option[sel1] && option[sel1].exchangeSymbol,
                  bTID: option && option[sel2] && option[sel2].id,
                  bToken: option && option[sel2] && option[sel2].exchangeSymbol,
                });
              }
              if (!bity) {
                let exc = BigNumber(e.target.value).multipliedBy(
                  getRates(
                    option && option[sel1] && option[sel1].exchangeSymbol
                  )
                );

                setamt2(exc);
              }
            }}
          />
          {!bity && tableno != "second" && (
            <div className="btext1">
              {loadingRate ? (
                <Spinner animation="grow" className="spincolor" />
              ) : (
                <span className="txt">
                  {t("ExchangeRate")} 1{" "}
                  {option && option[sel1] && option[sel1].exchangeSymbol} ={" "}
                  {BigNumber(
                    getRates(
                      option && option[sel1] && option[sel1].exchangeSymbol
                    )
                  ).toFixed(6, BigNumber.ROUND_FLOOR)}{" "}
                  {option && option[sel2] && option[sel2].exchangeSymbol}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="exchange formblock">
          <div
            className="exgbtn"
            onClick={() => {
              setsel1(sel2);
              setsel2(sel1);
              setamt2("");
              setamt1("");
            }}
          >
            <ExchangeIcon className="exgimg" />
          </div>
        </div>
        <div className="to formblock ">
          <ExchangeTo
            bity={bity}
            option={option}
            coinItem={coinItem}
            Selected={sel2}
            setSelectedIndex={(q) => {
              setamt1("");
              setamt2("");
              setsel2(q);
            }}
            onBlur={() => {
              if (bity) {
                getEstimate({
                  input: {
                    currency: option?.[sel2]?.code,
                    amount: amt2,
                  },
                  output: {
                    currency: option?.[sel1]?.code,
                  },
                });
              }
            }}
            val={tableno == "second" ? decentraliseEPrice : amt2}
            loadingRate={tableno == "second"}
            onChange={(e) => {
              setamt2(e.target.value);
              let exc = BigNumber(e.target.value).multipliedBy(
                getRates(option && option[sel2] && option[sel2].exchangeSymbol)
              );

              setamt1(exc);
            }}
          />
          {!bity && tableno != "second" && (
            <div className="btext1">
              <span>
                {t("Commission")}{" "}
                {BigNumber(commissionperc).multipliedBy(100).toFixed(0)}%{" "}
              </span>
            </div>
          )}
        </div>
        <div className="insufficientbal mx-auto w-100">
          <span>{t(`${exchangeTokenerror}`)}</span>
        </div>
        {exchangeTokenloading && <Loader msg={t("TransactionWaitMessage")} />}
        {/*  */}
        <div className="outercontainerpopup">
          {
            <div>
              {tableno == "second" && (
                <div className="insufficientbal">
                  <span>
                    {decentraliseEPriceFail ==
                    "Request failed with status code 400"
                      ? ""
                      : decentraliseEPriceFail}
                  </span>
                </div>
              )}
              {!bity &&
                sel1 != sel2 &&
                amt1 != "" &&
                !BigNumber(balances).isGreaterThanOrEqualTo(amt1) && (
                  <div className="insufficientbal">
                    <span>{t("InsufficientBalance")}</span>
                  </div>
                )}

              <CustomButton
                type="submit"
                name={t("Exchange")}
                filled={true}
                primary={true}
                disable={
                  bity
                    ? disable || sel1 == sel2
                    : sel1 == sel2 ||
                      amt1 == "" ||
                      !BigNumber(balances).isGreaterThanOrEqualTo(amt1)
                }
                onClick={() => {
                  if (amt1 != "") {
                    if (bity) callbity(option?.[sel1]?.code, amt1);
                    else {
                      setHideThis(true);
                      setshowmodal(true);
                    }
                  }
                }}
              />
            </div>
          }
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = ({
  wallet = {},
  app = {},
  paymentlink = {},
  fiat = {},
}) => {
  const loadingRate = _.get(wallet, "loadingRate", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const exchangeTokenloading = _.get(wallet, "exchangeTokenloading", false);

  const settings = _.get(app, "settings", false);
  const exchangeTokenerror = _.get(wallet, "exchangeTokenerror.message", "");
  const decentraliseEPrice = _.get(wallet, "decentraliseEPrice", 0);

  const decentraliseEPriceFail = _.get(wallet, "decentraliseEPriceFail", "");

  const commission = _.get(paymentlink, "commission", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);

  return {
    loadingRate,
    exchangeRates,
    exchangeTokenloading,
    settings,
    exchangeTokenerror,
    commission,
    myaccountandbalance,
    decentraliseEPrice,
    getFiatBalanceinfo,
    decentraliseEPriceFail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),
  exchangeTokenOrCoin: (data) =>
    dispatch({ type: "exchangeTokenOrCoincalled", payload: data }),
  getcomissionper: (data) =>
    dispatch({ type: "getcommissionCalled", payload: data }),
  clearError: () => dispatch(clearExchangeTokenError()),
  getDectExchangerate: (data) =>
    dispatch({ type: "getDectExchangerate", payload: data }),
  postDectExchangerate: (data) =>
    dispatch({ type: "postDectExchangerate", payload: data }),
  clearError1: (data) => dispatch(getDectExchangePriceFail(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangePopup);
