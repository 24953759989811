export default {
  TotalValueLocked: 'Toplam değer kilitlendi',
  Current: 'Cari',
  Bonus: 'Bonus',
  Today: 'Bugün',
  MarkerRate: 'Piyasa Değeri',
  Buy: 'Al',
  BuyCyborCoin: 'PayCrypt Coin Satın Al',
  BuyWithCurrent: 'Mevcut bakiyenizi kullanarak satın alın',
  CyborCoin: 'PayCrypt Coin',
  With: 'İle',
  ExchangeRates: 'Döviz kurları',
  Cybor: 'Cybor',
  InsufficientBalance: 'Yetersiz bakiye',
  Lessthenminimumamount: "Minimum değerden az",
  Minimum: "Minimum",
  Fees: "Ücretler",
  Value: "Değer",
  My: '',
  Balances: 'Bakiyeler',
  TransactionWaitMessage: 'İşlem 1-3 Dakika sürebilir. Lütfen bekleyin!',
  FiatBalances: "Fiat Bakiyeler",
  CurrencyPair: "Döviz Çifti",
  Withdrawisdeactivated: "Bu jeton için para çekme işlemi devre dışı bırakıldı",
};
