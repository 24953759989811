import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import user from "./userredux";
import saga from "./sagaindex";
import app from "./appredux";
import wallet from "./platformuserredux";
import ido from "./idoredux";
import paymentlink from "./paymentlinkredux";
import fiat from "./fiatredux";
import translationLanguage from "./translatelanguageredux";
import branchmanagementredux from "./branchmanagementredux";
import admin from "./adminredux";
import notification from "./notificationredux";
import bity from "./bityredux";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "user",
    "app",
    "wallet",
    "ido",
    "paymentlink",
    "fiat",
    "bity",
    "translationLanguage",
    "branchmanagementredux",
    "admin",
    "notification",
  ],
};
const reducer = combineReducers({
  user,
  app,
  wallet,
  ido,
  paymentlink,
  fiat,
  translationLanguage,
  branchmanagementredux,
  admin,
  notification,
  bity,
});
const persistedReducer = persistReducer(persistConfig, reducer);
let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(saga);

const persistor = persistStore(store);

export { persistor, store };
