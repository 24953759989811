export default {
  TransferHistory: "Transfer History",
  TransactionDetails: "Transfer Details",
  TransactionId: "Transaction ID",
  Date: "Date",
  From: "From",
  To: "To",
  Coin: "Coin",
  Amount: "Amount",
  Status: "Status",
  Showing: "Showing",
  from: "from",
  Deposit: "Deposit",
  Withdrawals: "Withdrawals",
  ExchangeID: "Exchange ID",
  AmountReceived: "Amount Received",
  completed: "completed",
  ExchangeHistory: "Exchange History",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  PENDING: "PENDING",
  ReplenishmentBankTransfer: "Replenishment by bank transfer",
  ReceiverName: "Receiver name",
  RecipientOfMoneyTransfers:
    "is the recipient of money transfers via EFT. should be entered as",
  Remaining24hourDepositLimit: "Your remaining 24-hour deposit limit",
  Remaining30dayDepositLimit: "Remaining 30-day deposit limit",
  TransferLimitMessage:
    "You can transfer TL to BtcTurk bank accounts within the daily deposit limit with no lower limit.",
  TransferPossible: "Transfer possible",
  Days: "days",
  Hours: "hours",
  Next: "Next",
  OnWeekdays: "on weekdays",
  ReceiveSMS: "Receive banking information by SMS",
  AddBankConfirmed: "Add Bank Confirmed!",
  SelectBankError:"Please select a bank",
  BankAccountFromWhichDeposit: "Bank account from which you will deposit",
  SelectBankAccount: "Select a bank account",
  DefineNewBankAccount: "Define a new bank account",
  AmountToDeposit: "The amount you want to deposit",
  TransactionFee: "Transaction Fee",
  Included: "included",
  DepositAmount: "Deposit amount",
  Cryptocurrency:"Crypto Currency",
  СheckWithBankTransfer: "Сheck with bank transfer",
  SeeFullBalance: "If you do not see your full balance, check your",
  OpenOrders: "open orders.",
  Remaining24hourWithdrawalLimit: " Your remaining 24-hour withdrawal limit",
  Remaining30dayWithdrawalLimit: "Your remaining 30-day withdrawal limit",
  BankTransferValidForWithdrawals:
    "Bank transfer is only valid for withdrawals of",
  OrMore: "or more",
  IBANInfromation:
    "The IBAN information of your bank account where you deposited Turkish Lira in BtcTurk will be automatically add",
  SubmitPullRequest: "Submit a pull request",
  BankAccount: "Bank Account",
  MyBankAccount: "My Bank Account",
  AddNew: "Add New...",
  BankAccountsRegistered:
    "The bank accounts registered to your account are as follows.",
  DontHaveLinkedBankAccounts: "You don't have linked bank accounts yet.",
  AddNewBankAccount: "Add New Bank Account",
  SelectBank: "Select Bank",
  AccountNumber: "Account Number",
  EnterAccountNumber: "Please enter account number",
  AccountHolderName: "Account Holder's Name",
  Ibannumber:"IBAN Number",
  Swiftnumber:"SWIFT Number",
  Ifscnumber:"IFSC Number",
  EnterAccountHolderName: "Please enter account holder's name",
  EnterIBAN:"Please enter IBAN number",
  EnterSWIFT:"Please enter SWIFT number",
  EnterIFSC : "Please enter IFSC number",
  Number: "Number",
  Submit: "Submit",
  BankTransferLine1: "Your remaining 24-hour withdrawal limit",
  BankTransferLine2: "Your remaining 30-day withdrawal limit",
  BankTransferLine3: "Bank transfer is only valid for withdrawals of",
  BankTransferLine4: "The IBAN information of your bank account where you deposited Turkish Lira in BtcTurk will be automatically added to the list of banks from which you can withdraw TL." ,
  BankTransferLine4_1:"The IBAN information of your bank account where you deposited",
  BankTransferLine4_2: "in BtcTurk will be automatically added to the list of banks from which you can withdraw",
  SelectNewBankAccount: "Select new bank account",
  Photo: "Photo",
  Fiat:'Fiat',
  ExchangedCoins: "Exchanged Coins",
  ExchangedAmount: "Exchanged Amount",
  Link: "Link",
  AmountPaid:"Amount Paid",
  AccountNumber: " Enter Account Number  ",
  AccountHolderNumber:"Enter Account Holder Number",
  IBANNumber:"Enter IBAN Number",
  SWIFTNumber:"Enter SWIFT Number",
  IFSCNumber:"Enter IFSC Number",
  AccountCreatedSuccessfully:"Account Created Successfully",
  MonthlyDepositlimit: "Your remaining 30-day deposit limit",
  BankTransferValidForDeposit: "Bank transfer is only valid for deposit of ",
  FiatWithdrawalRequest: "Fiat Withdrawal Request",
  Fiatwithdrawapplicationsent: "Fiat withdraw application sent.",
  SelectCurrencyError:"Please select a currency",
  SelectBankError:"Please select a bank",
  SelectCurrencyError:"Please select a currency",

  SelectedBank: "Selected Bank",
  Surname: "Surname",
  TType:"Type Of Transaction",
  Sender:"Sender",
  Receiver:"Receiver", 
};
