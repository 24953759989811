import ScreenLayout from "src/components/layout/ScreenLayout";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import FooterCommon from "@components/footer-common/FooterCommon";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Modal, Row, Form, Col } from "react-bootstrap";
import InputField from "@components/input-field/InputField";
import "@features/add-new-bank-acount/AddNewBankAccountStyles.scss";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import Accountsuccessful from "@components/account-successful/Accountsuccessful";
import Loader from "@components/loader/Loader";
import { clearBankError } from "@store/fiatredux";

const AddNewBankAccount = ({
  showAddBankConfirmation,
  setShowAddBankConfirmation,
  onPress,
  onCancelled,
  fiatBanks,
  getAllBanksCall,
  createBankAccountCall,
  createBankError,
  exchangeId,
  fiatBalance,
  userAccessToken,
  setHideThis,
  createBankAccountLoading,
  createBankAccSuccess,
  clearError,
}) => {
  const { t, i18n, ready } = useTranslation();
  const history = useHistory();
  const [selectedBank, setSelectedBank] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [accountHolderName, setAccountHolderName] = useState();
  const [valid, setValid] = useState(true);
  const [iban, setIban] = useState();
  const [swift, setSwift] = useState("");
  const [ifsc, setIfsc] = useState();
  const [bankOptions, setBankOptions] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [goBack, setGoBack] = useState(true);
  let [Selected, setSelectedIndex] = useState("");

  useEffect(() => {
    getAllBanksCall();
  }, []);

  useEffect(() => {
    if (createBankError.length !== 0) {
      setErrorMessage(createBankError);
      setGoBack(false);
    } else {
      setGoBack(true);
    }
  }, [createBankError]);

  useEffect(() => {
    let allBanks = fiatBanks.map((bank) => ({
      id: bank.id,
      tokenName: bank.bankName,
    }));
    setBankOptions(allBanks);
  }, [fiatBanks]);

  const FormOnSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValid(false);
    } else {
      event.preventDefault();
      event.stopPropagation();
      setValid(true);
      createBankAccount(form);
      fiatBalance();
    }
  };

  const createBankAccount = (form) => {
    if (valid === true || form.checkValidity() === true) {
      const bankData = {
        bankAccountNumber: accountNumber,
        BankId: parseInt(bankOptions[Selected].id),
        BankAccountType: "SAVINGS",
        BranchUniqueId: "999999",
        IFSC: ifsc,
        IBANs: iban,
        SWIFT: swift,
        exchangeId: exchangeId,
        accountHolderName: accountHolderName,
      };
      createBankAccountCall(bankData);
      closeDetection();
      //onCancelled&&onCancelled()
    } else {
    }
  };

  const closeDetection = () => {
    if (goBack === true) {
      closeModal();
    }
  };
  const closeModal = () => {
    setErrorMessage("");
    setSelectedBank();
    setAccountNumber("");
    setAccountHolderName("");
    setIban("");
    setSwift("");
    setIfsc("");
    setValid(true);
    setSelectedIndex("");
    // setBankOptions('')
    // setBankOptions();
    // setHideThis(false);
    // setShowAddBankConfirmation(false);
    clearError();
    //onCancelled();
  };

  return (
    <ScreenLayout>
      <>
        {createBankAccountLoading && <Loader />}

        <Accountsuccessful show={createBankAccSuccess} />
        <Container>
          <div id="addnewbank">
            <Row>
              <Col lg={12}>
                <Form noValidate onSubmit={FormOnSubmit}>
                  <div className="outercontainer">
                    <div
                      className="d-flex align-items-center mb-5"
                      onClick={() => history.goBack()}
                    >
                      <Arrow className="arrowicon" />
                      <p className="addNewText">{t("AddNewBankAccount")}</p>
                    </div>

                    <div className="titles">
                      <span>{t("SelectBank")}</span>
                      <div className="inputbox1">
                        <DropdownSearch
                          options={bankOptions}
                          setSelectedIndex={(e) => {
                            setSelectedIndex(e);
                          }}
                          Selected={Selected}
                        />
                      </div>
                    </div>

                    <div className="titles">
                      <span>{t("AccountNumber")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          value={accountNumber}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterAccountNumber"}`)}
                          placeholder={t("AccountNumber")}
                        />
                      </div>
                    </div>

                    <div className="titles">
                      <span>{t("AccountHolderName")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          value={accountHolderName}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterAccountHolderName"}`)}
                          placeholder={t("AccountHolderName")}
                        />
                      </div>
                    </div>

                    <div className="titles">
                      <span>IBAN {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setIban(e.target.value)}
                          value={iban}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterIBAN"}`)}
                          placeholder={t("Ibannumber")}
                        />
                      </div>
                    </div>

                    <div className="titles">
                      <span>SWIFT {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setSwift(e.target.value)}
                          value={swift}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterSWIFT"}`)}
                          placeholder={t("Swiftnumber")}
                        />
                      </div>
                    </div>

                    <div className="titles">
                      <span>IFSC {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setIfsc(e.target.value)}
                          value={ifsc}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterIFSC"}`)}
                          placeholder={t("Ifscnumber")}
                        />
                      </div>
                    </div>

                    <div className="btmbtnoutercontainer">
                      <div className="topbuttons">
                        <CustomButton
                          type="submit"
                          name={t("Submit")}
                          filled={true}
                          primary={true}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
        {t(`${errorMessage}`)}
        <FooterCommon />
      </>
    </ScreenLayout>
  );
};

const mapStateToProps = ({ fiat = {}, app = {}, user = {} }) => {
  const fiatBanks = _.get(fiat, "fiatBank", []);
  const createBankError = _.get(fiat, "createBankAccountError", "");
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const createBankAccountLoading = _.get(fiat, "createBankAccountLoading", "");
  const createBankAccSuccess = _.get(fiat, "createBankAccSuccess", false);
  return {
    fiatBanks,
    createBankError,
    exchangeId,
    userAccessToken,
    createBankAccountLoading,
    createBankAccSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllBanksCall: () => dispatch({ type: "getBankTrigger" }),
  createBankAccountCall: (data) =>
    dispatch({ type: "createBankAccountStart", payload: data }),
  fiatBalance: (data) =>
    dispatch({ type: "callFiatBalanceStartCalled", payload: data }),
  clearError: () => dispatch(clearBankError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewBankAccount);
