export default {
  BankRequest: "Bank Requests",
  ID: "ID",
  UserName: "Account Holder's Name",
  BankName: "Bank Name",
  BankAccountNumber: "Bank account number",
  Approve: "Approve",
  Reject: "Reject",
  Approved: "Approved",
  Rejected: "Rejected",
  BusinessRequest: "Business Requests",
  BusinessID: "Business ID",
  CompanyName: "Company Name",
  CompanyAddress: "Company Address",
  Region: "Region",
  FiatDepositRequest: "Fiat Deposit Requests",
  AdminBanks: "Admin Banks",
  CreatedOn: "Created On",
  FiatWithdrawalRequest: "Fiat Withdrawal Requests",
  BankRequestDetails: "Bank Request Details",
  BankAccountType: "Bank account type",
  UserEmail: "User email",
  UserContactNumber: "User contact Number",
  BusinessRequestDetails: "Business Request Details",
  CompanyEmail: "Company Email",
  ContactNumber: "Contact Number",
  Language: "Language",
  Document_Link: "Document Link",
  Click_to_download: "Click to download",
  BusinessApproval: "Status",
  FiatDepositDetails: "Fiat Deposit Details",
  TransactionType: "Transaction Type",
  CurrencyName: "Currency Name",
  BankIFSCNumber: "Bank IFSC Number",
  BankIBANNumber: "Bank IBAN Number",
  ListOfBanks: "List of banks",
  FiatWithdrawDetails: "Fiat Withdraw Details",
  withdraw: "withdraw",
  deposit: "deposit",
  SAVINGS: "SAVINGS",
  active: "Active",
  UsersList: "Users List",
  UserID: "User ID",
  disabled: "disabled",
  Active: "Active",
  Activate: "Activate",
  Disabled: "Disabled",
  Disable: "Disable",
  Doyouwanttoactivatethisuser: "Do you want to activate this user ?",
  Doyouwanttodisablethisuser: "Do you want to disable this user ?",
  UserDetails: "User Details",
  SubName: "SubName",
  Role: "Role",
  DoyouwanttoReject: "Do you want to Reject?",
  Activated: "Activated",
  Successfull: "Successfull",

  Bank: "Bank",
  Currency: "Currency",
  IBAN: "IBAN",
  AccountHolder: "Account Holder",
  Edit: "Edit",
  AddBankAccount: "Add Bank Account",
  FilterCurrency: "Filter Currency",
  Logoforlighttheme: "Logo for light theme",
  Logofordarktheme: "Logo for dark theme",
  Pleaseenterbanksname: "Please enter bank's name",
  ErrorDarkLogo: "Please choose a logo for dark theme",
  ErrorLightLogo: "Please choose a logo for light theme",
  EditBankAccount: "Edit Bank Account",
  Doyouwanttodeletethisbank: "Do you want to delete this bank ?",
  Doyouwanttodeletethisbankaccount: "Do you want to delete this bank account ?",
  Owner: "Owner",
  User: "User",
};
