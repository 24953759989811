import * as _ from "lodash";
import React, { useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomButton from "@components/custom-button/CustomButton";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import CyborBusinessDarkLogo from "@images/onboardingImages/cyborbusinessdarkmode.svg";
import CyborBusinessLightLogo from "@images/onboardingImages/cyborbusinesslightmode.svg";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";

import "@components/request-sent/RequestSent.scss";
const RequestSent = ({ exchange, thememode,show, show1, getMyprofile , setShowModal, logouts}) => {
  const history = useHistory()
  useEffect(() => {
    // getMyprofile();
  }, []);
  const { t } = useTranslation();
  return (
    <Modal
      className="RequestSent"
      show={show}
      // onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        
        <Container>
          <div className="imagesection">
            {/*<img
              alt=""
              className="imgonsignup"
              src={
                thememode == "dark"
                      ? `${CyborBusinessDarkLogo}`
                      : `${CyborBusinessLightLogo}`
              } 
            /> */}
            <img src={paymentsolutionlogo} className="imgonsignup" />
          </div>
          <div className="textsection">
            <div className="lineone">
              <span className="text">{t("Requestsent")}</span>
            </div>
            <div className="linetwo">
              <span className="text1">
                {t("Thankyouprovidinginfo")}
              </span>
            </div>
            <div className="logout-btn-wrap" >
            <CustomButton
              id="signin"
              type="submit"
              name={t("LogOut")}
              filled={true}
              primary={true}
              onClick={()=>logouts({ history })}
            />
            </div>
          </div>
{/* 
           <div className="outerconforbtn">
            <CustomButton
               type="submit"
              name="OK"
              filled={true}
              primary={true}
              onClick={() => setShowModal(false)} 
            />
          </div>  */}
        </Container>
      </Modal.Body>
    </Modal>
  );
};


  const mapDispatchToProps = (dispatch) => ({
    logouts: (data) => dispatch({ type: "logoutCalled", payload: data }),
  });


export default connect(null , mapDispatchToProps)(React.memo(RequestSent));
