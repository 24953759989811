import ScreenLayout from 'src/components/layout/ScreenLayout';
import React, { useEffect, useState, useLayoutEffect } from "react";
import "@features/crypto-wallet/CryptoWalletStyle.scss"
import Container from "react-bootstrap/Container";
import { Row, Col } from 'react-bootstrap'
import { connect } from "react-redux";
import FooterCommon from "@components/footer-common/FooterCommon";
import SearchBox from '@components/search-box/SearchBox';
import CoinTable from "@components/coin-table/CoinTable.js";
import { useTranslation } from "react-i18next";
import moment from "moment";
const Binance = require("binance-api-node").default;
import WalletBigCoinCard from '@components/wallet-big-coin-card/WalletBigCoinCard';
import WalletQr from '@components/wallet-qr/WalletQr';
import ModernBgcard from '@components/modern-bg-card/ModernBgCard';
import BigNumber from "bignumber.js";
import CurrencyPairWallet from "@components/currencypair-wallet/CurrencyPairWallet";
import IdoTable from "@components/ido-table/IdoTable";
import CyborTokenomics from '@components/cybor-tokenomics/CyborTokenomics';
import AboutCyborCard from '@components/about-cybor-coin-card/AboutCyborCard';
import BuyCyborCard from '@components/buy-cyborcoincard/BuyCyborCard';
import VideoCard from '@components/video-card/VideoCard'
import { ReactComponent as Download} from '@images/downloadwhitepaper.svg';
import CustomButton from '@components/custom-button/CustomButton';
import { getESymbol } from "src/utils/Currencies";
import currencies from "src/utils/Currencies";
import CoinTableWrapper from '@components/coin-table-wrapper/CoinTableWrapper';

function CryptoWallet(props) {

    let { myaccountandbalance } = props;

    const client = Binance();
  
    let {
      getplatformTokensinfo,
      displayedCurrencies,
      withdramodal,
      setwithdrawModal,
      exchangemodal,
      setExchangeModal,
      plaformcurrencies,
      depositModal,
      setDepositModal,
      role,
      mywalletsinfo,
      PreferredCurrency,
      platformBanks,
      fiatDepositModal,
      setFiatDepositModal,
      hideZeroBalance,
      sethideZero,
      fiatrate,
      hideLoaderOnRefresh,
      myEstimatedBal
    } = props;
    const [bnbdata, setbnbdata] = useState("");
    const [btcdata, setbtcdata] = useState("");
    const [ethdata, setethdata] = useState("");
    const [bnbpdata, setbnbpdata] = useState("");
    const [btcpdata, setbtcpdata] = useState("");
    const [ethpdata, setethpdata] = useState("");
    const [busddata, setbusddata] = useState("");
    const [busdpdata, setbusdpdata] = useState("");
    const [marketdata, setmarketdata] = useState("");
    const [chartdata, setchartdata] = useState([]);
    const [trxdata, settrxdata] = useState("");
    const [trxpdata, settrxpdata] = useState("");
    const [trycdata, settrycdata] = useState("");
    const [trycpdata, settrycpdata] = useState("");
    // const [min, setmin] = useState(0);
    // const [max, setmax] = useState(1000000);
    // const [Currency, setCurrency] = useState("USD");
    const [Currency, setCurrency] = useState(
      PreferredCurrency,
    );
    let [tableno, settableno] = useState("first");
    let currenciesData = currencies.map((data)=> data.key);
    const [startDate, setStartDate] = useState(moment().subtract(15, "days"));
    const [endDate, setEndDate] = useState(new Date());
    const [searchvalue, setSearchValue] = useState("");
    const [market, setmarket] = useState([]);
    const [chartsdata, setcharts] = useState([]);
    const [min, setmin] = useState([]);
    const [max, setmax] = useState([]);
    const [ coinItem, setcoinItem ] =  useState(myaccountandbalance && myaccountandbalance[0] && myaccountandbalance[0].coin )
    const [ circularLoader , setCircularLoader] = useState(true)
    useEffect(() => {
      if(Currency !== PreferredCurrency){
        getMarketdata(selectedCoin, PreferredCurrency);
      }
      setCurrency(PreferredCurrency)
    }, [PreferredCurrency])
    

    useEffect(() => {
      const ws = new WebSocket("wss://stream.binance.com:9443/ws");
      let prefSymbol = getESymbol(PreferredCurrency).toLocaleLowerCase()
       const msg = {
        method: "SUBSCRIBE",
        params: [
          `eth${prefSymbol}@aggTrade`,
          `btc${prefSymbol}@aggTrade`,
          `bnb${prefSymbol}@aggTrade`,
          `busd${prefSymbol}@aggTrade`,
          `eth${prefSymbol}@ticker`,
          `btc${prefSymbol}@ticker`,
          `bnb${prefSymbol}@ticker`,
          `busd${prefSymbol}@ticker`,
          `trx${prefSymbol}@ticker`,
          `trx${prefSymbol}@aggTrade`,
          `${prefSymbol}try@ticker`,
          `${prefSymbol}try@aggTrade`,
        ],
        id: 1,
      };
  
      const msg2 = {
        method: `UNSUBSCRIBE`,
        params: [
          `eth${prefSymbol}@aggTrade`,
          `btc${prefSymbol}@aggTrade`,
          `bnb${prefSymbol}@aggTrade`,
          `busd${prefSymbol}@aggTrade`,
          `trx${prefSymbol}@aggTrade`,
          `eth${prefSymbol}@ticker`,
          `btc${prefSymbol}@ticker`,
          `bnb${prefSymbol}@ticker`,
          `busd${prefSymbol}@ticker`,
          `trx${prefSymbol}@ticker`,
          `${prefSymbol}try@ticker`,
          `${prefSymbol}try@aggTrade`,
        ],
        id: 1,
      };
      
      ws.onopen = () => {
        ws.send(JSON.stringify(msg));
        setTimeout(() => {ws.send(JSON.stringify(msg2))
          setTimeout(() => {
            setCircularLoader(false)
            },200)
        }, 500);
      };
      let cSymbol = getESymbol(PreferredCurrency)
      ws.onmessage = (e) => {
        let newdata = JSON.parse(e.data);
         if (!_.isUndefined(e.data)) {
          if (newdata.e == "24hrTicker") {
            if (newdata.s === `BNB${cSymbol}`) setbnbpdata(newdata);
            if (newdata.s === `ETH${cSymbol}`) setethpdata(newdata);
            if (newdata.s === `BTC${cSymbol}`) setbtcpdata(newdata);
            if (newdata.s === `BUSD${cSymbol}`) setbusdpdata(newdata);
            if (newdata.s === `TRX${cSymbol}`) settrxpdata(newdata);
            if (newdata.s === `${cSymbol}TRY`) settrycpdata(newdata);
          } else {
            if (newdata.s === `BNB${cSymbol}`) setbnbdata(newdata);
            if (newdata.s === `ETH${cSymbol}`) setethdata(newdata);
            if (newdata.s === `BTC${cSymbol}`) setbtcdata(newdata);
            if (newdata.s === `BUSD${cSymbol}`) setbusddata(newdata);
            if (newdata.s === `TRX${cSymbol}`) settrxdata(newdata);
            if (newdata.s === `${cSymbol}TRY`) settrycdata(newdata);
          }
        }
      }
      getMarketdata(myaccountandbalance && myaccountandbalance[0], "USD");
    }, []);
  
    const [selectedCoin, setselectedCoin] = useState(
      myaccountandbalance && myaccountandbalance[0]
    );
 
    const [value, setvalue] = useState("");
    const [firstTimeDateMinus,setfirstTimeDateMinus] = useState(2);
  
    const getMarketdata = (selectedCoin, Currency, picker, fixDate) => {
      let tempDate = firstTimeDateMinus;
      if(fixDate){
        setfirstTimeDateMinus(1)
        tempDate = 1;
      }
      try {
        let CurrencyT = Currency == "USD" ? "USDT" : Currency;
        if (
          selectedCoin &&
          selectedCoin.coin &&
          selectedCoin.coin.exchangeSymbol == "USDT"
        ) {
          client
            .dailyStats({
              symbol: `BUSD${CurrencyT}`,
            })
            .then((time) => {
              setmarketdata(time);
            })
            .catch((er) => {});
          client
            .candles({
              symbol: `BUSD${CurrencyT}`,
              interval: "1d",
              startTime: moment(picker?.startDate || startDate).subtract(tempDate, "days").valueOf(),
              endTime: moment(picker?.endDate || endDate).subtract(1, "days").valueOf(),
            })
            .then((time) => {
               let charts = [];
              let min = time && time[0].close;
              let max = time && time[0].close;
              time &&
                time.map((point) => {
                  if (point.close < min) {
                    min = point.close;
                  }
                  if (point.close > max) {
                    max = point.close;
                  }
                  charts.push({
                    x: moment(point.closeTime).format("MM/DD"),
                    y: point.close,
                  });
                });
              setmax(max);
              setmin(min-min*0.01);
              setchartdata(charts);
            })
            .catch((er) => {});
        } else if (selectedCoin.coin.exchangeSymbol == "TRYC") {
          client
            .dailyStats({
              symbol: `${CurrencyT}TRY`,
            })
            .then((time) => {
              setmarketdata(time);
            })
            .catch((er) => {});
           client
            .candles({
              symbol: `${CurrencyT}TRY`,
              interval: "1d",
  
              startTime: moment(picker?.startDate || startDate).subtract(tempDate, "days").valueOf(),
              endTime: moment(picker?.endDate || endDate).subtract(1, "days").valueOf(),
            })
            .then((time) => {
               let charts = [];
              let min = time && time[0].close;
              let max = time && time[0].close;
              time &&
                time.map((point,index) => {
                  if (point.close < min) {
                    min = point.close;
                  }
                  if (point.close > max) {
                    max = point.close;
                  }
                   
                     charts.push({
                    x: moment(point.closeTime).format("MM/DD"),
                    y: point.close,
                  });
                 });
              setmax(max);
              setmin(min-min*0.01);
              setchartdata(charts);
            })
            .catch((er) => {});
        } else {
          client
            .dailyStats({
              symbol: `${
                selectedCoin &&
                selectedCoin.coin &&
                selectedCoin.coin.exchangeSymbol
              }${CurrencyT}`,
            })
            .then((time) => {
              setmarketdata(time);
            })
            .catch((er) => {});
          client
            .candles({
              symbol: `${
                selectedCoin &&
                selectedCoin.coin &&
                selectedCoin.coin.exchangeSymbol
              }${CurrencyT}`,
              interval: "1d",
  
              startTime: moment(picker?.startDate || startDate).subtract(tempDate, "days").valueOf(),
              endTime: moment(picker?.endDate || endDate).subtract(1, "days").valueOf(),
            })
            .then((time) => {
               let charts = [];
              let min = time && time[0].close;
              let max = time && time[0].close;
              time &&
                time.map((point,index) => {
                  if (point.close < min) {
                    min = point.close;
                  }
                  if (point.close > max) {
                    max = point.close;
                  }
                   charts.push({
                    x: moment(point.closeTime).format("MM/DD"),
                    y: point.close,
                  });
                });
              setmax(max);
              setmin(min-min*0.01);
              setchartdata(charts);
            })
            .catch((er) => {});
        }
      } catch (err) {}
    };

    const getBal = (cur) => {
        let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
        let reelbal = _.get(obj, "[0].amount", 0);
        let decimals = _.get(obj, "[0].coin.decimals", 0);
        return BigNumber(reelbal).dividedBy(10 ** decimals);
    };
    const getAmount = (cur) => {
        let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
        let reelbal = _.get(obj, "[0].amount", 0);
        return reelbal;
    };
    const getLockBal = (cur) => {
        let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
        let reelbal = _.get(obj, "[0].lockBalance", 0);
        let decimals = _.get(obj, "[0].coin.decimals", 0);
        return BigNumber(reelbal).dividedBy(10 ** decimals);
    };

    const getAdd = (cur) => {
        let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
        let address = _.get(obj, "[0].address", " ");
        return address;
    };

    useEffect(() => {
     }, [selectedCoin])

    const { t } = useTranslation();
    return (
        <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh} >
            <>
            <Container>
                <div id="cryptowallet">
                    <WalletBigCoinCard 
                    mywalletsinfo={myaccountandbalance}
                    bnbdata={bnbdata}
                    bnbpdata={bnbpdata}
                    getAdd={getAdd}
                    getplatformTokensinfo={getplatformTokensinfo}
                    depositModal={depositModal}
                    withdramodal={withdramodal}
                    exchangemodal={exchangemodal}
                    getBal={getBal}
                    tableno={tableno}
                    settableno={settableno}
                    ethdata={ethdata}
                    ethpdata={ethpdata}
                    btcdata={btcdata}
                    btcpdata={btcpdata}
                    busddata={busddata}
                    busdpdata={busdpdata}
                    trycdata={trycdata}
                    trycpdata={trycpdata}
                    trxdata={trxdata}
                    PreferredCurrency={PreferredCurrency}
                    trxpdata={trxpdata}
                    setwithdrawModal={setwithdrawModal}
                    setExchangeModal={setExchangeModal}
                    setShowDeposit={setDepositModal}
                    displayedCurrencies={displayedCurrencies}
                    circularLoader={circularLoader}
                    role={role}
                    hideZeroBalance={hideZeroBalance}
                     sethideZero={sethideZero}
                    getAmount={getAmount}
                    setselectedCoin={(e)=>{
                     setselectedCoin(e) 
                     getMarketdata(e, Currency);
                    }}
                    fiatrate={fiatrate}
                    myEstimatedBal={myEstimatedBal}
                    />
                    <div className="d-lg-none d-block mt-3">
                        <div className="topspace">
                            <SearchBox value={searchvalue} setvalues={setSearchValue} />
                        </div>
                        <CoinTableWrapper 
                        searchvalue={searchvalue}
                        selectedCoin={selectedCoin} 
                        setcoinItem={(e)=>setcoinItem(e)}
                        coinItem={coinItem}
                        onClick={(e)=>{
                          const dd = myaccountandbalance && myaccountandbalance.filter((data)=>{
                            if(data.coinId == String(e))
                            return data
                            })
                            setselectedCoin(dd[0])
                            getMarketdata(dd[0], Currency);
                        }}
                        hideZeroBalance={hideZeroBalance}
                        />
                    </div>
                    <div className="qrsection mt-3">
                        <div className="qrswrappingborder">
                            <WalletQr selectedCoin={selectedCoin} />
                        </div>
                    </div>
                    <div className="modernBgWrap">
                        <ModernBgcard 
                        selectedCoin={selectedCoin && selectedCoin} 
                        marketdata={marketdata}
                        idoBalance={props.idoBalance}
                        getplatformTokensinfo={props.getplatformTokensinfo}
                        myaccountandbalance={props.myaccountandbalance}
                        cryptoWallet={
                            selectedCoin && selectedCoin.tORcName != "CyborCoin"
                        }
                        prctChg={''}
                        />
                    </div>
                    {
                      selectedCoin && selectedCoin.tORcName != "CyborCoin" &&
                      <div className="currWrap">
                        <CurrencyPairWallet
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={(e) => {
                            setStartDate(e);
                        }}
                        setEndDate={(e) => {
                            setEndDate(e);
                        }}
                        onDateApply={(picker) =>{
                            // setfirstTimeDateMinus(1)
                            getMarketdata(selectedCoin, Currency, picker, true)
                          }
                        }
                        crypto
                        setCurrency={(e) => {
                            setCurrency(e);
                            getMarketdata(selectedCoin, e);
                        }}
                        min={min}
                        max={max}
                        coin={
                            selectedCoin &&
                            selectedCoin.coin &&
                            selectedCoin.coin.exchangeSymbol
                        }
                        currencies={currenciesData}
                        currency={Currency}
                        chartdata={chartdata}
                        selectedCoin={selectedCoin}
                        marketdata={marketdata}
                        />
                    </div>
                    }
                    {selectedCoin && selectedCoin.tORcName == "CyborCoin" && (
                      <>
                        <div className="spacearea mt-5">
                          <IdoTable />
                        </div>
                        <div className='mt-5 d-none d-lg-block' >
                          <CyborTokenomics />
                        </div>
                        {/* <div className='mt-5 d-none d-lg-block'>
                          <Row>
                            <Col lg={6}>
                              <AboutCyborCard />
                            </Col>
                            <Col lg={6} className="smallcardwrapper">
                              <div className="innersmallcard">
                                <BuyCyborCard />
                              </div>
                              <div className="innersmallcard">
                                <VideoCard />
                              </div>
                            </Col>
                          </Row>
                        </div> */}
                        <div className="mt-5 d-block d-lg-none">
                          <div className="whtBtn">
                            <CustomButton
                            name={"Open Tokenomics"}
                            filled={true}
                            primary={false}
                            className="buttonmain"
                            onClick={() => {
                            }}
                            />
                          </div>
                          <div className="blkBtn">
                            <CustomButton
                            name={
                                <>
                                <Download />
                                <span className="ms-1">{t("DownloadTokenonmics")}</span>
                            </>
                            }
                            filled={true}
                            primary={true}
                            className="buttonmain"
                            onClick={() => {
                            }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                </div>
            </Container>
            </>
            <div className='mt-5'>
              <FooterCommon />
            </div>
        </ScreenLayout>
    );
}

const mapStateToProps = ({ ido, wallet = {}, app = {}, user = {}, fiat={} }) => {
    const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
    const transactionCount = _.get(ido, "transactionCount.count", 0);
    const Roundcount = _.get(ido, "Roundcount.count", 0);
    const claimtokencount = _.get(ido, "claimtokencount.count", 0);
    const sendtokenCount = _.get(ido, "sendtokenCount.count", 0);
    const fiatDepositModal = _.get(app, "FiatDepositModal", false);
    const fiatWithdrawModal = _.get(app, "FiatWithdrawModal", false);
    const exchangemodal = _.get(app, "exchangemodal", false);
    const withdramodal = _.get(app, "withdramodal", false);
    const depositModal = _.get(app, "DepositModal", false);
    const getFiatBalanceinfo = _.get(wallet, "getFiatBalanceinfo", []);
    const hideZeroBalance = _.get(app, "hideZeroBalance", false);
    const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
    const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
    const myaccountandbalance = _.get(
      wallet,
      "myaccountandbalancebywalletidinfo",
      []
    );
    const role = _.get(user, "userInfo.role", "");
    const displayedCurrencies = _.get(user, "displayedCurrencies", []);
    const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");

    const platformBanks = _.get(fiat, "platformBanks", []);
    const fiatrate = _.get(app, "fiatrate", false);
    const myEstimatedBal = _.get(user, "myEstimatedBal", 0);
    return {
      getplatformTokensinfo,
      transactionCount,
      Roundcount,
      sendtokenCount,
      claimtokencount,
      mywalletsinfo,
      myaccountandbalance,
      withdramodal,
      exchangemodal,
      plaformcurrencies,
      fiatDepositModal,
      getFiatBalanceinfo,
      role,
      displayedCurrencies,
      depositModal,
      PreferredCurrency,
      platformBanks,
      hideZeroBalance,
      fiatrate,
      myEstimatedBal,
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    updateTheme: (data) => dispatch({ type: "updateTheme", payload: data }),
    setwithdrawModal: (data) =>
      dispatch({ type: "withdramodalcalled", payload: data }),
    setExchangeModal: (data) =>
      dispatch({ type: "setExchangeModalcalled", payload: data }),
    setFiatDepositModal: (data) =>
      dispatch({ type: "FiatDepositModalCalled", payload: data }),
    setDepositModal: (data) =>
      dispatch({ type: "DepositModalCalled", payload: data }),
      sethideZero: (data) => dispatch({ type: "hideZerocalled", payload: data }),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CryptoWallet);