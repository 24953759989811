import { call, put } from "redux-saga/effects";
import {
  appSettingsSuccess,
  changeThemeSuccess,
  updateVersionSuccess,
  setWithdrawModalSuccess,
  setExchangeModalSuccess,
  setPaymentlinkSuccModalSuccess,
  setPaymentlinkModalSuccess,
  setFiatDepositModalSuccess,
  setDepositModalSuccess,
  setIdobuyModalSuccess,
  setConfirmcodeModalSuccess,
  setAuthenticatorModalSuccess,
  setFiatPullRequestClose,
  setFiatPullRequestOpen,
  setFiatWithdrawModalSuccess,
  setbuyModalSuccess,
  changeRoleSuccess,
  hideZeroBalanceSuccess,
  businessSuceessSuccess,
  setFiatrateSuccess,
  closeBetaSuccess,
  setbnbdata,
  setbnbpdata,
  setbtcdata,
  setbtcpdata,
  setethdata,
  setethpdata,
  setbusddata,
  setbusdpdata,
  settrxdata,
  settrxpdata,
  settrycdata,
  settrycpdata,
} from "./appredux";

import Api from "./../services/ApiCaller";
import { toast } from "react-toastify";
import {
  exchangeTokenorCoinTrigger,
  transferBalanceOrTokenTrigger,
  clearplatformerroSuccess,
} from "./platformuserredux";
import { clearPaymentLinkError } from "./paymentlinkredux";

import { clearAllFiatError, closeBankPopup } from "./fiatredux";

export function* getappsettings(api, { payload = {} }) {
  try {
    const result = yield call(Api.callServer, api.GetSettings, payload, true);
    yield put(appSettingsSuccess(result));
  } catch (error) {}
}

export function* setthememode(api, { payload = {} }) {
  yield put(changeThemeSuccess(payload));
}

export function* updateVersion(api, { payload = {} }) {
  yield put(updateVersionSuccess(payload));
}
export function* setDepositModal(api, { payload = {} }) {
  yield put(setDepositModalSuccess(payload));
  yield put(clearplatformerroSuccess());
}

export function* setWithdrawModal(api, { payload = {} }) {
  yield put(setWithdrawModalSuccess(payload));
  yield put(transferBalanceOrTokenTrigger(false));
  yield put(clearplatformerroSuccess());
}

export function* setFiatDepositModal(api, { payload = {} }) {
  yield put(setFiatDepositModalSuccess(payload));
  yield put(clearAllFiatError());
}

export function* setFiatWithdrawModal(api, { payload = {} }) {
  yield put(setFiatWithdrawModalSuccess(payload));
  yield put(clearAllFiatError());
}

export function* setExchangeModal(api, { payload = {} }) {
  yield put(setExchangeModalSuccess(payload));
  yield put(exchangeTokenorCoinTrigger(false));
  yield put(clearplatformerroSuccess());
}
export function* setPaymentlink(api, { payload = {} }) {
  yield put(setPaymentlinkModalSuccess(payload));
}
export function* setPaymentlinkSucc(api, { payload = {} }) {
  yield put(setPaymentlinkSuccModalSuccess(payload));
  yield put(clearPaymentLinkError());
}

export function* setbuyModal(api, { payload = {} }) {
  yield put(setbuyModalSuccess(payload));
  // yield put(clearbuyModalSuccess());
}
export function* setIdobuyModal(api, { payload = {} }) {
  yield put(setIdobuyModalSuccess(payload));
  // yield put(clearIdobuyModalSuccess());
}
export function* setConfirmcodeModal(api, { payload = {} }) {
  yield put(setConfirmcodeModalSuccess(payload));
  // yield put(clearConfirmModal)
}

export function* setAuthenticatorModal(api, { payload = {} }) {
  yield put(setAuthenticatorModalSuccess(payload));
}

export function* setFiatPullRequestOpenAsync(api, { payload = {} }) {
  yield put(setFiatPullRequestOpen(payload));
}

export function* setFiatPullRequestCloseAsync(api, { payload = {} }) {
  yield put(setFiatPullRequestClose(payload));
}

export function* setCreateBankAccountPopupCloseAsync(api, { payload = {} }) {
  yield put(closeBankPopup(payload));
}

export function* changeRole(api, { payload = {} }) {
  yield put(changeRoleSuccess(payload));
}

export function* hideZeroBalance(api, { payload = {} }) {
  yield put(hideZeroBalanceSuccess(payload));
}

export function* businessSuceess(api, { payload = {} }) {
  yield put(businessSuceessSuccess(payload));
}

export function* closeBetaPopup(api, { payload = {} }) {
  yield put(closeBetaSuccess(payload));
}

export function* setFiatrate(api, { payload = {} }) {
  yield put(setFiatrateSuccess(payload));
}

export function* getWSRate(api, { payload = {} }) {
  const ws = new WebSocket("wss://stream.binance.com:9443/ws");
  const ws2 = new WebSocket("wss://stream.binance.com:9443/ws");
  const ws3 = new WebSocket("wss://stream.binance.com:9443/ws");
  let { prefSymbol, cSymbol, dispatch } = payload;
  // const msg = {
  //   method: "SUBSCRIBE",
  //   params: [
  //     `eth${prefSymbol}@aggTrade`,
  //     `btc${prefSymbol}@aggTrade`,
  //     `bnb${prefSymbol}@aggTrade`,
  //     `busd${prefSymbol}@aggTrade`,
  //     `eth${prefSymbol}@ticker`,
  //     `btc${prefSymbol}@ticker`,
  //     `bnb${prefSymbol}@ticker`,
  //     `busd${prefSymbol}@ticker`,
  //     `trx${prefSymbol}@ticker`,
  //     `trx${prefSymbol}@aggTrade`,
  //     `${prefSymbol}try@ticker`,
  //     `${prefSymbol}try@aggTrade`,
  //   ],
  //   id: 1,
  // };

  const etha = {
    method: `SUBSCRIBE`,
    params: [`eth${prefSymbol}@aggTrade`],
    id: 1,
  };
  const etht = {
    method: `SUBSCRIBE`,
    params: [`eth${prefSymbol}@ticker`],
    id: 2,
  };

  const btca = {
    method: `SUBSCRIBE`,
    params: [`btc${prefSymbol}@aggTrade`],
    id: 3,
  };
  const btct = {
    method: `SUBSCRIBE`,
    params: [`btc${prefSymbol}@ticker`],
    id: 4,
  };

  const bnba = {
    method: `SUBSCRIBE`,
    params: [`bnb${prefSymbol}@aggTrade`],
    id: 5,
  };
  const bnbt = {
    method: `SUBSCRIBE`,
    params: [`bnb${prefSymbol}@ticker`],
    id: 6,
  };
  const busda = {
    method: `SUBSCRIBE`,
    params: [`busd${prefSymbol}@aggTrade`],
    id: 7,
  };
  const busdt = {
    method: `SUBSCRIBE`,
    params: [`busd${prefSymbol}@ticker`],
    id: 8,
  };
  const tryda = {
    method: `SUBSCRIBE`,
    params: [`${prefSymbol}try@aggTrade`],
    id: 9,
  };
  const trydt = {
    method: `SUBSCRIBE`,
    params: [`${prefSymbol}try@ticker`],
    id: 10,
  };
  const trxa = {
    method: `SUBSCRIBE`,
    params: [`trx${prefSymbol}@aggTrade`],
    id: 11,
  };
  const trxt = {
    method: `SUBSCRIBE`,
    params: [`trx${prefSymbol}@ticker`],
    id: 12,
  };
  const ethag = {
    method: `UNSUBSCRIBE`,
    params: [`eth${prefSymbol}@aggTrade`],
    id: 1,
  };
  const ethti = {
    method: `UNSUBSCRIBE`,
    params: [`eth${prefSymbol}@ticker`],
    id: 2,
  };

  const btcag = {
    method: `UNSUBSCRIBE`,
    params: [`btc${prefSymbol}@aggTrade`],
    id: 3,
  };
  const btcti = {
    method: `UNSUBSCRIBE`,
    params: [`btc${prefSymbol}@ticker`],
    id: 4,
  };

  const bnbag = {
    method: `UNSUBSCRIBE`,
    params: [`bnb${prefSymbol}@aggTrade`],
    id: 5,
  };
  const bnbti = {
    method: `UNSUBSCRIBE`,
    params: [`bnb${prefSymbol}@ticker`],
    id: 6,
  };
  const busdag = {
    method: `UNSUBSCRIBE`,
    params: [`busd${prefSymbol}@aggTrade`],
    id: 7,
  };
  const busdti = {
    method: `UNSUBSCRIBE`,
    params: [`busd${prefSymbol}@ticker`],
    id: 8,
  };
  const trydag = {
    method: `UNSUBSCRIBE`,
    params: [`${prefSymbol}try@aggTrade`],
    id: 9,
  };
  const trydti = {
    method: `UNSUBSCRIBE`,
    params: [`${prefSymbol}try@ticker`],
    id: 10,
  };
  const trxag = {
    method: `UNSUBSCRIBE`,
    params: [`trx${prefSymbol}@aggTrade`],
    id: 11,
  };
  const trxti = {
    method: `UNSUBSCRIBE`,
    params: [`trx${prefSymbol}@ticker`],
    id: 12,
  };
  ws.onopen = () => {
    ws.send(JSON.stringify(etha));
    ws.send(JSON.stringify(etht));
    ws.send(JSON.stringify(btca));
    ws.send(JSON.stringify(btct));
    setTimeout(() => {
      ws.close();
    }, 2000);
  };
  ws.onmessage = (e) => {
    let newdata = JSON.parse(e.data);

    if (!_.isUndefined(e.data)) {
      if (newdata.e == "24hrTicker") {
        if (newdata.s === `ETH${cSymbol}`) {
          dispatch(setethpdata(newdata));
        }
        if (newdata.s === `BTC${cSymbol}`) {
          dispatch(setbtcpdata(newdata));
        }
      } else {
        if (newdata.s === `ETH${cSymbol}`) {
          dispatch(setethdata(newdata));
        }
        if (newdata.s === `BTC${cSymbol}`) {
          dispatch(setbtcdata(newdata));
        }
      }
    }
  };
  ws2.onopen = () => {
    ws2.send(JSON.stringify(tryda));
    ws2.send(JSON.stringify(trydt));
    ws2.send(JSON.stringify(busda));
    ws2.send(JSON.stringify(busdt));
    setTimeout(() => {
      ws2.close();
    }, 2000);
  };
  ws2.onmessage = (e) => {
    let newdata = JSON.parse(e.data);
    if (!_.isUndefined(e.data)) {
      if (newdata.e == "24hrTicker") {
        if (newdata.s === `BUSD${cSymbol}`) {
          dispatch(setbusdpdata(newdata));
        }

        if (newdata.s === `${cSymbol}TRY`) {
          dispatch(settrycpdata(newdata));
        }
      } else {
        if (newdata.s === `BUSD${cSymbol}`) {
          dispatch(setbusddata(newdata));
        }

        if (newdata.s === `${cSymbol}TRY`) {
          dispatch(settrycdata(newdata));
        }
      }
    }
  };

  ws3.onopen = () => {
    ws3.send(JSON.stringify(bnbt));
    ws3.send(JSON.stringify(bnba));
    ws3.send(JSON.stringify(trxa));
    ws3.send(JSON.stringify(trxt));
    setTimeout(() => {
      ws3.close();
    }, 2000);
  };
  ws3.onmessage = (e) => {
    let newdata = JSON.parse(e.data);
    if (!_.isUndefined(e.data)) {
      if (newdata.e == "24hrTicker") {
        if (newdata.s === `BNB${cSymbol}`) {
          dispatch(setbnbpdata(newdata));
        }

        if (newdata.s === `TRX${cSymbol}`) {
          dispatch(settrxpdata(newdata));
        }
      } else {
        if (newdata.s === `BNB${cSymbol}`) {
          dispatch(setbnbdata(newdata));
        }

        if (newdata.s === `TRX${cSymbol}`) {
          dispatch(settrxdata(newdata));
        }
      }
    }
  };
}
