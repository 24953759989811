import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { useTranslation } from "react-i18next";
import ScreenLayout from "src/components/layout/ScreenLayout";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import CustomButton from "@components/custom-button/CustomButton";
import "@features/admin-crypto-cards/AdminCryptoCardsStyle.scss";

function AdminCryptoCards({
  listAllNfcCards,
  getAllNfcCards,
  nfcCardCount,
  loadNfcCard,
  thememode,
  hideLoaderOnRefresh,
}) {

    let [pages, setpages] = useState(0);
    let [limit, setlimit] = useState(10);
    let [skip, setskip] = useState(0);

  useEffect(() => {
    getAllNfcCards({ skip: 0, limit: 10 });
  }, []);
  

  const { t } = useTranslation();

  console.log("check data count",  nfcCardCount, listAllNfcCards);

  return (
    <div id="AdminCryptoCards">
      <ScreenLayout
        hideLoaderOnStart={true}
        loaderOnRefresh={hideLoaderOnRefresh}
      >
        <Container className="containertp">
          <Row>
            <Col lg={12}>
            <div className="trouter">
                <div className="pageTitle">
                  <span className=" title">{t("Cryptocards")} </span>
                </div>
              </div>
              <Table
                responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless
              >
                { loadNfcCard && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading width-start">{t("ID")}</th>
                    <th className="trtbleheading">{t("CreatedOn")}</th>
                    <th className="trtbleheading">
                      {t("Card Name")}
                    </th>
                    <th className="trtbleheading">{t("Card Number")}</th>
                  </tr>
                </thead>

                <tbody>
                  {listAllNfcCards && listAllNfcCards.data && listAllNfcCards.data.map((item, index) => (
                      <tr key={`trans${index}`}>
                        <td className="amt">
                          <div className="innerTd idtr ">
                            <span className="id idtxt">{item.id}</span>
                            <p className="hidden-for-desktop date margintop">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </p>
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd  ">
                            {moment(item.createdOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd">
                            {item.cardName}
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd  ">
                            <span className="id idtxt">{item.decryptedCardNumber !== "" ? item.decryptedCardNumber : item.cardNumber}</span>
                            <span className="id idtxt hidden-for-desktop">
                              {item.cardName}
                            </span>
                          </div>
                        </td> 
                      </tr>
                    ))}
                </tbody>
              </Table>
              {nfcCardCount > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(nfcCardCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={nfcCardCount / limit}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        setskip(e.selected * limit);
                        getAllNfcCards({
                          skip: e.selected * limit,
                          limit,
                        });
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </ScreenLayout>
    </div>
  );
}
const mapStateToProps = ({ user = {}, app = {} }) => {
  const listAllNfcCards = _.get(user, "getNfcCardSuccess", []);
  const nfcCardCount = _.get(user, "getNfcCardCount", 0);
  const loadNfcCard = _.get(user, "getNfcCardLoading", false);
  const thememode = _.get(app, "thememode", false);
  return {
    listAllNfcCards,
    nfcCardCount,
    loadNfcCard,
    thememode
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllNfcCards: (data) =>
    dispatch({ type: "allNfcCardsCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminCryptoCards);
