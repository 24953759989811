export default {
  otp: "Проверочный код",
  OTP_NOT_MATCHED: "Проверочный код не совпадает",
  OTP_EXPIRED: "Проверочный код просрочен",
  OTP_NOT_GENERATED: "Проверочный код не сгенерирован для этого пользователя, сканачала сгенерируйте проверочный код",
  LIMIT_EXCEED:
    "Вы не можете ввести проверочный код в данный момент из-за превышенного лимита попыток. Пожалуйста, попробуйте немного позже.",
  ocbe: "Проверочный код не может быть пустым",
  nv: "Некорректный",
  "mobile should not be empty": "Номер не должен быть пустым",
};
