import * as _ from "lodash";
import React from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/personal-loan-emi-detail-popup/PersonalLoanEmiPopup.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";


const PersonalLoanEmiPopup = ({
  showModal,
  setShowModal,
  onClick,
  data,
  tenure,
  onClose,
}) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="confirmsuccess"
      show={showModal}
      // onHide={() => {
      //   setShowModal(false);
      // }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            onClose();
          }}
        />
        <Container>
          <Row className="card-body">
          <Col lg={12}>
              <div className="exchangetitle">Loan Calculation</div>
              
            </Col>
            <Col lg={12}>
              <div className="exchangetext">Principal Amount: <span className="value">{data?.principal} USD</span></div>
              
            </Col>
            <Col lg={12}>
              <div className="exchangetext">Monthly EMI: <span className="value">{data?.monthlyEmi}</span></div>
            </Col>
            <Col lg={12}>
              <div className="exchangetext">Tenure: <span className="value">{tenure} Months</span></div>
            </Col>
            <Col lg={12}>
              <div className="exchangetext">Total Interest Amount: <span className="value">{data?.totalInterestPaid} USD</span></div>
            </Col>
            <Col lg={12}>
              <div className="exchangetext">Total Amount: <span className="value">{data?.totalAmount} USD</span></div>
            </Col>
        
            <Col lg={12}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Apply"
                  filled={true}
                  primary={true}
                  onClick={onClick}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(PersonalLoanEmiPopup);
