import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import InputField from "@components/input-field/InputField";
import { Modal, Container, Row, Col, Spinner } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "@components/amount-popup/AmountPoupStyles.scss";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import BigNumber from "bignumber.js";
import { connect } from "react-redux";
import Loader from "@components/loader/Loader";
import { useTranslation } from "react-i18next";

function AmountPopup({
  show1,
  showm,
  selectedCoin,
  setIdoModal,
  setShowModal1,
  setShowModal,
  getExchangerate,
  coinItem,
  idorounds,
  CyborExchangeRate,
  BuyCyborcoinFromIdo,
  getplatformTokensinfo,
  myaccountandbalance,
  createTransactionLoading,
  CyborExchangeRateLoading,
  createTransFail,
}) {
  const [Selected, setSelectedIndex] = useState(0);
  const [val1, setVal1] = useState("");
  const [val2, setVal2] = useState("");
  useEffect(() => {
     getExchangerate({
      symbol1:
        selectedCoin && selectedCoin.coin && selectedCoin.coin.exchangeSymbol,
      symbol2: option && option[Selected] && option[Selected].exchangeSymbol,
    });
  }, []);
  useEffect(()=>{
      setVal1("");
      setVal2("");
  },[selectedCoin.coin])

  let option = [];
  if (selectedCoin && selectedCoin.coin && selectedCoin.coin.exchangeSymbol)
    option =
      getplatformTokensinfo &&
      getplatformTokensinfo.filter(
        (item) => item.tokenName != selectedCoin.coin.tokenName
      );
  else option = getplatformTokensinfo && getplatformTokensinfo[0];

  useEffect(() => {
    getExchangerate({
      symbol1:
        selectedCoin && selectedCoin.coin && selectedCoin.coin.exchangeSymbol,
      symbol2: option && option[Selected] && option[Selected].exchangeSymbol,
    });
  }, [Selected]);

  const { t } = useTranslation();
   return (
    <Modal
      className="amountpopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <Modal.Header className="modalhead">
        <Modal.Title>
          <span className="buytitle"></span>
        </Modal.Title>

        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal1(false);
          }}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="maintitle hidden-for-desktop"></div>
        <div
          className="back hidden-for-desktop"
          onClick={() => {
            setShowModal1(false);
            setShowModal && setShowModal(false);
          }}
        >
          <ExpandIcon />
        </div>
        <div className="subtitle hidden-for-desktop">
          <span>{t("Pleaseenteramount")}:</span>
        </div>
        <Container>
          <Row className="topspace">
            <div className="buytext">
              <span>{t("DetermineamounttoDepositorExchange")}</span>
            </div>
            <Col lg={12}>
              <div className="btext">{t("Amount")} </div>
            </Col>
            <Col lg={7}>
              <div className="buytxtalign">
                <img
                  className="icons"
                  src={`${process.env.REACT_APP_IMGLINK}${
                    selectedCoin &&
                    selectedCoin.coin &&
                    selectedCoin.coin.colouredLogo
                  }`}
                />
                {selectedCoin &&
                  selectedCoin.coin &&
                  selectedCoin.coin.tokenName}
              </div>
            </Col>
            <Col lg={5}>
              <InputField
                type="number"
                className={"roundedborderfield"}
                onChange={(e) => {
                  let exc = 0;
                  if (
                    CyborExchangeRate &&
                    CyborExchangeRate.sym1 == selectedCoin &&
                    selectedCoin.coin &&
                    selectedCoin.coin.tokenName
                  )
                    exc = BigNumber(e.target.value).dividedBy(
                      CyborExchangeRate && CyborExchangeRate.result
                    );
                  else
                    exc = BigNumber(e.target.value).multipliedBy(
                      CyborExchangeRate && CyborExchangeRate.result
                    );
                  setVal1(e.target.value);

                  setVal2(Number(exc).toFixed(4));
                }}
                value={val1}
              />
            </Col>
            <Col lg={12}>
              <div className="btext">{t("exchange_amt")}</div>
            </Col>
            <Col lg={7}>
              {" "}
              <DropdownSearch
                options={option}
                coinItem={coinItem}
                Selected={Selected}
                setSelectedIndex={(e) => {
                  setSelectedIndex(e);
                  setVal1("");
                  setVal2("");
                }}
              />
            </Col>
            <Col lg={5} className="mbspace">
              <InputField
                type="number"
                className={"roundedborderfield"}
                onChange={(e) => {
                  setVal2(e.target.value);

                  let exc = 0;
                   if (
                    CyborExchangeRate &&
                    CyborExchangeRate.sym1 == selectedCoin &&
                    selectedCoin.coin &&
                    selectedCoin.coin.tokenName
                  )
                    exc = BigNumber(e.target.value).multipliedBy(
                      CyborExchangeRate && CyborExchangeRate.result
                    );
                  else
                    exc = BigNumber(e.target.value).dividedBy(
                      CyborExchangeRate && CyborExchangeRate.result
                    );

                  setVal1(Number(exc).toFixed(4));
                }}
                value={val2}
              />
            </Col>
            <div className="txtmargin">
              <div className="bottomtext">{t("ExchangeRates")}:</div>
              {CyborExchangeRateLoading && (
                <Spinner animation="grow" className="spincolor" />
              )}
              {!CyborExchangeRateLoading && (
                <div className="bottomtext">
                  1{" "}
                  {selectedCoin &&
                    selectedCoin.coin &&
                    selectedCoin.coin.exchangeSymbol}
                  ={" "}
                  {BigNumber(
                    CyborExchangeRate && CyborExchangeRate.result
                  ).toFixed(8,BigNumber.ROUND_FLOOR)}{" "}
                  {option &&
                    option[Selected] &&
                    option[Selected].exchangeSymbol}{" "}
                </div>
              )}
            </div>

            {createTransactionLoading && (
              <Loader msg={t("TransactionWaitMessage")} />
            )}
            <div>
              <p className="errorstyles">{createTransFail}</p>
            </div>
            <Col lg={12}>
              <div className="ok-btn">
                <CustomButton
                  type="submit"
                  name={t("Submit")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal1(false);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({ wallet = {}, ido = {} }) => {
  const loadingRate = _.get(wallet, "loadingRate", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const createTransactionLoading = _.get(
    ido,
    "createTransactionLoading",
    false
  );
  const idorounds = _.get(ido, "idorounds", []);
  const CyborExchangeRate = _.get(wallet, "exchangeRates", {});
  const CyborExchangeRateLoading = _.get(wallet, "loadingRate", false);
  const createTransFail = _.get(ido, "createTransFail.message", false);

  return {
    loadingRate,
    exchangeRates,
    createTransactionLoading,
    CyborExchangeRate,
    idorounds,
    CyborExchangeRateLoading,
    createTransFail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),
  BuyCyborcoinFromIdo: (data) =>
    dispatch({ type: "BuyCyborcoinFromIdoCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AmountPopup);
