export default {
  Balances: 'Bakiyeler',
  CopyWalletNumber: 'almak için cüzdan numaranızı aşağıda kopyalayın',
  MarketRate: 'Piyasa Değeri',
  BasedOn: "'a dayalı",
  Volume: 'Tutar',
  MarketCap: 'Piyasa Kapitalizasyonu',
  WalletNumber: 'cüzdan numarası',
  AllType: 'Her tip',
  EstimateBalance: 'Tahmini Bakiye',
  HideZeroBalance: 'Sıfır bakiyeleri gizle',
  Filter: 'Filtre',
  Reset: 'Sıfırla',
  AddBankConfirmed: 'Banka eklenmesi onaylandı !',
 "amount should not be empty":"Tutar alanı boş olmamalıdır",
  NoResultsFound: 'Sonuç bulunamadı',
  exchange_amt:"Döviz Tutarı",
  AmountL:'Miktar',
  T0: "İle",
  "Internal server error": "İç Sunucu Hatası",
  Pleaseenteramount: "Lütfen tutarı girin",
  DetermineamounttoDepositorExchange: "Mevduat veya Takas Tutarı miktarını belirleyin",
  ReceiveLeft: " ",
  ReceiveRight: "Almak",
}
