export default {
  Payments: "Payments",
  CreatePaymentLink: "Create Payment Link",
  Print: "Print",
  Excel: "Excel",
  PaymentID: "Payment ID",
  Date: "Date",
  PaymentLink: "Payment Link",
  CoinToAccept: "Coin to accept",
  Amount: "Amount",
  CoinToConvert: "Coin to convert",
  Status: "Status",
  CopyPaymentLink: "Copy Payment Link",
  Details: "Details",
  Showing: "Showing",
  From: "from",
  Completed: "Completed",
  Cancelled: "Cancelled",
  Pending: "Pending",
  failed: "FAILED",
  "transfer": "Transfer",
  copied: "Copied !",
  YOUR_BUSINESS_ACCOUNT_REQUEST_IS_NOT_APPROVED:
    "Your business account request is not approved",
  CurrencyToConvert: "Currency",
  CryptoCurrencyToPay: "Crypto Currency (to pay with)",
  CryptoCurrencyToConvert: "Crypto Currency to Convert",
  PaymentLinkwillbecreated: "Payment Link will be created",
  Showonscreen: "Show on screen",
  CopyAddress : "Copy Address",
  LinkCreatedSuccessfully: "Link Created Successfully",
  WantToCancel: "Do you want to cancel this payment",
  ClientPays: "Client Pays",
  CurrencytoConvert: "Currency to Convert",
  CurrencytoReceive: "Currency to Receive",
  PAYMENT_DETECTED: "Payment detected",
  PAYMENT_REQUESTED: "Waiting for payment",
  PAY_CYWALL: "Pay using PayCryptwallet",
  LPAY_CYWALL: "Login To Pay using PayCryptwallet",
  PAY_OTWALL:`Pay using other wallet`
};
