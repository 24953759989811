import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import { EN, TR, RU } from "./locales/index";
//import resourcesToBackend from "i18next-resources-to-backend";
const fallbackLng = ["tr"];
const availableLanguages = ["en", "ru", "tr"];

//   return new Promise((resolve, reject) => {
//     try {
//let englishLang = localStorage.getItem("englishLang");
//let turkishLang = localStorage.getItem("turkishLang");

const resources = {
  en: { translation: EN },
  tr: { translation: TR },
  ru: { translation: RU },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // .use(
  //   resourcesToBackend((language, namespace, callback) => {
  //     console.log("language, namespace,", language, namespace);
  //     localStorage.getItem("englishLang")
  //       .then((resources) => {
  //         console.log("askudydja ", resources);
  //         callback(null, resources);
  //       })
  //       .catch((error) => {
  //         callback(error, null);
  //       });
  //   })
  // )
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources,

    whitelist: availableLanguages,

    react: {
      useSuspense: false,
    },
  });
export const languageChange = (englishLang = [], turkishLang = [], russianLang = []) => {
  i18next.addResourceBundle("en", "translation", englishLang, true, true);
  i18next.addResourceBundle("tr", "translation", turkishLang, true, true);
  i18next.addResourceBundle("ru", "translation", russianLang, true, true);
};
//     resolve(i18n)

//   } catch (error) {
//     reject(error.message)
//   }
// })

export default i18n;
