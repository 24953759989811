import * as _ from "lodash";
import React from "react";
import "./DetailedPopupStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
import moment from "moment";
import copy from "@images/copy1.svg";
import share from "@images/share1.svg";
import { useTranslation } from "react-i18next";

const DetailedPopup = ({ setShowModal, data, show1 }) => {
  let {
    id,
    txnHash,
    block,
    from,
    to,
    amount,
    status,
    type,
    tOrCName,
    transactionType,
    gasUsed,
    timestamp,
    note,
    createdOn,
    updatedOn,
    withdraw,
    userexchange,
    fromCoin,
    toCoin,
    coin,
    TypeCurrency,
    currency,
    fiatTransactionStatus,
    fees,
  } = data || {};
  // console.log(data);
  let { decimals, exchangeSymbol, explorerLink } = coin || {};
  let { tokenName } = fromCoin || {};
  // let toCoinTokename = toCoin.tokenName
  // let newDecimal = fromCoin.decimals

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="Detailed"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      <div
        className=" back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
        <div className="transectiontype">
          {!transactionType ? (
            <span className="transectiontext uppercase">
              {t(`Exchange`)} {t("Details")}{" "}
            </span>
          ) : (
            <span className="transectiontext uppercase">
              {t(`${transactionType}`)} {t("Details")}{" "}
            </span>
          )}
        </div>
      </Modal.Header>

      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Transactionreference")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{id}</span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Amount")}</span>
            </div>
            <div className="valueamout">
              <div className="longvalue">
                {decimals ? (
                  <span className="valuetext">
                    {" "}
                    {amount / 10 ** decimals} {exchangeSymbol}{" "}
                    {/* {amount /10 ** fromCoin.decimals } {exchangeSymbol}{" "}  */}
                  </span>
                ) : (
                  <span className="valuetext">
                    {" "}
                    {amount} {currency && currency.exchangeSymbol}
                    {/* {amount / 10 ** newDecimal} {" "} */}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              {!transactionType ? (
                <span className="labeltext">{t("ExchangedCoins")} </span>
              ) : (
                <span className="labeltext">{t("TransactionFee")}</span>
              )}
            </div>
            <div className="value">
              {fees ? (
                <span className="valuetext uppercase"> {fees} </span>
              ) : (
                <span className="valuetext uppercase">{"-"}  </span>
              )}

              <span className="valuetext uppercase">
                {/* {t(`${transactionType}`)}{" "} */}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Status")}</span>
            </div>
            <div className="value">
              {TypeCurrency == "Fiat" ? (
                <span className="valuetext uppercase">
                  {t(`${fiatTransactionStatus}`)}
                </span>
              ) : (
                <span className="valuetext uppercase">{t(`${status}`)}</span>
              )}
            </div>
          </div>

          {transactionType != "transfer" && txnHash && (
            <div className="detail">
              <div className="label">
                <span className="labeltext">{t("TransactionID")}</span>
              </div>
              <div className="valuetransid">
                <div className="longvalue">
                  <span className="valuetext">{txnHash}</span>
                </div>
              </div>
            </div>
          )}

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("DateandTime")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {moment(createdOn).local().format("MM/DD/YYYY hh:mm A")}
              </span>
            </div>
          </div>

          {transactionType != "transfer" && txnHash && (
            <div className="linktag">
              <a href={`${explorerLink}${txnHash}`} target="blank">
                {t("ViewinBlockchainExplorer")}
              </a>
            </div>
          )}

          <div className="confirm">
            <CustomButton
              type="submit"
              name={t("Close")}
              filled={true}
              primary={true}
              onClick={() => setShowModal(false)}
            />
          </div>
          {/* 
          <div className="options hidden-for-desktop">
            <div className="optiontxtholder1">
              <img
                onClick={() => {
                  setcopied(true);
                  navigator.clipboard.writeText(address);
                }}
                src={copy}
                className="comtxt"
              />
              <div
                className="comtxt1"
                onClick={() => {
                  setcopied(true);
                  navigator.clipboard.writeText(address);
                }}
              >
                {copied ? t(`${"Copied"}`) : t(`${"Copy"}`)}
                {t(`${"Copy"}`)}
              </div>
            </div>

            <div className="optiontxtholder1">
              <img
                src={share}
                className="comtxt"
                onClick={() => {
                  setDepositModal(false)
                  setsharem(true)
                  // setshow(true);

                }}
              />
              <div className="comtxt1">{t("Share")}</div>
            </div>
          </div> */}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(DetailedPopup);
