import React from "react";
import "@components/loadersmall/LoderSmallStyles.scss";
import { BeatLoader } from "react-spinners";
function LoaderSmall({ msg }) {
  return (
    <div className="Loader">
      <div className="load">
        <div className="loader1">
          <div className="message">{msg}</div>
          <BeatLoader size={24} color="var(--primary)" loading />
        </div>
      </div>
    </div>
  );
}
export default LoaderSmall;
