import React from "react";
import ScreenLayout from "@components/layout/ScreenLayout";
import "@features/request-loan/RequestLoan.scss";
import { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import InputField from "@components/input-field/InputField";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
// import ScreenLayout from "@components/layout/ScreenLayout";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import CustomButton from "@components/custom-button/CustomButton";
import { useLocation } from "react-router-dom";
import { clearConversionRedux } from "@store/platformuserredux";

import { Form } from "react-bootstrap";
import LoaderSmall from "src/components/loadersmall/LoaderSmall";
import Loader from "src/components/loader/Loader";
import AdminLoanSuccesfulPopup from "src/components/admin-loan-template-successfull-popup/AdminLoanSuccesfulPopup";
import FooterCommon from "src/components/footer-common/FooterCommon";
import { Spinner, Tab } from "react-bootstrap";
import BigNumber from "bignumber.js";

function RequestLoan({
  getplatformTokensinfo,
  requestLoanLoad,
  loanTypeByAdmin,
  requestLoanError,
  borrowLoanAsyncCalled,
  collateralConversionLoading,
  collaterConvoAmount,
  collaterConvoError,
  collaterConversionAsync,
  clearConversionAmountAsync,
  borrowRequestLoading,
  borrowError,
  userInfo,
  myaccountandbalance,
  getFiatBalanceinfo,
  allLoanTypeAsync,
  allLoans,
  allLoanLoading,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const LoanType = [
    { label: "7 Days - Stable Rate", value: 0 },
    { label: "30 Days - Stable Rate", value: 1 },
  ];
  const [loanType, setLoanType] = useState("");
  const [amount, setAmount] = useState("");
  const [coin, setCoin] = useState("");
  const [coinId, setCoinId] = useState("");
  const [collateral, setCollateral] = useState("");
  const [collateralCoin, setCollaterlCoin] = useState("");
  const [validated, setValidated] = useState();
  const [coinError, setCoinError] = useState("");
  const [chosenCoin, setChosenCoin] = useState("");
  const [collateralCoinError, setCollateralCoinError] = useState("");
  const [allInterestRates, setAllInterest] = useState();
  const [coloredLogo, setColoredLogo] = useState();
  const [borrowCoinId, setBorrowCoinId] = useState("");
  const [loanTypeId, setLoanTypeId] = useState("");
  const [collateralCoinId, setCollateralCoinId] = useState("");

  const [collateralLoader, setCollateralLoader] = useState("");
  const [amountLoader, setAmountLoader] = useState("");
  const [AmountType, setAmountType] = useState("");
  const [successPopup, setShowSuccess] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValid2, setIsValid2] = useState(true);
  const [tag, setTag] = useState();
  const [checkBox, setCheckBox] = useState(false);
  const [isValid3, setIsValid3] = useState("");
  // const [compareUserAmount , setUserAmount] = useState(false)
  const [coinSameError, setCoinSameError] = useState("");
  const [loanInitialLTV , setLoanInitialLTV] = useState();
  const [loanLiquiLTV , setLiquiLTV] = useState();
  const [collateralCoinName , setCollateralCoinName] = useState();
  const [chosenExchangeSymbol , setChosenExchangeSymbol] = useState();
  // let [amt1, setamt1] = useState("");
  //const [amountType,setAmountType] = useState('borrow')

 
  

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAmount(e.target.value);
    setAmountType("borrow");
    // setInputValue(value);
    setIsValid(value >= 0);
  };
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setCollateral(e.target.value);
    setAmountType("collateral");
    // setInputValue(value);
    setIsValid2(value >= 0); // Check if the value is non-negative
  };

  const handleCheckChange = () => {
    setCheckBox(!checkBox);
  };

  useEffect(() => {
    clearConversionRates();
    setCollateral("");
    setAmount("");
  }, []);

 
  useEffect(() => {
    // console.log("location", location.state.state);
    if (
      location &&
      location.state !== undefined &&
      location.state.state.item !== undefined
    ) {
     
      if (location.state.state.item.type) {
        setTag(location.state.state.item.type);
      }
      setChosenCoin(location.state.state.item.tokenName);
      console.log("location2", location.state.state);
    
      setColoredLogo(location.state.state.item.colouredLogo);
      setBorrowCoinId(location.state.state.item.id);
      setChosenExchangeSymbol(location.state.state.item.exchangeSymbol)
      extractCoinFromIndexForCollateralOnLoad(location.state.state.index);

      setLoanTypeId(location.state.state.extractFirstLoan.id);
      setLoanInitialLTV(location.state.state.extractFirstLoan.initialLTV)
      setLiquiLTV(location.state.state.extractFirstLoan.liquidationLTV)
      setLoanType(0);
    }
  }, [location]);

  useEffect(()=>{
    console.log('allLoans' , allLoans)
    if(allLoans !== undefined && allLoans.data.length > 0  ){
        
      setAllInterest(allLoans.data);
      }
  } , [allLoans])
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (checkBox === false) {
      setIsValid3("Please agree to terms and conditions applied!");
    } else {
      setValidated(true);
      const data = {
        walletId: Number(userInfo.walletId),
        borrowCoinId: Number(borrowCoinId),

        loanTypeId: Number(loanTypeId),

        borrowCoinAmount: amount,

        collateralCoinId: Number(collateralCoinId),

        collateralCoinAmount: collateral,

        amountType: AmountType,
        openSuccess: () => setShowSuccess(true),
      };
      borrowLoanAsyncCalled(data);
    }
  };
  // useEffect(() => {
  //   if (loanType !== "") {
  //     setCoinError("");
  //   }
  // }, [loanType]);

  useEffect(() => {
    if (checkBox === true) {
      setIsValid3("");
    }
  }, [checkBox]);

  // useEffect(() => {
  //   if (collateralCoin !== "") {
  //     setCollateralCoinError("");
  //   }
  // }, [collateralCoin]);

  useEffect(() => {
    console.log("collater", collaterConvoAmount);
    if (collaterConvoAmount !== undefined) {
      setAmountLoader(false);
      setCollateralLoader(false);
      setAmount(collaterConvoAmount.borrowAmount);
      setCollateral(collaterConvoAmount.collateralAmount);
      setAmountType(collaterConvoAmount.amountType);
      setLoanInitialLTV(collaterConvoAmount.initialLTV)
      setLiquiLTV(collaterConvoAmount.liquidityLTV);
    }
    //  else {
    //   clearConversionRates();
    // }
  }, [collaterConvoAmount]);

  const clearConversionRates = () => {
    clearConversionAmountAsync();
    // setAmount("")
    // setCollateral("")
    // setLoanType("")
    // setLoanTypeId("")
    // setCollateralCoinId("")
  };

  const extractCoinFromIndexForCollateralOnLoad = (index) => {
    // console.log('passed index' , index)
    let modifiedVal = (index + 1) % getplatformTokensinfo.length;
    // console.log('modified val' , modifiedVal)
    if (getplatformTokensinfo !== undefined) {
      let collatId = getplatformTokensinfo[modifiedVal].id;
      let collatName = getplatformTokensinfo[modifiedVal].exchangeSymbol;
      setCollateralCoinId(collatId);
      setCollaterlCoin(modifiedVal);
      setCollateralCoinName(collatName);
      console.log("colllllll", collatId);
    }
  };

  const handleFirstFieldBlur = (e) => {
    if (amount !== "" || collateral !== "") {
      console.log("inside");
      const data = {
        borrowCoinId: Number(borrowCoinId),

        collateralCoinId: Number(collateralCoinId),

        amount: AmountType === "borrow" ? amount : collateral,

        //amountType: amount !== "" ? "borrow" : "collateral",
        amountType: AmountType,

        loanTypeId: Number(loanTypeId),
      };
      console.log("coin", coin);
      collaterConversionAsync(data);
      setAmountLoader(true);
    }

    // clearConversionRates();
  };

  // const handleSecondBlur = (e)=>{

  //     const data = {
  //       borrowCoinId: Number(borrowCoinId),

  //       collateralCoinId: Number(collateralCoinId),

  //       amount: collateral,

  //       amountType: "collateral",

  //       loanTypeId: Number(loanTypeId),
  //     };
  //     console.log("coin", coin);
  //     collaterConversionAsync(data);
  //     setCollateralLoader(true);

  //   // clearConversionRates();
  // }
  useEffect(() => {
    if (collaterConvoAmount !== undefined) {
      clearConversionAmountAsync();
      setAmount("");
      setCollateral("");
    }
  }, [collateralCoin , loanType]);

  const getBal = (cur) => {
    console.log("getfiat balance", myaccountandbalance);
    // let obj2 =
    //   getFiatBalanceinfo &&
    //   getFiatBalanceinfo.filter((item) => item.currencyId == cur);
    // let reelbal = _.get(obj2, "[0].balance", 0);
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal2 = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);

    return BigNumber(reelbal2).dividedBy(10 ** decimals);
  };

  let balances = BigNumber(getBal(collateralCoinId)).toFixed(
    6,
    BigNumber.ROUND_FLOOR
  );

  // useEffect(()=>{
  //   if(compareUserAmount === true){
  //   setUserAmount(false);
  //   }
  // } , [collateralCoin , amount , loanType])

  useEffect(() => {
    if (borrowCoinId === collateralCoinId) {
      setCoinSameError("You are not allowed to select same currency");
    } else {
      setCoinSameError("");
    }
  }, [borrowCoinId, collateralCoinId]);

  return (
    <ScreenLayout
    //  loaderOnRefresh={hideLoaderOnRefresh}
    >
      <Container>
        <div id="RequestLoan">
          <AdminLoanSuccesfulPopup
            setShowModal={setShowSuccess}
            showModal={successPopup}
            scope={"loanRequest"}
            message={
              "Loan Request submitted successfully! You can check Loan Request Page to check further details"
            }
          />
          <Form noValidate onSubmit={handleSubmit} validated={validated}>
            {(borrowRequestLoading || allLoanLoading)&& <Loader />}
            <Row>
              <Col lg={12}>
                <div className="trouter">
                  <div className="transactiontitle">
                    <span className=" title">{t("Borrow")}</span>
                  </div>
                </div>
              </Col>
              <Col lg={12} className="colspace">
                <div className="titlepass">
                  <span className="titleText">{t("Loan Type")}</span>
                </div>

                <div className="inputboxpass">
                  <div className="top-dropdown">
                    <DropdownSearch
                      Selected={loanType}
                      loan={allInterestRates}
                      getLoanTypeId={(e) => setLoanTypeId(e)}
                      getLoanInitialLTV={(e)=>setLoanInitialLTV(e)}
                      getLoanLiquiLTV={(e)=>setLiquiLTV(e)}
                      setSelectedIndex={(e) => {
                        // console.log('event' , e)
                        setLoanType(e);
                      }}
                    />
                  </div>
                </div>
                <div className="titlepass">
                  <span className="errortext">{coinError}</span>
                </div>
              </Col>
              <Col lg={12} className="colspace">
                <div className="titlepass">
                  <span className="titleText">{t("I want to borrow")}</span>
                </div>

                <div className="inputboxpass">
                  <InputField
                    type="number"
                    value={amount}
                    required
                    isInvalid={validated === false && amount === ""}
                    onBlur={(e) => handleFirstFieldBlur(e)}
                    // disabled={collateral !== ""}
                    error={"Amount cannot be empty"}
                    onChange={(e) => {
                      //  setBorrowAmount(e.target.value)
                      handleInputChange(e);
                    }}
                  />

                  <div className="input-drop-down">
                    {chosenCoin !== undefined && (
                      <span className="coinicon">
                        <img
                          src={`${process.env.REACT_APP_IMGLINK}${coloredLogo}`}
                          className="coin-img"
                        ></img>
                      </span>
                    )}
                    <span className="cointext">{chosenCoin}</span>
                    <span className="cointag">{tag !== undefined && tag}</span>
                  </div>
                  {!isValid && (
                    <div className="titlepass">
                      <span className="errortext">
                        Please enter a non-negative number.
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={12} className="colspace">
                <div className="titlepass">
                  <span className="titleText">{t("Collateral")}</span>
                </div>

                <div className="inputboxpass">
                  <InputField
                    type="number"
                    value={collateral}
                    required
                    onBlur={(e) => {
                      handleFirstFieldBlur(e);
                      // setUserAmount(true);
                    }}
                    isInvalid={validated === false && collateral === ""}
                    error={"Collateral Amount cannot be empty"}
                    // disabled={amount !== ""}
                    onChange={(e) => {
                      handleInputChange2(e);
                    }}
                  />

                  <div className="input-dropdown">
                    <DropdownSearch
                      Selected={collateralCoin}
                      options={getplatformTokensinfo}
                      scope={"loan"}
                      getCollateralCoinName = {(e)=>setCollateralCoinName(e)}
                      setCoinId={(e) => setCollateralCoinId(e)}
                      setSelectedIndex={(e) => {
                        setCollaterlCoin(e);
                      }}
                    />
                  </div>
                  {/* <LoaderSmall/> */}
                </div>
                {collateralLoader && (
                  <Spinner animation="grow" className="spincolor" />
                )}
                {!isValid2 && (
                  <div className="titlepass">
                    <span className="errortext">
                      Please enter a non-negative number.
                    </span>
                  </div>
                )}
              </Col>
              <Col lg={12}>
                {collaterConvoAmount !== undefined &&
                  collaterConvoAmount.interestRate !== undefined && (
                    <div className="section">
                      <span className="left">Annual Interest Rate</span>
                      <span className="right">
                        {collaterConvoAmount.interestRate}%
                      </span>
                    </div>
                  )}
              </Col>
              {/* <Col lg={12}>
                <div className="section">
                  <span className="left">Estimated Hourly Interest</span>
                  <span className="right">0.0000025 ETH</span>
                </div>
              </Col> */}
              <Col lg={12}>
                {collaterConvoAmount !== undefined &&
                  collaterConvoAmount.liquiditionPrice !== undefined && (
                    <div className="section">
                      <span className="left">Liquidation Price({chosenExchangeSymbol}/{collateralCoinName})</span>
                      <span className="right">
                        {collaterConvoAmount.liquiditionPrice}
                      </span>
                    </div>
                  )}
              </Col>
              <Col lg={12}>
                <div className="section">
                  <span className="left">
                    Initial LTV :{" "}
                    {loanInitialLTV !== undefined && loanInitialLTV}
                    %
                  </span>
                  <span className="right">
                    Liquidation LTV :{" "}
                    {loanLiquiLTV !== undefined && loanLiquiLTV}
                    %
                  </span>
                </div>
              </Col>
              <Col lg={12}>
                <Form.Group className="form-check">
                  <Form.Check
                    type="checkbox"
                    id="checkbox-agreement"
                    label="I have read and I agree to Binance Loan Service Agreement"
                    onChange={() => handleCheckChange()}
                    checked={checkBox}
                  />
                </Form.Group>
                <Col>
                  {isValid3 !== "" && (
                    <div className="titlepass">
                      <span className="errortext">{isValid3}</span>
                    </div>
                  )}
                  {!BigNumber(balances).isGreaterThanOrEqualTo(
                    Number(collateral)
                  ) && (
                    <div className="titlepass">
                      <span className="errortext">
                        {t("InsufficientBalance")}
                      </span>
                    </div>
                  )}
                </Col>
              </Col>
              <Col>
                <div className="titlepass">
                  <span className="errortext">
                    {borrowError !== undefined && borrowError}
                  </span>
                  <span className="errortext">
                    {coinSameError !== "" && coinSameError}
                  </span>
                </div>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                {amountLoader && (
                  <Spinner animation="grow" className="spincolor" />
                )}
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <div className="cancel">
                  <CustomButton
                    name={t("Borrow")}
                    filled={true}
                    primary={true}
                    disable={
                      !BigNumber(balances).isGreaterThanOrEqualTo(
                        Number(collateral)
                      ) || borrowCoinId === collateralCoinId
                    }
                    type="submit"
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
      <FooterCommon />
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {}, user = {}, fiat = {} }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const requestLoanLoad = _.get(wallet, "addLoanRequestLoading", false);
  const allLoanLoading = _.get(wallet , "allLoanTypeInAdminLoading" , false);
  const loanTypeByAdmin = _.get(wallet, "allLoanTypesInAdmin", undefined);
  const requestLoanError = _.get(wallet, "addLoanRequestError", undefined);
  const userInfo = _.get(user, "userInfo", "");
  const collateralConversionLoading = _.get(
    wallet,
    "collateralConversionLoading",
    false
  );
  const collaterConvoAmount = _.get(wallet, "collateralConversion", undefined);
  const collaterConvoError = _.get(
    wallet,
    "collateralConversionError",
    undefined
  );
  const allLoans = _.get(wallet , "allLoanTypes" , undefined);
  const borrowRequestLoading = _.get(wallet, "addLoanRequestLoading", false);
  const borrowError = _.get(wallet, "addLoanRequestError", undefined);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);
  return {
    allLoans,
    getplatformTokensinfo,
    requestLoanLoad,
    loanTypeByAdmin,
    requestLoanError,
    collateralConversionLoading,
    collaterConvoAmount,
    collaterConvoError,
    borrowRequestLoading,
    borrowError,
    userInfo,
    myaccountandbalance,
    allLoanLoading,
    getFiatBalanceinfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  borrowLoanAsyncCalled: (data) =>
    dispatch({ type: "addLoanRequestSagaCalled", payload: data }),
    allLoanTypeAsync : (data)=>dispatch(
    
      {type : "listAllLoanSupportedInAdminSagaCalled" , payload : data}),
  collaterConversionAsync: (data) =>
    dispatch({ type: "collateralConversionSagaCalled", payload: data }),
  clearConversionAmountAsync: () => dispatch(clearConversionRedux()),
});
export default connect(mapStateToProps, mapDispatchToProps)(RequestLoan);
