import * as _ from "lodash";
import React, { useEffect } from "react";
import "./LoanRequestDetailPopup.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
import moment from "moment";
import copy from "@images/copy1.svg";
import share from "@images/share1.svg";
// import moment from "moment";
import { useTranslation } from "react-i18next";

const LoanRequestPopupDetail = ({ setShowModal, show1, data, setData }) => {
  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="LoanRequestPopupDetail"
      show={show1}
      // onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      <div
        className=" back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
        <div className="transectiontype">
          <span className="transectiontext uppercase">
            {t(`Loan Request Details`)}{" "}
          </span>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Request Status")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" && data.adminApproval === false
                  ? "Pending"
                  : "Approved"}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Borrowed Amount")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.borrowedAmount !== undefined &&
                  data.borrowedCoin !== undefined &&
                  `${data.borrowedAmount} ${data.borrowedCoin.tokenName}`}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Collateral Amount")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.collateralCoin !== undefined &&
                  data.collateralCoin.tokenName !== undefined &&
                  data.lockedCapitalAmount !== undefined &&
                  `${data.lockedCapitalAmount} ${data.collateralCoin.tokenName}`}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Loan Name")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.loanType !== undefined &&
                  data.loanType.loanName !== undefined &&
                  `${data.loanType.loanName}`}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Tenure (In Hours)")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  data.loanType !== undefined &&
                  data.loanType.tenureInHours !== undefined &&
                  data.loanType.tenureInHours}
              </span>
            </div>
          </div>
          {data !== undefined && data.user !== undefined && (data.user.name !== null) &&
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Borrower Name")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" && data.user !== undefined &&
                  `${data.user.name} ${data.user.subName !== null && data.user.subName}`  }
              </span>
            </div>
          </div>
}
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Creation Date")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {data !== "" &&
                  moment(data?.createdOn).local().format("MM/DD/YYYY hh:mm A")}
              </span>
            </div>
          </div>
          <div className="confirm">
            <CustomButton
              type="button"
              name={t("Close")}
              filled={true}
              primary={true}
              onClick={() => {
                setData("");
                setShowModal(false);
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(LoanRequestPopupDetail);
