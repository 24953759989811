export default {
  TransferHistory: "История Транзакций",
  TransactionDetails: "Детали передачи",
  TransactionId: "ID Транзакции",
  Date: "Дата",
  From: "От",
  To: "Кому",
  Coin: "Монета",
  Amount: "Сумма",
  Status: "Статус",
  Showing: "Показано",
  from: "из",
  Deposit: "Пополнения",
  Withdrawals: "Выводы",
  ExchangeID: "ID Обмена",
  AmountReceived: "Полученная Сумма",
  completed: "Завершено",
  ExchangeHistory: "История Обменов",
  COMPLETED: "Завершено",
  FAILED: "Ошибка",
  PENDING: "Ожидает",
  ReplenishmentBankTransfer: "Пополнения через банковский перевод",
  ReceiverName: "Имя получателя",
  RecipientOfMoneyTransfers:
    "является получателем денежных переводов через EFT. следует ввести как",
  Remaining24hourDepositLimit: "Ваш оставшийся 24-часовой лимит пополнения",
  Remaining30dayDepositLimit: "Оставшийся 30-дневный лимит пополнений",
  TransferLimitMessage:
    "Вы можете переводить TL на банковские счета CyborPay в пределах дневного лимита поплнения без ограничения по минимальной сумме.",
  TransferPossible: "Перевод возможен",
  Days: "дней",
  Hours: "часов",
  Next: "Далее",
  SelectBankError:"Пожалуйста, выберите банк",
  
  OnWeekdays: "на выходных",
  ReceiveSMS: "Получить банковскую информацию по SMS",
  AddBankConfirmed: "Добавление банка подтверждено!",
  BankAccountFromWhichDeposit: "Банковский счет с которого Вы будете совершать пополнение",
  SelectBankAccount: "Выберите банковский счет",
  DefineNewBankAccount: "Добавьте новый банковский счет",
  AmountToDeposit: "Сумма, на которую Вы хотите пополнить",
  TransactionFee: "Комиссия",
  Included: "включена",
  DepositAmount: "Сумма пополнения",
  Cryptocurrency:"Crypto Currency",
  СheckWithBankTransfer: "Проверить с помощью банковского перевода",
  SeeFullBalance: "Если Вы не видите свой полный баланс, проверьте свои",
  OpenOrders: "открытые ордеры.",
  Remaining24hourWithdrawalLimit: " Ваш оставшийся 24-часовой лимит снятия (вывода) средств",
  Remaining30dayWithdrawalLimit: "Ваш оставшийся 30-дневный лимит снятия (вывода) средств",
  BankTransferValidForWithdrawals:
    "Банковский перевод действителен только для снятия",
  OrMore: "или более",
  IBANInfromation:
    "Номер IBAN Вашего банковского счета, на который Вы положили турецкую лиру в CyborPay, будет автоматически добавлен.",
  SubmitPullRequest: "Отправить запрос на перевод (вывод)",
  BankAccount: "Банковский Счет",
  MyBankAccount: "Мой Банковский Счет",
  AddNew: "Добавить новый...",
  BankAccountsRegistered:
    "Банковские счета, зарегистрированные для вашей учетной записи, следующие:",
  DontHaveLinkedBankAccounts: "У вас еще нет привязанных банковских счетов.",
  AddNewBankAccount: "Добавить новый Банковский Счет",
  SelectBank: "Выберите Банк",
  AccountNumber: "Номер Счета",
  EnterAccountNumber: "Пожалуйста, введите номер счета",
  AccountHolderName: "Имя владельца счета",
  EnterAccountHolderName: "Пожалуйста, введите имя владельца счета",
  EnterIBAN:"Пожалуйста, введите номер IBAN",
  EnterSWIFT:"Пожалуйста, введите номер SWIFT",
  EnterIFSC : "Пожалуйста, введите номер IFSC",
  Number: "Номер",
  Submit: "Подтвердить",
  BankTransferLine1: "Ваш оставшийся 24-часовой лимит снятия средств",
  BankTransferLine2: "Ваш оставшийся 30-дневный лимит снятия средств",
  BankTransferLine3: "Банковский перевод действителен только для снятия",
  BankTransferLine4: "Информация IBAN вашего банковского счета, на который вы внесли турецкие лиры в BtcTurk, будет автоматически добавлена ​​в список банков, из которых вы можете снять TL." ,
  BankTransferLine4_1:"Информация IBAN вашего банковского счета, на который вы внесли депозит",
  BankTransferLine4_2: "в BtcTurk автоматически добавится в список банков, из которых можно снять",
  SelectNewBankAccount: "Выберите новый банковский счет",
  ExchangedCoins: "Обмен монет",
  ExchangedAmount: "Сумма обмена",
  Link: "ссылка",
  AmountPaid:"Выплаченная сумма",
  AccountNumber: " Введите номер счета ",
  AccountHolderNumber:"Введите номер владельца счета",
  IBANNumber:"Введите номер IBAN",
  SWIFTNumber:"Введите номер SWIFT",
  IFSCNumber:"Введите номер IFSC",
  AccountCreatedSuccessfully:"Аккаунт успешно создан",
  MonthlyDepositlimit: "Ваш оставшийся 30-дневный лимит депозита",
  BankTransferValidForDeposit: "Банковский перевод действителен только для депозита ",
  FiatWithdrawalRequest: "Запрос на вывод фиата",
  Fiatwithdrawapplicationsent: "Заявка на вывод фиата отправлена.",
  SelectCurrencyError:"Пожалуйста, выберите валюту",
  SelectBankError:"Пожалуйста, выберите банк",
  SelectCurrencyError:"Пожалуйста, выберите валюту",
  SelectedBank: "Выбранный банк",
  Surname: "Фамилия",
  TType:"Тип сделки",
  Sender:"Отправитель",
  Receiver:"Приёмник",
};
