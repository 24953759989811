import { call, put } from "redux-saga/effects";
import {
  notificationSuccess,
  notificationCount,
  notificationFailure,
} from "./notificationredux";
import Api from "./../services/ApiCaller";
import { toast } from "react-toastify";
import { getAccessToken } from "./utilities";

export function* getnotification(api, { payload = {} }) {
  
  try {
    const { skip, limit,   } = payload;
    const token = yield getAccessToken();
     const result = yield call(
      Api.callServer,
      api.getNotification,
      { token,  skip:0, limit:20  },
      true
    );
    
    yield put(notificationSuccess(result));
  } catch (error) {
    console.log(error);
   }
     
}

export function* getnotificationCount(api, { payload = {} }) {
  try {
    const { skip, limit,   } = payload;
    const token = yield getAccessToken();
     const result = yield call(
      Api.callServer,
      api.getNotificationcount,
      { token, },
      true
    );
    
    yield put(notificationCount(result));
  } catch (error) {
    console.log(error);
   } 
   
     
}

export function* readnotification(api, { payload = {} }) {
  yield put(notificationCount(0)) 

   try {
    const { skip, limit,   } = payload;
    const token = yield getAccessToken();
     const result = yield call(
      Api.callServer,
      api.markallread,
      { token, },
      true
    );
    const result2 = yield call(
      Api.callServer,
      api.getNotificationcount,
      { token, },
      true
    );
    yield put(notificationCount(result2)) 
  } catch (error) {
    //console.log(error.message)
    }
     
}