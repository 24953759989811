export default {
  All: "Tüm",
  Received: "Alınan",
  Sent: "Gönderilen",
  Deposits: "Yatırımlar",
  Withdrawals: "Çekimler",
  Captured: "Tamamlanan",
  Pending: "Bekleyenler",
  Cancelled: "İptal edilenler",
  Transfer: "Gönder",
  Round: "Tur",
  Crypto: "Kripto",
  Fiat: "Fiat",
  Rejected: "Reddedildi",
  Approved: "Onaylandı",
  Business: "İşletme",
  Personal: "Kişisel",
  Business: "İşletme",
  Personal: "Kişisel",
  MyProfile: "Profilim",
  Myaccounts: "Hesaplarım",
};
