import React, { useEffect, useState } from "react";
import "@components/modern-bg-card/ModernBgCardStyles.scss";
import { ReactComponent as GraphIncreased } from "@images/graphincreased.svg";
import { ReactComponent as REDGraph } from "@images/redgraph.svg";
import { ReactComponent as UpArrow } from "@images/uparrowgreen.svg";
import CustomButton from "../custom-button/CustomButton";
import BigNumber from "bignumber.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import IdoBuyPopup from "src/components/ido-buy-popup/IdoBuyPopup";
import BuyPopup from "src/components/buy-popup/BuyPopup";
import { getCSymbol, getESymbol } from "src/utils/Currencies";

const ModernBgCard = ({
  selectedCoin,
  marketdata,
  getplatformTokensinfo,
  myaccountandbalance,
  idoBalance,
  cryptoWallet,
  fromIdo,
  setIdobuyModal,
  prctChg,
  idobuyModal,
  buyModal,
  setbuyModal,
  fiatrate,
  PreferredCurrency,
}) => {
  const [logoBgColor, setLogoBgColor] = useState("");
  useEffect(() => {
    const logobgcolor = selectedCoin?.coin?.logoBgColor?.includes("\r")
      ? selectedCoin?.coin?.logoBgColor?.split(`\r`)[0]
      : selectedCoin?.coin?.logoBgColor;
    setLogoBgColor(logobgcolor);
  }, [selectedCoin]);
  const { t } = useTranslation();
  return (
    <div className={`modernBgCard ${!cryptoWallet && "cyborModernBgCard"}`}>
      <div className={`m-coinnamewrapper ${!fromIdo && "d-none"} d-lg-flex`}>
        {(fromIdo && (
          <p className="m-coinname m-coinwhenfromido">{t("IDOCyborCoin")}</p>
        )) || (
          <p className="m-coinname">
            {selectedCoin?.coin?.exchangeSymbol}{" "}
            {(selectedCoin?.coin?.type == "TRC20" ||
              selectedCoin?.coin?.type == "BEP20" ||
              selectedCoin?.coin?.type == "ERC20") &&
              selectedCoin?.coin?.type}
            {""} {t("Balance")}
          </p>
        )}
      </div>
      <IdoBuyPopup
        show1={idobuyModal}
        setShowModal={(e) => setIdobuyModal(e)}
        getplatformTokensinfo={getplatformTokensinfo}
        myaccountandbalance={myaccountandbalance}
      />
      <BuyPopup
        show1={buyModal}
        setShowModal={(e) => setbuyModal(e)}
        getplatformTokensinfo={getplatformTokensinfo}
        myaccountandbalance={myaccountandbalance}
        selectedCoin={selectedCoin && selectedCoin}
      />
      <div
        className={`m-bgwraper ${fromIdo && "m-bgwrapfromido"}`}
        style={{
          background: logoBgColor,
        }}
      >
        {!fromIdo && (
          <div className="m-coinnamewrapper d-flex d-lg-none">
            {(fromIdo && <p className="m-coinname">{t("IDOCyborCoin")}</p>) || (
              <p className="m-coinname">
                {selectedCoin?.coin?.exchangeSymbol}{" "}
                {(selectedCoin?.coin?.type == "TRC20" ||
                  selectedCoin?.coin?.type == "BEP20" ||
                  selectedCoin?.coin?.type == "ERC20") &&
                  selectedCoin?.coin?.type}
                {""} {t("Balance")}
              </p>
            )}
          </div>
        )}

        <div className="formainbg">
          <img
            src={`${process.env.REACT_APP_IMGLINK}${selectedCoin?.coin?.nonColouredLogo}`}
            alt=""
          />
        </div>

        <div className="m-coininfo">
          {!cryptoWallet && (
            <div className="m-currentwrapper">
              <p className="m-current">{t("TotalValueLocked")}</p>
              <div className="m-imgamtwrapper">
                <img
                  src={`${process.env.REACT_APP_IMGLINK}${selectedCoin?.coin?.nonColouredLogo}`}
                  alt="coinlogo"
                  className="m-smalllogo"
                />
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 500 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {BigNumber(idoBalance && idoBalance.count).toFixed(2,BigNumber.ROUND_FLOOR)}{" "}
                    </Tooltip>
                  )}
                >
                  <span className="m-mainamount">
                    {BigNumber(idoBalance && idoBalance.count).toFixed(2,BigNumber.ROUND_FLOOR)}
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          )}
          <div className="m-currentwrapper">
            <p className="m-current">{t("Current")}</p>
            <div className="m-imgamtwrapper">
              <img
                src={`${process.env.REACT_APP_IMGLINK}${selectedCoin?.coin?.nonColouredLogo}`}
                alt="coinlogo"
                className="m-smalllogo"
              />
              <OverlayTrigger
                placement="top"
                delay={{ show: 0, hide: 500 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {BigNumber(selectedCoin && selectedCoin.amount)
                      .dividedBy(
                        10 **
                          (selectedCoin &&
                            selectedCoin.coin &&
                            selectedCoin.coin.decimals)
                      )
                      .toFixed(2,BigNumber.ROUND_FLOOR)}
                  </Tooltip>
                )}
              >
                <span className="m-mainamount">
                  {BigNumber(selectedCoin && selectedCoin.amount)
                    .dividedBy(
                      10 **
                        (selectedCoin &&
                          selectedCoin.coin &&
                          selectedCoin.coin.decimals)
                    )
                    .toFixed(2)}
                </span>
              </OverlayTrigger>
            </div>
          </div>
          {cryptoWallet && (
            <div className="m-currentwrapper m-currentmarket">
              <div className="marketrategraphwrap">
                <p className="m-current">{t("MarketRate")}</p>
                <p className="ms-lg-2 ms-0 m-marketrate">
                  {prctChg.includes("-") ? (
                    <REDGraph className="graphincreaseicon" />
                  ) : (
                    <GraphIncreased className="graphincreaseicon" />
                  )}
                  <span
                    className={`coinpercentchange ${
                      prctChg.includes("-") ? "redtext" : "greentext"
                    }`}
                  >
                    {marketdata && marketdata.priceChangePercent}%
                  </span>
                </p>
              </div>
              <div className="m-imgamtwrapper">
                <img
                  src={`${process.env.REACT_APP_IMGLINK}${selectedCoin?.coin?.nonColouredLogo}`}
                  alt="coinlogo"
                  className="m-smalllogo"
                />
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 500 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {selectedCoin && selectedCoin.tORcName !== "TRYCoin" ? (
                        <span className="m-mainamount">
                          {`(${getCSymbol(PreferredCurrency)})`}
                          {BigNumber(
                            (marketdata && marketdata.bidPrice) || 0
                          ).toFixed(2,BigNumber.ROUND_FLOOR)}
                        </span>
                      ) : (
                        <span className="m-mainamount">
                          {" "}
                          {`(${getCSymbol(PreferredCurrency)})`}
                          {BigNumber(1)
                            .dividedBy(marketdata && marketdata.bidPrice)
                            .multipliedBy(
                              (fiatrate &&
                                fiatrate[getESymbol(PreferredCurrency)]&& fiatrate[getESymbol(PreferredCurrency)].value) ||
                                1
                            )
                            .toFixed(2, BigNumber.ROUND_FLOOR)}{" "}
                        </span>
                      )}
                    </Tooltip>
                  )}
                >
                  {selectedCoin && selectedCoin.tORcName !== "TRYCoin" ? (
                    <span className="m-mainamount">
                      {`(${getCSymbol(PreferredCurrency)})`}
                      {BigNumber(
                        (marketdata && marketdata.bidPrice) || 0
                      ).toFixed(2)}
                    </span>
                  ) : (
                    <span className="m-mainamount">
                      {" "}
                      {`(${getCSymbol(PreferredCurrency)})`}
                      {BigNumber(1)
                        .dividedBy(marketdata && marketdata.bidPrice)
                        .multipliedBy(
                          (fiatrate &&
                            fiatrate[getESymbol(PreferredCurrency)]&& fiatrate[getESymbol(PreferredCurrency)].value) ||
                            1
                        )
                        .toFixed(2, BigNumber.ROUND_FLOOR)}{" "}
                    </span>
                  )}
                </OverlayTrigger>
              </div>
            </div>
          )}
          {!cryptoWallet && (
            <div className="m-currentwrapper m-bonuswrapper">
              <div className="marketrategraphwrap">
                <p className="m-current">{t("Bonus")}</p>
                <p className="ms-2 greentext bonustext">
                  <UpArrow />
                  <span>+0,0005 CYBOR {t("Today")}</span>
                </p>
              </div>
              <div className="m-imgamtwrapper">
                <img
                  src={`${process.env.REACT_APP_IMGLINK}${selectedCoin?.coin?.nonColouredLogo}`}
                  alt="coinlogo"
                  className="m-smalllogo"
                />
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 500 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      0.00
                    </Tooltip>
                  )}
                >
                  <span className="m-mainamount">0.00</span>
                </OverlayTrigger>
              </div>
            </div>
          )}
          <div className="m-currentwrapper m-btnwrapper d-lg-block d-none">
            <CustomButton
              // disable={cryptoWallet == true ? false : true}
              name={t("Buy")}
              filled={true}
              primary={false}
              className="buttonmain"
              onClick={() => {
                //if (cryptoWallet) 
                
                setbuyModal(true);
               // else setIdobuyModal(true);
              }}
            />
          </div>
        </div>
        <div className="m-currentwrapper m-btnwrapper d-block d-lg-none">
          <CustomButton
        ///    disable={cryptoWallet == true ? false : true}
            name={t("Buy")}
            filled={true}
            primary={false}
            className="buttonmain"
            onClick={() => {
         setbuyModal(true);
          //    else setIdobuyModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ ido, wallet, app, user }) => {
  // const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const idoBalance = _.get(ido, "idoBalance", 0);
  const idobuyModal = _.get(app, "idobuyModal", false);
  const buyModal = _.get(app, "buyModal", false);
  const role = _.get(user, "userInfo.role", "");
  const fiatrate = _.get(app, "fiatrate", false);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");
  // const myaccountandbalance = _.get(
  //   wallet,
  //   "myaccountandbalancebywalletidinfo",
  //   []
  // );
  const idorounds = _.get(ido, "idorounds", []);

  return {
    //   getplatformTokensinfo,
    role,
    idoBalance,
    idobuyModal,
    //   myaccountandbalance,
    buyModal,
    idorounds,
    fiatrate,
    PreferredCurrency,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setIdobuyModal: (data) =>
    dispatch({ type: "setIdobuyModalCalled", payload: data }),
  setbuyModal: (data) => dispatch({ type: "buyModalCalled", payload: data }),

  getRountido: (data) => dispatch({ type: "getRountidocalled", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModernBgCard));
