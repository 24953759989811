export default {
    Payments: "Платежи",
    CreatePaymentLink: "Создать ссылку для оплаты",
    Print: "Печать",
    Excel: "Excel",
    PaymentID: "ID Платежа",
    Date: "Дата",
    PaymentLink: "Ссылка для оплаты",
    CoinToAccept: "Монета для получения",
    Amount: "Сумма",
    CoinToConvert: "Монета для конвертирования",
    Status: "Статус",
    CopyPaymentLink: "Скопировать ссылку для оплаты",
    Details: "Детали",
    Showing: "Showing",
    From: "от",
    Completed: "Завершено",
    Cancelled: "Отменено",
    Pending: "Ожидает",
    failed: "НЕ УДАЛОСЬ",
    "transfer": "Перечислить",
    copied: "Скопировано !",
    YOUR_BUSINESS_ACCOUNT_REQUEST_IS_NOT_APPROVED:
      "Запрос активации Вашего бизнес-аккаунта не одобрен",
    CurrencyToConvert: "Валюта для конвертации",
    CryptoCurrencyToPay: "Криптовалюта (для оплаты)",
    CryptoCurrencyToConvert: "Криптовалюта для конвертации",
    PaymentLinkwillbecreated: "Ссылка для оплаты будет создана",
    Showonscreen: "Показать на экране",
    CopyAddress: "Копировать адрес",
    LinkCreatedSuccessfully: "Ссылка успешно создана",
    PDF : 'PDF',
    WantToCancel: "Вы хотите отменить этот платеж?",
    ClientPays: "Клиент оплачивает",
    CurrencytoConvert: "Валюта для конвертации",
    CurrencytoReceive: "Валюта для получения",
    PAYMENT_DETECTED: "Платеж обнаружен",
    PAYMENT_REQUESTED: "ожидается платеж",
    PAY_CYWALL: "Оплатить с помощью PayCryptwallet",
    PAY_OTWALL:`Оплатить с помощью другого кошелька`,
    LPAY_CYWALL: "Войти для оплаты с помощью PayCryptwallet",
  };
  