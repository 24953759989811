import React, { useEffect, useState } from "react";
import SearchBox from "@components/search-box/SearchBox";
import BankListItem from "src/components/bank-list-item/BankListItem";
import "@components/bank-list-layout/BankListLayoutStyles.scss";
import { connect } from "react-redux";
import * as _ from "lodash";


function BankListLayout({ platformBanks, getPlatformBank, setshowInfo }) {
 
  const [ownerBank,setOwnerBank] = useState([]);
  const getFilteredBank = ()=>{
    let ownerBank = platformBanks && platformBanks.filter(bank => bank.ownersBankAccount.length !== 0)
    setOwnerBank(ownerBank)
    
  }
  
  useEffect(() => {
    getFilteredBank();
    
  } , [platformBanks])
  
  useEffect(() => {
    getPlatformBank();
  }, []);

 
  const [value, setvalue] = useState("");
  return (
    <div className="banklistlayout">
      <div className="topspacese">
        <SearchBox setvalues={setvalue} />
      </div>
      <div className="topspace">
        {ownerBank &&
          ownerBank
            .filter((item) =>
              item.bankName.toLowerCase().includes(value.toLowerCase())
            )
            .map((payload) => (
              <BankListItem
                setshowInfo={(e) => setshowInfo&&setshowInfo(e)}
                payload={payload}
                scope=""
              />
            ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ fiat = {}, user = {} }) => {
  const platformBanks = _.get(fiat, "platformBanks", []);

  return { platformBanks };
};

const mapDispatchToProps = (dispatch) => ({
  getPlatformBank: (data) =>
    dispatch({ type: "getPlatformBankCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankListLayout);
