import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import Filter from "@components/filter/Filter.js";
import SearchBox from "@components/search-box/SearchBox";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as RightArrow } from "@images/rightarrow.svg";
import EditBranchpopup from "@components/edit-branchpopup/EditBranchpopup.js";
import "@features/admin-loan-management/AdminLoanManagement.scss";
import EditLogo from "@images/branchmanagement/Edit.svg";
import DeleteLogo from "@images/branchmanagement/Delete.svg";
// import "./BranchManagementStyle.scss";
import DeleteConfirmationPopup from "@components/delete-confirmationpopup/DeleteConfirmationPopup";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import EditLoanType from "src/components/edit-loan-type/EditLoanType";
import DeleteAdminLoanManagementPopup from "src/components/delete-admin-loan-management-popup/DeleteAdminLoanManagementPopup";
import AdminLoanSuccesfulPopup from "src/components/admin-loan-template-successfull-popup/AdminLoanSuccesfulPopup";
import CoinList from "src/components/coin-list/CoinList";
import {clearplatformerroSuccess} from '@store/platformuserredux';

function AdminLoanManagement({ 
    settings,
  
  allLoanLoading,
  allLoans,
  allLoanError,
  deleteLoanTypeLoading,
  deleteLoanError,
  allLoanTypeAsync,
  deleteLoanTypeAsync,
  clearBackendError,
}) {
  
  const history = useHistory();
  
  let [deleteId, setDeleteId] = useState();

  let [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  let [editBranchPopup, setEditBranchPopup] = useState(false);
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [skip , setskip] = useState(0);
  let [editingDetails  , setEditingDetails] = useState();
  let [edited , setEditedSuccesfully] = useState(false);
  let [deleteSuccess , setDeleted] = useState(false);
  

  useEffect(()=>{
    console.log('called')
    allLoanTypeAsync({skip , limit  })
  } , [])

  

  const DeleteLoan = (id) => {
    setShowDeleteConfirmation(true);
    setDeleteId(id);
  };

  const EditLoan = (branchDetails) => {
    setEditBranchPopup(true);
    setEditingDetails(branchDetails)
  };

  const { t } = useTranslation();

  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      // loaderOnRefresh={hideLoaderOnRefresh}
    >
      <div id="AdminLoanManagement">
        <DeleteAdminLoanManagementPopup
          showDeleteConfirmation={showDeleteConfirmation}
          setShowDeleteConfirmation={setShowDeleteConfirmation}
          deleteError={deleteLoanError !== undefined && deleteLoanError}
          onPress={() => {
            

            deleteLoanTypeAsync({ id: Number(deleteId) , openSuccess : ()=>{
              setShowDeleteConfirmation(false);
              setDeleted(true)}
             });
          }}
          onCancelled={() => {
           
            setDeleteId();
            clearBackendError();
            setShowDeleteConfirmation(false);

          }}
        />

        <EditLoanType
          setShowModal={setEditBranchPopup}
          show1={editBranchPopup}
          details={editingDetails}
          successPopup={setEditedSuccesfully}
        />
        
        <AdminLoanSuccesfulPopup
          setShowModal={setEditedSuccesfully}
          showModal={edited}
          message={"Edited Successfully"}
        />
        <AdminLoanSuccesfulPopup
          setShowModal={setDeleted}
          showModal={deleteSuccess}
          message={"Deleted Successfully"}
        />
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("Loan Types")}</span>
                  <div className="filtersection d-none d-lg-block">
                    {/* <Filter
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="btmmargin">
                  {/* <SearchBox setvalues={setSearchName} /> */}
                </div>
                <div className="leftsection">
                  <div className="buttoncontainer">
                    <CustomButton
                      type="submit"
                      name={t("Create")}
                      filled={true}
                      primary={true}
                      onClick={() => history.push("/createLoanType")}
                    />
                  </div>
                </div>
                <div className="filtersection d-block d-lg-none"></div>
              </div>

              <Table responsive="sm" className=" tablestyle" borderless>
                {(allLoanLoading || deleteLoanTypeLoading) && <Loader />}
                {/* <Loader loading={allBranchesLoading} /> */}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading paddingleft">{t("NO")}</th>
                    <th className="trtbleheading">{t("Name")} </th>
                    {/* <th className="trtbleheading">{t("Coin")} </th> */}
                    <th className="trtbleheading">{t("Tenure (In Days)")} </th>
                    <th className="trtbleheading">{t("Initial LTV")} </th>
                    <th className="trtbleheading">{t("Liquidation LTV")} </th>
                    <th className="trtbleheading">{t("Edit")} </th>
                    <th className="trtbleheading">{t("Delete")}</th>
                  </tr>
                </thead>

                <tbody>
                  <>
                  {allLoans !== undefined && allLoans.data.length > 0 ?
                   allLoans.data.map((item)=>(
                    <tr className="trtbleheading maintdwrapper">
                      <td className="innercontent">
                        <div className="innerTd d-lg-flex d-none">{item.id}</div>
                        <div className="innerTd d-lg-none d-flex">
                              <p className="mobUpperline">
                                No{item.id} {item.loanName}
                              </p>
                              <p className="mobLowerline">
                              {Math.trunc(Number(item.tenureInHours)/24)} days
                              </p>
                            </div>
                      </td>
                      <td className="amt d-none d-lg-table-cell">
                        <div className="innerTd id smallwidth">{item.loanName}</div>
                      </td>
                      {/* <td className="amt d-none d-lg-table-cell">
                     
                        <div className="innerTd id smallwidth">{item && item.coin && item.coin.tokenName}</div>
                      </td> */}
                      <td className="innercontent d-none d-lg-table-cell">
                        <div className="innerTd smallwidth">{Math.trunc(Number(item.tenureInHours)/24)}</div>
                      </td>
                      <td className="innercontent d-none d-lg-table-cell">
                        <div className="innerTd">{item.initialLTV}</div>
                      </td>
                      <td className="innercontent d-none d-lg-table-cell">
                        <div className="innerTd">{item.liquidationLTV}</div>
                      </td>
                      <td className="innercontent">
                        {/* <div className="buttonwrapper innerTd d-none d-lg-flex"> */}
                        <div className="innerTd passive">
                          <img
                            src={EditLogo}
                            alt="Edit Logo"
                            className="editbutton"
                            onClick={() => EditLoan(item)}
                          />
                        </div>
                      </td>
                      <td className="innercontent">
                        {/* <div className="buttonwrapper innerTd d-none d-lg-flex"> */}
                        <div className="innerTd passive">
                          <img
                            src={DeleteLogo}
                            alt="Delete Logo"
                            className="editbutton"
                            onClick={() => DeleteLoan(item.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  )) :  (
                    <p className="trtbleheading">{t("Sorrynoresultsfound")}!</p>
                  )}
                    
                  </>
                </tbody>
              </Table>
              {allLoans !== undefined && allLoans.count > 10 && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(allLoans.count / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allLoans.count / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={(e) => {
                        setskip(e.selected * limit);
                          allLoanTypeAsync({
                            
                         
                            skip: e.selected * limit,
                            limit,
                          

                          })

                          setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}


            </Col>

          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({
  app = {},
  wallet = {},
 
 
}) => {
  const deleteLoanTypeLoading = _.get(wallet , "deleteLoanSupportInPlatformLoading" , false);
  const deleteLoanError = _.get(wallet , "deleteLoanSupportInPlatformError" , undefined);
  const allLoanLoading = _.get(wallet , "allLoanTypeInAdminLoading" , false);
  const allLoans = _.get(wallet , "allLoanTypesInAdmin" , undefined);
  const allLoanError = _.get(wallet , "allLoanTypesInAdminError" , undefined);
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
 
  return {
    thememode,
    settings,
    deleteLoanTypeLoading,
    deleteLoanError,
    allLoanLoading,
    allLoans,
    allLoanError,

  };
};

const mapDispatchToProps = (dispatch) => ({
  allLoanTypeAsync : (data)=>dispatch(
    
    {type : "listAllLoanSupportedInAdminSagaCalled" , payload : data}),
  deleteLoanTypeAsync : (data)=>dispatch({type : "deleteLoanSupportInSagaCalled" , payload : data}),
  clearBackendError : ()=>dispatch(clearplatformerroSuccess())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLoanManagement);
