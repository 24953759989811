import ScreenLayout from "src/components/layout/ScreenLayout";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Form, Tab } from "react-bootstrap";
import "@features/personal-settings/PersonalSetingsStyles.scss";
import "@components/mobile-settings/MobileSettings.scss";
import { ReactComponent as PencilIcon } from "@images/editpencil.svg";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ResetPasswordPopup from "@components/reset-password-popup/ResetPasswordPopup";
import { useTranslation } from "react-i18next";
import FooterCommon from "@components/footer-common/FooterCommon";
import CurrencyTab from "src/components/currency-tab/CurrencyTab";
import MyProfile from "src/components/my-profile/MyProfile";
import MyAccounts from "src/components/my-accounts/MyAccounts";
import MobileSettings from "src/components/mobile-settings/MobileSettings";

function PersonalSettings(props) {
  const [tableno, settableno] = useState('first');
  // const [tableno, settableno] = useState(props?.location?.state?.tab);
  const [default1 , setDefault] = useState(false);
  
  // useEffect(() => {
  //   console.log('table no' ,tableno)
  //   console.log('props' , props)
  //   console.log('props se jo mil rha h' ,props?.location?.state?.tab !== undefined &&props?.location?.state?.tab)
  //   settableno(props?.location?.state?.tab !== undefined ? "second" : "first")
  //   // settableno(props?.location?.state?.tab !== undefined && props.location.state.tab )
  // }, [props]);
  
  useEffect(() => {
   if(default1){
    settableno("second")
   }
   else{
     setDefault(false);
    settableno("first");
   }
  }, [default1]);

  const { t } = useTranslation();

  return (
    <ScreenLayout loaderOnRefresh={props.hideLoaderOnRefresh} >
      <>
        <Container>
          <MobileSettings tab={props?.location?.state?.tab} />
          <div id="personalsettings">
            <Row>
              <Col lg={12}>
                <div className="outercontainer d-none d-lg-block">
                  <div className="settingtitle">
                    <span>{t("Settings")}</span>
                  </div>
                  <div className="topspace align-left">
                    <CurrencyTab
                      page={"settings"}
                      changeTab={(e) => {
                        if (e == "second") {
                          settableno(e);
                        }

                        if (e == "first") {
                          settableno(e);
                        }
                      }}
                      tableno={tableno}
                    >
                      <div className="topspace" />
                      <Tab.Pane eventKey="first">
                        <MyProfile />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <MyAccounts />
                      </Tab.Pane>
                    </CurrencyTab>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <FooterCommon />
      </>
    </ScreenLayout>
  );
}

const mapStateToProps = ({ app = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  return {
    thememode,
  };
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSettings);
