import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { connect } from "react-redux";
import { Container, Modal, Row, Form, Col } from "react-bootstrap";
import InputField from "@components/input-field/InputField";
import "@components/add-bank-account-popup2/AddBankAccountPopup2.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FooterCommon from "@components/footer-common/FooterCommon";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import { Dropdown, FormControl } from "react-bootstrap";
import Accountsuccessful from "@components/account-successful/Accountsuccessful";
import Loader from "@components/loader/Loader";
import { clearBankError } from "@store/fiatredux";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import { removeCurrentBankAccount } from "@store/fiatredux";

const AddBankAccountPopup2 = ({
  showAddBankConfirmation,

  setShowAddBankConfirmation,
  onPress,
  onCancelledAsync,
  onError,
  fiatBanks,
  getAllBanksCall,
  createBankAccountCall,
  createBankError,
  exchangeId,
  fiatBalance,
  userAccessToken,
  setHideThis,
  createBankAccountLoading,
  createBankAccSuccess,
  clearError,
  plaformcurrencies,
  onSuccessfulBankCreation,
  selectedBankRedux,
  openBankAccountAddModal,
  removeCurrentBankAccountAsync,
  selectedFiatCurrencyInfo,
  setShowDeposit,
}) => {
  const { t, i18n, ready } = useTranslation();
  const [selectedBank, setSelectedBank] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [accountHolderName, setAccountHolderName] = useState("");
  const [valid, setValid] = useState(true);
  const [valid1, setValid1] = useState(true);
  const [iban, setIban] = useState("");
  const [swift, setSwift] = useState("");
  const [ifsc, setIfsc] = useState();
  const [bankOptions, setBankOptions] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [goBack, setGoBack] = useState(true);
  let [Selected, setSelectedIndex] = useState("");
  let [supportedCurrency, setSupportedCurrencies] = useState();
  let [selectedCurrency, setSelectedCurrency] = useState(0);
  let [showSuccesful, setShowSuccessful] = useState(false);
  let [showDropDownError1, setShowDropDownError1] = useState(false);

  let [showDropDownError2, setShowDropDownError2] = useState(false);

  useEffect(() => {
    getAllBanksCall();
  }, []);

  const checkCurrentBankAndSet = () => {
    if (bankOptions !== undefined) {
      if (selectedBankRedux !== undefined && bankOptions.length !== 0) {
        for (let i = 0; i < bankOptions.length; i++) {
          if (bankOptions[i].id === selectedBankRedux.id) {
            setSelectedIndex(i);
          }
        }
      }
    }
  };

  const checkCurrentFiatAndSet = (supportedCurrencies) => {
    if (
      supportedCurrencies !== undefined &&
      supportedCurrencies.length !== 0 &&
      selectedFiatCurrencyInfo !== undefined &&
      selectedFiatCurrencyInfo.id !== undefined
    ) {
      for (let i = 0; i < supportedCurrencies.length; i++) {
        if (supportedCurrencies[i].id === selectedFiatCurrencyInfo.id) {
          if (i != selectedCurrency) {
            setSelectedCurrency(i);
          }
        }
        // else{
        //   // added new to solve when no fiat is present
        //   console.log('from else check' , i)
        //   setSelectedCurrency(0)
        // }
      }
    }
  };

  // useEffect(() => {
  //   console.log('Selected bank list item -> ',selectedBankRedux)
  //   console.log('Open modal -> ',openBankAccountAddModal)
  // },[selectedBankRedux])

  useEffect(() => {
    if (createBankAccountLoading === false && createBankError) {
      if (createBankError.length !== 0) {
        setErrorMessage(createBankError);
        //setGoBack(false);
      }
    } else {
      if (createBankAccountLoading === false && createBankError === undefined) {
        closeModalModified();
      }
    }
  }, [createBankAccountLoading]);

  const handleMe = (e) => {
    var target = e.target,
      position = target.selectionEnd,
      length = target.value.length;

    target.value = target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;
  };

  useEffect(() => {
    let allBanks =
      fiatBanks &&
      fiatBanks.map((bank) => ({
        id: bank.id,
        tokenName: bank.bankName,
      }));
    if (openBankAccountAddModal) {
      setHideThis(true);
    }
    if (allBanks && allBanks.length != 0) setBankOptions(allBanks);
    let supportedCurrencies = plaformcurrencies.map((currency) => ({
      id: currency.id,
      tokenName: currency.exchangeSymbol,
    }));
    checkCurrentFiatAndSet(supportedCurrencies);
    setSupportedCurrencies(supportedCurrencies);
  }, [fiatBanks, selectedBankRedux]);

  // useEffect(() => {
  //   let allBanks = fiatBanks.map((bank) => ({
  //     id: bank.id,
  //     tokenName: bank.bankName,
  //   }));
  //   setBankOptions(allBanks);
  //   checkCurrentBankAndSet()
  // },[selectedBankRedux])

  useEffect(() => {
    let supportedCurrencies = plaformcurrencies.map((currency) => ({
      id: currency.id,
      tokenName: currency.exchangeSymbol,
    }));
    if (openBankAccountAddModal) {
      setHideThis(true);
    }
    checkCurrentFiatAndSet(supportedCurrencies);
    setSupportedCurrencies(supportedCurrencies);
  }, [plaformcurrencies, selectedFiatCurrencyInfo]);
  // useEffect(() => {console.log('support',supportedCurrency)},[])

  const FormOnSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      if (Selected === "") {
        setShowDropDownError1(true);
      } else {
        setShowDropDownError1(false);
      }
      if (selectedCurrency === "" || selectedCurrency === undefined) {
        setShowDropDownError2(true);
      } else {
        setShowDropDownError2(false);
      }
      // else {
      //   console.log("else hitted")
      //   setShowDropDownError1(false)
      //   setShowDropDownError2(false)
      // }
      event.preventDefault();
      event.stopPropagation();
      if (iban === "") {
        setValid1(false);
      } else {
        setValid1(true);
      }
      if (accountHolderName === "") {
        setValid(false);
      } else {
        setValid(true);
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
      setValid(true);
      setValid1(true);
      createBankAccount(form);
      fiatBalance();
    }
  };

  const createBankAccount = (form) => {
    if (valid === true || valid1 === true || form.checkValidity() === true) {
      if (Selected !== "") {
        setShowDropDownError1(false);
        if (selectedCurrency !== "") {
          setShowDropDownError2(false);
          const bankData = {
            bankAccountNumber: accountNumber,
            BankId: parseInt(bankOptions[Selected].id),
            BankAccountType: "SAVINGS",
            BranchUniqueId: "999999",
            IFSC: ifsc,
            IBANs: iban,
            SWIFT: swift,
            exchangeId: exchangeId,
            accountHolderName: accountHolderName,
            curencyType: supportedCurrency[selectedCurrency].tokenName,
          };

          createBankAccountCall(bankData);
        }
        // const bankData = {
        //   bankAccountNumber: accountNumber,
        //   BankId: parseInt(bankOptions[Selected].id),
        //   BankAccountType: "SAVINGS",
        //   BranchUniqueId: "999999",
        //   IFSC: ifsc,
        //   IBANs: iban,
        //   Swift: swift,
        //   exchangeId: exchangeId,
        //   accountHolderName: accountHolderName,
        //   curencyType: supportedCurrency[selectedCurrency].tokenName,
        // };

        // createBankAccountCall(bankData);
      }
      // else if(selectedCurrency !==''){
      //   setShowDropDownError2(false)
      //   const bankData = {
      //     bankAccountNumber: accountNumber,
      //     BankId: parseInt(bankOptions[Selected].id),
      //     BankAccountType: "SAVINGS",
      //     BranchUniqueId: "999999",
      //     IFSC: ifsc,
      //     IBANs: iban,
      //     Swift: swift,
      //     exchangeId: exchangeId,
      //     accountHolderName: accountHolderName,
      //     curencyType: supportedCurrency[selectedCurrency].tokenName,
      //   };

      // createBankAccountCall(bankData);
      // }
      else {
        if (Selected === "") {
          setShowDropDownError1(true);
        } else {
          setShowDropDownError1(false);
        }

        // setShowDropDownError1(true)
      }

      //closeDetection();
      // if(!openBankAccountAddModal){
      //   onCancelled && onCancelled()
      // }

      //setShowSuccessful(true);
    } else {
      if (Selected === "") {
        setShowDropDownError1(true);
      } else {
        setShowDropDownError1(false);
      }
    }
    // else {
    //   //console.log(t(`${"NotValid"}`));
    // }
  };

  const closeDetection = () => {
    if (goBack === true) {
      closeModal();
    }
  };

  const closeModalModified = () => {
    if (openBankAccountAddModal) {
      removeCurrentBankAccountAsync();
      setErrorMessage("");
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setValid1(true);
      setSelectedIndex("");
      // setSelectedCurrency("");
      setShowDropDownError1(false);
      setShowDropDownError2(false);
      //setHideThis(false)
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      clearError();
    } else {
      setErrorMessage("");
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setValid1(true);

      setSelectedIndex("");
      // setSelectedCurrency("");
      setShowDropDownError1(false);
      setShowDropDownError2(false);
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      //setHideThis(false)
      clearError();
      onCancelledAsync();
    }
  };

  const closeModal = () => {
    if (openBankAccountAddModal) {
      removeCurrentBankAccountAsync();
      setErrorMessage("");
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setValid1(true);
      setSelectedIndex("");
      // setSelectedCurrency("");
      setHideThis(false);
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      clearError();
    } else {
      setErrorMessage("");
      setSelectedBank();
      setAccountNumber("");
      setAccountHolderName("");
      setIban("");
      setSwift("");
      setIfsc("");
      setValid(true);
      setValid1(true);
      setSelectedIndex("");
      // setSelectedCurrency("");
      // setBankOptions('')
      // setBankOptions();
      // setHideThis(false);
      // setShowAddBankConfirmation(false);
      //setHideThis(false)
      clearError();
      onCancelledAsync();
    }
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid var(--borderdark) !important",
      backgroundColor: "var(--bgwhite)",
      fontSize: "13px",
      color: state.isSelected ? "blue" : "var(--textlight)",
    }),

    // control: (_, { selectProps: { width }}) => ({
    //   width: width
    // }),

    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    //   return { ...provided, opacity, transition };
    // }
  };
  useEffect(() => {
    createBankAccSuccess && onSuccessfulBankCreation();
    //  createBankAccSuccess && onCancelled();
  }, [createBankAccSuccess]);

  return (
    <>
      <Accountsuccessful
        show={createBankAccSuccess}
        setShowDeposit={setShowDeposit}
        setHideThis={setHideThis}
      />

      <Modal
        className="addbank"
        show={showAddBankConfirmation || openBankAccountAddModal}
        onHide={() => {
          //onCancelled();
          closeModal();
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <span className="deletetitle">{t("AddNewBankAccount")}</span>
          </Modal.Title>
          <CloseIcon
            className="closebtn"
            onClick={() => {
              //onCancelled();
              closeModal();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            {createBankAccountLoading && <Loader />}
            <Row>
              <Col lg={12}>
                <Form noValidate onSubmit={FormOnSubmit}>
                  <div className="outercontainer">
                    <div className="hide">
                      <div
                        className="d-flex align-items-center mb-5"
                        onClick={() => {
                          //onCancelled();
                          closeModal();
                        }}
                      >
                        <Arrow className="arrowicon" />
                        <p className="addNewText">{t("AddNewBankAccount")}</p>
                      </div>
                    </div>
                    <div className="titles">
                      <span>{t("SelectBank")}</span>
                      <div className="inputbox1">
                        <DropdownSearch
                          options={bankOptions}
                          setSelectedIndex={(e) => {
                            setSelectedIndex(e);
                          }}
                          Selected={Selected}
                        />
                        {showDropDownError1 && (
                          <p className="errormessage">
                            {t(`${"SelectBankError"}`)}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <div className="titles">
                      <span>{t("AccountNumber")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          value={accountNumber}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterAccountNumber"}`)}
                          placeholder={t(`${"AccountNumber"}`)}
                        />
                      </div>
                    </div> */}

                    <div className="titles">
                      <span>{t("AccountHolderName")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          value={accountHolderName}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterAccountHolderName"}`)}
                          placeholder={t(`${"AccountHolderName"}`)}
                        />
                      </div>
                    </div>
                    <div className="titles">
                      <span>{t("SelectCurrency")}</span>
                      <div className="inputbox1">
                        <DropdownSearch
                          options={supportedCurrency}
                          setSelectedIndex={(e) => {
                            setSelectedCurrency(e);
                          }}
                          Selected={selectedCurrency}
                        />
                      </div>
                      {showDropDownError2 && (
                        <p className="errormessage">
                          {t(`${"SelectCurrencyError"}`)}
                        </p>
                      )}
                    </div>
                    <div className="titles">
                      <span>IBAN {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => {
                            handleMe(e);
                            setIban(e.target.value);
                          }}
                          value={iban}
                          required
                          isInvalid={valid1 === false}
                          error={t(`${"EnterIBAN"}`)}
                          placeholder={t(`${"IBANNumber"}`)}
                          minlength={42}
                        />
                      </div>
                    </div>

                    {/* <div className="titles">
                      <span>SWIFT {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setSwift(e.target.value)}
                          value={swift}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterSWIFT"}`)}
                          placeholder={t(`${"SWIFTNumber"}`)}
                        />
                      </div>
                    </div> */}

                    {/* <div className="titles">
                      <span>IFSC {t("Number")}</span>
                      <div className="inputbox">
                        <InputField
                          type="text"
                          onChange={(e) => setIfsc(e.target.value)}
                          value={ifsc}
                          required
                          isInvalid={valid === false}
                          error={t(`${"EnterIFSC"}`)}
                          placeholder={t(`${"IFSCNumber"}`)}
                        />
                      </div>
                    </div> */}
                    {t(`${errorMessage}`)}
                    <div className="btmbtnoutercontainer">
                      <div className="topbuttons">
                        <CustomButton
                          type="submit"
                          name={t("Submit")}
                          filled={true}
                          primary={true}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <div className="hidefordeskop">
          <FooterCommon />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ fiat = {}, app = {}, user = {}, wallet = {} }) => {
  const fiatBanks = _.get(fiat, "fiatBank", []);
  const createBankError = _.get(fiat, "createBankAccountError", undefined);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const createBankAccountLoading = _.get(
    fiat,
    "createBankAccountLoading",
    undefined
  );
  const createBankAccSuccess = _.get(fiat, "createBankAccSuccess", false);
  const selectedBankRedux = _.get(fiat, "selectedBank", undefined);
  const openBankAccountAddModal = _.get(fiat, "openBankAccountAddModal", false);
  const selectedFiatCurrencyInfo = _.get(
    fiat,
    "selectedFiatCurrencyInfo",
    undefined
  );
  return {
    fiatBanks,
    createBankError,
    exchangeId,
    userAccessToken,
    createBankAccountLoading,
    createBankAccSuccess,
    plaformcurrencies,
    selectedBankRedux,
    openBankAccountAddModal,
    selectedFiatCurrencyInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllBanksCall: () => dispatch({ type: "getBankTrigger" }),
  createBankAccountCall: (data) =>
    dispatch({ type: "createBankAccountStart", payload: data }),
  fiatBalance: (data) =>
    dispatch({ type: "callFiatBalanceStartCalled", payload: data }),
  clearError: () => dispatch(clearBankError()),
  removeCurrentBankAccountAsync: () => dispatch(removeCurrentBankAccount()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBankAccountPopup2);
