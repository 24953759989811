export default {
  WelcometoCyborPayfrombusiness: "Welcome to PayCrypt from business",
  Tostartuseallthefeatures:
    "To start using all the features of the platform,please verify your organization",
  UploadyourDocuments: "Upload your Documents",
  documensconfirmingyouractivities:
    "Documents confirming your activities under the current legislation. Document must be in formats PDF, DOCX, JPEG.",
  wehavesentyouonetimepassword:
    "We have sent you One time password on phone number typed in previous page. Kindly enter it here to verify",

  verificationmailsent:
    "Verification mail sent to your email address. Kindly check your email inbox, spam as well as junk folder and open verification mail. Click on",
  link: "link",
  inmailtoVerify: "in mail to Verify",
  or: "or",
  button: "button",
  upload: " Upload...",

  companyname: "Company Name",
  validname: "Please enter company name",
  address: "Address",
  enteraddress: "Please enter address",
  contactperson: "Contact Person",
  contactname: "Please enter name",
  "Select Document": "Select Document",
  Requestsent: "Request sent!",
  Thankyouprovidinginfo: "Thank you for providing information.We’ll contact you soon!",
  DoyouwanttoApprove: "Do you want to Approve?",
};
