export default {
  EMAIL_USED: "Bu e-mail adresi kullanılıyor",
  MOBILE_USED: "Girdiğiniz telefon numarası kullanılıyor.",
  NAME_CANNOT_START_WITH_NUMBER:
    "İsim, sayı, boşluk ve özel karakterlerle başlayamaz",
  SECOND_NAME_CANNOT_START_WITH_NUMBER:
    "Soy isim, sayı, boşluk ve özel karakterlerle başlayamaz",
  passwordnomatch: "Şifreler eşleşmiyor",
  "email must be an email": "Geçerli bir e-posta adresi girin",
  "mobile must be a phone number": "Geçerli bir telefon numarası girin",
  "email must be an email,mobile must be a phone number":
    "Geçerli bir e-posta adresi girin, Geçerli bir telefon numarası girin",
  Subscribe: "E-mail güncellemelerine abone olun",
  Terms: "PayCrypt'in Hizmet Şartlarını okudum ve kabul ediyorum",
  fname: "İsim",
  fms: "İsim çok kısa",
  in: "Geçersiz isim",
  lname: "Soy isim",
  lms: "Soy isim çok kısa",
  entpass: "Şifre",
  pts: "Şifre çok kısa",

  //Newly Added
  DoYouHaveAccount: "Hesabınız var mı?",
  Login: "Giriş yap",
  SignUpTo: "Kayıt ol",
  Password: "Şifrenizi girin",
  reentpass: "Şifrenizi tekrar girin",
  PhoneMustValid: "Telefon numarası geçerli olmalıdır",
  Term: "Şartlar",
};
