import React, { useState } from "react";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import QrCode from "@components/qr-code/QrCode";
import DepositFiatModal from "@components/depositfiat-modal/DepositFiatModal";
import "@components/qr-toggle/QrToggle.scss";
function QrToggle({ coinItem, getAdd, showToggle, tableNo, setTableNo, setsharem , setamountm, setIdo}) {
  let [tableno, settableno] = useState("first");
  return (
    <div className="qrtoggle">
      <div className="topspaceformodal">
        {showToggle === true && (
                  <div className="hide">

          <CurrencyTab
            page={"currency"}
            tableno={tableNo}
            changeTab={(e) => setTableNo(e)}
          />
            </div>
        )}
      </div>
      {tableno === "second" && <DepositFiatModal />}
      <QrCode coinItem={coinItem} getAdd={getAdd} setsharem={setsharem} setamountm={setamountm} setIdo={setIdo} />
    </div>
  
  );
}
export default QrToggle;
