import CustomButton from "@components/custom-button/CustomButton";
import Header from "@components/header/Header";
import InputField from "@components/input-field/InputField";
import OnboardingNavbar from "@components/navbar/OnboardingNavBar";
import Loader from "@components/loader/Loader";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Fade from "react-reveal/Fade";
import { Row, Col, Form, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import googlechrome from "@images/GoogleChrome.svg";
import SignInBannerLightPersonal from "@images/signinforlightpersonal.svg";
import SignInBannerDarkPersonal from "@images/sign_in_for_dark_personal.svg";
import SignInBannerLightBusiness from "@images/signinforlightbusiness.svg";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import PersonalBusinessToogle from "src/components/personal-business-toogle/PersonalBusinessToogle";
import Forgotpasswordpopup from "@components/forgot-password-popup/Forgotpasswordpopup";
import LoginConfirmationPopup from "src/components/login-confirmation-popup/LoginConfirmationPopup";
import "@features/onboarding/signin/SignInScreensStyles.scss";
import SigninqrPopup from "src/components/signin-qrpopup/SigninqrPopup";
import SignInBannerDarkBusiness from "@images/signinfordarkbusiness.svg";
import Footer from "@components/footer/Footer";
import FooterCommon from "@components/footer-common/FooterCommon";
import BannerImage from "@components/onboarding-animated-image/BannerImage";
import ResetPasswordPopup from "src/components/reset-password-popup/ResetPasswordPopup";

function SignIn(props) {
  const { t } = useTranslation();
  const captchaLang = {
    en: "eng",
    ru: "rus",
    tr: "tur",
  };
  const { exchange, thememode, role, defaultrole, changerole } = props;
  const [captchadata, setcaptchadata] = useState({});
  const [verified, setverified] = useState(true);
  const [show, setshow] = useState(false);
  const [captOBJ, setCaptOBJ] = useState();
  const [showExitAnimation, setShowExitAnimation] = useState(true);

  const [imageChange, setimageChange] = useState(
    defaultrole == "Personal" ? true : false
  );
  const [isToogleOn, setIsToggleOn] = useState(
    defaultrole == "Personal" ? true : false
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [showeye, setshoweye] = useState(true);
  const [key, setkey] = useState("1");
  const [phone, setPhone] = useState("");
  const [code, setcode] = useState("91");
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [currentLang, setCurrentlang] = useState("en");
  const formRef = React.createRef();

  useEffect(() => {
    console.log("settgfffff");
    // setGeetestLanguage();
    setCurrentlang(localStorage.lang);
  }, [localStorage.lang]);

  useEffect(() => {
    if (props.signInError) {
      console.log(" iam CALLED");
      // setGeetestLanguage();
    }
    console.log("props.singinerror", props.signInError);
  }, [props.signInError]);

  useEffect(() => {
    if (captchaSuccess) {
      submitForm();
    }
  }, [captchaSuccess]);

  useEffect(() => {
    console.log("geetest called");
    // setGeetestLanguage();
    return () => {
      setShowExitAnimation(!showExitAnimation);
    };
  }, []);

  const setGeetestLanguage = () => {
    initGeetest4(
      {
        captchaId: "088ff0623cc492dae5a36cea39b0f59b",
        product: "bind",
        language: captchaLang[localStorage.lang],
        btnWidth: "350px",
        bgColor: "rgba(0,0,0,0.5)",
      },
      function (captchaObj) {
        console.log("line 97", captchaObj);
        captchaObj.appendTo("#captcha");
        captchaObj.onReady(function (ready) {
          console.log("on ready", ready);
        });
        captchaObj
          .reset((what) => {
            console.log("what", what);
          })
          .onSuccess(function (result) {
            //your code

            console.log("on result", result);
            console.log("on sucesss", captchaObj);

            var result = captchaObj.getValidate();
            console.log("capatchobje", captchaObj.getValidate());
            console.log("result line 113", result);
            setcaptchadata(result);
            setverified(false);
            //setCaptchaSuccess((prev) => (prev !== undefined ? prev + 1 : 1));
            setCaptchaSuccess(true);
            //props.history.push("/");
          })
          .onError(function (error) {
            console.log("eeeeeeeee", error);
            setverified(true);
          });
        setCaptOBJ(captchaObj);
      }
    );
  };

  const submitForm = () => {
    // if (captchaSuccess === true) {
    props.usersignIn({
      mobile: code,
      password: password,
      email: email.trim(),
      navigation: props.history,
      loginType: "email",
      exchangeId: props.exchangeId,
      captchadata: captchadata,
      locations: window.location.host,
      role: props.defaultrole,
      lang: currentLang,
    });
    // }
    setCaptchaSuccess(false);
  };

  const handleCapcha = () => {
    console.log("captObj", captOBJ);
    captOBJ && captOBJ.showBox();
  };

  // const [role , setRole] = useState(defaultrole)
  useEffect(() => {
    //props.logouts({ history });

    // initGeetest4(
    //   {
    //     captchaId: "088ff0623cc492dae5a36cea39b0f59b",
    //     product: "bind",
    //     language: captchaLang[localStorage.lang],
    //     btnWidth: "350px",
    //     bgColor: "rgba(0,0,0,0.5)",
    //   },
    //   function (captchaObj) {
    //     captchaObj.appendTo("#captcha");
    //     captchaObj
    //       .onReady(function () {})
    //       .onSuccess(function (e) {
    //         //your code
    //         var result = captchaObj.getValidate();
    //         setcaptchadata(result);
    //         setverified(false);
    //         setCaptchaSuccess(true);
    //         //setCaptchaSuccess((prev) => (prev !== undefined ? prev + 1 : 1));
    //         //props.history.push("/");
    //       })
    //       .onError(function () {
    //         console.log("ERR");
    //         //your code
    //         // setverified(true);

    //         //your code
    //       });
    //     setCaptOBJ(captchaObj);
    //   }
    // );
    props.clearErrorMessage();
  }, [defaultrole]);

  useEffect(() => {
    props.clearErrorMessage();
  }, []);

  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      console.log("form. check validity", form.checkValidity());
      event.preventDefault();
      // handleCapcha();
      submitForm();
    }
  };

  return (
    <div
      id="signin"
      className={`${props.thememode == "dark" ? "fordarkbg" : "forlightbg"}`}
    >
      {props.signInLoading && <Loader />}
      <Forgotpasswordpopup setShowModal={setshow} show1={show} />
      <OnboardingNavbar
        location={useLocation()}
        setimageChange={setimageChange}
      />
      <Container className="contForHidingOverflow">
        <Row className="topspace">
          {/* img hide  cyborbusinesslogo*/}
          <div className="centeralign">
            {(imageChange && (
              // <img
              //   alt=""
              //   className=" img hide"
              //   src={
              //     thememode == "light"
              //       ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalLightmode}`
              //       : `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalDarkmode}`
              //   }
              // />
              <img src={paymentsolutionlogo} className=" img hide" />
            )) || (
              // <img
              //   alt=""
              //   className=" img hide"
              //   src={
              //     thememode == "dark"
              //       ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessDarkmode}`
              //       : `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessLightmode}`
              //   }
              // />
              <img src={paymentsolutionlogo} className=" img hide" />
            )}
          </div>
          <Col lg={7} className="imageCol">
            {/* {(!imageChange && (
                  <img
                  className="banner-image"
                    alt=""
                    src={
                      thememode == "light"
                      ? SignInBannerLightBusiness
                        : SignInBannerDarkBusiness
                        
                    }
                    
                  />
                  <BannerImage imageChange={imageChange} />
                )) || (
                  <img
                    alt=""
                    className="banner-image"
                    // className="cyborbusinesslogo"
                    src={
                      thememode == "dark"
                      ? SignInBannerDarkPersonal
                        : SignInBannerLightPersonal
                        
                    }
                    
                  />
                  <BannerImage imageChange={imageChange} />
                )} */}
            <BannerImage imageChange={imageChange} page="signin" />
          </Col>
          <Col lg={5}>
            <div className="outerbox">
              <div className="link-to-signup hidetop">
                {t("DontHaveAccount")}{" "}
                <Alert.Link
                  className="signup-register-link pointer"
                  onClick={() => props.history.push("/signup")}
                >
                  {" "}
                  {t("Register")}
                </Alert.Link>
              </div>
              {/* <Header className='sign-in-header' menu={false} /> */}
              <Fade right duration={500} distance="80px" opposite delay={100}>
                <div className="topheader">
                  <span className="topheader1">{t("SignInTo")}</span>
                  {imageChange ? (
                    <span className="topheader2">{t("CyborPay")}</span>
                  ) : (
                    <span className="topheader2">{t("CyborPayBusiness")}</span>
                  )}
                  {/* // <span className="topheader2">{t("CyborPay")}</span> */}
                </div>
                {/* <img
            alt=""
            className="img"
            src={
              thememode == "light"
                ? exchange &&
                  `${process.env.REACT_APP_IMGLINK}${exchange.lightLogo}`
                : exchange &&
                  `${process.env.REACT_APP_IMGLINK}${exchange.darkLogo}`
            }
          /> */}

                <Form noValidate validated={validated} onSubmit={onSendmsg}>
                  <div className="formfieldholder1">
                    <div className="input-field-title"></div>
                    {/* <InputField
                  phone
                  type="Phone"
                  placeholder="00 000 00 00"
                  onChange={(e) => setcode(e)}
                  value={code}
                  required
                  error={
                    code.length == 0
                      ? "Phone cannot be empty"
                      : "Phone must be a valid"
                  }
                /> */}
                    <h3 className="heading">{t("Email")}</h3>
                    <InputField
                      type="text"
                      placeholder={t("EmailID")}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      classname={"bottomborder"}
                      required
                      error={t("enteremailid")}
                    />
                  </div>

                  <div className="formfieldholder1">
                    <h3 className="heading">{t("Password")}</h3>
                    <InputField
                      type={showeye ? "password" : "text"}
                      placeholder={t("EnterPassword")}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      classname={"bottomborder"}
                      required
                      error="PasswordNotEmpty"
                      eye
                      setshow={() => setshoweye(!showeye)}
                      showeye={showeye}
                    />
                  </div>

                  {/* {props.signInLoading && <Loader />} */}
                  <Col className="mt-1 greyText" md="auto">
                    {props.signInError === undefined
                      ? ""
                      : t(`${props.signInError}`)}
                  </Col>
                  <div className="mb-2 ml-2 text-center">
                    <div id="captcha"></div>
                  </div>
                  <div className="forgotpasslink">
                    <Alert.Link
                      className="forgot-pass-link"
                      onClick={() => {
                        setshow(true);
                      }}
                    >
                      {t("ForgotPassword")}
                    </Alert.Link>
                  </div>
                  <div className="outerconforbtn displayblock">
                    <CustomButton
                      //disable={props.signInLoading || verified}
                      id="signin"
                      type="submit"
                      name={t("SignIn")}
                      filled={true}
                      primary={true}
                    />
                  </div>
                  <div className="displayblock signuptxt ">
                    <span
                      onClick={() => props.history.push("/signup")}
                      className=" pointer hidesignup"
                    >
                      {t("SignUp")}
                    </span>
                  </div>
                </Form>
                {/* <div className="outerconttxt">
            <span
              onClick={() => props.history.push("/signup")}
              className=" pointer "
            >
              {t("SignUp")}
            </span>
          </div> */}
                {/* <div className="security">{t("security")} </div> */}
                {/* <div className="subsecurity">{t("universal")}</div> */}

                {/* <a
                  // target="_blank"
                  className="decoration"
                  href="#"
                  // href="https://chrome.google.com/webstore/detail/cybor-protection-system/cnahcinenbahinmnkhflafbnnolnahlg"
                >
                  <div className="outerconforbtn1">
                    <CustomButton
                      name={t("downloadforchrome")}
                      type="submit"
                      filled={true}
                      primary={true}
                      leftIcon={googlechrome}
                    />
                  </div>
                </a> */}
              </Fade>
            </div>
          </Col>
        </Row>
        <div className="signinfooter hidetop">
          <Footer theme={thememode} />
        </div>

        {/* <LoginConfirmationPopup
          show1={props.ConfirmcodeModal}
          setShowModal={(e) => props.setConfirmcodeModal(e)}
          thememode={thememode}
          exchange={exchange}
          requestEmailCode={() =>
            props.requestEmailCode({
              exchangeId: props.exchangeId,
              email: props.emailIDS,
              lang: currentLang,
            })
          }
          signInLoading={props.signInLoading}
          ConfirmEmailCode={(e) =>
            props.ConfirmEmailCode({
              code: e.code,
              exchangeId: props.exchangeId,
              email: props.emailIDS,
              navigation: props.history,
              verifyToken: e.verifyToken,
            })
          }
          Error={t(`${props.ConfirmcodeModalError}`)}
        /> */}
        {/* <SigninqrPopup
          show1={props.AuthenticatorModal}
          thememode={props.thememode}
          settings={props.settings}
          googleAuthData={props.googleAuthData}
          setConfirmcodeModal={(e) => props.setConfirmcodeModal(e)}
          setShowModal={(e) => props.setAuthenticatorModal(e)}
        /> */}
      </Container>
      <div className=" signinfooter hidesignup">
        <FooterCommon />
      </div>
    </div>
  );
}

const mapStateToProps = ({ app = {}, user = {}, translationLanguage = {} }) => {
  const signInLoading = _.get(user, "signInLoading", false);
  const signInError = _.get(user, "signInError", undefined);
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const exchange = _.get(app, "settings.exchange", false);
  const settings = _.get(app, "settings", {});
  const thememode = _.get(app, "thememode", false);
  const defaultrole = _.get(app, "role", "Business");
  const role = _.get(user, "userInfo.role", "");
  const authenticatorConfirm = _.get(user, "userInfo.authenticator", undefined);
  const ConfirmcodeModal = _.get(app, "ConfirmcodeModal", false);
  const AuthenticatorModal = _.get(app, "AuthenticatorModal", false);
  const googleAuthData = _.get(user, "googleAuthData", {});
  const ConfirmcodeModalError = _.get(user, "ConfirmcodeModalError", {});
  const emailIDS = _.get(user, "MyProfileInfo.email", "");

  const currentLang = _.get(translationLanguage, "language", "");
  const googleAuthLoader = _.get(user, "googleAuthLoading", undefined);

  return {
    signInLoading,
    signInError,
    exchangeId,
    exchange,
    thememode,
    currentLang,
    ConfirmcodeModal,
    settings,
    AuthenticatorModal,
    googleAuthData,
    emailIDS,
    ConfirmcodeModalError,
    role,
    googleAuthLoader,
    authenticatorConfirm,
    defaultrole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  usersignIn: (data) => dispatch({ type: "usersignIncalled", payload: data }),
  requestEmailCode: (data) => {
    console.log("I am requestEmailCode");
    dispatch({ type: "requestEmailCodeCalled", payload: data });
  },
  ConfirmEmailCode: (data) =>
    dispatch({ type: "ConfirmEmailCodeCalled", payload: data }),
  setAuthenticatorModal: (data) =>
    dispatch({ type: "setAuthenticatorModalCalled", payload: data }),
  setConfirmcodeModal: (data) =>
    dispatch({ type: "setConfirmcodeModalCalled", payload: data }),
  clearErrorMessage: (data) =>
    dispatch({ type: "clearErrorMessageCalled", payload: data }),
  changerole: (data) => dispatch({ type: "changerolecalled", payload: data }),
  logouts: (data) => dispatch({ type: "logoutCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
