import "@components/coin-list/CoinListStyles.scss";
function CoinList({ logo, name, logoBgColor, shownamewithsymbol, symbol, type, currencyName }) {
  return (
    <div className="coinlist">
      <div className="coincircle" style={{ backgroundColor: logoBgColor }}>
        <img
          src={`${process.env.REACT_APP_IMGLINK}${logo}`}
          className="coinimg"
        />
      </div>
      {
        shownamewithsymbol && ( currencyName && 
          <div className="coinlistarea">
            <p className="nameclass">{currencyName}</p>
            <p className="symbolclass">{symbol} </p>
          </div> || 
          <div className="coinlistarea">
            <p className="nameclass"> {name}</p>
            <p className="symbolclass"> {symbol} {type=="ETH"?"ERC20":type=="BSC"?"BEP20":type=="TRON"?"TRC20":type}</p>
          </div>
          ) ||
        <div className="coinlistarea">
          <span> {name}</span>
        </div>
      }
    </div>
  );
}
export default CoinList;
