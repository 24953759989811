import React, { useState } from "react";
import buy from "@images/buy.svg";
import logoimg from "@images/logoimg.svg";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/buy-cyborcoincard/BuyCyborCardStyle.scss";
import { Dropdown } from 'react-bootstrap'
import { ReactComponent as Dropdownarrow } from "@images/downarrow.svg";
import { useTranslation } from "react-i18next";

const BuyCyborCard = ({ idorounds, setIdobuyModal ,activeRounds }) => {
  let { exchangeRate, id, roundName, lockingPeriod, tokenLimit } =
    (idorounds && idorounds[0]) || {};

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      >
        {children}
      </span>
    ));
    
    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
      ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
    
        return (
          <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
          >
            <ul className="list-unstyled">
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value),
              )}
            </ul>
          </div>
        );
      },
    );

  const { t, i18n, ready } = useTranslation();

  return (
    <div className="buycyborcard">
      <div className="buyheading">
        <div className="buyTextStyle">{t("IDO")}</div>
      </div>
      <div className="buycardcontent">
        <div className="dropNcontWrap">
          {/* <Dropdown> */}
            {/* <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components"> */}
              <div className="maindrptoogle">
                <div className="togglecontent">
                  <p className="boldt">{t("IDOCyborCoin")}</p>
                  <p className="lightt">{activeRounds && activeRounds.roundName?activeRounds.roundName : ''}</p>
                </div>
                <div>
                  {/* <Dropdownarrow /> */}
                </div>
              </div>
            {/* </Dropdown.Toggle> */}
{/* 
            <Dropdown.Menu as={CustomMenu}>
              <Dropdown.Item eventKey="1">Red</Dropdown.Item>
              <Dropdown.Item eventKey="2">Blue</Dropdown.Item>
              <Dropdown.Item eventKey="3" active>
                Orange
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item>
            </Dropdown.Menu> */}
          {/* </Dropdown> */}

          <div className="fancyDesign">
            <div className="togglecontent">
              <p className="boldt">{t("CYBOR")}</p>
              <p className="lightt">{t("CyborCoin")}</p>
            </div>
            <div className="emptyline"></div>
            <div className="fancyCircle">{activeRounds && activeRounds.exchangeRate?activeRounds.exchangeRate : 0}</div>
          </div>
          <div className="buttoncontainer">
            <CustomButton
              disable={true}
              type="submit"
              name={t("Buy")}
              filled={true}
              primary={true}
              className="buttonmain"
              onClick={() => setIdobuyModal(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BuyCyborCard);
