export default {
  Symbol: "Символ",
  Price: "Цена",
  loading: "Загрузка",
  Balances: "Балансы",
  My: "Мои",
  ResendOTP: "Отправить OTP снова",
  SignUp: "Зарегистироваться",
  SignIn: "Войти",
  phone: "Телефон",
  emailid: "Электронная почта",
  enteremailid: "Пожалуйста, введите адрес электронной почты",
  password: "Пароль",
  passwordnotempty: "Пароль не может быть пустым",
  rntrpass: "Введите пароль повторно",
  verify: "Верифицировать",
  FilterPeriode: "Выбрать Период",
  gotosignin: "Перейти на Вход",
  create: "Создать",
  Darkmode: "Темный режим",
  Lightmode: "Светлый режим",
  downloadforchrome:
    "Скачать систему защиты PayCrypt Security для браузера Google Chrome",
  security: "Безопасность PayCrypt",
  universal:
    "Универсальная система защиты персональных данных и криптоактивов, доступная каждому",

  ///////////common er msgs entire platform////////////////
  EMAIL_EMPTY: "Адрес электронной почты пуст",
  MOBILE_EMPTY: "Телефон не введен",
  PASSWORD_EMPTY: "Пароль не введен",
  USER_NOT_FOUND: "Пользователь не найден",
  VERI_LINK_EXPIRED: "Ссылка верификации просрочена",
  INVALID_VERI_DETAILS: "Неверные детали верификации",
  "email should not be empty,email must be an email":
    "Введите действительный адрес электронной почты",

  //payment
  CURRENCY_NOT_SUPPORTED: "Валюта не поддерживается",
  COIN_NOT_SUPPORTED: "Монета не поддерживается ",
  ACCOUNT_NOT_FOUND: "Аккаунт не найден",
  WALLET_NOT_FOUND: "Кошелек не найден",
  UNAUTHRIZED_ACCOUNT: "Аккаунт не авторизирован",
  LINK_USED: "Сыылка уже использована",
  PAYMENT_LINK_TOKEN_NOT_FOUND: "Ссылка на платеж не найдена",
  LINK_USED_OR_NOT_FOUND: "Link used or not found",
  COMMISSION_NOT_FOUND: "Комиссия не найдена",
  EXCHANGE_NOT_FOUND: "Обмен не найден",
  BALANCEE_TOO_LOW: "Низкий баланс",
  TIME_EXCEED: "Время истекло",
  PaymentSuccessfully: "Платеж выполнен успешно",

  /////IDO/////////
  TOKENS_NOT_AVAILABLE: "Токены недоступны",
  IDO_ROUND_NOT_FOUND: "IDO раунд не найден",
  ADDRESS_DOESNOT_BELONG_TO_CYBORPAY_USER:
    "Адрес не принадлежит пользователю PayCrypt",
  EXCHANGE_RATE_NOT_MATCHING: "Обменный курс не совпадает",
  IDO_TRANSACTION_NOT_FOUND: "IDO транзакция не найдена",
  TOKENS_ALREADY_CLAIMED: "Вывод токена уже запрошен",
  LOCKING_PERIOD_IS_NOT_OVER: "Период блокировки еще не прошел",
  INSUFFICIENT_BALANCE: "Недостаточный баланс",
  TOKENS_NOT_AVAILABLE: "Токены недоступны",
  AMOUNT_TO_EXCHANGE_CANNOT_BE_IN_NEGETIVE:
    "Сумма для обмена не может быть отрицательной",
  TOKEN_ADDED: "Токен добавлен",
  TOKEN_TRANSFERRED: "Токен переведен",

  //////Fiat/////
  BANK_ACCOUNT_NOT_FOUND: "Банк не найден",
  YOUR_ACCOUNT_IS_NOT_ACTIVATED: "Ваш аккаунт не активен",
  FIAT_CURRENCY_NOT_FOUND: "Фиатная валюта не найдена",
  ACCOUNT_IS_NOT_ACTIVE: "Аккаунт не активен",
  LOW_BALANCE: "Низкий баланс",
  USERS_FIAT_DEPOSIT_REQUEST_NOT_FOUND:
    "Запрос пользователя на ввод фиатной валюты не найден",
  AMOUNT_ALREADY_DEPOSITED: "Сумма уже введена",
  USER_CURRENCY_NOT_FOUND: "Валюта пользователя не найдена",
  BUSINESS_REQUEST_NOT_FOUND: "Business запрос не найден",
  "BUSINESS_USER_WITH_ID_${Id}_NOT_FOUND": "Business user with ID not found",
  INVALID_MERCHANT_ADDRESS: "Некорректный адрес мерчанта",

  AMOUNT_CANNOT_BE_IN_NEGETIVE: "Сумма не может быть отрицательной",

  ////////////withdraw///////////////
  BALANCEE_TOO_LOW: "Низкий баланс",

  PLATFORM_ERROR:
    "Ошибка платформы из-за большой суммы. Пожалуйста, свяжитесь со службой поддержки",
  ///////////common er msgs entire platform-----------ends-----------////////////////

  FAILED_WITHDRAWAL_AUTHENTICATOR_VERIFICATION: "Неверный код аутентификатора",
  Security_verification: "Проверка безопасности",
  borrowbankloan: "Взять банковский кредит",
};
