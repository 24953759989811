import * as _ from "lodash";
import React, { useState, useEffect, Suspense } from "react";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import NavBar from "@components/navbar/NavBar";
import "@components/reset-password-popup/ResetPasswordPopup.scss";
import "@features/onboarding/set-new-password/SetNewPassword.Styles.scss";
import PasswordSuccess from "src/components/password-success/PasswordSuccess";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "@components/loader/Loader";
import { useSearchQuery } from "react-router-dom";
const SetNewPassword = ({
  //   show1,
  location,
  exchange,
  setShowModal,
  changePasswordStart,
  changePasswordloading,
  changePasswordErrorMessage,
  userAccessToken,
  changePasswordSuccess,
}) => {
  //  const params = useSearchQuery()
  let query = new URLSearchParams(location.search);
  const [exchangeIdAPI, setExchangeIdAPI] = useState("");
  const [show1, setshow1] = useState(true);
  const [email, setEmail] = useState(undefined);
  const [token, setToken] = useState(undefined);
  const [showform, setShowForm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errormessage, setErrorMessage] = useState('');
  const [showeye, setshoweye] = useState(true);
  const [showeye2, setshoweye2] = useState(true);
  const [showeye3, setshoweye3] = useState(true);
  const [passwordToken, setpasswordToken] = useState(undefined);
  const [showPasswordErr, setShowPasswordErr] = useState(false);
  const [showError , setshowError] = useState(false);
  // const [currentLang,setCurrentLang] = useState(localStorage.lang ? localStorage.lang : 'en')
  const [currentLang,setCurrentLang] = useState('en')
  // const [currentLang, setCurrentLang] = useState(
  //   localStorage.lang ? localStorage.lang : "en"
  // );
  const [password2, setPassword2] = useState("");
  const [password1, setPassword1] = useState("");
  const [validated, setValidated] = useState(true);
  const { t, i18n, ready } = useTranslation();
  const [show, setShowPass] = useState(false);

  useEffect(() => {
    setCurrentLang(localStorage.lang);
  }, [localStorage.lang]);
 
  const onSendmsg =  (event) => {
    setLoader(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      //   setValidated(false);
    }

    if (form.checkValidity() === true) {
      setShowPasswordErr(true);
      if (password2 !== password1) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(false);
      } else if(password2 == password1) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const data = {
          newPassword: password2,
          passwordToken: passwordToken,
          lang: currentLang !== undefined ? currentLang : 'en',
        };
       
        axios
          .post(
            `${process.env.REACT_APP_APILINK}/auth/user/set-new-password?email=${email}&token=${token}&exchangeId=${exchangeIdAPI}}`,
            data
          ).then(response =>{
             setLoader(false);
            let message = response.data.message;
            let success = response.data.success;
            {
              success && setShowPass(true);
            }

            setErrorMessage(message);
          }).catch(error => {
             setLoader(false)
          })
          
          
        // changePasswordStart(form);
      }
    }
  };

  useEffect(() => {
    if (changePasswordSuccess !== undefined) {
      setShowModal(true);
      setShowPass(true);

      setPassword1("");
      setPassword2("");
    }
  }, [changePasswordSuccess]);

  const init = async () => {
    const email1 = query.get("email");
    const token1 = query.get("token");
    let responses = await axios.get(
      `${process.env.REACT_APP_APILINK}/manager/getexchangedetail?exchangeUrl=${process.env.REACT_APP_SITELINK}`
    );
    setExchangeIdAPI(responses.data.exchange.id);
    await axios
      .get(
        `${process.env.REACT_APP_APILINK}/auth/user/reset-password?email=${email1}&token=${token1}&exchangeId=${responses.data.exchange.id}`
      )
      .then((res) => {
 
        let success = res.data.success;
        let message = res.data.message;

        let passToken = res.data.passwordToken;
         setpasswordToken(passToken);
        setEmail(email1);
        setToken(token1);
        setErrorMessage(message);

        if (success === true) {
          setShowForm(true);
        }
        //  else {
        //   setshowError(true);
        // }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    init();
    setErrorMessage('')
    setshowError('')
   }, []);

  //   useEffect(() => {
  //     let query =  location.search;
  //     setTimeout(() => {
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_APILINK}/auth/user/confirm${query}&exchangeId=${props && props.exchange.id}`
  //         )
  //         .then((res) => {
  //           setVerifyScreenState({
  //             result:
  //               "accountVerified",
  //             active: false,
  //             verify: true,
  //           });
  //         })
  //         .catch((err) => {
  //           setVerifyScreenState({
  //             result: "link_expired",
  //             active: false,
  //             verify: false,
  //           });
  //         });
  //     }, 10000);
  //   }, [])

  return (
    <>
      {loader && <Loader />}
      <PasswordSuccess
        show1={show}
        setShowPass={(e) => setShowPass()}
        setShowModal={(e) => setShowPass(e)}
      />
      <div
        id="setnewpassword"
        // className="ResetPasswordPopup"
        show={show1}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setshow1(false)}
        centered
      >
        <NavBar location={location} />
        {/* {showError && <span className="red">{t(`${errormessage}`)}</span>} */}
        {/* {errormessage ? <span className="red">{t("InvalidCredentials")}</span> : <span></span>} */}
        {showform && (
          <div className="outercls">
            <Form noValidate onSubmit={(event) => onSendmsg(event)}>
              <span className="exchangetitle">{t("ResetPassword")}</span>

              <Container>
                <Row>
                  <Col lg={12} className="colspace">
                    <div className="titlepass">
                      <span className="titleText">{t("NewPassword")}</span>
                    </div>
                    <div className="inputboxpass">
                      <InputField
                        type={showeye2 ? "password" : "text"}
                        onChange={(e) => setPassword2(e.target.value)}
                        value={password2}
                        eye
                        required
                        setshow={() => {
                          setShowPasswordErr(false);
                          setshoweye2(!showeye2);
                        }}
                        showeye={showeye2}
                        isInvalid={
                          validated === false ||
                          (password1 && password2 && password1 !== password2)
                        }
                        error={
                          password2 && password1 !== password2
                            ? "Password and confirm password should match"
                            : password2.length < 8
                            ? "Password cannot be empty"
                            : ""
                        }
                      />
                    </div>

                    <div className="titlepass">
                      <span className="titleText">{t("ConfirmPassword")}</span>
                    </div>
                    <div className="inputboxpass">
                      <InputField
                        na
                        type={showeye3 ? "password" : "text"}
                        onChange={(e) => setPassword1(e.target.value)}
                        value={password1}
                        eye
                        required
                        setshow={() => {
                          setShowPasswordErr(false);
                          setshoweye3(!showeye3);
                        }}
                        showeye={showeye3}
                        isInvalid={
                          validated === false ||
                          (password1 && password1 !== password2)
                        }
                        error={
                          password1 && password1 !== password2
                            ? "Password and confirm password should match"
                            : password1.length < 8
                            ? "Confirm password cannot be empty"
                            : ""
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={6} sm={6} xs={6}>
                    <div className="confirm">
                      <CustomButton
                        type="submit"
                        name={t("Confirm")}
                        filled={true}
                        primary={true}
                        disabled={loader}
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={6} xs={6}>
                    <div className="cancel">
                      <CustomButton
                        name={t("Cancel")}
                        filled={true}
                        primary={true}
                        onClick={() => setshow1(false)}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
        )}

        {showPasswordErr && (
          <p className="red">
            {changePasswordErrorMessage !== undefined
              ? changePasswordErrorMessage == "INVALID_PASSWORD"
                ? "Invalid Password"
                : ""
              : ""}
          </p>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ app = {}, user = {} }) => {
  const changePasswordloading = _.get(user, "changePasswordloading", false);
  const changePasswordErrorMessage = _.get(
    user,
    "changePasswordErrorMessage",
    undefined
  );
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  return {
    exchange,
    thememode,
  };
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const changePasswordSuccess = _.get(user, "changePasswordSuccess", undefined);
  return {
    changePasswordloading,
    changePasswordErrorMessage,
    userAccessToken,
    changePasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePasswordStart: (data) =>
    dispatch({ type: "changePasswordStart", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SetNewPassword));
