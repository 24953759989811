import React from "react";
import "@components/bank-list-item/BankListItemStyles.scss";
import { addBankAccounts } from "@store/fiatredux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import {
  setCurrentBankAccount,
  setBankAccountAddingModal,
} from "@store/fiatredux";
import _ from "lodash";

const BankListItem = ({
  payload,
  setShowAddBankConfirmation,
  addBank,
  selectedBankAccounts,
  scope,
  setshowInfo,
  fromAccountPopup1Called,
  update,
  index,
  banks,
  setCurrentBankAccountAsync,
  setBankAccountAddingModalAsync,
  setSelectedBankAccountId,
  thememode,
  selectedBank
}) => {
  let { colouredLogo, nonColouredLogo, bankName, bankDetails } = payload || {};
  const { t, i18n, ready } = useTranslation();

  const setAccount = () => {
    let itemExists = false;

    for (let bank of selectedBankAccounts) {
      if (
        bank.userName === bankDetails.userName &&
        bank.bankAccountNumber === bankDetails.bankAccountNumber &&
        bank.id === bankDetails.id
      ) {
        itemExists = true;
      }
    }
    if (itemExists === false) {
      addBank([
        ...selectedBankAccounts,
        {
          userName: bankDetails.userName,
          bankAccountNumber: bankDetails.bankAccountNumber,
          id: bankDetails.id,
        },
      ]);
      setShowAddBankConfirmation();
    } 
    else {
      setShowAddBankConfirmation();
    }
  };

 
  return (
    <div
      className={`BankListItem pointer ${selectedBank?.id == payload.id ? 'activeList' : 'inactiveList'}`}
      onClick={() => {
        if (scope === "FiatDeposit") {
          setAccount();
          update(index);
        } else {
          if (scope === "FiatWithdraw") {
            setSelectedBankAccountId(index);
            setShowAddBankConfirmation();
          }
          setshowInfo && setshowInfo(true);
        }
        if(scope ===""){
          payload && setCurrentBankAccountAsync(payload)
        }
        // else{
        //   if (scope === "") {
        //     let allBankIds = [];
        //     if(banks.length !== 0){
        //       allBankIds = banks.map(itm => itm.bankId)
        //     }

        //     if(allBankIds.length !== 0){
        //        if(allBankIds.includes(payload.id) === false){
        //         setCurrentBankAccountAsync(payload)
        //         setBankAccountAddingModalAsync()
        //        }
        //     }
        //     else{
        //       setCurrentBankAccountAsync(payload)
        //       setBankAccountAddingModalAsync()
        //     }

        //
        //   }
        // }
      }}
    >
      {/* {(bankDetails.currencyType !== null) || (bankDetails.currencyType !== undefined)  ?<span className="bankname">
       {`${bankName} ${bankDetails.currencyType}`}
       </span> : <span>{`${t("Not supported for ")} ${bankDetails.currencyType}`}</span>} */}
      {fromAccountPopup1Called ? (
        <div>
          {bankDetails && bankDetails.IBANs ? (
            <span>
              {" "}
              <img
                className="bankimgs"
                src={`${process.env.REACT_APP_IMGLINK}${
                  thememode != "light"
                    ? bankDetails &&
                      bankDetails.bank &&
                      bankDetails.bank.colouredLogo
                    : bankDetails &&
                      bankDetails.bank &&
                      bankDetails.bank.nonColouredLogo
                }`}
              />
              {`${bankDetails.IBANs}`}
            </span>
          ) : (
            ""
          )}{" "}
          <span>
            {bankDetails.Status === "Pending" ? t(`${"PendingApproval"}`) : ""}
          </span>
        </div>
      ) : (
        <>
        <span onClick={() => setshowInfo(false)}>
          {/* {payload && payload.ownersBankAccount &&  */}
          {/* <img
            className="bankimg"
            src={`${process.env.REACT_APP_IMGLINK}${colouredLogo}`}
            
          /> */}

          <img
            alt="cybor"
            src={
              thememode != "light"
                ? `${process.env.REACT_APP_IMGLINK}${colouredLogo}`
                : `${process.env.REACT_APP_IMGLINK}${nonColouredLogo}`
            }
            className="bankimg"
          />
        </span>
        <p className="float-end" >{`${bankName}`}</p>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ fiat = {}, app = {} }) => {
  const selectedBankAccounts = _.get(fiat, "selectedBankAccounts", []);
  const selectedBank = _.get(fiat, "selectedBank", {});
  const banks = _.get(fiat, "banks", []);
  const thememode = _.get(app, "thememode", false);

  return {
    selectedBankAccounts,
    banks,
    thememode,
    selectedBank
  };
};

const mapDispatchToProps = (dispatch) => ({
  addBank: (data) => dispatch(addBankAccounts(data)),
  setCurrentBankAccountAsync: (data) =>{
     dispatch(setCurrentBankAccount(data))},
  setBankAccountAddingModalAsync: () => dispatch(setBankAccountAddingModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankListItem);
