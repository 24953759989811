import InputField from "@components/input-field/InputField";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import * as _ from "lodash";
import React from "react";
import Container from "react-bootstrap/Container";
import "@components/exchange-to/ExchangeToStyles.scss";
import { useTranslation } from "react-i18next";

function ExchangeTo({
  option,
  coinItem,
  Selected,
  setSelectedIndex,
  onChange,
  val,
  loadingRate,
  bity,
  onBlur,
}) {
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="ExchangeTo">
      <Container>
        <div className="exchangetitle">
          <span>{t("To")}</span>
        </div>

        <DropdownSearch
          bity={bity}
          options={option}
          coinItem={coinItem}
          Selected={Selected}
          setSelectedIndex={setSelectedIndex}
        />

        <div className="margintp">
          <div className="textinput">
            <span className="usdtclass">
              {bity
                ? option?.[Selected]?.code
                : option && option[Selected] && option[Selected].exchangeSymbol}
            </span>
            <div className="outerinput">
              <InputField
                type="number"
                classname={"inputwoanyborder"}
                onChange={onChange}
                value={val}
                disabled={loadingRate}
                onBlur={onBlur}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ExchangeTo;
