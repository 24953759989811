export default {
  Logo: 'Logo',
  Thesizeofimage: 'Kabul ediler resmin boyutu',
  pixels: 'piksel',
  sizetothefile: 'dosya boyutu',
  upto: 'kadar',
  inPNGorJPEGformat: 'PNG veya JPEG formatında',
  Change: 'Yükle',
  Account: 'Hesap',
  Branch: 'Şube',
  Phone: 'Telefon',
  email: 'E-mail',
  Address: 'Adres',
  Password: 'Şifre',
  Confirm: 'Onayla',
  AccountName: 'Hesap adı',
  EnterPassword: 'Şifrenizi girin',
  ReEnterPassword: 'Şifrenizi tekrar girin',
  ImageRequired: "Resim gerekli",
  PhoneEmpty:"Telefon numarası alanı boş olamaz",
  Email_empty:"E-mail alanı boş olamaz",
  Passwordempty:"Şifre alanı boş olamaz",
  false:"yanlış",
  Cryptocurrency:"Kripto para",
  Fiat:"Fiat",
  "Please enter a valid email address": "Lütfen geçerli bir e-mail adresi girin",
  "Please enter a valid phone no": "Lütfen geçerli bir telefon numarası girin",
  "Please enter a branch name": "Lütfen şube adı girin",
  "Please enter an account name": "Lütfen bir hesap adı girin",
  "EMAIL_ALREADY_IN_USE": "Bu e-mail adresi kullanılıyor",
  "MOBILE_ALREADY_IN_USE": "Girdiğiniz telefon numarası kullanılıyor.",
  "INVALID_ROLE": "Geçersiz Rol",
  "Email_empty": "Email boş",
  "NAME_CANNOT_BE_A_NUMBER":"Ad bir sayı olamaz",
};
