import NavBar from "@components/navbar/NavBar";
import ScreenLayout from "src/components/layout/ScreenLayout";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Form } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";
import "@components/my-profile/MyProfile.scss";
import pprofile from "@images/pprofile.svg";
import pprofiledark from "@images/profiledark.svg";
import { ReactComponent as PencilIcon } from "@images/editpencil.svg";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import { connect } from "react-redux";
import ResetPasswordPopup from "@components/reset-password-popup/ResetPasswordPopup";
import { useTranslation } from "react-i18next";
import FooterCommon from "@components/footer-common/FooterCommon";
import CurrencyTab from "src/components/currency-tab/CurrencyTab";
import Loader from "../loader/Loader";

function MyProfile({
  userdata,
  uploadProfileImage,
  updateProfileLoading,
  updateProfileImageLoading,
  updateProfile,
  changePasswordStart,
  role,
  profileImage,
  removeImage,
  showBackIcon,
  setShowProfileTab,
  thememode,
  getplatformTokensinfo,
  platformBanks,
  getPlatformBank,
}) {
  const [name, setname] = useState(userdata.name);
  const [subName, setSubName] = useState(userdata.subName);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [email, setemail] = useState(userdata.email);
  const [phone, setPhone] = useState(userdata.mobile);
  const [code, setcode] = useState("91");
  const [imageChaned, setimageChaned] = useState(false);
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [validated2, setValidated2] = useState(false);
  const [showeye, setshoweye] = useState(true);
  const [image, setImage] = useState("");
  const [imageurl, setImageurl] = useState(null);
  const [readerImage, setReaderImage] = useState(false);
  const [error2, seterror2] = useState("");
  var re = /[^a-zA-Z]+/;
  // const role = "Admin"; // just for testing....
  useEffect(() => {
    let links = profileImage &&profileImage.original && profileImage.original.includes("profile16")
      ? ""
      : userdata.profileImage.original;
    setImageurl(links);
     
  }, [userdata.profileImage]);


  const resetForm = () => {
    setname(userdata.name);
    setSubName(userdata.subName);
    setReaderImage(false);
    setImage("");
    setImageurl(userdata?.profileImage?.original);
    setIsFieldChanged(false);
  };

  const onSendmsg = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      setValidated(true);
      setIsFieldChanged(false);
      // setIsFieldChanged(true);
      if (readerImage) {
        uploadImage();
        setReaderImage(false);
      }
      if (name.length < 3 || subName.length < 3) {
        setIsFieldChanged(true);
      } else {
        updateProfile({
          name,
          subName,
        });
      }
    }
  };

  const uploadImage = () => {
    const data = new FormData();
    data.append("image", image);
    uploadProfileImage({ data });
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setReaderImage(true);
      setImage(file);
      setImageurl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  let [show, setshow] = useState(false);
  let [tableno, settableno] = useState("first");

  const { t } = useTranslation();

  return (
    <div id="myprofile">
      <div className="containerallignm">
        <ResetPasswordPopup
          setShowModal={setshow}
          show1={show}
          changePasswordStart={changePasswordStart}
        />
        {(updateProfileLoading || updateProfileImageLoading) && <Loader />}
        <Container>
          <Row>
            <Col lg={12}>
              <Form noValidate validated={validated} onSubmit={onSendmsg}>
                <div className="outercontainer">
                  <div className="titles1 maintitle">
                    {showBackIcon && (
                      <span
                        className="backicon"
                        onClick={() => {
                          setShowProfileTab();
                        }}
                      >
                        <Arrow className={"Arrow"} />
                      </span>
                    )}
                    <span className="leftspace">{t("MyProfile")}</span>
                  </div>

                  <div className="phototitle">
                    <span>{t("Photo")}</span>
                  </div>

                  <div className="outercls">
                    <div className="display imgouter">
                      {readerImage == true ? (
                        <img
                          src={imageurl}
                          className="imgsize integratedimage"
                        />
                      ) : (
                        !(
                          profileImage &&
                          profileImage.original &&
                          profileImage.original.includes("profile-1638")
                        ) && (
                          <img
                            src={`${process.env.REACT_APP_IMGLINK}${imageurl}`}
                            className={
                              profileImage &&
                              profileImage.original &&
                              profileImage.original.includes("profile-1638")
                                ? "imgsize2"
                                : "imgsize"
                            }
                          />
                        )
                      )}

                      {profileImage &&
                        profileImage.original &&
                        profileImage.original.includes("profile-1638") &&
                        !readerImage && (
                          <img
                            src={thememode == "light" ? pprofile : pprofiledark}
                            className={
                              profileImage &&
                              profileImage.original &&
                              profileImage.original.includes("profile-1638")
                                ? "imgsize2"
                                : "imgsize"
                            }
                          />
                        )}
                    </div>
                    <div className="display">
                      <div className="marginlft">
                        <div className="spanwidth">
                          <span className="spanmsg">
                            {t("Thesizeofimage")} 98 x 98 {t("pixels")} (
                            {t("sizetothefile")} -{t("upto")} 4 MB){" "}
                            {t("inPNGorJPEGformat")}
                          </span>
                        </div>
                        <div className="topbuttons btntop">
                          <label
                            htmlFor="inputfile"
                            className={
                              thememode == "dark"
                                ? "inputbuttonmaindark"
                                : "inputbuttonmain"
                            }
                          >
                            {t("Change")}
                          </label>
                          <input
                            onChange={(e) => {
                              handleImageChange(e);
                              setIsFieldChanged(true);
                            }}
                            type="file"
                            id="inputfile"
                            className="inputfilebtn"
                          />
                        </div>
                        <div className="topbuttons btntop">
                          <div
                            className={
                              thememode == "dark"
                                ? "btnexcludedark"
                                : "btnexclude"
                            }
                          >
                            <CustomButton
                              name={t("Exclude")}
                              filled={true}
                              primary={true}
                              type={"text"}
                              onClick={(e) => {
                                if (!readerImage) {
                                  removeImage();
                                } else {
                                  e.preventDefault();
                                  setImageurl("");
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {updateProfileLoading && <p>{t("loading")}</p>}

                  {role == "Business" && (
                    <>
                      <div className="mainorgaisationwrapper">
                        <div className="organisationinfo">
                          <div className="organisationinfowrapper">
                            <div>
                              <span className="titleText">{t("Operator")}</span>
                            </div>
                            <div>
                              <span className="titleTextValue">
                                {userdata !== undefined &&
                                userdata.companyName !== undefined
                                  ? userdata.companyName
                                  : ""}
                              </span>
                            </div>
                          </div>
                          {/* <div className="outerspan">
                            <PencilIcon className="editicon" />
                            <span className="editText">{t("Edit")}</span>
                          </div> */}
                        </div>

                        <div className="organisationinfo">
                          <div className="organisationinfowrapper">
                            <div>
                              <span className="titleText">
                                {t("Merchants")}
                              </span>
                            </div>
                            <div>
                              <span className="titleTextValue">
                                {userdata !== undefined &&
                                userdata.name !== undefined &&
                                userdata.subName !== undefined
                                  ? `${userdata.name} ${userdata.subName}`
                                  : ""}
                              </span>
                            </div>
                          </div>
                          {/* <div className="outerspan">
                            <PencilIcon className="editicon" />
                            <span className="editText">{t("Edit")}</span>
                          </div> */}
                        </div>

                        <div className="organisationinfo">
                          <div className="organisationinfowrapper">
                            <div>
                              <span className="titleText">
                                {t("Commission")}
                              </span>
                            </div>
                            <div>
                              <span className="titleTextValue">1%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="titles">
                        <span className="titleText">{t("Name")}</span>
                        <div className="inputbox">
                          <InputField
                            type="text"
                            required
                            onChange={(e) => {
                              setname(e.target.value);
                              setIsFieldChanged(true);
                            }}
                            value={name}
                            isInvalid={validated && name.length < 3}
                            error={t("Nametooshort")}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {role != "Business" && (
                    <>
                      <div className="titles">
                        <span className="titleText">{t("FirstName")}</span>
                        <div className="inputbox">
                          <InputField
                            type="text"
                            required
                            onChange={(e) => {
                              setname(e.target.value);
                              setIsFieldChanged(true);
                            }}
                            value={name}
                            isInvalid={validated && name.length < 3}
                            error={t("fms")}
                          />
                        </div>
                      </div>

                      <div className="titles">
                        <span className="titleText">{t("LastName")}</span>
                        <div className="inputbox">
                          <InputField
                            type="text"
                            required
                            onChange={(e) => {
                              setIsFieldChanged(true);
                              setSubName(e.target.value);
                            }}
                            value={subName}
                            isInvalid={validated && subName.length < 3}
                            error={t("lms")}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="titles">
                    <span className="titleText">{t("Email")}</span>
                    <div className="inputbox">
                      <InputField
                        type="text"
                        onChange={(e) => setemail(e.target.value)}
                        value={email}
                        disabled
                        // required
                        // error={t("enteremailid")}
                      />
                    </div>
                  </div>
                  <div className="titles">
                    <span className="titleText">{t("Phone")}</span>
                    <div className="inputphone mt-2">
                      <InputField
                        phone
                        type="Phone"
                        onChange={(e) => setcode(e)}
                        value={phone}
                        required
                        disabled
                        // isInvalid={validated && phone.length < 10}
                        // error={
                        //   phone.length == 0
                        //     ? "Phone cannot be empty"
                        //     : "Phone must be a valid"
                        // }
                      />
                    </div>
                  </div>
                  {role == "Business" && (
                    <div>
                      {/* <div className="titlepass">
                        <span className="titleText">{t("Password")}</span>
                      </div>
                      <div className="outerpass">
                        <div className="inputboxpass">
                          <InputField
                            type={showeye ? "password" : "text"}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            value={password}
                            eye
                            required
                            setshow={() => setshoweye(!showeye)}
                            showeye={showeye}
                          />
                        </div>
                      </div> */}
                    </div>
                  )}

                  {/* <div className="titlepass">
                  <span className="titleText">{t("Password")}</span>
                </div> */}
                  <div className="outerpass">
                    {/* <div className="inputboxpass">
                    <InputField
                      type={showeye ? "password" : "text"}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      eye
                      setshow={() => setshoweye(!showeye)}
                      showeye={showeye}
                    />
                  </div> */}
                    <div className="outerspan">
                      <span
                        className="resetpass resetpass2"
                        onClick={() => {
                          setshow(true);
                        }}
                      >
                        {t("ResetPassword")}
                      </span>
                    </div>
                  </div>

                  <div className="btmbtnoutercontainer">
                    <div className="savebtn">
                      <CustomButton
                        type="submit"
                        name={t("Save")}
                        filled={true}
                        primary={true}
                        disable={isFieldChanged == true ? false : true}
                      />
                    </div>
                    <div className="bottombuttons">
                      <div className="btncancel">
                        <CustomButton
                          type="button"
                          name={t("Cancel")}
                          filled={true}
                          primary={true}
                          onClick={resetForm}
                          disable={
                            ((updateProfileLoading ||
                              updateProfileImageLoading) &&
                              true) ||
                            false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

const mapStateToProps = ({ app = {}, user = {}, fiat = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const userdata = _.get(user, "MyProfileInfo", false);
  const updateProfileLoading = _.get(user, "updateProfileLoading", false);
  const updateProfileImageLoading = _.get(user, "updateProfileLoading", false);
  const role = _.get(user, "userInfo.role", "");
  const profileImage = _.get(user, "MyProfileInfo.profileImage", undefined);
  // const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const platformBanks = _.get(fiat, "platformBanks", []);
  return {
    thememode,
    userdata,
    updateProfileLoading,
    updateProfileImageLoading,
    role,
    profileImage,
    // getplatformTokensinfo,

    platformBanks,
  };
};
const mapDispatchToProps = (dispatch) => ({
  removeImage: (data) => dispatch({ type: "removeImageCalled", payload: data }),
  uploadProfileImage: (data) =>
    dispatch({ type: "updateProfileImage", payload: data }),
  updateProfile: (data) => dispatch({ type: "updateProfile", payload: data }),
  changePasswordStart: (data) =>
    dispatch({ type: "changePasswordStart", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
