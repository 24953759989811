import { createSlice } from "@reduxjs/toolkit";

const notificationslice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
  },
  reducers: {
    notificationSuccess: (state, action) => {
      return {
        ...state,
        notifications: action.payload,
      };
    },
    notificationCount: (state, action) => {
      return {
        ...state,
        notificationsCount: action.payload,
      };
    },
    notificationFailure: (state, action) => {
      return {
        ...state,
        notificationsFailure: action.payload,
      };
    },
  },
});
export default notificationslice.reducer;

export const { notificationSuccess, notificationCount, notificationFailure } =
  notificationslice.actions;
