import * as _ from "lodash";
import React, { useEffect } from "react";
import "./DetailedExchangePopup.Styles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DetailedExchangePopup = ({ setShowModal, data, show1 }) => {
  let {
    id,
    txnHash,
    block,
    from,
    to,
    amount,
    status,
    type,
    tOrCName,
    transactionType,
    gasUsed,
    timestamp,
    note,
    createdOn,
    updatedOn,
    withdraw,
    userexchange,
    fromCoin,
    toCoin,
    coin,
    total,
    toCurrency,
    fromCurrency
  } = data || {};
  let { decimals, exchangeSymbol, explorerLink } = coin || {};
  let token =()=>{
   return( fromCoin !== undefined && fromCoin !== null && fromCoin.tokenName != undefined
      ? fromCoin.tokenName
      :
      fromCurrency !== undefined && fromCurrency !== null && fromCurrency.currencyName != undefined ?
      fromCurrency.currencyName :
      "");
   }
  let toCoinTokename =()=>{
   return toCoin !== undefined && toCoin !== null && toCoin.tokenName != undefined
      ? toCoin.tokenName
      :
      toCurrency !== undefined && toCurrency !== null && toCurrency.currencyName != undefined ?
      toCurrency.currencyName : 
      ""};
  let newDecimal =()=>{
   return fromCoin !== undefined && fromCoin !== null && fromCoin.decimals != undefined
      ? fromCoin.decimals
      :
      ""};
  let fromCoinSymbol =()=>{
   return fromCoin !== undefined && fromCoin !== null && fromCoin.exchangeSymbol != undefined
      ? fromCoin.exchangeSymbol
      :
      fromCurrency !== undefined && fromCurrency !== null && fromCurrency.exchangeSymbol != undefined ?
      fromCurrency.exchangeSymbol : 
      ""};
  let tonewDec =()=>{
   return toCoin !== undefined && toCoin !== null && toCoin.decimals != undefined ? toCoin.decimals : "";}
  let toCoinSymbol =()=>{
   return toCoin !== undefined && toCoin !== null && toCoin.exchangeSymbol != undefined
      ? toCoin.exchangeSymbol
      :
      toCurrency !== undefined && toCurrency !== null && toCurrency.exchangeSymbol != undefined ?
      toCurrency.exchangeSymbol :  
      ""};

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="Detailedex"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <Modal.Header>
        <div className="transectiontype">
          {!transactionType ? (
            <span className="transectiontext uppercase">
              {t(`Exchange`)} {t("Details")}{" "}
            </span>
          ) : (
            <span className="transectiontext uppercase">
              {t(`${transactionType}`)} {t("Details")}{" "}
            </span>
          )}
        </div>
      </Modal.Header>
      <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      <div
        className=" back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>

      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Transactionreference")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{id}</span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              {!transactionType ? (
                <span className="labeltext">{t("Amount")} </span>
              ) : (
                <span className="labeltext">{t("Amount")}</span>
              )}
            </div>
            <div className="valueamout">
              {decimals ? (
                <span className="valuetext">
                  {" "}
                  {amount / 10 ** decimals} {exchangeSymbol}{" "}
                  {/* {amount /10 ** fromCoin.decimals } {exchangeSymbol}{" "}  */}
                </span>
              ) : (
                <span className="valuetext">
                  {" "}
                  {amount / 10 ** newDecimal()} {fromCoinSymbol()}{" "}
                </span>
              )}
            </div>
          </div>

          <div className="detail">
            <div className="label">
              {!transactionType ? (
                <span className="labeltext">{t("ExchangedCoins")} </span>
              ) : (
                <span className="labeltext">{t("TransactionFee")}</span>
              )}
            </div>
            <div className="value">
              {!transactionType ? (
                <span className="valuetext uppercase">
                  
                  {`${token()}  ${t("To")} ${toCoinTokename()} `}{" "}
                </span>
              ) : (
                <span className="valuetext uppercase">
                  {t(`${transactionType}`)}{" "}
                </span>
              )}
            </div>
          </div>
          <div className="detail">
            <div className="label">
              {!transactionType ? (
                <span className="labeltext">{t("ExchangedAmount")} </span>
              ) : (
                <span className="labeltext">{t("TransactionFee")}</span>
              )}
            </div>
            <div className="value">
              {!transactionType ? (
                <span className="valuetext uppercase">
                  {`${(amount / 10 ** newDecimal()).toFixed(6)} ${fromCoinSymbol()}  ${t("To")} ${
                    (total / 10 ** tonewDec()).toFixed(6)
                  } ${toCoinSymbol()} `}{" "}
                </span>
              ) : (
                <span className="valuetext uppercase">
                  {t(`${transactionType}`)}{" "}
                </span>
              )}
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Status")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">{t(`${status}`)}</span>
            </div>
          </div>

          {transactionType != "transfer" && txnHash && (
            <div className="detail">
              <div className="label">
                <span className="labeltext">{t("TransactionID")}</span>
              </div>
              <div className="valuetransid">
                <span className="valuetext">{txnHash}</span>
              </div>
            </div>
          )}

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("DateandTime")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {moment(createdOn).local().format("MM/DD/YYYY  hh:mm A")}

              </span>
            </div>
          </div>

          {transactionType != "transfer" && txnHash && (
            <div className="linktag">
              <a href={`${explorerLink}${txnHash}`} target="blank">
                {t("ViewinBlockchainExplorer")}
              </a>
            </div>
          )}

          <div className="confirm">
            <CustomButton
              type="submit"
              name={t("Close")}
              filled={true}
              primary={true}
              onClick={() => setShowModal(false)}
            />
          </div>

          {/* <div className="options hidden-for-desktop">
            <div className="optiontxtholder1">
              <img
                onClick={() => {
                  setcopied(true);
                  navigator.clipboard.writeText(address);
                }}
                src={copy}
                className="comtxt"
              />
              <div
                className="comtxt1"
              onClick={() => {
                setcopied(true);
                navigator.clipboard.writeText(address);
              }}
              >
                {copied ? t(`${"Copied"}`) : t(`${"Copy"}`)}
                {t(`${"Copy"}`)}
              </div>
            </div>


            <div className="optiontxtholder1">
              <img
                src={share}
                className="comtxt"
              onClick={() => {
                setDepositModal(false)
                setsharem(true)
                setshow(true);

              }}
              />
              <div className="comtxt1">{t("Share")}</div>
            </div>
          </div> */}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(DetailedExchangePopup);
