export default {
  Deposit: "Yatırım",
  Withdraw: "Çekim",
  Exchange: "Döviz",
  Displayedcurrencies: "Görüntülenen para birimleri",
  TotalBalances: "Toplam Bakiyeler",
  Searchhere: "Ara",
  Coin: "Coin",
  Balance: "Bakiye",
  Locked: "Kilitli",
  Price: "Fiyat",
  Showing: "Gösteriliyor",
  from: "itibaren",
  Currency: "Para birimi",
  AddCoin: "Coin ekle",
  Cryptocurrency: "Kripto para",
  pleasemakepayment:
    "Lütfen aşağıdaki IBAN numarasına (belirtilen miktarda) ödeme yapın",
  SelectRound: "Tur Seç",
  Enterrecipientaddress: "Alıcı adresini girin(PayCrypt adresi)",
  Enteramounttotransfer: "Gönderilecek tutarı girin",
  Amounteqivalenttokentotransfer: "Aktarılacak eşdeğer jeton miktarı",
  Createreport: "Rapor oluştur",
  Yourreporthasbeengenerated: "Raporunuz oluşturuldu",
  Downloadfile: "Dosyayı indir",
  Details: "Detaylar",
  Transactionreference: "İşlem referansı",
  Amount: "Miktar",
  amount: "miktar",
  TransactionFee: "İşlem ücreti",
  Status: "Durum",
  TransactionID: "İşlem İD numarası",
  DateandTime: "Tarih ve Saat",
  ViewinBlockchainExplorer: "Blockchain Explorer'de Görüntüle",
  Acceptas: "olarak kabul et",
  Convertto: "e dönüştür",
  Sendlinkto: "Bağlantıyı gönder",
  EnterEmail: "E-maili girin",
  Paymentlinkwillbecreated: "Ödeme bağlantısı oluşturulacak",
  YouwillReceive: "Alınacak",
  PlatformCommission: "Platformu Komisyonu",
  Create: "Oluştur",
  From: "İtibaren",
  Total: "Toplam",
  PaymentLinkDetails: "Ödeme Bağlantısı Ayrıntıları",
  Completed: "Tamamlandı",
  PaymentID: "Ödemenin İD numarası",
  Date: "Tarih",
  Recepient: "Alıcı",
  Copylink: "Bağlantıyı kopyala",
  ShareLink: "Bağlantıyı paylaş",
  Print: "Yazdır",
  Close: "Kapat",
  Logo: "Logo",
  thesizeoftheimage:
    "Resmin boyutu 98 x 98 piksele kadar, (dosya boyutu - 4 MB'a kadar) PNG veya JPEG formatında olmalıdır",
  Change: "Yükle",
  Exclude: "Sil",
  Account: "Hesap",
  Branch: "Şube",
  DeleteConfirmation: "Silme Onayı",
  Doyouwanttodeletethisbranch: "Bu şubeyi silmek istiyor musunuz",
  TransferredSuccessfully: "Başarıyla Gönderildi !",
  ExchangedSuccessfully: "Başarıyla döviz edildi !",
  Twofactorauthentication: "İki faktörlü kimlik doğrulama",
  pleaseusegoogleauth:
    "Bu QR kodunu taramak için lütfen Google kimlik doğrulama uygulamasını kullanın veya uygulamanıza sayısal bir kod girin",
  Cancel: "İptal",
  Ok: "Tamam",
  GETITFROM: "Hemen indir",
  GETITON: "Hemen indir",
  DOWNLOADONTHE: "Hemen indir",
  EXPLOREITON: "Hemen indir",
  EstimatedBalances: "Bakiyeler",
  Hidezerobalance: "Sıfır bakiyeleri gizle",
  showzerobalance:"Sıfır bakiyeyleri göster ",
  PaymentFrom: "Ödeme",
  PaymentTo: "Için ödeme",
  FromWallet: "Cüzdandan",
  ToWallet: "Cüzdana",
  WithdrawOrder: "Emri geri çek",
};
