import * as _ from "lodash";
import React, { useEffect } from "react";
import "./ViewDocumentsPopup.scss";
import { Modal, Container, Row, Col,Button } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
import { useTranslation } from "react-i18next";

const ViewDocumentsPopup = ({ setShowModal, show1, data, setData,onClick }) => {
  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="ViewDocumentsPopup"
      show={show1}
      // onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      <div
        className=" back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
        <div className="transectiontype">
          <span className="transectiontext uppercase">
            {t(`Documents Detail`)}{" "}
          </span>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Address Proof")}</span>
            </div>
            <div className="value">
              <span className="">

                <Button onClick={()=>onClick(data?.addressVerificationLink, "address_proof")} className="download-btn valuetext">Download</Button>
                {/* <CustomButton
                  name={t("Download")}
                  filled={true}
                  primary={true}
                  classname="download-btn"
                //   onClick={() => {
                //     setData(item);
                //     setShowDoc(true);
                //   }}
                /> */}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Income Statement")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
              <Button onClick={()=>onClick(data?.incomeStatementLink, name="income_statement")} className="download-btn valuetext">Download</Button>

              </span>
            </div>
          </div>

          <div className="confirm">
            <CustomButton
              type="button"
              name={t("Close")}
              filled={true}
              primary={true}
              onClick={() => {
                setData("");
                setShowModal(false);
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(ViewDocumentsPopup);
