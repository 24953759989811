import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "@components/coin-list-item/CoinListItem.scss";
const CoinListItem = ({ logo, name, balance, symbol,type }) => {
  return (
    <div className="CoinListItem pointer">
      <span>
        <img
          className="logoSize"
          src={`${process.env.REACT_APP_IMGLINK}${logo}`}
        />
      </span>
      <span className="coinname">{name}</span>
      {(type == "TRC20" || type == "BEP20" || type == "ERC20") && (
        <span className="cointag">{type}</span>
      )}
      <div className="coinbalance">
      <OverlayTrigger
                    placement="top"
                    delay={{ show: 0, hide: 1000 }}
                    overlay={(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                     {Number(balance).toFixed(4)} {symbol}
                      </Tooltip>
                    )}
                  >
                    <span>  {Number(balance).toFixed(4)} {symbol}</span>
                  </OverlayTrigger>
      
      </div>
    </div>
  );
};
export default CoinListItem;
