export default {
  about: "About",
  Downloadext: "Download PayCrypt Security protection system for Google Chrome browser",
  ChromeExtension: "Chrome Extension",
  CyborProtectionSystem: "PayCrypt Protection System",
  applicationforcontrol:
    "is an application for your control and security in dealing with financial and cryptocurrency transactions",
  freeappcyborprotection:
    " Free app PayCrypt Protection System uses strong encryption to protect all your traffic and hides your real IP address so you can access any blocked website, protect your data and browse the Internet anonymously without bandwidth limitation.",
  cyborprotectionforchrome:
    " PayCrypt Protection System from Chrome is the easiest way to keep your online security and privacy safe. Our VPN allows you to hide your IP address and access blocked content after connecting to our VPN servers.",
  cyborprotectionvpn:
    "PayCrypt Protection System is considered the best VPN for Google Chrome by millions of users around the world. Connect to our secure servers from all over the world to try unlimited free VPN and access blocked sites, streaming content and more.",
  installvpn:
    "Install our VPN extension for Google Chrome to start using unlimited bandwidth and stay secure and anonymous, even when you're on public Wi-Fi.",
  CyborProtectionSystemApp: "PayCrypt Protection System App",
  // 'GET IT ON': 'GET IT ON',
};
