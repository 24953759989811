import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import CustomButton from "src/components/custom-button/CustomButton";
import Loader from "@components/loader/Loader";
import depositwallet from "@images/depositwallet.svg";
import PayPopup from "src/components/pay-popup/PayPopup";
import "@features/pay-screen/PayScreenStyle.scss";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import UploadPopup from "@components/upload-popup/UploadPopup";

function PayScreen(props) {
  const {
    getPaymentbytokenLink,
    match,
    paymentbytoken,
    paymenthistoryLoading,
    transferByPaymentLink,
    exchangeRates,
    getBalances,
    myaccountandbalance,
    MyProfileInfo,
    setPaymentlinkSucc,
    thememode,
    settings,
    auth,
  } = props || {};
  let [show, setshow] = useState(false);
  let history = useHistory();
  useEffect(() => {
    getBalances();
    getPaymentbytokenLink({ tokenLink: match.params.id });
    let ws = new WebSocket("wss://paymentsolutionapi.sparkrex.com");
    ws.onopen = () => {
      ws.send(JSON.stringify({ paymentId: match.params.id }));
      ws.onmessage = (e) => {
        let resp = JSON.parse(e.data);
        if (resp.paymentStatus == "Paid") {
          getPaymentbytokenLink({ tokenLink: match.params.id });
        }
        if (resp.paymentStatus == "Cancelled") {
          getPaymentbytokenLink({ tokenLink: match.params.id });
        }
      };
    };
  }, []);
  let paymentToken = { link: match.params.id };
  const { t } = useTranslation();
  let [show2, setshow2] = useState(false);
  let [show3, setshow3] = useState(false);

  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", 0);

    return address;
  };
  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return reelbal / 10 ** decimals;
  };

  return (
    <div id="PayScreen">
      <NavBar location={useLocation()} />
      <Container className="containerallignm">
        {paymenthistoryLoading && <Loader />}
        <Row>
          <Col lg={3}>{/* <Sidebar /> */}</Col>

          <Col lg={7}>
            {!paymenthistoryLoading &&
              paymentbytoken.toPaymentLinkWallet &&
              paymentbytoken.toPaymentLinkWallet.user &&
              paymentbytoken.paymentStatus == "Pending" && (
                <>
                  <PayPopup
                    setShowModal={setshow}
                    show1={show}
                    data={paymentbytoken}
                  />

                  <div className="outerbox">
                    <div className="payhead">
                      <div className="image">
                        {/* <payIcon className="arrowclass"/> */}
                        <img src={depositwallet} />
                      </div>
                      <div className="text">
                        <span className="textpayemnt"> {t("Payment")} </span>
                      </div>
                      <div className="merchantlogo">
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_IMGLINK}${
                            paymentbytoken &&
                            paymentbytoken.merchant &&
                            paymentbytoken.merchant.profileImage
                              ? paymentbytoken.merchant.profileImage.original
                              : ""
                          }`}
                          className="imgmerchant"
                        />
                      </div>
                    </div>

                    <div className="bottomsection">


                  
                      <div className="merchant">
                        <span className="textmerchant">{t("Merchant")}:</span>
                      </div>
                      

                      <div className="imgofmerchant">
                        <span className="merchantext">
                          {paymentbytoken.toPaymentLinkWallet.user.name +
                            " " +
                            paymentbytoken.toPaymentLinkWallet.user.subName}
                        </span>
                        {/*    <img src={merchant} className="imgmerchant"  />*/}
                      </div>

                      <div className="payersection">
                        {auth && (
                          <div className="merchant">
                            <span className="textmerchant">{t("Payer")}:</span>
                          </div>
                        )}
                        {auth && (
                          <div className="payer">
                            <span className="payertext">
                              {MyProfileInfo.name} {MyProfileInfo.subName}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="amountsections">
                        {auth && (
                          <div className="merchant">
                            <span className="textmerchant">{t("Amount")}:</span>
                          </div>
                        )}
                        { (
                          <div className="amountpays">
                            <span className="payertext">
                            {paymentbytoken &&
                              paymentbytoken.currencyAmountCommission} 
                            {paymentbytoken &&
                              paymentbytoken.currency.exchangeSymbol}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="amounttopay">
                        <div className="amount">
                          <span className="amounttopaytxt">
                            {t("AmountToPay")}:
                          </span>
                        </div>

                        <div className="amountvalue">
                          <span className="valuetopaytxt">
                            {" "}
                            {BigNumber(
                              paymentbytoken && paymentbytoken.expectedAmount2
                            ).toFixed(6, BigNumber.ROUND_CEIL)}{" "}
                            {paymentbytoken.fromPaymentLinkCoin &&
                              paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                          </span>
                        </div>
                      </div>



                      
                              
                      {auth && (
                        <div>
                          {BigNumber(
                            getBal(
                              paymentbytoken.fromPaymentLinkCoin &&
                                paymentbytoken.fromPaymentLinkCoin.id
                            )
                          ).isGreaterThanOrEqualTo(
                            BigNumber(
                              paymentbytoken.amountWithFee
                            ).multipliedBy(exchangeRates.result)
                          ) ? (
                            <div className="outercontainer">
                              <CustomButton
                                type="submit"
                                name={`${
                                  paymentbytoken.paymentStatus === "Cancelled"
                                    ? "link_expired"
                                    : paymentbytoken.paymentStatus === "Paid"
                                    ? "Already Paid"
                                    : t("PAY_CYWALL")
                                }`}
                                filled={true}
                                primary={true}
                                onClick={() => {
                                  transferByPaymentLink({
                                    amount: paymentbytoken.amountWithFee,
                                    acceptAs: paymentbytoken.acceptAs,
                                    exchangeTo: paymentbytoken.exchangeTo,
                                    acceptAsType: paymentbytoken.acceptAsType,
                                    exchangeToType:
                                      paymentbytoken.exchangeToType,
                                    email: paymentbytoken.email,
                                    fromAddress: getAdd(
                                      paymentbytoken.fromPaymentLinkCoin &&
                                        paymentbytoken.fromPaymentLinkCoin.id
                                    ),
                                    toAddress: paymentbytoken.toAddress,
                                    exchangeRate: `${exchangeRates.result}`,
                                    paymentLinkId: paymentbytoken.id,
                                    paymentLinkToken:
                                      paymentbytoken.paymentLinkToken,
                                    tokenLink: paymentbytoken.tokenLink,
                                  });
                                }}
                                disable={
                                  paymentbytoken.paymentStatus ===
                                    "Cancelled" ||
                                  paymentbytoken.paymentStatus === "Paid"
                                }
                              />
                            </div>
                          ) : (
                            <div className="insufficientbal">
                              <span>{t("InsufficientBalance")}!</span>
                            </div>
                          )}
                        </div>
                      )}
                      {!auth && (
                        <div className="outercontainer">
                          <CustomButton
                            type="submit"
                            name={t("LPAY_CYWALL")}
                            filled={true}
                            primary={true}
                            onClick={() => {
                              history.push("/");
                            }}
                          />
                        </div>
                      )}
                      <div className="outercontainer">
                        <CustomButton
                          type="submit"
                          name={t("PAY_OTWALL")}
                          filled={true}
                          primary={true}
                          onClick={() => {
                            setshow2(true);
                          }}
                          disable={
                            paymentbytoken.paymentStatus === "Cancelled" ||
                            paymentbytoken.paymentStatus === "Paid"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            {show2 && (
              <UploadPopup
                currencyToReceive={BigNumber(
                  paymentbytoken && paymentbytoken.expectedAmount2
                ).toFixed(6, BigNumber.ROUND_CEIL)}
                currencyToConvert={BigNumber(
                  paymentbytoken && paymentbytoken.amountWithFee
                ).toFixed(4)}
                setshow2={setshow2}
                show2={show2}
                setShowModal={setshow3}
                setShowCreateLink={setPaymentlinkSucc}
                paymentbytoken={paymentbytoken}
                paymentToken={paymentToken}
                thememode={thememode}
                settings={settings}
                getPaymentbytokenLink={() =>
                  getPaymentbytokenLink({
                    tokenLink: match.params.id,
                  })
                }
              />
            )}

            {!paymenthistoryLoading &&
              paymentbytoken.toPaymentLinkWallet &&
              paymentbytoken.toPaymentLinkWallet.user &&
              paymentbytoken.paymentStatus == "Paid" && (
                <div className="outerbox1">
                  <span className="sucesstxt valuetopaytxt">
                    {t("PaymentSuccessfully")}!
                  </span>
                </div>
              )}
            {!paymenthistoryLoading &&
              paymentbytoken.toPaymentLinkWallet &&
              paymentbytoken.toPaymentLinkWallet.user &&
              paymentbytoken.paymentStatus == "Cancelled" && (
                <div className="outerbox1">
                  <span className="sucesstxt valuetopaytxt">
                    {t("PaymentLinkNotValid")}
                  </span>
                </div>
              )}

            {/* {paymentbytoken.toPaymentLinkWallet &&
                  paymentbytoken.toPaymentLinkWallet.user && (
                    <div>
                      <span className=" title">{`Payment Link from ${paymentbytoken.toPaymentLinkWallet.user.name} ${paymentbytoken.toPaymentLinkWallet.user.subName} `}</span>
                    </div>
                  )} */}
            {/* <div>
                  <span className=" title">
                    {`Payment Link ID : ${paymentbytoken.tokenLink} `}
                  </span>
                </div> */}

            {/* <div>
                  {
                    <span className=" title">
                      {`Amount Payable : ${Number(
                        exchangeRates.result * paymentbytoken.amount
                      ).toFixed(5)} ${
                        paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol
                      }`}
                    </span>
                  }
                </div> */}
            {/* {getBal(paymentbytoken.acceptAs) >=
                paymentbytoken.amount * exchangeRates.result ? (
                  <div className="outrcont">
                    <CustomButton
                      name={`${
                        paymentbytoken.paymentStatus === "canc"
                          ? "Linked Expired"
                          : paymentbytoken.paymentStatus === "paid"
                          ? "Paid Successfully"
                          : "Pay now"
                      }`}
                      filled={true}
                      primary={true}
                      onClick={() => {
                        transferByPaymentLink({
                          amount: paymentbytoken.amount,
                          acceptAs: paymentbytoken.acceptAs,
                          exchangeTo: paymentbytoken.exchangeTo,
                          acceptAsType: paymentbytoken.acceptAsType,
                          exchangeToType: paymentbytoken.exchangeToType,
                          email: paymentbytoken.email,
                          fromAddress: getAdd(paymentbytoken.acceptAs),
                          toAddress: paymentbytoken.toAddress,
                          exchangeRate: `${exchangeRates.result}`,
                          paymentLinkId: paymentbytoken.id,
                          paymentLinkToken: paymentbytoken.paymentLinkToken,
                          tokenLink: paymentbytoken.tokenLink,
                        });
                      }}
                      disable={
                        paymentbytoken.paymentStatus === "canc" ||
                        paymentbytoken.paymentStatus === "paid"
                      }
                    />
                  </div>
                ) : (
                  <div className="insufficientbal">
                    <span>Insufficient Balance!</span>
                  </div>
                )} */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = ({
  paymentlink = {},
  wallet = {},
  user = {},
  app = {},
}) => {
  const paymentbytoken = _.get(paymentlink, "paymentbytoken", {});
  const paymenthistoryLoading = _.get(paymentlink, "paymenthistoryLoading", {});
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const MyProfileInfo = _.get(user, "MyProfileInfo", false);
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const auth = _.get(user, "auth", false);

  return {
    paymentbytoken,
    paymenthistoryLoading,
    exchangeRates,
    myaccountandbalance,
    MyProfileInfo,
    thememode,
    settings,
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getBalances: (data) => dispatch({ type: "getBalancesCalled", payload: data }),
  getPaymentbytokenLink: (data) =>
    dispatch({ type: "getPaymentbytokenLinkCalled", payload: data }),
  transferByPaymentLink: (data) =>
    dispatch({ type: "transferByPaymentLinkCalled", payload: data }),
  setPaymentlinkSucc: (data) =>
    dispatch({ type: "setPaymentlinkSuccCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayScreen);
