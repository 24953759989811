import React from "react";
import ScreenLayout from "@components/layout/ScreenLayout";
import "@features/request-loan/RequestLoan.scss";
import { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import InputField from "@components/input-field/InputField";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import CustomButton from "@components/custom-button/CustomButton";
import { useLocation } from "react-router-dom";
import { cleargetInterestRateForPersonal } from "@store/platformuserredux";
import { Form, Range } from "react-bootstrap";
import LoaderSmall from "src/components/loadersmall/LoaderSmall";
import Loader from "src/components/loader/Loader";
import AdminLoanSuccesfulPopup from "src/components/admin-loan-template-successfull-popup/AdminLoanSuccesfulPopup";
import FooterCommon from "src/components/footer-common/FooterCommon";
import { Spinner, Tab } from "react-bootstrap";
import BigNumber from "bignumber.js";
import PersonalLoanEmiPopup from "src/components/personal-loan-emi-detail-popup/PersonalLoanEmiPopup";
import { Link } from "react-router-dom";
import './RequestPersonalLoan.scss'

function RequestPersonalLoan({
  getplatformTokensinfo,
  borrowError,
  userInfo,
  myaccountandbalance,
  getFiatBalanceinfo,
  getLiveAmountRateChanges,
  getDynamicInterest,
  dynamicInterestRateLoading,
  dynamicInterestRateError,
  borrowPersonalLoan,
  addPersonalLoanRequestLoading,
  plaformcurrencies,
  clearConversionAmountAsync,

}) {
console.log("getLiveAmountRateChanges", getLiveAmountRateChanges)
  const { t } = useTranslation();
  const location = useLocation();
  const [validated, setValidated] = useState();

  const [amountLoader, setAmountLoader] = useState("");
  const [successPopup, setShowSuccess] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValid2, setIsValid2] = useState(true);
  const [checkBox, setCheckBox] = useState(true);
  const [isValid3, setIsValid3] = useState(true);
  const [isValid4, setIsValid4] = useState(true);
  const [isValid5, setIsValid5] = useState(true);
  const [collateralCoinName, setCollateralCoinName] = useState();
  const [loanCurrencyId, setLoanCurrencyId] = useState(3);
  const [loanCurrencyInd, setLoanCurrencyInd] = useState(3);
  const[isValidate, setIsvaidate]= useState(true)

  const [loanName, setLoanName] = useState("");
  const [loanInterestRate, setLoanInterestRate] = useState("");
  const [loanTypeId, setLoanTypeId] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [maxLoanAmount, setMaxLoanAmount] = useState("");
  const [loanAmountError, setLoanAmountError] = useState("");
  const [loanTenure, setLoanTenure] = useState(6);
  const [prevLoanTenure, setPrevLoanTenure] = useState(0);
  const [description, setDescription] = useState("");
  const [isApply, setIsApply] = useState(false)
  const[principalAmountInUSDT, setPrincipalAmountInUSDT] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [addressProof, setaddressProof] = useState("");
  const [incomeStatement, setIncomeStatement] = useState("");
  const [borrwoLoanError, setBorrowLoanError] = useState(undefined)



  const [maxLoanTenure, setMaxLoanTenure] = useState("");

  useEffect(() => {
    clearConversionAmountAsync()
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;

  };

  const handleDescription = (e) => {
    const value = e.target.value;
    setDescription(value);
  };
  const handleInputChange2 = (e) => {
    setIsValid2(true);
    const value = e.target.value;
    setLoanAmount(value);
    // setIsValid2(value >= 0); // Check if the value is non-negative
    if (parseInt(e.target.value) > parseInt(maxLoanAmount)) {
      setLoanAmountError("The entered value is beyond the acceptable range. ");
      setIsValid2(false);
      return;
    }
    if (parseInt(e.target.value) <= 0) {
      setIsValid2(false);
      setLoanAmountError("Please enter a non-negative number.");
      return;
    }
  };

  
  const handleInputChange4 = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      setaddressProof(file);
      setIsValid4(true);
    }else{
      setaddressProof("")
      setIsValid4(false)
    }
    
  };
  
  const handleInputChange5 = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      setIncomeStatement(file);
      setIsValid5(true);
    }else{
      setIncomeStatement("")
      setIsValid5(false)
    }
      
    };
    
    const handleCheckChange = () => {
      setCheckBox(!checkBox);
    };
    
    useEffect(() => {
      if (
        location &&
        location.state !== undefined &&
        location.state.state.item !== undefined
        ) {
          setLoanName(location.state.state.item.loanName);
          setLoanInterestRate(location.state.state.item.loanInterestRate);
          setLoanTypeId(location.state.state.item.id);
          setMaxLoanAmount(location.state.state.item.loanAmount);
          setMaxLoanTenure(location.state.state.item.tenureInMonths);
        }
      }, [location]);
      
      const handleSecondFieldBlur = ()=>{
    if (loanAmount !== "" && loanTenure !== "") {
        const data = {
          loanTypeId: Number(loanTypeId),
          loanAmount: loanAmount,
          transactionCurrencyId: Number(loanCurrencyId),
          interestRate: loanInterestRate,
          tenureInMonths: loanTenure.toString(),
          tokenType: "fiat",
          // openSuccess: () => setShowModal(true),
          setBorrowLoanError,
        };
        getDynamicInterest(data);
        console.log("called")
      }
    }


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (checkBox === false) {
      setIsValid3("Please read and agree to the terms and conditions before proceeding.");
    } else {
        if(loanAmount==""){
          setIsValid2(false)
          return
        }
        if(!addressProof){
          setIsValid4(false)
          return
        }
        if(!incomeStatement){
          setIsValid5(false)
          return
        }
          setValidated(true);
          let data = {
            loanTypeId: loanTypeId.toString(),
            loanAmount: loanAmount,
            transactionCurrencyId:loanCurrencyId.toString(),
            interestRate: loanInterestRate,
            tenureInMonths: loanTenure.toString(),
            tokenType: "fiat",
            additionalInfo:description,
            walletId: userInfo.fiatWalletId,
            addressVerification:addressProof,
            incomeStatement:incomeStatement,
            principalAmountInUSDT:getLiveAmountRateChanges?.principal.toString(),
            openSuccess: () =>setShowSuccess(true),
            setBorrowLoanError,
          }
          borrowPersonalLoan(data)
    }
  };

  
  const restStates= ()=> {
    window.location.reload()
  }

  useEffect(() => {
    if (checkBox === true) {
      setIsValid3("");
    }
  }, [checkBox]);

  const handleSliderChange = (event) => {
    const newValue = event.target.value;
    setPrevLoanTenure(loanTenure);
    setLoanTenure(event.target.value);
  };

  const getColor = () => {
    const percentage = (loanTenure / (maxLoanTenure ? maxLoanTenure : 120)) * 100;
    const darkenPercentage = percentage / 2;
    return `hsl(210, 70%, ${100 - darkenPercentage}%)`;
  };

  const loanSubmitHandler = () => {
    let principalAmount = getLiveAmountRateChanges?.principal
    setPrincipalAmountInUSDT(principalAmount)
    setIsApply(true)
    setShowModal(false)
    setCheckBox(false)

  }
  const sliderStyles = {
    '::-webkit-slider-runnable-track': {
      background: 'red !important',
    },
  };
  const handleLoanAgreement=()=>{
    console.log("loan agreement")
  }

  return (
    <ScreenLayout
    >
      
      <PersonalLoanEmiPopup onClose={restStates} onClick={loanSubmitHandler} showModal={showModal} tenure={loanTenure} data={getLiveAmountRateChanges} setShowModal={setShowModal} />
      <Container>
        <div id="RequestPersonalLoan">
          <AdminLoanSuccesfulPopup
            setShowModal={setShowSuccess}
            showModal={successPopup}
            scope={"loanRequestPersonal"}
            message={
              "Loan Request submitted successfully! You can check Loan Request Page to check further details"
            }
          />
          <Form noValidate onSubmit={handleSubmit} validated={validated}>
          {(addPersonalLoanRequestLoading)&& <Loader />}

            <Row>
              <Col lg={12}>
                <div className="trouter">
                  <div className="transactiontitle">
                    <span className=" title">{t("Borrow")}</span>
                  </div>
                </div>
              </Col>
              <Col lg={12} className="colspace">
                <div className="titlepass">
                  <span className="titleText">{t("Loan Name")}</span>
                </div>

                <div className="inputboxpass">
                  <InputField
                    value={loanName}
                    required
                    disabled={true}
                    isInvalid={validated === false && loanName === ""}
                    error={"Loan Name cannot be empty"}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  {!isValid && (
                    <div className="titlepass">
                      <span className="errortext">
                        Please Select a Loan Type.
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={12} className="colspace">
                <div className="titlepass">
                  <span className="titleText">{t("Loan Amount")}</span>
                </div>

                <div className="inputboxpass">
                  <InputField
                    // disabled = {isApply}
                    type="number"
                    value={loanAmount}
                    required ={true}
                    onBlur={(e) => {
                      handleSecondFieldBlur(e)
                    }}
                    error={"Loan Amount cannot be empty"}
                    // disabled={amount !== ""}
                    onChange={(e) =>handleInputChange2(e)}
                  />

                  <div className="input-dropdown">
                    <DropdownSearch
                      Selected={loanCurrencyInd}
                      options={plaformcurrencies}
                      scope={"personalloan"}
                      getCollateralCoinName={(e) => setCollateralCoinName(e)}
                      setCoinId={(e) => setLoanCurrencyId(e)}
                      setSelectedIndex={(e) => {
                        setLoanCurrencyInd(e);
                      }}
                    />
                  </div>
                </div>
                {!isValid2 && (
                  <div className="titlepass">
                    <span className="errortext">{loanAmountError}</span>
                  </div>
                )}
              </Col>

              <Col lg={12} className="colspace">
                <div className="titlepass">
                  <span className="titleText">{t("Tenure (In Months)")}</span>
                </div>

                <div className="inputboxpass my-3">
                  <Form.Range
                      onBlur={(e) => {
                        handleSecondFieldBlur(e)
                      }}
                    // disabled = {isApply}
                    required={true}
                    value={loanTenure}
                    min={6}
                    max={maxLoanTenure ? maxLoanTenure : 120}
                    onChange={handleSliderChange}
                    className="slider"
                    style={sliderStyles}
                  />
    
                  <div className="text-center mt-2">{loanTenure}</div>
                  {dynamicInterestRateLoading &&
                  <Spinner animation="grow" className="spincolor" />
                  }
                  {!isValid && (
                    <div className="titlepass">
                      <span className="errortext">
                        Please Select a Loan Type.
                      </span>
                    </div>
                  )}
                </div>
                              <style>
        {`.slider::-webkit-slider-runnable-track { background: ${getColor()}; }`}
      </style>
              </Col>
                  <Col lg={12} className="colspace">
                  <div className="titlepass">
                    <span className="titleText">{t("Address Proof")}</span>
                  </div>
  
                  <div className="inputboxpass">
                    <InputField
                    classname={"file-upload-field"}
                      // value={addressProof}
                      type={"file"}
                      required ={true}
                      error={"Address Verification document is mandatory."}
                      onChange={(e) => {
                        handleInputChange4(e);
                      }}
                    />
                    
                  </div>
                </Col>

                   <Col lg={12} className="colspace">
                   <div className="titlepass">
                     <span className="titleText">{t("Income Statement")}</span>
                   </div>
   
                   <div className="inputboxpass">
                     <InputField
                    classname={"file-upload-field"}
                      //  value={incomeStatement}
                       type={"file"}
                       required ={true}
                       error={"Income Statement is mandatory."}
                       onChange={(e) => {
                         handleInputChange5(e);
                       }}
                     />
                     
                   </div>
                 </Col>
        

              <Col lg={12} className="colspace">
                <div className="titlepass">
                  <span className="titleText">{t("Description")}</span>
                </div>

                <div className="inputboxpass textarea-field">
                  <InputField
                    classname="text-area-field"
                    as="textarea"
                    value={description}
                    onChange={(e) => {
                      handleDescription(e);
                    }}
                  />
                </div>
              </Col>

              <Col lg={12}>
                <div className="section">
                  <span className="left">Annual Interest Rate</span>
                  <span className="right">{loanInterestRate}%</span>
                </div>
              </Col>
              <Col lg={12}>
                {getLiveAmountRateChanges !== undefined &&
                  getLiveAmountRateChanges.monthlyEmi !== undefined && (
                    <div className="section">
                      <span className="left">Monthly Emi (USD)</span>
                      <span className="right">
                        {getLiveAmountRateChanges.monthlyEmi}
                      </span>
                    </div>
                  )}
              </Col>

              <Col lg={12}>
                {getLiveAmountRateChanges !== undefined &&
                  getLiveAmountRateChanges.totalInterestPaid !== undefined && (
                    <div className="section">
                      <span className="left">Total interest (USD)</span>
                      <span className="right">
                        {getLiveAmountRateChanges.totalInterestPaid}
                      </span>
                    </div>
                  )}
              </Col>
              <Col lg={12}>
                {getLiveAmountRateChanges !== undefined &&
                  getLiveAmountRateChanges.totalAmount !== undefined && (
                    <div className="section">
                      <span className="left">Total Amount (USD)</span>
                      <span className="right">
                        {getLiveAmountRateChanges.totalAmount}
                      </span>
                    </div>
                  )}
              </Col>

              <Col lg={12}>
              <Form.Group className="form-check">
                <Form.Check
                 type="checkbox"
                 id="checkbox-agreement"
                 label={
                 <span>
                  I have read and I agree to{' '}<a  onClick={handleLoanAgreement} >Loan Service Agreement</a>
                  </span>
                   }
                   onChange={() => handleCheckChange()}
                   checked={checkBox}
                   />
                   </Form.Group>


                <Col>
                  {isValid3 !== "" && (
                    <div className="titlepass">
                      <span className="errortext">{isValid3}</span>
                    </div>
                  )}
                </Col>

                <Col>
                  {borrwoLoanError && (
                    <div className="titlepass">
                      {borrwoLoanError.map(item=>
                      <span className="errortext">{(item)}</span> )}
                    </div>
                  )}
                </Col>
                {!isValid2 && (
                      <div className="titlepass">
                        <span className="errortext">
                        Please enter the Loan Amount.
                        </span>
                      </div>
                    )}
                {!isValid4 && (
                      <div className="titlepass">
                        <span className="errortext">
                        Please upload the Address proof.
                        </span>
                      </div>
                    )}
                    {!isValid5 && (
                       <div className="titlepass">
                         <span className="errortext">
                           Please upload the income statement.
                         </span>
                       </div>
                     )}
                
              </Col>

              <Col>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                {amountLoader && (
                  <Spinner animation="grow" className="spincolor" />
                )}
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <div className="cancel">
                  <CustomButton
                    name={t("Borrow")}
                    filled={true}
                    primary={true}
                    type="submit"
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
      <FooterCommon />
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {}, user = {}, fiat = {} }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const userInfo = _.get(user, "userInfo", "");
  const borrowError = _.get(wallet, "addPersonalLoanRequestError", undefined);
  const addPersonalLoanRequestLoading = _.get(wallet, "addPersonalLoanRequestLoading", false)
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);


  const getLiveAmountRateChanges = _.get(wallet,"dynamicInterestRateForPersonalLoan",undefined);
  const dynamicInterestRateLoading = _.get(wallet,"getDynamicInterestRateLoading",false);
  const dynamicInterestRateError = _.get(wallet,"getDynamicInterestRateError",undefined);

  return {
    addPersonalLoanRequestLoading,
    getplatformTokensinfo,
    borrowError,
    userInfo,
    myaccountandbalance,
    getFiatBalanceinfo,
    getLiveAmountRateChanges,
    dynamicInterestRateLoading,
    dynamicInterestRateError,
    plaformcurrencies,
    
  };
};

const mapDispatchToProps = (dispatch) => ({
  borrowPersonalLoanCalled: (data) =>dispatch({ type: "addLoanRequestSagaCalled", payload: data }),
  getDynamicInterest: (data) =>dispatch({ type: "getDynamicInterestRateSagaCalled", payload: data }),
  borrowPersonalLoan: (data)=> dispatch({type: "borrowPersonalLoanSagaCalled", payload:data}),
  clearConversionAmountAsync: () => dispatch(cleargetInterestRateForPersonal()),


});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPersonalLoan);
