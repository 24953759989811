import { createSlice } from "@reduxjs/toolkit";

const translationSlice = createSlice({
  name: "Translation",
  initialState: {
    language: "en",
  },
  reducers: {
    changeLanguage: (state, action) => {
      return {
        ...state,
        language: action.payload,
      };
    },
  },
});

export default translationSlice.reducer;
export const { changeLanguage } = translationSlice.actions;
