import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import "@features/borrow-personal-loan/BorrowPersonalLoan.scss";
import Table from "react-bootstrap/esm/Table";
import ReactPaginate from "react-paginate";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import CoinList from "@components/coin-list/CoinList";
import Loader from "@components/loader/Loader";

import { useTranslation } from "react-i18next";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import "src/themes/OpenSans-Regular-normal.js";
import "jspdf-autotable";
import { useHistory } from "react-router-dom";

function BorrowPersonalLoan(props) {
  const {
    thememode,
    getAllpersonalLaonPublic,
    hideLoaderOnRefresh,
    allPersonalLloanErrorPublic,
    allPersonalLoanListPublic,
    allPersonalLoanLoadingPublic,
  } = props || {};
  let navigate = useHistory();
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [skip, setskip] = useState(0);


  const { t } = useTranslation();

  const handleBorrowPage = (item , index) => {
    navigate.push("/requestPersonalLoan", { state: { item , index } });
  };

  useEffect(() => {
    getAllpersonalLaonPublic({ skip, limit });


  }, []);


  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      loaderOnRefresh={hideLoaderOnRefresh}
    >
      <div id="borrowPersonalLoan">
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("borrowbankloan")}</span>
                  <div className="filtersection"></div>
                </div>
              </div>

                 <Table className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                  responsive="sm" borderless>
           
                {(allPersonalLoanLoadingPublic) && (
                  <Loader />
                )}
                <thead className="hidden-for-mobile">
                  <tr>
                
                    <th className="trtbleheading paddingleft">
                      <span className="heading">{t("No")}</span>
                    </th>
                    <th className="trtbleheading text-center">
                      <span className="heading">{t("Name")}</span>
                    </th>

                    <th className="trtbleheading text-center">
                      <span className="heading">{t("Tenure")} {(t("InMonths"))}{" "}</span>
                    </th>
                    
                    <th className="trtbleheading text-center">
                      <span className="heading">{t("LoanAmount")}</span>
                    </th>

                    <th className="trtbleheading text-center">
                      <span className="heading">{t("InterestRate")}</span>
                    </th>
                    <th className="trtbleheading text-center">
                      <span className="heading">{t("Clicktoborrow")}</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <>
                    {allPersonalLoanListPublic !== undefined &&
                    allPersonalLoanListPublic.data.length > 0 && (
                      allPersonalLoanListPublic.data.map((item, index) => (
                        <tr className="trtbleheading maintdwrapper">
                          <td className="innercontent">
                            <div className="innerTd d-lg-flex d-none">
                              {index +skip+1}
                            </div>
                            <div className="innerTd d-lg-none d-flex">
                              <p className="mobUpperline">
                                No{item.id} {item.loanName}
                              </p>
                              <p className="mobLowerline">
                                {item.tenureInMonths} Months
                              </p>
                            </div>
                          </td>
                          <td className="amt d-none d-lg-table-cell">
                            <div className="innerTd id smallwidth">
                              {item.loanName}
                            </div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd smallwidth">
                              {item.tenureInMonths}
                            </div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd">{item.loanAmount}</div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd">
                              {item.loanInterestRate} %
                            </div>
                          </td>
          
                          
                      <td>
                        <div className="innerTd">
                          <span
                            className="borrow"
                            onClick={() => {
                              handleBorrowPage(item , index);
                            }}
                          >
                            Borrow
                          </span>
                        </div>
                      </td>
                     
                        </tr>
                      ))
                    )} { allPersonalLoanListPublic !== undefined &&
                      allPersonalLoanListPublic.data.length  == 0 &&(
                      <p className="trtbleheading">
                        {t("Sorrynoresultsfound")}!
                      </p>
                    )}
                  </>
                </tbody>
              </Table>
              {allPersonalLoanListPublic !== undefined && allPersonalLoanListPublic.count > 10 && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(allPersonalLoanListPublic.count / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allPersonalLoanListPublic.count / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={(e) => {
                        setskip(e.selected * limit);
                        getAllpersonalLaonPublic({
                          skip: e.selected * limit,
                          limit,
                        });

                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, wallet = {} }) => {
  const allPersonalLoanLoadingPublic = _.get(wallet,"allPersonalLoanLoadingPublic",false);
  const allPersonalLoanListPublic = _.get(wallet, "allPersonalLoanListPublic", undefined);
  const allPersonalLloanErrorPublic = _.get(wallet,"allPersonalLloanErrorPublic",undefined);

  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);

  return {
    thememode,
    settings,
    allPersonalLloanErrorPublic,
    allPersonalLoanListPublic,
    allPersonalLoanLoadingPublic,
  };
};

const mapDispatchToProps = (dispatch) => ({
    getAllpersonalLaonPublic: (data) =>
    dispatch({ type: "GetPersonalLoanPublicSagaSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BorrowPersonalLoan);


