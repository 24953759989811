import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import InputField from "@components/input-field/InputField";
import CustomButton from "src/components/custom-button/CustomButton";
import { ReactComponent as PlusIcon } from "@images/plus.svg";
import { ReactComponent as DropdownIcon } from "@images/dropdownsearch/Dropdown.svg";
import DepositPopup from "@components/deposit-popup/DepositPopup.js";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import { Dropdown, FormControl, Form } from "react-bootstrap";
import "@components/fiat-form/FiatDepositFormStyles.scss";
import { useTranslation } from "react-i18next";
import AddBankAccountPopup1 from "@components/add-bank-account-popup1/AddBankAccountPopup1";
import { connect } from "react-redux";
import {
  clearDepositErrorAndSuccessMessages,
  clearAllFiatError,
} from "@store/fiatredux";
import Loader from "../loader/Loader";
import { setCurrentFiatInfo } from "@store/fiatredux";
import { setBanksForSpecificFiat } from "@store/fiatredux";
import { toast } from "react-toastify";
import copy from "@images/copy1.svg";
import bankimage from "@images/opening-a-bank-account-01 1.svg";
import { removeOwnerBankOnModalClose } from "@store/fiatredux";

const FiatDepositForm = ({
  getplatformTokensinfo,
  setShowModal,
  data,
  Idata,
  show1,
  exchangeRates,
  getExchangerate,
  createpaymentLink,
  myaccountandbalance,
  paymentToken,
  settings,
  val,

  //selectedCurrency,
  selectedBankAccounts,
  createDepositRequestError,
  createDepositRequest,
  createDepositRequestSuccess,
  plaformcurrencies,
  clearMessages,
  createDepositRequestLoading,
  setHideThis,
  activeBankAccounts,
  items,
  getAllOwnerBanks,
  setShowDeposit,
  allBankOnwerAccountNumber,
  setCurrentFiatInfoAsync,
  setBanksForSpecificFiat,
  showInfo,
  selectedBankOfOwner,
  thememode,
  removeOwnerBankOnClose,
  allPlatformBanks,
  setcoinItem,
}) => {
  const [amount, setamount] = useState("");
  const [selectedCurrency, setselectedCurrency] = useState(items);
  let [showDepositModal, setShowDepositModal] = useState(false);
  let [showAddBankAccountPopup1, setShowAddBankAccountPopup1] = useState(false);
  let [Selected, setSelectedIndex] = useState("");
  const [selectedBankAccountId, setSelectedBankAccountId] = useState();
  const [depositError, setDepositError] = useState("");
  const [options, setOptions] = useState();
  const [validated, setValidated] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [data1, setData1] = useState({});
  const [show, setShow] = useState(false);
  const [forFiatDeposit, setForFiatDeposit] = useState(false);
  const [bankNumber, setBankNumber] = useState("");
  let [twoBanksOfOwner, setTwoBanksOfOwner] = useState([]);
  let [newStateForIndex, setNewStateForIndex] = useState(0);
  let [dynamicCurrency, setDynamicCurrency] = useState(plaformcurrencies || []);
  //const [bankAccountHolderName, setBankAccountHolderName] = useState();
  const { t, i18n, ready } = useTranslation();
  useEffect(() => {
    getDynamicCurrency();
    getAllOwnerBanks();
  }, []);

  let getDynamicCurrency = () => {
    let newlistedcurrency = [];
    let currency =
      allPlatformBanks &&
      allPlatformBanks.filter((item) => {
        return item?.id === selectedBankOfOwner.id;
      });
    let ownersBankAccount =
      (currency && currency[0] && currency[0].ownersBankAccount) || [];
    ownersBankAccount &&
      ownersBankAccount.forEach((item) => {
        let obj;
        if (item.IBANs !== "") {
          obj = plaformcurrencies.filter(
            (itm) => itm.id === item.currencyId
          )[0];
          newlistedcurrency.push(obj);
        }
      });

    setDynamicCurrency(newlistedcurrency);
    if (
      newlistedcurrency &&
      newlistedcurrency[0] &&
      items &&
      items.id != newlistedcurrency[0].id
    ) {
      let indexofDrop =
        newlistedcurrency &&
        newlistedcurrency.findIndex((i) => i.id == items.id);
      if (indexofDrop != -1) {
        setNewStateForIndex(indexofDrop);
        setcoinItem(newlistedcurrency[indexofDrop]);
      } else {
        setNewStateForIndex(0);
        setcoinItem(newlistedcurrency[0]);
      }
    } else {
      if (items && items) {
        let indexofDrop =
          newlistedcurrency &&
          newlistedcurrency.findIndex((i) => i.id == items.id);
        setNewStateForIndex(indexofDrop);
        setcoinItem(newlistedcurrency[indexofDrop]);
        filterTwoBanksOfOwner();
      } else {
        setNewStateForIndex(0);
        setcoinItem(newlistedcurrency[0]);
        filterTwoBanksOfOwner();
      }
    }
  };

  useEffect(() => {
    if (selectedBankOfOwner) getDynamicCurrency();
  }, [selectedBankOfOwner]);

  useEffect(() => {
    setCurrentFiatInfoAsync(items || (dynamicCurrency && dynamicCurrency[0]));
    setselectedCurrency(items || (plaformcurrencies && plaformcurrencies[0]));
    (items || (plaformcurrencies && plaformcurrencies[0])) &&
      getBanksBasedOnCurrency();

    // if(items){
    //   setIndexOfCurrencyOnMount()
    //   filterTwoBanksOfOwner();
    // }

    // if(selectedCurrency !== undefined){

    //   getBanksBasedOnSelectedCurrency();
    // filterBanksForSelectedCurrency();
    // }
    //  to fix addbankpopup

    clearMessages();
  }, []);

  useEffect(() => {
    setCurrentFiatInfoAsync(items);

    if (items !== undefined) {
      getBanksBasedOnSelectedCurrency();
      filterBanksForSelectedCurrency();
    } else {
      getBanksBasedOnCurrency();
      filterTwoBanksOfOwner();
    }
  }, [items]);

  useEffect(() => {
    if (selectedCurrency !== undefined) {
      getBanksBasedOnSelectedCurrency();
    } else {
      getBanksBasedOnCurrency();
    }
  }, [activeBankAccounts.length]);

  useEffect(() => {
    if (selectedCurrency !== undefined) {
      getBanksBasedOnSelectedCurrency();
      filterBanksForSelectedCurrency();
    } else {
      filterTwoBanksOfOwner();
      getBanksBasedOnCurrency();
    }
  }, [selectedBankOfOwner]);

  const setIndexOfCurrencyOnMount = () => {
    for (let i = 0; i < plaformcurrencies.length; i++) {
      if (items !== undefined) {
        if (plaformcurrencies[i].id === items.id) {
          setNewStateForIndex(i);
        }
      } else {
        setNewStateForIndex(i);
      }
    }
  };

  useEffect(() => {
    if (selectedCurrency !== undefined) {
      getBanksBasedOnSelectedCurrency();
      filterBanksForSelectedCurrency();
    } else {
      filterTwoBanksOfOwner();
      getBanksBasedOnCurrency();
    }
    selectedCurrency && setCurrentFiatInfoAsync(selectedCurrency);
    //  getDynamicCurrency();
  }, [selectedCurrency]);

  useEffect(() => {
    if (selectedCurrency !== undefined) {
      getBanksBasedOnSelectedCurrency();
      filterBanksForSelectedCurrency();
    } else {
      filterTwoBanksOfOwner();
      getBanksBasedOnCurrency();
    }
  }, [newStateForIndex]);

  const getBanksBasedOnCurrency = () => {
    let bnOptions =
      activeBankAccounts &&
      activeBankAccounts
        .filter((account) => {
          if (account.currencyType !== null) {
            if (items !== undefined) {
              return (
                account.currencyType.includes(items.exchangeSymbol) &&
                account.bankName.includes(selectedBankOfOwner.bankName)
              );
            } else {
              return (
                account.currencyType.includes(
                  plaformcurrencies && plaformcurrencies[0].exchangeSymbol
                ) && account.bankName.includes(selectedBankOfOwner.bankName)
              );
            }
          } else {
            return;
          }
        })
        .map((item) => ({
          id: item.id,
          tokenName: `${item.userName}-${item.IBANs}`,
          ...item,
        }));

    setOptions(bnOptions);
    setBanksForSpecificFiat(bnOptions);
  };

  useEffect(() => {
    setDepositError(createDepositRequestError);
  }, [createDepositRequestError]);

  const forDepositModalOnly = () => {
    setHideThis(true);
  };

  useEffect(() => {
    if (selectedCurrency !== undefined) {
      getBanksBasedOnSelectedCurrency();
      filterBanksForSelectedCurrency();
    } else {
      filterTwoBanksOfOwner();
    }
  }, [items]);

  useEffect(() => {
    setCoinFromDropdown();
    if (selectedCurrency !== undefined) {
      getBanksBasedOnSelectedCurrency();
      filterBanksForSelectedCurrency();
    } else {
      getBanksBasedOnCurrency();
      filterTwoBanksOfOwner();
    }
  }, [newStateForIndex]);

  useEffect(() => {
    filterTwoBanksOfOwner();
    selectedCurrency && filterBanksForSelectedCurrency();
  }, [allBankOnwerAccountNumber]);

  const filterTwoBanksOfOwner = () => {
    let twoBanks =
      allBankOnwerAccountNumber &&
      allBankOnwerAccountNumber.length !== 0 &&
      allBankOnwerAccountNumber
        .filter((bank) => {
          if (bank && bank.IBANs !== undefined) {
            if (
              items !== undefined &&
              selectedCurrency &&
              selectedCurrency.exchangeSymbol
            ) {
              return (
                bank.currencyType === selectedCurrency.exchangeSymbol &&
                bank.bankId === selectedBankOfOwner.id
              );
            } else {
              return (
                bank.currencyType ===
                  (dynamicCurrency &&
                  dynamicCurrency[0] &&
                  dynamicCurrency[0].exchangeSymbol
                    ? dynamicCurrency[0].exchangeSymbol
                    : plaformcurrencies[0].exchangeSymbol) &&
                bank.bankId === selectedBankOfOwner.id
              );
            }
          } else {
            return;
          }
        })
        .map((item) => ({
          accountholdername: item?.bank?.accountHoldersName,
          iban: item.IBANs,
        }));
    setTwoBanksOfOwner(twoBanks);
  };

  const filterBanksForSelectedCurrency = () => {
    let twoBanks2 =
      allBankOnwerAccountNumber &&
      allBankOnwerAccountNumber.length !== 0 &&
      selectedCurrency &&
      selectedCurrency.exchangeSymbol !== undefined &&
      allBankOnwerAccountNumber
        .filter((bank) => {
          if (bank && bank.IBANs !== undefined) {
            return (
              bank.currencyType === selectedCurrency.exchangeSymbol &&
              bank.bankId === selectedBankOfOwner.id
            );
          } else {
            return;
          }
        })
        .map((item) => ({
          accountholdername: item?.bank?.accountHoldersName,
          iban: item.IBANs,
        }));
    setTwoBanksOfOwner(twoBanks2);
  };

  const getBanksBasedOnSelectedCurrency = () => {
    let bnOptions =
      activeBankAccounts &&
      activeBankAccounts
        .filter((account) => {
          if (
            account.currencyType !== null &&
            account.Status !== "Pending" &&
            selectedCurrency &&
            selectedCurrency.exchangeSymbol !== undefined
          ) {
            return (
              account.currencyType.includes(selectedCurrency.exchangeSymbol) &&
              account.bankName.includes(selectedBankOfOwner.bankName)
            );
          } else {
            return;
          }
        })
        .map((item) => ({
          id: item.id,
          tokenName: `${item.userName}-${item.IBANs}`,
          ...item,
        }));
    setOptions(bnOptions);
    setBanksForSpecificFiat(bnOptions);
  };

  useEffect(() => {
    createDepositRequestSuccess && forDepositModalOnly();
  }, [createDepositRequestSuccess]);

  const setCoinFromDropdown = () => {
    setselectedCurrency(dynamicCurrency[newStateForIndex]);
    setcoinItem(dynamicCurrency[newStateForIndex]);
  };

  const CreateDepositRequest = () => {
    const data = {
      CurrencyId: parseInt(selectedCurrency.id || plaformcurrencies[0].id),
      bankaccountId: parseInt(options[Selected].id),
      amount: amount,
    };

    createDepositRequest(data);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid var(--borderlight)",
      backgroundColor: "var(--bgwhite)",
      fontSize: "13px",
      color: state.isSelected ? "blue" : "var(--textlight)",
    }),
  };

  const handleClick = async (valueFromChild) => {
    setSelectedIndex(valueFromChild);
    setForFiatDeposit(false);
  };

  return (
    <div className="fiatdepositform">
      {createDepositRequestLoading && <Loader />}
      <AddBankAccountPopup1
        showAddBankConfirmation={showAddBankAccountPopup1}
        setShowAddBankConfirmation={setShowAddBankAccountPopup1}
        onPress={() => {}}
        toCallBanks={() => {
          if (selectedCurrency !== undefined) {
            setCurrentFiatInfoAsync(selectedCurrency);
            getBanksBasedOnSelectedCurrency();
          } else {
            setCurrentFiatInfoAsync(items);
            getBanksBasedOnCurrency();
          }
        }}
        onCancelled={() => {
          //setHideThis(false);

          setForFiatDeposit(false);
          setShowAddBankAccountPopup1(false);
        }}
        setHideThis={(e) => {
          setHideThis(e);
        }}
        update={handleClick}
        setShowDeposit={setShowDeposit}
        scope="FiatDeposit"
      />
      <DepositPopup
        createDepositRequestSuccess={createDepositRequestSuccess}
        datashown={data1}
        // show={show}
        setshow={() => {
          setShow(false);
          setHideThis(false);
          setShowDeposit(false);
        }}
        onCancelled={() => {
          setHideThis(false);
          setShowDeposit(false);

          setShowAddBankAccountPopup1(true);
        }}
        setHideThis={(e) => setHideThis(e)}
        setShowDep={() => setShowDeposit(true)}
        setRemoveBank={setSelectedIndex}
        setRemoveAmount={setamount}
      />
      <div className="fieldssectionmargin">
        <div className="labeloffield">
          <span className="label">{t("SelectedBank")}</span>
        </div>
        {selectedBankOfOwner && selectedBankOfOwner !== undefined ? (
          <div className="banklogo">
            {thememode != "light" ? (
              <img
                className="bankimg"
                src={`${process.env.REACT_APP_IMGLINK}${selectedBankOfOwner.colouredLogo}`}
              />
            ) : (
              <img
                className="bankimg"
                src={`${process.env.REACT_APP_IMGLINK}${selectedBankOfOwner.nonColouredLogo}`}
              />
            )}
            <span className="newbankname">{selectedBankOfOwner.bankName}</span>
          </div>
        ) : (
          ""
        )}
        {plaformcurrencies && plaformcurrencies.length !== 0 && (
          <div
            className="currencydropdown"
            onClick={() => {
              setCoinFromDropdown();
              // getDropdownCurrencyBasedOnOwner();
              // getBanksBasedOnCurrency();
            }}
          >
            <DropdownSearch
              options={dynamicCurrency && dynamicCurrency}
              exchangeSymbolshow
              setSelectedIndex={(e) => {
                setNewStateForIndex(e);
              }}
              Selected={newStateForIndex}
              // scope="FiatDeposit"
            />
          </div>
        )}
      </div>
      <div className="fieldssectionmargin"></div>

      <div className="fieldssectionmargin">
        <div className="labeloffield">
          <span className="label">IBAN</span>
        </div>
        <div className="formfield">
          <div className="inputfieldholder">
            {twoBanksOfOwner !== undefined && twoBanksOfOwner.length !== 0 && (
              <div className="infotext">{twoBanksOfOwner[0]?.iban}</div>
            )}
          </div>
          <div className="copysection">
            <img
              onClick={() => {
                navigator.clipboard.writeText(
                  twoBanksOfOwner !== undefined && twoBanksOfOwner[0]?.iban
                );
                toast.success(t(`${"copied"}`), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }}
              src={copy}
              className="comtxt"
            />
          </div>
        </div>
      </div>
      <div className="fieldssectionmargin">
        <div className="labeloffield">
          <span className="label">
            {t("Name")} / {t("Surname")}
          </span>
        </div>
        <div className="formfield">
          <div className="inputfieldholder">
            {twoBanksOfOwner !== undefined &&
            twoBanksOfOwner[0]?.accountholdername !== undefined ? (
              <div className="infotext">
                {twoBanksOfOwner[0]?.accountholdername}
              </div>
            ) : (
              <div className="infotext"></div>
            )}
            {/* {bankAccountHolderName !== undefined ? (
              <div className="infotext">{bankAccountHolderName}</div>
            ) : (
              <div className="infotext"></div>
            )} */}
          </div>

          <div className="copysection">
            <img
              onClick={() => {
                navigator.clipboard.writeText(
                  twoBanksOfOwner !== undefined &&
                    twoBanksOfOwner[0]?.accountholdername !== undefined &&
                    twoBanksOfOwner[0].accountholdername
                );
                toast.success(t(`${"copied"}`), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }}
              src={copy}
              className="comtxt"
            />
          </div>
        </div>
      </div>
      <div className="fieldssectionmargin">
        <div className="labeloffield">
          <span className="label"> {t("BankAccountFromWhichDeposit")}</span>
        </div>
        <div className="radiolable">
          {
            // activeBankAccounts && activeBankAccounts.length !== 0 ?
            options && options.length !== 0 ? (
              <>
                {forFiatDeposit ? (
                  <div className="inputfieldholder">
                    {Selected !== undefined &&
                    Selected.bank !== undefined &&
                    Selected.bank.bankAccountNumber !== undefined
                      ? bankNumber
                      : ""}
                  </div>
                ) : (
                  <div className="inputfieldholder">
                    <DropdownSearch
                      options={options && options}
                      setSelectedIndex={(e) => {
                        setSelectedIndex(e);
                      }}
                      Selected={Selected}
                      scope="FiatDeposit"
                    />
                  </div>
                )}

                <div className="logomodified">
                  <div className="logoholder">
                    <PlusIcon
                      className="tickmark"
                      onClick={() => {
                        setHideThis(true);
                        setForFiatDeposit(true);
                        setShowAddBankAccountPopup1(true);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="formfieldnew">
                <div className="inputfieldholder">
                  <InputField
                    type="text"
                    placeholder={t("DefineNewBankAccount")}
                    classname={"withoutleftborder"}
                    // onChange={(e) => setaddress(e.target.value)}
                    // value={address}
                    disabled
                    required
                  />
                </div>
                <div className="logosection">
                  <div className="logoholder">
                    <PlusIcon
                      className="tickmark"
                      onClick={() => {
                        setHideThis(true);

                        setShowAddBankAccountPopup1(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div className="fieldssectionmargin m-btm">
        <div className="allignleft">
          <div className="labeloffield amtleft">
            <span className="label">{t("Amount")}</span>
            {/* <span className="amtrighttext">(0,00 Turkish lira)</span> */}
          </div>

          {/* <div className="amtright">
            <span className="lira">{items && items.currencyName}</span>
          </div>*/}
        </div>
        <div className="formfield">
          <div className="inputfieldholder">
            <InputField
              type="text"
              placeholder=""
              classname={"withoutleftborder"}
              onChange={(e) => setamount(e.target.value)}
              value={amount}
              required
            />
          </div>
          <div className="logosection">
            <div className="logoholder dropdown">
              {/* <Dropdown
                align="end"
                autoClose="true"
                onSelect={(eventKey, event) => {
                  let obj =
                    plaformcurrencies &&
                    plaformcurrencies.filter(
                      (item) => item.exchangeSymbol == event.target.text
                    );
                  setselectedCurrency(obj[0]);
                }}
              >
                <Dropdown.Toggle> */}
              <span className="filtertext">
                {selectedCurrency && selectedCurrency.exchangeSymbol
                  ? selectedCurrency.exchangeSymbol
                  : plaformcurrencies[0].exchangeSymbol}
              </span>
              {/* <DropdownIcon className="droparrow" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {plaformcurrencies &&
                    plaformcurrencies.map((currency) => (
                      <>
                        <Dropdown.Item
                          eventKey={currency.exchangeSymbol}
                          key={currency.exchangeSymbol}
                        >
                          {currency.exchangeSymbol}
                        </Dropdown.Item>
                      </>
                    ))}
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>
        </div>
      </div>

      <div className="detail mar">
        <div className="label">
          <span className="labeltext">{t("AmountToDeposit")}</span>
        </div>
        <div className="value">
          <span className="valuetext transectionid">
            {amount}{" "}
            {selectedCurrency && selectedCurrency.exchangeSymbol
              ? selectedCurrency.exchangeSymbol
              : dynamicCurrency &&
                dynamicCurrency[0] &&
                dynamicCurrency[0].exchangeSymbol
              ? dynamicCurrency[0].exchangeSymbol
              : plaformcurrencies[0].exchangeSymbol}
          </span>
        </div>
      </div>

      <div className="detail mar ">
        <div className="label">
          <span className="labeltext">
            {t("TransactionFee")} (VAT {t("Included")})
          </span>
        </div>
        <div className="value">
          <span className="valuetext transectionid red">
            0{" "}
            {selectedCurrency && selectedCurrency.exchangeSymbol
              ? selectedCurrency.exchangeSymbol
              : dynamicCurrency &&
                dynamicCurrency[0] &&
                dynamicCurrency[0].exchangeSymbol
              ? dynamicCurrency[0].exchangeSymbol
              : plaformcurrencies[0].exchangeSymbol}
          </span>
        </div>
      </div>

      <div className="detail mar m-btm">
        <div className="label">
          <span className="labeltext">{t("DepositAmount")}</span>
        </div>
        <div className="value">
          <span className="valuetext">
            {amount}{" "}
            {selectedCurrency && selectedCurrency.exchangeSymbol
              ? selectedCurrency.exchangeSymbol
              : dynamicCurrency &&
                dynamicCurrency[0] &&
                dynamicCurrency[0].exchangeSymbol
              ? dynamicCurrency[0].exchangeSymbol
              : plaformcurrencies[0].exchangeSymbol}
          </span>
        </div>
      </div>
      {/*  <div className="detail mar m-btm">
        <div className="label">
          <span className="labeltext">{t("Cryptocurrency")}</span>
        </div>
         <div className="value">
          <span className="valuetext">0,00 {selectedCurrency && selectedCurrency.exchangeSymbol ? selectedCurrency.exchangeSymbol : plaformcurrencies[0].exchangeSymbol}</span>
        </div> 
      </div>*/}
      <div className="boxtitle">
        <span className="boxtitletext">{t("СheckWithBankTransfer")}</span>
      </div>
      {/* <div className="currencytoconvert">
        <div className="box">
          <ul>
           
            <li>
              {t("BankTransferLine1")}:
              <span className="bold"> 1,000,000.00 TL</span>
            </li>
            <li>
              {t("BankTransferLine2")}:
              <span className="bold">5,000,000.00 TL</span>
            </li>
            <li>
              {t("BankTransferLine3")}
              <span className="bold">10 TL {t("OrMore")}</span>
            </li>
            <li>{t("BankTransferLine4")}</li>
          </ul>
        </div>
      </div> */}
      <div className="currencytoconvert">
        <div className="box">
          <ul>
            <li>
              {t("Remaining24hourDepositLimit")}:
              <span className="bold">
                {" "}
                {selectedCurrency &&
                  selectedCurrency.maxWithdrawLimitaDay &&
                  selectedCurrency.maxWithdrawLimitaDay}{" "}
                {selectedCurrency &&
                  selectedCurrency.exchangeSymbol &&
                  selectedCurrency.exchangeSymbol}
              </span>
            </li>
            <li>
              {t("MonthlyDepositlimit")}:
              <span className="bold">
                {" "}
                {selectedCurrency &&
                  selectedCurrency.maxDepositLimitPerMonth &&
                  selectedCurrency.maxDepositLimitPerMonth}{" "}
                {selectedCurrency &&
                  selectedCurrency.exchangeSymbol &&
                  selectedCurrency.exchangeSymbol}
              </span>
            </li>
            <li>
              {t("BankTransferValidForDeposit")}
              <span className="bold">
                {" "}
                {selectedCurrency &&
                  selectedCurrency.minimumvalue &&
                  selectedCurrency.minimumvalue}{" "}
                {selectedCurrency &&
                  selectedCurrency.exchangeSymbol &&
                  selectedCurrency.exchangeSymbol}{" "}
                {t("OrMore")}
              </span>
            </li>

            <li>
              {t("BankTransferLine4_1")}{" "}
              {t(
                selectedCurrency &&
                  selectedCurrency.currencyName &&
                  selectedCurrency["currencyName"].trim()
              )}{" "}
              {t("BankTransferLine4_2")}{" "}
              {selectedCurrency &&
                selectedCurrency.exchangeSymbol &&
                selectedCurrency.exchangeSymbol}
            </li>
          </ul>
        </div>
      </div>
      {
        <div className="buttoncontainer">
          <CustomButton
            type="submit"
            name={t("SubmitPullRequest")}
            filled={true}
            primary={true}
            disable={
              !_.isNumber(Selected) ||
              amount == "" ||
              (options &&
                options[Selected] &&
                options[Selected].Status === "Pending")
            }
            onClick={() => {
              CreateDepositRequest();
              setShow(true);
              const item = {
                CurrencySymbol:
                  selectedCurrency.exchangeSymbol ||
                  dynamicCurrency[0].exchangeSymbol,
                bankId: parseInt(options[Selected].bankId),
                amount: amount,
              };

              setData1(item);
            }}
          />
        </div>
      }

      <br />
      <span className="errors">{t(`${depositError}`)}</span>
    </div>
  );
};

const mapStateToProps = ({ admin = {}, fiat = {}, wallet = {}, app = {} }) => {
  const selectedBankAccounts = _.get(fiat, "selectedBankAccounts", []);
  const createDepositRequestError = _.get(
    fiat,
    "createDepositRequestError",
    ""
  );
  const createDepositRequestSuccess = _.get(
    fiat,
    "createDepositRequestSuccess",
    undefined
  );

  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const createDepositRequestLoading = _.get(
    fiat,
    "createDepositRequestLoading",
    false
  );
  const activeBankAccounts = _.get(fiat, "getAllBankAccountDetails", []);
  const allBankOnwerAccountNumber = _.get(
    fiat,
    "getAllOwnerBankData",
    undefined
  );
  const thememode = _.get(app, "thememode", false);

  const selectedBankOfOwner = _.get(fiat, "selectedBank", []);
  const allPlatformBanks = _.get(fiat, "fiatBank", []);
  return {
    selectedBankAccounts,
    createDepositRequestError,
    // createDepositRequestSuccess,
    createDepositRequestSuccess,
    plaformcurrencies,
    createDepositRequestLoading,
    activeBankAccounts,
    allBankOnwerAccountNumber,
    selectedBankOfOwner,
    thememode,
    allPlatformBanks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createDepositRequest: (data) =>
    dispatch({ type: "depositFiatTrigger", payload: data }),
  clearMessages: () => dispatch(clearAllFiatError()),
  getAllOwnerBanks: () => dispatch({ type: "getAllOwnerBankAccountStart" }),
  setCurrentFiatInfoAsync: (data) => dispatch(setCurrentFiatInfo(data)),
  setBanksForSpecificFiat: (data) => dispatch(setBanksForSpecificFiat(data)),
  removeOwnerBankOnClose: () => dispatch(removeOwnerBankOnModalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FiatDepositForm);
