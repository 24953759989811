import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import "@features/completed-transaction/CompletedTransactionStyles.scss";
import Table from "react-bootstrap/esm/Table";
import tcheck from "@images/tcheck.svg";
import deposit from "@images/deposit.svg";
import withdraw from "@images/withdraw.svg";
import pending from "@images/pending.svg";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import { ReactComponent as CopyIcon } from "@images/copy.svg";
import { ReactComponent as SquareIcon } from "@images/tcheck.svg";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import CoinList from "@components/coin-list/CoinList";
import { Row, Col, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import PaymentPopup from "@components/payment-popup/PaymentPopup.js";
import { ToastContainer, toast } from "react-toastify";
import Filter from "@components/filter/Filter.js";
import CustomButton from "src/components/custom-button/CustomButton";
import printer from "@images/printer.svg";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import PaymentSuccess from "@components/paymentSuccess-popup/paymentSuccess";
import PaymentDetailPopup from "src/components/paymentdetail-popup/PaymentDetailPopup";
import BusinessTransactionPopup from "@components/business-transaction-popup/BusinessTransactionPopup";
import { ReactComponent as PrinterIcon } from "@images/printer.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import { useTranslation } from "react-i18next";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import PaymentCancelConfirmation from "@components/payment-cancel-confirmation/PaymentCancelConfirmation";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import "jspdf-autotable";
import { ClearSansBase64 } from "src/custom-fonts-base64/index";

function CompletedTransaction(props) {
  const {
    exchangeRates,
    createpaymentLink,
    paymenthistory,
    getpaymenthistoryCount,
    myusertransactionloading,
    getplatformTokensinfo,
    getExchangerate,
    getPaymentHistory,
    myaccountandbalance,
    getPaymentHistoryyCount,
    setPaymentlink,
    paymentlinkmodal,
    paymentlinksuccmodal,
    setPaymentlinkSucc,
    paymentToken,
    createloaders,
    paymentbytoken,
    settings,
    thememode,
    getcomissionper,
    commissionPercentage,
    createPaymentFail,
    hideLoaderOnRefresh,
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("fourth");
  let [show, setshow] = useState(false);
  let [showi, setshowi] = useState(false);
  let [Idata, setIdata] = useState({});
  let [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  let [selectedPaymentID, setSelectedPaymentID] = useState();
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [statusType, setStatusType] = useState("");
  const [isFilterSet, setIsFilterSet] = useState(true);
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [obj, setObj] = useState("");
  const [hideSpec, setHideSpec] = useState(true);
  const [compRef, setCompRef] = useState();
  const [language, setLanguage] = useState("en");
  const componentRef = useRef();
  useEffect(() => {
    setCompRef(componentRef);
  }, []);
  useEffect(() => {
    if (hideSpec === false) {
      setCompRef(componentRef);
      handlePrint();
      setHideSpec(true);
    }
  }, [hideSpec]);

  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });

  useEffect(() => {
    getPaymentHistory({ type: "paid", skip: pages, limit });
    getPaymentHistoryyCount({ type: "paid" });
    setpages(0);
    // filterPaymentInfoForExcelExport();
  }, []);

  useEffect(() => {
    setLanguage(localStorage.lang);
    filterPaymentInfoForExcelExport();
  }, [localStorage.lang]);
  const { t } = useTranslation();
  useEffect(() => {
    filterPaymentInfoForExcelExport();
  }, [getpaymenthistoryCount, paymenthistory]);
  const filterPaymentInfoForExcelExport = () => {
    let filtered = [];
    let headerList = [];
    if (paymenthistory && paymenthistory.length !== 0) {
      for (let i of paymenthistory) {
        let newOBJ = {
          PaymentID: i.id,
          Date:
            i.createdOn !== undefined
              ? moment(i.createdOn).local().format("MM/DD/YYYY hh:mm A")
              : "",
          Merchant: `${i.merchant.name} ${i.merchant.subName}`,
          PaymentLink: `${window.location.origin}/pay/${i && i.tokenLink}`,
          CoinToAccept: i.acceptAs !== undefined ? i.acceptAs : "",
          CoinToConvert: i.exchangeTo !== undefined ? i.exchangeTo : "",
          Amount: Number(i.amount).toFixed(6),
          Status: t(i.paymentStatus),
        };

        filtered.push(newOBJ);
      }

      for (let i of Object.keys(filtered[0])) {
        let newOBJ = {
          title: t(i),
          dataIndex: i,
        };
        headerList.push(newOBJ);
      }

      let pack = {
        data: filtered,
        head: headerList,
      };

      setExcelHeader(pack.head);
      setExcelData(pack.data);

      return pack;
    }
    return filtered;
  };
  const generatePdf = () => {
    var doc = new jsPDF();
    doc.addFileToVFS("ClearSans-Regular.ttf", ClearSansBase64);
    doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
    doc.setFont("ClearSans", "Regular");
    var col = [
      `${t("PaymentID")}`,
      `${t("Date")}`,
      `${t("Merchant")}`,
      `${t("PaymentLink")}`,
      `${t("CoinToAccept")}`,
      `${t("CoinToConvert")}`,
      `${t("Amount")}`,
      `${t("Status")}`,
    ];

    var rows = [];

    if (paymenthistory && paymenthistory.length !== 0) {
      paymenthistory.forEach((element) => {
        var temp = [
          element.id,
          moment(element.createdOn).local().format("MM/DD/YYYY hh:mm A"),
          `${element.merchant.name} ${element.merchant.subName}`,
          `${window.location.origin}/pay/${element && element.tokenLink}`,
          element.acceptAs,
          element.exchangeTo,
          Number(element.amount).toFixed(6),
          t(element.paymentStatus),
        ];

        rows.push(temp);
      });
    }
    doc.autoTable(col, rows, {
      startY: 5,

      styles: {
        font: "ClearSans",
        fontSize: 8,
      },
    });
    doc.save(`${t("CompletedTransaction")}.pdf`);
  };
  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      loaderOnRefresh={hideLoaderOnRefresh}
    >
      <div id="completed">
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("CompletedTransaction")}</span>
                  <div className="filtersection">
                    <Filter
                      onApplyDate={(picker) => {
                        setIsFilterSet(true);
                        getPaymentHistory({
                          skip: 0,
                          limit,
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          type: "paid",
                          orderBy: orderBy,
                          sort: sortBy,
                          obj: obj,
                        });
                        getPaymentHistoryyCount({
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          type: "paid",
                          orderBy: orderBy,
                          sort: sortBy,
                          obj: obj,
                        });
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                      oncancel={() => {
                        setStartDate({});
                        setEndDate({});
                        setIsFilterSet(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* deleted class actions */}
              <div className="actions">
                <div className="rightsection">
                  <div className="thethreebutton">
                    <div className="buttoncontainersm">
                      <ReactToPrint
                        trigger={() => (
                          <CustomButton
                            type="submit"
                            name={t("Print")}
                            testvalue={
                              <PrinterIcon className="iconcolor mleft" />
                            }
                            text={true}
                            image={true}
                            //   onClick={() => {
                            //     setcoinItem(items);
                            //     setShowDeposit(true)
                            //   }}
                          />
                        )}
                        content={() => componentRef.current}
                        onBeforeGetContent={() => {
                          orientation: "landscape";
                          setHideSpec(false);
                        }}
                      />
                    </div>

                    <div className="buttoncontainersm">
                      <CustomButton
                        type="submit"
                        name="PDF"
                        testvalue={<PdfIcon className="mleft" />}
                        text={true}
                        image={true}
                        onClick={() => {
                          generatePdf();
                        }}
                      />
                    </div>

                    <div className="buttoncontainersm mr-0">
                      <ExportSheet
                        header={excelHeader}
                        fileName={`download`}
                        dataSource={excelData}
                        xlsx={XLSX}
                      >
                        <CustomButton
                          type="submit"
                          name={t("Excel")}
                          testvalue={<ExcelIcon className="mleft" />}
                          text={true}
                          image={true}
                          onClick={() => {
                            filterPaymentInfoForExcelExport();
                          }}
                        />
                      </ExportSheet>
                    </div>
                  </div>
                </div>
              </div>
              <BusinessTransactionPopup
                setShowModal={setshowi}
                show1={showi}
                data={Idata}
              />
              <Table
                responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless
                ref={componentRef}
              >
                {myusertransactionloading && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading">{/* <SquareIcon /> */}</th>
                    <th className="trtbleheading">
                      <span className="heading">{t("PaymentID")}</span>{" "}
                      <span className="sorticon">
                        <SortupIcon
                          className="uparrow"
                          onClick={() => {
                            setOrderBy("id");
                            setSortBy("ASC");
                            if (isFilterSet) {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                startDate,
                                endDate,
                                orderBy: "id",
                                sort: "ASC",
                              });
                            } else {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                orderBy: "id",
                                sort: "ASC",
                              });
                            }
                          }}
                        />

                        <SortdownIcon
                          className="downarrow"
                          onClick={() => {
                            setOrderBy("id");
                            setSortBy("DESC");
                            if (isFilterSet) {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                startDate,
                                endDate,
                                orderBy: "id",
                                sort: "DESC",
                              });
                            } else {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                orderBy: "id",
                                sort: "DESC",
                              });
                            }
                          }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("Date")}</span>{" "}
                      <span className="sorticon">
                        <SortupIcon
                          className="uparrow"
                          onClick={() => {
                            setOrderBy("createdOn");
                            setSortBy("ASC");
                            if (isFilterSet) {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                startDate,
                                endDate,
                                orderBy: "createdOn",
                                sort: "ASC",
                              });
                            } else {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                orderBy: "createdOn",
                                sort: "ASC",
                              });
                            }
                          }}
                        />

                        <SortdownIcon
                          className="downarrow"
                          onClick={() => {
                            setOrderBy("createdOn");
                            setSortBy("DESC");
                            if (isFilterSet) {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                startDate,
                                endDate,
                                orderBy: "createdOn",
                                sort: "DESC",
                              });
                            } else {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                orderBy: "createdOn",
                                sort: "DESC",
                              });
                            }
                          }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("Merchant")}</span>{" "}
                      <span className="sorticon">
                        <SortupIcon
                          className="uparrow"
                          onClick={() => {
                            setOrderBy("name");
                            setObj("merchant");
                            setSortBy("ASC");

                            if (isFilterSet) {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                startDate,
                                endDate,
                                orderBy: "name",
                                sort: "ASC",
                                obj: "merchant",
                              });
                            } else {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                orderBy: "name",
                                sort: "ASC",
                                obj: "merchant",
                              });
                            }
                          }}
                        />

                        <SortdownIcon
                          className="downarrow"
                          onClick={() => {
                            setOrderBy("name");
                            setObj("merchant");
                            setSortBy("DESC");

                            if (isFilterSet) {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                startDate,
                                endDate,
                                orderBy: "name",
                                sort: "DESC",
                                obj: "merchant",
                              });
                            } else {
                              getPaymentHistory({
                                skip: pages * limit,
                                limit,
                                type: "paid",
                                orderBy: "name",
                                sort: "DESC",
                                obj: "merchant",
                              });
                            }
                          }}
                        />
                      </span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("PaymentLink")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("CoinToAccept")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("Amount")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("CoinToConvert")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading">{t("Status")}</span>
                    </th>
                    <th className="trtbleheading"></th>
                  </tr>
                </thead>

                <tbody>
                  {paymenthistory &&
                    paymenthistory.map((item, index) => (
                      <tr
                        key={`trans${index}`}
                        onClick={() => {
                          setIdata(item);
                          setshow(true);
                        }}
                        className="maintdwrapper"
                      >
                        <td>
                          {hideSpec &&
                          <div className="innerTd  ">
                            {item.paymentStatus == "Cancelled" ? (
                              <img src={withdraw} className="imgstatus" />
                            ) : item.paymentStatus == "Paid" ||
                              item.paymentStatus == "paid" ? (
                              <img src={deposit} className="imgstatus" />
                            ) : (
                              <img src={pending} className="imgstatus" />
                            )}
                          </div>
}
                        </td>
                        
                        <td className="amt">
                          {" "}
                          <div className="innerTd id idtr">
                            {item.id}

                            <span className="idtxt">
                              {" "}
                              {hideSpec &&
                              <span className=" hidden-for-desktop">
                                {item.merchant.name} {item.merchant.subName}
                              </span>}{" "}
                            </span>
                          {hideSpec &&
                            <p className="hidden-for-desktop date">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </p>
}
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd id datewidth hidden-for-mobile ">
                            {moment(item.createdOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </td>
                        {/* for design it is hiddent temporarily it will be added to show once data is integrated */}
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd id">
                            <p className="merchant">
                              {" "}
                              {item.merchant.name} {item.merchant.subName}
                            </p>
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className=" link innerTd id ">
                            <a className="linktxt">
                              {" "}
                              {`${window.location.origin}/pay/${
                                item && item.tokenLink
                              }`}{" "}
                            </a>

                            <div className="vertical-align">
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    {t("CopyPaymentLink")}
                                  </Tooltip>
                                }
                              >
                                <CopyIcon
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${window.location.origin}/pay/${
                                        item && item.tokenLink
                                      }`
                                    );
                                    toast.success(t(`${"copied"}`), {
                                      position: toast.POSITION.TOP_RIGHT,
                                    });
                                  }}
                                  className="iconCopy"
                                />
                              </OverlayTrigger>
                            </div>
                          </div>
                        </td>
                        {hideSpec ? (
                          <td className=" amt hidden-for-mobile ">
                            <div className=" id  innerTd innerTdforprofile  ">
                              <CoinList
                                name={item.acceptAs}
                                logoBgColor={
                                  item &&
                                  item.fromPaymentLinkCoin &&
                                  item.fromPaymentLinkCoin.logoBgColor
                                }
                                logo={
                                  item &&
                                  item.fromPaymentLinkCoin &&
                                  item.fromPaymentLinkCoin.nonColouredLogo
                                }
                              />
                            </div>
                          </td>
                        ) : (
                          <td className=" amt hidden-for-mobile ">
                            <div className=" id  innerTd innerTdforprofile  ">
                              <p>{item.acceptAs}</p>
                            </div>
                          </td>
                        )}

                        <td className="amount hidden-for-mobile">
                          <div className="innerTd id">
                            <span className="amttxt">
                              {" "}
                              {Number(item.currencyAmount).toFixed(2)}{" "}
                              {item.fiatExchangeSymbol}{" "}
                            </span>
                          </div>
                        </td>
                        {hideSpec ? (
                          <td className=" amt ">
                            <div className=" id  innerTd innerTdforprofile  ">
                              <span className="amttxt  hidden-for-desktop ">
                                {Number(item.currencyAmount).toFixed(2)}{" "}
                                {item.fiatExchangeSymbol}{" "}
                              </span>

                              <p className="coin-for-mobile align-coin">
                                <CoinList
                                  name={item.exchangeTo}
                                  logoBgColor={
                                    item &&
                                    item.toPaymentLinkCoin &&
                                    item.toPaymentLinkCoin.logoBgColor
                                  }
                                  logo={
                                    item &&
                                    item.toPaymentLinkCoin &&
                                    item.toPaymentLinkCoin.nonColouredLogo
                                  }
                                />
                              </p>
                            </div>
                          </td>
                        ) : (
                          <td className=" amt ">
                            <div className=" id  innerTd innerTdforprofile  ">
                            
                            { hideSpec && <span className="amttxt  hidden-for-desktop ">
                                {Number(item.currencyAmount).toFixed(2)}{" "}
                                {item.fiatExchangeSymbol}{" "}
                              </span>
}

                              <p className="coin-for-mobile align-coin">
                                {item.exchangeTo}
                              </p>
                            </div>
                          </td>
                        )}

                        {item.paymentStatus == "Paid" ||
                        item.paymentStatus == "paid" ? (
                          <td className="completed hidden-for-mobile">
                            <div className="statusr innerTd id">
                              <span className="status green">
                                {" "}
                                {t("Completed")}{" "}
                              </span>
                            </div>
                          </td>
                        ) : item.paymentStatus == "Cancelled" ? (
                          <td className="cancel hidden-for-mobile">
                            <div className="statusr innerTd id">
                              <span className="status red">
                                {" "}
                                {t("Cancelled")}{" "}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td className="pending hidden-for-mobile">
                            <div className="statusr innerTd id ">
                              <span className="status yellow">
                                {" "}
                                {t("Pending")}{" "}
                              </span>
                            </div>
                          </td>
                        )}
                        {hideSpec ? (
                          <td className=" ">
                            <div className="vertical innerTd id">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="threedot"
                                  id="dropdown-basic"
                                >
                                  <VerticleIcon className="arrowclass hidden-for-mobile" />
                                  <Replacementof3dots
                                    className=" arrow hidden-for-desktop"
                                    // onClick={() => {
                                    //   setIdata(item);
                                    //   setshow(true);
                                    // }}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      //  setcoinItem(items);
                                      setshowi(true);
                                    }}
                                  >
                                    {t("Details")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
              {getpaymenthistoryCount > 10 && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(getpaymenthistoryCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={getpaymenthistoryCount / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={(e) => {
                        if (isFilterSet) {
                          getPaymentHistory({
                            skip: e.selected * limit,
                            limit,
                            type: "paid",
                            startDate,
                            endDate,
                            orderBy: orderBy,
                            sort: sortBy,
                            obj: obj,
                          });
                        } else {
                          getPaymentHistory({
                            skip: e.selected * limit,
                            limit,
                            type: "paid",
                            orderBy: orderBy,
                            sort: sortBy,
                            obj: obj,
                          });
                        }
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({
  app = {},
  wallet = {},
  paymentlink = {},
  translationLanguage = {},
}) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const paymenthistory = _.get(paymentlink, "paymenthistory", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const myusertransactionloading = _.get(
    paymentlink,
    "paymenthistoryLoading",
    false
  );
  const getpaymenthistoryCount = _.get(
    paymentlink,
    "getpaymenthistoryCount.count",
    10
  );
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const paymentlinkmodal = _.get(app, "paymentlinkmodal", false);
  const paymentlinksuccmodal = _.get(app, "paymentlinksuccmodal", false);
  const paymentToken = _.get(paymentlink, "paymentToken", false);
  const createPaymentFail = _.get(paymentlink, "createPaymentFail", "");
  const createloaders = _.get(paymentlink, "createPaymentLoading", false);

  const paymentbytoken = _.get(paymentlink, "paymentbytoken", false);
  const commissionPercentage = _.get(
    paymentlink,
    "commissionPercentage",
    false
  );
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const currentLang = _.get(translationLanguage, "language", false);
  return {
    thememode,
    settings,
    mywalletsinfo,
    paymenthistory,
    myaccountandbalance,
    getpaymenthistoryCount,
    myusertransactionloading,
    getplatformTokensinfo,
    exchangeRates,
    paymentlinkmodal,
    paymentlinksuccmodal,
    paymentToken,
    createloaders,
    paymentbytoken,
    commissionPercentage,
    currentLang,
    createPaymentFail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),
  getPaymentHistoryyCount: (data) =>
    dispatch({ type: "getpaymetHistoryCountCalled", payload: data }),
  getPaymentHistory: (data) =>
    dispatch({ type: "getPaymentHistoryCalled", payload: data }),
  createpaymentLink: (data) =>
    dispatch({ type: "createpaymentLinkCalled", payload: data }),
  setPaymentlink: (data) =>
    dispatch({ type: "setPaymentlinkCalled", payload: data }),
  setPaymentlinkSucc: (data) =>
    dispatch({ type: "setPaymentlinkSuccCalled", payload: data }),
  getcomissionper: (data) =>
    dispatch({ type: "getcomissionperCalled", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedTransaction);
