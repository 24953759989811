export default {
  about: "Про PayCrypt Protection System",
  Downloadext:
    "Скачать систему защиты PayCrypt Security для браузера Google Chrome",
  ChromeExtension: "Расширение Chrome",
  CyborProtectionSystem: "PayCrypt Protection System",
  applicationforcontrol:
    "Универсальная система защиты персональных данных и крипто активов доступная всем",
  freeappcyborprotection:
    "PayCrypt Protection System - надежная защита личной информации и финансовых средств. PayCrypt представляет надежную систему защиты крипто активов от злоумышленников, шпионского программного обеспечения, вредоносных программ и вымогателей в сети.",
  cyborprotectionforchrome:
    "Ее использование помогает предотвратить утечку платежной информации при осуществлении транзакций и обеспечивает шифрование информации на разных уровнях.",
  cyborprotectionvpn:
    "Система защиты PayCrypt считается лучшим VPN для Google Chrome миллионами пользователей по всему миру. Подключайтесь к нашим безопасным серверам со всего мира, чтобы попробовать неограниченный бесплатный VPN и получить доступ к заблокированным сайтам, потоковому контенту и многому другому.",
  installvpn:
    "PayCrypt Protection System отличается удобством для пользователей. Юзабилити находится на высшем уровне. Технологии и инструменты, предложенные разработчиками являются интуитивно понятными для людей и максимально соответствуют предъявляемым к ним требованиям.",
  CyborProtectionSystemApp: "Приложение PayCrypt Protection System",
};
