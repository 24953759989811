import { call, put } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import { businessSuceessSuccess , createBranchSuccessPoup } from "./appredux";

import {
  createBranchStart,
  createBranchSuccess,
  createBranchFailure,
  listAllBranchesStart,
  listAllBranchesSuccess,
  listAllBranchesFailure,
  deleteBranchStart,
  deleteBranchSuccess,
  deleteBranchFailure,
  editBranchStart,
  editBranchSuccess,
  editBranchFailure,
  updateStatusSuccess,
  updateStatustrigger,
  getBranchpaymentListSuccess,
  clearBranchReduxAllErrors,
} from "./branchmanagementredux";
 import { getAccessToken } from "./utilities";

export function* createBranchAsync(api, { payload }) {
  yield put(createBranchStart());
  let token = yield getAccessToken();
  const {
    image,
    branchName,
    address,
    phone,
    status,
    id,
    accountName,
    email,
    exchangeId,
    password,
    lang
  } = payload;
  try {
    const data = yield call(Api.callServer, api.CreateBranch, {
      image,
      branchName,
      address,
      phone,
      status,
      id,
      accountName,
      email,
      exchangeId,
      password,
      token,
      lang
    });
    
    yield put(createBranchSuccess());
    // yield put(createBranchSuccessPoup(true));
    yield put(businessSuceessSuccess(true));
    // yield put({ type: "listAllBranchesStart" });
    yield put(listAllBranchesStart());
    const data2 = yield call(
      Api.callServer,
      api.ListAllBranches,
      {
         token,
        // orderBy:payload.orderBy,
        // sort: payload.sort
        },
      true,
    );
    yield put(listAllBranchesSuccess(data2));
  } catch (error) {
    yield put(createBranchFailure(error.message));
  }
}

export function* listAllBranchesAsync(api, { payload }) {
  yield put(listAllBranchesStart());
  
  try {
    let token = yield getAccessToken();
    const data = yield call(
      Api.callServer,
      api.ListAllBranches,
      {
         token,
        orderBy:payload.orderBy,
        sort: payload.sort
        },
      true,
    );
    yield put(listAllBranchesSuccess(data));
  } catch (error) {
    yield put(listAllBranchesFailure(error.message));
  }
}

export function* deleteBranchAsync(api, { payload }) {
  yield put(deleteBranchStart());

  const { branchId } = payload;
  let token = yield getAccessToken();

  try {
    const data = yield call(Api.callServer, api.DeleteBranch, {
      branchId,
      token,
    });
    yield put(deleteBranchSuccess());
    yield put(listAllBranchesStart());
    const data2 = yield call(
      Api.callServer,
      api.ListAllBranches,
      {
         token,
        // orderBy:payload.orderBy,
        // sort: payload.sort
        },
      true,
    );
    yield put(listAllBranchesSuccess(data2));
  } catch (error) {
    yield put(deleteBranchFailure(error.message));
    yield put(listAllBranchesStart({ token: payload.userAccessToken }));
  }
}

export function* editBranchAsync(api, { payload }) {
  yield put(editBranchStart());
  let token = yield getAccessToken();
  const { branchId, image, branchName, address, phone, status, accountName,   imageAdded,
    formdata} =
    payload;
  try {
    const data = yield call(Api.callServer, api.EditBranch, {
      branchId,
      image,
      branchName,
      address,
      phone,
      status,
      accountName,
      token,
   
    });
    if(imageAdded)
     yield call(
      Api.callServer,
      api.UpdateBranchProfileImage,
      {
        formdata,
        token,
        branchId
      },
      true
    );
    // yield put({ type: "listAllBranchesStart" });
    
    yield put(editBranchSuccess());
    yield put(listAllBranchesStart());
    const data2 = yield call(
      Api.callServer,
      api.ListAllBranches,
      {
         token,
        // orderBy:payload.orderBy,
        // sort: payload.sort
        },
      true,
    );
    yield put(listAllBranchesSuccess(data2));
   
  } catch (error) {
    console.log('error',error.message)
    yield put(editBranchFailure(error.message));
  }
}

export function* updateBranchStatus(api, { payload }) {
  yield put(updateStatustrigger(true));
  let token = yield getAccessToken();
  const {
    branchId,

    status,
  } = payload;
  try {
    const data = yield call(Api.callServer, api.updateBranchStatus, {
      branchId,
      token,
      status,
    });
    const result = yield call(
      Api.callServer,
      api.ListAllBranches,
      { token },
      false
    );
    yield put(updateStatusSuccess(result));
    yield put(updateStatustrigger(false));
  } catch (error) {
    yield put(updateStatustrigger(false));
   }
}

export function* getBranchpayment(api, { payload }) {
   try {
    let token = yield getAccessToken();
    const data = yield call(
      Api.callServer,
      api.getBranchpaymentList,
      { token },
      false
    );
    yield put(getBranchpaymentListSuccess(data));
  } catch (error) {
   }
}

export function* clearBranchError(){
  yield put(clearBranchReduxAllErrors());
}