import CustomButton from "@components/custom-button/CustomButton";
import Header from "@components/header/Header";
import InputField from "@components/input-field/InputField";
import Loader from "@components/loader/Loader";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import UploadPopup from "@components/upload-popup/UploadPopup.js";
import RequestSent from "@components/request-sent/RequestSent";
import "@features/onboarding/business-signup/BusinessSignupStyle.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import { closeBusinessForm } from "@store/userredux";
function BusinessSignUp(props) {
  const [compname, setcompname] = useState("");
  const [address, setaddress] = useState("");
  const [validated, setValidated] = useState(false);
  const [email, setemail] = useState("");
  const [error, seterror] = useState("");
  const [error2, seterror2] = useState("");
  const [person, setperson] = useState("");
  const [phone, setphone] = useState(" ");
  const [imageser, setImage] = useState({});
  const [setupimage, setsetupimage] = useState(false);
  const [showPopup, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  let fileRef = React.createRef();

  const { exchange, thememode, show2, setshow2, show, setshow } = props;
  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (setupimage) {
      seterror2("");
      if (form.checkValidity() === true) {
        event.preventDefault();
        // show(!businessForm)
        props.businessForm({
          companyAddress: address,
          companyContact: phone,
          companyEmail: email,
          companyName: compname,
          contactPerson: compname,
          imageserver: imageser,
        });
      }
    } else {
      event.preventDefault();
      seterror2("Select Document");
    }
  };

  const { t } = useTranslation();

  return (
    <div>
      <RequestSent show={show1} setShowModal={setShow1} />
      <Modal
        show={show}
        dialogClassName="modal-90w"
        // onHide={() => show(!businessForm)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="content"
        id="BusinessSignUp"
      >
        <Modal.Body>
          {/* <CloseIcon className="closebtn" onClick={() => setShow(false)} /> */}
          {/* <img
            alt="cybor"
            src={
              thememode == "dark"
                ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessDarkmode}`
                : `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessLightmode}`
            }
            className="imgonsignup"
          /> */}

          <img src={paymentsolutionlogo} className="imgonsignup"/>

          <div className="firsttext">
            <div className="firsttextlineone">
              <span className="lineonestyle">
                {t("WelcometoCyborPayfrombusiness")}!
              </span>
            </div>
            <div className="firsttextlinetwo">
              <span className="linetwostyle">
                {t("Tostartuseallthefeatures")}
              </span>
            </div>
          </div>
          <Form noValidate validated={validated} onSubmit={onSendmsg}>
            <Row>
              <div className="formfieldholder">
                <InputField
                  type="text"
                  placeholder={t("companyname")}
                  onChange={(e) => setcompname(e.target.value)}
                  value={compname}
                  required
                  error={t("validname")}
                  // isInvalid={validated && (name.length < 3 || re.test(name))}
                  // error={
                  //   !re.test(name) ? "First name is too short" : "Invalid name"
                  // }
                />
              </div>

              <div className="formfieldholder">
                <InputField
                  type="text"
                  placeholder={t("address")}
                  onChange={(e) => setaddress(e.target.value)}
                  value={address}
                  required
                  error={t("enteraddress")}
                />
              </div>

              <div className="formfieldholder">
                <InputField
                  phone
                  type="Phone"
                  placeholder={t("phone")}
                  onChange={(e) => setphone(e)}
                  value={phone}
                  required
                  isInvalid={validated && phone.length < 10}
                  error={t(`${error}`)}
                />
              </div>

              <div className="formfieldholder">
                <InputField
                  type="text"
                  placeholder={t("emailid")}
                  onChange={(e) => setemail(e.target.value)}
                  value={email}
                  required
                  error={t("enteremailid")}
                />
              </div>

              <div className="formfieldholder">
                <InputField
                  type="text"
                  placeholder={t("contactperson")}
                  onChange={(e) => setperson(e.target.value)}
                  value={person}
                  required
                  error={t("contactname")}
                />
              </div>

              <div className="bottomtext">
                <div className="bottomtextfirstline">
                  <span className="bottomtextline1">
                    *{t("UploadyourDocuments")}:
                  </span>
                </div>
                <div className="bottomtextsecondline">
                  <span className="bottomtextline2">
                    *{t("documensconfirmingyouractivities")}
                  </span>
                </div>
              </div>
              <input
                hidden
                onChange={(event) => {
                  let set = event.target.files[0];
                  setImage(event.target.files[0]);
                  if (set && set.name) {
                    setsetupimage(true);
                  } else {
                    setsetupimage(false);
                  }
                }}
                type="file"
                id="inputfile"
                ref={fileRef}
                className="inputfilebtn"
              />
              <div className="linktag">
                {imageser && imageser.name}
                <a
                  className="text"
                  onClick={() => {
                    fileRef.current.click();
                  }}
                >
                  {t("upload")}
                </a>
              </div>

              <Col lg={12}>
                {" "}
                <Col className="mt-1 greyText" md="auto">
                  {t(`${error2}`)}
                </Col>
                <Col className="mt-1 greyText" md="auto">
                  {t(`${props.signUpError}`)}
                </Col>
                {props.businessFormLoading && (
                  <Loader loading={props.businessFormLoading} />
                )}
                <div className="outerconforbtn">
                  <CustomButton
                    disable={props.signUpLoading}
                    type="submit"
                    name={t("verify")}
                    filled={true}
                    primary={true}
                    onClick={() => {
                      setShow1(true);
                      props.closeBusiness();
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const mapStateToProps = ({ app = {}, user = {} }) => {
  const businessFormLoading = _.get(user, "businessFormLoading", false);

  const signUpError = _.get(user, "signUpError", "");

  const exchangeId = _.get(app, "settings.exchange.id", "");
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);

  return { businessFormLoading, signUpError, exchangeId, exchange, thememode };
};

const mapDispatchToProps = (dispatch) => ({
  businessForm: (data) =>
    dispatch({ type: "businessFormCalled", payload: data }),
  closeBusiness: () => dispatch(closeBusinessForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSignUp);
