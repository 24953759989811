import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import charttable from "@images/charttable.png";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import CoinList from "@components/coin-list/CoinList";
import "@components/coin-table/CoinTable.scss";
import _ from "lodash";
import Withdrawal from "../withdrawal/Withdrawal";
import ExchangeModal from "../exchange-modal/ExchangeModal";
import DepositModal from "../deposit-modal/DepositModal";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SmallButton from "@components/small-button/SmallButton";
import { ResponsiveLine } from "@nivo/line";
import { getCSymbol, getESymbol } from "src/utils/Currencies";
import WithdrawalFiat from "@components/withdrawal-fiat/WithdrawalFiat";
import DepositFiatModal from "@components/depositfiat-modal/DepositFiatModal";
import BigNumber from "bignumber.js";

function CoinTable({
  getplatformTokensinfo,
  gpt,
  getBal,
  getAdd,
  bnbdata,
  bnbpdata,
  ethdata,
  ethpdata,
  btcdata,
  btcpdata,
  busddata,
  busdpdata,
  withdramodal,
  setwithdrawModal,
  coinItem,
  setcoinItem,
  exchangemodal,
  setExchangeModal,
  getLockBal,
  setDepositModal,
  depositModal,
  searchvalue,
  // setFiatDepositModal,
  trxdata,
  trxpdata,
  // setFiatWithdrawModal,
  role,
  max,
  min,
  chartData,
  percentChanges,
  onClick,
  activeSelectedCoin,
  filteredTokens,
  setFilteredTokens,
  trycpdata,
  trycdata,
  PreferredCurrency,
  platformBanks,
  fiatDepositModal,
  setFiatDepositModal,
  fiatrate,
  hideZeroBalance,
  // sortUpCoins,

  // sortDownCoins
}) {
  let [pages, setpages] = useState(0);
  let [tableNo, setTableno] = useState("first");
  const [fiatWithdrawModal, setFiatWithdrawModal] = useState(false);
  const [show, setShowDeposit] = useState(false);
  const [value, setvalue] = useState("");
  const [limit, setlimit] = useState(9);
  const [platformTokensinfo, setPlatformTokensinfo] = useState(
    getplatformTokensinfo
  );

  // useEffect(() => {
  //   if (searchvalue !== "" && searchvalue !== null) {
  //     setPlatformTokensinfo(
  //       getplatformTokensinfo.filter((data) =>
  //         data.tokenName.toLowerCase().includes(searchvalue.toLowerCase())
  //       )
  //     );
  //   } else {
  //     setPlatformTokensinfo(getplatformTokensinfo);
  //   }
  // }, [getplatformTokensinfo]);

  useEffect(() => {}, [filteredTokens]);

  useEffect(() => {}, []);
  const gettextcolor = (tokenName) => {
    // console.log('data' , tokenName);
    let cond = "";
    if (tokenName == "Ethereum") {
      // console.log('ethpdata' , ethpdata.P)
      if (ethpdata && ethpdata.P.includes("-")) {
        cond = "-";
      } else if (ethpdata && ethpdata.P != 0) {
        cond = "+";
      } else {
        cond;
      }
    }
    if (tokenName == "Bitcoin") {
      // console.log('btcdata' , btcpdata.P)
      if (btcpdata && btcpdata.P.includes("-")) {
        cond = "-";
      } else if (btcpdata && btcpdata.P != 0) {
        cond = "+";
      } else {
        cond;
      }
    }
    if (tokenName == "BSC") {
      if (bnbpdata && bnbpdata.P.includes("-")) {
        cond = "-";
      } else if (bnbpdata && bnbpdata.P != 0) {
        cond = "+";
      } else {
        cond;
      }
    }
    if (tokenName == "Tether") {
      if (busdpdata && busdpdata.P.includes("-")) {
        cond = "-";
      } else if (busdpdata && busdpdata.P != 0) {
        cond = "+";
      } else {
        cond;
      }
    }
    if (tokenName == "Tron") {
      if (trxpdata && trxpdata.P.includes("-")) {
        cond = "-";
      } else if (trxpdata && trxpdata.P != 0) {
        cond = "+";
      } else {
        cond;
      }
    }
    if (tokenName == "TRYCoin") {
      if (trycpdata && trycpdata.P.includes("-")) {
        cond = "-";
      } else if (trxpdata && trxpdata.P != 0) {
        cond = "+";
      } else {
        cond;
      }
    }
    return cond;
  };

  const { t, i18n, ready } = useTranslation();

  const trsection = (items, index) => {
    return (
      <tr
        className={`maintdwrapper ${
          activeSelectedCoin && activeSelectedCoin == items.id && "activetr"
        }`}
        key={`Tokens${index}`}
        onClick={() => onClick && onClick(items.id)}
      >
        <td className="colspace">
          <div className="innerTd">
            <div className="carea d-none d-lg-block">
              <CoinList
                type={items.type}
                logo={items.nonColouredLogo}
                name={items.tokenName}
                logoBgColor={items.logoBgColor}
              />

              {
                <span className="cointag">
                  {items.type == "ETH"
                    ? "ERC20"
                    : items.type == "BSC"
                    ? "BEP20"
                    : items.type == "TRON"
                    ? "TRC20"
                    : items.type}
                </span>
              }
            </div>
            <div className="carea d-block d-lg-none">
              <CoinList
                logo={items.nonColouredLogo}
                name={items.tokenName}
                logoBgColor={items.logoBgColor}
                symbol={items.exchangeSymbol}
                type={items.type}
                shownamewithsymbol={true}
              />
              {
                <span className="cointag d-none d-lg-block">
                  {items.type == "ETH"
                    ? "ERC20"
                    : items.type == "BSC"
                    ? "BEP20"
                    : items.type == "TRON"
                    ? "TRC20"
                    : items.type}
                </span>
              }
            </div>
          </div>
        </td>
        <div className="tokentagwrapper d-block d-lg-none">
          {
            <div className="tokentag">
              {items.type == "ETH"
                ? "ERC20"
                : items.type == "BSC"
                ? "BEP20"
                : items.type == "TRON"
                ? "TRC20"
                : items.type}
            </div>
          }
        </div>
        {/* <div className="tokentag d-block d-lg-none">{items.exchangeSymbol}</div> */}
        <td className="balance colspace d-none d-lg-block">
          <div className="innerTd">
            {BigNumber(getBal(items.id)).toFixed(4, BigNumber.ROUND_FLOOR)}
          </div>
        </td>
        <td className="price colspace">
          <div className="innerTd">
            <div className="contentinnertdstyle">
              {getCSymbol(PreferredCurrency)}{" "}
              {items.tokenName == "Ethereum"
                ? BigNumber(ethdata && ethdata.p)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[getESymbol(PreferredCurrency)] &&
                        fiatrate[getESymbol(PreferredCurrency)].value) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)
                : items.tokenName == "Bitcoin"
                ? BigNumber(btcdata && btcdata.p)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[getESymbol(PreferredCurrency)] &&
                        fiatrate[getESymbol(PreferredCurrency)].value) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)
                : items.tokenName == "BSC"
                ? BigNumber(bnbdata && bnbdata.p)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[getESymbol(PreferredCurrency)] &&
                        fiatrate[getESymbol(PreferredCurrency)].value) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)
                : items.tokenName == "Tether"
                ? BigNumber(busddata && busddata.p)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[getESymbol(PreferredCurrency)] &&
                        fiatrate[getESymbol(PreferredCurrency)].value) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)
                : items.tokenName == "Tron"
                ? BigNumber(trxdata && trxdata.p)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[getESymbol(PreferredCurrency)] &&
                        fiatrate[getESymbol(PreferredCurrency)].value) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)
                : items.tokenName == "TRYCoin"
                ? BigNumber(1)
                    .dividedBy(trycdata && trycdata.p)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[getESymbol(PreferredCurrency)] &&
                        fiatrate[getESymbol(PreferredCurrency)].value) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)
                : BigNumber(0.15)
                    .multipliedBy(
                      (fiatrate &&
                        fiatrate[getESymbol(PreferredCurrency)] &&
                        fiatrate[getESymbol(PreferredCurrency)].value) ||
                        1
                    )
                    .toFixed(2, BigNumber.ROUND_FLOOR)}
              <div
                className={`d-block d-lg-none ${
                  gettextcolor(items.tokenName) == "+"
                    ? "completed"
                    : gettextcolor(items.tokenName) == "-"
                    ? "cancel"
                    : "completed"
                }`}
              >
                <div>
                  {items.tokenName == "Ethereum"
                    ? Number(ethpdata && ethpdata.P).toFixed(2)
                    : items.tokenName == "Bitcoin"
                    ? Number(btcpdata && btcpdata.P).toFixed(2)
                    : items.tokenName == "BSC"
                    ? Number(bnbpdata && bnbpdata.P).toFixed(2)
                    : items.tokenName == "Tether"
                    ? Number(busdpdata && busdpdata.P).toFixed(2)
                    : items.tokenName == "Tron"
                    ? Number(trxpdata && trxpdata.P).toFixed(2)
                    : items.tokenName == "TRYCoin"
                    ? Number(trycpdata && trycpdata.p).toFixed(2)
                    : Number("0.15").toFixed(2)}
                  %
                </div>
              </div>
            </div>
          </div>
        </td>
        <td
          className={`d-none d-lg-block change colspace ${
            gettextcolor(items.tokenName) == "+"
              ? "completed"
              : gettextcolor(items.tokenName) == "-"
              ? "cancel"
              : "price"
          }`}
        >
          <div className="innerTd">
            {gettextcolor(items.tokenName) == "+" ? "+" : ""}
            {items.tokenName == "Ethereum"
              ? BigNumber(ethpdata && ethpdata.P).toFixed(
                  2,
                  BigNumber.ROUND_FLOOR
                )
              : items.tokenName == "Bitcoin"
              ? BigNumber(btcpdata && btcpdata.P).toFixed(
                  2,
                  BigNumber.ROUND_FLOOR
                )
              : items.tokenName == "BSC"
              ? BigNumber(bnbpdata && bnbpdata.P).toFixed(
                  2,
                  BigNumber.ROUND_FLOOR
                )
              : items.tokenName == "Tether"
              ? BigNumber(busdpdata && busdpdata.P).toFixed(
                  2,
                  BigNumber.ROUND_FLOOR
                )
              : items.tokenName == "Tron"
              ? BigNumber(trxpdata && trxpdata.P).toFixed(
                  2,
                  BigNumber.ROUND_FLOOR
                )
              : items.tokenName == "TRYCoin"
              ? BigNumber(trxpdata && trxpdata.p).toFixed(
                  2,
                  BigNumber.ROUND_FLOOR
                )
              : BigNumber("0.00").toFixed(2)}
            %
          </div>
        </td>{" "}
        <td className={`price colspace`}>
          <div className="innerTd rotate180deg">
            <div
              className={`${
                percentChanges[items.itemIndex] &&
                percentChanges[items.itemIndex].priceChangePercent.includes("-")
                  ? "redgraph"
                  : "greengraph"
              } coingraphsection`}
            >
              {chartData[items.itemIndex] && (
                <ResponsiveLine
                  data={[
                    {
                      id: "japan",
                      color: "hsl(295, 70%, 50%)",
                      data: chartData[items.itemIndex] || [],
                    },
                  ]}
                  enableGridY={false}
                  keys={["close"]}
                  margin={{
                    top: 20,
                    right: 0,
                    bottom: 40,
                    left: 0,
                  }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  enableArea={true}
                  useMesh={false}
                  enablePoints={false}
                  enableCrosshair={false}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={null}
                  isInteractive={false}
                  axisLeft={null}
                  enableStackTooltip={false}
                  enableGridX={false}
                  curve="cardinal"
                  offsetType="none"
                  colors={{ scheme: "paired" }}
                  borderColor={"#637EEB"}
                  borderWidth={1}
                  dotSize={0}
                  dotColor={{ from: "color" }}
                  dotBorderWidth={1}
                  dotBorderColor={{
                    from: "color",
                    modifiers: [["darker", 0.7]],
                  }}
                  animate={true}
                />
              )}
            </div>
          </div>
        </td>
        <td className="colspace1 actions">
          <div className="innerTd">
            <div
              className={`smallbuttons d-none d-lg-flex ${
                role == "Business" && "busssmallbuttons"
              }`}
            >
              <SmallButton
                type="deposit"
                hideButtonName={true}
                onclick={() => {
                  setcoinItem(items);
                  setDepositModal();
                }}
              />

              <SmallButton
                type="withdraw"
                hideButtonName={true}
                onclick={() => {
                  setcoinItem(items);
                  setwithdrawModal();
                }}
              />
              {tableNo !== "second" && (
                <SmallButton
                  type="exhange"
                  hideButtonName={true}
                  onclick={() => {
                    setcoinItem(items);
                    setExchangeModal();
                  }}
                />
              )}
            </div>
            <div className="mobilescrbal d-block d-lg-none">
              <p className="mainbal">
                {BigNumber(getBal(items.id)).toFixed(4)}
              </p>
              <p className="mainsym">{items.exchangeSymbol}</p>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="cointable">
        {
          <Table responsive="sm" className="tablestyle" borderless>
            <thead>
              <tr>
                <th className="tableheading coincol">
                  {t("Coin")}{" "}
                  {/* <span className="sorticon">
                    <SortupIcon
                      className="uparrow"
                      onClick={() => sortUpCoins() }
                    />

                    <SortdownIcon
                      className="downarrow"
                      onClick={() => sortDownCoins() }
                    />
                  </span> */}
                </th>
                <th className="tableheading">
                  {t("Balance")}{" "}
                  <span className="sorticon">
                    {/* <SortupIcon className="uparrow" />

                    <SortdownIcon className="downarrow" /> */}
                  </span>
                </th>
                <th className="tableheading">
                  {t("Price")} {`(${getCSymbol(PreferredCurrency)})`}
                  <span className="sorticon">
                    {/* <SortupIcon className="uparrow" />

                    <SortdownIcon className="downarrow" /> */}
                  </span>
                </th>
                <th className="tableheading">{t("Changes")}</th>
                <th className="tableheading">{t("Chart")}</th>
                <th className="tableheading">{t("Function")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredTokens &&
                filteredTokens
                  .slice(pages * limit, pages * limit + limit)
                  .map((items, index) => {
                    if (hideZeroBalance) {
                      return trsection(items, index);
                    }
                    if (!hideZeroBalance) {
                      return trsection(items, index);
                    }
                  })}
            </tbody>
          </Table>
        }

        <DepositModal
          getAdd={getAdd}
          setcoinItem={setcoinItem}
          value={value}
          coinItem={coinItem}
          setvalue={setvalue}
          getplatformTokensinfo={getplatformTokensinfo}
          getBal={getBal}
          showToggle={true}
          show={depositModal}
          setdepositModal={(e) => setDepositModal(e)}
          tableno={tableNo}
          settableno={(e) => {
            setDepositModal(false);
            setFiatDepositModal(true);
          }}
        />
        <Withdrawal
          getAdd={getAdd}
          setcoinItem={setcoinItem}
          value={value}
          coinItem={coinItem}
          setvalue={setvalue}
          getplatformTokensinfo={getplatformTokensinfo}
          getBal={getBal}
          showToggle={true}
          tableno={tableNo}
          settableno={(e) => {
            setwithdrawModal(false);
            setFiatWithdrawModal(true);
          }}
          show1={withdramodal}
          setShowWithdraw={(e) => setwithdrawModal(e)}
        />
        <ExchangeModal
          coinItem={coinItem}
          getplatformTokensinfo={getplatformTokensinfo}
          getBal={getBal}
          show2={exchangemodal}
          setExchangeModal={(e) => setExchangeModal(e)}
        />
        <DepositFiatModal
          setShowDeposit={(e) => setFiatDepositModal(e)}
          show={fiatDepositModal}
          setcoinItem={setcoinItem}
          tableNo={tableNo}
          showToggle={true}
          selectedCurrency={coinItem}
          allplatformbanks={platformBanks}
          setTableno={(e) => {
            setFiatDepositModal(false);
            setDepositModal(true);
          }}
        />
        <WithdrawalFiat
          setShowWithdraw={(e) => setFiatWithdrawModal(e)}
          show1={fiatWithdrawModal}
          
          showToggle={true}
          tableNo={"second"}
          selectedCurrency={coinItem}
          setTableno={(e) => {
            setFiatWithdrawModal(false);
            setwithdrawModal(true);
          }}
        />
      </div>
      {filteredTokens && filteredTokens.length > limit && (
        <div className="paginationsection">
          <div className="paginationtxtholer">
            <span className="noofpagetxt">
              {t("Showing")} {pages + 1} {t("from")}{" "}
              {Math.ceil(filteredTokens && filteredTokens.length / limit)}{" "}
            </span>
          </div>
          <div className="paginationallignment">
            <ReactPaginate
              previousLabel={<PreviousIcon className="paginationicon" />}
              nextLabel={<NextIcon className="paginationicon" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={filteredTokens && filteredTokens.length / limit}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={(e) => {
                //   if (tableno == "second") {
                //     getTransaction({
                //       skip: e.selected * limit,
                //       limit,
                //       type: "rec",
                //     });
                setpages(e.selected);
                //   }
                //   if (tableno == "third") {
                //     getTransaction({
                //       skip: e.selected * limit,
                //       limit,
                //       type: "send",
                //     });
                //     setpages(e.selected);
                //   }
                //   if (tableno == "first") {
                //     getTransaction({ skip: e.selected * limit, limit });
                //     setpages(e.selected);
                //   }
              }}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CoinTable;
