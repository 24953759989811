import { all, takeEvery, takeLatest, call } from "redux-saga/effects";
import API from "./../services/Api";
import {
  getappsettings,
  setthememode,
  updateVersion,
  setWithdrawModal,
  setExchangeModal,
  setPaymentlinkSucc,
  setPaymentlink,
  setFiatDepositModal,
  setDepositModal,
  setIdobuyModal,
  setConfirmcodeModal,
  setAuthenticatorModal,
  setFiatPullRequestOpenAsync,
  setFiatPullRequestCloseAsync,
  setCreateBankAccountPopupCloseAsync,
  setFiatWithdrawModal,
  setbuyModal,
  changeRole,
  hideZeroBalance,
  businessSuceess,
  closeBetaPopup,
  setFiatrate,
  getWSRate,
} from "./appsaga";
import {
  getPlatformBank,
  getFiatTransaction,
  getFiatTransactionCount,
  createFiatWithDrawlRequestAsync,
  callFiatBalanceAsync,
  getAllBankAccountsAsync,
  getAllBank,
  createBankAccountAsync,
  depositFiatcurrency,
  depositFiatClearMessagesAsync,
  getALLTransaction,
  getALLTransactionCount,
  getAllOwnerBankAccounts,
  deleteBankAccountAsync,
} from "./fiatsaga";
import {
  createIdoTrans,
  createRoundIdo,
  getRoundIdo,
  getidoListbyaddress,
  claimIdoTrans,
  getTransactionCount,
  getRoundcount,
  getclaimtokenCount,
  getsendtokenCount,
  getCyborExchangeRate,
  BuyCyborcoinFromIdo,
  EditRoundPrivacy,
} from "./idosaga";
import {
  createpaymentLink,
  getPaymentHistory,
  getPaymentHistoryCount,
  getPaymentbytokenLink,
  transferByPaymentLink,
  getcommissionPercentage,
  cancelPaymentAsyn,
  createFiatpaymentLink,
  getcommission,
  paymentReport,
  getPaymentHistoryDownload,
} from "./paymentlinksaga";
import {
  getAccountAndBalanceByWalletId,
  getExchangehistory,
  getUserTransaction,
  getUserTransactionDownload,
  getPlatformTokens,
  transferBalanceOrToken,
  getWsdata,
  exchangeTokenOrCoin,
  getExchangerate,
  getFiatExchangerate,
  getTranferHistoryCount,
  getExchangeHistoryCount,
  getPlatformCurrencys,
  getFiatBalance,
  getDepositWithHistoryCount,
  getDepWithdrawhistory,
  getDepWithdrawhistoryDownload,
  getExchangehistoryDownload,
  getDectExchangerate,
  postDectExchangerate,
  LoanSupportedInPlatformSaga,
  BorrowLoanSaga,
  CreateLoanSupportInPlatformSaga,
  EditLoanSupportSupportInPlatformSaga,
  DeleteLoanSupportInPlatformSaga,
  ListLoanSupportedByAdminSaga,
  LoanRequestListingSaga,
  AddLoanRequestSaga,
  CollateralConversionSaga ,
  LoanRequestApprovalSaga,
  CreatePersonalLoanAdminSaga,
  AdminPersonalLoanListSaga,
  EditPersonalLoanAdminSaga,
  DeletePersonalLoanAdminSaga,
  GetPersonalLoanPublicSaga,
  BorrowPersonalLoanSaga,
  GetDynamicInterestRateSaga,
  PersonalLoanRequestListSaga,
  PersonalLoanRequestAdminApprovalSaga,
} from "./platformusersaga";
import {
  usersignIn,
  usersignUp,
  VerificationOtp,
  logout,
  clearErrorMessage,
  clearResetPopupError,
  storeuserDashboardList,
  uploadProfileImage,
  updateProfile,
  changePasswordAsync,
  requestEmailCode,
  ConfirmEmailCode,
  removeImage,
  businessForm,
  getMyprofile,
  forgotPassword,
  getAllAddressBookingsAsync,
  addAddressBookingAsync,
  editAddressBookingAsync,
  deleteAddressBookingAsync,
  storeMyBalance,
  storePreferredCurrency,
  addNewCoin,
  getNfcCardSaga,
  createCardSaga,
  listUserNfcCardSage
} from "./usersaga";

import {
  createBranchAsync,
  listAllBranchesAsync,
  deleteBranchAsync,
  editBranchAsync,
  updateBranchStatus,
  getBranchpayment,
  clearBranchError,
} from "./branchmanagementsaga";
import {
  createFiatWithDrawlStart,
  callFiatBalanceStart,
  getAllBankAccountsStart,
  getBankTrigger,
} from "./fiatredux";
import {
  requestedAccountVerification,
  usersFiatDepositRequest,
  businessRequest,
  usersFiatWithdrawRequest,
  UserDepositRequestVerify,
  VerifyUserAccountRequest,
  VerifyUserWithdrawRequest,
  ApproveBusinessRequest,
  WithdrawRequestCount,
  DepositRequestCount,
  BusinessCountAsync,
  BankAccountRequestCountAsync,
  usersListCount,
  usersList,
  usersListDownload,
  setUsersStatus,
  allAdminBanks,
  editAdminBankAsync,
  addAdminBankAsync,
  deleteAdminBankAsync,
  editBankImagesAsync,
  editNonColouredIconAsync,
} from "./adminsaga";
import {
  getnotification,
  getnotificationCount,
  readnotification,
} from "./notificationsaga";
import bityApi from "src/services/bityApi";
import { getCurrency, getEstimate, getPairs } from "./bitysaga";

const api = API.create();
const bityapi = bityApi.create();
export default function* root() {
  yield all([
    takeEvery("getAppSettings", getappsettings, api),
    takeEvery("updateVersion", updateVersion, api),
    takeEvery("updateTheme", setthememode, api),
    takeEvery("usersignIncalled", usersignIn, api),
    takeEvery("usersignUpcalled", usersignUp, api),
    takeEvery("OtpverifyCalled", VerificationOtp, api),
    takeEvery("getBalancesCalled", getAccountAndBalanceByWalletId, api),
    takeEvery("requestEmailCodeCalled", requestEmailCode, api),
    takeEvery("ConfirmEmailCodeCalled", ConfirmEmailCode, api),
    takeEvery("setConfirmcodeModalCalled", setConfirmcodeModal, api),
    // takeEvery("setAuthenticatorModalCalled", setAuthenticatorModal, api),
    takeEvery("getUserTransactionsCalled", getUserTransaction, api),
    takeEvery(
      "getUserTransactionsDownloadCalled",
      getUserTransactionDownload,
      api
    ),

    takeEvery("getExchangehistoryCalled", getExchangehistory, api),
    takeLatest(
      "getExchangehistorydownloadCalled",
      getExchangehistoryDownload,
      api
    ),
    takeEvery("getPlatformTokensCalled", getPlatformTokens, api),
    takeEvery("transferBalanceOrTokenCalled", transferBalanceOrToken, api),
    takeEvery("getWsdatacalled", getWsdata, api),
    takeEvery("exchangeTokenOrCoincalled", exchangeTokenOrCoin, api),
    takeEvery("withdramodalcalled", setWithdrawModal, api),
    takeEvery("setExchangeModalcalled", setExchangeModal, api),
    takeEvery("getExchangeratecalled", getExchangerate, api),
    takeEvery("getFiatExchangeratecalled", getFiatExchangerate, api),
    takeEvery("logoutCalled", logout, api),
    takeEvery("getTranferHistoryCountCalled", getTranferHistoryCount, api),
    takeEvery("getExchangeHistoryCountCalled", getExchangeHistoryCount, api),
    takeEvery("getPlatformCurrencysCalled", getPlatformCurrencys, api),
    takeEvery("getFiatBalanceCalled", getFiatBalance, api),
    takeEvery("updateProfileImage", uploadProfileImage, api),
    takeEvery("updateProfile", updateProfile, api),
    takeEvery("getMyprofilecalled", getMyprofile, api),
    takeEvery("getRountidocalled", getRoundIdo, api),
    takeEvery("createRidocalled", createRoundIdo, api),
    takeEvery("createIdoTranscalled", createIdoTrans, api),
    takeEvery("getidoListbyaddresscalled", getidoListbyaddress, api),
    takeEvery("claimIdoCalled", claimIdoTrans, api),
    takeEvery("getPaymentHistoryCalled", getPaymentHistory, api),
    takeLatest(
      "getPaymentHistoryDownloadCalled",
      getPaymentHistoryDownload,
      api
    ),

    takeEvery("getpaymetHistoryCountCalled", getPaymentHistoryCount, api),
    takeEvery("createpaymentLinkCalled", createpaymentLink, api),
    takeEvery("getPaymentbytokenLinkCalled", getPaymentbytokenLink, api),

    takeEvery("setPaymentlinkSuccCalled", setPaymentlinkSucc, api),
    takeEvery("setPaymentlinkCalled", setPaymentlink, api),

    takeEvery("getRoundcountCalled", getRoundcount, api),
    takeEvery("getTransactionCountCalled", getTransactionCount, api),
    takeEvery("getDepwithHistoryCountCalled", getDepositWithHistoryCount, api),
    takeEvery("getDepwithHistoryCalled", getDepWithdrawhistory, api),

    takeEvery("getclaimtokenCounttCalled", getclaimtokenCount, api),
    takeEvery("getsendtokenCountCalled", getsendtokenCount, api),

    takeEvery("transferByPaymentLinkCalled", transferByPaymentLink, api),
    takeEvery("getcomissionperCalled", getcommissionPercentage, api),
    takeEvery("getPlatformBankCalled", getPlatformBank, api),
    takeLatest("getFiatTransactionCalled", getFiatTransaction, api),
    takeLatest("getFiatTransactionCountCalled", getFiatTransactionCount, api),
    takeEvery("clearErrorMessageCalled", clearErrorMessage, api),
    takeLatest("clearErrorMessagePopup", clearResetPopupError, api),
    takeEvery("FiatDepositModalCalled", setFiatDepositModal, api),
    takeEvery("DepositModalCalled", setDepositModal, api),
    takeEvery("getCyborExchangeRateCalled", getCyborExchangeRate, api),
    takeEvery("BuyCyborcoinFromIdoCalled", BuyCyborcoinFromIdo, api),
    takeEvery("setIdobuyModalCalled", setIdobuyModal, api),
    takeEvery("setFiatWithdrawModalCalled", setFiatWithdrawModal, api),

    takeEvery("storeuserDashboardCalled", storeuserDashboardList, api),
    takeEvery("changePasswordStart", changePasswordAsync, api),
    takeLatest("createBranchStart", createBranchAsync, api),
    takeLatest("listAllBranchesStart", listAllBranchesAsync, api),
    takeLatest("deleteBranchStart", deleteBranchAsync, api),
    takeLatest("editBranchStart", editBranchAsync, api),
    takeLatest(createFiatWithDrawlStart, createFiatWithDrawlRequestAsync, api),
    takeLatest("callFiatBalanceStartCalled", callFiatBalanceAsync, api),
    takeLatest("getAllBankAccountsStart", getAllBankAccountsAsync, api),
    takeLatest("getBankTrigger", getAllBank, api),
    takeLatest("createBankAccountStart", createBankAccountAsync, api),
    takeLatest("depositFiatTrigger", depositFiatcurrency, api),
    takeLatest("openFiatPullRequestModal", setFiatPullRequestOpenAsync, api),
    takeLatest("closeFiatPullRequestModal", setFiatPullRequestCloseAsync, api),
    takeLatest("clearFiatDepositMessages", depositFiatClearMessagesAsync, api),
    takeLatest("cancelPaymentStart", cancelPaymentAsyn, api),
    takeLatest("removeImageCalled", removeImage, api),
    takeLatest("buyModalCalled", setbuyModal, api),
    takeLatest("FiatPaymentLink", createFiatpaymentLink, api),
    takeLatest("businessFormCalled", businessForm, api),

    // user forgot password popup
    takeLatest("forgotPasswordpopupCalled", forgotPassword, api),
    //
    //get all owner bank account
    takeLatest("getAllOwnerBankAccountStart", getAllOwnerBankAccounts, api),
    //get all owner bank account
    takeLatest(
      "requestedAccountVerification",
      requestedAccountVerification,
      api
    ),
    takeLatest("businessRequest", businessRequest, api),
    takeLatest("usersFiatWithdrawRequest", usersFiatWithdrawRequest, api),
    takeLatest("VerifyUserAccountRequest", VerifyUserAccountRequest, api),
    takeLatest("userDepositRequestVerify", UserDepositRequestVerify, api),
    takeLatest("VerifyUserWithdrawRequest", VerifyUserWithdrawRequest, api),
    takeLatest("ApproveBusinessRequest", ApproveBusinessRequest, api),
    takeLatest("usersFiatDepositRequest", usersFiatDepositRequest, api),
    takeLatest("withdrawRequestCount", WithdrawRequestCount, api),
    takeLatest("depositRequestCount", DepositRequestCount, api),
    takeLatest("businessRequestCount", BusinessCountAsync, api),
    takeLatest("bankRequestCount", BankAccountRequestCountAsync, api),
    takeLatest("usersList", usersList, api),
    takeLatest("usersListDownload", usersListDownload, api),
    takeLatest("usersListCount", usersListCount, api),
    takeLatest("setUsersStatus", setUsersStatus, api),
    takeLatest("EditRoundPrivacycalled", EditRoundPrivacy, api),
    takeLatest("changerolecalled", changeRole, api),
    takeLatest("hideZerocalled", hideZeroBalance, api),

    takeLatest("GetAllAddressBooksCalled", getAllAddressBookingsAsync, api),
    takeLatest("AddAddressBookCalled", addAddressBookingAsync, api),
    takeLatest("EditAddressBookCalled", editAddressBookingAsync, api),
    takeLatest("DeleteAddressBookCalled", deleteAddressBookingAsync, api),

    takeLatest("storeEstimatedCalled", storeMyBalance, api),
    takeLatest("storePreferredCurrencydCalled", storePreferredCurrency, api),

    takeLatest("getcommissionCalled", getcommission, api),
    takeLatest("businessModalCalled", businessSuceess, api),

    takeLatest("paymentReport", paymentReport, api),
    takeLatest("updateBStatusCalled", updateBranchStatus, api),
    takeLatest("closeBetaCalled", closeBetaPopup, api),
    takeLatest("setFiatrateCalled", setFiatrate, api),

    takeLatest("getAllTransactionCalled", getALLTransaction, api),
    takeLatest("getAllTransactionCountCalled", getALLTransactionCount, api),

    takeLatest("getBranchpaymentCalled", getBranchpayment, api),
    //calling of clear brach error messages
    takeLatest("clearBrachError", clearBranchError, api),
    takeLatest(
      "closeBankAccountPopup",
      setCreateBankAccountPopupCloseAsync,
      api
    ),

    takeLatest("getnotificationcalled", getnotification, api),
    takeLatest("getnotificationcountcalled", getnotificationCount, api),
    takeLatest("readnotificationcalled", readnotification, api),
    takeLatest("websocketRateCalled", getWSRate, api),

    takeLatest("callAllAdminBanksAsync", allAdminBanks, api),
    takeLatest("editAdminBank", editAdminBankAsync, api),
    takeLatest("addAdminBank", addAdminBankAsync, api),
    takeLatest("deleteAdminBank", deleteAdminBankAsync, api),
    takeLatest("editBankImages", editBankImagesAsync, api),
    takeLatest("editNonColouredIcon", editNonColouredIconAsync, api),
    //to handle download issues
    takeLatest(
      "getDepositWithHistoryDownload",
      getDepWithdrawhistoryDownload,
      api
    ),
    takeLatest("deleteBankAccountCall", deleteBankAccountAsync, api),
    takeLatest("getBcurrency", getCurrency, bityapi),
    takeLatest("getBpairs", getPairs, bityapi),
    takeLatest("getEstimate", getEstimate, bityapi),
    takeLatest("getDectExchangerate", getDectExchangerate, api),
    takeLatest("postDectExchangerate", postDectExchangerate, api),
    takeLatest("addNewCoinCalled", addNewCoin, api),
    takeLatest("allNfcCardsCalled", getNfcCardSaga, api),
    takeLatest("CreateCardCalled", createCardSaga, api),
    takeLatest("listUserNfcCardSageCalled", listUserNfcCardSage, api),
    takeLatest("borrowLoanSagaCalled" , BorrowLoanSaga , api),
    takeLatest("loanSupportedInPlatformCalled" , LoanSupportedInPlatformSaga , api),
    takeLatest("createLoanSupportInPlatformSagaCalled" , CreateLoanSupportInPlatformSaga , api),
    takeLatest("editLoanSupportInPlatformSagaCalled"  , EditLoanSupportSupportInPlatformSaga , api),
    takeLatest("deleteLoanSupportInSagaCalled" , DeleteLoanSupportInPlatformSaga , api),
    takeLatest("listAllLoanSupportedInAdminSagaCalled" , ListLoanSupportedByAdminSaga , api),
    takeLatest("loanRequestListingSagaCalled" , LoanRequestListingSaga , api),
    takeLatest("addLoanRequestSagaCalled" , AddLoanRequestSaga , api),
    takeLatest("collateralConversionSagaCalled" , CollateralConversionSaga , api),
    takeLatest("approvalLoanRequestSagaCalled" , LoanRequestApprovalSaga , api),

    // personal loan
    takeLatest("CreatePersonalLoanAdminSagaCalled" , CreatePersonalLoanAdminSaga , api),
    takeLatest("adminAllPersonalLoanSagaCalled" , AdminPersonalLoanListSaga , api),
    takeLatest("editPersonalLoanAdminSagaCalled"  , EditPersonalLoanAdminSaga , api),
    takeLatest("deletePersonalLoanAdminSagaCalled"  , DeletePersonalLoanAdminSaga , api),
    takeLatest("GetPersonalLoanPublicSagaSagaCalled"  , GetPersonalLoanPublicSaga , api),
    takeLatest("addPersonalLoanSagaCalled" , BorrowPersonalLoanSaga , api),
    takeLatest("getDynamicInterestRateSagaCalled" , GetDynamicInterestRateSaga , api),
    takeLatest("getPersonalLoanRequestListSagaCalled" , PersonalLoanRequestListSaga , api),
    takeLatest("borrowPersonalLoanSagaCalled" ,  BorrowPersonalLoanSaga , api),
    takeLatest("PersonalLoanRequestAdminApprovalSagaCalled" ,  PersonalLoanRequestAdminApprovalSaga, api),


   




  ]);
}
