import React, { useState } from "react";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import WithdrawForm from "@components/withdraw-form/WithdrawFrom";
import "@components/withdraw-form-with-toggle/WithdrawFormWithToggle.scss";
function WithdrawFormWithToggle({
  coinItem,
  getBal,
  getAdd,
  setcoinItem,
  option,
  showToggle,
  tableNo,
  setTableNo,
  setHideThis,
  setShowWithdraw
}) {
  let [tableno, settableno] = useState("first");
  return (
    <div className="withdrawformwithtoggle">
      <div className="topspaceformodal1">
        {
          showToggle == true &&
          <div className="hidetgl">
          <CurrencyTab
            page={"currency"}
            tableno={tableNo}
            changeTab={(e) => setTableNo(e)}
          />
          </div>
        }

      </div>
      {tableno === "first" && (
        <WithdrawForm
          coinItem={coinItem}
          getBal={getBal}
          getAdd={getAdd}
          option={option}
          setcoinItem={setcoinItem}
          setHideThis={setHideThis}
          setShowWithdraw={setShowWithdraw}
        />
      )}
    </div>
  );
}
export default WithdrawFormWithToggle;
