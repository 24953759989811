import { call, put } from "redux-saga/effects";
import {
  createPaymentFailure,
  createPaymentTrigger,
  createPaymentSuccess,
  getpaymenthistoryFailure,
  getpaymenthistorySuccess,
  getpaymenthistoryTrigger,
  getpaymenthistoryCountSuccess,
  setpaymentTokenSuccess,
  getPaymentbytokenLinkSuccess,
  getcommissionPercentageSuccess,
  cancelPaymentStart,
  cancelPaymentSuccess,
  cancelPaymentFailure,
  createFiatPaymentFailure,
  createFiatPaymentSuccess,
  createFiatPaymentTrigger,
  getcommissionSuccess,
  paymentReportTrigger,
  paymentReportSucess,
  paymentReportFailure,
  paymentCountSave,
  getpaymenthistoryDownloadSuccess,
} from "./paymentlinkredux";
import Api from "../services/ApiCaller";
import { getAccessToken, getWalletId } from "./utilities";
import { toast } from "react-toastify";
import {
  setPaymentlinkModalSuccess,
  setPaymentlinkSuccModalSuccess,
} from "./appredux";
import { getExchangerateSuccess } from "./platformuserredux";
export function* createFiatpaymentLink(api, { payload = {} }) {
  yield put(createFiatPaymentTrigger(true));
  try {
    const {
      amount,
      acceptAs,
      exchangeTo,
      acceptAsType,
      exchangeToType,
      email,
      toAddress,
      fiatCurrency,
      fiatCurrencyType,
      ExpectedAmount,
      lang,
    } = payload;
    const token = yield getAccessToken();
    let walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.generatePaymentLink,
      {
        amount,
        acceptAs,
        exchangeTo,
        acceptAsType,
        exchangeToType,
        email,
        toAddress,
        walletId,
        token,
        fiatCurrency,
        fiatCurrencyType,
        lang,
        expectedAmount: ExpectedAmount,
      },
      true
    );
    let id = yield getWalletId();
    const result2 = yield call(
      Api.callServer,
      api.getPaymentLinkData,
      {
        token,
        tokenLink: result.link,
      },
      true
    );
    yield put(getPaymentbytokenLinkSuccess(result2));
    yield put(createFiatPaymentSuccess(result));
    yield put(setPaymentlinkSuccModalSuccess(true));
  } catch (e) {
    yield put(createFiatPaymentFailure(e));
  } finally {
    yield put(createFiatPaymentTrigger(false));
  }
}

export function* createpaymentLink(api, { payload = {} }) {
  yield put(createPaymentTrigger(true));
  try {
    const {
      amount,
      acceptAs,
      exchangeTo,
      acceptAsType,
      exchangeToType,
      email,
      toAddress,
      lang,
      paymentLinkType,
    } = payload;
    const token = yield getAccessToken();
    let walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.generatePaymentLink,
      {
        amount,
        acceptAs,
        exchangeTo,
        acceptAsType,
        exchangeToType,
        email,
        toAddress,
        walletId,
        token,
        lang,
        paymentLinkType: "both",
      },
      true
    );
    let id = yield getWalletId();
    const result2 = yield call(
      Api.callServer,
      api.getPaymentLinkData,
      {
        token,
        tokenLink: result.link,
      },
      true
    );
    yield put(getPaymentbytokenLinkSuccess(result2));
    yield put(createPaymentSuccess(result));
    const result3 = yield call(
      Api.callServer,
      api.getpaymenthistory,
      {
        token,
        id,
        skip: 0,
        limit: 10,
      },
      true
    );
    yield put(getpaymenthistorySuccess(result3));
    yield put(setPaymentlinkModalSuccess(false));
    yield put(setPaymentlinkSuccModalSuccess(true));
  } catch (e) {
    yield put(createPaymentFailure(e));
  } finally {
    yield put(createPaymentTrigger(false));
  }
}

export function* getPaymentHistory(api, { payload = {} }) {
  yield put(getpaymenthistoryTrigger(true));
  try {
    const { skip, limit, type, startDate, endDate, orderBy, sort, obj } =
      payload;

    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getpaymenthistory,
      {
        token,
        id,
        skip,
        limit,
        type,
        startDate,
        endDate,
        orderBy,
        sort,
        obj,
      },
      true
    );
    yield put(getpaymenthistorySuccess(result));
  } catch (error) {
    yield put(getpaymenthistoryFailure(error));
  } finally {
    yield put(getpaymenthistoryTrigger(false));
  }
}

export function* getPaymentHistoryDownload(api, { payload = {} }) {
  yield put(getpaymenthistoryTrigger(true));
  try {
    const { type, startDate, endDate, orderBy, sort, obj } = payload;

    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getpaymenthistory,
      {
        token,
        id,
        skip: 0,

        type,
        startDate,
        endDate,
        orderBy,
        sort,
        obj,
      },
      true
    );
    yield put(getpaymenthistoryDownloadSuccess(result));
  } catch (error) {
    yield put(getpaymenthistoryFailure(error));
  } finally {
    yield put(getpaymenthistoryTrigger(false));
  }
}
export function* getPaymentHistoryCount(api, { payload = {} }) {
  yield put(getpaymenthistoryTrigger(true));
  const { type, startDate, endDate } = payload;

  try {
    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.getpaymenthistorycount,
      {
        token,
        id,
        type,
        startDate,
        endDate,
      },
      true
    );
    yield put(getpaymenthistoryCountSuccess(result));
  } catch (error) {
    yield put(getpaymenthistoryFailure(error));
  } finally {
    yield put(getpaymenthistoryTrigger(false));
  }
}

export function* getPaymentbytokenLink(api, { payload = {} }) {
  yield put(getpaymenthistoryTrigger(true));
  const { tokenLink } = payload;
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getPaymentLinkData,
      {
        token,
        tokenLink,
      },
      true
    );
    yield put(getPaymentbytokenLinkSuccess(result));

    let symbol1 =
      result.toPaymentLinkCoin && result.toPaymentLinkCoin.exchangeSymbol;
    let symbol2 =
      result.fromPaymentLinkCoin && result.fromPaymentLinkCoin.exchangeSymbol;
    const result2 = yield call(
      Api.callServer,
      api.exchangeRate,
      {
        symbol1,
        symbol2,
        token,
      },
      true
    );
    yield put(getExchangerateSuccess(result2));
  } catch (error) {
    yield put(getpaymenthistoryFailure(error));
  } finally {
    yield put(getpaymenthistoryTrigger(false));
  }
}

export function* transferByPaymentLink(api, { payload = {} }) {
  yield put(getpaymenthistoryTrigger(true));
  const {
    amount,
    acceptAs,
    exchangeTo,
    acceptAsType,
    exchangeToType,
    email,
    fromAddress,
    toAddress,
    exchangeRate,
    paymentLinkId,
    paymentLinkToken,
    tokenLink,
  } = payload;
  try {
    const token = yield getAccessToken();
    let walletId = yield getWalletId();
    const result = yield call(
      Api.callServer,
      api.transferThroughPaymentLink,
      {
        amount,
        acceptAs,
        exchangeTo,
        acceptAsType,
        exchangeToType,
        email,
        fromAddress,
        toAddress,
        walletId: Number(walletId),
        exchangeRate,
        paymentLinkId,
        paymentLinkToken,
        token,
      },
      true
    );
    const result2 = yield call(
      Api.callServer,
      api.getPaymentLinkData,
      {
        token,
        tokenLink,
      },
      true
    );
    yield put(getPaymentbytokenLinkSuccess(result2));

    //  yield put(createPaymentSuccess(result));
  } catch (e) {
    const token = yield getAccessToken();

    const result2 = yield call(
      Api.callServer,
      api.getPaymentLinkData,
      {
        token,
        tokenLink,
      },
      true
    );
    yield put(getPaymentbytokenLinkSuccess(result2));
    yield put(createPaymentFailure(e));
  } finally {
    yield put(getpaymenthistoryTrigger(false));
  }
}

export function* getcommissionPercentage(api, { payload = {} }) {
  const { id } = payload;
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getcommissionPercentage,
      {
        token,
        id,
      },
      true
    );
    yield put(getcommissionPercentageSuccess(result));
  } catch (error) {
    //   yield put(getcommissionPercentageFailure(error));
  } finally {
  }
}

export function* cancelPaymentAsyn(api, { payload = {} }) {
  const { paymentID, status, data } = payload;
  yield put(cancelPaymentStart());
  try {
    const token = yield getAccessToken();
    const result = yield call(Api.callServer, api.CancelPaymentCall, {
      paymentID,
      status,
      token,
    });
    let id = yield getWalletId();
    yield put(cancelPaymentSuccess());
    const result3 = yield call(
      Api.callServer,
      api.getpaymenthistory,
      {
        token,
        id,
        skip: 0,
        limit: 10,
        type: data,
      },
      true
    );
    yield put(getpaymenthistorySuccess(result3));
  } catch (error) {
    console.log("Error -> ", error.message);
    yield put(cancelPaymentFailure(error.message));
  }
}

export function* getcommission(api, { payload = {} }) {
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getcommission,
      {
        token,
      },
      true
    );
    yield put(getcommissionSuccess(result));
  } catch (error) {
  } finally {
  }
}

export function* paymentReport(api, { payload = {} }) {
  //console.log('Api params from saga -> ',payload)
  yield put(paymentReportTrigger());
  try {
    const {
      skip,
      limit,
      coinToAccept,
      currencyId,
      coinToConvert,
      paymentStatus,
      currencyAmount,
      paymentId,
      startDate,
      endDate,
    } = payload;
    const token = yield getAccessToken();
    let id = yield getWalletId();
    const result = yield call(Api.callServer, api.paymentReportApiCall, {
      token,
      id,
      skip,
      limit,
      coinToConvert: coinToConvert,
      currencyId: currencyId,
      paymentStatus,
      currencyAmount,
      paymentId,
      startDate,
      endDate,
      orderBy: payload.orderBy,
      sort: payload.sort,
      obj: payload.obj,
    });
    const countResult = yield call(
      Api.callServer,
      api.getFilteredPaymentHistoryCount,
      {
        token,
        id,
        coinToConvert: coinToConvert,
        currencyId: currencyId,
        paymentStatus,
        currencyAmount,
        paymentId,
        startDate,
        endDate,
      }
    );
    yield put(paymentCountSave(countResult.count));
    yield put(paymentReportSucess(result));
  } catch (error) {
    yield put(paymentReportFailure(error.message));
  }
}
