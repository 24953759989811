import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { connect } from "react-redux";
import { Container, Modal, Row, Col } from "react-bootstrap";
import "@components/admin-bank-operation/AdminBankOperationStyles.scss";
import { clearEditAddError } from '@store/adminredux'
import { useTranslation } from "react-i18next";

const Accountsuccessful = ({
    show,
    setshow,
    setShowDeposit,
    closePopup,
    setHideThis,
    msg
}) => {
    const { t, i18n, ready } = useTranslation();

    return (
        <Modal
            className="accountsuccess"
            show={show}
            onHide={() => {
                //setHideThis(false);
                //setShowDeposit(true);
                //closePopup(false);
                closePopup()
            }}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <CloseIcon className="closebtn" onClick={() => {
                    //setHideThis(false);
                    //setShowDeposit(true);
                    //closePopup(false);
                    closePopup()
                }} />
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="deletetext">
                                {/* {t("AccountCreatedSuccessfully")} ! */}
                                {msg && msg} !
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="confirm">
                                <CustomButton
                                    type="submit"
                                    name={t("OK")}
                                    filled={true}
                                    primary={true}
                                    onClick={() => {
                                        setHideThis && setHideThis(false);
                                        //setShowDeposit(true);
                                        closePopup(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch) => ({
    // closePopup: (data) =>
    //     dispatch({ type: "closeBankAccountPopup", payload: data }),
    closePopup: () => dispatch(clearEditAddError())
});

export default connect(null, mapDispatchToProps)(Accountsuccessful);
