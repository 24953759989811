import NavBar from '@components/navbar/NavBar';
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import "@features/claimido-screen/claimidoStyles.scss";
import Table from "react-bootstrap/esm/Table";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import ScreenLayout from "src/components/layout/ScreenLayout";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import CustomButton from "@components/custom-button/CustomButton";

import { useTranslation } from "react-i18next";
function ClaimIdoScreen(props) {
  const {
    getidoList,
    idotransaction,
    transferhistoryCount,
    claimTransactionLoading,
    claimIdo,
    getcount,
    myusertransactionloading,
    role,
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("first");
  let [show, setshow] = useState(false);
  let [Idata, setIdata] = useState({});

  const [address, setaddress] = useState("");
  useEffect(() => {
    getidoList({limit:10,skip:0});
    getcount()
  }, []);
   const { t } = useTranslation();
   return (
    <div id="claimido">
      {/* <NavBar location={useLocation()} /> */}
      <ScreenLayout>
      <Container className="containertp">
        <Row>
          
          <Col lg={12}>
            <div className="trouter trasactiontitle">
              <span className=" title">{t("IDOTransferHistory")}</span>
            </div>

            <Table responsive="sm" className="transfertbl">
              {claimTransactionLoading && <Loader />}
              <thead>
                {idotransaction && idotransaction.length != 0 && (
                  <tr>
                    <th className="trtbleheading">{t("TransactionID")}</th>
                    <th className="trtbleheading">{t("RoundNo")}</th>
                    <th className="trtbleheading">{t("BoughtOn")}</th>
                    <th className="trtbleheading">{t("LockedFor")}</th>
                    <th className="trtbleheading">{t("UnlockDate")}</th>
                    <th className="trtbleheading">{t("TokensAllocated")}</th>
                    <th className="trtbleheading">{t("Status")}</th>
                    {role != "Admin" && (
                      <th className="trtbleheading colwidth">{t("Action")}</th>
                    )}
                  </tr>
                )}
              </thead>

              <tbody>
                {idotransaction &&
                  idotransaction.map((item, index) => (
                    <tr
                      key={`idotrans${index}`}
                      onClick={() => {
                        setIdata(item);
                        setshow(true);
                      }}
                    >
                      <td className="amt">{item.id}</td>
                      <td className="amt">{item.idoRoundId}</td>

                      <td className="amt">
                        {moment.unix(item.date).format("MM/DD/YYYY hh:mm A")}
                      </td>
                      <td className="amt">
                        {Number(item.lockingPeriod / 86400).toFixed(0)}{" "}
                        {t("days")}
                      </td>
                      <td className="amt">
                        {moment
                          .unix(item.unlockdate)
                          .format("MM/DD/YYYY hh:mm A")}
                      </td>
                      <td className="amt">
                        {Number(item.tokensallocated / 10 ** 18).toFixed(0)}{" "}
                        {t("CYBOR")}
                      </td>

                      {item.claimStatus == "Successfull" ? (
                        <td className="status completed">{"Claimed"}</td>
                      ) : item.claimStatus == "failed" ? (
                        <td className="status cancel">{item.claimStatus}</td>
                      ) : (
                        <td className="status pending">{"Locked"}</td>
                      )}

                      {role != "Admin" && (
                        <td>
                          {item.claimStatus !== "Successfull" && (
                            <div
                              className={`claimbtn ${
                                moment().unix() < item.unlockdate &&
                                "claimbtnfalse"
                              }`}
                            >
                              <CustomButton
                                disable={moment().unix() < item.unlockdate}
                                name="Claim"
                                filled={true}
                                primary={true}
                                onClick={() => claimIdo(item.id)}
                              />
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
            {transferhistoryCount > limit && (
              <div className="paginationsection">
                <div className="paginationtxtholer">
                  <span className="noofpagetxt">
                    Showing {pages + 1} from{" "}
                    {Math.ceil(transferhistoryCount / limit)}
                  </span>
                </div>
                <div className="paginationallignment">
                  <ReactPaginate
                    previousLabel={<PreviousIcon className="paginationicon" />}
                    nextLabel={<NextIcon className="paginationicon" />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={transferhistoryCount / limit}
                    // pageCount={2000}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(e) => {
                      if (tableno == "second") {
                        getidoList({
                          skip: e.selected * limit,
                          limit,
                          type: "rec",
                        });
                        setpages(e.selected);
                      }
                      if (tableno == "third") {
                        getidoList({
                          skip: e.selected * limit,
                          limit,
                          type: "send",
                        });
                        setpages(e.selected);
                      }
                      if (tableno == "first") {
                        getidoList({ skip: e.selected * limit, limit });
                        setpages(e.selected);
                      }
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      </ScreenLayout>
    </div>
  );
}
const mapStateToProps = ({ wallet = {}, ido = {}, user = {} }) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const idotransaction = _.get(ido, "idotransaction", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const claimTransactionLoading = _.get(ido, "claimTransactionLoading", false);
  const myusertransactionloading = _.get(
    wallet,
    "myusertransactionloading",
    false
  );
  const transferhistoryCount = _.get(ido, "transactionCount.count", 10);
  const role = _.get(user, "MyProfileInfo.role", []);
  return {
    mywalletsinfo,
    claimTransactionLoading,
    idotransaction,
    myaccountandbalance,
    transferhistoryCount,
    myusertransactionloading,
    role,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getidoList: (data) =>
    dispatch({ type: "getidoListbyaddresscalled", payload: data }),
  claimIdo: (data) => dispatch({ type: "claimIdoCalled", payload: data }),
  getcount: (data) => dispatch({ type: "getTransactionCountCalled", payload: data }),

});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimIdoScreen);
