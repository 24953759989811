import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import NavBar from "@components/navbar/NavBar";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import "@features/onboarding/verification-signup/VerificationSignUpStyle.scss";
import { useTranslation } from "react-i18next";

const VerificationSignup = (props) => {
  const { exchange, role, thememode } = props;
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div id="VerificationSignUp">
      <NavBar location={useLocation()} />
      <Container>
        <div className="outerbox whitebg">
          {role == "Business" && (
            <>
              {/* <img
            alt=""
            className="img"
            src={
              thememode == "dark"
              ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessDarkmode}`
              : `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessLightmode}`
                }
          /> */}
              <img src={paymentsolutionlogo} className="imglogo" />
            </>
          )}
          {role != "Business" && (
            <>
              {/* <img
            alt=""
            className="image"
            src={
              thememode == "light"
                ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalLightmode}`
                : `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalDarkmode}`
                }
          /> */}
              <img src={paymentsolutionlogo} className="image" />
            </>
          )}
          <div className="text">
            <span className="outerconttxt spanmsg ">
              {t("verificationmailsent")} <b>{t("button")}</b> {t("or")}{" "}
              <b>{t("link")}</b> {t("inmailtoVerify")}.
            </span>
          </div>
          <span className="reverttext" onClick={() => history.push("/signin")}>
            <u>{t("gotosignin")}</u>
          </span>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app = {}, user = {} }) => {
  const role = _.get(user, "userInfo.role", "");
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  return {
    exchange,
    thememode,
    role,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationSignup);
