import { createSlice } from "@reduxjs/toolkit";

const bityslice = createSlice({
  name: "bity",
  initialState: {
    notifications: [],
  },
  reducers: {
    getCurrencySuccess: (state, action) => {
      return {
        ...state,
        currencies: action.payload,
      };
    },
    getCurrencyFailure: (state, action) => {
      return {
        ...state,
        currenciesFail: action.payload,
      };
    },
    getCurrencyLoading: (state, action) => {
      return {
        ...state,
        currenciesLoading: action.payload,
      };
    },
    getPairsSuccess: (state, action) => {
      return {
        ...state,
        pairs: action.payload,
      };
    },
    getPairsFailure: (state, action) => {
      return {
        ...state,
        currenciesFail: action.payload,
      };
    },
    getPairsLoading: (state, action) => {
      return {
        ...state,
        pairsLoading: action.payload,
      };
    },
    getEstimateLoading: (state, action) => {
      return {
        ...state,
        estimateLoading: action.payload,
      };
    },
    getEstimateSuccess: (state, action) => {
      return {
        ...state,
        estimate: action.payload,
      };
    },
    getEstimateFailure: (state, action) => {
      return {
        ...state,
        estimateFail: action.payload,
      };
    },
  },
});
export default bityslice.reducer;

export const {
  getCurrencyFailure,
  getCurrencySuccess,
  getCurrencyLoading,
  getPairsFailure,
  getPairsLoading,
  getPairsSuccess,
  getEstimateFailure,
  getEstimateLoading,
  getEstimateSuccess,
} = bityslice.actions;
