export default {
  TotalValueLocked: "Общий заблокированный объем",
  Current: "Текущий",
  Bonus: "Бонус",
  Today: "сегодня",
  MarkerRate: "Рыночная стоимость",
  Buy: "Купить",
  BuyCyborCoin: "Купить PayCrypt Coin",
  BuyWithCurrent: "Покупка с использованием текущего баланса",
  CyborCoin: "PayCrypt Coin",
  With: "Используя",
  ExchangeRates: "Обменный курс",
  Cybor: "Cybor",
  InsufficientBalance: "Недостаточный баланс",
  Lessthenminimumamount: "Меньше минимального значения",
  Minimum: "Минимум",
  Fees: "Сборы",
  Value: "ценность",
  My: "Мои",
  Balances: "Балансы",
  TransactionWaitMessage: "Транзакция может занять 1-3 минуты. Пожалуйста, ожидайте!",
  FiatBalances: "Фиатный Баланс",
  CurrencyPair: "Валютные пары",
  Withdrawisdeactivated: "Вывод деактивирован для этого токена",
};
