import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import ScreenLayout from "src/components/layout/ScreenLayout";
import TransferForm from "@components/transfer-form/TransferForm.js";
import IdoCard from "@components/idocard/IdoCard";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import "@features/ido-screens/IdoScreensStyle.scss";
function IdoScreens(props) {
  const getLogo = (cur) => {
    let obj = props.getplatformTokensinfo.filter(
      (item) => item.tokenName == cur
    );
    let logo = _.get(obj, "[0].nonColouredLogo", 0);
    return logo;
  };
  const getLogoback = (cur) => {
    let obj = props.getplatformTokensinfo.filter(
      (item) => item.tokenName == cur
    );
    let logo = _.get(obj, "[0].logoBgColor", 0);
    return logo;
  };
  useEffect(() => {
    props.getRountido();
    props.getRoundcount();
    props.getTransactionCount();
    props.getsendtokenCount();
    props.getclaimtokenCount();
  }, []);
  return (
    <div id="IdoScreens">
      {/* <NavBar location={useLocation()} /> */}
      <ScreenLayout>
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <Row>
                <Col md={4}>
                  <IdoCard
                    name={"TR"}
                    value={props.Roundcount}
                    logoBgColor={getLogoback("CyborCoin")}
                    logo={`${getLogo("CyborCoin")}`}
                  />
                </Col>
                <Col md={4}>
                  <IdoCard
                    name={"TT"}
                    value={props.transactionCount}
                    logoBgColor={getLogoback("CyborCoin")}
                    logo={`${getLogo("CyborCoin")}`}
                  />
                </Col>
                {/*}   <Col lg={3} className="cardholder1">
                  <IdoCard
                    name={"Token In contract"}
                    value={"4909990 Cybor"}
                    logoBgColor={getLogoback("CyborCoin")}
                    logo={`${getLogo("CyborCoin")}`}
                  />
                </Col>
  */}
                <Col md={4}>
                  <IdoCard
                    name={"TTS"}
                    value={`${props.sendtokenCount} Cybor`}
                    logoBgColor={getLogoback("CyborCoin")}
                    logo={`${getLogo("CyborCoin")}`}
                  />
                </Col>
                {/*}     <Col lg={3} className="cardholder1">
                  <IdoCard
                    name={"Total Token Claimand"}
                    value={`${props.claimtokencount} Cybor`}
                    logoBgColor={getLogoback("CyborCoin")}
                    logo={`${getLogo("CyborCoin")}`}
                  />
  </Col>*/}
              </Row>
              <hr className="hrtag"></hr>
              <Row>
                <Col>
                  <TransferForm />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ScreenLayout>
    </div>
  );
}

const mapStateToProps = ({ ido, wallet }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const transactionCount = _.get(ido, "transactionCount.count", 0);
  const Roundcount = _.get(ido, "Roundcount.count", 0);
  const claimtokencount = _.get(ido, "claimtokencount.count", 0);
  const sendtokenCount = _.get(ido, "sendtokenCount.count", 0);
  return {
    getplatformTokensinfo,
    transactionCount,
    Roundcount,
    sendtokenCount,
    claimtokencount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createIdoTrans: (data) =>
    dispatch({ type: "createIdoTranscalled", payload: data }),
  getRoundcount: (data) =>
    dispatch({ type: "getRoundcountCalled", payload: data }),
  getTransactionCount: (data) =>
    dispatch({ type: "getTransactionCountCalled", payload: data }),
  getclaimtokenCount: (data) =>
    dispatch({ type: "getclaimtokenCounttCalled", payload: data }),
  getsendtokenCount: (data) =>
    dispatch({ type: "getsendtokenCountCalled", payload: data }),
  getRountido: (data) => dispatch({ type: "getRountidocalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdoScreens);
