import NavBar from "@components/navbar/NavBar";
import ScreenLayout from "src/components/layout/ScreenLayout";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import { Row, Col } from "react-bootstrap";
import cyborlight from "@images/cyborlight.svg";
import cybordark from "@images/cybordark.svg";
import googlechrome from "@images/GoogleChrome.svg";
import about from "@images/about.png";
import playstore from "@images/playstore.svg";
import apple from "@images/Appleicon.svg";
import applelight from "@images/Appleicon1.svg";
import microsoft from "@images/microsoft.svg";
import huawei from "@images/huawei.svg";
import cp1 from "@images/cp1.svg";
import cp2 from "@images/cp2.svg";
import cp3 from "@images/cp3.svg";
import CustomButton from "@components/custom-button/CustomButton";
import DownloadApp from "@components/download-app/DownloadApp";
import "@features/cybor-protection/CyborProtection.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import FooterCommon from "@components/footer-common/FooterCommon";

function CyborProtection({ thememode, currentLang,hideLoaderOnRefresh }) {
  const { t } = useTranslation();
  

  return (
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh} >
      <>
        <Container>
          <div id="cyborprotection">
            <Container className="containertp">
              <Col lg={9}>
                <div className="cyborprotectionwrapper">
                  <div className="logo-and-button">
                    <img
                      src={(thememode == "dark" && cybordark) || cyborlight}
                      alt="cybor holding logo"
                      className="cyborholdinglogo"
                    />
                    <a
                      className="dwnldChrAtag"
                      target="_blank"
                      href="https://chrome.google.com/webstore/detail/cybor-protection-system/cnahcinenbahinmnkhflafbnnolnahlg"
                    >
                      <div className="dlbtn">
                        <CustomButton
                          primary
                          filled
                          active
                          name={
                            <>
                             {t("Downloadext")}
                            </>
                          }
                          leftIcon={googlechrome}
                        />
                      </div>
                    </a>
                  </div>
                  <div className="protectioncontent">
                    <div className="protectionheading">
                      <h1 className="mainheading">
                        {t("CyborProtectionSystem")}
                      </h1>
                      <p className="textbelowmainheading">
                        {t("applicationforcontrol")}
                      </p>
                    </div>
                    <Row className="mobilescreenimages">
                      <Col lg={4} md={4} sm={6} xs={6}>
                        <div className="appscreenwrapper">
                          <img src={cp1} alt="" />
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={6} xs={6}>
                        <div className="appscreenwrapper">
                          <img src={cp2} alt="" />
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={6} xs={6}>
                        <div className="appscreenwrapper">
                          <img src={cp3} alt="" />
                        </div>
                      </Col>
                    </Row>
                    <div className="protectiondecription">
                      <p className="protectiondesciptionline">
                        {t("freeappcyborprotection")}
                      </p>

                      <p className="protectiondesciptionline">
                        {t("cyborprotectionforchrome")}
                      </p>

                      <p className="protectiondesciptionline">
                        {t("cyborprotectionvpn")}
                      </p>

                      <p className="protectiondesciptionline">
                        {t("installvpn")}
                      </p>
                    </div>
                    <div className="protectiondownloadcontent">
                      <p className="protectiondownloadlines">{t("Download")}</p>
                      <p className="protectiondownloadlines">
                        <b>{t("CyborProtectionSystemApp")}:</b>
                      </p>
                    </div>

                    <a
                      className="dwnldChrAtag"
                      target="_blank"
                      href="https://chrome.google.com/webstore/detail/cybor-protection-system/cnahcinenbahinmnkhflafbnnolnahlg"
                    >
                      <div className="bottombtn">
                        <CustomButton
                          primary
                          filled
                          active
                          name={
                            <>
                              {t("Downloadext")}
                            </>
                          }
                          leftIcon={googlechrome}
                        />
                      </div>
                    </a>
                    {/* <div className="appstoreswrapper">
                      <Row>
                        <Col lg={3} md={4} sm={6} xs={6}>
                          <div className="singleappstorewrapper">
                            <div
                              className={
                                thememode == "dark"
                                  ? "lightborder"
                                  : "darkborder"
                              }
                            >
                              <DownloadApp
                                image={playstore}
                                headtitle={t("GETITON")}
                                maintitle="Google Play"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                          <div className="singleappstorewrapper">
                            <div
                              className={
                                thememode == "dark"
                                  ? "lightborder"
                                  : "darkborder"
                              }
                            >
                              <DownloadApp
                                image={thememode == "dark" ? apple : applelight}
                                headtitle={t("DOWNLOADONTHE")}
                                maintitle="App Store"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                          <div className="singleappstorewrapper">
                            <div
                              className={
                                thememode == "dark"
                                  ? "lightborder"
                                  : "darkborder"
                              }
                            >
                              <DownloadApp
                                image={microsoft}
                                headtitle={t("GETITFROM")}
                                maintitle="Microsoft"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                          <div className="singleappstorewrapper">
                            <div
                              className={
                                thememode == "dark"
                                  ? "lightborder"
                                  : "darkborder"
                              }
                            >
                              <DownloadApp
                                image={huawei}
                                headtitle={t("EXPLOREITON")}
                                maintitle="AppGallery"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Container>
          </div>
        </Container>
        <FooterCommon />
      </>
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, translationLanguage = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const currentLang = _.get(translationLanguage, "language", false);

  return {
    thememode,
    currentLang,
  };
};

export default connect(mapStateToProps, null)(CyborProtection);
