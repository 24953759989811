export default {
  Branch: "",
  Management: "Управление отелениями",
  No: "Нет",
  Passive: "Не Активно",
  Active: "Активно",
  Account: "Аккаунт",
  Phone: "Телефон",
  Address: "Адрес",
  Edit: "Изменить",
  Sorrynoresultsfound: "Извините, ничего не найдено",
  Showing: "Показано",
  from: "из",
  CreateBranch: "Создать Отделение",
  MyBranch: "Мои филии",
  BranchCreatedSuccessfully: "Филиал успешно создан",
  Okay: "Хорошо",
  DefiLoans: "Крипто кредит",
  BankLoan: "Банковский кредит",
  Cryptocards: "Криптовалюты",
  Buysellcrypto: "Купить/Продать Криптовалюту",
  Loanrequests: "Запросы на кредит",
  Borrow: "Занимать",
  LoanID: "Идентификатор кредита",
  BorrowedAmount: "Заемная сумма",
  BorrowedCoin: "Заемная монета",
  Tenure: "срок пребывания в должности",
  InDays: "Через дней",
  InitialLTV: "Начальная LTV",
  LiquidationLTV: "Ликвидация LTV",
  CreationDate: "Дата создания",
  Details: "Подробности",

  BankLoanRequests: "Запросы на банковский кредит",
  BorrowedCurrency: "Заемная валюта",
  InterestRate: "Процентная ставка",
  Status: "Положение дел",
  RequestedDate: "Запрошенная дата",
  ViewDocuments: "Просмотр документов",
  ApproveReject: "Утвердить/отклонить",
  InMonths : "В месяцах",
  LoanAmount: "Величина займа",
  Clicktoborrow : "Нажмите, чтобы одолжить",
};
