import React from 'react'
import {Dropdown} from 'react-bootstrap'
import select from "@images/select.svg";
import { ReactComponent as PersonIcon } from "@images/personal.svg";
import "@components/max-dropdown/MaxDropdownStyles.scss";
import { ReactComponent as Dropdownarrow } from "@images/downarrow.svg";
import BigNumber from "bignumber.js";

const MaxDropdown = ({classs, setamount, bal}) => {
    return (
        <Dropdown className={`maxdropdown ${classs}`} align="end">
            <Dropdown.Toggle>
            <div>
                <div className="topblock">
                    Max
                </div>
                <Dropdownarrow className="arrowicon" />
            </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item><span onClick={()=>{
                    setamount(BigNumber(bal).dividedBy(3).toFixed(6))
                }} >25%</span></Dropdown.Item>
                <Dropdown.Item><span onClick={()=>{
                    setamount(BigNumber(bal).dividedBy(2).toFixed(6))
                }} >50%</span></Dropdown.Item> 
                <Dropdown.Item><span
                 onClick={()=>{
                    setamount(BigNumber(bal).dividedBy(1.33).toFixed(6))
                }} >75%</span></Dropdown.Item>
                <Dropdown.Item><span onClick={()=>{
                    setamount(BigNumber(bal).dividedBy(1).toFixed(6))
                }} >100%</span></Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default MaxDropdown
