import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import InputField from "@components/input-field/InputField";
import { Modal, Container, Row, Col, Spinner } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "@components/buy-popup/BuyPopupStyles.scss";
import BigNumber from "bignumber.js";
import { connect } from "react-redux";
import Loader from "@components/loader/Loader";
import { useTranslation } from "react-i18next";

function BuyPopup({
  show1,
  setShowModal,
  getExchangerate,
  coinItem,
  idorounds,
  exchangeTokenOrCoin,
  getplatformTokensinfo,
  myaccountandbalance,
  exchangeTokenloading,
  exchangeRates,
  selectedCoin,
  loadingRate
}) {
  const [Selected, setSelectedIndex] = useState(0);
  const [val1, setVal1] = useState("");
  const [val2, setVal2] = useState("");
   let option = getplatformTokensinfo && getplatformTokensinfo.filter(
    (item) => item.id != selectedCoin?.coinId
  );
  let CyborCoin = getplatformTokensinfo && getplatformTokensinfo.filter(
    (item) => item.id == selectedCoin?.coinId
  );
  useEffect(() => {
    getExchangerate({
      symbol1: CyborCoin && CyborCoin[0] && CyborCoin[0].exchangeSymbol,
      symbol2: option && option[Selected] && option[Selected].exchangeSymbol,
    });
  }, [Selected]);

  const getRates = (symb1) => {
    let { result, sym1, sym2 } = exchangeRates || {};
    if (sym1 == symb1) {
      return result;
    } else {
      return 1 / result;
    }
  };
  const getBal = (cur) => {
    let obj = myaccountandbalance && myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  let balances = Number(
    getBal(option && option[Selected] && option[Selected].id)
  ).toFixed(6);

  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="buypopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <span className="buytitle">
            {t("Buy")}{" "}
            {selectedCoin && selectedCoin.coin && selectedCoin.coin.tokenName}
          </span>
        </Modal.Title>
        <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <div className="buytext">
              <span>{t("BuyWithCurrent")}</span>
            </div>
            <Col lg={12}>
              <div className="btext">{t("Buy")} </div>
            </Col>
            <Col lg={7}>
              <div className="buytxtalign">
                <img
                  className="icons"
                  src={`${process.env.REACT_APP_IMGLINK}${
                    CyborCoin && CyborCoin[0] && CyborCoin[0].colouredLogo
                  }`}
                />
                {selectedCoin &&
                  selectedCoin.coin &&
                  selectedCoin.coin.tokenName}
                {((selectedCoin &&
                  selectedCoin.coin &&
                  selectedCoin.coin.type == "TRC20") ||
                  (selectedCoin &&
                    selectedCoin.coin &&
                    selectedCoin.coin.type == "BEP20") ||
                  (selectedCoin &&
                    selectedCoin.coin &&
                    selectedCoin.coin.type == "ERC20")) && (
                  <span className="cointag">
                    {selectedCoin &&
                      selectedCoin.coin &&
                      selectedCoin.coin.type}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={5}>
              <InputField
                type="number"
                className={"roundedborderfield"}
                onChange={(e) => {
                  let exc = BigNumber(e.target.value).dividedBy(
                    getRates(
                      option &&
                        option[Selected] &&
                        option[Selected].exchangeSymbol
                    )
                  );
                  setVal1(e.target.value);

                  setVal2(exc);
                }}
                value={val1}
              />
            </Col>
            <Col lg={12}>
              <div className="btext">{t("With")}</div>
            </Col>
            <Col lg={7}>
              {" "}
              <DropdownSearch
                options={option}
                coinItem={coinItem}
                Selected={Selected}
                setSelectedIndex={(e) => {
                  setSelectedIndex(e);
                  setVal1("");
                  setVal2("");
                }}
              />
            </Col>
            <Col lg={5}>
              <InputField
                type="number"
                className={"roundedborderfield"}
                onChange={(e) => {
                  setVal2(e.target.value);

                  let exc = BigNumber(e.target.value).multipliedBy(
                    getRates(
                      option &&
                        option[Selected] &&
                        option[Selected].exchangeSymbol
                    )
                  );

                  setVal1(exc);
                }}
                value={val2}
              />
            </Col>
            {loadingRate ? (
              <Spinner animation="grow" className="spincolor" />
            ) : (
              <div className="txtmargin">
                <div className="bottomtext">{t("ExchangeRates")}:</div>
                <div className="bottomtext">
                  1{" "}
                  {option &&
                    option[Selected] &&
                    option[Selected].exchangeSymbol}{" "}
                  ={" "}
                  {getRates(
                    option &&
                      option[Selected] &&
                      option[Selected].exchangeSymbol
                  )}{" "}
                  {selectedCoin &&
                    selectedCoin.coin &&
                    selectedCoin.coin.exchangeSymbol}
                </div>
              </div>
            )}
            {
              <Loader
                msg={t("TransactionWaitMessage")}
                loading={exchangeTokenloading}
              />
            }

            <Col lg={12}>
              {BigNumber(val1).isGreaterThan(0) && (
                <div className="buy">
                  {BigNumber(balances).isGreaterThanOrEqualTo(val2) ? (
                    <CustomButton
                      type="submit"
                      name={t("Buy")}
                      filled={true}
                      primary={true}
                      onClick={() => {
                        exchangeTokenOrCoin({
                          type: "buy",
                          t1:'crypto',
                          t2:'crypto',
                          amount: val2,
                          amount2: val1,
                          tokenName1: Number(
                            option && option[Selected] && option[Selected].id
                          ),
                          tokenName2: Number(
                            CyborCoin && CyborCoin[0] && CyborCoin[0].id
                          ),
                          exchangeRate: getRates(
                            option &&
                              option[Selected] &&
                              option[Selected].exchangeSymbol
                          ),
                        });
                      }}
                    />
                  ) : (
                    <div className="insufficientbal">
                      {(val2 != "" || val1 != "") && (
                        <span>{t("InsufficientBalance")}</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({ wallet = {}, ido = {} }) => {
  const loadingRate = _.get(wallet, "loadingRate", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const createTransactionLoading = _.get(
    ido,
    "createTransactionLoading",
    false
  );
  const exchangeTokenloading = _.get(wallet, "exchangeTokenloading", false);

  return {
    loadingRate,
    exchangeRates,
    createTransactionLoading,
    exchangeTokenloading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),
  exchangeTokenOrCoin: (data) =>
    dispatch({ type: "exchangeTokenOrCoincalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyPopup);
