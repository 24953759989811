import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import copy from "@images/copy.svg";
import { Modal, Container, Form, Col, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import CustomButton from "src/components/custom-button/CustomButton";
import { ReactComponent as DownIcon } from "@images/down.svg";
import moment from "moment";

import "@components/print-popup/PrintPopupStyles.scss";
import { toast } from "react-toastify";
import banklogo from "@images/banklogo.png";
import { useTranslation } from "react-i18next";

const PrintPopup = ({
  setShowModal,
    data,
    show1,
    
    paymentbytoken,
    getExchangerate,
    createpaymentLink,
    myaccountandbalance,
    
    exchangeRates,
    settings,
    setshowi,
    showi,
    
    paymentToken,
    thememode,
    componentRef,
    currencyToReceive,
    currencyToConvert,
},ref) => {
  
    // getplatformTokensinfo,
    
    
    
   
  let [selected, setSelectedIndex] = useState(0);
  let [selected2, setSelectedIndex2] = useState(1);
  const [amount, setamount] = useState("");
  const [email, setemail] = useState("");
  let { result, sym1, sym2 } = exchangeRates || {};
  const [validated, setValidated] = useState(false);
  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter(
      (item) => item.coin.exchangeSymbol == cur
    );
    let address = _.get(obj, "[0].address", 0);

    return address;
  };
  //   useEffect(() => {
  //     getExchangerate({
  //       symbol1:
  //         getplatformTokensinfo &&
  //         getplatformTokensinfo[selected] &&
  //         getplatformTokensinfo[selected].exchangeSymbol,
  //       symbol2:
  //         getplatformTokensinfo &&
  //         getplatformTokensinfo[selected2] &&
  //         getplatformTokensinfo[selected2].exchangeSymbol,
  //     });
  //   }, [selected2, selected]);

  const { t, i18n, ready } = useTranslation();

  return (
    <div ref={ref}  className="paymentSuccess">
    {/* <Modal
      className="paymentSuccess"
      show={showi}
      
      onHide={() => {
        setshowi(false);
        setShowModal(true);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    > */}
      {/* <Modal.Header>
        <Modal.Title>
          <span className="exchangetitle">{t("LinkCreatedSuccessfully")}!</span>
        </Modal.Title>
      </Modal.Header>
      <CloseIcon
        className="closebtn"
        onClick={() => {
          setshowi(false);
          setShowModal(false);
        }}
      /> */}
      {/* <Modal.Body> */}
        <Container>
          {/* <Row>
            <Col lg={12}>
              <div className="imgsection">
                <img src={banklogo} className="imglogo"></img>
              </div>
              <div className="text">
              Barbaros, Buğday Sk. No:5, 06680 Çankaya/Ankara
              </div>
            </Col>
          </Row> */}
          <div class="split left">
          <div className="qrcode-section">
            {/* <QRCode
              size="200"
              value={`${window.location.origin}/pay/${
                paymentToken && paymentToken.link
              }`}
              bgColor={
                thememode == "light"
                  ? settings.lightTheme.bgwhite
                  : settings.darkTheme.bgwhite
              }
              fgColor={
                thememode == "light"
                  ? settings.lightTheme.textdarker
                  : settings.darkTheme.textdarker
              }
            /> */}
             <QRCode
                  size="200"
                  className={"margin"}
                  value={`${window.location.origin}/pay/${paymentToken && paymentToken.link
                    }`}
                  bgColor={
                    thememode == "light"
                      ? settings &&
                      settings.lightTheme &&
                      settings.lightTheme.bgwhite
                      : settings &&
                      settings.darkTheme &&
                      settings.darkTheme.bgwhite
                  }
                  fgColor={
                    thememode == "light"
                      ? settings &&
                      settings.lightTheme &&
                      settings.lightTheme.textdarker
                      : settings &&
                      settings.darkTheme &&
                      settings.darkTheme.textdarker
                  }
                />
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("PaymentID")}</span>
            </div>
            <div className="value">
              <span className="valuetext transectionid">
                {paymentbytoken.paymentLinkToken}
              </span>
            </div>
          </div>

          <div className="detail ">
            <div className="label">
              <span className="labeltext">{t("Date")}</span>
            </div>
            <div className="value">
              <span className="valuetext transectionid">
                {" "}
                {moment(paymentbytoken.createdOn).format("MM/DD/YYYY hh:mm A")}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Recepient")}</span>
            </div>
            <div className="value">
              {" "}
              {paymentbytoken.toPaymentLinkWallet &&
                paymentbytoken.toPaymentLinkWallet.user && (
              <span className="valuetext">
                {paymentbytoken.toPaymentLinkWallet.user.name}{" "}
                    {paymentbytoken.toPaymentLinkWallet.user.subName}
              </span>
                )}
            </div>
          </div>
          </div>
          <div class="split right">
          <div className="detail2">
            <div className="label">
              <span className="labeltext">{t("Amount")}</span>
            </div>
            <div className="line1">
              <div className="valuetext">
                <span className=" transectionid textcolor2 ">
                {paymentbytoken && paymentbytoken.currencyAmount}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                </span>
              </div>
            </div>
          </div>

          <div className=" detail2">
            <div className="label">
              <span className="labeltext">{t("Commission")}</span>
            </div>
            <div className="line2">
              <div className="valuetext">
                <span className=" transectionid textcolor2">
                {paymentbytoken && paymentbytoken.commissionAmount}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                </span>
              </div>
            </div>
          </div>

          <div className=" detail2">
            <div className="label">
              <span className="labeltext">{t("Total")}</span>
            </div>
            <div className="line3">
              <div className="valuetext">
                <span className=" transectionid textcolor2">
                {" "}
                      {paymentbytoken &&
                        paymentbytoken.currencyAmountCommission}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                </span>
              </div>
            </div>
          </div>

          <div className="currencytoconvert">
            <div className="box">
              <div className="commonsection">
                <div className="link">
                  <span className="linktxt">{t("ClientPays")}: </span>
                </div>
                <span className="textline1">
                {" "}
                      {paymentbytoken &&
                        paymentbytoken.currencyAmountCommission}{" "}
                      {paymentbytoken && paymentbytoken.currency.exchangeSymbol}
                </span>
                <span className="textline2">
                {paymentbytoken && paymentbytoken.currency.currencyName}
                  {/* {paymentbytoken.exchangeTo} */}
                </span>
              </div>
              {/* <div className="imgsection">
                <DownIcon className="down" />
              </div> */}
              <div className="commonsection">
                <div className="link">
                  <span className="linktxt">{t("CurrencytoReceive")}: </span>
                </div>
                <span className="textline1">
                  {currencyToReceive} {" "}
                  { paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                  {/* {Number(result * paymentbytoken.amount).toFixed(5)} */}
                </span>
                {/* <span className="textline2"> */}
                  {/* (1 BTC = 500000 TRY) BTC - Bitcoin */}
                  {/* ( 1{" "}
                  {paymentbytoken.toPaymentLinkCoin &&
                    paymentbytoken.toPaymentLinkCoin.exchangeSymbol}{" "}
                  / {Number(result).toFixed(5)}{" "}
                  {paymentbytoken.fromPaymentLinkCoin &&
                    paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}
                  ) */}
                {/* </span> */}
                <span className="textline2">
                  {/* {paymentbytoken.acceptAs} */}
                  {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.exchangeSymbol}{" "}
                      -{" "}
                      {paymentbytoken.fromPaymentLinkCoin &&
                        paymentbytoken.fromPaymentLinkCoin.tokenName}
                </span>
              </div>
              <div className="commonsection">
                <div className="link">
                  <span className="linktxt">{t("CurrencytoConvert")}: </span>
                </div>
                <span className="textline1">
                {" "}
                      {/* {Number(paymentbytoken.amount).toFixed(5)}{" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.exchangeSymbol} */}
                      {currencyToConvert}
                </span>
                <span className="textline2">
                {" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.exchangeSymbol}{" "}
                      -{" "}
                      {paymentbytoken.toPaymentLinkCoin &&
                        paymentbytoken.toPaymentLinkCoin.tokenName}
                </span>
                <span className="textline2">
                  {/* {paymentbytoken.acceptAs} */}
                </span>
              </div>
            </div>
          </div>

          {/* <div className="currencytoconvert">
            <div  >
              <p> </p>
              <img
                onClick={() => {
                   navigator.clipboard.writeText(
                    `${window.location.origin}/pay/${paymentToken&&paymentToken.link}`
                  );
                }}
                src={copy}
                className="copy"
              />
            </div>
            <div className="buttoncontainer">
              <CustomButton
                type="submit"
                name="Clsoe"
                filled={true}
                primary={true}
                onClick={() => setShowModal(false)}
              />
            </div>
          </div> */}
          </div>
        </Container>
      {/* </Modal.Body>
    </Modal> */}
    </div>
  );
};

export default React.forwardRef(PrintPopup);
