import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import "@components/add-coin-modal/AddCoinModalStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as PlusIcon } from "@images/addcoin.svg";
import { ReactComponent as CrossIcon } from "@images/coincross.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import coinimg from "@images/coinimg.svg";
import SearchBox from "src/components/search-box/SearchBox";
import CustomButton from "src/components/custom-button/CustomButton";
import { connect } from "react-redux";
import { setMarketRatesCurrencies } from "@store/userredux";

import { useTranslation } from "react-i18next";
const Addcoin = ({
  setShowAddcoin,
  show1,
  getplatformTokensinfo,
  displayedCurrencies,
  plaformcurrencies,
  value,
  setvalue,
  setCoinOfState,
  getBal,
  setcoinItem,
  addNewCoin,
}) => {
  // useEffect(() => {
  //   setvalue("");
  // }, [show1]);

  const [search, setSearch] = useState("");
  const [allTokens, setAllTokens] = useState([]);

  useEffect(() => {
    filterTokenList();
  }, [plaformcurrencies, getplatformTokensinfo]);

  useEffect(() => {
    filterTokenList();
  }, [search]);

  const filterTokenList = () => {
    let totalCoin = [];
    if (plaformcurrencies !== undefined) {
      totalCoin = [...getplatformTokensinfo, ...plaformcurrencies];
    } else {
      totalCoin = [...getplatformTokensinfo];
    }

    setAllTokens(
      totalCoin
        .filter((itm) => {
          if (
            itm.tokenName !== undefined &&
            itm.tokenName.toLowerCase().includes(search.toLowerCase())
          ) {
            return itm;
          } else {
            if (
              itm.currencyName !== undefined &&
              itm.currencyName.toLowerCase().includes(search.toLowerCase())
            ) {
              return itm;
            }
          }
        })
        .map((itm) => itm)
    );
  };

  const onChangeSearchBox = (val) => {
    setSearch(val);
  };

  const removeCryptoCoins = (id) => {
    let cryptolist = [];
    if (displayedCurrencies.crpto.length === 0) {
      return [];
    } else {
      cryptolist = displayedCurrencies.crpto.filter((data) => {
        if (data !== id) {
          return data;
        }
      });

      return cryptolist;
    }
  };

  const removeFiatCoins = (id) => {
    let fiatlist = [];
    if (displayedCurrencies.fiat.length === 0) {
      return [];
    } else {
      fiatlist = displayedCurrencies.fiat.filter((data) => {
        if (data !== id) {
          return data;
        }
      });

      return fiatlist;
    }
  };

  const setCryptoCoins = (id) => {
    let currentTokenList = displayedCurrencies.crpto;
    if (currentTokenList === undefined) {
      return [id];
    }
    if (!currentTokenList.includes(id)) {
      return [...displayedCurrencies.crpto, id];
    } else {
      return [...displayedCurrencies.crpto];
    }
  };

  const setFiatCoins = (id) => {
    let currentTokenList = displayedCurrencies.fiat;
    if (currentTokenList === undefined) {
      return [id];
    }
    if (!currentTokenList.includes(id)) {
      return [...displayedCurrencies.fiat, id];
    } else {
      return [...displayedCurrencies.fiat];
    }
  };

  const { t } = useTranslation();
  return (
    <Modal
      className="addcoin"
      show={show1}
      onHide={() => setShowAddcoin(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hide "></div>
      <Modal.Header>
        <Modal.Title className="addcoin">
          <span className="deletetitle">{t("AddCoin")}</span>
        </Modal.Title>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowAddcoin(false);
          }}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="rightnav hide" onClick={() => setShowAddcoin(false)}>
          <ExpandIcon />
        </div>
        <div className="addcointxt hide">
          <span>{t("AddCoin")}</span>
        </div>
        <Container>
          <Row className="topspace addcoinscroll">
            <Col lg={12}>
              <SearchBox onChangeValue={onChangeSearchBox} />
              {allTokens.length !== 0 &&
                allTokens.map((token) => (
                  <div className="outerbox">
                    <div className="outerflex">
                      <div className="coinimg">
                        <img
                          className="logoSize"
                          src={`${process.env.REACT_APP_IMGLINK}${token.colouredLogo}`}
                        />
                      </div>
                      <div className="coinname">
                        <p className="nametxt">
                          <span className="nametxt">
                            {token.exchangeSymbol}
                          </span>
                          <span className="cointag">{token.type}</span>
                        </p>
                        <p className="nametxt">{token.tokenName}</p>
                        <p className="nametxt">{token.currencyName}</p>
                      </div>
                      <div className="rightalign">
                        {token.tokenType !== undefined &&
                        token.tokenType === "crypto" ? (
                          displayedCurrencies.crpto &&
                          displayedCurrencies.crpto.includes(token.id) ? (
                            <CrossIcon
                              className="plusicon crossic"
                              onClick={() => {
                                //setCoinOfState({crpto: displayedCurrencies.crpto,fiat: removeFiatCoins(token.id),})
                                setCoinOfState({
                                  crpto: removeCryptoCoins(token.id),
                                  fiat: displayedCurrencies.fiat,
                                });
                              }}
                            />
                          ) : (
                            <PlusIcon
                              className="plusicon"
                              onClick={() => {
                                addNewCoin({
                                  coinId: token.id,
                                });
                                setCoinOfState({
                                  crpto: setCryptoCoins(token.id),
                                  fiat: displayedCurrencies.fiat,
                                });
                              }}
                            />
                          )
                        ) : displayedCurrencies.fiat &&
                          displayedCurrencies.fiat.includes(token.id) ? (
                          <CrossIcon
                            className="plusicon crossic"
                            onClick={() => {
                              setCoinOfState({
                                crpto: displayedCurrencies.crpto,
                                fiat: removeFiatCoins(token.id),
                              });
                            }}
                          />
                        ) : (
                          <PlusIcon
                            className="plusicon"
                            onClick={() => {
                              setCoinOfState({
                                crpto: displayedCurrencies.crpto,
                                fiat: setFiatCoins(token.id),
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

// const mapStateToProps = ({ user={} }) => {
//   const displayedCurrencies = _.get(user, "marketRateDisplayCurrencies", []);
//   return {
//     displayedCurrencies
//   }
// }

// const mapDispatchToProps = (dispatch) => ({
//   addCurrency: (data) => dispatch(setMarketRatesCurrencies(data))
// })

export default React.memo(Addcoin);
