import * as _ from "lodash";
import React from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/log-out/LogoutStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
const Logout = ({
  show1,
  setShowModal,
  onPress,
  address,
  amount,
  onClick,
  setHideThis,
  logouts,
}) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="logout"
      show={show1}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext">{t("WantToLogout")}</div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name={t("Yes")}
                  filled={true}
                  primary={true}
                  onClick={() => onClick()}
                />
              </div>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <div className="cancel">
                <CustomButton
                  type="submit"
                  name={t("No")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(Logout);
