import CustomButton from "@components/custom-button/CustomButton";
import Header from "@components/header/Header";
import InputField from "@components/input-field/InputField";
import OnboardingNavbar from "@components/navbar/OnboardingNavBar";
import Loader from "@components/loader/Loader";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";
import { Col, Form, Row, FormCheck } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import "@features/onboarding/signup/SignUpScreenStyles.scss";
import { useTranslation } from "react-i18next";
import SignUpBannerLightPersonal from "@images/signupforlightpersonal.svg";
import SignUpBannerDarkPersonal from "@images/signupfordarkpersonal.svg";
import SignUpBannerLightBusiness from "@images/signupforlightbusiness.svg";
import SignUpBannerDarkBusiness from "@images/signupfordarkbusiness.svg";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import Alert from "react-bootstrap/Alert";
import Footer from "@components/footer/Footer";
import FooterCommon from "@components/footer-common/FooterCommon";
import BannerImage from "@components/onboarding-animated-image/BannerImage";

function SignUp(props) {
  const [name, setname] = useState("");
  const [subName, setsubName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validated, setValidated] = useState(false);
  const [showeye, setshoweye] = useState(true);
  const [email, setemail] = useState("");
  const [error2, seterror2] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setcode] = useState("91");
  const [subtye, setsubtye] = useState(false);
  const [tscheck, settscheck] = useState(false);
  const [errorFromApi, setErrorFromApi] = useState(
    props.signUpError ? props.signUpError : ""
  );
  const { exchange, thememode, role, defaultrole } = props;
  const [imageChange1, setimagechange] = useState(
    defaultrole == "Personal" ? true : false
  );
  const [currentLang, setCurrentlang] = useState("en");

  useEffect(() => {
    if (localStorage.lang !== undefined) {
      setCurrentlang(localStorage.lang);
    }
  }, [localStorage.lang]);

  useEffect(() => {
    if (props.signUpError !== undefined) {
      setErrorFromApi(props.signUpError);
    }
  }, [localStorage.lang]);

  let errorMapper = {
    MOBILE_ALREADY_IN_USE: "MOBILE_USED",
    PASSWORD_MUST_BE_MORE_THAN_8: "passwordmorethan8",
  };
  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (password === password2) {
      if (password2.length >= 8 && password2.length <= 20) {
        seterror2("");
        props.clearErrorMessage();

        if (form.checkValidity() === true) {
          event.preventDefault();
          props.usersignUp({
            mobile: code,
            password,
            name,
            subName,
            email,
            loginType: "email",
            navigation: props.history,
            sendVerification: true,
            exchangeId: props.exchangeId,
            role: props.defaultrole,
            lang: currentLang,
          });
        }
      } else {
        props.clearErrorMessage();

        event.preventDefault();
        if (password2.length < 8) seterror2("MIN_LENGTH");
        if (password2.length > 20) seterror2("MAX_LENGTH");
      }
    } else {
      props.clearErrorMessage();

      event.preventDefault();
      seterror2("passwordnomatch");
    }
  };
  useEffect(() => {
    //  props.logouts({ history });

    props.clearErrorMessage();
  }, []);
  var re = /[^A-Z^a-z^0-9^&#351;&#350;&#305;&#304;çÇöÖüÜ&#286;&#287;\s]+/;

  const { t } = useTranslation();
  useEffect(() => {}, []);

  useEffect(() => {
    console.log(props.signUpError);
  }, [localStorage.lang, props.signUpError]);

  return (
    <div
      id="signup"
      className={`${props.thememode == "dark" ? "fordarkbg" : "forlightbg"}`}
    >
      <OnboardingNavbar
        location={useLocation()}
        setimageChange={setimagechange}
      />
      <Container className="content">
        <Row className="topspace">
          <div className="centeralign">
            {(imageChange1 && (
              // <img
              //   alt=""
              //   className=" logoimg hide"
              //   src={
              //     thememode == "light"
              //       ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalLightmode}`
              //       : `${process.env.REACT_APP_IMGLINK}${exchange.cyborPersonalDarkmode}`
              //   }
              // />
              <img src={paymentsolutionlogo} className=" logoimg hide" />
            )) || (
              // <img
              //   alt=""
              //   className=" logoimg hide"
              //   src={
              //     thememode == "dark"
              //       ? `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessDarkmode}`
              //       : `${process.env.REACT_APP_IMGLINK}${exchange.cyborBusinessLightmode}`
              //   }
              // />
              <img src={paymentsolutionlogo} className=" logoimg hide" />
            )}
          </div>
          <Col lg={7} className={`imageCol ${imageChange1 && "personalImg"}`}>
            <BannerImage imageChange={imageChange1} page="signup" />
          </Col>
          {props.signUpLoading && <Loader />}

          <Col lg={5}>
            <div className="outerbox">
              <div className="link-to-signin">
                {t("DoYouHaveAccount")}{" "}
                <Alert.Link
                  className="signin-register-link pointer"
                  onClick={() => props.history.push("/signin")}
                >
                  {" "}
                  {t("Login")}
                </Alert.Link>
              </div>
              <Fade right duration={500} distance="80px" opposite delay={100}>
                {/* <Header menu={false} /> */}
                <div className="topheader">
                  <span className="topheader1">{t("SignUpTo")}</span>
                  {imageChange1 ? (
                    <span className="topheader2">{t("CyborPay")}</span>
                  ) : (
                    <span className="topheader2">{t("CyborPayBusiness")}</span>
                  )}
                </div>

                {/* <img
            alt="cybor"
            src={
              thememode == "light"
                ? exchange &&
                  `${process.env.REACT_APP_IMGLINK}${exchange.lightLogo}`
                : exchange &&
                  `${process.env.REACT_APP_IMGLINK}${exchange.darkLogo}`
            }
            className="imgonsignup"
          /> */}
                <Form noValidate validated={validated} onSubmit={onSendmsg}>
                  <Row>
                    <div className="formfieldholder">
                      <div className="heading">
                        <span>{t("fname")}</span>
                      </div>
                      <InputField
                        type="text"
                        placeholder={t("fname")}
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                        classname={"bottomborder"}
                        required
                        isInvalid={validated && name.length < 3}
                        error={"fms"}
                      />
                    </div>

                    <div className="formfieldholder">
                      <div className="heading">
                        <span>{t("lname")}</span>
                      </div>
                      <InputField
                        type="text"
                        placeholder={t("lname")}
                        onChange={(e) => setsubName(e.target.value)}
                        value={subName}
                        classname={"bottomborder"}
                        required
                        isInvalid={validated && subName.length < 3}
                        error={"lms"}
                      />
                    </div>

                    <div className="formfieldholder">
                      <div className="heading">
                        <span>{t("emailid")}</span>
                      </div>
                      <InputField
                        type="text"
                        placeholder={t("Email")}
                        onChange={(e) => setemail(e.target.value)}
                        value={email}
                        classname={"bottomborder"}
                        required
                        error={t("enteremailid")}
                      />
                    </div>

                    <div className="formfieldholder">
                      <div className="heading">
                        <span>{t("entpass")}</span>
                      </div>
                      <InputField
                        type={showeye ? "password" : "text"}
                        placeholder={t("Password")}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                        classname={"bottomborder"}
                        isInvalid={validated && password.length < 8}
                        error={
                          password.length === 0 ? "passwordnotempty" : "pts"
                        }
                        eye
                        setshow={() => setshoweye(!showeye)}
                        showeye={showeye}
                      />
                    </div>
                    <div className="formfieldholder">
                      <div className="heading">
                        <span>{t("rntrpass")}</span>
                      </div>
                      <InputField
                        placeholder={t("reentpass")}
                        type={showeye ? "password" : "text"}
                        onChange={(e) => setPassword2(e.target.value)}
                        value={password2}
                        required
                        classname={"bottomborder"}
                        eye
                        setshow={() => setshoweye(!showeye)}
                        showeye={showeye}
                        isInvalid={validated && password.length < 8}
                        error={
                          password2.length === 0 ? "passwordnotempty" : "pts"
                        }
                      />
                    </div>
                    <div className="formfieldholder">
                      <div className="heading">
                        <span>{t("phone")}</span>
                      </div>
                      <InputField
                        phone
                        type="Phone"
                        placeholder={t("phone")}
                        onChange={(e) => setcode(e)}
                        value={phone}
                        required
                        isInvalid={validated && phone.length < 10}
                        error={phone.length == 0 ? "PCBE" : "PhoneMustValid"}
                      />
                    </div>
                    <div className="checkspace">
                      <div
                        className="checktext"
                        onClick={() => {
                          setsubtye(subtye ? false : true);
                        }}
                      >
                        <Form.Check
                          type="checkbox"
                          required
                          checked={subtye}
                          label={t("Subscribe")}
                        />
                      </div>
                      <div
                        className="checktext"
                        onClick={() => {
                          settscheck(tscheck ? false : true);
                        }}
                      >
                        <Form.Check
                          required
                          type="checkbox"
                          checked={tscheck}
                          label={
                            <span>
                              {t("Terms")}{" "}
                              <a
                                href="#"
                                // href="https://cyborholding.com/ru/privacy-policy/"
                                className="checktxt"
                              >
                                {t("Term")}
                              </a>
                            </span>
                          }
                        />
                      </div>
                    </div>
                    <Col lg={12}>
                      {" "}
                      <Col className="mt-1 greyText" md="auto">
                        {t(`${error2}`)}
                      </Col>
                      {props.signUpError && (
                        <Col className="mt-1 greyText" md="auto">
                          {t(`${props.signUpError}`)}
                          {/* {t(errorFromApi)} */}
                        </Col>
                      )}
                      <div>
                        <div className="outerconforbtn displayblock">
                          <CustomButton
                            disable={props.signUpLoading}
                            type="submit"
                            name={t("SignUp")}
                            filled={true}
                            primary={true}
                          />
                        </div>
                        <div className="displayblock signuptxt ">
                          <span
                            onClick={() => props.history.push("/signin")}
                            className=" pointer hidesignup"
                          >
                            {t("SignIn")}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
                {/* <div className="outerconttxt">
            <span
              onClick={() => props.history.push("/signin")}
              className=" pointer txtclass"
            >
              {t("SignIn")}
            </span>
          </div> */}
              </Fade>
            </div>
          </Col>
        </Row>

        <div className="signupfooter hidetop">
          <Footer theme={thememode} />
        </div>
      </Container>
      <div className=" signupfooter hidesignup">
        <FooterCommon />
      </div>
    </div>
  );
}
const mapStateToProps = ({ app = {}, user = {} }) => {
  const signUpLoading = _.get(user, "signUpLoading", false);

  const signUpError = _.get(user, "signUpError", "");
  const role = _.get(user, "userInfo.role", "");
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  const defaultrole = _.get(app, "role", "Business");

  return {
    signUpLoading,
    signUpError,
    exchangeId,
    exchange,
    thememode,
    role,
    defaultrole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  usersignUp: (data) => dispatch({ type: "usersignUpcalled", payload: data }),
  clearErrorMessage: (data) =>
    dispatch({ type: "clearErrorMessageCalled", payload: data }),
  logouts: (data) => dispatch({ type: "logoutCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
