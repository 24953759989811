import * as _ from "lodash";
import React from "react";
import "@components/pay-popup/PayPopupStyle.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import CustomButton from "src/components/custom-button/CustomButton";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DetailedPopup = ({ setShowModal, data, show1 }) => {
  let { coin } = data || {};
  let { decimals, exchangeSymbol, explorerLink } = coin || {};
  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="Paypopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon className="closebtn" onClick={() => setShowModal(false)} />
        <Container>
          <div className="transectiontype">
            <span className="transectiontext uppercase">
              Payment Order
            </span>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">Payment Link From:</span>
            </div>
            <div className="name">
              <div className="longvalue">
                <span className="valuetext">Prachi</span>
              </div>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">Payment Link ID:</span>
            </div>
            <div className="valuetransid">
              <div className="longvalue">
                <span className="valuetext">41e87662241e87662241e876622</span>
              </div>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">Amount</span>
            </div>
            <div className="valueamout">
              <div className="longvalue">
                <span className="valuetext">0,993499349934 CYBOR</span>
              </div>
            </div>
          </div>

          <Row>
            <Col lg={6}>
              <div className="cancel">
                <CustomButton
                  type="submit"
                  name="Cancel"
                  filled={true}
                  primary={true}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Confirm"
                  filled={true}
                  primary={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(DetailedPopup);
