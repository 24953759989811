const currencies = [
  {
    key: "USD",
    Symbol: "$",
    exchangeSymbol:'USDT'
    
  },
  {
    key: "TRY",
    Symbol:"₺",
    exchangeSymbol:'TRY'
  },
  {
    key: "EUR",
    Symbol:"€",
    exchangeSymbol:'EUR'
  },
  {
    key: "GBP",
    Symbol:"£",
    exchangeSymbol:'GBP'
  },
];

export default currencies;

export const getCSymbol = (sym) => {
  var c = currencies.find((i) => i.key == sym)
   return  c&&c.Symbol || "$" 
};

export const getESymbol = (sym) => {
  var c = currencies.find((i) => i.key == sym)
   return  c&&c.exchangeSymbol  ||"USDT"
}
