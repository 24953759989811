export default {
  Balances: "Balances",
  CopyWalletNumber: "just copy your wallet number below",
  MarketRate: "Market Rate",
  BasedOn: "Based on",
  Volume: "Volume",
  MarketCap: "Market Cap",
  WalletNumber: "wallet number",
  AllType: "All Type",
  EstimateBalance: "Estimate Balance",
  HideZeroBalance: "Hide zero balance",
  Filter: "Filter",
  Reset: "Reset",
  AddBankConfirmed: "Add Bank Confirmed !",
  "amount should not be empty": "Amount should not be empty",
  NoResultsFound: 'No results found',
  exchange_amt:"Exchange Amount",
  AmountL:'Amount',
  T0: "To",
  "Internal server error": "Internal server error",
  Pleaseenteramount: "Please enter amount",
  DetermineamounttoDepositorExchange: "Determine amount to Deposit or Exchange Amount",
  ReceiveLeft: "Receive",
  ReceiveRight: " ",
};
