import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import "@components/dropdown-for-deposit-modal/DropdownDepositModal.Styles.scss";
import { Dropdown, FormControl } from "react-bootstrap";
import { ReactComponent as DropdownarrowIcon } from "@images/dropdownsearch/dropdownarrow.svg";
import SearchBox from "@components/search-box/SearchBox";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function DropdownDepositModal({
  getplatformTokensinfo,
  getBal,
  balance,
  setcoinItem,
  options,
  Selected,
  setSelectedIndex,
  symbol,
}) {
  const [value, setValue] = useState("");
  const [dropdownshow, setShow] = useState(false);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="dropdownlink"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (!dropdownshow) {
          onClick(e);
          // setShow(true);
        }
      }}
      // show={dropdownshow}
    >
      {children}
      <span className="toogleicon">
        <DropdownarrowIcon className="dropdownarrow" />
      </span>
    </div>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div ref={ref} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.value.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  return (
    <div className="dropdowndownmobiledepositmodal">
      <Dropdown align="end">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <span className="selecteditem">
            {options && options[Selected] && options[Selected].colouredLogo && (
              <span className="coinicon">
                <img
                  src={
                    options &&
                    options[Selected] &&
                    `${process.env.REACT_APP_IMGLINK}${options[Selected].colouredLogo}`
                  }
                ></img>
              </span>
            )}

            <span className="cointext">
              {options && options[Selected] && options[Selected].tokenName}
            </span>
            {((options &&
              options[Selected] &&
              options[Selected].type == "TRC20") ||
              (options &&
                options[Selected] &&
                options[Selected].type == "BEP20") ||
              (options &&
                options[Selected] &&
                options[Selected].type == "ERC20")) && (
              <span className="cointag">
                {options && options[Selected] && options[Selected].type}
              </span>
            )}
          </span>
          <FormControl
            autoFocus
            className="search"
            //placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={value}
            autoComplete="off"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {options &&
            options.map((option, index) => {
              return (
                <Dropdown.Item
                  eventKey={index}
                  className={Selected == index ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedIndex(index);

                    setShow(false);
                  }}
                  value={options && options[index] && options[index].tokenName}
                >
                  {options && options[index] && options[index].colouredLogo && (
                    <span className="coinicon">
                      <img
                        src={
                          options &&
                          options[index] &&
                          `${process.env.REACT_APP_IMGLINK}${options[index].colouredLogo}`
                        }
                      ></img>
                    </span>
                  )}

                  <span className="cointext">
                    {options && options[index] && options[index].tokenName}
                  </span>
                  {((options &&
                    options[index] &&
                    options[index].type == "TRC20") ||
                    (options &&
                      options[index] &&
                      options[index].type == "BEP20") ||
                    (options &&
                      options[index] &&
                      options[index].type == "ERC20")) && (
                    <span className="cointag">
                      {options && options[index] && options[index].type}
                    </span>
                  )}
                  <div className="coinbalance">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 0, hide: 1000 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {Number(balance).toFixed(4)} {symbol}
                        </Tooltip>
                      )}
                    >
                      <span>
                        {" "}
                        {Number(balance(options && options[index] && options[index])).toFixed(4)} {symbol}
                      </span>
                    </OverlayTrigger>
                  </div>
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default DropdownDepositModal;
