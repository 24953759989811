import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import Loader from "@components/loader/Loader";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import "@components/withdrawform-fiat/WithdrawFormFiatStyles.scss";
import {
  createFiatWithDrawlStart,
  getAllBankAccountsStart,
} from "@store/fiatredux";
import AddBankAccountPopup1 from "../add-bank-account-popup1/AddBankAccountPopup1";
import AddBankAccountPopup2 from "../add-bank-account-popup2/AddBankAccountPopup2";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import AddressDropdown from "@components/address-dropdown/AddressDropdown";
import { clearFiatWithDrawErrorClear } from "@store/fiatredux";
import { setCurrentFiatInfo } from "@store/fiatredux";
import MaxDropdown from "@components/max-dropdown/MaxDropdown";
import FiatWithdrawSuccessModal from "@components/withdrawlfiat-success/WithDrawlFiatSuccess";
import { setDynamicFiatCurrency } from "@store/fiatredux";
import { setBanksForSpecificFiat } from "@store/fiatredux";
import { ReactComponent as PlusIcon } from "@images/plus.svg";

function WithdrawFormFiat(props) {
  let {
    userAccessToken,
    fiatWithdrawlStart,
    fiatBalance,
    fiatWalletBalance,
    allBankAccounts,
    plaformcurrencies,
    selectedBankAccounts,
    createFiatWithdrawlError,
    createFiatWithdrawlRequestLoading,
    setHideThis,
    activeBankAccounts,
    selectedCurrency,
    selectedFiatCurrencyInfo,
    clearFiatWithDrawErrorClearAsync,
    setCurrentFiatInfoAsync,
    setDynamicFiatCurrencyAsync,
    setBanksForSpecificFiatAsync,
  } = props || {};
  const [validated, setValidated] = useState(false);
  const [coinItem, setcoinItem] = useState([]);
  const [amount, setamount] = useState("");
  const [allcurrencies, setallcurrencies] = useState([]);
  let [Selected, setSelectedIndex] = useState("");
  const [selectedBankAccountId, setSelectedBankAccountId] = useState();
  const [showAddBankAccountPopup1, setShowAddBankAccountPopup1] =
    useState(false);
  const [filteredBankAccounts, setFilteredBankAccounts] = useState([]);
  const [selectedBankAccountIndex,setSelectedBankAccountIndex] = useState()

  useEffect(() => {
    filterBankAccountsBasedOnSpecificFiat();
  }, [activeBankAccounts]);


  useEffect(() => {
    //console.log('Coin Item -> ',coinItem);
    filterBankAccountsBasedOnSpecificFiat();
  }, [coinItem]);

  const filterBankAccountsBasedOnSpecificFiat = () => {
    let newData = [];
    if (
      activeBankAccounts.length !== 0 &&
      coinItem.exchangeSymbol !== undefined
    ) {
      // newData = activeBankAccounts.filter((itm) => {
      //   if (itm.currencyType === coinItem.exchangeSymbol) {
      //     return itm;
      //   }
      // });
      newData =
      activeBankAccounts &&
      activeBankAccounts
        .filter((account) => {
          if (account.currencyType !== null && account.Status !== 'Pending') {
            return account.currencyType.includes(coinItem.exchangeSymbol);
          }
        })
        .map((item) => ({
          id: item.id,
          tokenName: `${item.userName}-${item.IBANs} ${
            item.Status == "Pending" ? t(`${"PendingApproval"}`) : ""
          }`,
          ...item,
        }));
      setBanksForSpecificFiatAsync(newData);
    }
    setFilteredBankAccounts(newData);
    //console.log('New Data -> ',newData)
  };

  const setInitialFiatCurrency = (data) => {
    if (selectedFiatCurrencyInfo.exchangeSymbol !== undefined) {
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].exchangeSymbol === selectedFiatCurrencyInfo.exchangeSymbol
        ) {
          setcoinItem(data[i]);
          setSelectedIndex(i);
          setDynamicFiatCurrencyAsync(data[i]);
        }
      }
    }
  };

  const setPlatformCurr = () => {
    let allcurrenc = [];
    plaformcurrencies &&
      plaformcurrencies.map((items) => {
        allcurrenc.push({
          tokenName: items.currencyName,
          ...items,
        });
        //setCurrentFiat()

        setallcurrencies(allcurrenc);
        selectedCurrency &&
          selectedCurrency.id &&
          setSelectedIndex(
            plaformcurrencies.findIndex((i) => i.id == selectedCurrency.id) || 0
          );
        setcoinItem(selectedCurrency || allcurrenc[0]);
      });
    setInitialFiatCurrency(allcurrenc);
  };


  useEffect(() => {
    setShowAddBankAccountPopup1(false);
    clearFiatWithDrawErrorClearAsync();
    allBankAccounts();
    fiatBalance();
    filterBankAccountsBasedOnSpecificFiat();
    setPlatformCurr();
  }, []);

  const getFiatBal = (cur) => {
    let obj =
      fiatWalletBalance &&
      fiatWalletBalance.filter((item) => item.currencyId == cur);
    let reelbal = _.get(obj, "[0].balance", 0);
    return reelbal;
  };

  const createWithDrawlRequest = () => {
    let data = {
      token: userAccessToken,
      withDrawlDetails: {
        //CurrencyId: parseInt(coinItem && coinItem.id),
        //CurrencyId: parseInt(selectedFiatCurrencyInfo.id !== undefined && selectedFiatCurrencyInfo.id),
        CurrencyId: parseInt(
          allcurrencies.length !== 0 && allcurrencies[Selected].id
        ),
        bankaccountId: filteredBankAccounts[parseInt(selectedBankAccountId)].id,
        amount: amount,
      },
    };
    //console.log(data)
    fiatWithdrawlStart(data);
  };

  const { t, i18n, ready } = useTranslation();
  return (
    <div className="withdrawfiat">
      {createFiatWithdrawlRequestLoading && <Loader />}

      <AddBankAccountPopup1
        showAddBankConfirmation={showAddBankAccountPopup1}
        setShowAddBankConfirmation={setShowAddBankAccountPopup1}
        onPress={() => {}}
        onCancelled={() => {
          setShowAddBankAccountPopup1(false);
        }}
        setHideThis={setHideThis}
        scope="FiatWithdraw"
        setSelectedBankAccountId={setSelectedBankAccountId}
      />

      <Container>
        <div className="withdrawspace">
          <span className="withtitle">{t("Withdraw")}</span>
        </div>
        <Form
          noValidate
          validated={validated}
          className="withdrawspace"
          onSubmit={(event) => event.preventDefault()}
        >
          <Row>
            <Col lg={5}>
              <div className="textleft cointop">
                <span className="labelfont">{t("SelectCurrency")}</span>
              </div>
            </Col>

            <Col lg={7} className="dropdownsearchcol allignleft">
              <DropdownSearch
                setcoinItem={setcoinItem}
                options={allcurrencies}
                coinItem={coinItem}
                setSelectedIndex={(e) => {
                  setSelectedIndex(e);
                  setCurrentFiatInfoAsync(allcurrencies[e]);
                  setcoinItem(allcurrencies[e]);
                  setDynamicFiatCurrencyAsync(allcurrencies[e]);
                }}
                Selected={Selected}
              />
              <div className="textleft balancespace">
                <span className="labelfont1">
                  {t("Balance")}: {getFiatBal(coinItem && coinItem.id)}{" "}
                  {coinItem && coinItem.exchangeSymbol}
                </span>
                
              </div>
            </Col>
          </Row>
          <Row className="mt-lg-4">
            <Col lg={5}>
              <div className="textleft cointop">
                <span className="labelfont">{t("Amount")}</span>
              </div>
            </Col>
            <Col lg={7} className="allignleft">
              <div className="marginbtm amountmax margin-top">
                <InputField
                  value={amount}
                  onChange={(e) => setamount(e.target.value)}
                  required
                  type={"number"}
                  className={"roundedborderfield"}
                  link={true}
                  linktext={
                    <span className="addressbtnwrapper hidden-for-desktop">
                      <MaxDropdown
                        setamount={setamount}
                        bal={getFiatBal(coinItem.currencyName)}
                      />
                    </span>
                  }
                />
              </div>
              <div className="percentage d-none d-lg-block">
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(
                      BigNumber(getFiatBal(coinItem && coinItem.id))
                        .dividedBy(3)
                        .toFixed(6)
                    )
                  }
                >
                  25%
                </span>
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(
                      BigNumber(getFiatBal(coinItem && coinItem.id))
                        .dividedBy(2)
                        .toFixed(6)
                    )
                  }
                >
                  50%
                </span>
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(
                      BigNumber(getFiatBal(coinItem && coinItem.id))
                        .dividedBy(1.33)
                        .toFixed(6)
                    )
                  }
                >
                  {" "}
                  75%
                </span>
                <span
                  className="percentageleftmargin"
                  onClick={() =>
                    setamount(
                      BigNumber(getFiatBal(coinItem && coinItem.id)).toFixed(6)
                    )
                  }
                >
                  100%
                </span>
              </div>
            </Col>
          </Row>
          {/* <Row className="mt-lg-4">
            <Col lg={5}>
              <div className="textleft addmargin">
                <span className="labelfont">{t("AddressToWithdraw")}</span>
              </div>
            </Col>
            <Col lg={7}>
              <div className="marginbtm margintop">
                <InputField
                  value={amount}
                  onChange={(e) => setamount(e.target.value)}
                  required
                  type={"number"}
                  className={"roundedborderfield"}
                  // link={true}
                  // linktext={
                  //   <span className="addressbtnwrapper ">
                  //     <AddressDropdown classs={"d-none"} />
                  //   </span>
                  // }
                />
              </div>
            </Col>
            <div className="ortext">
              <span>{t("Or")}</span>
            </div>
          </Row> */}

          <Form.Group as={Row}>
            <Col lg={5}>
              <div className="textleft addrtitle">
                {filteredBankAccounts && filteredBankAccounts.length === 0 ? (
                  " "
                ) : (
                  <span className="labelfont">
                    {t("SelectAmountToWithdraw")}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={7} className="banksection allignleft">
              <div className="radiolable marginbtm amountmax">
                {/* {filteredBankAccounts && filteredBankAccounts.length !== 0
                  ? filteredBankAccounts.map((account) => {
                      let label = `${account.userName} - ${
                        account.bankAccountNumber
                      }   ${
                        account.Status == "Pending"
                          ? t(`${"PendingApproval"}`)
                          : ""
                      }`;
                      if(account.Status !== "Pending"){
                        return (
                          <>
                            <Form.Check
                              type="radio"
                              className={`${
                                account.Status == "Pending" ||
                                account.Status == "Rejected"
                                  ? "forhidingradiobtn"
                                  : ""
                              }`}
                              label={label}
                              name="account"
                              id="account1"
                              checked={account.id == selectedBankAccountId}
                              onChange={() => {
                                if (account.Status != "Pending") {
                                  setSelectedBankAccountId(account.id);
                                }
                              }}
                            />
                            <div className="labelfont3">{t("ServiceFee")} 1%</div>
                          </>
                        );
                      }
                    })
                  : ""} */}
                  
                {
            // activeBankAccounts && activeBankAccounts.length !== 0 ?
            filteredBankAccounts && filteredBankAccounts.length !== 0 ? (
              <>
                 
                  <div className="inputfieldholder">
                    <DropdownSearch
                      options={filteredBankAccounts}
                      setSelectedIndex={(e) => {
                        setSelectedBankAccountId(e);
                      }}
                      Selected={selectedBankAccountId}
                      scope="FiatDeposit"
                    />
                 
                

                <div
                  className="logomodified"
                  onClick={() => {
                    setHideThis(true);
                    //setForFiatDeposit(true);
                    setShowAddBankAccountPopup1(true);
                  }}
                >
                  <div className="logoholder">
                    <PlusIcon className="tickmark" />
                  </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="formfieldnew">
                <div className="inputfieldholder">
                  <InputField
                    type="text"
                    placeholder={t("DefineNewBankAccount")}
                    classname={"roundedborderfield"}
                    // onChange={(e) => setaddress(e.target.value)}
                    // value={address}
                    disabled
                    required
                  />
                </div>
                <div
                  className="logosection"
                  onClick={() => {
                    setHideThis(true);
                    setShowAddBankAccountPopup1(true);
                  }}
                >
                  <div className="logoholder">
                    <PlusIcon className="tickmark" />
                  </div>
                </div>
              </div>
            )
          }
                {/* <div className="labeloffield">
                  {filteredBankAccounts && filteredBankAccounts.length !== 0 ? (
                    <p className="label">{`${t(
                      "BankAccountFromWhichDeposit"
                    )} ${selectedCurrency.exchangeSymbol}`}</p>
                  ) : (
                    <p className="label">{`${t("Noaccounts")} ${
                      selectedCurrency.exchangeSymbol
                    }`}</p>
                  )}
                </div> */}


              </div>
            </Col>
          </Form.Group>

          {/* <div
            className="bottomtext"
            onClick={() => {
              setHideThis(true);
              setShowAddBankAccountPopup1(true);
            }}
          >
            <p className="newacc">{t("AddNewAccount")}</p>
          </div> */}

          {/* <div className="outercontainer1">
            <CustomButton
              type="submit"
              name={t("AddNewAccount")}
              filled={true}
              primary={true}
              onClick={() => {
                setHideThis(true);
                setShowAddBankAccountPopup1(true);
              }}
            />
          </div> */}

          <span className="errors">{t(`${createFiatWithdrawlError}`)}</span>

          <div className="outercontainer">
            <CustomButton
              type="submit"
              name={t("Continue")}
              filled={true}
              primary={true}
              onClick={() => createWithDrawlRequest()}
              disable={
                selectedBankAccountId == undefined ||
                filteredBankAccounts.length == 0 ||
                amount == "" ||
                !BigNumber(amount).isGreaterThan(0) ||
                //!BigNumber(amount).isGreaterThan(coinItem && coinItem.maxLimit) ||
                !BigNumber(
                  getFiatBal(coinItem && coinItem.id)
                ).isGreaterThanOrEqualTo(amount)
              }
            />
          </div>
        </Form>
      </Container>
    </div>
  );
}

const mapStateToProps = ({ app = {}, user = {}, wallet = {}, fiat = {} }) => {
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const userAccessToken = _.get(user, "userInfo.accessToken", false);
  const createFiatWithdrawlRequestLoading = _.get(
    fiat,
    "createFiatWithdrawlRequestLoading",
    false
  );
  const transferbalanceortokenloading = _.get(
    wallet,
    "transferbalanceortokenloading",
    false
  );
  const fiatWalletBalance = _.get(fiat, "fiatBalance", []);
  const selectedBankAccounts = _.get(fiat, "selectedBankAccounts", []);
  const fiatWalletBalanceLoading = _.get(fiat, "callFiatBalanceLoading", false);
  const getAllBankAccountsLoading = _.get(
    fiat,
    "getAllBankAccountsLoading",
    false
  );
  const activeBankAccounts = _.get(fiat, "getAllBankAccountDetails", false);
  const createFiatWithdrawlError = _.get(
    fiat,
    "createFiatWithdrawlRequestError",
    ""
  );
  const selectedFiatCurrencyInfo = _.get(fiat, "selectedFiatCurrencyInfo", {});
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);

  return {
    exchangeId,
    transferbalanceortokenloading,
    userAccessToken,
    fiatWalletBalance,
    fiatWalletBalanceLoading,
    getAllBankAccountsLoading,
    activeBankAccounts,
    selectedBankAccounts,
    createFiatWithdrawlError,
    plaformcurrencies,
    createFiatWithdrawlRequestLoading,
    selectedFiatCurrencyInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  withdraw: (data) =>
    dispatch({ type: "transferBalanceOrTokenCalled", payload: data }),
  fiatWithdrawlStart: (data) => dispatch(createFiatWithDrawlStart(data)),
  fiatBalance: (data) =>
    dispatch({ type: "callFiatBalanceStartCalled", payload: data }),
  allBankAccounts: () => dispatch({ type: "getAllBankAccountsStart" }),
  clearFiatWithDrawErrorClearAsync: () =>
    dispatch(clearFiatWithDrawErrorClear()),
  setCurrentFiatInfoAsync: (data) => dispatch(setCurrentFiatInfo(data)),
  setDynamicFiatCurrencyAsync: (data) => dispatch(setDynamicFiatCurrency(data)),
  setBanksForSpecificFiatAsync: (data) =>
    dispatch(setBanksForSpecificFiat(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawFormFiat);
