import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import "@features/ido-screens/ido-new/IdoNewStyle.scss";
import IdoTable from "@components/ido-table/IdoTable";
import CyborTokenomics from "@components/cybor-tokenomics/CyborTokenomics";
import AboutCyborCard from "@components/about-cybor-coin-card/AboutCyborCard";
import BuyCyborCard from "@components/buy-cyborcoincard/BuyCyborCard";
import VideoCard from "@components/video-card/VideoCard";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "@components/footer-common/FooterCommon";
import ModernBgcard from "@components/modern-bg-card/ModernBgCard";
import { useTranslation } from "react-i18next";

function IdoScreens(props) {
  let {
    myaccountandbalance,
    getplatformTokensinfo,
    setIdobuyModal,
    idorounds,
    idotransaction,
  } = props || {};
  let selectedCoin =
    myaccountandbalance &&
    myaccountandbalance.filter((item) => item.tORcName == "CyborCoin")[0];
 

  let activeRounds =
    idorounds && idorounds.filter((item) => item.active == true)[0];

  const { t } = useTranslation();
  return (
    <ScreenLayout loaderOnRefresh={props.hideLoaderOnRefresh}>
      <>
        <div id="IdoNew">
          <ModernBgcard
            selectedCoin={selectedCoin}
            idoBalance={props.idoBalance}
            cryptoWallet={selectedCoin && selectedCoin.tORcName != "CyborCoin"}
            fromIdo={true}
            myaccountandbalance={myaccountandbalance}
            getplatformTokensinfo={getplatformTokensinfo}
          />
          <div className="mt-5">
            <IdoTable />
          </div>
          <div className="mt-5 hide-for-mobile">
            <CyborTokenomics />
          </div>
          <div className="mt-5">
            <Row>
              <Col lg={6} className="colspace">
                <AboutCyborCard />
              </Col>
              <Col lg={6} className="smallcardwrapper">
                <div className="innersmallcard colspace">
                  <BuyCyborCard
                    setIdobuyModal={(e) => setIdobuyModal(e)}
                    activeRounds={activeRounds}
                  />
                </div>
                <div className="innersmallcard colspace">
                  <VideoCard />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="mt-5">
          <FooterCommon />
        </div>
      </>
    </ScreenLayout>
  );
}

const mapStateToProps = ({ ido, wallet, app }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const transactionCount = _.get(ido, "transactionCount.count", 0);
  const Roundcount = _.get(ido, "Roundcount.count", 0);
  const idoBalance = _.get(ido, "idoBalance", 0);
  const idotransaction = _.get(ido, "idotransaction", []);
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const claimTransactionLoading = _.get(ido, "claimTransactionLoading", false);

  const idorounds = _.get(ido, "idorounds", []);
  return {
    getplatformTokensinfo,
    transactionCount,
    Roundcount,
    idotransaction,
    idoBalance,
    exchange,
    idoBalance,
    thememode,
    myaccountandbalance,
    idorounds,
    claimTransactionLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getidoList: (data) =>
    dispatch({ type: "getidoListbyaddresscalled", payload: data }),
  setIdobuyModal: (data) =>
    dispatch({ type: "setIdobuyModalCalled", payload: data }),
  getRountido: (data) => dispatch({ type: "getRountidocalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdoScreens);
