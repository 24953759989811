import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import CustomButton from "@components/custom-button/CustomButton";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import deposit from "@images/deposit.svg";
import withdraw from "@images/withdraw.svg";
import pending from "@images/pending.svg";
import ScreenLayout from "src/components/layout/ScreenLayout";
import { ReactComponent as CopyIcon } from "@images/copy.svg";
import { ReactComponent as SquareIcon } from "@images/tcheck.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import CoinList from "@components/coin-list/CoinList";
import { Row, Col, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import AdminFiatWithdrawPopup from "@components/admin-fiat-withdraw-popup/AdminFiatWithdrawPopup.js";
import { ToastContainer, toast } from "react-toastify";
import Filter from "@components/filter/Filter.js";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";
import { useTranslation } from "react-i18next";
import ApprovalPopup from "src/components/approval-popup/ApprovalPopup";
import RejectionPopup from "src/components/rejection-popup/RejectionPopup";
import "@features/admin-fiat-withdraw-request/AdminFiatWithdrawRequest.scss";
import { clearAdminRedux } from "@store/adminredux";

function AdminFiatWithdrawRequest(props) {
  const {
    usersFiatWithdrawRequest,
    usersFiatWithdrawRequestLoading,
    getFiatWithdrawRequest,
    setFiatWithdrawRequest,
    verifyUserWithdrawRequestLoading,
    withdrawRequestCount,
    setWithdrawRequestCount,
    clearAdmin,
    thememode,
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("third");
  let [show, setshow] = useState(false);
  let [Idata, setIdata] = useState({});
  let [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  let [selectedPaymentID, setSelectedPaymentID] = useState();
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [data, setData] = useState({});
  const [show1, setShowApproval] = useState(false);
  const [show2, setShowRejection] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [isFilterSet, setIsFilterSet] = useState(false);

  useEffect(() => {
    getFiatWithdrawRequest({
      limit,
      skip: 0,
    });
    setWithdrawRequestCount({ status: statusType });
    clearAdmin();
  }, []);

  const { t } = useTranslation();

  return (
    <div id="AdminFiatWithdrawRequest">
      <AdminFiatWithdrawPopup show1={show} setShowModal={setshow} data={data} />
      {/* <NavBar location={useLocation()} /> */}
      <ScreenLayout hideLoaderOnStart={true}>
        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("FiatWithdrawalRequest")} </span>
                  <div className="filtersection">
                    <Filter
                      onApplyDate={(picker) => {
                        setIsFilterSet(true);
                        getFiatWithdrawRequest({
                          skip: 0,
                          limit,
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          status: statusType,
                        });
                        setWithdrawRequestCount({
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          status: statusType,
                        });
                        setpages(0);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                      oncancel={() => {
                        setStartDate({});
                        setEndDate({});
                        setIsFilterSet(false);
                        getFiatWithdrawRequest({
                          limit,
                          skip: 0,
                          status: statusType,
                        });
                        setWithdrawRequestCount({ status: statusType });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="btmmargin">
                  <CurrencyTab
                    page={"businessrequest"}
                    changeTab={(e) => {
                      setStartDate({});
                      setEndDate({});
                      setIsFilterSet(false);
                      setpages(0);
                      settableno(e);
                      if (e == "second") {
                        getFiatWithdrawRequest({
                          status: "Cancelled",
                          limit,
                          skip: 0,
                        });
                        setStatusType("Cancelled");
                        setWithdrawRequestCount({ status: "Cancelled" });
                      }
                      if (e == "first") {
                        getFiatWithdrawRequest({
                          status: "Successfull",
                          limit,
                          skip: 0,
                        });
                        setStatusType("Successfull");
                        setWithdrawRequestCount({ status: "Successfull" });
                      }
                      if (e == "third") {
                        getFiatWithdrawRequest({ limit, skip: 0 });
                        setIsFilterSet("");
                        setWithdrawRequestCount();
                      }
                    }}
                    tableno={tableno}
                  />
                </div>
              </div>

              <Table
                responsive="sm"
                className={`transfertbl ${
                  thememode == "light" ? "tableinlight" : "tableindark"
                }`}
                borderless
              >
                {(usersFiatWithdrawRequestLoading ||
                  verifyUserWithdrawRequestLoading) && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading">
                      <span className="heading"> {t("TransactionID")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading"> {t("From")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading"> {t("To")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading"> {t("Amount")}</span>
                    </th>
                    <th className="trtbleheading">
                      <span className="heading"> {t("CreatedOn")}</span>
                    </th>
                    <th className="trtbleheading thwidth">
                      <span className="heading"> {t("Action")}</span>
                    </th>
                    <th className="trtbleheading"></th>
                  </tr>
                </thead>

                <tbody>
                  {usersFiatWithdrawRequest &&
                    usersFiatWithdrawRequest.map((item, index) => (
                      <tr key={`trans${index}`}>
                        <td className="amt">
                          <div className="innerTd idtr ">
                            <span className="id idtxt">{item.id}</span>
                            <p className="hidden-for-desktop date margintop">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </p>
                          </div>
                        </td>

                        <td className="amt">
                          <div className="innerTd">
                            <span className="link">F000000</span>
                            <span className="link hidden-for-desktop margintop">T000000</span>
                          </div>
                        </td>
                        <td className="amt">
                          <div className="innerTd hidden-for-mobile">
                            <div className="link">T000000</div>
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd">{item.amount}</div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd">
                            {moment(item.createdOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </td>
                        <td className="amt hidden-for-mobile">
                          <div className="innerTd">
                            <div className="btnwidth">
                              {item?.fiatTransactionStatus == "Successfull" ? (
                                <div>
                                  <span>{t("Approved")}</span>
                                </div>
                              ) : item?.fiatTransactionStatus == "Cancelled" ? (
                                <div>
                                  <span>{t("Rejected")}</span>
                                </div>
                              ) : (
                                <>
                                  <div className="approve">
                                    <CustomButton
                                      name={
                                        item?.fiatTransactionStatus ==
                                        "Successfull"
                                          ? t("Approved")
                                          : t("Approve")
                                      }
                                      filled={true}
                                      primary={true}
                                      disable={
                                        item?.fiatTransactionStatus ==
                                        "Successfull"
                                          ? true
                                          : false
                                      }
                                      classname={
                                        item?.fiatTransactionStatus ==
                                        "Successfull"
                                          ? "disabledBtn"
                                          : null
                                      }
                                      onClick={() => {
                                        setData(item);
                                        setShowApproval(true);
                                      }}
                                    />
                                  </div>

                                  <div className="reject">
                                    <CustomButton
                                      name={
                                        item?.fiatTransactionStatus ==
                                        "Cancelled"
                                          ? t("Rejcted")
                                          : t("Reject")
                                      }
                                      filled={true}
                                      primary={true}
                                      disable={
                                        item?.fiatTransactionStatus ==
                                        "Cancelled"
                                          ? true
                                          : false
                                      }
                                      classname={
                                        item?.fiatTransactionStatus ==
                                        "Cancelled"
                                          ? "disabledBtn"
                                          : null
                                      }
                                      onClick={() => {
                                        setData(item);
                                        setShowRejection(true);
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className="threedotspace">
                          <div className="vertical innerTd">
                            <Dropdown className="hidden-for-mobile">
                              <Dropdown.Toggle
                                className="threedot"
                                id="dropdown-basic"
                              >
                                <VerticleIcon className="arrowiconclass hidden-for-mobile" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="menu">
                                <Dropdown.Item
                                  onClick={() => {
                                    setData(item);
                                    setshow(true);
                                  }}
                                >
                                  {t("Details")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Replacementof3dots className=" arrow hidden-for-desktop"
                            onClick={() => {
                              setData(item);
                              setshow(true);
                            }} />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {withdrawRequestCount > limit && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(withdrawRequestCount / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={withdrawRequestCount / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={(e) => {
                        if (isFilterSet == true) {
                          getFiatWithdrawRequest({
                            startDate: startDate,
                            endDate: endDate,
                            skip: e.selected * limit,
                            limit,
                            status: statusType,
                          });
                        } else {
                          getFiatWithdrawRequest({
                            skip: e.selected * limit,
                            limit,
                            status: statusType,
                          });
                        }
                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}

              <ApprovalPopup
                onClick={() => {
                  setFiatWithdrawRequest({
                    id: data.id,
                    status: "Successfull",
                    note: "test",
                  });
                  setShowApproval(false);
                }}
                show1={show1}
                setShowModal={(e) => setShowApproval(e)}
              />
              <RejectionPopup
                onClick={() => {
                  setFiatWithdrawRequest({
                    id: data.id,
                    status: "Cancelled",
                    note: "test",
                  });
                  setShowRejection(false);
                }}
                show1={show2}
                setShowModal={(e) => setShowRejection(e)}
              />
            </Col>
          </Row>
        </Container>
      </ScreenLayout>
    </div>
  );
}
const mapStateToProps = ({ admin = {}, app = {} }) => {
  const usersFiatWithdrawRequest = _.get(admin, "usersFiatWithdrawRequest", []);
  const usersFiatWithdrawRequestLoading = _.get(
    admin,
    "usersFiatWithdrawRequestLoading",
    false
  );
  const verifyUserWithdrawRequestLoading = _.get(
    admin,
    "verifyUserWithdrawRequestLoading",
    false
  );
  const withdrawRequestCount = _.get(admin, "withdrawRequestCount.count", 0);
  const thememode = _.get(app, "thememode", false);
  return {
    usersFiatWithdrawRequest,
    usersFiatWithdrawRequestLoading,
    verifyUserWithdrawRequestLoading,
    withdrawRequestCount,
    thememode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFiatWithdrawRequest: (data) =>
    dispatch({ type: "usersFiatWithdrawRequest", payload: data }),
  setFiatWithdrawRequest: (data) =>
    dispatch({ type: "VerifyUserWithdrawRequest", payload: data }),
  setWithdrawRequestCount: (data) =>
    dispatch({ type: "withdrawRequestCount", payload: data }),
  clearAdmin: () => dispatch(clearAdminRedux()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminFiatWithdrawRequest);
