import copy from "@images/copy1.svg";
import amount from "@images/amount1.svg";
import share from "@images/share1.svg";
import SharePopup from "@components/share-popup/SharePopup.js";
import "@components/qr-code/QrCodeStyles.scss";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCSymbol, getESymbol } from "./../../utils/Currencies";
import { ToastContainer, toast } from "react-toastify";
function QrCode({
  thememode,
  settings,
  coinItem,
  getAdd,
  setDepositModal,
  setsharem,
  setamountm,
  setIdo,
  getExchangerate,
  exchangeRates,
  PreferredCurrency
}) {
  const [copied, setcopied] = useState(false);
  const [address, setaddress] = useState(false);

  useEffect(() => {
    setcopied(false);
    let addres = getAdd(coinItem && coinItem.id);
    setaddress(addres);
    getExchangerate({
      symbol1: coinItem && coinItem.exchangeSymbol,
      symbol2: getESymbol(PreferredCurrency),
    });
  }, [coinItem && coinItem.id]);
  const { t } = useTranslation();
  // let [show, setshow] = useState(false);
  return (
    <div className="qrcode">
      <div className="qrcard">
        <div className="qrname">
          {t("ReceiveLeft")} {coinItem && coinItem.tokenName} {t("ReceiveRight")}
        </div>
        <div className="qrbox applyingshadow">
          <div>
            <QRCode
              className=""
              size="180"
              value={address}
              bgColor={
                thememode == "light"
                  ? settings.lightTheme.bgwhite
                  : settings.darkTheme.bgwhite
              }
              fgColor={
                thememode == "light"
                  ? settings.lightTheme.textdarker
                  : settings.darkTheme.textdarker
              }
            />
          </div>
          <div>
            {coinItem && coinItem.depositStatus === "Disable" && (
              <span className="deactivationMessage">
                Deposit is deactivated for this token
              </span>
            )}
          </div>
          <div className="qrspace">
            <span className="qrid">{address}</span>
          </div>
        </div>
        {/* {address}       */}
        <div className="qramountspace">
          <span className="qramount">
            {" "}
            1 {coinItem && coinItem.exchangeSymbol} = {getCSymbol(PreferredCurrency)}{" "}
            {Number(exchangeRates && exchangeRates.result).toFixed(2)}{" "}
          </span>
        </div>
        <div className="qrcontentspace">
          {/* <span className="qramountcontent">{t("OnlyCyborCoin")} </span> */}
          <span className="qramountcontent"> 
          {localStorage.getItem("lang") == 'tr' ? 
          `Bu adrese sadece ${coinItem && coinItem.tokenName} gönderin. Başka herhangi bir kripto para gönderimi, geri dönüşü olmayan kayıplara neden olabilir.` : 
          localStorage.getItem("lang") == 'ru' ? 
          `Отправляйте на этот адрес только ${coinItem && coinItem.tokenName} Отправка любых других монет может привести к их безвозвратной потере.` :
          `Send only ${coinItem && coinItem.tokenName} to this address. Sending any other coins may result in their irrecoverable loss.`
           } 
          </span>
        </div>
        <div className="options">
          <div className="optiontxtholder1">
            <img
              onClick={() => {
                setcopied(true);
                navigator.clipboard.writeText(address);
                toast.success(t(`${"copied"}`), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }}
              src={copy}
              className="comtxt"
            />
            <div
              className="comtxt1"
              onClick={() => {
                setcopied(true);
                navigator.clipboard.writeText(address);
                toast.success(t(`${"copied"}`), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }}
            >
              {copied ? t(`${"Copied"}`) : t(`${"Copy"}`)}
            </div>
          </div>
          <div className="optiontxtholder1">
            <img
              src={amount}
              className="comtxt"
              onClick={() => {
                setDepositModal(false);

                setIdo(true);
              }}
            />
            <div className="comtxt1">{t("Amount")}</div>
          </div>

          <div className="optiontxtholder1">
            <img
              src={share}
              className="comtxt"
              onClick={() => {
                setDepositModal(false);
                setsharem(true);
                // setshow(true);
              }}
            />
            <div className="comtxt1">{t("Share")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ app = {}, wallet = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const loadingRate = _.get(wallet, "loadingRate", []);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");

  return { thememode, settings, loadingRate, exchangeRates, PreferredCurrency };
};

const mapDispatchToProps = (dispatch) => ({
  setDepositModal: (data) =>
    dispatch({ type: "DepositModalCalled", payload: data }),
  getExchangerate: (data) =>
    dispatch({ type: "getExchangeratecalled", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(QrCode);
