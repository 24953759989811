export default {
  BankRequest: "Banka Talebi",
  ID: "ID",
  UserName: "Hesap Sahibinin Adı",
  BankName: "Banka Adı",
  BankAccountNumber: "Banka hesap numarası",
  Approve: "Onayla",
  Reject: "Reddet",
  Approve: "Onaylandı",
  Reject: "Reddedildi",
  BusinessRequest: "İşletme talebi",
  BusinessID: "İşletme bilgileri",
  CompanyName: "Şirket adı",
  CompanyAddress: "Şirket adresi",
  Region: "Bölge",
  FiatDepositRequest: "Fiat yatırım talebi",
  AdminBanks: "Yönetici Bankaları",
  CreatedOn: "Oluşturma tarihi",
  FiatWithdrawalRequest: "Fiat çekim talebi",
  BankRequestDetails: "Banka talep detayları",
  BankAccountType: "Banka hesap türü",
  UserEmail: "Kullanıcı e-mail",
  UserContactNumber: "Kullanıcı iletişim numarası",
  BusinessRequestDetails: "İşletme talebi ayrıntıları",
  CompanyEmail: "Şirket E-mail",
  ContactNumber: "İletişim numarası",
  Language: "Dil",
  Document_Link: "Belge Bağlantısı",
  Click_to_download: "İndirmek için tıklayın",
  BusinessApproval: "İşletme onayı",
  FiatDepositDetails: "Fiat yatırım detayları",
  TransactionType: "İşlem türü",
  CurrencyName: "Para birimi adı",
  BankIFSCNumber: "Banka IFSC numarası",
  BankIBANNumber: "Banka IBAN Numarası",
  ListOfBanks: "bankaların listesi",
  FiatWithdrawDetails: "Fiat Çekim Detayları",
  withdraw: "Çekil",
  deposit: "Yatırım",
  SAVINGS: "KAYITLAR",
  active: "Aktif",
  UsersList: "Kullanıcı Listesi",
  UserID: "Kullanıcı kimliği",
  disabled: "engelli",
  Active: "Aktif",
  Activate: "etkinleştir",
  Disabled: "Engelli",
  Disable: "Devre dışı bırakmak",
  Doyouwanttoactivatethisuser: "Bu kullanıcıyı etkinleştirmek istiyor musunuz?",
  Doyouwanttodisablethisuser:
    "Bu kullanıcıyı devre dışı bırakmak istiyor musunuz?",
  UserDetails: "Kullanıcı detayları",
  SubName: "Alt Ad",
  Role: "Relay",
  DoyouwanttoReject: "Reddetmek istiyor musunuz?",
  Activated: "Aktif",
  Successfull: "Başarılı",
  Bank: "Banka",
  Currency: "Para birimi",
  IBAN: "IBAN",
  AccountHolder: "Hesap sahibi",
  Edit: "Düzenlemek",
  AddBankAccount: "Banka Hesabı Ekle",
  FilterCurrency: "Filtre Para Birimi",
  Logoforlighttheme: "Işık teması için logo",
  Logofordarktheme: "Karanlık tema için logo",
  Pleaseenterbanksname: "Lütfen bankanın adını girin",
  ErrorDarkLogo: "Lütfen koyu tema için bir logo seçin",
  ErrorLightLogo: "Lütfen hafif tema için bir logo seçin",
  EditBankAccount: "Banka Hesabını Düzenle",
  Doyouwanttodeletethisbank: "Bu bankayı silmek istiyor musunuz?",
  Doyouwanttodeletethisbankaccount: "Bu banka hesabını silmek istiyor musunuz?",
  Owner: "Mal sahibi",
  User: "kullanıcı",
};
