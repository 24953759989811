import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import "@features/bity/bityscreenStyle.scss";
import MainDashboard from "@components/main-dashboard/MainDashboard";
import ScreenLayout from "src/components/layout/ScreenLayout";
import Loader from "src/components/loader/Loader";
import FooterCommon from "@components/footer-common/FooterCommon";
import ExchangePopup from "@components/exchange-popup/ExchangePopup";

function BityScreen(props) {
  const {
    getEstimate,
    getPlatformCurrencys,
    getBpairs,
    getPlatformTokens,
    hideLoaderOnRefresh,
    currencies,
    pairs,
    estimate,
  } = props || {};
  const [displayedCurrency, setdisplayedCurrency] = useState(["ETH", "Cybor"]);
  const [coinItem, setcoinItem] = useState({});

  useEffect(() => {
    getPlatformCurrencys();
    getBpairs();
  }, []);
  const callbity = (code, amt) => {
    console.log(code, amt);
  };
  return (
    <ScreenLayout
      loaderOnRefresh={hideLoaderOnRefresh}
      hideLoaderOnStart={props?.location?.state?.stopLoading}
    >
      <div id="bityscreen">
        <span className="headtext">{"Bity Crypto Exchange "}</span>
        <Container className="p-4">
          <ExchangePopup
            coinItem={{ name: "" }}
            bity
            estimate={estimate}
            getEstimate={getEstimate}
            pairs={pairs}
            option={currencies}
            callbity={callbity}
          />
        </Container>
        <div className="mt-5">
          <FooterCommon />
        </div>
      </div>
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {}, bity = {} }) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const currencies = _.get(bity, "currencies", [
    { code: "BTC", max_digits_in_decimal_part: 8, tags: ["crypto"] },
    { code: "CHF", max_digits_in_decimal_part: 2, tags: ["fiat"] },
    {
      code: "ETH",
      max_digits_in_decimal_part: 18,
      tags: ["crypto", "ethereum"],
    },
    { code: "EUR", max_digits_in_decimal_part: 2, tags: ["fiat"] },
    { code: "ICP", max_digits_in_decimal_part: 8, tags: ["crypto"] },
    { code: "LTC", max_digits_in_decimal_part: 8, tags: ["crypto"] },
    { code: "NYM", max_digits_in_decimal_part: 6, tags: ["crypto"] },
    {
      code: "USDC",
      max_digits_in_decimal_part: 6,
      tags: ["crypto", "ethereum", "erc20"],
    },
    {
      code: "USDT",
      max_digits_in_decimal_part: 6,
      tags: ["crypto", "ethereum", "erc20"],
    },
  ]);
  const pairs = _.get(bity, "pairs", [
    { input: "BTC", output: "CHF", enabled: true },
    { input: "BTC", output: "ETH", enabled: true },
    { input: "BTC", output: "EUR", enabled: true },
    { input: "BTC", output: "ICP", enabled: true },
    { input: "BTC", output: "LTC", enabled: true },
    { input: "BTC", output: "NYM", enabled: true },
    { input: "BTC", output: "USDC", enabled: true },
    { input: "BTC", output: "USDT", enabled: true },
    { input: "CHF", output: "BTC", enabled: true },
    { input: "CHF", output: "ETH", enabled: true },
    { input: "CHF", output: "ICP", enabled: true },
    { input: "CHF", output: "LTC", enabled: true },
    { input: "CHF", output: "NYM", enabled: true },
    { input: "CHF", output: "USDC", enabled: true },
    { input: "CHF", output: "USDT", enabled: true },
    { input: "ETH", output: "BTC", enabled: true },
    { input: "ETH", output: "CHF", enabled: true },
    { input: "ETH", output: "EUR", enabled: true },
    { input: "ETH", output: "ICP", enabled: true },
    { input: "ETH", output: "LTC", enabled: true },
    { input: "ETH", output: "USDC", enabled: true },
    { input: "ETH", output: "USDT", enabled: true },
    { input: "EUR", output: "BTC", enabled: true },
    { input: "EUR", output: "ETH", enabled: true },
    { input: "EUR", output: "ICP", enabled: true },
    { input: "EUR", output: "LTC", enabled: true },
    { input: "EUR", output: "NYM", enabled: true },
    { input: "EUR", output: "USDC", enabled: true },
    { input: "EUR", output: "USDT", enabled: true },
    { input: "LTC", output: "BTC", enabled: true },
    { input: "LTC", output: "CHF", enabled: true },
    { input: "LTC", output: "ETH", enabled: true },
    { input: "LTC", output: "EUR", enabled: true },
    { input: "LTC", output: "USDT", enabled: true },
  ]);
  let estimate = _.get(bity, "estimate", {});
  return { mywalletsinfo, currencies, pairs, estimate };
};

const mapDispatchToProps = (dispatch) => ({
  getEstimate: (data) => dispatch({ type: "getEstimate", payload: data }),
  getPlatformTokens: (data) =>
    dispatch({ type: "getPlatformTokensCalled", payload: data }),

  getPlatformCurrencys: (data) =>
    dispatch({ type: "getBcurrency", payload: data }),
  getBpairs: (data) => dispatch({ type: "getBpairs", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BityScreen);
