export default {
  Payment: "Payment",
  Merchant: "Merchant",
  Payer: "Payer",
  AmountToPay: "Amount to pay",
  InsufficientBalance: "Insufficient Balance",
  PaymentSuccessfull: "Payment has been done Successfully!",
  PaymentLinkNotValid: "Payment link is no longer valid",
  PaymentLinkDetails: "Payment Link Details",
  Close: "Close",
  Total: "Total",
  Pending: "Pending",
  Paid: "Paid",
  Cancelled: "Cancelled",
};
