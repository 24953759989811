import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import moment from 'moment'
import { ReactComponent as FilterIcon } from "@images/filter.svg";
import {ReactComponent as DropdownIcon} from '@images/dropdown.svg'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "@components/fiatwallet-datedropdown/FiatwalletDatedropdown.scss";

const FiatwalletDatedropdown = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onDateApply,
  formobile
}) => {

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}

    </span>
  ));
   
  return (
    <div className="filterfiat">
      <Dropdown align="end" autoClose="inside">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
          <>
          <span className="filtericon ms-2">
            <FilterIcon className="calendericon" />
            {
              formobile && 
              <DropdownIcon className="ms-2 drpicon" />
            }
          </span>
          {/* <span className="filtertext">4 Sept 2021 - 17 Sept 2021</span> */}
          <span className="filtertext">{moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}</span>
          </>
     
        </Dropdown.Toggle>
      </Dropdown>
      <DateRangePicker
        initialSettings={{ startDate: startDate, endDate: endDate }}  //month-day-year
        onApply={(event,picker)=>{
          picker.startDate && setStartDate(picker.startDate);
          picker.endDate && setEndDate(picker.endDate);
          onDateApply(picker)
        }}
      >
      <div className="fordaterange"></div>
      </DateRangePicker>
    </div>
  );
};

export default FiatwalletDatedropdown ;
