import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import withdrawwallet from "@images/withdrawwallet.svg";
import { connect } from "react-redux";
import CustomButton from "@components/custom-button/CustomButton";
import QrcryptoWallet from "@components/qr-cryptowallet/QrcryptoWallet";
import { ToastContainer, toast } from "react-toastify";
import QRCode from "react-qr-code";
import { ReactComponent as CopyIcon } from "@images/copy.svg";
import DollarInspect from "@images/DollarInspect.svg";
import "@components/wallet-qr/WalletQrStyle.scss";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SharePopup from "../share-popup/SharePopup";
import IdoBuyPopup from "@components/ido-buy-popup/IdoBuyPopup";
import AmountPoupup from "../amount-popup/AmountPoupup";

const WalletQr = ({
  selectedCoin,
  thememode,
  settings,
  getplatformTokensinfo,
  myaccountandbalance,
}) => {
  let [show, setshow] = useState(false);
  let [copied, setcopied] = useState(false);
  let [amountModal, setamountModal] = useState(false);

  useEffect(() => {
    setcopied(false);
  }, [selectedCoin]);
  let [showsharem, setshowsharem] = useState(false);
  const [address, setaddress] = useState("");
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="WalletQr">
      <AmountPoupup
        show1={amountModal}
        setShowModal1={(e) => setamountModal(e)}
        getplatformTokensinfo={getplatformTokensinfo}
        myaccountandbalance={myaccountandbalance}
        selectedCoin={selectedCoin}
       />
      <SharePopup
        coinItem={selectedCoin}
        setShowModal={setshowsharem}
        show2={showsharem}
        setdepositModal={setshow}
      />
      <QrcryptoWallet
        setShowModal={setshow}
        show1={show}
        setamountModal={setamountModal}
        setsharem={setshowsharem}
        setdepositModal={setshow}
        address={selectedCoin && selectedCoin.address}
        tokenName={
          selectedCoin && selectedCoin.coin && selectedCoin.coin.tokenName
        }
      />
      <div className="outermost">
        <div className="iconwithtextwallet">
          {/* <div className="iconwallet">
            <img src={withdrawwallet} className="graphwallet" />
          </div> */}
          <div className="textwallet">
            {t("T0")} {t("Receive")}{" "}
            {selectedCoin && selectedCoin.coin && selectedCoin.coin.tokenName}{" "}
            {t("CopyWalletNumber")}:
          </div>
        </div>

        <div className="imgright d-inline-block d-lg-none">
          <div
            className="imagesection"
            onClick={() => {
              setshow(true);
            }}
          >
            <QRCode
              size={185}
              value={address}
              className="qrwallet"
              bgColor={
                thememode == "light"
                  ? settings.lightTheme.bgwhite
                  : settings.darkTheme.bgwhite
              }
              fgColor={
                thememode == "light"
                  ? settings.lightTheme.textdarker
                  : settings.darkTheme.textdarker
              }
            ></QRCode>
            <div className="borderimg borderimg-1"></div>
            <div className="borderimg borderimg-2"></div>
            <div className="borderimg borderimg-3"></div>
            <div className="borderimg borderimg-4"></div>
            <div className="zoomiconwrapper">
              <img src={DollarInspect} alt="" className="zoomicon" />
            </div>
          </div>
        </div>

        <div className="outerclass">
          <div className="box">
            <div className="valuefield">
              <span className="address">
                {" "}
                {selectedCoin && selectedCoin.address}
              </span>
            </div>
          </div>
          <div className="coin">
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id="button-tooltip">{t("CopyWalletNumber")}</Tooltip>}
            >
              <CopyIcon
                onClick={() => {
                  setcopied(true);
                  navigator.clipboard.writeText(
                    selectedCoin && selectedCoin.address
                  );
                  toast.success(t(`${"copied"}`), {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }}
                className="iconCopy"
              />
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="imgright d-none d-lg-inline-block">
        <div
          className="imagesection"
          onClick={() => {
            setshow(true);
          }}
        >
          <QRCode
            size="97"
            value={address}
            className="qrwallet"
            bgColor={
              thememode == "light"
                ? settings.lightTheme.bgwhite
                : settings.darkTheme.bgwhite
            }
            fgColor={
              thememode == "light"
                ? settings.lightTheme.textdarker
                : settings.darkTheme.textdarker
            }
          ></QRCode>
          <div className="borderimg borderimg-1"></div>
          <div className="borderimg borderimg-2"></div>
          <div className="borderimg borderimg-3"></div>
          <div className="borderimg borderimg-4"></div>
          <div className="zoomiconwrapper">
            <img src={DollarInspect} alt="" className="zoomicon" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app = {}, wallet = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  return { thememode, settings, getplatformTokensinfo, myaccountandbalance };
};

export default connect(mapStateToProps, null)(WalletQr);
