export default {
  PaymentReport: "Payment Report",
  PaymentID: "Payment ID",
  InvalidPaymentID: "Invalid Payment ID",
  Date: "Date",
  InvalidDate: "Invalid Date",
  Merchant: "Merchant",
  InvalidMerchant: "Invalid Merchant",
  CoinToAccept: "Coin to Accept",
  InvalidCoin: "Invalid Coin to Accept",
  Amount: "Amount",
  InvalidAmount: "Invalid Amount",
  CoinToConvert: "Coin to Convert",
  InvalidCoinToConvert: "Invalid Coin to Convert",
  Status: "Status",
  InvalidSatus: "Invalid Status",
  CreateReport: "Create Report",
  Dealdate: "Deal date",
  CreateBranch: "Create Branch",
  TransactionID: "Transaction ID",
  AmountTransaction: "Amount Transaction",
  Сurrency: "Сurrency",
  Convertedamount: "Converted amount",
  Сurrencyinformation: "Сurrency information",
  Commission: "Commission",
  Recipient: "Recipient",
};
