export default {
  BankRequest: "Запрос на добавление банка",
  ID: "ID",
  UserName: "Имя владельца счета",
  BankName: "Название банка",
  BankAccountNumber: "Номер банковского счета",
  Approve: "Подтвердить",
  Reject: "Отменить",
  Approved: "Подтверждено",
  Rejected: "Отменено",
  BusinessRequest: "Бизнес запросы",
  BusinessID: "Бизнес ID",
  CompanyName: "Название компании",
  CompanyAddress: "Адрес компании",
  Region: "Регион",
  FiatDepositRequest: "Запрос на ввод Фиата",
  AdminBanks: "Банки администратора",
  CreatedOn: "Создано",
  FiatWithdrawalRequest: "Запрос на вывод Фиата",
  BankRequestDetails: "Детали запроса на добавление Банка",
  BankAccountType: "Тип банковского аккаунта",
  UserEmail: "E-mail пользователя",
  UserContactNumber: "Контактный номер пользователя",
  BusinessRequestDetails: "Детали запроса на создание бизнес аккаунта",
  CompanyEmail: "E-mail Компании",
  ContactNumber: "Контактный номер",
  Language: "Язык",
  Document_Link: "Ссылка на документ",
  Click_to_download: "Нажмите, чтобы загрузить",
  BusinessApproval: "Статус",
  FiatDepositDetails: "Детали ввода фиата",
  TransactionType: "Тип транзакции",
  CurrencyName: "Назваиние валюты",
  BankIFSCNumber: "Банковский номер IFSC",
  BankIBANNumber: "Номер IBAN банка",
  ListOfBanks: "Список банков",
  FiatWithdrawDetails: "Детали вывода Фиата",
  withdraw: "изымать",
  deposit: "ввод",
  SAVINGS: "СОХРАНЕНИЯ",
  active: "Активный",
  UsersList: "Список пользователей",
  UserID: "ID пользователя",
  disabled: "инвалид",
  Active: "Активный",
  Activate: "Активировать",
  Disabled: "Неполноценный",
  Disable: "Запрещать",
  Doyouwanttoactivatethisuser: "Вы хотите активировать этого пользователя?",
  Doyouwanttodisablethisuser: "Вы хотите отключить этого пользователя?",
  UserDetails: "Сведения о пользователе",
  SubName: "Дополнительное имя",
  Role: "Роль",
  DoyouwanttoReject: "Вы хотите Отклонить?",
  Activated: "Активировано",
  Successfull: "Успешный",
  Bank: "банк",
  Currency: "Валюта",
  IBAN: "IBAN",
  AccountHolder: "Владелец счета",
  Edit: "Редактировать",
  AddBankAccount: "Добавить банковский счет",
  FilterCurrency: "Валюта фильтра",
  Logoforlighttheme: "Логотип для светлой темы",
  Logofordarktheme: "Логотип для темной темы",
  Pleaseenterbanksname: "Пожалуйста, введите название банка",
  ErrorDarkLogo: "Пожалуйста, выберите логотип для темной темы",
  ErrorLightLogo: "Пожалуйста, выберите логотип для светлой темы",
  EditBankAccount: "Редактировать банковский счет",
  Doyouwanttodeletethisbank: "Удалить этот банк?",
  Doyouwanttodeletethisbankaccount: "Вы хотите удалить этот банковский счет?",
  Owner: "Владелец",
  User: "Пользователь",
};
