export default {
  Dashboard: "Dashboard",
  MyBalances: "My Balances",
  MyWallets: "My Wallets",
  MyWallet: "My Wallet",
  CryptoWallet: "Crypto Wallet",
  FiatWallet: "Fiat Wallet",
  Transactions: "Transactions",
  TransferHistory: " Transfer History",
  ExchangeHistory: "Exchange History",
  Deposit: " Deposit",
  Withdrawals: "Withdrawals",
  IDOCyborCoin: "IDO PayCrypt",
  CyborProtectionSystem: "PayCrypt Protection System",
  MarketRates: "Market Rates",
  PersonalSettings: "Settings",
  Reports: "Reports",
  PaymentReport: "Payment Report",
  Business: "Business",
  BranchManagement: "Branch Management",
  PaymentHistory: "Payment History",
  AdminIDOPanel: "Admin IDO Panel",
  IDODashboard: "IDO Dashboard",
  LockedCyborCoin: "Locked PayCrypt Coin",
  AddBankRequest: "Add Bank Requests",
  FiatWithdrawRequest: "Fiat Withdraw Requests",
  CompletedTransaction: "Completed Transactions",
  PendingTransaction: "Pending Transactions",
  CancelledTransaction: "Cancelled Transactions",
  Oursupportworks: "Our support works for you",
  DefiLoans: "Crypto Loan",
  BankLoan: "Bank Loan",
  Cryptocards: "Crypto Cards",
  Buysellcrypto: "Buy/Sell Crypto",
  Loanrequests: "Loan Requests",
  Borrow: "Borrow",
};
