import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { connect } from "react-redux";
import { Container, Modal, Row, Col } from "react-bootstrap";
import "@components/branch-successful/BranchsuccessfulStyles.scss";
import { useTranslation } from "react-i18next";

const Branchsuccessful = ({
  showDeleteConfirmation,
  setShowDeleteConfirmation,
}) => {
  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="branchsuccess"
      show={showDeleteConfirmation}
      onHide={() => setShowDeleteConfirmation(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => setShowDeleteConfirmation(false)}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="deletetext">
                {t("BranchCreatedSuccessfully")} !
                {/* <span>{t("BranchWillBeAdded")}</span> */}
              </div>
            </Col>
            <Col lg={12}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name={t("Okay")}
                  filled={true}
                  primary={true}
                  onClick={() => setShowDeleteConfirmation(false)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ branchmanagementredux = {} }) => {
  const allBranchesLoading = _.get(
    branchmanagementredux,
    "listAllBranchesLoading",
    false
  );
  return {
    allBranchesLoading,
  };
};

export default connect(mapStateToProps)(Branchsuccessful);
