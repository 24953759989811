import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import { connect } from "react-redux";
import { Row, Col, Dropdown } from "react-bootstrap";
import WalletCoinCard from "@components/wallet-coin-card/WalletCoinCard";
import "@features/business-cypto-wallet/BusinessCryptoWallet.scss";
import { useTranslation } from "react-i18next";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as DownIcon } from "@images/down.svg";
import { ReactComponent as ExchangeIcon } from "@images/exchange.svg";
import { ReactComponent as Search } from "@images/search.svg";
import { ReactComponent as Eye } from "@images/eye.svg";
import { ReactComponent as HideEye } from "@images/hideeye2.svg";
import BigNumber from "bignumber.js";
import Withdrawal from "@components/withdrawal/Withdrawal";
import ExchangeModal from "@components/exchange-modal/ExchangeModal";
import DepositModal from "@components/deposit-modal/DepositModal";
import WithdrawalFiat from "@components/withdrawal-fiat/WithdrawalFiat";
import DepositFiatModal from "@components/depositfiat-modal/DepositFiatModal";
import SmallButton from "@components/small-button/SmallButton";
import ScreenLayout from "@components/layout/ScreenLayout";
import FooterCommon from "@components/footer-common/FooterCommon";
// import eye from "@images/eye.svg";

function CryptoWallet({
  myaccountandbalance,
  exchangemodal,
  setExchangeModal,
  setDepositModal,
  withdramodal,
  depositModal,
  getplatformTokensinfo,
  setwithdrawModal,
  platformBanks,
  hideLoaderOnRefresh
}) {
  const estimatedCurrency = [
    {
      eventKey: 1,
      name: "USD",
    },
    {
      eventKey: 2,
      name: "EURO",
    },
  ];
  const [activekey, setActivekey] = useState(
    myaccountandbalance && myaccountandbalance[0]?.id
  );
  const [activeCardEye, setctiveCardEye] = useState({ id: "", state: false });

  const [deposit, setdeposit] = useState(false);
  const [fiatdeposit, setfiatdeposit] = useState(false);
  const [withdraw, setwithdraw] = useState(false);
  const [fiatwithdraw, setfiatwithdraw] = useState(false);
  const [exchange, setexchange] = useState(false);

  const [coinItem, setcoinItem] = useState(
    myaccountandbalance && myaccountandbalance[0] && myaccountandbalance[0].coin
  );
  const [activeEstCur, setActiveEstCur] = useState({
    eventKey: 1,
    name: "USD",
  });
  const [currencyFilter, setCurrencyFilter] = useState("");
  const [isEyeActive, setIsEyeActive] = useState(false);
  const [myWalletCurr, setMyWalletCur] = useState(myaccountandbalance);
  const [isCoinInfo, setIsCoinInfo] = useState(false);
  const [value, setvalue] = useState("");
  let [tableNo, setTableno] = useState("first");
  const [array, setArray] = useState([]);

  // useEffect(() => {
  //   console.log('card eye', activeCardEye)
  // }, [activeCardEye]);

  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };
  const getLockBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].lockBalance", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", 0);

    return address;
  };

  const filtercoins = () => {
    let temp = myaccountandbalance;
    if (currencyFilter != "" && currencyFilter != null) {
      temp = temp.filter(
        (data) =>
          data.tORcName.toLowerCase().includes(currencyFilter.toLowerCase()) ||
          data.coin.exchangeSymbol
            .toLowerCase()
            .includes(currencyFilter.toLowerCase())
      );
    }
    if (isEyeActive == true) {
      temp = temp.filter((data) => data.amount != "0");
    }

    setMyWalletCur(temp);
  };
  const resetfilters = () => {
    setActiveEstCur({ eventKey: 1, name: "USD" });
    setCurrencyFilter("");
    setIsEyeActive(false);
    setMyWalletCur(myaccountandbalance);
  };

   useEffect(() => {
    let arr = [];
    myWalletCurr.forEach((itm) => {
      arr.push({ id: itm.id, state: false });
    });
    setArray(arr);
   }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      href=""
      ref={ref}
      className="dropdownwrapper"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}

      <span className="toogleicon">
        {/* <DropdownarrowIcon className="dropdownarrow" /> */}
        &#x25bc;
      </span>
    </div>
  ));

  const { t, i18n, ready } = useTranslation();

  return (
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh} >
      <>
        <Container>
          <div id="cryptowalletbusiness">
            <div className="trouter trasactiontitle">
              <div className="mybalancesheadwrapper">
                <div className="title">
                  {t("My")} {t("Wallet")}
                </div>
                <div className="bcButtonwrapper d-none d-lg-flex">
                  <SmallButton
                    type="deposit"
                    onclick={() => setdeposit(true)}
                  />
                  <SmallButton
                    type="withdraw"
                    onclick={() => setwithdraw(true)}
                  />
                  <SmallButton
                    type="exchange"
                    onclick={() => setexchange(true)}
                  />
                </div>
              </div>
            </div>
            <div className="contentwrapper">
              <div className="filterwrapper">
                <Row className="filterwrapperrow">
                  <Col xs={6} sm={6} md={6} lg={4}>
                    <div>
                      <p className="filtersectionheading">{t("Currency")}</p>
                      <div className="searchinputwrapper">
                        <input
                          type="text"
                          placeholder={t("AllType")}
                          value={currencyFilter}
                          onChange={(e) => setCurrencyFilter(e.target.value)}
                        />
                        <Search className="searchicon" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={4}>
                    <div>
                      <p className="filtersectionheading">
                        {t("EstimateBalance")}
                      </p>
                      <Dropdown
                        onSelect={(eventKey, event) => {
                          estimatedCurrency.map((data) => {
                            if (data.eventKey == eventKey) {
                              setActiveEstCur(data);
                            }
                          });
                        }}
                      >
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                          {activeEstCur.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {estimatedCurrency.map((data) => {
                            return (
                              <Dropdown.Item
                                active={
                                  activeEstCur.eventKey == data.eventKey
                                    ? true
                                    : false
                                }
                                eventKey={data.eventKey}
                              >
                                {data.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    className="d-none d-lg-block"
                  >
                    <div>
                      <p className="filtersectionheading opacity-0">.</p>
                      <div
                        className={`hidetextwrapper ${isEyeActive && "active"}`}
                        onClick={() => setIsEyeActive(!isEyeActive)}
                      >
                        {(isEyeActive && <HideEye className="eyeIcon" />) || (
                          <Eye className="eyeIcon2" />
                        )}
                        <span className={`hideText`}>
                          {t("HideZeroBalance")}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="buttonWrapper">
                  <div className="topbuttons">
                    <CustomButton
                      type="submit"
                      name={t("Filter")}
                      filled={true}
                      primary={true}
                      onClick={filtercoins}
                    />
                  </div>
                  <div className="topbuttons">
                    <CustomButton
                      type="submit"
                      name={t("Reset")}
                      filled={true}
                      onClick={resetfilters}
                      // primary={true}
                    />
                  </div>
                </div>
                <div className="d-lg-none d-block">
                  <p className="filtersectionheading opacity-0">.</p>
                  <div
                    className={`hidetextwrapper ${isEyeActive && "active"}`}
                    onClick={() => setIsEyeActive(!isEyeActive)}
                  >
                    {(isEyeActive && <HideEye className="eyeIcon" />) || (
                      <Eye className="eyeIcon2" />
                    )}
                    <span className={`hideText`}>{t("HideZeroBalance")}</span>
                  </div>
                </div>
              </div>
              {/* cards */}
              <div className="walletcoinswrap mt-2">
                <Row>
                  {myWalletCurr &&
                    myWalletCurr.map((coins, index) => {
                      if (isEyeActive){
                         if(coins.amount!=0)
                        return (
                          <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                            <WalletCoinCard
                              setEye={(e) => {
                                setIsEyeActive(e);
                              }}
                              eye={isEyeActive}
                              coin={coins.coin}
                              cardArray={array}
                              setCardArray={setArray}
                              cardEye={activeCardEye}
                              setCardEye={setctiveCardEye}
                              logo={coins.coin.nonColouredLogo}
                              name={coins.tORcName}
                              type={coins.coin.type}
                              balance={coins.amount}
                              decimals={coins.coin.decimals}
                              symbol={coins.coin.exchangeSymbol}
                              bgcolor={coins.coin.logoBgColor}
                              coinkey={coins.id}
                              activekey={activekey}
                              tag={coins.coin.type}
                              setcoinItem={setcoinItem}
                              setDepositModal={(e) => setdeposit(e)}
                              setShowWithdraw={(e) => setwithdraw(e)}
                              setExchangeModal={(e) => setexchange(e)}
                            />
                          </Col>
                        );
                      }else
                        return (
                          <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                            <WalletCoinCard
                              setEye={(e) => {
                                setIsEyeActive(e);
                              }}
                              eye={isEyeActive}
                              coin={coins.coin}
                              cardArray={array}
                              setCardArray={setArray}
                              cardEye={activeCardEye}
                              setCardEye={setctiveCardEye}
                              logo={coins.coin.nonColouredLogo}
                              name={coins.tORcName}
                              type={coins.coin.type}
                              balance={coins.amount}
                              decimals={coins.coin.decimals}
                              symbol={coins.coin.exchangeSymbol}
                              bgcolor={coins.coin.logoBgColor}
                              coinkey={coins.id}
                              activekey={activekey}
                              tag={coins.coin.type}
                              setcoinItem={setcoinItem}
                              setDepositModal={(e) => setdeposit(e)}
                              setShowWithdraw={(e) => setwithdraw(e)}
                              setExchangeModal={(e) => setexchange(e)}
                            />
                          </Col>
                        );
                    })}
                  {myWalletCurr.length == 0 && (
                    <Col lg={12}>
                      <p className="noresults">{t("NoResultsFound")}</p>
                    </Col>
                  )}
                </Row>
              </div>
            </div>

            <DepositModal
              getAdd={getAdd}
              setcoinItem={setcoinItem}
              value={value}
              coinItem={coinItem}
              setvalue={setvalue}
              getplatformTokensinfo={getplatformTokensinfo}
              getBal={getBal}
              showToggle={true}
              show={deposit}
              setdepositModal={(e) => setdeposit(e)}
              tableno={tableNo}
              settableno={(e) => {
                setdeposit(false);
                setfiatdeposit(true);
              }}
            />

            <DepositFiatModal
              setShowDeposit={(e) => setfiatdeposit(e)}
              show={fiatdeposit}
              tableNo={tableNo}
              showToggle={true}
              selectedCurrency={coinItem}
              allplatformbanks={platformBanks}
              setTableno={(e) => {
                setfiatdeposit(false);
                setdeposit(true);
              }}
            />

            <Withdrawal
              getAdd={getAdd}
              setcoinItem={setcoinItem}
              value={value}
              coinItem={coinItem}
              setvalue={setvalue}
              getplatformTokensinfo={getplatformTokensinfo}
              getBal={getBal}
              show1={withdraw}
              setShowWithdraw={(e) => setwithdraw(e)}
              showToggle={true}
              tableno={tableNo}
              settableno={(e) => {
                setwithdraw(false);
                setfiatwithdraw(true);
              }}
            />
            <WithdrawalFiat
              setShowWithdraw={(e) => setfiatwithdraw(e)}
              show1={fiatwithdraw}
              tableNo={"second"}
              setTableno={(e) => {
                setfiatwithdraw(false);
                setwithdraw(true);
              }}
              showToggle={true}
            />
            <ExchangeModal
              coinItem={coinItem}
              getplatformTokensinfo={getplatformTokensinfo}
              getBal={getBal}
              show2={exchange}
              setExchangeModal={(e) => setexchange(e)}
            />
          </div>
        </Container>
        <div className="mt-5">
          <FooterCommon />
        </div>
      </>
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {}, app = {}, fiat = {} }) => {
  const exchangemodal = _.get(app, "exchangemodal", false);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const withdramodal = _.get(app, "withdramodal", false);
  const depositModal = _.get(app, "DepositModal", false);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const platformBanks = _.get(fiat, "platformBanks", []);
  return {
    myaccountandbalance,
    exchangemodal,
    withdramodal,
    depositModal,
    getplatformTokensinfo,
    platformBanks,
  };
};

// const mapDispatchToProps = (dispatch) => ({
//   setExchangeModal: (data) =>
//     dispatch({ type: "setExchangeModalcalled", payload: data }),
//   setwithdrawModal: (data) =>
//     dispatch({ type: "withdramodalcalled", payload: data }),
//   setDepositModal: (data) =>
//     dispatch({ type: "DepositModalCalled", payload: data }),
// });

export default connect(mapStateToProps, null)(CryptoWallet);
