export default {
  WelcometoCyborPayfrombusiness: "İş dünyasından PayCrypt'e hoş geldiniz",
  Tostartuseallthefeatures:
    'Platformun tüm özelliklerini kullanmaya başlamak için lütfen kuruluşunuzu doğrulayın',
  UploadyourDocuments: 'Belgelerinizi Yükleyin',
  documensconfirmingyouractivities:
    'Yürürlükteki mevzuat kapsamında faaliyetlerinizi teyit eden belgeler. Belge PDF, DOCX, JPEG formatlarında olmalıdır.',
  wehavesentyouonetimepassword:
    'Bir önceki sayfa için telefon numaranıza tek kullanımlık şifre gönderdik. Doğrulamak için lütfen buraya girin',
  verificationmailsent:
    "E-mail adresinize doğrulama maili gönderildi. Lütfen e-mail gelen kutunuzu ve spam kalsörünü kontrol edin ve doğrulama mailinizi açın. Mail'de hesabınızı doğrulamak için",
  link: 'Bağlantıyı',
  inmailtoVerify: "kullanabilirsiniz",
  or: 'veya',
  button: 'Tıklama tuşu',
  upload: 'Yükle...',
 
  companyname:'Şirket Adı',
  validname:'Lütfen şirket adını girin',
  address:'Adres',
  enteraddress:'Lütfen adresi girin',
  contactperson:'İlgili kişi',
  contactname:'Lütfen isim girin',
  "Select Document": "Belge Seç",
  Requestsent: "İstek gönderildi!",
  Thankyouprovidinginfo: "Verdiğiniz bilgiler için teşekkür ederiz. En yakın zamanda sizinle iletişime geçeceğiz!",
  DoyouwanttoApprove: "Onaylamak istiyor musunuz?",
  
};
