import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Add from "@images/Add.svg";
import AddBankAccountPopup2 from "@components/add-bank-account-popup2/AddBankAccountPopup2";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import Loader from "@components/loader/Loader";
import { ReactComponent as DeleteLogo } from "@images/branchmanagement/Delete.svg";
import DeleteAdminBankPopup from "src/components/delete-admin-bank-popup/DeleteAdminBank";
import "@components/my-accounts/MyAccounts.scss";
import { useTranslation } from "react-i18next";

function MyAccounts({
  thememode,
  role,
  showBackIcon,
  setShowAccontTab,
  showAddBankConfirmation,
  // setShowAddBankConfirmation,

  setHideThis,
  getAllAddressBooks,
  getAllAddresses,
  addAddressBookCalled,
  editAddressBookCalled,
  deleteAddressBookCalled,

  platformBanks,
  getPlatformBank,
  activeBankAccounts,
  deleteBankAccountCallAsync,
  deleteBankAccountLoading,
  getAllBankAccountsLoading,
}) {
  const history = useHistory();
  const [showAddBankForm, setShowAddBankForm] = useState(false);
  const [openDeleteBankPopup, setOpenDeleteBankPopup] = useState(false);
  const [currentBankAccount, setCurrentBankAccount] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    getPlatformBank();
  }, []);

  return (
    <div id="myaccounts">
      <DeleteAdminBankPopup
        show1={openDeleteBankPopup}
        msg="Doyouwanttodeletethisbankaccount"
        setShowModal={(val) => setOpenDeleteBankPopup(val)}
        onClick={() => {
          //deleteAdminBank(currentBankSelected);
          if (currentBankAccount !== undefined) {
            deleteBankAccountCallAsync({ id: currentBankAccount });
          }

          setOpenDeleteBankPopup(false);
        }}
      />
      <Container className="containerallignm">
        {(deleteBankAccountLoading || getAllBankAccountsLoading) && <Loader />}
     
          <div className="outercontainer">
            <Row>
              <Col xs={12} lg={12}>
                <div className="myaccountheading">
                  {showBackIcon && (
                    <span
                      className="backicon"
                      onClick={() => {
                        setShowAccontTab();
                      }}
                    >
                      <Arrow className={"Arrow"} />
                    </span>
                  )}
                  <h1 className="mainheading">{t("MyAccounts")}</h1>
                </div>
                <h1 className="mainheading1">{t("MyBankAccount")}</h1>
                <p className="textbelowmainheading">
                  {t("BankAccountsRegistered")}
                </p>
                {/* <div className={thememode == "dark" ? "boxdark" : "box"}> */}
                <div className="boxdark">
                  <div className="logo">
                    <div className="col1">
                      <div className="col11">
                        {activeBankAccounts &&
                        activeBankAccounts.length !== 0 ? (
                          <div
                            className="boxs"
                            onClick={() => {
                              console.log("bank");
                              setShowAddBankForm(true);
                            }}
                          >
                            <img
                              className="addlogo"
                              src={Add}
                              alt=""
                              onClick={() => {
                                // setShowAddBankConfirmation(false);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="addbankcenter">
                            <img
                              className="addlogo"
                              src={Add}
                              alt=""
                              onClick={() => {
                                console.log("adddddddd");
                                setShowAddBankForm(true);
                                // setShowAddBankConfirmation(false);
                              }}
                            />
                          </div>
                        )}

                        <span className="addbanktext">
                          {t("AddNewBankAccountt")}
                        </span>
                        <div className="bankaccounts">
                          {activeBankAccounts !== undefined
                            ? activeBankAccounts.map((item) => {
                                return (
                                  <div className="boxs">
                                    <div className="logos-allign">
                                      <div className="banklogo">
                                        <img
                                          className="fixed"
                                          src={
                                            item.bank.colouredLogo !== undefined
                                              ? `${
                                                  process.env.REACT_APP_IMGLINK
                                                }${
                                                  thememode == "light"
                                                    ? item.bank.nonColouredLogo
                                                    : item.bank.colouredLogo
                                                }`
                                              : ""
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <DeleteLogo
                                        className="deletebutton"
                                        onClick={() => {
                                          setCurrentBankAccount(item.id);
                                          setOpenDeleteBankPopup(true);
                                        }}
                                      />
                                    </div>
                                    <div className="namebusiness">
                                      {item.userName}
                                    </div>
                                    <OverlayTrigger
                                      placement={"top"}
                                      overlay={
                                        <Tooltip id="bankaccount-tooltip">
                                          {item.IBANs}
                                        </Tooltip>
                                      }
                                    >
                                      <div className="namebusiness">
                                        {item.IBANs}
                                      </div>
                                    </OverlayTrigger>
                                    <div className="idbusiness">
                                      {item.currencyType}
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="col12 d-block d-lg-none"
                      onClick={() => {
                        history.push("/addnewbankaccount");
                      }}
                    >
                      <div className="addbank">
                        <img className="addlogo" src={Add} alt="" />
                      </div>
                      <span className="addbanktext">
                        {t("AddNewBankAccountt")}
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="decription">
                  <p className="desciptionline">{t("CreditCardMessage")}</p>
                </div>
              </Col>
              {/* <Col xs={12} lg={6}>
                <div className="col2">
                  <div className="mainheading">{t("MyCryptoAddress")}</div>
                  <div className="textbelowmainheading">
                    {t("CryptoAddressAsFollows")}
                  </div>
                  {role == "Business" && (
                    <>
                      <div
                        className={
                          thememode == "dark" ? "boxdark box1 boxdisplay" : "box box1 boxdisplay"
                        }
                      >
                        <div className="innerboxbusiness">
                          <div className="line1business">
                            <div className="cyborlogobusiness">
                              <img src={Cyborcoin} alt="" />
                            </div>
                            <div className="cointitlebusiness">Cybor Coin</div>
                          </div>
                          <div className="namebusiness">Mikhail Mazanov</div>
                          <div className="idbusiness">TXQ23ii78aI12OoTJBD1...</div>
                        </div>
                        <div>
                          <div className="addnewbusiness">
                            <img className="Addlogo" src={Add} alt="" />
                          </div>
                          <div className="addchange">
                            {t("AddNewCryptoAddress")}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {role != "Business" && (
                    <>
                      <div
                        className={
                          thememode == "dark" ? "boxdark box1" : "box box1"
                        }
                      >
                        <div className="innerbox">
                          <div className="line1">
                            <div className="cyborlogo">
                              <img src={Cyborcoin} alt="" />
                            </div>
                            <div className="cointitle">Cybor Coin</div>
                          </div>
                          <div className="name">Mikhail Mazanov</div>
                          <div className="id">TXQ23ii78aI12OoTJBD1...</div>
                        </div>
                        <div>
                          <div className="addnew">
                            <img className="Addlogo" src={Add} alt="" />
                          </div>
                          <div className="addblue">{t("AddNeww")}</div>
                          <div className="addchange">
                            {t("AddNewCryptoAddress")}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col> */}
            </Row>
          </div>
    
      </Container>
      <AddBankAccountPopup2
        showAddBankConfirmation={showAddBankForm}
        // setShowAddBankConfirmation={setShowAddBankForm}
        onPress={() => {}}
        onCancelledAsync={() => {
          setShowAddBankForm(false);
        }}
        onSuccessfulBankCreation={() => {
          setShowAddBankForm(false);
        }}
        onCancelled={() => {
          setShowAddBankForm(false);
        }}
        // setHideThis={(e) => setHideThis(e)}
      />
    </div>
  );
}
const mapStateToProps = ({ app = {}, user = {}, fiat = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const deleteBankAccountLoading = _.get(
    fiat,
    "deleteBankAccountLoading",
    false
  );
  const getAllBankAccountsLoading = _.get(
    fiat,
    "getAllBankAccountsLoading",
    false
  );
  const role = _.get(user, "userInfo.role", "");
  const getAllAddresses = _.get(user, "getAllAddresses", []);
  const platformBanks = _.get(fiat, "platformBanks", []);
  const activeBankAccounts = _.get(fiat, "getAllBankAccountDetails", []);
  return {
    thememode,
    role,
    getAllAddresses,
    platformBanks,
    activeBankAccounts,
    deleteBankAccountLoading,
    getAllBankAccountsLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAllAddressBooks: () => dispatch({ type: "GetAllAddressBooksCalled" }),
  deleteBankAccountCallAsync: (data) =>
    dispatch({ type: "deleteBankAccountCall", payload: data }),
  addAddressBookCalled: (data) =>
    dispatch({ type: "AddAddressBookCalled", payload: data }),
  editAddressBookCalled: (data) =>
    dispatch({ type: "EditAddressBookCalled", payload: data }),
  getPlatformBank: (data) =>
    dispatch({ type: "getPlatformBankCalled", payload: data }),
  deleteAddressBookCalled: (data) =>
    dispatch({ type: "DeleteAddressBookCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccounts);
