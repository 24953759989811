import * as _ from "lodash";
import React, { useState, useEffect } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import InputField from "@components/input-field/InputField";
import { Modal, Container, Row, Col, Spinner } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "@components/ido-buy-popup/IdoBuyPopupStyles.scss";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import BigNumber from "bignumber.js";
import { connect } from "react-redux";
import Loader from "@components/loader/Loader";
import { useTranslation } from "react-i18next";

function IdoBuyPopup({
  show1,
  showm,
  passedfrom,
  setIdoModal,
  setShowModal1,
  setShowModal,
  getExchangerate,
  coinItem,
  idorounds,
  CyborExchangeRate,
  BuyCyborcoinFromIdo,
  getplatformTokensinfo,
  myaccountandbalance,
  createTransactionLoading,
  CyborExchangeRateLoading,
  createTransFail
}) {
  const [Selected, setSelectedIndex] = useState(0);
  const [val1, setVal1] = useState("");
  const [val2, setVal2] = useState("");

  let option = getplatformTokensinfo.filter(
    (item) => item.tokenName != "CyborCoin"
  );
  let CyborCoin = getplatformTokensinfo.filter(
    (item) => item.tokenName == "CyborCoin"
  );
  useEffect(() => {
    getExchangerate({
      token1: option && option[Selected] && option[Selected].id,
      id: idorounds && idorounds[0] && idorounds[0].id,
    });
  }, [Selected]);

  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  let balances = Number(
    getBal(option && option[Selected] && option[Selected].id)
  ).toFixed(6);

  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="idobuypopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <Modal.Header className="modalhead">
        <Modal.Title>
          <span className="buytitle">{t("BuyCyborCoin")}</span>
        </Modal.Title>

        <CloseIcon
          className="closebtn"
          onClick={() => {
            passedfrom === "DepositModal"
              ? setShowModal1(false)
              : setShowModal(false);
          }}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="maintitle hidden-for-desktop">
          <span>Buy Cybor Coin</span>
        </div>
        <div
          className="back hidden-for-desktop"
          onClick={() => {
            passedfrom === "DepositModal"
              ? setShowModal1(false)
              : setShowModal(false);
          }}
        >
          <ExpandIcon />
        </div>
        <div className="subtitle hidden-for-desktop">
          <span>Buy with using your current balances</span>
        </div>
        <Container>
          <Row className="topspace">
            <div className="buytext">
              <span>{t("BuyWithCurrent")}</span>
            </div>
            <Col lg={12}>
              <div className="btext">{t("Buy")} </div>
            </Col>
            <Col lg={7}>
              <div className="buytxtalign">
                <img
                  className="icons"
                  src={`${process.env.REACT_APP_IMGLINK}${
                    CyborCoin && CyborCoin[0] && CyborCoin[0].colouredLogo
                  }`}
                />
                {t("CyborCoin")}
              </div>
            </Col>
            <Col lg={5} className="mbspace">
              <InputField
                type="number"
                className={"roundedborderfield"}
                onChange={(e) => {
                  let exc = BigNumber(e.target.value).dividedBy(
                    CyborExchangeRate && CyborExchangeRate.result
                  );
                  setVal1(e.target.value);

                  setVal2(exc);
                }}
                value={val1}
              />
            </Col>
            <Col lg={12}>
              <div className="btext">{t("With")}</div>
            </Col>
            <Col lg={7}>
              {" "}
              <DropdownSearch
                options={option}
                coinItem={coinItem}
                Selected={Selected}
                setSelectedIndex={(e) => {
                  setSelectedIndex(e);
                  setVal1("");
                  setVal2("");
                }}
              />
            </Col>
            <Col lg={5} className="mbspace">
              <InputField
                type="number"
                className={"roundedborderfield"}
                onChange={(e) => {
                  setVal2(e.target.value);

                  let exc = BigNumber(e.target.value).multipliedBy(
                    CyborExchangeRate && CyborExchangeRate.result
                  );

                  setVal1(exc);
                }}
                value={val2}
              />
            </Col>
            <div className="txtmargin">
              <div className="bottomtext">{t("ExchangeRates")}:</div>
              {CyborExchangeRateLoading && (
                <Spinner animation="grow" className="spincolor" />
              )}
              <div className="bottomtext">
                1{" "}
                {option && option[Selected] && option[Selected].exchangeSymbol}{" "}
                = {CyborExchangeRate && CyborExchangeRate.result} {t("Cybor")}
              </div>
            </div>

            {createTransactionLoading && (
              <Loader msg={t("TransactionWaitMessage")} />
            )}
            <div>
              <p className="errorstyles">{createTransFail}</p>
            </div>
            <Col lg={12}>
              <div className="ok-btn">
                {BigNumber(balances).isGreaterThanOrEqualTo(val2) ? (
                  <CustomButton
                    type="submit"
                    name={t("Buy")}
                    filled={true}
                    primary={true}
                    onClick={() => {
                      BuyCyborcoinFromIdo({
                        token2:
                          option && option[Selected] && option[Selected].id,
                        idoRoundId:
                          idorounds && idorounds[0] && idorounds[0].id,
                        amount: val2,
                      });
                    }}
                  />
                ) : (
                  <div className="insufficientbal">
                    {(val2 != "" || val1 != "") && (
                      <span>{t("InsufficientBalance")}</span>
                    )}
                  </div>
                )}
              </div>
            </Col>
            {/* <Col lg={12}>
              <div className="ok-btn">
                <CustomButton
                  name="Buy"
                  className="close-btn"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </Col> */}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({ wallet = {}, ido = {} }) => {
  const loadingRate = _.get(wallet, "loadingRate", []);
  const exchangeRates = _.get(wallet, "exchangeRates", 1);
  const createTransactionLoading = _.get(
    ido,
    "createTransactionLoading",
    false
  );
  const idorounds = _.get(ido, "idorounds", []);
  const CyborExchangeRate = _.get(ido, "CyborExchangeRate", {});
  const CyborExchangeRateLoading = _.get(
    ido,
    "CyborExchangeRateLoading",
    false
  );
  const createTransFail = _.get(ido, "createTransFail.message", false);

  return {
    loadingRate,
    exchangeRates,
    createTransactionLoading,
    CyborExchangeRate,
    idorounds,
    CyborExchangeRateLoading,
    createTransFail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExchangerate: (data) =>
    dispatch({ type: "getCyborExchangeRateCalled", payload: data }),
  BuyCyborcoinFromIdo: (data) =>
    dispatch({ type: "BuyCyborcoinFromIdoCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdoBuyPopup);
