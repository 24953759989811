import "@components/input-field/InputFieldStyles.scss";
import Hide from "@images/hide.png";
import IconPassword from "@images/IconPassword.png";
import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

const InputField = ({
  icon,
  iconsrc,
  eye,
  showeye,
  setshow,
  isInvalid,
  required,
  disabled,
  value,
  type,
  placeholder,
  error,
  phone,
  minlength,
  onChange,
  onFocus,
  onBlur,
  as,
  rows,
  onClick,
  classname,
  min,
  link,
  max,
  onPress,
  linktext,
  normtext,
  text,
  btn,
  counter,
  timer,
}) => {
  const { t, i18n, ready } = useTranslation();

  return (
    <div className="InputField">
      <Form.Group className="area">
        {!phone && (
          <Form.Control
            as={as}
            rows={rows}
            className={`input ${classname}`}
            value={value}
            autoCorrect={false}
            type={type}
            onWheel={(e) => e.target.blur()}
            maxLength={minlength}
            required={required}
            isInvalid={isInvalid}
            onChange={onChange}
            placeholder={placeholder}
            autoComplete="nope"
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            onClick={onClick}
            min={min}
            max={max}
          />
        )}
        {timer && <span onClick={onPress} className="linktxt" target="_blank">
            {counter}
          </span>}
        
        {link && (
          <span onClick={onPress} className="linktxt" target="_blank">
            {linktext}
          </span>
        )}
        {text && (
          <span className="txtclass" target="_blank">
            {normtext}
          </span>
        )}
        {eye && (
          <img
            onClick={setshow}
            src={showeye ? Hide : IconPassword}
            className="passwordIcon"
            alt=""
          />
        )}
        {icon && (
          <span className="othersIcon">
            {iconsrc}
          </span>
        )}
        {phone && (
          <div className="phoneinput form-group">
            <PhoneInput
              inputProps={{
                required: true,
              }}
              placeholder=""
              enableSearch
              specialLabel=""
              country={"in"}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        )}
        {/* <Form.Control.Feedback type="invalid">{t(`${error}`)}</Form.Control.Feedback> */}
       <Form.Control.Feedback type="invalid">{ error === undefined ? t("Invalid") :t(`${error}`)}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default InputField;
