import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import CurrencyPairWallet from "@components/currencypair-wallet/CurrencyPairWallet";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import DepositWallet from "@components/deposit-wallet/DepositWallet";
import CryptoBalance from "@components/crypto-balance/CryptoBalance";
import "@features/fiat-wallet/FiatWalletStyle.scss";
import axios from "axios";
import moment from "moment";
import FiatBalance from "@components/fiat-balance/FiatBalance";
import WalletBigCoinCard from "@components/wallet-big-coin-card/WalletBigCoinCard";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "@components/footer-common/FooterCommon";
import currencies from "src/utils/Currencies";
function FiatWallet(props) {
  let currenciesData = currencies.map((data)=> data.exchangeSymbol);

  let {
    plaformcurrencies,
    getFiatBalanceinfo,
    setwithdrawModal,
    setDepositModal,
    fiatDepositModal,
    PreferredCurrency,
    withdramodal,
    platformBanks,
    getplatformTokensinfo,
    myaccountandbalance,
    fiatrate,
    hideLoaderOnRefresh
  } = props;
  const [marketdata, setmarketdata] = useState("");
  const [chartdata, setchartdata] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(30, "days"));
  const [endDate, setEndDate] = useState(new Date());
  const [ circularLoader , setCircularLoader] = useState(true)
  const [Currency, setCurrency] = useState(
    PreferredCurrency,
  );
  const [ApiDATA, setApiDATA] = useState([]);

  const [selectedCoin, setselectedCoin] = useState(
    plaformcurrencies && plaformcurrencies[0]
  );

  useEffect(() => {
    if(Currency !== PreferredCurrency){
      setCurrency(PreferredCurrency)
    }
  }, [PreferredCurrency])

  useEffect(() => {
    getData(selectedCoin.exchangeSymbol);
  }, [endDate, Currency, selectedCoin]);

  const getAmount = (cur) => {
    // console.log(cur,getFiatBalanceinfo)
     let obj =
      getFiatBalanceinfo &&
      getFiatBalanceinfo.filter((item) => item.currencyId == cur);
    let reelbal = _.get(obj, "[0].balance", 0);
    // console.log(obj,'get amount' , reelbal);
    return reelbal;
  };

  const getData = (exchangeSymbol, picker) => {
    let stdt = picker?.startDate || startDate;
    let eddt = picker?.endDate || endDate;
    let charts = [];
   axios
      .get(
        `https://api.currencyapi.com/v3/range?apikey=${
          process.env.REACT_APP_FIATKEY
        }&base_currency=${exchangeSymbol}&datetime_start=${moment(stdt).format(
          "YYYY-MM-DD"
        )}&datetime_end=${moment(eddt).format("YYYY-MM-DD")}&accuracy=day `
      )
      .then((res) => {
        setApiDATA(res.data && res.data.data && res.data.data);
        res.data && res.data.data && res.data.data.map(it=> {
          charts.push({
            x: moment(it.datetime).format("MM/DD"),
            y:
              it.currencies[Currency] !== undefined
                ? it.currencies[Currency].value
                : exchangeSymbol == Currency
                ? 1
                : "",
          });
        })
         setchartdata(charts);
      });
     axios
      .get(
        `https://api.currencyapi.com/v3/latest?apikey=${process.env.REACT_APP_FIATKEY}&base_currency=${exchangeSymbol}`
      )
      .then((res) => {
        setmarketdata(res.data && res.data.data);
         setCircularLoader(false)
      });
  };
 
  return (
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh} >
        <Container className="containertp">
        <div id="FiatWallet">
          <Row>
            <Col lg={12}>
              <div className="cryptobalacesection">
                <FiatBalance
                  setselectedSymbol={(e) => {
                    setselectedCoin(e);
                  }}
                  PreferredCurrency={PreferredCurrency}
                  mywalletsinfo={getFiatBalanceinfo}
                  plaformcurrencies={plaformcurrencies}
                  getAmount={getAmount}
                  selectedCoin={selectedCoin}
                  setselectedCoin={(e) => setselectedCoin(e)}
                  marketdata={marketdata}
                  // setwithdrawModal={(e)=>setwithdrawModal(e)}
                  // setDepositModal={(e)=>setDepositModal(e)}
                  // fiatDepositModal={fiatDepositModal}
                  // FiatWithdrawModal={withdramodal}
                  circularLoader={circularLoader}
                  platformBanks={platformBanks}
                  myaccountandbalance={myaccountandbalance}
                  getplatformTokensinfo={getplatformTokensinfo}
                  fiatrate={fiatrate}
                />
              </div>

              <div className="spacearea">
                <CurrencyPairWallet
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={(e) => setStartDate(e)}
                  setEndDate={(e) => setEndDate(e)}
                  onDateApply={() =>{}}
                  setCurrency={(e) => {
                     setCurrency(e);
                  }}
                  currencies={currenciesData}
                  currency={Currency}
                  chartdata={chartdata}
                  selectedCoin={selectedCoin}
                  marketdata={marketdata}
                  coin={selectedCoin && selectedCoin.exchangeSymbol}
                />
              </div>

              {/* <div className="threecards">
              <div className="leftcard">
                <div className="cardtopwallet">
                  <SendWallet />
                </div>
                <div className="cardbottomwallet">
                  <WithdrawalWallet />
                </div>
              </div>
              <div className="rightcard ">
                <div className="rightcardswallet">
                  <DepositWallet />
                </div>
                <div className="rightcardswallet topspace">
                  <ExchangeWallet />
                </div>
              </div>
            </div> */}
            </Col>
          </Row>
          </div>
        </Container>
      <FooterCommon />
    </ScreenLayout>
  );
}

const mapStateToProps = ({ ido, wallet = {}, app = {}, fiat = {} ,user}) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const transactionCount = _.get(ido, "transactionCount.count", 0);
  const Roundcount = _.get(ido, "Roundcount.count", 0);
  const claimtokencount = _.get(ido, "claimtokencount.count", 0);
  const sendtokenCount = _.get(ido, "sendtokenCount.count", 0);
  const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const fiatDepositModal = _.get(app, "DepositModal", false);
  const withdramodal = _.get(app, "withdramodal", false);
  const exchangemodal = _.get(app, "exchangemodal", false);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const fiatrate = _.get(app, "fiatrate", false);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");

  const platformBanks = _.get(fiat, "platformBanks", []);
  return {
    getplatformTokensinfo,
    transactionCount,
    Roundcount,
    sendtokenCount,
    claimtokencount,
    mywalletsinfo,
    myaccountandbalance,
    withdramodal,
    exchangemodal,
    plaformcurrencies,
    fiatDepositModal,
    getFiatBalanceinfo,
    platformBanks,
    PreferredCurrency,
    fiatrate
  };
};

const mapDispatchToProps = (dispatch) => ({
  createIdoTrans: (data) =>
    dispatch({ type: "createIdoTranscalled", payload: data }),
  // setwithdrawModal: (data) =>
  //   dispatch({ type: "withdramodalcalled", payload: data }),
  // setDepositModal: (data) =>
  //   dispatch({ type: "DepositModalCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FiatWallet);
