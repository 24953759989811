import * as _ from "lodash";
import React  ,{useEffect} from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/password-success/PasswordSuccessStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { setChangePasswordMessageClear } from '@store/userredux'
const PasswordSuccess = (props) => {
  const {
    show1,
    setShowModal,
    onPress,
    address,
    amount,
    onClick,
    setShowPass,
    logouts,
    clearMessages,
    
  } = props || {};
  const { t, i18n, ready } = useTranslation();
  const history = useHistory();
 
  return (
    <Modal
      className="passwordsuccess"
      show={show1}
      onHide={() => {
        setShowPass(false);
        setShowModal(false);
        clearMessages()
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowPass(false);
            setShowModal(false);
            clearMessages()
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext">
                {t("PasswordchangedSuccessfully")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name={t("Ok")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowPass(false);
                    // props.history.push("/signin")
                    // setShowModal(false);
                    // clearMessages();
                  history.push("/signin")
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};


const mapStateToProps = ({ app = {}, user = {} }) => {
  const signUpLoading = _.get(user, "signUpLoading", false);

  const signUpError = _.get(user, "signUpError", "");
  const role = _.get(user, "userInfo.role", "");
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  const defaultrole = _.get(app, "role", "Business");

  return {
    signUpLoading,
    signUpError,
    exchangeId,
    exchange,
    thememode,
    role,
    defaultrole,
  };
};


const mapDispatchToProps = (dispatch) => ({
  clearMessages: () => dispatch(setChangePasswordMessageClear())
})

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PasswordSuccess));
