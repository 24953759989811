import React from "react";
import BigNumber from "bignumber.js";
import "@components/branch-item/BranchItemStyles.scss";
import { getCSymbol, getESymbol } from "src/utils/Currencies";

const BranchItem = ({data,PreferredCurrency}) => {
   let {branchName,address,mtotal}= data||{}
  return (
    <div
      className="Branchitem
         pointer"
    >
      <div className="outer">
        <div className="maintext">{branchName}</div>
        <div className="subtext">
         {address}
        </div>
      </div>
      <div className="coinbalance">
        <span>    {getCSymbol(PreferredCurrency)}{BigNumber(mtotal).toFixed(4,BigNumber.ROUND_FLOOR)}</span>
      </div>
    </div>
  );
};
export default BranchItem;
