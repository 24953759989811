import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, Suspense, useState } from "react";
import Container from "react-bootstrap/Container";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import { Row, Col } from "react-bootstrap";
import ScreenLayout from "src/components/layout/ScreenLayout";
import CustomButton from "@components/custom-button/CustomButton";
import MarketRatesComponent from "@components/market-rates/MarketRates";
import moment from "moment";
import "@features/market-rates/MarketRates.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import FooterCommon from "@components/footer-common/FooterCommon";
import SearchBox from "@components/search-box/SearchBox.js";
import AddCoinModal from "@components/add-coin-modal/AddCoinModal.js";
import { setMarketRatesCurrencies } from "@store/userredux";
import Loader from "@components/loader/Loader";
const Binance = require("binance-api-node").default;

function MarketRates({
  getplatformTokensinfo,
  displayedCurrencies,
  addCurrency,
  fiatrate,
  PreferredCurrency,
  hideLoaderOnRefresh
}) {
  const { t } = useTranslation();
  const [market, setmarket] = useState([]);
  const [chartsdata, setcharts] = useState([]);
  const [min, setMin] = useState([]);
  const [max, setMax] = useState([]);
  const [value, setvalue] = useState("");
  const [addCoin, setAddCoin] = useState(false);
  const [allFilteredTokens, setFilteredTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = Binance();

  useEffect(() => {
    filterTokens();
  }, [value]);

  useEffect(() => {
    filterTokens();
  }, [displayedCurrencies.crpto]);

  const filterTokens = () => {
    if (displayedCurrencies.length !== 0) {
      let filteredTkn = getplatformTokensinfo.map((item) =>
        _.findIndex(
          [...displayedCurrencies.crpto].sort(),
          (chr) => chr === item.id
        )
      );
      if (filteredTkn.includes(-1)) {
        filteredTkn = filteredTkn.map((itm) =>
          itm === 0 || itm === -1 ? itm : itm + 1
        );
      }

      let filTKN = [];
      let dataForChecking = [];

      for (let i of [...displayedCurrencies.crpto].sort()) {
        let data = getplatformTokensinfo.filter((itm) => itm.id === i)[0];
        // condition to remove try coin full array
        // if(data.exchangeSymbol!=="TRYC")
        // // condition to remove try coin full array
        // {
        let dataWithItem = {
          ...data,
          itemIndex: getplatformTokensinfo.indexOf(data),
        };
      
        dataForChecking.push(data);
        if (
          dataWithItem.tokenName.toLowerCase().includes(value.toLowerCase()) &&
          value != ""
        )
          filTKN.push(dataWithItem);
        if (value == "") filTKN.push(dataWithItem);
      }
     
      
      setFilteredTokens(filTKN);

      for (let i of dataForChecking) {
        if ((i.exchangeSymbol != "CYBOR")&&(i.exchangeSymbol != "TRYC")) {
          getMarketdata(
            i.exchangeSymbol,
            "USD",
            getplatformTokensinfo.indexOf(i)
          );
        }
      }
    }
  //}
  };

  useEffect(() => {
  
  }, [window.innerWidth]);

  const getMarketdata = (exchangeSymbol, Currency, index) => {
    try {
      setLoading(true)
      let CurrencyT = Currency == "USD" ? "USDT" : "USDT";
      if (exchangeSymbol == "USDT") {
        client
          .dailyStats({
            symbol: `BUSD${CurrencyT}`,
          })
          .then((time) => {
            
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `BUSD${CurrencyT}`,
            interval: "1d",
            limit: 20,
            // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
            // endTime: moment(new Date()).valueOf(),
          })
          .then((time) => {
            
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
            setLoading(false)
          })
          .catch((er) => {
            setLoading(false)
          });
      } else if (exchangeSymbol == "TRYC") {
        // client
        //   .dailyStats({
        //     symbol: `${CurrencyT}TRY`,
        //   })
        //   .then((time) => {
            
        //     market[index] = time;
        //     setmarket(() => [...market]);
        //   })
        //   .catch((er) => {});
        // client
        //   .candles({
        //     symbol: `${CurrencyT}TRY`,
        //     interval: "1d",
        //     limit: 20,
        //     // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
        //     // endTime: moment(new Date()).valueOf(),
        //   })
        //   .then((time) => {
            
        //     let charts = [];
        //     let mins = time && time[0].close;
        //     let maxs = time && time[0].close;
        //     time &&
        //       time.map((point) => {
        //         if (point.close < min) {
        //           mins = point.close;
        //         }
        //         if (point.close > max) {
        //           maxs = point.close;
        //         }
        //         charts.push({
        //           x: moment(point.closeTime).format("MM/DD/YYYY"),
        //           y: point.close,
        //         });
        //       });
        //     min[index] = mins;
        //     setMin(() => [...min]);
        //     max[index] = maxs;
        //     setMax(() => [...max]);
        //     chartsdata[index] = charts;
        //     setcharts(() => [...chartsdata]);
        //     setLoading(false)
        //   })
        //   .catch((er) => {
        //     setLoading(false)
        //   });
      } else {
        client
          .dailyStats({
            symbol: `${exchangeSymbol}${CurrencyT}`,
          })
          .then((time) => {
            
            
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `${exchangeSymbol}${CurrencyT}`,
            interval: "1d",
            limit: 20,
          })
          .then((time) => {
            
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
            setLoading(false)
          })
          .catch((er) => {setLoading(false)});
      }
    } catch (err) {
      setLoading(false)
    }
  };
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <ScreenLayout hideLoaderOnStart={true} loaderOnRefresh={hideLoaderOnRefresh} >
        <>
          <Container>
            <div id="marketrates">
            {loading && <Loader />}
              <Row>
                <Col lg={12}>
                  <div className="trouter trasactiontitle">
                    <div className="marketratesheadwrapper">
                      <div className="title">
                        {t("Market")} {t("Rates")}
                      </div>
                      <div className="add-btn2 d-none d-lg-block">
                        <CustomButton
                          name={<> {t("AddCoin")} +</>}
                          rounded
                          bordered
                          classname="lessfontweight"
                          onClick={() => {
                            setAddCoin(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="topspacese d-lg-block d-flex">
                    <SearchBox
                      className="search-align-for-market"
                      value={value}
                      setvalues={setvalue}
                    />
                    <div className="add-btn d-block d-lg-none">
                      <CustomButton
                        name={<> {t("AddCoin")} +</>}
                        rounded
                        bordered
                        classname="lessfontweight"
                        onClick={() => {
                          setAddCoin(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    {allFilteredTokens.length !== 0 &&
                      allFilteredTokens.map((items, index) => {
                        if ((items.exchangeSymbol != "CYBOR")&&(items.exchangeSymbol != "TRYC"))
                          return (
                            <MarketRatesComponent
                              bgcolor={items.logoBgColor}
                              logo={`${process.env.REACT_APP_IMGLINK}${items.nonColouredLogo}`}
                              coinName={items.tokenName}
                              symbol={items.exchangeSymbol}
                              fiatrate={fiatrate}
                              PreferredCurrency={PreferredCurrency}
                              marketlink={items.marketlink}
                              chartPoinst={
                                chartsdata && chartsdata[items.itemIndex]
                              }
                              balance={
                                market &&
                                market[items.itemIndex] &&
                                market[items.itemIndex].askPrice
                              }
                              min={min && min[items.itemIndex]}
                              max={max && max[items.itemIndex]}
                              percentChanges={`${
                                market &&
                                market[items.itemIndex] &&
                                market[items.itemIndex].priceChangePercent
                              }`}
                            />
                          );
                      })}
                  </div>
                 
                </Col>
              </Row>
            </div>
          </Container>
          <FooterCommon />
        </>
      </ScreenLayout>

      <AddCoinModal
        getplatformTokensinfo={getplatformTokensinfo}
        setShowAddcoin={setAddCoin}
        show1={addCoin}
        setCoinOfState={addCurrency}
        displayedCurrencies={displayedCurrencies}
      />
    </Suspense>
  );
}

const mapStateToProps = ({ wallet = {}, user = {},app={} }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const displayedCurrencies = _.get(user, "marketRateDisplayCurrencies", []);
  const fiatrate = _.get(app, "fiatrate", false);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");

  return {
    getplatformTokensinfo,
    displayedCurrencies,
    fiatrate,
    PreferredCurrency
  };
};

const mapDispatchToProps = (dispatch) => ({
  addCurrency: (data) => dispatch(setMarketRatesCurrencies(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MarketRates);
