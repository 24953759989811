import { createSlice } from "@reduxjs/toolkit";

const idoslice = createSlice({
  name: "ido",
  initialState: {
    createRoundLoading: false,
    getroundLoading: false,
    idorounds: [],
    idoBalance: null,
  },
  reducers: {
    createIdoSuccess: (state, action) => {
      return {
        ...state,
        createRoundLoading: false,
        createRoundFail:''
      };
    },
    createIdoTrigger: (state, action) => {
      return {
        ...state,
        createRoundLoading: action.payload,
        createRoundFail:""
      };
    },
    createIdoFailure: (state, action) => {
      return {
        ...state,
        createRoundFail: action.payload,
      };
    },
    getroundIdoFailure: (state, action) => {
      return {
        ...state,
        createRoundFail: action.payload,
      };
    },
    getroundIdoSuccess: (state, action) => {
      // console.log('ido round redux' , action.payload);
      return {
        ...state,
        idorounds: action.payload,
      };
    },
    getroundIdoTrigger: (state, action) => {
      // console.log('action', action.payload)
      return {
        ...state,
        getroundLoading: action.payload,
      };
    },
    createIdoTransFailure: (state, action) => {
      return {
        ...state,
        createTransFail: action.payload,
      };
    },
    createIdoTransSuccess: (state, action) => {
      return {
        ...state,
        createTransactionLoading: false,
      };
    },
    createIdoTransTrigger: (state, action) => {
      return {
        ...state,
        createTransactionLoading: action.payload,
      };
    },
    getidolistSuccess: (state, action) => {
      return {
        ...state,
        idotransaction: action.payload,
      };
    },

    claimIdoTransFailurer: (state, action) => {
      return {
        ...state,
        claimTransFail: action.payload,
      };
    },
    claimIdoTransSuccessr: (state, action) => {
      return {
        ...state,
        claimTransactionLoading: false,
      };
    },
    claimIdoTransTrigger: (state, action) => {
       return {
        ...state,
        claimTransactionLoading: action.payload,
      };
    },
    getRoundcountSuccess: (state, action) => {
      return {
        ...state,
        Roundcount: action.payload,
      };
    },
    gettransactionCountSuccess: (state, action) => {
      return {
        ...state,
        transactionCount: action.payload,
      };
    },
    gettclaimtokenSuccess: (state, action) => {
      return {
        ...state,
        claimtokencount: action.payload,
      };
    },
    getsendtokenCountSuccess: (state, action) => {
      return {
        ...state,
        sendtokenCount: action.payload,
      };
    },

    gettIdoBalanceSuccess: (state, action) => {
      return {
        ...state,
        idoBalance: action.payload,
      };
    },

    getCyborExchangeRateSuccess: (state, action) => {
      return {
        ...state,
        CyborExchangeRate: action.payload,
      };
    },
    getCyborExchangeRateTrigger: (state, action) => {
      return {
        ...state,
        CyborExchangeRateLoading: action.payload,
      };
    },
    clearIdoReduxSuccuss: (state, action) => {
      return {};
    },
    
  },
});
export default idoslice.reducer;

export const {
  createIdoTrigger,
  createIdoSuccess,
  createIdoFailure,
  getroundIdoFailure,
  getroundIdoSuccess,
  getroundIdoTrigger,
  createIdoTransTrigger,
  createIdoTransFailure,
  createIdoTransSuccess,
  getidolistSuccess,
  claimIdoTransFailure,
  claimIdoTransSuccess,
  claimIdoTransTrigger,
  getRoundcountSuccess,
  gettransactionCountSuccess,
  gettclaimtokenSuccess,
  getsendtokenCountSuccess,
  gettIdoBalanceSuccess,
  getCyborExchangeRateSuccess,
  getCyborExchangeRateTrigger,
  clearIdoReduxSuccuss
} = idoslice.actions;
