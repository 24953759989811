import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "@components/idocard/IdoCardStyle.scss";
function IdoCard({logoBgColor,logo,name,value}) {
  const { t } = useTranslation(); 
  return (
    <div className="idocard">
       <div className="cardoutersection">
           <div className="labelwithicon">
           <div className="coincircle" style={{backgroundColor:logoBgColor}}>
           <img src={`${process.env.REACT_APP_IMGLINK}${logo}`} className="coinimg" />  
          </div>
           <span className="coinlistarea"> {t(`${name}`)} </span>
           </div>
          <div className="valuesection">
          <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 1000 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                     {value} 
                    </Tooltip>
                  )}
                >
              <span className="idovalue">{value}</span>
              </OverlayTrigger>
          </div>
       </div>
    </div>
  );
}
export default React.memo(IdoCard);