import React from "react";
import "@components/footer-common/FooterCommonStyles.scss";
import { Row, Col, Container } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";
function FooterCommon() {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang") || "en";
  return (
    <div className="footercommon">
      <div className=" d-block d-lg-none bigblueline" />
      <Container>
        <Row className="contentbelowbigline">
          <Col lg={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
            <div className="leftalign">
              <p className="maintxt">
                {t("trademark")}{" "}
                <a
                  // href={`https://cyborholding.com/${lang}`}
                >
                  {/* {" "}
                  <span className="applink">www.cyborholding.com</span> */}
                </a>{" "}
                {/* , {t("CyborexPlatform")}
                <br className="d-none d-xl-block" />
                <a href="https://cyborex.io/">
                  {" "}
                  <span className="applink"> www.cyborex.io</span>
                </a>{" "}
                , {t("CyborPayPaymentSystem")}
                <a href={`https://cyborpay.com/${lang}`}>
                  {" "}
                  <span className="applink">www.cyborpay.com </span>
                </a>
                , {t("Cyborcoin")} <br className="d-none d-xl-block" />
                {t("cryptocurrency")}{" "}
                <a href={`https://cyborcoin.com/${lang}`}>
                  {" "}
                  <span className="applink">www.cyborcoin.com </span>
                </a> */}
                {/* {t("OwnedBy")} */}
                {/* <br className="d-none d-xl-block" /> */}
                {t("RightReserved")}
              </p>
            </div>
          </Col>
          <Col lg={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
            <div className="linktxt">
              <a
                // href="https://cyborholding.gitbook.io/cybor-holding/"
                href="#"
              >
                {" "}
                <span className="links">{t("FAQs")}</span>
              </a>{" "}
              <a href="#">
                {" "}
                <span className="links">{t("CommissionsFees")}</span>
              </a>
              <a
                href="#"
                // href={`https://cyborholding.com/${lang}/company-regulation/`}
              >
                <span className="links">{t("TermsCondition")}</span>
              </a>
              <a
                href="#"
                // href={`https://cyborholding.com/${lang}/privacy-policy/`}
              >
                {" "}
                <span className="links">{t("PrivacyPolicy")}</span>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FooterCommon;
