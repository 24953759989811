import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import NavBar from "@components/navbar/NavBar";
import Loader from "@components/loader/Loader";
import * as _ from "lodash";
import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import paymentsolutionlogo from "@images/paymentsolutionlogo.svg";
import "@features/onboarding/otp-screen/OtpScreenStyles.scss";
import { useTranslation } from "react-i18next";

function OtpScreen(props) {
  const { exchange, thememode } = props;
  const [otp, setotp] = useState("");
  const [validated, setValidated] = useState(false);
  const [error2, seterror2] = useState("");
  const [showeye, setshoweye] = useState(true);
  const { mobile, reset } =
    (history && history.state && history.state.state) || {};
  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      props.UserVerifyOTP({
        mobile,
        otp,
        exchangeId: props.exchangeId,
        navigation: props.history,
      });
    }
  };

  const { t } = useTranslation();
  // useEffect(() => {}, []);
  return (
    <div id="OtpScreen">
      <NavBar location={useLocation()} />
      <Container>
        <div className="outerbox whitebg">
          {/* <img
            alt=""
            className="img"
            src={
              thememode == 'light'
                ? exchange &&
                  `${process.env.REACT_APP_IMGLINK}${exchange.lightLogo}`
                : exchange &&
                  `${process.env.REACT_APP_IMGLINK}${exchange.darkLogo}`
            }
          /> */}
          <img src={paymentsolutionlogo} className=" img" />

          <div className="spanmsg">
            <span className="font">{t("wehavesentyouonetimepassword")}</span>
          </div>
          <Form noValidate validated={validated} onSubmit={onSendmsg}>
            <div className="formfieldholder">
              <InputField
                type="otp"
                placeholder={t("otp")}
                onChange={(e) => setotp(e.target.value)}
                value={otp}
                error={otp.length == 0 ? "ocbe" : "nv"}
                required
                classname={"bottomborder"}
              />
            </div>
            {(props.signInLoading ||
              props.resendotplaoding ||
              props.otpverifyloading) && <Loader />}
            <Col className="mt-1 greyText" md="auto">
              {t(`${props.signInError}`)}
            </Col>
            <div className="outerconforbtn">
              <CustomButton
                disable={
                  otp.length !== 6 ||
                  props.signInLoading ||
                  props.resendotplaoding ||
                  props.otpverifyloading
                }
                type="submit"
                name={t("verify")}
                filled={true}
                primary={true}
              />
            </div>
            <div className="outerconttxt">
              <span
                onClick={() => props.UserResendOtp({ mobile })}
                className="  pointer txtclass"
              >
                {t("ResendOTP")}
              </span>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = ({ app = {}, user = {} }) => {
  const otpverifyloading = _.get(user, "otpverifyloading", false);
  const signupfinish = _.get(user, "signupfinish", false);
  const exchangeId = _.get(app, "settings.exchange.id", "");
  const resendotplaoding = _.get(user, "resendotplaoding", false);
  const exchange = _.get(app, "settings.exchange", false);
  const thememode = _.get(app, "thememode", false);
  return {
    otpverifyloading,
    resendotplaoding,
    signupfinish,
    exchangeId,
    exchange,
    thememode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  UserVerifyOTP: (data) => dispatch({ type: "OtpverifyCalled", payload: data }),
  UserResendOtp: (data) => dispatch({ type: "resendotpCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpScreen);
