export default {
  PaymentReport: "Отчет об оплате",
  PaymentID: "Идентификатор платежа",
  InvalidPaymentID: "Неверный идентификатор платежа",
  Date: "Дата",
  InvalidDate: "Недействительная дата",
  Merchant: "Продавец",
  InvalidMerchant: "Недействительный продавец",
  CoinToAccept: "Монета для принятия",
  InvalidCoin: "Недействительная монета для принятия",
  Amount: "Количество",
  InvalidAmount: "Недопустимая сумма",
  CoinToConvert: "Монета для конвертации",
  InvalidCoinToConvert: "Недопустимая монета для конвертации",
  Status: "Статус",
  InvalidSatus: "Неверный статус",
  CreateReport: "Создать отчет",
  TransactionID: "номер транзакции",
  AmountTransaction: "Сумма транзакции",
  Сurrency: "Валюта",
  Convertedamount: "Конвертированная сумма",
  Сurrencyinformation: "Информация о валюте",
  Commission: "Комиссия",
  Recipient: "Получатель",
  Dealdate: "Дата сделки",
};
