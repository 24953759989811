import Drawer from 'react-motion-drawer';
import NavBar from '@components/navbar/NavBar';
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import Loader from '@components/loader/Loader';
import { Row, Col, Container } from "react-bootstrap";
import '@components/layout/ScreenLayoutStyles.scss';
import React, {useState, useEffect} from 'react'

const ScreenLayout = ({children, hideLoaderOnStart,loaderOnRefresh}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true)
    const onSwitch = () => {
        setOpen(!open)
    }

    const stopLoader = async () => {
        setTimeout( () => {
            setLoading(false);
        }, 2000);
    }

    useEffect(() => {
        stopLoader()
    }, [])
    

    return (
        <div className="screenMainWrapper">
            <div className="d-flex">
                <div className="sidebarCol d-lg-block d-none">
                    <LeftSidebar />
                </div>
                <div className="d-block d-lg-none">
                    <Drawer open={open} onChange={onSwitch} >
                        <LeftSidebar />
                    </Drawer>
                </div>
                <div className="screenContentCol">
                    <div className="screenContainer" >
                        <div className='Nav-Wrp'>
                            <NavBar onSwitch={onSwitch} />
                        </div>
                        <div className="screenContentContainer">
                            { hideLoaderOnStart !== true && loaderOnRefresh !==0 && loading && <Loader /> }
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScreenLayout
