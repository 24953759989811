import BranchItem from "@components/branch-item/BranchItem";
import "@components/branch/BranchStyles.scss";
import { Row, Col, Dropdown } from "react-bootstrap";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { getCSymbol } from "src/utils/Currencies";

function Branch({
  BranchpaymentList,
  mywalletsinfo,
  bnbdata,
  ethdata,
  btcdata,
  busddata,
  trxdata,
  trycdata,
  getplatformTokensinfo,
  PreferredCurrency
}) {
  const { t, i18n, ready } = useTranslation();

  let pieadata = [],
    colorsdata = ['#F7931A','#A93434','#26A17B','#26A17B','#D60000'],
    total=0,
    branches =[],
     l = "";
    BranchpaymentList &&
    BranchpaymentList.map((items,index) => {
       let {user,branchName,address} = items[0]||{}
       let mtotal = 0
      let mybal = 0
      let {usercoin} = user||{}
       usercoin&&usercoin.map(coins=>{         
       let bal = coins && coins.amount;
        let dec = getplatformTokensinfo.find(token=>token.id ==coins.coinId)?.decimals
        let val = bal / 10 ** dec;
      if (coins.tORcName == "Ethereum") {
        mybal = Number(val * Number(ethdata && ethdata.p).toFixed(2));
      }
      if (coins.tORcName  == "Bitcoin") {
        mybal = Number(val * Number(btcdata && btcdata.p).toFixed(2));
      }
      if (coins.tORcName  == "BSC") {
        mybal = Number(val * Number(bnbdata && bnbdata.p).toFixed(2));
      }
      if (coins.tORcName  == "CyborCoin") {
        mybal = Number(val * Number("1").toFixed(2));
      }
      if (coins.tORcName  == "Tron") {
        mybal = Number(val * Number(trxdata && trxdata.p).toFixed(2));
      }
      if (coins.tORcName  == "Tether") {
        mybal = Number(val * Number(busddata && busddata.p).toFixed(2));
      } 
      
      if (coins.tORcName== "TRYCoin") {
        if(trycdata && trycdata.p!=undefined)
        mybal = Number(val * BigNumber(1).dividedBy(trycdata && trycdata.p));
      }
      mtotal += mybal;
       total+=mybal
       
      })

       pieadata.push({
        id: `${user.id}`,
        label:branchName,
        value: mtotal,
        color: colorsdata[index],
      });
      branches.push({...user,branchName,address,mtotal})

    });

  return (
    <div className="Branch">
      <Row>
        <Col lg={6}>
          <h1 className="balance">{t("MyBranch")}</h1>
        </Col>
      </Row>

      <Row>
      <Col lg={12} xl={4}>
          <div className="piechartsection">
            <ResponsivePie
              data={pieadata}
              margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
              innerRadius={0.76}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#fff"
              arcLinkLabelsThickness={0}
              enableArcLabels={false}
              isInteractive={false}
              enableArcLinkLabels={false}
              // tooltip={function(e){var t=e.datum;return a.createElement(i,{style:{color:t.color}},a.createElement(s,null,"id"),a.createElement(u,null,t.id),a.createElement(s,null,"value"),a.createElement(u,null,t.value),a.createElement(s,null,"formattedValue"),a.createElement(u,null,t.formattedValue),a.createElement(s,null,"color"),a.createElement(u,null,t.color))}}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              colors={colorsdata}
              fill={[
                {
                  match: {
                    id: "ETH",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "CYBOR",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "BTC",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "USDT",
                  },
                  id: "dots",
                },
              ]}
            ></ResponsivePie>
            <div className="mybalinpie">
              <span className="pietxt"> {getCSymbol(PreferredCurrency)}{Number(total).toFixed(2)}</span>
              <span className="pietxt1">{t("TotalBalances")}</span>
            </div>
          </div>
          <div className="legendarea">
            {pieadata &&
              pieadata.map((o) => (
                <div className="legend">
                  <span
                    className="legendcolor"
                    style={{ "background-color": o.color }}
                  ></span>
                  <span className="legendname">{o.label}</span>
                </div>
              ))}
          </div>
        </Col>
        <Col>
          <Row>
            <Col lg={12}>
            {branches.map((item,index)=>  <BranchItem data={item} key={`branch${index}`} PreferredCurrency={PreferredCurrency}/>)}
               
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default Branch
