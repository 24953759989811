import * as _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import "@components/create-report-modal/CreateReportModal.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import moment from 'moment'
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import CustomButton from "@components/custom-button/CustomButton";
import Reporttable from "src/components/report-table/Reporttable";
import "jspdf-autotable";
import { ExportSheet } from "react-xlsx-sheet";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";

const CreateReportModal = ({
  setModal,
  modal,
  apiParams,
  componentRef,
  filterReportTable,
  parentCallback,
  onClickPdf,
  onClickExcel,
  excelHeader,
  download,
  excelData,
  XLSX,
}) => {
  const { t, i18n, ready } = useTranslation();

  var re = /[^a-zA-Z]+/;

  return (
    <Modal
      className="createreport"
      show={modal}
      onHide={() => setModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalheaderwrapper">
        <div>
          <p className="createreporttitle">{t("Createreport")}</p>
        </div>
        <div onClick={() => setModal(false)}>
          <CloseIcon className="closebtn" />
        </div>
      </div>
      <Modal.Body>
        <Container>
          <div>
            <p className="yourreporttext">{t("Yourreporthasbeengenerated")}</p>
          </div>
          <div>
            <Reporttable
              filterReportTable={filterReportTable}
              componentRef={componentRef}
              parentCallback={parentCallback}
              apiParams={apiParams}
            />
          </div>
          <div>
            <p className="downloadfiletext">{t("Downloadfile")}:</p>
            <div className="buttoncontainersm">
              <CustomButton
                type="submit"
                name="PDF"
                testvalue={<PdfIcon className="mleft" />}
                text={true}
                image={true}
                onClick={onClickPdf}
              />
            </div>
            <div className="buttoncontainersm">
              <ExportSheet
                header={excelHeader}
                fileName={download}
                dataSource={excelData}
                xlsx={XLSX}
              >
                <CustomButton
                  type="submit"
                  name="Excel"
                  testvalue={<ExcelIcon className="mleft" />}
                  text={true}
                  image={true}
                  
                />
              </ExportSheet>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CreateReportModal;
