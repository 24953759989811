import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import "@components/loader/LoaderStyles.scss";
function Loader({ msg, loading }) {
  const [loadingState, setLoadingState] = useState(loading);
  useEffect(() => {
    setLoadingState(loading);
    if (loading == undefined) {
      setLoadingState(true);
    }
    return () => {
      setLoadingState(false);
    };
  }, [loading, msg]);
  return (
    <>
      {loadingState && (
        <div className={`fadeloader fadeinloader`}>
          <div id="preloader" className={`fadeinpreloader`}>
            <div className="loader">
            </div>
            <div className="messageee">{msg}</div>
          </div>
        </div>
      )}
    </>
  );
}
export default Loader;
