import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

const ApplyTheme = ({
  localversion,
  updateVersion,
  settings,
  theme,
  children,
  getSettings,
}) => {
  const updateCSSVariables = () => {
    const { darkTheme, lightTheme, exchange } = settings || {};
    let arrayOfVariableKeys, arrayOfVariableValues;
    if (theme === "light") {
      arrayOfVariableKeys = Object.keys(lightTheme);
      arrayOfVariableValues = Object.values(lightTheme);
    } else {
      arrayOfVariableKeys = Object.keys(darkTheme);
      arrayOfVariableValues = Object.values(darkTheme);
    }
    arrayOfVariableKeys.forEach((cssVariableKey, index) => {
      document.documentElement.style.setProperty(
        `--${cssVariableKey}`,
        arrayOfVariableValues[index]
      );
    });
    updateVersion(exchange.version);
  };

  useEffect(() => {
    const { exchange } = settings || {};
    getSettings(process.env.REACT_APP_SITELINK);
    const { domain, version } = exchange || {};
    if (domain != process.env.REACT_APP_SITELINK) {
      getSettings(process.env.REACT_APP_SITELINK);
    } else {
      if (version != localversion) {
        updateCSSVariables();
      } else {
        updateCSSVariables();
      }
    }
  }, [theme]);

  return <Fragment>{children}</Fragment>;
};

const mapStateToProps = ({ app }) => ({
  theme: app.thememode,
  settings: app.settings,
  localversion: app.version,
});

const mapDispatchToProps = (dispatch) => ({
  getSettings: (data) => dispatch({ type: "getAppSettings", payload: data }),
  updateVersion: (data) => dispatch({ type: "updateVersion", payload: data }),
});
const connectedApplyTheme = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyTheme);

export default connectedApplyTheme;
