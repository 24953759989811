import Table from "react-bootstrap/esm/Table";
import ReactPaginate from "react-paginate";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import "@components/report-table/ReporttableStyles.scss";
import moment from "moment";
import React, { useState, useEffect } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import CoinList from "@components/coin-list/CoinList";
// import Loader from "@components/loader/Loader";
import BigNumber from "bignumber.js";
import { connect } from "react-redux";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";

function Reporttable({
  //   getidoList,
  getRountido,
  idotransaction,
  claimIdo,
  claimTransactionLoading,
  paymentReports,
  thememode,
  filterReportTable,
  paymentReportCallLoading,
  componentRef,
  parentCallback,
  paymentReport,
  apiParams,
  paymentCount,
}) {
  let [pages, setpages] = useState(0);
  let [showTable, setShowTable] = useState(false);
  let [limit, setLimit] = useState(10);
  let [tableToExport, setTableToExport] = useState([]);
  const [orderBy,setOrderBy] = useState('')
  const [sortBy,setSortBy] = useState('')
  const [obj,setObj] = useState('')
  let tableColumnMapper = {
    paymentId: "TransactionID",
    date: "Dealdate",

    // commission: 'Commission',

    convertedamount: "Convertedamount",
    cointoaccept: "Currency",

    amount: "Amount",

    cointoconvert: "Currency",

    merchant: "Merchant",

    status: "Status",
  };
  let sortIconMapper = {
    paymentId: true,
    date: true,

    // commission: 'Commission',

    convertedamount: false,
    cointoaccept: true,

    amount: false,

    cointoconvert: true,

    merchant: false,

    status: false,
  };

  let sortMapper = {
    paymentId: "id",
    date: "updatedOn",
    cointoaccept: "exchangeTo",
    cointoconvert: "fiatCurrency",
    // merchant: "M",
    // status: "Status",
  };
  useEffect(() => {
    if (paymentReports.length !== 0) {
       setTableToExport(paymentReports);
      callParentCallBack();
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  }, [paymentReports]);

  useEffect(() => {
    setTableToExport(paymentReports);
    callParentCallBack();
  }, [pages]);

  useEffect(() => {
    setTableToExport(paymentReports);
    callParentCallBack();
  }, []);

  const callParentCallBack = () => {
    console.log('callParentCallBack')
    parentCallback(paymentReports);
  };

  const { t, i18n, ready } = useTranslation();
  return (
    <div className="reporttable">
      {showTable === true ? (
        <>
          <Table
            responsive="sm"
            className="tablestyle"
            borderless
            ref={componentRef}
          >
            {/* {paymentReportCallLoading && <Loader />} */}
            <thead>
              <tr>
                {Object.keys(tableColumnMapper).map((i) => {
                   if (filterReportTable[i]) {
                    return (
                      <th className="tableheading">
                        {t(`${tableColumnMapper[i]}`)}{" "}
                        {sortIconMapper[i] == true ?
                        <span className="sorticon">
                        
                         <SortupIcon className="uparrow"  onClick={()=>{
                           setOrderBy(`${sortMapper[i]}`)
                           setSortBy('ASC');
                           paymentReport({
                            ...apiParams,
                            skip: pages* limit,
                            limit,
                          orderBy:`${sortMapper[i]}` ,
                          sort: 'ASC',
                          // obj: obj
                          });
                         }}
                         />
      
                         <SortdownIcon className="downarrow"
                         onClick={()=>{
                          setOrderBy(`${sortMapper[i]}`)
                          setSortBy('DESC');
                          paymentReport({
                           ...apiParams,
                           skip: pages* limit,
                           limit,
                         orderBy:`${sortMapper[i]}` ,
                         sort: 'DESC',
                         // obj: obj
                         });
                        }}
                         />
                        </span>:""
                  }
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {paymentReports.length !== 0 &&
                paymentReports.map((pt) => (
                  <tr className="maintdwrapper">
                    {filterReportTable.paymentId && (
                      <td className="colspace">
                        <div className="innerTd">
                          <div className="moreInnerTd minwidth">{pt.id}</div>
                        </div>
                      </td>
                    )}

                    {filterReportTable.date && (
                      <td className="colspace">
                        <div className="innerTd">
                          <div className="moreInnerTd">
                            {moment(pt.updatedOn)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </div>
                      </td>
                    )}

                    {filterReportTable.convertedamount && (
                      <td className="colspace">
                        <div className="innerTd">
                          <div className="moreInnerTd minwidth1">
                            {pt.paymentStatus == "Paid"
                              ? `${Number(
                                  pt &&
                                    pt.userexchange &&
                                    pt.userexchange.total /
                                      10 ** pt.toPaymentLinkCoin.decimals
                                ).toFixed(5)}`
                              : "-"}
                          </div>
                        </div>
                      </td>
                    )}
                    {filterReportTable.cointoaccept && (
                      <td className="colspace">
                        <div className="innerTd">
                          <div className="moreInnerTd">
                            <CoinList
                              type={pt.toPaymentLinkCoin.type}
                              logo={pt.toPaymentLinkCoin.nonColouredLogo}
                              name={pt.toPaymentLinkCoin.tokenName}
                              logoBgColor={pt.toPaymentLinkCoin.logoBgColor}
                            />
                          </div>
                        </div>
                      </td>
                    )}
                    {filterReportTable.amount && (
                      <td className="colspace">
                        <div className="innerTd">
                          <div className="moreInnerTd minwidth">
                            {pt.currencyAmount}
                          </div>
                        </div>
                      </td>
                    )}

                    {filterReportTable.cointoconvert && (
                      <td className="colspace">
                        <div className="innerTd minwidth2">
                          <CoinList
                            type={pt?.currency?.type}
                            logo={pt?.currency?.nonColouredLogo}
                            name={pt?.currency?.currencyName}
                            logoBgColor={pt?.currency?.logoBgColor}
                          />
                        </div>
                      </td>
                    )}

                    {filterReportTable.merchant && (
                      <td className="colspace">
                        <div className="innerTd">
                          <div className="moreInnerTd">
                            {pt.merchant
                              ? pt.merchant.name
                                ? pt.merchant.name
                                : ""
                              : ""}
                          </div>
                        </div>
                      </td>
                    )}
                    {filterReportTable.status && (
                      <td className="colspace">
                        <div className="innerTd">
                          <div className="moreInnerTd status">
                            {t(`${pt.paymentStatus}`)}
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* {idotransaction.length > 10 && ( */}
          <div className="paginationsectionforidotable">
            <div className="paginationallignmentfortable">
              <ReactPaginate
                previousLabel={<PreviousIcon className="paginationicon" />}
                nextLabel={<NextIcon className="paginationicon" />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={paymentCount && parseInt(paymentCount) / limit}
                // forcePage={pages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={(e) => {
                  paymentReport({
                    ...apiParams,
                    skip: e.selected * limit,
                    limit,
                  });
                  // setpages(e.selected)
                }}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          {/* )} */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

const mapStateToProps = ({ ido, wallet, app, paymentlink = {} }) => {
  const idotransaction = _.get(ido, "idotransaction", []);

  const claimTransactionLoading = _.get(ido, "claimTransactionLoading", false);
  const thememode = _.get(app, "thememode", false);
  const paymentReports = _.get(paymentlink, "paymentReports", []);
  const paymentCount = _.get(paymentlink, "paymentCount", undefined);
  const paymentReportCallLoading = _.get(
    paymentlink,
    "paymentReportCallLoading",
    false
  );

  return {
    idotransaction,
    thememode,
    claimTransactionLoading,
    paymentReports,
    paymentReportCallLoading,
    paymentCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  claimIdo: (data) => dispatch({ type: "claimIdoCalled", payload: data }),
  getidoList: (data) =>
    dispatch({ type: "getidoListbyaddresscalled", payload: data }),
  paymentReport: (data) => dispatch({ type: "paymentReport", payload: data }),

  getRountido: (data) => dispatch({ type: "getRountidocalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reporttable);
