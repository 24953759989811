export default {
  My: "",
  Balances: "Bakiyelerim",
  Deposit: "Yarırım",
  Withdraw: "Çekim",
  Exchange: "Döviz",
  Balance: "Bakiye",
  Price: "Fiyat",
  Changes: "Değişim",
  SelectAmountToWithdraw: "Çekilecek Tutarı Seçin",
  Chart: "Çizelge",
  ExchangeRate: "Döviz kuru",
  Commission: "Komisyon",
  InsufficientBalance: "Yetersiz bakiye",
  From: "Satış",
  Cancel: "İptal",
  Confirm: "Onayla",
  To: "Alış",
  BankTransfer: "Banka transferi",
  TLWallet: "TL Cüzdan",
  List1:
    "Türk Lirası'nı Akbank, Denizbank, Fibabanka, Vakıfbank, Yapı Kredi veya Ziraat Bankası'ndan istediğiniz zaman PayCrypt hesabınıza yatırabilirsiniz.",
  List2:
    "Diğer bankalardaki hesaplarınızdan Akbank, Denizbank, Fibabanka, Yapı Kredi ve Ziraat Bankası hesaplarımıza EFT gün ve saatlerinde Türk Lirası yatırabilirsiniz. Mesai saatleri dışında yapacağınız EFT işlemler ardından gelen ilk iş günü içerisinde gerçekleşecektir. ",
  List3:
    "Türkiye Cumhuriyet Merkez ATM'sinden (TCMB) devreye alınan FAST (Fonların Anlık ve Sürekli Transferi) ödeme sistemi ile FAST ödeme sistemine girilen banka hesaplarınızdan 7 gün 24 saat PayCrypt'e Türk Lirası yatırabilirsiniz. FAST ile para transfer limitleri ve işlem ücretleri için ATM'niz ile görüşebilirsiniz.",
  List4:
    "TL para yatırma işlemi yaparken gönderenin ad, soyad ve kimlik numarası bilgilerinin PayCrypt hesabındaki verilerle eşleşmesi gerektiğini hatırlatırız.",
  Click: "Tıkla",
  DetailsOnTL: "TL para yatırım ve çekim işlemleri ile ilgili detaylar için",
  Receive: "Yarırım",
  Share: "Paylaş",
  Amount: "Miktar",
  Copied: "Kopyalandı",
  Copy: "Kopyala",
  SelectCoin: "Coin Seç",
  AddressToWithdraw: "Gönderilecek Adres",
  MAX: "MAX",
  SearchHere: "Ara",
  Withdrawal: "Çekim",
  to: "Buraya",
  Wallet: "Cüzdan",
  Continue: "Devam et",
  SelectCurrency: "Para birimini seçin",
  SelectAmountToWithdraw: "Çekilecek Tutarı Seçin",
  ServiceFee: "Servis ücreti",
  AddNewAccount: "Yeni Hesap Ekle",
  WrittenAmount: "5000 PayCrypt = 124.811$",
  Function: "İşlev",
  Functions: "İşlevseller",
  OnlyCyborCoin:
    "Bu adrese sadece PayCrypt Coin (CYBOR) gönderin. Başka herhangi bir kripto para gönderimi, geri dönüşü olmayan kayıplara neden olabilir.",
  PendingApproval: "(Onay bekleniyor)",
  Withdrawalfees: "Çekim ücretleri",
  withdrawal: "Çekim",
  limit: "limitler",
  is: "",
  Or: "Veya",
  YouWillRecieve: "Alınacak",
  MyAccounts: "Hesaplarım",
  MyBalances: "Bakiyelerim",
  MyWallets: "Cüzdanlarım",
  VerifiedAccount: "Hesap Doğrulandı",
  UnverifiedAccount: "Doğrulanmamış Hesap",
};
