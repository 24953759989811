import React from "react";
import "@components/small-button/SmallButtonStyles.scss";
import { ReactComponent as UpIcon } from "@images/depoimg.svg";
import { ReactComponent as DownIcon } from "@images/wiimg.svg";
import { ReactComponent as ExchangeIcon } from "@images/eximg.svg";
import { useTranslation } from "react-i18next";

const SmallButton = ({ type, onclick, hideButtonName }) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="optionholder">
      <div className="holdercss" onClick={onclick}>
        {type == "deposit" ? (
          <UpIcon className="arrowclass" />
        ) : type == "withdraw" ? (
          <DownIcon className="arrowclass" />
        ) : (
          <ExchangeIcon className="arrowclass" />
        )}
      </div>
      {
        !hideButtonName &&
        <div className="commtxt">
        {type == "deposit"
          ? t("Deposit")
          : type == "withdraw"
          ? t("Withdraw")
          : t("Exchange")}
        </div>
      }
      
    </div>
  );
};

export default SmallButton;
