export default {
  Balances: "Балансы",
  CopyWalletNumber: "просто скопируйте номер кошелька",
  MarketRate: "Рыночная стоиомсть",
  BasedOn: "Основано на",
  Volume: "Объем",
  MarketCap: "Рыночная капитализация",
  WalletNumber: "номер кошелька",
  AllType: "Все Виды",
  EstimateBalance: "Текущий баланс",
  HideZeroBalance: "Скрыть нулевые балансы",
  Filter: "Фильтр",
  Reset: "Сброс",
  AddBankConfirmed: "Добавление банка подтверждено !",
  "amount should not be empty": "Необходимо указать сумму.",
  NoResultsFound: "Результаты не найдены",
  exchange_amt:"Сумма обмена ",
  AmountL:'Количество',
  T0: "Чтобы",
  "Internal server error": "Внутренняя ошибка сервера",
  Pleaseenteramount: "Пожалуйста, введите сумму",
  DetermineamounttoDepositorExchange: "Определить сумму депозита или суммы обмена",
  ReceiveLeft: "Получать",
  ReceiveRight: " ",
};
