import apisauce from "apisauce";
import _ from "lodash";
const baseUrl = process.env.REACT_APP_APILINK;
const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 200000,
  });
  const GetSettings = (data) =>
    apis.get(`/manager/getexchangedetail?exchangeUrl=${data}`);

  const UsersignIn = (data) => {
    return apis.post("/auth/user/login", data);
  };
  const UsersignUp = (data) => apis.post("/auth/user/signup", data);

  const UserForgotpassword = ({ data }) => {
    return apis.post("/auth/user/request-password-reset", data);
  };

  const UserResetPasswordEmail = (data) => {
    return apis.get("/auth/user/reset-password", data);
  };

  const UserSetNewPasswordMobile = (data) =>
    apis.post("/auth/user/set-new-passwordmobile", data);

  const UserSetNewPasswordMob = (data) =>
    apis.post("/auth/user/set-new-passwordmob", data);

  const getVerifyEmailcode = (data) =>
    apis.post("/auth/user/verify-email-code", data);
  const confirmEmailcode = (data) =>
    apis.get(
      `/auth/user/confirm-code?email=${data.email}&token=${data.code}&exchangeId=${data.exchangeId}`
    );

  const UserSetNewPasswordEmail = (data) =>
    apis.post("/auth/user/set-new-password", data);

  const UserVerifyEmail = (data) => apis.post("/auth/user/verify-email", data);

  const UserConfirmEmail = (data) => apis.get("/auth/user/confirm", data);
  const validateCaptcha = (data) => {
    console.log("data for backend for geetest", data);
    apis.get(
      `/user/captcha?lotNumber=${data.lot_number}&passToken=${data.pass_token}&genTime=${data.gen_time}&captchaOutput=${data.captcha_output}`
    );
  };

  const setupGoogleAuth = (data) => {
    return apis.post(
      `/auth/user/setupgoogleauthenticator`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const removeprofileImage = (data) => {
    return apis.patch(
      `/auth/user/remove-profile-image`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const verifyGoogleAuth = (data) => {
    return apis.post(
      `/auth/user/verifyauthenticator`,
      { token: data.verifyToken },
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const VerifyToken = (data) => {
    return apis.post(
      `/auth/manager/verifytoken`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const ProfileImageUpload = (data) => {
    return apis.post("/auth/user/profile-image", data.data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${data.token}`,
        Accept: "Application/json",
      },
    });
  };

  const UpdateProfile = (data) => {
    return apis.patch("/auth/user/editProfile", data.payload, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };
  const UpdateBranchProfileImage = (data) => {
    return apis.patch(
      `/user/branchManagement/editBranchImage/${data.branchId}`,
      data.formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const UserVerifyOTP = (data) => apis.post("/auth/user/verify-otp", data);

  const UserResendOtp = (data) => apis.post("/auth/user/resendOtp", data);

  const UserCreateCryptoWallet = (data) => {
    // console.log('data',data)
    return apis.post(
      `/auth/user/createcryptowallet`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const UserCreateFiatWallet = (data) => {
    // console.log('data',data)
    return apis.post(
      `/auth/user/createfiatwallet`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  //manager

  const RegisterPlatformAdmin = (data) =>
    apis.post("/auth/manager/register-as-admin", data);

  const ManagerForgotpassword = (data) =>
    apis.post("/auth/manager/request-password-reset", data);

  const ManagerEmailVerify = (data) =>
    apis.get("/auth/manager/reset-password", data);

  const ManagerSetNewPasswordMobile = (data) =>
    apis.post("/auth/manager/set-new-passwordmobile", data);

  const ManagerSetNewPasswordMob = (data) =>
    apis.post("/auth/manager/set-new-passwordmob", data);

  const ManagerSetNewPasswordEmail = (data) =>
    apis.post(
      "/auth/manager/set-new-password?email=${data.email}&token=${data.token}",
      data
    );

  const ManagerVerifyEmail = (data) =>
    apis.post("/auth/manager/verify-email", data);

  const ManagerConfirmEmail = (data) =>
    apis.get(
      "/auth/manager/confirm?token=${data.token}&email=${data.email}&exchangeId=${data.exchangeId}",
      data
    );

  const ManagerVerifyOTP = (data) =>
    apis.post("/auth/manager/verify-otp", data);

  const ManagerResendOtp = (data) => apis.post("/auth/manager/resendOtp", data);

  const ManagersignIn = (data) => {
    return apis.post("/auth/manager/login", data);
  };

  const AddSuperManager = (data) => {
    // console.log('data',data)
    return apis.get(
      `/auth/manager/add`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  //Platform user/manager
  const addExchangeBySuperManager = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/manager/addexchange`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const addPlatformToken = (data) => {
    const formData = new FormData();

    formData.append("nonColouredIcon", data.file);

    formData.append("colouredIcon", data.file);

    const { token } = data;
    delete data["token"];
    return apis.post(`/manager/addtoken`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  //Platform user/manager/platform
  const getPlatformToken = (data) => {
    let link = "";
    if (data.skip) link += `skip=${data.skip}`;
    if (data.limit) link += `&limit=${data.limit}`;
    if (data.orderBy) {
      link += `&orderBy=${data.orderBy}`;
    } else {
      link += `&orderBy=tokenName`;
    }
    if (data.sort) {
      link += `&sort=${data.sort}`;
    } else {
      link += `&sort=ASC`;
    }
    if (data.tOrcName) link += `&tOrcName=${data.tOrcName}`;

    const { token } = data;
    return apis.get(
      `/platform/getcoins?${link}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };
  const getPlatformCurrency = (data) => {
    let link = "";
    if (data.skip) link += `skip=${data.skip}`;
    if (data.limit) link += `&limit=${data.limit}`;
    if (data.Name) link += `&tOrcName=${data.Name}`;
    if (data.orderBy) {
      link += `&orderBy=${data.orderBy}`;
    } else {
      link += `&orderBy=currencyName`;
    }
    if (data.sort) {
      link += `&sort=${data.sort}`;
    } else {
      link += `&sort=ASC`;
    }

    const { token } = data;
    return apis.get(
      `/platform/getcurrency?${link}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  //Platform user/user
  const getMywallets = (data) => {
    return apis.get(
      `/user/getmywallets`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getAccountByWalletId = (data) => {
    return apis.get(
      `/user/getmywalletaccounts/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getAccountAndBalanceByWalletId = (data) => {
    let link = "";
    if (data.skip) link += `skip=${data.skip}`;
    if (data.limit) link += `&limit=${data.limit}`;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data.tOrcName) link += `&tOrcName=${data.tOrcName}`;

    return apis.get(
      `/user/getaccountsandbalance/${data.id}?${link}`, //convertedTo=USDT
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const depositwithHistory = (data) => {
    let link = "";
    if (data.limit) link += `&limit=${data.limit}`;
    // if (data && data.type)

    if (data.currencyType) link += `&currencyType=${data.currencyType}`;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data && data.startDate)
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate)
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    return apis.get(
      `/user/transactions/${data.walletId}?skip=${data.skip}${link}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const depositwithHistoryDownload = (data) => {
    let link = "";
    if (data.skip) link += `?skip=${data.skip}`;
    if (data.limit) link += `&limit=${data.limit}`;
    // if (data && data.type)

    if (data.currencyType) link += `&currencyType=${data.currencyType}`;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data && data.startDate)
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate)
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    return apis.get(
      `/user/transactions/${data.walletId}${link}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  //*
  const getCoinOrTokenBalance = (data) => {
    return apis.get(
      `/user/balance?tokenName=${data.tokenName}&address=${data.address}&walletId=${data.walletId}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const activateCoinOrToken = (data) => {
    const { token } = data;
    delete data["token"];

    return apis.post(`/user/activatecoinortoken`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const transferBalanceOrToken = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(
      `/user/transferbalanceortoken?emailCode=${data.emailCode}&authenticatorCode=${data.authenticatorCode}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  const selectRegionAndLanguage = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/user/selectregionandlanguage`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const getExchangeHistory = (data) => {
    const { token, walletId } = data;
    let link = "";
    if (data && data.startDate)
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data && data.endDate)
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    if (data.limit) link += `&limit=${data.limit}`;
    if (data && data.type) link += `&type=${data.type}`;
    delete data["token"];
    return apis.get(
      `user/exchangehistory?skip=${data.skip}${link}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  //Platform user/user/exchange

  const exchange = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/user/exchange`, data, {
      headers: { Authorization: `Bearer ${token}`, Accept: "Application/json" },
    });
  };

  //Platform user/user/fiat

  const getFiatBalance = (data) => {
    const { token, walletId } = data;
    delete data["token"];
    return apis.get(
      `/user/fiat/balance/${walletId}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const activateCurrency = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/user/fiat/activatecurrency`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const deleteBankAccountFiat = (data) => {
    const { token, id } = data;
    return apis.delete(
      `user/fiat/deleteBankAccount/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  //Platform user/user/paymentLink
  const generatePaymentLink = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/user/paymentlink/generatelink`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const getPaymentLinkData = (data) => {
    const { token, tokenLink } = data;
    delete data["token"];
    return apis.get(
      `/user/paymentlink/getdatafromlink?tokenLink=${tokenLink}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const transferThroughPaymentLink = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/user/paymentlink/transferthroughlink`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const addLogo = (data) => {
    const formData = new FormData();

    formData.append("image", data.file);

    const { token } = data;
    delete data["token"];
    return apis.post(`/manager/addlogo/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const getMyProfile = (data) => {
    return apis.get(
      `user/myprofile?fiatWalletId=${data.fiatWalletId}&cryptoWalletId=${data.walletId}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getFiatBalanceByWalletId = (data) => {
    const { token, walletId } = data;
    delete data["token"];
    return apis.get(
      `/user/fiat/balance/${walletId}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const ActivateFiatCurrency = (data) => {
    const { token, walletId, name, currencyId } = data;
    delete data["token"];
    return apis.post(`/user/fiat/activatecurrency`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const addexchangeinfoColors = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/manager/addexchangeinfo/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };
 

  const depositwithHistoryCount = (data) => {
    const { token, walletId } = data;
    let link = "";
    if (data && data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data && data.endDate)
      link += `endDate=${new Date(data.endDate).toISOString()}&`;
    link += `currencyType=${data.currencyType}&`;
    delete data["token"];
    return apis.get(
      `/user/transactionCounts/${walletId}?${link}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const exchangeHistoryCount = (data) => {
    const { token, walletId, startDate, endDate } = data;
    let link = "";
    if (startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (endDate) link += `endDate=${new Date(data.endDate).toISOString()}&`;
    delete data["token"];
    return apis.get(
      `/user//exchangehistoryCount${(link && `?${link}`) || ""}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const exchangeRate = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.get(
      `/binance/account/exchangeRate?symbol1=${data.symbol1}&symbol2=${data.symbol2}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const createIDORound = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/ido/createRound`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const createIDOTrans = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/ido/createIdoTransaction`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const buyCyborcoinFromIdo = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/ido/BuyCyborcoinFromIdo`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const getIDOround = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.get(
      `/ido/listRounds`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getcyborExchangeRate = (data) => {
    const { token, token1, id } = data;
    delete data["token"];
    return apis.get(
      `/ido/cyborExchangeRate?token1=${token1}&idoRoundId=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getidolistbyaddress = (data) => {
    const { token, address } = data;
    delete data["token"];
    return apis.get(
      `/ido/listTransactions`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const claimiodTrans = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.patch(`/ido/claimTokens/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const getpaymenthistory = (data) => {
    // console.log("Payment history api called");
    // console.log('data from paymenthistory' , data);
    let link = "";

    if (data && data.limit) link += `&limit=${data.limit}`;

    if (data && data.type) link += `&paymentStatus=${data.type}`;
    if (data && data.startDate && !_.isEmpty(data.startDate))
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate && !_.isEmpty(data.endDate))
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data.obj) link += `&obj=${data.obj}`;

    const { token, id } = data || {};
    // console.log('token , id' ,token ,id);

    delete data["token"];
    return apis.get(
      `/user/paymentlink/paymenthistory/${id}?skip=${data.skip}${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getpaymenthistorycount = (data) => {
    const { token, id } = data;
    let link = "";
    if (data && data.type) link += `paymentStatus=${data.type}&`;
    if (data && data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data && data.endDate)
      link += `endDate=${new Date(data.endDate).toISOString()}&`;
    if (data.coinToConvert) link += `coinToConvert=${data.coinToConvert}&`;
    if (data.coinToAccept) link += `coinToAccept=${data.coinToAccept}`;

    delete data["token"];
    return apis.get(
      `/user/paymentlink/paymenthistoryCount/${id}?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const gettransferhistorycount = (data) => {
    const { token, id, startDate, endDate, type } = data;
    delete data["token"];
    let link = "";
    if (startDate) link += `&startDate=${new Date(startDate).toISOString()}`;
    if (endDate) link += `&endDate=${new Date(endDate).toISOString()}`;
    return apis.get(
      `/user/transfersCount/${id}?type=${type}${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const gettransferhistory = (data) => {
    let link = "";
    if (data.limit) link += `&limit=${data.limit}`;
    if (data && data.startDate)
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate)
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    if (data && data.type) link += `&type=${data.type}`;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data.obj) link += `&obj=${data.obj}`;
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/user/transfers/${id}?skip=${data.skip}${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  // fiat transaction api called start
  const getfiattransaction = (data) => {
    let link = "";
    if (data.skip !== undefined) link += `skip=${data.skip}`;
    if (data.limit) link += `&limit=${data.limit}`;
    link += `&currencyType=Fiat`;
    if (data && data.startDate && !_.isEmpty(data.startDate))
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate && !_.isEmpty(data.Date))
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/user/transactions/${id}?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const getfiattransactioncount = (data) => {
    const { token, id, type } = data;
    delete data["token"];
    let link = "";

    if (data && data.startDate)
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate)
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    link += `&currencyType=Fiat`;

    return apis.get(
      `/user/transactionCounts/${id}?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getalltransaction = (data) => {
    let link = "";
    if (data.skip !== undefined) link += `skip=${data.skip}`;
    if (data.limit) link += `&limit=${data.limit}`;
    if (data && data.startDate)
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate)
      link += `&endDate=${new Date(data.endDate).toISOString()}`;
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/user/transactions/${id}?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const getalltransactioncount = (data) => {
    const { token, id, type } = data;
    delete data["token"];
    let link = "";

    if (data && data.startDate)
      link += `&startDate=${new Date(data.startDate).toISOString()}`;
    if (data && data.endDate)
      link += `&endDate=${new Date(data.endDate).toISOString()}`;

    return apis.get(
      `/user/transactionCounts/${id}?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  // fiat transaction api called end
  const getroundcount = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/ido/roundsCount`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const gettransactionCount = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/ido/transactionCount`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const claimtokenCount = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/ido/claimtokenCount`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const allTransactions = (data) => {
    let link = "";
    const { token, id } = data;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data.obj) link += `&obj=${data.obj}`;

    delete data["token"];
    return apis.get(
      `/ido/allTransactions?skip=${data.skip}&limit=${data.limit}${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const sendtokenCount = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/ido/sendtokenCount`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const addBank = (data) => {
    const formData = new FormData();

    formData.append("nonColouredIcon", data.file);
    formData.append("colouredIcon", data.file);

    const { token } = data;
    delete data["token"];
    return apis.post(`/manager/addBank`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const getwithdrawFA = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(
      `/user/verify-withdrawal-code`,
      {
        lang: data.lang,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getAllBanks = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.get(
      `/platform/getBank`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getcommission = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `manager/getcommission?Type=exchange`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getcommissionPercentage = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/manager/getcommission?Type=payment`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getBank = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.get(
      `/platform/getBank`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const getIDOBalance = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.get(
      `/ido/IdoBalance1`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const depositFiat = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post(`/user/fiat/requestFiatDeposit`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };
  const editRoundPrivacy = (data) => {
    const { token, roundId } = data;
    delete data["token"];
    return apis.patch(`/ido/editRoundPrivacy/${roundId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const getBusinessRequest = (data) => {
    const { token, status } = data;
    delete data["token"];
    return apis.get(
      `/platform/getBusinessRequest?approvalRequest=${status}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const businessForm = (data) => {
    return apis.post(`/auth/user/businessForm`, data.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${data.token}`,
        Accept: "Application/json",
      },
    });
  };

  const BusinessRequestApproval = (data) => {
    delete data["token"];
    return apis.patch(
      `/platform/businessRequestVerification/${data.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  /* Change password internally api call */
  const ChangePasswordApiCall = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.post("auth/user/newPasswordInternally", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  /* Create branch api call */

  const CreateBranch = (data) => {
    //return data
    const { token } = data;
    delete data["token"];

    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("branchName", data.branchName);
    formData.append("address", data.address);
    formData.append("phone", data.phone);
    formData.append("status", data.status);
    formData.append("id", data.id);
    formData.append("accountName", data.accountName);
    formData.append("email", data.email);
    formData.append("exchangeId", parseInt(data.exchangeId));
    formData.append("password", data.password);
    formData.append("lang", data.lang);

    return apis.post("user/branchManagement/addBranch", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  /* List All Branches */

  const ListAllBranches = (data) => {
    let link = "";
    if (data && data.orderBy) link += `orderBy=${data.orderBy}`;
    if (data && data.sort) link += `&sort=${data.sort}`;

    return apis.get(
      `user/branchManagement/getAllBranch?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  };

  /* Delete Branch */

  const DeleteBranch = (data) => {
    const { token, branchId } = data;
    delete data["token"];
    return apis.delete(
      `user/branchManagement/${parseInt(branchId)}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  /* Edit Branch */

  const EditBranch = (data) => {
    const { token, branchId } = data;
    delete data["token"];
    delete data["branchId"];

    return apis.patch(
      `user/branchManagement/editBranch/${parseInt(branchId)}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const updateBranchStatus = (data) => {
    const { token, branchId } = data;
    delete data["token"];
    delete data["branchId"];

    return apis.patch(
      `user/branchManagement/changeBranchstatus/${parseInt(branchId)}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  /* Create Fiat Withdrawl Request */

  const CreateFiatWithDrawlRequest = ({ token, withDrawlDetails }) => {
    return apis.post("user/fiat/requestFiatWithdrawal", withDrawlDetails, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  /* Call Fiat Balance */

  const CallFiatBalance = ({ token, id }) => {
    return apis.get(
      `user/fiat/balance/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  /* Call All Active Bank Accounts */

  const GetAllBankAccounts = ({ token }) => {
    return apis.get(
      "user/fiat/getMyBankAccounts",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getBranchpaymentList = ({ token, id }) => {
    return apis.get(
      `/user/branchManagement/getMyBranchBalance`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  /* Call Create Bank Account Api */

  const CreateBankAccount = (data) => {
    console.log("dtttt", data);
    const { token } = data;
    delete data["token"];
    return apis.post("user/fiat/AddBankAccount", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  /* Cancel Payment Call */
  const CancelPaymentCall = (data) => {
    const { token, paymentID, status } = data;
    return apis.patch(
      `user/paymentlink/cancelPayment/${paymentID}`,
      { status: status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  // get requested bank account verifications
  const requestedAccountVerification = (data) => {
    const { token, skip, limit, status, startDate, endDate } = data;
    let link = "";
    if (skip !== undefined) link += `skip=${skip}&`;
    if (limit) link += `limit=${limit}&`;
    if (status) link += `status=${status}&`;
    if (startDate) link += `startDate=${new Date(startDate).toISOString()}&`;
    if (endDate) link += `endDate=${new Date(endDate).toISOString()}`;

    return apis.get(
      `platform/getAccountsRequestedForVerification?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // get Users Fiat deposit request
  const usersFiatDepositRequest = (data) => {
    const { token } = data;
    let link = "";
    if (data.type) link += `Type=${data.type}&`;
    if (data.status) link += `status=${data.status}&`;
    if (data.bankName) link += `bankName=${data.bankName}&`;
    if (data.AccountNumber) link += `AccountNumber=${data.AccountNumber}&`;
    if (data.truee) link += `true=${data.truee}&`;
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    if (data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data.endDate) link += `endDate=${new Date(data.endDate).toISOString()}`;
    return apis.get(
      `platform/GetallFiatDepositRequest?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // get business requests
  const businessRequest = (data) => {
    const { token, approvalRequest, startDate, endDate, skip, limit } = data;
    let link = "";
    if (approvalRequest) link += `approvalRequest=${approvalRequest}&`;
    if (startDate) link += `startDate=${new Date(startDate).toISOString()}&`;
    if (endDate) link += `endDate=${new Date(endDate).toISOString()}&`;
    if (skip !== undefined) link += `skip=${skip}&`;
    if (limit) link += `limit=${limit}`;
    return apis.get(
      `platform/getBusinessRequest?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  //get business request count

  const businessRequestCount = (data) => {
    const { token, approvalRequest, startDate, endDate, skip, limit } = data;
    let link = "";
    if (approvalRequest) link += `approvalRequest=${approvalRequest}&`;
    if (startDate) link += `startDate=${new Date(startDate).toISOString()}&`;
    if (endDate) link += `endDate=${new Date(endDate).toISOString()}&`;
    if (skip !== undefined) link += `skip=${skip}&`;
    if (limit) link += `limit=${limit}`;
    return apis.get(
      `platform/getBusinessRequestCount?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // get bank account counts

  const bankAccountCount = (data) => {
    const { token, startDate, endDate, status } = data;
    let link = "";
    if (status) link += `status=${status}&`;
    if (startDate) link += `startDate=${new Date(startDate).toISOString()}&`;
    if (endDate) link += `endDate=${new Date(endDate).toISOString()}&`;

    return apis.get(
      `platform/getAccountsRequestedForVerificationCount?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // get Users withdraw deposit request
  const usersFiatWithdrawRequest = (data) => {
    const { token } = data;
    let link = "";
    if (data.status) link += `status=${data.status}&`;
    if (data.bankName) link += `bankName=${data.bankName}&`;
    if (data.AccountNumber) link += `AccountNumber=${data.AccountNumber}&`;
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    if (data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data.endDate) link += `endDate=${new Date(data.endDate).toISOString()}`;
    return apis.get(
      `platform/GetallFiatDepositRequest?Type=withdraw&${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // verification of users request for bank account verification
  const VerifyUserAccountRequest = (data) => {
    const { token, id, status } = data;
    return apis.patch(
      `manager/verifyUserBankAccount/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // verify users deposit request
  const VerifyUserDepositRequest = (data) => {
    let { token, id, rel, status, note } = data;
    rel = JSON.stringify(rel);
    return apis.patch(
      `manager/verifyUsersRequestForDeposit/${id}?relations=${rel}`,
      {
        status,
        note,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // verify withdraw request
  const VerifyUserWithdrawRequest = (data) => {
    let { token, id, rel, status, note } = data;
    rel = JSON.stringify(rel);
    return apis.patch(
      `manager/verifyUsersRequestForWithdrawal/${id}?relations=${rel}`,
      {
        status,
        note,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // approve business Requests
  const ApproveBusinessRequest = (data) => {
    const { token, id, relations, status, note } = data;
    return apis.patch(
      `platform/businessRequestVerification/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // withdraw request count
  const withdrawRequestCount = (data) => {
    const { token } = data;
    let link = "";
    if (data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data.endDate)
      link += `endDate=${new Date(data.endDate).toISOString()}&`;
    if (data.type) link += `Type=${data.type}&`;
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    if (data.status) link += `status=${data.status}&`;
    return apis.get(
      `platform/GetallWithdrwalRequestCount?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // withdraw request count
  const depositRequestCount = (data) => {
    const { token } = data;
    let link = "";
    if (data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data.endDate)
      link += `endDate=${new Date(data.endDate).toISOString()}&`;
    if (data.type) link += `Type=${data.type}&`;
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    if (data.status) link += `status=${data.status}&`;
    return apis.get(
      `platform/GetallFiatDepositRequestCount?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // get users list
  const getUsersList = (data) => {
    const { token } = data;
    let link = "";
    if (data.role) link += `role=${data.role}&`;
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    if (data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data.endDate) link += `endDate=${new Date(data.endDate).toISOString()}`;
    return apis.get(
      `manager/getusers?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // withdraw request count
  const getUsersListCount = (data) => {
    const { token } = data;
    let link = "";
    if (data.startDate)
      link += `startDate=${new Date(data.startDate).toISOString()}&`;
    if (data.endDate)
      link += `endDate=${new Date(data.endDate).toISOString()}&`;
    if (data.role) link += `role=${data.role}&`;
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    return apis.get(
      `manager/getuserscount?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // set users status
  const setUsersStatus = (data) => {
    const { token, id, status } = data;
    return apis.patch(
      `manager/changeuserstatus/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // Getting all address bookings
  const getAllAddressBooks = (data) => {
    const { skip, limit, sort, Name, address, token } = data;
    let link = "";
    if (sort) link += `approvalRequest=${approvalRequest}&`;
    if (Name) link += `startDate=${new Date(startDate).toISOString()}&`;
    if (address) link += `endDate=${new Date(endDate).toISOString()}&`;
    if (skip !== undefined) link += `skip=${skip}&`;
    if (limit) link += `limit=${limit}`;
    return apis.get(
      `user/addressBook/getAllAddress?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // Add address book
  const addAddressBook = (data) => {
    const { token } = data;
    delete data["token"];

    let formData = new FormData();
    formData.append("name", data.Name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("status", data.status);
    formData.append("accountAddress", data.accountAddress);
    formData.append("coinId", data.coinId);
    formData.append("image", data.image);
    formData.append("memo", data.memo),
      formData.append("whitelisted", data.whitelisted);

    return apis.post(`user/addressBook/addAddress`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  // Edit address book
  const editAddressBook = (data) => {
    const { token } = data;
    delete data["token"];

    let formData = new FormData();
    formData.append("name", data.Name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("setUniversal", data.setUniversal);
    formData.append("accountAddress", data.accountAddress);
    formData.append("coinId", data.coinId);
    formData.append("image", data.image);
    formData.append("memo", data.memo),
      formData.append("whitelisted", data.whitelisted);

    return apis.patch(`user/addressBook/editAddress/${data.id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  // Delete address booking
  const deleteAddressBook = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.delete(
      `user/addressBook/${data.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  // Payment report
  const paymentReportApiCall = (data) => {
    let link = "";
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    if (data.coinToConvert) link += `coinToConvert=${data.coinToConvert}&`;
    if (data.currencyId) link += `currencyId=${data.currencyId}&`;
    if (data.coinToAccept) link += `coinToAccept=${data.coinToAccept}&`;
    if (data.paymentStatus) link += `paymentStatus=${data.paymentStatus}&`;
    if (data.currencyAmount) link += `currencyAmount=${data.currencyAmount}&`;
    if (data.paymentId) link += `paymentId=${data.paymentId}&`;
    if (data.startDate) link += `startDate=${data.startDate}&`;
    if (data.endDate) link += `endDate=${data.endDate}`;
    if (data.orderBy) link += `&orderBy=${data.orderBy}`;
    if (data.sort) link += `&sort=${data.sort}`;
    if (data.obj) link += `&obj=${data.obj}`;

    const { token, id } = data;
    delete data["token"];
    return apis.get(
      `/user/paymentlink/paymenthistory/${id}?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getFilteredPaymentHistoryCount = (data) => {
    let link = "";
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;
    if (data.coinToConvert) link += `coinToConvert=${data.coinToConvert}&`;
    if (data.currencyId) link += `currencyId=${data.currencyId}&`;
    if (data.coinToAccept) link += `coinToAccept=${data.coinToAccept}&`;
    if (data.paymentStatus) link += `paymentStatus=${data.paymentStatus}&`;
    if (data.currencyAmount) link += `currencyAmount=${data.currencyAmount}&`;
    if (data.paymentId) link += `paymentId=${data.paymentId}&`;
    if (data.startDate) link += `startDate=${data.startDate}&`;
    if (data.endDate) link += `endDate=${data.endDate}`;

    const { token, id } = data;
    delete data["token"];

    return apis.get(
      `/user/paymentlink/paymenthistoryCount/${id}?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  // get all owner bank account
  const GetAllOwnersBankAccount = ({ token }) => {
    return apis.get(
      "/manager/getAllOwnersBankAccounts",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };
  const getDExchangePrice = ({
    token,
    sAmount,
    sTID,
    sToken,
    bTID,
    bToken,
  }) => {
    return apis.get(
      `platformExchange/getPrice?sellToken=${sToken}&buyToken=${bToken}&sellAmount=${sAmount}&sellTokenId=${sTID}&buyTokenId=${bTID}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  const postDExchangePrice = ({ token, data }) => {
    console.log(data);
    return apis.post(
      `platformExchange/getQuoteAndExchange`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getNotification = (data) => {
    const { token } = data;
    let link = "";
    // if (data.startDate)
    //   link += `startDate=${new Date(data.startDate).toISOString()}&`;
    // if (data.endDate)
    //   link += `endDate=${new Date(data.endDate).toISOString()}&`;
    // if (data.type) link += `Type=${data.type}&`;
    if (data.skip !== undefined) link += `skip=${data.skip}&`;
    if (data.limit) link += `limit=${data.limit}&`;

    return apis.get(
      `user/notification??${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const getNotificationcount = (data) => {
    const { token } = data;
    return apis.get(
      `user/notification/count?read=0`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const markallread = (data) => {
    const { token } = data;
    return apis.patch(
      `user/notification/markall`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const editOwnerBankAccount = (data) => {
    const { token, id } = data;
    delete data["token"];
    delete data["id"];
    return apis.patch(`manager/editBankDetails/${id}`, data.formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const adduserCoin = (data) => {
    const { token } = data;
    delete data["token"];

    return apis.post(
      "/user/addCoin",
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };
  const addAdminBank = (data) => {
    const { token } = data;
    delete data["token"];

    return apis.post("manager/addBank", data.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const deleteAdminBank = (data) => {
    const { token, id } = data;
    delete data["token"];
    delete data["id"];

    return apis.delete(
      `manager/deleteBank/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const editBankIcons = (data) => {
    const { token, id } = data;
    delete data["token"];
    delete data["id"];

    return apis.patch(`manager/editBankImage/${id}`, data.formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const editNonColouredIcon = (data) => {
    const { token, id } = data;
    delete data["token"];
    delete data["id"];

    return apis.patch(`manager/editBankImageNonColored/${id}`, data.formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const Getallnfc = (data) => {
    const { details, token } = data;
    console.log("check details", details);
    const { skip, limit } = details;
    return apis.get(
      `nfc/allnfc?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const CreateCard = (data) => {
    const { token } = data;
    console.log("data for backend", data);
    delete data["token"];
    return apis.post("/nfc/createnfc", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const UpdateCard = (data) => {
    const { token, id } = data;
    delete data["token"];
    return apis.patch(`/nfc/editnfc/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const GetUserNfc = (data) => {
    const { details, token } = data;
    console.log("check details", details);
    const { skip, limit } = details;
    return apis.get(
      `nfc/allUserNfcs?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetDefiLoanAdmin = (data) => {
    const { details, token } = data;
    const { skip, limit } = details;
    console.log("called api");
    return apis.get(
      `/defiLoan/getAllAdminLoanType?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const EditDefiLoanAdmin = (data) => {
    const { details, token } = data;
    const { id } = details;
    delete details["id"];
    return apis.patch(`/defiLoan/editLoanType/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const CreateDefiLoanAdmin = (data) => {
    const { details, token } = data;
    return apis.post(`/defiLoan/createLoanType`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const DeleteDefiLoanAdmin = (data) => {
    const { details, token } = data;
    return apis.delete(
      `/defiLoan/deleteLoanType/${details.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const DefiLoanBorrowMarket = (data) => {
    const { details, token } = data;
    const { skip, limit } = details;
    console.log("called api");
    return apis.get(
      `/defiLoan/getAllInterestRates?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const LoanRequestLoanSupport = (data) => {
    const { details, token } = data;
    const { skip, limit } = details;
    console.log("called api");
    return apis.get(
      `/defiLoan/getAllBorrowRequests?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getAllLoanType = (data) => {
    const { details, token } = data;
    const { skip = 0, limit = 100 } = details;
    console.log("called api");
    return apis.get(
      `/defiLoan/getAllLoanType?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const BorrowToCollateralConversion =(data)=>{
     
      return apis.post(`/defiLoan/getLiveBorrowCollateralCalculation`, data);
  }
  
  const CreateBorrowRequestToAdmin = (data)=>{
    const { details, token } = data;
    
    console.log("called api");
    return apis.post(
      `/defiLoan/createBorrowDetails`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }



  const approveLoanRequest = (data) => {
    const {details , token} = data
    const {id} = details
    delete details["id"]
    return apis.patch(`/defiLoan/approveCoinBorrowRequest/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  // Personal loan
  const createPersonalLoanAdmin = (data) => {
    const { details, token } = data;
    return apis.post(`/personalLoan/createPersonalLoanType`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const GetPersonalLoanRequestLoanAdmin = (data) => {
    const { details, token } = data;
    const { skip, limit } = details;
    return apis.get(
      `/personalLoan/allAdminPersonalLoanType?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const EditPersonalLoanAdmin = (data) => {
    const { details, token } = data;
    const { id } = details;
    delete details["id"];
    return apis.patch(`/personalLoan/edtiPersonalLoanType/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const DeletePersonalLoanAdmin = (data) => {
    const { details, token } = data;
    return apis.delete(
      `/personalLoan/deletePersonalLoanType/${details.id}`,
      
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetPersonalLoanRequestLoanPublic = (data) => {
    const { details, token } = data;
    const { skip, limit } = details;
    return apis.get(
      `/personalLoan/allPlatformSupportedPersonalLoans?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetDynamicInterestRateforPersonalLoan = (data)=>{
    const { details, token } = data;
    return apis.post(
      `/personalLoan/getLiveAmountRateChanges`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  const CreateBorrowPersonalLoanRequest = (data)=>{
    const formData = new FormData();
    const { details, token } = data;
    Object.entries(details).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // formData.append('text', details.text);
    return apis.post(
      `/personalLoan/requestPersonalLoan`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  const getAllPersonalLoanRequest = (data) => {
    const { details, token } = data;
    const { skip , limit } = details;
    return apis.get(
      `/personalLoan/getAllPersonalLoanRequests?skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const approvePersonalLoanRequestByAdmin = (data) => {
    const {details , token} = data
    const {id} = details
    delete details["id"]
    return apis.patch(`/personalLoan/approvePersonalLoanRequest/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };


  return {
    approveLoanRequest,
    CreateBorrowRequestToAdmin,
    BorrowToCollateralConversion,
    LoanRequestLoanSupport,
    DefiLoanBorrowMarket,
    GetDefiLoanAdmin,
    CreateDefiLoanAdmin,
    EditDefiLoanAdmin,
    DeleteDefiLoanAdmin,
    UsersignIn,
    UsersignUp,
    UserForgotpassword,
    UserVerifyOTP,
    UserResendOtp,

    getVerifyEmailcode,
    confirmEmailcode,
    setupGoogleAuth,
    verifyGoogleAuth,
    removeprofileImage,
    UserCreateFiatWallet,
    UserCreateCryptoWallet,
    UserResetPasswordEmail,
    UserSetNewPasswordMobile,
    VerifyToken,
    UserConfirmEmail,
    UserVerifyEmail,
    UserSetNewPasswordMob,
    UserSetNewPasswordEmail,

    RegisterPlatformAdmin,
    ManagerForgotpassword,
    ManagerEmailVerify,
    ManagerSetNewPasswordMobile,
    AddSuperManager,
    ManagersignIn,
    ManagerResendOtp,
    ManagerSetNewPasswordMob,
    ManagerSetNewPasswordEmail,
    ManagerVerifyEmail,
    ManagerConfirmEmail,
    ManagerVerifyOTP,

    //Platform user/manager
    addExchangeBySuperManager,
    addPlatformToken,
    //Platform user/manager/platform
    getPlatformToken,

    addLogo,
    addexchangeinfoColors,

    //userPlatform/user
    getMywallets,
    getAccountByWalletId,
    getAccountAndBalanceByWalletId,
    depositwithHistory,
    getCoinOrTokenBalance,
    activateCoinOrToken,
    transferBalanceOrToken,
    selectRegionAndLanguage,
    getExchangeHistory,

    //userPlatform/user/
    exchange,
    validateCaptcha,
    //userPlatform/user/fiat
    getFiatBalance,
    activateCurrency,

    //userPlatform/user/paymentLink
    generatePaymentLink,
    getPaymentLinkData,
    transferThroughPaymentLink,

    GetSettings,
    getMyProfile,

    getFiatBalanceByWalletId,

    ActivateFiatCurrency,

    exchangeRate,
    getpaymenthistory,
    getpaymenthistorycount,
    depositwithHistoryCount,
    exchangeHistoryCount,
    getPlatformCurrency,
    createIDORound,
    createIDOTrans,
    getIDOround,
    getidolistbyaddress,
    claimiodTrans,
    getroundcount,
    gettransactionCount,
    claimtokenCount,
    sendtokenCount,
    allTransactions,
    gettransferhistorycount,
    gettransferhistory,
    addBank,
    getAllBanks,
    getcommissionPercentage,
    getBank,
    depositFiat,
    getIDOBalance,
    buyCyborcoinFromIdo,
    getcyborExchangeRate,
    getBusinessRequest,
    businessForm,
    BusinessRequestApproval,
    ProfileImageUpload,
    UpdateProfile,
    ChangePasswordApiCall,
    //fiat transaction api calls
    getfiattransaction,
    getfiattransactioncount,

    //Branch Management Api Calls
    CreateBranch,
    ListAllBranches,
    DeleteBranch,
    EditBranch,
    updateBranchStatus,
    //Fiat Withdrawl Request
    CreateFiatWithDrawlRequest,

    //Fiat balance
    CallFiatBalance,

    //Get all active bank account
    GetAllBankAccounts,

    //Create Bank Account Api Call
    CreateBankAccount,

    //Cancel payment link
    CancelPaymentCall,

    // Admin functions below :)
    requestedAccountVerification,
    usersFiatDepositRequest,
    businessRequest,
    usersFiatWithdrawRequest,
    VerifyUserAccountRequest,
    VerifyUserDepositRequest,
    VerifyUserWithdrawRequest,
    ApproveBusinessRequest,
    withdrawRequestCount,
    depositRequestCount,
    businessRequestCount,
    bankAccountCount,
    getUsersList,
    getUsersListCount,
    setUsersStatus,
    editRoundPrivacy,
    getwithdrawFA,

    getAllAddressBooks,
    addAddressBook,
    editAddressBook,
    deleteAddressBook,
    getcommission,
    paymentReportApiCall,
    getFilteredPaymentHistoryCount,
    UpdateBranchProfileImage,

    getalltransaction,
    getalltransactioncount,
    getBranchpaymentList,
    // get all owner bank accounts
    GetAllOwnersBankAccount,
    getNotification,
    getNotificationcount,
    markallread,

    // Admin bank account operations
    editOwnerBankAccount,
    addAdminBank,
    deleteAdminBank,
    editBankIcons,
    editNonColouredIcon,
    // new point to handle downloads of pdf and excel
    depositwithHistoryDownload,
    deleteBankAccountFiat,
    getDExchangePrice,
    adduserCoin,
    postDExchangePrice,
    //nfc cards
    Getallnfc,
    CreateCard,
    UpdateCard,
    GetUserNfc,
    getAllLoanType,

    // Personal loan
    createPersonalLoanAdmin,
    GetPersonalLoanRequestLoanAdmin,
    EditPersonalLoanAdmin,
    DeletePersonalLoanAdmin,
    GetPersonalLoanRequestLoanPublic,
    GetDynamicInterestRateforPersonalLoan,
    CreateBorrowPersonalLoanRequest,
    getAllPersonalLoanRequest,
    approvePersonalLoanRequestByAdmin,
  };
};
export default {
  create,
};
