import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { Row, Col, Form, Dropdown, Alert } from "react-bootstrap";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import VerticleIcon from "@images/Vertical.svg";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import CoinList from "src/components/coin-list/CoinList";
import PersonalLoanRequestDetailPopup from "src/components/personal-loan-request-detail-popup/PersonalLoanRequestDetailPopup";
import CustomButton from "@components/custom-button/CustomButton";
import ViewDocumentsPopup from "src/components/view-dcuments-popup/ViewDocumentsPopup";
import "@features/personal-loan-request/PersonalLoanRequest.scss";
import ReactPaginate from "react-paginate";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import SuccessPopup from "src/components/personal-loan-success-popup/SuccessPopup";


function PersonalLoanRequest({
  hideLoaderOnRefresh,
  personalLoanRequestLoading,
  approvePersonalLoanRequestAsync,
  personalLoanRequestListAsync,
  personalLoanRequestList,
  personalLoanRequestError,
  personalLoanAdminApproveLoading,
  personalLoanAdminApproveError,
  role,
}) {
  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState("");
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [skip, setskip] = useState(0);
  const [loanApproveError, setLoanApproveError] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [actionMessage, setActionMessage] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    personalLoanRequestListAsync({ skip, limit });
  }, []);

  const Approve = (id) => {
    setShowDeleteConfirmation(true);
    setDeleteId(1);
  };


  const documentDownloadHanlder = (item, fileName = "doc") => {
    const fileUrl = item;
    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  };

  // const approveHandler =()=>{
  //   approvePersonalLoanRequestAsync({
  //     approve:true,
  //     id: item?.id,
  //     setLoanApproveError,
  //   });
  // }

  const { t } = useTranslation();

  return (
    <ScreenLayout
      hideLoaderOnStart={true}
      loaderOnRefresh={hideLoaderOnRefresh}
    >
          <SuccessPopup
          setShowModal={setShowSuccess}
          showModal={showSuccess}
          message={actionMessage}
          scope="loanAction"
          error = {loanApproveError}
          setError={setLoanApproveError}
        />

      <div id="PersonalLoanRequestManagement">
        <PersonalLoanRequestDetailPopup
          show1={showDetail}
          setShowModal={setShowDetail}
          data={data}
          setData={setData}
        />
        <ViewDocumentsPopup
          show1={showDoc}
          setShowModal={setShowDoc}
          data={data}
          setData={setData}
          onClick={documentDownloadHanlder}
        />

        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("BankLoanRequests")}</span>
                  <div className="filtersection d-none d-lg-block"></div>
                </div>
              </div>
              <div className="actions">
                <div className="filtersection d-block d-lg-none"></div>
              </div>
              <Table responsive="sm" className=" tablestyle" borderless>
                { (personalLoanRequestLoading || personalLoanAdminApproveLoading) && <Loader />}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading paddingleft">{t("LoanID")}</th>
                    <th className="trtbleheading text-center">{t("Name")} </th>
                    <th className="trtbleheading">{t("BorrowedAmount")} </th>
                    <th className="trtbleheading">{t("BorrowedCurrency")} </th>


                    <th className="trtbleheading">
                    {t("Tenure")} {(t("InDays"))}{" "}
                    </th>
                    <th className="trtbleheading">{t("InterestRate")} </th>
                    <th className="trtbleheading">{t("Status")} </th>

                    <th className="trtbleheading">{t("RequestedDate")} </th>


                    {role === "Admin" && (
                      <>
                        <th className="trtbleheading">
                          {t("ViewDocuments")}{" "}
                        </th>
                        <th className="trtbleheading text-center">
                          {t("ApproveReject")}{" "}
                        </th>
                      </>
                    )}
                    <th className="trtbleheading">{t("Details")}</th>

                  </tr>
                </thead>
                <tbody>
                  <>
                    {personalLoanRequestList !== undefined &&
                    personalLoanRequestList.count > 0 && (
                      personalLoanRequestList.data.map((item, index) => (
                        <tr className="trtbleheading maintdwrapper">
                          <td className="innercontent">
                            <div className="innerTd d-lg-flex d-none">
                              {index + skip+ 1}
                            </div>
                            <div className="innerTd d-lg-none d-flex">
                              <p className="mobUpperline">
                                No{item?.id} {item?.borrowedLoan?.loanName}
                              </p>
                              <p className="mobLowerline">
                                {Math.trunc(item?.borrowedLoan?.tenureInMonths)}{" "}
                                Months
                              </p>
                            </div>
                          </td>
                          <td className="amt d-none d-lg-table-cell">
                            <div className="innerTd id smallwidth">
                              {item?.borrowedLoan?.loanName}
                            </div>
                          </td>
                          <td className="amt d-none d-lg-table-cell ">
                            <div className="innerTd id smallwidth ">
                              {" "}
                              {item !== undefined &&
                                parseFloat(item?.borrowedLoanAmount).toFixed(4)}
                            </div>
                          </td>

                          <td className="amt d-none d-lg-table-cell">
                            <div className="innerTd table-coin">
                              <CoinList
                                // type={item?.personalLoanRequestCurrency?.type}
                                logo={
                                  item?.personalLoanRequestCurrency
                                    ?.nonColouredLogo
                                }
                                name={
                                  item?.personalLoanRequestCurrency
                                    ?.currencyName
                                }
                                logoBgColor={
                                  item?.personalLoanRequestCurrency
                                    ?.logoBgColor
                                }
                              />
                            </div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd smallwidth">
                              {Math.trunc(item?.borrowedLoan?.tenureInMonths)}
                            </div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd">
                              {item?.borrowedInterestRate !== null
                                ? `${item.borrowedInterestRate}%`
                                : "-"}
                            </div>
                          </td>
                        

                          {role !== undefined && role === "Admin" && (
                            <td className="innercontent">
                              <div className="innerTd passive">
                                <CustomButton
                                  name={t("View")}
                                  filled={true}
                                  primary={true}
                                  classname="download-btn"
                                  onClick={() => {
                                    setData(item);
                                    setShowDoc(true);
                                  }}
                                />
                              </div>
                            </td>
                          )}

                          {role !== undefined && role === "Admin" && (
                            <td className="innercontent">
                              <div className="innerTd d-flex flex-row align-items-center">
                                {item.adminApproval === true ? (
                                  <div className="text-success">Approved</div>
                                ) : item.status === "Rejected" ? (
                                  <div className="text-alert">Rejected</div>
                                ) :(
                                  <>
                                  <div className="approve">
                                  <CustomButton
                                    name={t("Approve")}
                                    filled={true}
                                    primary={true}
                                    classname="ml-2"
                                    onClick={(e) => {
                                      setActionMessage("The request has been successfully approved.")
                                      approvePersonalLoanRequestAsync({
                                        approve:true,
                                        id: item?.id,
                                        setLoanApproveError,
                                        setActionMessage,
                                        setShowSuccess,
                                        
                                      });
                                    }}
                                  />
                                  </div>
                                  <div className="reject">

                                  <CustomButton
                                  name={t("Reject")}
                                  filled={true}
                                  primary={true}
                                  // classname="reject-btn"

                                  onClick={(e) => {
                                    setActionMessage("The request has been rejected successfully.")
                                    approvePersonalLoanRequestAsync({
                                      approve:false,
                                      id: item?.id,
                                      setLoanApproveError,
                                      setActionMessage,
                                      setShowSuccess,

                                    });
                                  }}
                                />
                                </div>
                                </>
                                // </div>
                                )}
                              </div>
                            </td>
                            
                          )}
                              <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd smallwidth">
                              {(item?.status)}
                            </div>
                          </td>

                           <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd smallwidth">
                              {(item?.createdOn).substring(0,10)}
                            </div>
                          </td>
                            <td className="innercontent">
                            <div className="buttonwrapper innerTd d-none d-lg-flex">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="threedot"
                                  id="dropdown-basic"
                                >
                                  <img
                                    src={VerticleIcon}
                                    alt="Delete Logo"
                                    className="editbutton"
                                    // onClick={() => DetailsPopup(branch.id)}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="menu dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      //  setcoinItem(item);
                                      setData(item);
                                      setShowDetail(true);
                                    }}
                                  >
                                    {t("Details")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) }{ personalLoanRequestList !== undefined &&
                      personalLoanRequestList.count  == 0 &&(
                      <p className="trtbleheading">
                        {t("Sorrynoresultsfound")}!
                      </p>
                    )}
                  </>
                </tbody>
              </Table>
              {personalLoanRequestList !== undefined && personalLoanRequestList.count > 10 && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(personalLoanRequestList.count / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={personalLoanRequestList.count / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={(e) => {
                        setskip(e.selected * limit);
                        personalLoanRequestListAsync({
                          skip: e.selected * limit,
                          limit,
                        });

                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, wallet = {}, user = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const role = _.get(user, "userInfo.role", "");
  const settings = _.get(app, "settings", false);
  const personalLoanRequestLoading = _.get(
    wallet,
    "personalLoanRequestListLoading",
    false
  );
  const personalLoanRequestList = _.get(
    wallet,
    "personalLoanRequestList",
    undefined
  );
  const personalLoanRequestError = _.get(
    wallet,
    "personalLoanRequestError",
    undefined
  );
  const personalLoanAdminApproveLoading = _.get(
    wallet,
    "personalLoanAdminApproveLoading",
    false
  );
  const personalLoanAdminApproveError = _.get(
    wallet,
    "personalLoanAdminApproveError",
    false
  );

  return {
    role,
    thememode,
    settings,
    personalLoanRequestLoading,
    personalLoanRequestList,
    personalLoanRequestError,
    personalLoanAdminApproveLoading,
    personalLoanAdminApproveError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  approvePersonalLoanRequestAsync: (data) =>
    dispatch({
      type: "PersonalLoanRequestAdminApprovalSagaCalled",
      payload: data,
    }),
  personalLoanRequestListAsync: (data) =>
    dispatch({ type: "getPersonalLoanRequestListSagaCalled", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalLoanRequest);
