import * as _ from "lodash";
import React, { useEffect } from "react";
import { Modal, Container } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as EllipseIcon } from "@images/Ellipse.svg";
import { ReactComponent as FacebookIcon } from "@images/Facebook.svg";
import { ReactComponent as WhatsApp } from "@images/WhatsApp.svg";
import { ReactComponent as Gmail } from "@images/Gmail.svg";
import { ReactComponent as Mail } from "@images/Mail.svg";
import { ReactComponent as Telegram } from "@images/Telegram.svg";
import { ReactComponent as Viber } from "@images/Viber.svg";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import "@components/share-popup/SharePopupStyle.scss";

function SharePopup({
  show2,
  setShowModal,
  setshow2,
  setdepositModal,
  coinItem,
}) {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="SharePopup"
      show={show2}
      onHide={() => {
        setShowModal(false);
        setdepositModal(true);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
            setdepositModal(true);
          }}
        />
        <Container>
          <div className="detail1">
            <div className="label">
              <span className=" label-color">{t("ShareLink")}:</span>
            </div>
            <div className="value">
              <div className="socialicon ml">
                <FacebookShareButton
                  quote={`${coinItem && coinItem.address}`}
                  url={window.location.href}
                >
                  <FacebookIcon className="arrowclass111" />
                </FacebookShareButton>
              </div>
              <div className="socialicon">
                <WhatsappShareButton url={`${coinItem && coinItem.address}`}>
                  <WhatsApp className="arrowclass111" />
                </WhatsappShareButton>
              </div>
              <div className="socialicon">
                <EmailShareButton url={`${coinItem && coinItem.address}`}>
                  <Gmail className="arrowclass111" />
                </EmailShareButton>
              </div>
              {/* <div className="socialicon">
                <EmailShareButton url={`${coinItem && coinItem.address}`}>
                  <Mail className="arrowclass111" />
                </EmailShareButton>
              </div> */}
              <div className="socialicon">
                <TelegramShareButton url={`${coinItem && coinItem.address}`}>
                  <Telegram className="arrowclass111" />
                </TelegramShareButton>
              </div>
              <div className="socialicon">
                <ViberShareButton url={`${coinItem && coinItem.address}`}>
                  <Viber className="arrowclass111" />
                </ViberShareButton>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
export default React.memo(SharePopup);
