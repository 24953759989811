import * as _ from "lodash";
import React, { useState } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/no-access-popup/NoaccessPopupStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";

const NoaccessPopup = ({
  setShowModal,
  shownoacess,
  setshowmodalnoaccess,
  setshowmodalsecurity,
}) => {
  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="noacess"
      show={shownoacess}
      onHide={() => {}}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(true);
            setshowmodalnoaccess(false);
          }}
        />

        <Container>
          <div className="outercls">
            <div className="labelcls">
              <span>{t("optionselection1")} </span>
            </div>
          </div>
          <div className="outercls">
            <div className="labelcls">
              <span> {t("optionselection2")}: </span>
            </div>
          </div>

          <Row className="rowspace">
            <Col lg={12}>
              <div className="cancel">
                <CustomButton
                  type="submit"
                  name={t("buttonselection1")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setshowmodalnoaccess(false);
                    setshowmodalsecurity(true);
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name={t("buttonselection2")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setshowmodalnoaccess(false);
                    setshowmodalsecurity(true);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(NoaccessPopup);
