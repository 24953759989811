import * as _ from "lodash";
import React from "react";
import { Modal, Container } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import "@components/bank-request-popup/BankRequestPopup.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
const BusinessRequestPopup = ({ setShowModal, data, show1, item }) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="BankRequestPopup"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <CloseIcon
        className="closebtn hidden-for-mobile"
        onClick={() => setShowModal(false)}
      />
      <div
        className="back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
      <div className="transectiontype">
            <span className="transectiontext uppercase">
              {t("BankRequestDetails")}
            </span>
          </div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("ID")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item && item.id !== undefined ? item.id : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("UserName")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item && item.userName !== undefined ? item.userName : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BankName")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item && item.bankName !== undefined ? item.bankName : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BankAccountType")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
              {t(`${item && item.bankAccountType !== undefined
                  ? item.bankAccountType
                  : ""}`)}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BankAccountNumber")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item && item.bankAccountNumber !== undefined
                  ? item.bankAccountNumber
                  : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">IFSC</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item && item.IFSC !== undefined ? item.IFSC : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CreatedOn")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item && item.createdOn !== undefined
                  ? moment(item.createdOn).local().format("MM/DD/YYYY hh:mm A")
                  : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Status")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">
              {t(`${item && item.Status !== undefined ? item.Status : ""}`)}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("UserEmail")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item && item.user.email !== undefined ? item.user.email : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("UserContactNumber")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">
                {item && item.user.mobile !== undefined ? item.user.mobile : ""}
              </span>
            </div>
          </div>
          <div className="detail hidden-for-desktop">
            <div className="label">
              <span className="labeltext">{t("Action")}</span>
            </div>
            <div className="value">
              <div className="btnwidth">
                    <div className="approve">
                      <CustomButton
                        name={t("Approve")}
                        filled={true}
                        primary={true}
                      />
                    </div>

                    <div className="reject">
                      <CustomButton
                        name={t("Reject")}
                        filled={true}
                        primary={true} 
                      />
                    </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(BusinessRequestPopup);
