import * as _ from "lodash";
import React from "react";
import "@components/business-request-popup/BusinessRequestPopupStyles.scss";
import { Modal, Container } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "@components/custom-button/CustomButton";
const BusinessRequestPopup = ({
  setShowModal,
  data,
  show1,
  item,
  documentLink,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="Businessrequest"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <CloseIcon
        className="closebtn hidden-for-mobile"
        onClick={() => setShowModal(false)}
      />
      <div
        className="back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
        <div className="transectiontype">
          <span className="transectiontext uppercase">
            {t("BusinessRequest")}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BusinessID")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item !== undefined ? item.id : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CompanyName")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item !== undefined ? item.companyName : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CompanyAddress")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item !== undefined ? item.companyAddress : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CreatedOn")}</span>
            </div>

            <div className="value">
              <span className="valuetext">
                {item !== undefined
                  ? moment(item.createdOn).local().format("MM/DD/YYYY hh:mm A")
                  : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Region")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">
                {item !== undefined ? item.region : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("CompanyEmail")}</span>
            </div>

            <div className="value">
              <span className="valuetext emailtext">
                {item !== undefined ? item.companyEmail : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("ContactNumber")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">
                {item !== undefined ? item.mobile : ""}
              </span>
            </div>
          </div>

          {/*<div className="detail">
            <div className="label">
              <span className="labeltext">{t("Status")}</span>
            </div>
            <div className="value">
              <span className="valuetext uppercase">
                {item !== undefined ? t(`${item.businessApproval}`) : ""}
              </span>
            </div>
                </div> */}

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Currency")}</span>
            </div>

            <div className="value">
              <span className="valuetext">
                {item !== undefined ? item.currency : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Language")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item !== undefined ? item.language : ""}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("Document_Link")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item !== undefined &&
                item.businessDocument != undefined &&
                item.businessDocument[0] !== undefined ? (
                  <a
                    href={`${process.env.REACT_APP_IMGLINK}${item.businessDocument[0].document1}`}
                  >
                    {t("Click_to_download")}
                  </a>
                ) : (
                  "-"
                )}
              </span>
            </div>
          </div>

          <div className="detail">
            <div className="label">
              <span className="labeltext">{t("BusinessApproval")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {item !== undefined ? t(`${item.businessApproval}`) : ""}
              </span>
            </div>
          </div>
          <div className="detail hidden-for-desktop">
            <div className="label">
              <span className="labeltext">{t("Action")}</span>
            </div>
            <div className="value">
              <div className="btnwidth">
                <div className="approve">
                  <CustomButton
                    name={t("Approve")}
                    filled={true}
                    primary={true}
                  />
                </div>

                <div className="reject">
                  <CustomButton
                    name={t("Reject")}
                    filled={true}
                    primary={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(BusinessRequestPopup);
