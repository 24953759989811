import * as _ from "lodash";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackIcon } from "@images/back.svg";
import "@components/edit-loan-type/EditLoanType.scss";
import { Modal, Container, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import FooterCommon from "@components/footer-common/FooterCommon";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import {clearplatformerroSuccess} from '@store/platformuserredux';

const EditPersonalLoan = ({
  show1,
  setShowModal,
  editPersonalLoanLoading,
  editPersonalLoanError,
  editPersonalLoanAdmin,
  details,
  successPopup,
  handleEditClose,
  loanTypeName,setLoanTypeName,
  tenureInMonths, setTenureInMonths,
  loanInterestRate, setLoanInterestRate,
  loanAmount, setLoanAmount,
  clearBackendError,
}) => {
  // const [loanTypeName, setLoanTypeName] = useState("");
  // const [tenureInMonths, setTenureInMonths] = useState("");
  // const [loanInterestRate, setLoanInterestRate] = useState("");
  // const [loanAmount, setLoanAmount] = useState("");
  const [validated, setValidated] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValid2, setIsValid2] = useState(true);
  const [isValid3, setIsValid3] = useState(true);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setTenureInMonths(e.target.value);

    setIsValid(value >= 0) && setFlag(true);
  };
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setLoanInterestRate(e.target.value);

    setIsValid2(value >= 0) && setFlag(true);
  };
  const handleInputChange3 = (e) => {
    const value = e.target.value;
    setLoanAmount(e.target.value);

    // setInputValue(value);
    setIsValid3(value >= 0) && setFlag(true);
  };
 
  const createLoanType = (form) => {
    const data = {
      loanName: loanTypeName,
      tenureInMonths: tenureInMonths,
      loanInterestRate: loanInterestRate,
      loanAmount: loanAmount,
      id: details.id,
      openSuccess: () => {
        setShowModal(false);
        successPopup(true);
      },
    };
    editPersonalLoanAdmin(data);
  };

  const FormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      //  clearErrorMessage();
    } else {
      event.preventDefault();
      event.stopPropagation();
      if (isValid && isValid2 && isValid3) {
        createLoanType(form);
      }
    }
  };

  const resetState = ()=>{
    setLoanAmount("")
    setValidated(false)
    setIsValid(true)
    setIsValid2(true)
    setIsValid3(true)
    clearBackendError()
  }

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="EditLoanTypePopup"
      show={show1}
      onHide={() =>{
        resetState() 
        setShowModal(false)
      } }
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modalPrimaryHeader"></div>
        <CloseIcon className="closebtn" onClick={() =>{
          clearBackendError()
           setShowModal(false)}} />
        <Container>
          <Form noValidate onSubmit={FormSubmit} validated={validated}>
            <div className="outercontainer">
              <div className="edittitle hidden">
                {" "}
                <BackIcon
                  className="back"
                  onClick={() =>{ 
                    clearBackendError()
                    setShowModal(false)}}
                />
                <span className="editspan">
                  {t("Edit")} {t("Branch")}
                </span>
              </div>

              {editPersonalLoanLoading && <Loader />}

              <div className="titles">
                <span>{t("Loan Type Name")}</span>
                <div className="inputbox">
                  <InputField
                    type="text"
                    onChange={(e) => setLoanTypeName(e.target.value)}
                    value={loanTypeName}
                    isInvalid={validated === true}
                    required
                    error={
                      loanTypeName.length === 0
                        ? "Please enter loan type name"
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="titles">
                <span>{t("Tenure (In Months)")}</span>
                <div className="inputbox">
                  <InputField
                    type="number"
                    onChange={(e) => handleInputChange(e)}
                    value={tenureInMonths}
                    isInvalid={validated === true}
                    required
                    error={
                      tenureInMonths.length === 0 ? "Please enter tenure" : ""
                    }
                  />
                </div>
                {!isValid && (
                  <span className="errortext">
                    Please enter a non-negative number.
                  </span>
                )}
              </div>
              <div className="titles">
                <span>{t("Interest Rate")}</span>
                <div className="inputbox">
                  <InputField
                    type="number"
                    onChange={(e) => handleInputChange2(e)}
                    value={loanInterestRate}
                    isInvalid={validated === true}
                    required
                    error={
                      loanInterestRate.length === 0
                        ? "Please enter Interest Rate"
                        : ""
                    }
                  />
                </div>
                {!isValid2 && (
                  <span className="errortext">
                    Please enter the Interest rate between 1 and 20.
                  </span>
                )}
              </div>
              <div className="titles">
                <span>{t("Loan Amount")}</span>
                <div className="inputbox">
                  <InputField
                    type="number"
                    onChange={(e) => handleInputChange3(e)}
                    value={loanAmount}
                    isInvalid={validated === true}
                    required
                    error={
                      loanAmount.length === 0 ? "Please enter Loan Amount" : ""
                    }
                  />
                </div>
                {!isValid3 && (
                  <span className="errortext">
                    Please enter a non-negative number.
                  </span>
                )}
              </div>
              <div className="btmbtnoutercontainer">
                <div className="topbuttons">
                  <CustomButton
                    type="submit"
                    name={t("Save")}
                    filled={true}
                    primary={true}
                    //onClick={() => editBranch()}
                  />
                </div>
                <div className="bottombuttons">
                  <CustomButton
                    name={t("Cancel")}
                    filled={true}
                    primary={true}
                    type="button"
                    onClick={() => {
                      clearBackendError()
                      setShowModal(false)}}
                  />
                </div>
              </div>
            </div>
          </Form>
        </Container>

        {/* <p className="error-msg">{editBranchError !== undefined ? editBranchError : ""}</p> */}
        <p className="errortext1">{editPersonalLoanError}</p>
        <div className="hidden">
          <FooterCommon />
        </div>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ wallet = {} }) => {
  const editPersonalLoanLoading = _.get(
    wallet,
    "editPersonalLoanLoading",
    false
  );
  const editPersonalLoanError = _.get(
    wallet,
    "editPersonalLoanError",
    undefined
  );

  return {
    editPersonalLoanLoading,
    editPersonalLoanError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  editPersonalLoanAdmin: (data) =>
    dispatch({ type: "editPersonalLoanAdminSagaCalled", payload: data }),
    clearBackendError : ()=>dispatch(clearplatformerroSuccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalLoan);
