import SmallCard from "@components/smallcard/SmallCard.js";

import { Row, Col, Dropdown } from "react-bootstrap";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import { ReactComponent as HideEye } from "@images/hideeye.svg";
import { ReactComponent as Search } from "@images/search.svg";
import { ReactComponent as HideEye2 } from "@images/eye.svg";
import React, { useState, useEffect } from "react";
import "@components/fiat-balance/FiatBalanceStyle.scss";
import CoinListBalance from "@components/coin-list-balance/CoinListBalance";
import SmallButton from "@components/small-button/SmallButton";
import FiatTable from "@components/fiat-table/FiatTable";
import SearchBox from "@components/search-box/SearchBox";
import WithdrawalFiat from "../withdrawal-fiat/WithdrawalFiat";
import DepositFiatModal from "../depositfiat-modal/DepositFiatModal";
import Withdrawal from "../withdrawal/Withdrawal";
import ExchangeModal from "../exchange-modal/ExchangeModal";
import DepositModal from "../deposit-modal/DepositModal";
import BigNumber from "bignumber.js";
import moment from "moment";
import { Spinner } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { getCSymbol, getESymbol } from "src/utils/Currencies";

function FiatBalance({
  mywalletsinfo,
  role,
  getAmount,
  setselectedCoin,
  plaformcurrencies,
  setselectedSymbol,
  selectedCoin,
  FiatWithdrawModal,
  fiatDepositModal,
  setwithdrawModal,
  setDepositModal,
  platformBanks,
  getplatformTokensinfo,
  myaccountandbalance,
  marketdata,
  PreferredCurrency,
  circularLoader,
  fiatrate
}) {
  const [coinItem, setcoinItem] = useState(
    myaccountandbalance && myaccountandbalance[0] && myaccountandbalance[0].coin
  );
  const [value, setvalue] = useState("");
  const [deposit1, setdeposit1] = useState(false);
  const [fiatdeposit1, setfiatdeposit1] = useState(false);
  const [withdraw1, setwithdraw1] = useState(false);
  const [fiatwithdraw1, setfiatwithdraw1] = useState(false);
  const [exchange1, setexchange1] = useState(false);
  const [tableNo, setTableNo] = useState("second");



  //

  const getFiatBal = (cur) => {
    let obj =
      mywalletsinfo && mywalletsinfo.filter((item) => item.currencyId == cur);
    let reelbal = _.get(obj, "[0].balance", 0);
    return reelbal;
  };

  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", " ");

    return address;
  };

  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };
  let name = marketdata[`${moment().format("YYYY-MM-DD")}`];

  const [hideZeroBalance, sethideZero] = useState(false);

  let pieadata = [],
    colorsdata = [],
    total = 0,
    mybal = "0";
  plaformcurrencies &&
    plaformcurrencies.map((items) => {
      mybal = 0;
      let bal = getFiatBal(items.id);
      colorsdata.push(items.logoBgColor);
      if (items.exchangeSymbol == "USD") {
        mybal = Number(bal * 1);
      }
      else{
        mybal = Number(
          bal / Number((fiatrate && fiatrate[`${items.exchangeSymbol}`]&& fiatrate[`${items.exchangeSymbol}`].value) || 1).toFixed(2)
        );
      }
      pieadata.push({
        id: items.exchangeSymbol,
        label: items.currencyName,
        value: mybal,
        color: items.logoBgColor,
      });
      total += Number(mybal);
    });

  const { t, i18n, ready } = useTranslation();
  const [activeKey, setActiveKey] = useState(
    plaformcurrencies && plaformcurrencies[0].id
  );
  const [searchvalue, setSearchValue] = useState("");

  return (
    <div className="FiatBalance">
      <Row>
        <Col lg={8}>
          <div className="outerclass">
            <div className="allign allignMobile">
              <span className="balance">{t("FiatBalances")}</span>
              <span
                className="hideTag"
                onClick={() => sethideZero(!hideZeroBalance)}
              >
                {hideZeroBalance ? (
                  <HideEye2 className="hideeye" />
                ) : (
                  <HideEye className="hideeye" />
                )}
                {hideZeroBalance ? t("showzerobalance") : t("Hidezerobalance")}
              </span>
            </div>
          </div>
        </Col>
        <Col lg={4} className="actions">
          <div className={`options optionposition`}>
            <div className="mainbuttonswrapper">
              {role !== "Business" && (
                <SmallButton
                  type="deposit"
                  onclick={() => {
                    setfiatdeposit1(true);
                  }}
                />
              )}
              <SmallButton
                type="withdraw"
                onclick={() => {
                  setfiatwithdraw1(true);
                }}
              />
              <SmallButton
                type="exchange"
                onclick={() => {
                  setexchange1(true);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={5}>
          <div className="pieandlable">
            <div className="allign left">
              <div className="piechartsection">
              {circularLoader && 
          <div className="loaderWrap">
            <Spinner animation="grow" className="spincolor" />
          </div>}
                <ResponsivePie
                  data={pieadata}
                  isInteractive={false}
                  margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
                  innerRadius={0.76}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#fff"
                  arcLinkLabelsThickness={0}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                  }}
                  colors={colorsdata}
                ></ResponsivePie>
                {circularLoader  != true &&
                <div className="mybalinpie">
                  
                  <span className="pietxt"> 
                  {getCSymbol(PreferredCurrency)}{" "}
                  {BigNumber(total)
                    .multipliedBy(
                      (fiatrate && fiatrate[getESymbol(PreferredCurrency)]&& fiatrate[getESymbol(PreferredCurrency)].value) || 1
                    )
                  .toFixed(2, BigNumber.ROUND_FLOOR)}
                  </span>
                </div>
              }
              </div>
            </div>
            <div className="right">
              <div className="legendarea">
                {pieadata &&
                  pieadata.map((o) => (
                    <div className="legend">
                      <span
                        className="legendcolor"
                        style={{ "background-color": o.color }}
                      ></span>
                      <span className="legendname">{o.label}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Col>
        <Col lg={7} className="paddingtop">
          <Row className="d-lg-block d-none">
            {plaformcurrencies &&
              plaformcurrencies.map((items) => {
                if (hideZeroBalance && getAmount(items.id) != 0.0) {
                  return (
                    <Col lg={12}>
                      <div className="btmmargin">
                        <CoinListBalance
                          logo={items.nonColouredLogo}
                          name={items.currencyName}
                          balance={getAmount(items.id)}
                          decimals={items.decimals}
                          symbol={items.exchangeSymbol}
                          bgcolor={items.logoBgColor}
                          coinkey={items.id}
                          tag={items.type}
                          tableno={"second"}
                          onClick={() => {
                            setselectedSymbol(items);
                            setselectedCoin(items);
                            setActiveKey(items.id);
                          }}
                          fromwallet={true}
                          activekey={activeKey}
                          showtagontop={true}
                        />
                      </div>
                    </Col>
                  );
                }
                if (!hideZeroBalance) {
                  return (
                    <Col lg={12}>
                      <div className="btmmargin">
                        <CoinListBalance
                          logo={items.nonColouredLogo}
                          name={items.currencyName}
                          balance={getAmount(items.id)}
                          decimals={items.decimals}
                          symbol={items.exchangeSymbol}
                          bgcolor={items.logoBgColor}
                          coinkey={items.id}
                          tag={items.type}
                          tableno={"second"}
                          onClick={() => {
                            setselectedSymbol(items);
                            setselectedCoin(items);
                            setActiveKey(items.id);
                          }}
                          fromwallet={true}
                          activekey={activeKey}
                          showtagontop={true}
                        />
                      </div>
                    </Col>
                  );
                }
              })}
          </Row>
          <div className="d-lg-none d-block">
            <div className="topspace">
                <SearchBox value={searchvalue} setvalues={setSearchValue} />
            </div>
            <FiatTable
              plaformcurrencies={plaformcurrencies}
              getBal={getFiatBal}
              searchvalue={searchvalue}
              role={role}
              onClick={(e) => {
                 setselectedSymbol(e);
                setselectedCoin(e);
                // setActiveKey(e.id)
              }}
              activeSelectedCoin={selectedCoin}
              hideZeroBalance={hideZeroBalance}
            />
          </div>
        </Col>
      </Row>
      <DepositFiatModal
        setShowDeposit={(e) => setfiatdeposit1(e)}
        show={fiatdeposit1}
        tableNo={tableNo}
        setTableno={(e) => {
          setfiatdeposit1(false);
          setdeposit1(true);
        }}
        showToggle={true}
        selectedCurrency={selectedCoin}
        allplatformbanks={platformBanks}
        setcoinItem={setselectedCoin}
      />
      <WithdrawalFiat
        setShowWithdraw={(e) => setfiatwithdraw1(e)}
        show1={fiatwithdraw1}
        showToggle={true}
        tableNo={tableNo}
        setTableno={(e) => {
          setfiatwithdraw1(false);
          setwithdraw1(true);
        }}
        selectedCurrency={selectedCoin}
      />
      <DepositModal
        getAdd={getAdd}
        setcoinItem={setcoinItem}
        value={value}
        coinItem={coinItem}
        setvalue={setvalue}
        getplatformTokensinfo={getplatformTokensinfo}
        getBal={getBal}
        showToggle={true}
        show={deposit1}
        setdepositModal={(e) => setdeposit1(e)}
        tableNo={tableNo}
        settableno={(e) => {
          setdeposit1(false);
          setfiatdeposit1(true);
        }}
      />
      <Withdrawal
        getAdd={getAdd}
        setcoinItem={setcoinItem}
        value={value}
        coinItem={coinItem}
        setvalue={setvalue}
        getplatformTokensinfo={getplatformTokensinfo}
        getBal={getBal}
        showToggle={true}
        tableNo={tableNo}
        settableno={(e) => {
          setwithdraw1(false);
          setfiatwithdraw1(true);
        }}
        show1={withdraw1}
        setShowWithdraw={(e) => setwithdraw1(e)}
      />
      <ExchangeModal
        coinItem={coinItem}
        getplatformTokensinfo={getplatformTokensinfo}
        getBal={getBal}
        show2={exchange1}
        setExchangeModal={(e) => setexchange1(e)}
      />
    </div>
  );
}
export default FiatBalance;