import NavBar from "@components/navbar/NavBar";
import ScreenLayout from "src/components/layout/ScreenLayout";
import * as _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState, useLayoutEffect } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import { Row, Col, Tab } from "react-bootstrap";
import CoinListBalance from "@components/coin-list-balance/CoinListBalance";
import CoinBalanceDetails from "@components/coin-balance-details/CoinBalanceDetails";
import CurrencyTab from "src/components/currency-tab/CurrencyTab.js";
import FooterCommon from "@components/footer-common/FooterCommon";
import MyBalancePopup from "@components/my-balances-popup/MyBalancePopup";
import Withdrawal from "@components/withdrawal/Withdrawal";
import ExchangeModal from "@components/exchange-modal/ExchangeModal";
import DepositModal from "@components/deposit-modal/DepositModal";
import WithdrawalFiat from "@components/withdrawal-fiat/WithdrawalFiat";
// import FiatWithdrawModalSuccess from "@components/withdrawlfiat-success/WithDrawlFiatSuccess"


import DepositFiatModal from "@components/depositfiat-modal/DepositFiatModal";
import "@features/my-balances/MyBalances.scss";
import { getESymbol } from "src/utils/Currencies";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
const Binance = require("binance-api-node").default;
import moment from "moment";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function MyBalances(props) {
  let {
    myaccountandbalance,
    exchangemodal,
    setExchangeModal,
    setDepositModal,
    withdramodal,
    depositModal,
    getplatformTokensinfo,
    setwithdrawModal,
    plaformcurrencies,
    getFiatBalanceinfo,
    PreferredCurrency,
    hideLoaderOnRefresh
  } = props;

  const history = useHistory();
  const client = Binance();
  const [activekey, setActivekey] = useState(
    myaccountandbalance && myaccountandbalance[0]?.id
  );
  const [bnbdata, setbnbdata] = useState("");
  const [btcdata, setbtcdata] = useState("");
  const [ethdata, setethdata] = useState("");
  const [bnbpdata, setbnbpdata] = useState("");
  const [btcpdata, setbtcpdata] = useState("");
  const [ethpdata, setethpdata] = useState("");
  const [busddata, setbusddata] = useState("");
  const [busdpdata, setbusdpdata] = useState("");
  const [trycdata, settrycdata] = useState("");
  const [trycpdata, settrycpdata] = useState("");
  const [market, setmarket] = useState([]);
  const [chartsdata, setcharts] = useState([]);
  const [min, setMin] = useState([]);
  const [max, setMax] = useState([]);
  const [coinItem, setcoinItem] = useState(
    myaccountandbalance && myaccountandbalance[0] && myaccountandbalance[0].coin
  );
  const [trxdata, settrxdata] = useState("");
  const [trxpdata, settrxpdata] = useState("");
  const [value, setvalue] = useState("");
  const [isCoinInfo, setIsCoinInfo] = useState(false);
  const [tableno, settableno] = useState(
    (props.match.params.id &&
      (props.match.params.id.includes("F") ? "second" : "first")) ||
      "first"
  );
  const [show, setShow] = useState(false);
  const [width, height] = useWindowSize();
  useEffect(() => {
    if (
      props.match.params.id !== null &&
      props.match.params.id !== "" &&
      props.match.params.id !== undefined
    ) {
      if (props.match.params.id.includes("F")) {
        settableno("second");
        const coin =
          plaformcurrencies &&
          plaformcurrencies.filter((data) => {
            if (data.id == props.match.params.id.slice(1)) {
              return data;
            }
          })[0];
        setcoinItem(coin);
        setActivekey(coin.id);
      } else {
        const coin =
          myaccountandbalance &&
          myaccountandbalance.filter((data) => {
            if (data.coin.id == props.match.params.id) {
              return data.coin;
            }
          })[0];
        setcoinItem(coin.coin);
        setActivekey(coin.id);
      }
      if (window.innerWidth < 992) {
        history.replace("/mybalances");
      }
    } else {
      if (width >= 992) {
        if (tableno == "first") {
          setcoinItem(
            myaccountandbalance &&
              myaccountandbalance[0] &&
              myaccountandbalance[0].coin
          );
          setActivekey(myaccountandbalance && myaccountandbalance[0]?.id);
        } else {
          setcoinItem(plaformcurrencies && plaformcurrencies[0]);
          setActivekey(plaformcurrencies && plaformcurrencies[0]?.id);
        }
      } else {
        setcoinItem("");
        setActivekey("");
      }
    }
  }, [width, tableno]);
  useEffect(() => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    let prefSymbol = 'usdt'
    const msg = {
      method: "SUBSCRIBE",
      params: [
        `eth${prefSymbol}@aggTrade`,
        `btc${prefSymbol}@aggTrade`,
        `bnb${prefSymbol}@aggTrade`,
        `busd${prefSymbol}@aggTrade`,
        `eth${prefSymbol}@ticker`,
        `btc${prefSymbol}@ticker`,
        `bnb${prefSymbol}@ticker`,
        `busd${prefSymbol}@ticker`,
        `trx${prefSymbol}@ticker`,
        `trx${prefSymbol}@aggTrade`,
        `${prefSymbol}try@ticker`,
        `${prefSymbol}try@aggTrade`,
      ],
      id: 1,
    };

    const msg2 = {
      method: `UNSUBSCRIBE`,
      params: [
        `eth${prefSymbol}@aggTrade`,
        `btc${prefSymbol}@aggTrade`,
        `bnb${prefSymbol}@aggTrade`,
        `busd${prefSymbol}@aggTrade`,
        `trx${prefSymbol}@aggTrade`,
        `eth${prefSymbol}@ticker`,
        `btc${prefSymbol}@ticker`,
        `bnb${prefSymbol}@ticker`,
        `busd${prefSymbol}@ticker`,
        `trx${prefSymbol}@ticker`,
        `${prefSymbol}try@ticker`,
        `${prefSymbol}try@aggTrade`,
      ],
      id: 1,
    };
    ws.onopen = () => {
      ws.send(JSON.stringify(msg));
      setTimeout(() => ws.send(JSON.stringify(msg2)), 4000);
    };
    let cSymbol = 'USDT'
    ws.onmessage = (e) => {
      let newdata = JSON.parse(e.data);
      if (!_.isUndefined(e.data)) {
        if (newdata.e == "24hrTicker") {
          if (newdata.s === `BNB${cSymbol}`) setbnbpdata(newdata);
          if (newdata.s === `ETH${cSymbol}`) setethpdata(newdata);
          if (newdata.s === `BTC${cSymbol}`) setbtcpdata(newdata);
          if (newdata.s === `BUSD${cSymbol}`) setbusdpdata(newdata);
          if (newdata.s === `TRX${cSymbol}`) settrxpdata(newdata);
          if (newdata.s === `${cSymbol}TRY`) settrycpdata(newdata);
        } else {
          if (newdata.s === `BNB${cSymbol}`) setbnbdata(newdata);
          if (newdata.s === `ETH${cSymbol}`) setethdata(newdata);
          if (newdata.s === `BTC${cSymbol}`) setbtcdata(newdata);
          if (newdata.s === `BUSD${cSymbol}`) setbusddata(newdata);
          if (newdata.s === `TRX${cSymbol}`) settrxdata(newdata);
          if (newdata.s === `${cSymbol}TRY`) settrycdata(newdata);
        }
      }
    };
  }, []);

  useEffect(() => {
    if (tableno == "first") getSpecificMarketPriceChangeAndGraphData();
  }, [coinItem]);
 

  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", 0);

    return address;
  };

  const getFiatBal = (cur) => {
    
    let obj =
      getFiatBalanceinfo &&
      getFiatBalanceinfo.filter((item) => item.currencyId == cur);
      
    let reelbal = _.get(obj, "[0].balance", 0);
 
  
    return reelbal;
  };

  const getSpecificMarketPriceChangeAndGraphData = () => {
    setmarket([]);
    setcharts([]);
    setMin([]);
    setMax([]);

    let seletedItemIndex;
    let filteredPlatformToken = getplatformTokensinfo && getplatformTokensinfo.filter((item, index) => {
      if (item?.id === coinItem?.id) {
        seletedItemIndex = index;
        return item;
      }
    })[0];

    let seletedItemWithIndex = [
      { ...filteredPlatformToken, index: seletedItemIndex },
    ];

    seletedItemWithIndex.map((items) => {
      if (items.exchangeSymbol !== "CYBOR") {
        getMarketdata(items.exchangeSymbol, "USD", items.index, items.id);
      }
    });
  };

  const getMarketdata = (exchangeSymbol, Currency, index, id) => {
    try {
      let CurrencyT = Currency == "USD" ? "USDT" : "USDT";
      if (exchangeSymbol == "USDT") {
        client
          .dailyStats({
            symbol: `BUSD${CurrencyT}`,
          })
          .then((time) => {
            setmarket(() => time);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `BUSD${CurrencyT}`,
            interval: "1d",
            limit: 20,
            // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
            // endTime: moment(new Date()).valueOf(),
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            setMin(mins);
            setMax(maxs);
            setcharts(charts);
          })
          .catch((er) => {
            console.log(er);
          });
      } else if (exchangeSymbol == "TRYC") {
        client
          .dailyStats({
            symbol: `${CurrencyT}TRY`,
          })
          .then((time) => {
            setmarket(() => time);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `${CurrencyT}TRY`,
            interval: "1d",
            limit: 20,
            // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
            // endTime: moment(new Date()).valueOf(),
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            setMin(mins);
            setMax(maxs);
            setcharts(charts);
          })
          .catch((er) => {
            console.log(er);
          });
      } else {
        client
          .dailyStats({
            symbol: `${exchangeSymbol}${CurrencyT}`,
          })
          .then((time) => {
            setmarket(() => time);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `${exchangeSymbol}${CurrencyT}`,
            interval: "1d",
            limit: 20,
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            setMin(mins);
            setMax(maxs);
            setcharts(charts);
          })
          .catch((er) => {});
      }
    } catch (err) {}
  };

  function tabpane1() {
    return (
      <div className="mybalancescontent">
        <div className="balancecontentrowwrapper">
          <Row className="balancecontentrow">
            <Col xl={8} lg={6} md={12} sm={12} className="coinlistmainwrapper">
              <div className="coinlistwrapper">
                {myaccountandbalance &&
                  myaccountandbalance.map((coins) => (
                    <div
                      className="coinlistitemss"
                      key={coins.id}
                      onClick={() => {
                        setActivekey(coins.id);
                        setcoinItem(coins.coin);
                        if (window.innerWidth < 992) {
                          setShow(true);
                        } else {
                          history.replace(`/mybalances/${coins.coin.id}`);
                        }
                      }}
                    >
                      <CoinListBalance
                        logo={coins.coin.nonColouredLogo}
                        name={coins.tORcName}
                        balance={coins.amount}
                        coin = {coins}
                        decimals={coins.coin.decimals}
                        symbol={coins.coin.exchangeSymbol}
                        bgcolor={coins.coin.logoBgColor}
                        coinkey={coins.id}
                        activekey={activekey}
                        tag={coins.coin.type}
                        tableno={tableno}
                        showbuttons={true}
                        hidesymbol={true}
                        showtagontop={true}
                        setwithdrawModal={(e) => setwithdrawModal(e)}
                        setDepositModal={setDepositModal}
                        setExchangeModal={(e) => setExchangeModal(e)}
                        getFiatBal={getFiatBal}
                      />
                    </div>
                  ))}
              </div>
            </Col>
            <Col xl={4} lg={6} md={12} sm={12} className={`coindetailswrapper`}>
              <div className="coindetails">
                {myaccountandbalance &&
                  myaccountandbalance.map((coins) => {
                    if (coins.id == activekey) {
                      return (
                        <>
                          <CoinBalanceDetails
                            getplatformTokensinfo={getplatformTokensinfo}
                            getAdd={getAdd}
                            getBal={getBal}
                            logo={coins.coin.nonColouredLogo}
                            name={coins.tORcName}
                            balance={coins.amount}
                            decimals={coins.coin.decimals}
                            bgcolor={coins.coin.logoBgColor}
                            coinsymbol={coins.coin.exchangeSymbol}
                            bnbdata={bnbdata}
                            bnbpdata={bnbpdata}
                            ethdata={ethdata}
                            ethpdata={ethpdata}
                            btcdata={btcdata}
                            btcpdata={btcpdata}
                            busddata={busddata}
                            trxdata={trxdata}
                            trxpdata={trxpdata}
                            PreferredCurrency={PreferredCurrency}
                            coin={coins}
                            busdpdata={busdpdata}
                            coinItem={coinItem}
                            setcoinItem={setcoinItem}
                            withdramodal={withdramodal}
                            setwithdrawModal={(e) => setwithdrawModal(e)}
                            exchangemodal={exchangemodal}
                            depositModal={depositModal}
                            setDepositModal={setDepositModal}
                            setExchangeModal={(e) => setExchangeModal(e)}
                            min={min}
                            trycpdata={trycpdata}
                            trycdata={trycdata}
                            max={max}
                            chartPoinst={chartsdata}
                            percentChanges={market && market.priceChangePercent}
                          />
                          <MyBalancePopup
                            show={show}
                            setShow={setShow}
                            getplatformTokensinfo={getplatformTokensinfo}
                            getAdd={getAdd}
                            getBal={getBal}
                            logo={coins.coin.nonColouredLogo}
                            name={coins.tORcName}
                            balance={coins.amount}
                            decimals={coins.coin.decimals}
                            bgcolor={coins.coin.logoBgColor}
                            coinsymbol={coins.coin.exchangeSymbol}
                            bnbdata={bnbdata}
                            bnbpdata={bnbpdata}
                            PreferredCurrency={PreferredCurrency}
                            ethdata={ethdata}
                            ethpdata={ethpdata}
                            btcdata={btcdata}
                            trycpdata={trycpdata}
                            trycdata={trycdata}
                            btcpdata={btcpdata}
                            busddata={busddata}
                            trxdata={trxdata}
                            trxpdata={trxpdata}
                            coin={coins}
                            busdpdata={busdpdata}
                            coinItem={coinItem}
                             withdramodal={withdramodal}
                            setwithdrawModal={(e) => setwithdrawModal(e)}
                            exchangemodal={exchangemodal}
                            depositModal={depositModal}
                            setDepositModal={setDepositModal}
                            setExchangeModal={(e) => setExchangeModal(e)}
                            setActivekey={(e) => setActivekey(e)}
                            setcoinItem={(e) => setcoinItem(e)}
                            tableno={tableno}
                            history={history}
                          />
                        </>
                      );
                    }
                  })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  function tabpane2() {
    return (
      <div className="mybalancescontent tabpane2">
        <div className="balancecontentrowwrapper">
          <Row className="balancecontentrow">
            <Col xl={8} lg={6} md={12} sm={12} className="coinlistmainwrapper">
              <div className="coinlistwrapper">
                {plaformcurrencies &&
                  plaformcurrencies.map((coins) => (
                    <div
                      className="coinlistitemss"
                      key={coins.id}
                      onClick={() => {
                        setActivekey(coins.id);
                      
                        setcoinItem(coins);
                        if (window.innerWidth < 992) {
                          setShow(true);
                        } else {
                          history.replace(`/mybalances/F${coins.id}`);
                        }
                      }}
                    >
                      <CoinListBalance
                        logo={coins.nonColouredLogo}
                        name={coins.currencyName}
                        symbol={coins.exchangeSymbol}
                        bgcolor={coins.logoBgColor}
                        coinkey={coins.id}
                        balance={getFiatBal(coins.id)}
                        activekey={activekey}
                        tableno={tableno}
                        showbuttons={true}
                        coins={coins}
                        hidesymbol={true}
                        setcoinItem={(e) => setcoinItem(e)}
                         setwithdrawModal={(e) => setwithdrawModal(e)}
                        setDepositModal={setDepositModal}
                        setExchangeModal={(e) => setExchangeModal(e)}
                        getFiatBal={getFiatBal}
                      />
                    </div>
                  ))}
              </div>
            </Col>
            <Col xl={4} lg={6} md={12} sm={12} className={`coindetailswrapper`}>
              <div className="coindetails">
                {plaformcurrencies &&
                  plaformcurrencies.map((coins) => {
                    if (coins.id == activekey) {
                      return (
                        <>
                          <CoinBalanceDetails
                            fiat={true}
                            getplatformTokensinfo={getplatformTokensinfo}
                            getAdd={getAdd}
                            getBal={getBal}
                            logo={coins.nonColouredLogo}
                            name={coins.currencyName}
                            balance={getFiatBal(coins.id)}
                            bgcolor={coins.logoBgColor}
                            coinsymbol={coins.exchangeSymbol}
                            bnbdata={bnbdata}
                            bnbpdata={bnbpdata}
                            ethdata={ethdata}
                            ethpdata={ethpdata}
                            btcdata={btcdata}
                            btcpdata={btcpdata}
                            busddata={busddata}
                            trxdata={trxdata}
                            trxpdata={trxpdata}
                            PreferredCurrency={PreferredCurrency}
                            coin={coins}
                            busdpdata={busdpdata}
                            coinItem={coinItem}
                            setcoinItem={setcoinItem}
                            withdramodal={withdramodal}
                            setwithdrawModal={(e) => setwithdrawModal(e)}
                            exchangemodal={exchangemodal}
                            depositModal={depositModal}
                            setDepositModal={setDepositModal}
                            setExchangeModal={(e) => setExchangeModal(e)}
                          />
                          <MyBalancePopup
                            show={show}
                            setShow={setShow}
                            getplatformTokensinfo={getplatformTokensinfo}
                            getAdd={getAdd}
                            getBal={getBal}
                            logo={coins.nonColouredLogo}
                            name={coins.currencyName}
                            balance={getFiatBal(coins.currencyName)}
                            bgcolor={coins.logoBgColor}
                            coinsymbol={coins.exchangeSymbol}
                            bnbdata={bnbdata}
                            bnbpdata={bnbpdata}
                            ethdata={ethdata}
                            ethpdata={ethpdata}
                            btcdata={btcdata}
                            btcpdata={btcpdata}
                            busddata={busddata}
                            trxdata={trxdata}
                            trxpdata={trxpdata}
                            coin={coins}
                            PreferredCurrency={PreferredCurrency}

                            busdpdata={busdpdata}
                            coinItem={coinItem}
                            setcoinItem={setcoinItem}
                            withdramodal={withdramodal}
                            setwithdrawModal={(e) => setwithdrawModal(e)}
                            exchangemodal={exchangemodal}
                            depositModal={depositModal}
                            setDepositModal={setDepositModal}
                            setExchangeModal={(e) => setExchangeModal(e)}
                            setActivekey={(e) => setActivekey(e)}
                             tableno={tableno}
                            history={history}
                          />
                        </>
                      );
                    }
                  })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  const { t } = useTranslation();
  return (
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh} >
      <>
        <Container>
          <div id="mybalances">
            <div className="trouter trasactiontitle">
              <div className="mybalancesheadwrapper">
                <div className="title">
                  {t("My")} {t("Balances")}
                </div>
              </div>
            </div>

            <div className={`carrencytabwrapper`}>
              <div>
                <CurrencyTab
                  page={"currency"}
                  className="currencyarea"
                  changeTab={(e) => {
                    if (e == "second") {
                      if (
                        props.match.params.id !== null &&
                        props.match.params.id !== undefined &&
                        props.match.params.id !== ""
                      ) {
                        if (!props.match.params.id.includes("F")) {
                          history.replace(
                            `/mybalances/F${
                              plaformcurrencies && plaformcurrencies[0].id
                            }`
                          );
                        }
                      }
                      settableno(e);
                    }
                    if (e == "first") {
                      if (
                        props.match.params.id !== null &&
                        props.match.params.id !== undefined &&
                        props.match.params.id !== ""
                      ) {
                        if (props.match.params.id.includes("F")) {
                          history.replace(
                            `/mybalances/${
                              myaccountandbalance &&
                              myaccountandbalance[0].coin &&
                              myaccountandbalance[0].coin.id
                            }`
                          );
                        }
                      }
                      settableno(e);
                    }
                  }}
                  tableno={tableno}
                >
                  <Tab.Pane eventKey="first">{tabpane1()}</Tab.Pane>
                  <Tab.Pane eventKey="second">{tabpane2()}</Tab.Pane>
                </CurrencyTab>
              </div>
            </div>
          </div>

          <DepositModal
            getAdd={getAdd}
            setcoinItem={(e) => setcoinItem(e)}
            value={value}
            coinItem={coinItem}
            setvalue={setvalue}
            getplatformTokensinfo={getplatformTokensinfo}
            getBal={getBal}
            show={depositModal && tableno == "first"}
            setdepositModal={(e) => setDepositModal(e)}
            showToggle={true}
            tableno={tableno}
            settableno={(e) => settableno(e)}
          />
          <Withdrawal
            getAdd={getAdd}
            setcoinItem={(e) => setcoinItem(e)}
            value={value}
            coinItem={coinItem}
            setvalue={setvalue}
            getplatformTokensinfo={getplatformTokensinfo}
            getBal={getBal}
            show1={withdramodal && tableno == "first"}
            setShowWithdraw={(e) => setwithdrawModal(e)}
            showToggle={true}
            tableno={tableno}
            settableno={(e) => settableno(e)}
          />
          <ExchangeModal
            coinItem={coinItem}
            getplatformTokensinfo={getplatformTokensinfo}
            getBal={getBal}
            show2={exchangemodal}
            setExchangeModal={(e) => setExchangeModal(e)}
          />
          <DepositFiatModal
            setShowDeposit={(e) => setDepositModal(e)}
            show={depositModal && tableno == "second"}
            selectedCurrency={coinItem}
            tableNo={tableno}
            setTableno={(e) => settableno(e)}
            showToggle={true}
            setcoinItem={setcoinItem}

          />
          <WithdrawalFiat
           selectedCurrency={coinItem}
            setShowWithdraw={(e) => setwithdrawModal(e)}
            show1={withdramodal && tableno == "second"}
            tableNo={tableno}
            setTableno={(e) => settableno(e)}
            showToggle={true}
          />
         
         {/* <FiatWithdrawModalSuccess/> */}
        </Container>
      </>
      <div className="mt-3">
        <FooterCommon />
      </div>
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {}, app = {}, fiat = {}, user = {} }) => {
  const exchangemodal = _.get(app, "exchangemodal", false);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const withdramodal = _.get(app, "withdramodal", false);
  const depositModal = _.get(app, "DepositModal", false);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");

  return {
    myaccountandbalance,
    exchangemodal,
    withdramodal,
    depositModal,
    getplatformTokensinfo,
    plaformcurrencies,
    getFiatBalanceinfo,
    PreferredCurrency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setExchangeModal: (data) =>
    dispatch({ type: "setExchangeModalcalled", payload: data }),
  setwithdrawModal: (data) =>
    dispatch({ type: "withdramodalcalled", payload: data }),
  setDepositModal: (data) =>
    dispatch({ type: "DepositModalCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyBalances);
