export default {
  Branch: "Branch",
  Management: "Management",
  No: "No",
  Passive: "Passive",
  Active: "Active",
  Account: "Account",
  Phone: "Phone",
  Address: "Address",
  Edit: "Edit",
  Sorrynoresultsfound: "Sorry no results found",
  Showing: "Showing",
  from: "from",
  CreateBranch: "Create Branch ",
  MyBranch: "My Branch",
  BranchCreatedSuccessfully: "Branch Created Successfully",
  Okay: "Okay",
  DefiLoans: "Crypto Loan",
  BankLoan: "Bank Loan",
  Cryptocards: "Crypto Cards",
  Buysellcrypto: "Buy/Sell Crypto",
  Loanrequests: "Loan Requests",
  Borrow: "Borrow",
  LoanID: "Loan ID",
  BorrowedAmount: "Borrowed Amount",
  BorrowedCoin: "Borrowed Coin",
  Tenure: "Tenure",
  InDays: "In Days",
  InitialLTV: "Initial LTV",
  LiquidationLTV: "Liquidation LTV",
  CreationDate: "Creation Date",
  Details: "Details",
  BankLoanRequests: "Bank Loan Requests",
  BorrowedCurrency: "Borrowed Currency",
  InterestRate: "Interest Rate",
  Status: "Status",
  RequestedDate: "Request Date",
  ViewDocuments: "View Documents",
  ApproveReject: "Approve/Reject",
  InMonths : "In Months",
LoanAmount: "Loan Amount",
Clicktoborrow : "Click to borrow",
};
