import NavBar from '@components/navbar/NavBar';
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";
import "@features/dashboard/DashboardScreenStyle.scss";
import MainDashboard from "@components/main-dashboard/MainDashboard";
import ScreenLayout from 'src/components/layout/ScreenLayout';
import Loader from "src/components/loader/Loader";
import FooterCommon from "@components/footer-common/FooterCommon";
function DashboardScreen(props) {
  const {
    getBalances,
    getPlatformCurrencys,
    getFiatBalance,
    getPlatformTokens,
    hideLoaderOnRefresh
  } = props || {};
  const [displayedCurrency, setdisplayedCurrency] = useState(["ETH", "Cybor"]);
  const [coinItem, setcoinItem] = useState({});
  const [data, setdata] = useState([]);
  useEffect(() => {
     getPlatformTokens();
    getBalances();
    getPlatformCurrencys();
    getFiatBalance();
  }, []);

  return (
    // <div id="DashboardScreen">
    //   <NavBar location={useLocation()} />
    //   <Container className="containerallignm">
    //     {/* <Loader/> */}
    //     <Row>
    //       <Col lg={3}>
    //         <LeftSidebar />
    //       </Col>
    //       <Col lg={9}>
    //         <MainDashboard setcoinItem={setcoinItem} coinItem={coinItem} />
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh}  hideLoaderOnStart={props?.location?.state?.stopLoading} >
      <>
        <Container>
          <div id="DashboardScreen">
          <MainDashboard setcoinItem={setcoinItem} coinItem={coinItem} />
          </div>
        </Container>
        <div className="mt-5">
          <FooterCommon />
        </div>
      </>
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {}, user = {} }) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);

  return { mywalletsinfo };
};

const mapDispatchToProps = (dispatch) => ({
  getBalances: (data) => dispatch({ type: "getBalancesCalled", payload: data }),
  getPlatformTokens: (data) =>
    dispatch({ type: "getPlatformTokensCalled", payload: data }),

  getPlatformCurrencys: (data) =>
    dispatch({ type: "getPlatformCurrencysCalled", payload: data }),
  getFiatBalance: (data) =>
    dispatch({ type: "getFiatBalanceCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
