export default {
    Payment: 'Ödeme',
    Merchant: 'Pazarlamacı',
    Payer: 'Ödeyen',
    AmountToPay: 'Ödenecek miktar',
    InsufficientBalance: 'Yetersiz bakiye',
    PaymentSuccessfull: 'Ödeme Başarıyla Gerçekleştirildi!',
    PaymentLinkNotValid: 'Ödeme bağlantısı artık geçerli değil',
    Close: 'Kapat',
    Total: 'Toplam',
    Pending:"Bekleniyor",
    Paid:"Ödendi",
    Cancelled:"İptal edildi"
}