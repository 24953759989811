import React, { useEffect, useState } from "react";
import { Modal, Container } from "react-bootstrap";
import ExchangePopup from "src/components/exchange-popup/ExchangePopup";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import FooterCommon from "@components/footer-common/FooterCommon";
import "@components/exchange-modal/ExchangeModal.scss";
import * as _ from 'lodash'
import { connect  } from "react-redux"; 
const ExchangeModal = ({
  getBal,
  setExchangeModal,
  show2,
  coinItem,
  getplatformTokensinfo,
  plaformcurrencies,
}) => {
  const [hideThis, setHideThis] = useState(false);
  const [options, setoptions] = useState([]);

  useEffect(() => {
    setoptions(() => [...getplatformTokensinfo, ...plaformcurrencies]);
  }, []);
  
  return (
    <Modal
      className={`exchangemodal ${hideThis && "hidethisexchangemodal"}`}
      show={show2}
      onHide={() => setExchangeModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName={`${hideThis && "mod-exchangebackdrop"}`}
    >
      <div className="modalPrimaryHeader"></div>
      <Modal.Body>
        <CloseIcon
          className="closebtn hideclose"
          onClick={() => setExchangeModal(false)}
        />
        <Container>
          <div
            className="rightnav titlehide"
            onClick={() => setExchangeModal(false)}
          >
            <ExpandIcon />
          </div>
          <div className="titlehide exchangetoptitle">
            <span>Exchange</span>
          </div>
          <ExchangePopup
            coinItem={coinItem}
            option={options}
            getBal={getBal}
            setExchangeModal={setExchangeModal}
            setHideThis={(e) => setHideThis(e)}
          />
        </Container>
      </Modal.Body>

      <div className="titlehide">
        <FooterCommon />
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ wallet = {} }) => {
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);

  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);

  return {
    getplatformTokensinfo,
    plaformcurrencies,
  };
};

export default connect(mapStateToProps, null)(ExchangeModal);
