import { ReactComponent as Bell } from "@images/bell.svg";
import { ReactComponent as Help } from "@images/help.svg";
import { ReactComponent as Menu } from "@images/menu.svg";
import Profile from "@components/profile/Profile";
import ProfileMenu from "@components/profilemenuoption/ProfileMenu";
import NotificationDropdown from "@components/notification-dropdown/NotificationDropdown";
import { Nav, Navbar, NavItem, NavLink } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import Container from "react-bootstrap/Container";
import React, { Fragment, useState, useEffect } from "react";
import "@components/navbar/NavBarStyles.scss";
import { ReactComponent as DropdownarrowIcon } from "@images/dropdown.svg";
import help1 from "@images/help1.svg";
import help2 from "@images/help2.svg";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../store/translatelanguageredux";
import BusinessSignup from "src/features/onboarding/business-signup/BusinessSignup";
import RequestSent from "../request-sent/RequestSent";
import SmallDropdown from "../small-dropdown/SmallDropdown";
import DarkLightToogle from "../dark-light-toogle/DarkLightToogle";
import currencies from "src/utils/Currencies";
import LoginWelcomePopup from "../login-welcome-popup/LoginWelcomePopup";
import moment from "moment";

function NavBar(props) {
  const {
    thememode,
    updateTheme,
    MyProfileInfo,
    auth,
    exchange,
    businessApproval,
    businessForm,
    updateProfileLanguage,
    getMyprofile,
    role,
    onSwitch,
    storePreferredCurrency,
    PreferredCurrency,
    branch,
    closeBeta,
    betapopup,
    logouts,
    getnotification,
    getnotificationcount,
    notificationsCount,
    notifications,
    readnotification,
    showBusinessForm,
    userInfo,
  } = props || {};
  const [shownotify, setShowNotify] = useState(false); // set false to hide noifications

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      href=""
      ref={ref}
      className="smalltoggletext"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}

      <span className="toogleicon">
        <DropdownarrowIcon className="dropdownarrow" />
      </span>
    </span>
  ));
  useEffect(() => {
    if (auth) {
      getnotificationcount();
      getnotification();
    }
  }, []);
  useEffect(() => {
    setupWS();
  }, []);
  const setupWS = () => {
    if (userInfo) {
      let ws = new WebSocket(
        `wss://paymentsolutionapi.sparkrex.com/${userInfo.accessToken}`
      );
      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: "normal",
            userId: MyProfileInfo && MyProfileInfo.id,
          })
        );
        ws.onmessage = (e) => {
          let resp = JSON.parse(e.data);
          getnotificationcount();
          getnotification();
        };
      };
      ws.onclose = () => {
        //  setupWS();
      };
    }
  };

  const languages = [
    {
      key: "ENG",
      fullLanguageName: "English",
      apiKey: "en",
    },
    {
      key: "TUR",
      fullLanguageName: "Turkish",
      apiKey: "tr",
    },
    {
      key: "RUS",
      fullLanguageName: "Russian",
      apiKey: "ru",
    },
  ];
  const [language, setLanguage] = useState({
    key: "ENG",
    fullLanguageName: "English",
    apiKey: "en",
  });

  const [currency, setCurrency] = useState({
    key: PreferredCurrency,
  });
  let selectedLAngarray = languages.filter(
    (itm) => itm.apiKey === localStorage.getItem("lang")
  );
  let selectLang =
    (selectedLAngarray &&
      selectedLAngarray[0] &&
      selectedLAngarray[0].fullLanguageName) ||
    "English";

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const NotificationBell = ({ className }) => {
    return (
      <Nav.Item className={`itemsatrightpadding ${className}`}>
        <Nav.Link href="">
          <div className="languagewrapper notifycircle">
            <Dropdown show={shownotify}>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                {""}
                <Bell className="widthofimgbell" />
                {notificationsCount !== 0 && (
                  // <div className="yellowcircle">{notificationsCount}</div>
                  <div className="yellowcircle">
                    <span className="count">{notificationsCount}</span>
                  </div>
                )}
              </Dropdown.Toggle>
              <div>
                <NotificationDropdown setShowNotify={setShowNotify} />
              </div>
              {/* <Dropdown.Menu>
                <div className="notification">
                  {notifications &&
                    notifications.map((item) => {
                      let amount =''
                      if(item.type == "exchange")
                      {
                        let strings= JSON.parse(item.exchangeAmounts)
                        amount = `${strings[0].amount1} - ${strings[0].amount2} `
                      }
                      return(
                      <div key={item.id} className="details">
                        <div className="line1">
                          {item.type == "deposit" ? (
                            <Paid className="img" />
                          ) : item.type == "withdraw" ? (
                            <Withdraw className="img" />
                          ) : (
                            <Info className="img" />
                          )}
                          <div className="datentime">
                            {moment(item.createdOn).format("DD.MM.YYYY hh:mm")}
                          </div>
                        </div>
                        <div className="line2">
                          <div className="txt">
                            {item.type == "login"
                              ? t("notilogin")
                              : item.type == "withdraw"
                              ? t("notiwithdraw")
                              : item.type == "deposit"
                              ? t("notideposit")
                              : t("notiexchange")}
                          </div>
                        </div>
                        {item.type != "login" && (
                          <div className="line3">
                            <div className="txt">
                              {t("Amount")}: {""}
                            </div>
                            <div className="amount">
                              {item.type == "exchange"
                                ? amount
                                : item && item.amount}
                            </div>
                          </div>
                        )}
                      </div>
                    )})}
                </div>
              </Dropdown.Menu> */}
            </Dropdown>
          </div>
        </Nav.Link>
      </Nav.Item>
    );
  };

  const HelpDropd = ({ className }) => {
    return (
      <Nav.Item className={`itemsatrightpadding ${className}`}>
        <Nav.Link href="">
          <div className="helpwrapper">
            <Dropdown className="helpDrpArrow">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                {" "}
                <Help className="buttonsearch widthofimghelp" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="txtalign1">
                  <span className="helptxt1">
                    {t("Oursupportworks")}{" "}
                    <span className="helptxt2">24/7</span>
                  </span>
                </div>
                <div className="txtalign">
                  <a
                    href="#"
                    // href="https://t.me/Cybor_Holding"
                    // onClick={() =>
                    //   window.open("https://t.me/Cybor_Holding", "_blank")
                    // }
                  >
                    <img src={help2} className="helpimg" />
                  </a>

                  <a
                    href="#"
                    // href="https://api.whatsapp.com/send/?phone=905338301530&text&app_absent"
                    // onClick={() =>
                    //   window.open(
                    //     "https://api.whatsapp.com/send/?phone=905338301530&text&app_absent",
                    //     "_blank"
                    //   )
                    // }
                  >
                    <img src={help1} className="helpimg" />
                  </a>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav.Link>
      </Nav.Item>
    );
  };
  return (
    <div
      className={`mainnavwrapper ${
        thememode == "dark" ? "darknavwrapper" : null
      }`}
    >
      <Fragment>
        <Navbar sticky="top" fixed="top" expand="lg" className="navwhitebg">
          <Container className="navbarcontainer">
            <div className="formobilemenu d-flex d-lg-none">
              <Menu onClick={onSwitch} />
              {auth && (
                <div
                  onClick={() => {
                    readnotification();
                    setShowNotify(!shownotify);
                  }}
                >
                  <NotificationBell className="mobilemenuiconstyle d-flex d-md-none" />
                </div>
              )}
              {auth && (
                <HelpDropd className="mobilemenuiconstyle d-flex d-md-none" />
              )}
            </div>

            <Nav className="contentatend">
              <Nav.Item className="d-none d-md-flex">
                <div>
                  <SmallDropdown
                    options={languages}
                    setSelectedOption={(e) => {
                      languages.map((data) => {
                        if (data.key == e) {
                          localStorage.setItem("lang", data.apiKey);
                          i18n.changeLanguage(data.apiKey);
                          if (auth) {
                            updateProfileLanguage({ language: data.apiKey });
                          }
                          setLanguage(data);
                        }
                      });
                    }}
                    selectedOption={
                      languages.filter(
                        (data) => data.fullLanguageName == selectLang
                      )[0]?.key
                    }
                  />
                  <span className="navvl navline tempdisable"></span>
                </div>
              </Nav.Item>

              {auth && (
                <Nav.Item className="d-none d-md-flex">
                  <div>
                    <SmallDropdown
                      options={currencies}
                      setSelectedOption={(e) => {
                        currencies.map((data) => {
                          if (data.key == e) {
                            setCurrency(data);
                            storePreferredCurrency(data.key);
                          }
                        });
                      }}
                      selectedOption={currency.key}
                    />
                    <span className="navvl navline tempdisable"></span>
                  </div>
                </Nav.Item>
              )}

              <Nav.Item className="d-none d-md-flex">
                <div className="themeswitchwrapper">
                  <DarkLightToogle />
                </div>
              </Nav.Item>

              {auth && (
                <div
                  className="mt-10"
                  onClick={() => {
                    readnotification();
                    setShowNotify(!shownotify);
                  }}
                >
                  <NotificationBell className="d-none d-md-flex" />
                </div>
              )}
              {auth && <HelpDropd className="d-none d-md-flex" />}

              <Nav.Item className="itemsatrightpadding">
                {auth && (
                  <Dropdown as={NavItem} alignRight={false} align={"end"}>
                    <Dropdown.Toggle
                      as={NavLink}
                      className="dropdown-toggle::after"
                    >
                      <Profile
                        MyProfileInfo={MyProfileInfo}
                        classname={"pname"}
                        classname2={"navbarprofile"}
                        showverified={true} // set false for unverified account
                        showverifiedonmobile={true}
                        showoccupation={true}
                      />
                    </Dropdown.Toggle>
                    <div className="sidebarmenu">
                      <ProfileMenu MyProfileInfo={MyProfileInfo} />
                    </div>
                  </Dropdown>
                )}
              </Nav.Item>
            </Nav>
          </Container>
        </Navbar>

        {!branch && auth && role == "Business" && businessForm && (
          <RequestSent
            businessApproval={businessApproval}
            show={
              businessApproval == "Requested" || businessApproval == "Rejected"
            }
            getMyprofile={getMyprofile}
            thememode={thememode}
            exchange={exchange}
            logouts={logouts}
          />
        )}

        {!branch &&
          auth &&
          role == "Business" &&
          businessApproval != "Requested" &&
          businessApproval != "Approved" && (
            <BusinessSignup show={showBusinessForm} />
          )}
        {/* {!betapopup && auth && (
          <LoginWelcomePopup
            show={!betapopup}
            setShowModal={closeBeta}
            exchange={exchange}
            thememode={thememode}
          />
        )} */}
      </Fragment>
    </div>
  );
}
const mapStateToProps = ({ app = {}, user = {}, notification = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const MyProfileInfo = _.get(user, "MyProfileInfo", false);
  const auth = _.get(user, "auth", false);
  const role = _.get(user, "userInfo.role", false);
  const userInfo = _.get(user, "userInfo", {});

  const exchange = _.get(app, "settings.exchange", false);
  const businessForm = _.get(user, "MyProfileInfo.businessForm", false);
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");

  const businessApproval = _.get(user, "MyProfileInfo.businessApproval", false);
  const branch = _.get(user, "userInfo.branch", false);
  const showBusinessForm = _.get(user, "showBusinessForm", false);
  const betapopup = _.get(app, "betapopup", false);
  const notificationsCount = _.get(notification, "notificationsCount.count", 0);
  const notifications = _.get(notification, "notifications", []);

  return {
    businessForm,
    showBusinessForm,
    thememode,
    role,
    MyProfileInfo,
    auth,
    exchange,
    businessApproval,
    role,
    PreferredCurrency,
    branch,
    betapopup,
    notificationsCount,
    notifications,
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTheme: (data) => dispatch({ type: "updateTheme", payload: data }),
  changeLang: (lang) => dispatch(changeLanguage(lang)),
  updateProfileLanguage: (data) =>
    dispatch({ type: "updateProfile", payload: data }),
  getMyprofile: (data) =>
    dispatch({ type: "getMyprofilecalled", payload: data }),
  storePreferredCurrency: (data) =>
    dispatch({ type: "storePreferredCurrencydCalled", payload: data }),
  closeBeta: (data) => dispatch({ type: "closeBetaCalled", payload: data }),
  logouts: (data) => dispatch({ type: "logoutCalled", payload: data }),
  getnotification: (data) =>
    dispatch({ type: "getnotificationcalled", payload: data }),
  getnotificationcount: (data) =>
    dispatch({ type: "getnotificationcountcalled", payload: data }),
  readnotification: (data) =>
    dispatch({ type: "readnotificationcalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
