import React from 'react';
import "@components/onboarding-animated-image/BannerImageStyles.scss"
import { connect } from "react-redux";

import SignInBannerDarkBusiness from '@images/onboardingImages/SignIn_Black_Business.svg';
import SignInBannerLightBusiness from '@images/onboardingImages/SignIn_White_Business.svg';
import SignInBannerDarkPersonal from '@images/onboardingImages/Sign-in-Dark-personal.svg';
import SignInBannerLightPersonal from '@images/onboardingImages/Sign-in-light-personal.svg';

import SignUpBannerDarkBusiness from '@images/onboardingImages/Sign-up-Dark-business.svg';
import SignUpBannerLightBusiness from '@images/onboardingImages/Sign-up-light-business.svg';
import SignUpBannerDarkPersonal from '@images/onboardingImages/Sign-up-Dark-personal.svg';
import SignUpBannerLightPersonal from '@images/onboardingImages/Sign-up-light-personal.svg';

import circle1 from '@images/home-utp/circle1.svg'

import circle21 from '@images/home-utp/circle2.1.svg'
// import circle22 from '@images/home-utp/circle2.2.svg'
import circle23 from '@images/home-utp/circle2.3.svg'
import circle24 from '@images/home-utp/circle2.4.svg'

import circle31 from '@images/home-utp/circle3.1.svg'
import circle32 from '@images/home-utp/circle3.2.svg'
import circle33 from '@images/home-utp/circle3.3.svg'
import circle34 from '@images/home-utp/circle3.4.svg'

import circle41 from '@images/home-utp/circle4.1.svg'
import circle42 from '@images/home-utp/circle4.2.svg'
import circle43 from '@images/home-utp/circle4.3.svg'
import circle44 from '@images/home-utp/circle4.4.svg'
import circle45 from '@images/home-utp/circle4.5.svg'
import circle46 from '@images/home-utp/circle4.6.svg'
import circle47 from '@images/home-utp/circle4.7.svg'

const BannerImage = ({ role, thememode, imageChange, page }) => {
    function animationcode(){
        return(
            <div className="anim-circle">
                <div className="circle__item1">
                    <img src={circle1} alt="" className="circle1"/>
                </div>
                <div className="circle__item2">
                    <img src={circle21} alt="" className="circle21"/>
                    {/* <img src={circle22} alt="" className="circle22"/> */}
                    <img src={circle23} alt="" className="circle23"/>
                    <img src={circle24} alt="" className="circle24"/>
                </div>
                <div className="circle__item3">
                    <img src={circle31} alt="" className="circle31"/>
                    <img src={circle32} alt="" className="circle32"/>
                    <img src={circle33} alt="" className="circle33"/>
                    <img src={circle34} alt="" className="circle34"/>
                </div>
                <div className="circle__item4">
                    <img src={circle41} alt="" className="circle41"/>
                    <img src={circle42} alt="" className="circle42"/>
                    <img src={circle43} alt="" className="circle43"/>
                    <img src={circle44} alt="" className="circle44"/>
                    <img src={circle45} alt="" className="circle45"/>
                    <img src={circle46} alt="" className="circle46"/>
                    <img src={circle47} alt="" className="circle47"/>
                </div>
            </div>
        )
    }
  return(
  <div className="animatedBanner" >
      <div className='inner-animatedBanner'>
          { animationcode() }
      </div>
      {
          page == "signin" && (!imageChange && (
            <img
                className="banner-image"
                alt=""
                src={
                    thememode == "light"
                    ? SignInBannerLightBusiness
                    : SignInBannerDarkBusiness
                }
            />
          ) || (
            <img
                className="banner-image1"
                alt=""
                src={
                    thememode == "light"
                    ? SignInBannerLightPersonal
                    : SignInBannerDarkPersonal
                }
            />
          )) || (!imageChange && (
            <img
                className="banner-image"
                alt=""
                src={
                    thememode == "light"
                    ? SignUpBannerLightBusiness
                    : SignUpBannerDarkBusiness
                }
            />
          ) || (
            <img
                className="banner-image"
                alt=""
                src={
                    thememode == "light"
                    ? SignUpBannerLightPersonal
                    : SignUpBannerDarkPersonal
                }
            />
          ))
      }
  </div>
  )
};

const mapStateToProps = ({ app = {}, user = {} }) => {
    const thememode = _.get(app, "thememode", false);
    const role = _.get(user, "userInfo.role", "");
  
    return {
      thememode,
      role
    };
  };
  
  export default connect(mapStateToProps, null)(BannerImage);
  
