import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import "@components/dropdown-search/DropdownSearchStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { Dropdown, FormControl } from "react-bootstrap";
import { ReactComponent as DropdownarrowIcon } from "@images/dropdownsearch/dropdownarrow.svg";
import "@components/fiat-deposit-modal-dropdown/FiatDepositModalDropdownStyles.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
const FiatDepositModalDropdown = ({
  Selected,
  setSelectedIndex,
  scope,
  setshowInfo,
  platformBanks, 
  getPlatformBank
}) => {
  const [value, setValue] = useState("");
  const [dropdownshow, setShow] = useState(false);
  const options = platformBanks;
  const { t } = useTranslation();
  useEffect(() => {
    getPlatformBank();
  }, []);
  useEffect(() => {
     // Update the document title using the browser API
     console.log('setselectedindex' , setSelectedIndex)
     
  }, [setSelectedIndex]);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="dropdownlink"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (!dropdownshow) {
          onClick(e);
          // setShow(true);
        }
      }}
      // show={dropdownshow}
    >
      {children}
      <span className="toogleicon">
        <DropdownarrowIcon className="dropdownarrow" />
      </span>
    </div>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
       return (
        <div ref={ref} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.value.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <div className="fiatdropdown">
      <Dropdown align="end"  >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {scope && scope === "FiatDeposit" && Selected === "" && value ==="" ? (
            <span className="customDropDrownPlaceholder">
              Select a bank
            </span>
          ) : (
            <></>
          )}
          {
            value === "" &&
            <span
            className={scope == "FiatDeposit" ? "selectbank" : "selecteditem"}
          >
            {options && options[Selected] && options[Selected].colouredLogo && (
              <span
                className={scope == "FiatDeposit" ? "bankicon" : "coinicon"}
              >
                <img
                  src={
                    options &&
                    options[Selected] &&
                    `${process.env.REACT_APP_IMGLINK}${options[Selected].colouredLogo}`
                  }
                ></img>
              </span>
            )}
            {scope === "FiatDeposit" ? (
              <div className="banktext">
                <span className="bankspantext">
                  {" "}
                  {options &&
                    options[Selected] &&
                    options[Selected].supportedMethod[0]}
                </span>
                <span className="bankspantext">
                  {" "}
                  {options &&
                    options[Selected] &&
                    options[Selected].supportedMethod[1]}
                </span>
              </div>
            ) : (
              <span className="cointext">
                {options && options[Selected] && options[Selected].tokenName}
              </span>
            )}

            {((options &&
              options[Selected] &&
              options[Selected].type == "TRC20") ||
              (options &&
                options[Selected] &&
                options[Selected].type == "BEP20") ||
              (options &&
                options[Selected] &&
                options[Selected].type == "ERC20")) && (
              <span className="cointag">
                {options && options[Selected] && options[Selected].type}
              </span>
            )}
          </span>
          }
          
          <FormControl
            autoFocus
            className="search"
            // placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={value}
            autoComplete="off"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {options &&
            options.map((option, index) => {
              console.log('options' , options)
              console.log('index' , index)
              return (
                <Dropdown.Item
                  eventKey={index}
                  className={Selected == index ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log('index' , index)
                    console.log('e' , e)
                    setSelectedIndex(index);

                    setshowInfo(true);
                    setShow(false);
                  }}
                  value={option &&  option.bankName}
                >
                  <img
                    className="bankimg"
                    src={`${process.env.REACT_APP_IMGLINK}${option.colouredLogo}`}
                  />
                  <span className="bankname">{option.bankName}</span>
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = ({ fiat = {}, user = {} }) => {
  const platformBanks = _.get(fiat, "platformBanks", []);

  return { platformBanks };
};

const mapDispatchToProps = (dispatch) => ({
  getPlatformBank: (data) =>
    dispatch({ type: "getPlatformBankCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FiatDepositModalDropdown);
