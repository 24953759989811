import NavBar from "@components/navbar/NavBar";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import deposit from "@images/deposit.svg";
import withdraw from "@images/withdraw.svg";
import BigNumber from "bignumber.js";
import pending from "@images/pending.svg";
import profile from "@images/profile.svg";
import CustomButton from "src/components/custom-button/CustomButton";
import { ReactComponent as PrinterIcon } from "@images/printer.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import { ReactComponent as SquareIcon } from "@images/tcheck.svg";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import { ReactComponent as Replacementof3dots } from "@images/replacementof3dots.svg";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import Filter from "@components/filter/Filter.js";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "@components/footer-common/FooterCommon";
import CoinList from "@components/coin-list/CoinList";
import { Row, Col, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { ReactComponent as VerticleIcon } from "@images/Vertical.svg";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "@components/loader/Loader";
import DetailedPopup from "@components/detailed-popup/DetailedPopup";
import "@features/transfer-history/TransferHistoryStyles.scss";
import { useTranslation } from "react-i18next";
import ReactToPrint  ,{useReactToPrint}  from "react-to-print";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import "jspdf-autotable";
import { ClearSansBase64 } from 'src/custom-fonts-base64/index'

function TransferHistory(props) {
  const {
    thememode,
    getTransaction,
    myusertransactioninfo,
    transferhistoryCount,
    getTranferHistoryCount,
    myusertransactionloading,
    userID,
  } = props || {};
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [tableno, settableno] = useState("first");
  let [show, setshow] = useState(false);
  let [Idata, setIdata] = useState({});
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [isFilterSet, setIsFilterSet] = useState(false);
  const [transferType, setTransferType] = useState("");
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [orderBy,setOrderBy] = useState('')
  const [sortBy,setSortBy] = useState('')
  const [obj,setObj] = useState('');
  
  const [hideSpec , setHideSpec] = useState(true);
  const [compRef,setCompRef] = useState()
  const [language , setLanguage] = useState('en');
  

  const componentRef = useRef()
  useEffect(() => {
    setCompRef(componentRef)
  },[])
  useEffect(() => {
    if(hideSpec === false){
      setCompRef(componentRef)
      handlePrint();
      setHideSpec(true);

    }
  },[hideSpec])
  // console.log(componentRef.current)


  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });

  useEffect(() => {
    getTransaction({ skip: pages, limit, type: transferType });
    getTranferHistoryCount({ type: "transfer" });
    filterTransferInfoForExcelExport();
  }, []);


  useEffect(() => {},[myusertransactioninfo,transferhistoryCount])
  const { t } = useTranslation();

  useEffect(() => {
    setLanguage(localStorage.lang);
    filterTransferInfoForExcelExport();
  }, [localStorage.lang]);

  const filterTransferInfoForExcelExport = () => {
    let filtered = [];
    let headerList = [];
    if (myusertransactioninfo && myusertransactioninfo.length !== 0) {
      for (let i of myusertransactioninfo) {
        let newOBJ = {
          " TransactionId": i.id,
          "Date":
            i.createdOn !== undefined
              ? moment(i.createdOn).local().format("MM/DD/YYYY hh:mm A")
              : "",
          "From":
            i.sender !== undefined
              ? i.sender.name !== undefined
                ? i.sender.name
                : ""
              : "",
          "To":
            i.receiver !== undefined
              ? i.receiver.name !== undefined
                ? i.receiver.name
                : ""
              : "",
          "Amount": `${Number(
            i && i.fromCoin && i.fromCoin.decimals
              ? i.amount / 10 ** i.fromCoin.decimals
              : i.amount
          ).toFixed(5)} ${i.fromCoinSymbol}`,
          "Status": t(i.status),
        };

        filtered.push(newOBJ);
      }

      for (let i of Object.keys(filtered[0])) {
        let newOBJ = {
          title: t(i),
          dataIndex: i,
        };
        headerList.push(newOBJ);
      }

      let pack = {
        data: filtered,
        head: headerList,
      };

      setExcelHeader(pack.head);
      setExcelData(pack.data);

      return pack;
    }
    return filtered;
  };

  const generatePdf = () => {
    var doc = new jsPDF();
    doc.addFileToVFS("ClearSans-Regular.ttf",ClearSansBase64)
    doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
    doc.setFont("ClearSans","Regular");

    var col = [`${t("TransactionId")}`, `${t("Date")}`, `${t("From")}`, `${t("To")}`,`${t("Amount")}`, `${t("Status")}`];

    var rows = [];

    if (myusertransactioninfo && myusertransactioninfo.length !== 0) {
      myusertransactioninfo.forEach((element) => {
        var temp = [
          element.id,
          moment(element.createdOn).local().format("MM/DD/YYYY hh:mm A"),
          element.sender.name,
          element.receiver.name,
          BigNumber(element.amount).toFixed(6),
          t(element.status),
        ];

        rows.push(temp);
      });
    }
    doc.autoTable(col, rows, { startY: 5 ,
      styles: {
        font: 'ClearSans',
        fontSize: 8
      },
    });

    doc.save(`${t("TransferHistory")}.pdf`);
  };

  return (
    <ScreenLayout>
      <>
        <Container>
          <div id="transferhistory">
            <Row>
              <Col lg={12}>
                <div className="trouter ">
                  <div className="trasactiontitle">
                    <span>{t("TransferHistory")}</span>
                  </div>
                  <div className="filtersection">
                    <Filter
                      onApplyDate={(picker) => {
                        setIsFilterSet(true);
                        getTransaction({
                          skip: 0,
                          limit,
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          type: transferType,
                        });
                        getTranferHistoryCount({
                          startDate: picker.startDate,
                          endDate: picker.endDate,
                          type: transferType,
                        });
                      }}
                      oncancel={() => {
                        setStartDate({});
                        setEndDate({});
                        setIsFilterSet(true);
                        getTransaction({
                          skip: 0,
                          limit,
                          type: transferType,
                        });
                        getTranferHistoryCount();
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                    />
                  </div>
                </div>
                <div className="rightsection">
                  <div className="thethreebutton">
                    <div className="buttoncontainersm">
                      <ReactToPrint
                        trigger={() => (
                          <CustomButton
                            type="submit"
                            name={t("Print")}
                            testvalue={
                              <PrinterIcon className="iconcolor mleft" />
                            }
                            text={true}
                            image={true}
                            //   onClick={() => {
                            //     setcoinItem(items);
                            //     setShowDeposit(true)
                            //   }}
                          />
                        )}
                        content={() => componentRef.current}
                        onBeforeGetContent={() => {
                          orientation : 'landscape'
                          setHideSpec(false)
                        }}
                      />
                    </div>

                    <div className="buttoncontainersm">
                      <CustomButton
                        type="submit"
                        name="PDF"
                        testvalue={<PdfIcon className="mleft" />}
                        text={true}
                        image={true}
                        onClick={() => {
                          generatePdf();
                        }}
                      />
                    </div>

                    <div className="buttoncontainersm mr-0">
                      <ExportSheet
                        header={excelHeader}
                        fileName={`download`}
                        dataSource={excelData}
                        xlsx={XLSX}
                      >
                        <CustomButton
                          type="submit"
                          name={t("Excel")}
                          testvalue={<ExcelIcon className="mleft" />}
                          text={true}
                          image={true}
                          onClick={()=>{
                            filterTransferInfoForExcelExport();
                          }}
                        />
                      </ExportSheet>
                    </div>
                  </div>
                </div>
                {/* <div className="btmmargin">
                  <CurrencyTab
                    page={"transaction"}
                    changeTab={(e) => {
                      if (e == "second") {
                        setTransferType("rec");
                        getTransaction({ skip: 0, limit, type: "rec" });
                        setpages(0);
                        settableno(e);
                        getTranferHistoryCount({ type: "rec" });
                        setStartDate({});
                        setEndDate({});
                        setIsFilterSet(false);
                      }
                      if (e == "third") {
                        setTransferType("send");
                        getTransaction({ skip: 0, limit, type: "send" });
                        setpages(0);
                        settableno(e);
                        getTranferHistoryCount({ type: "send" });
                        setStartDate({});
                        setEndDate({});
                        setIsFilterSet(false);
                      }
                      if (e == "first") {
                        setTransferType("transffer");
                        getTransaction({ skip: 0, limit });
                        setpages(0);
                        settableno(e);
                        getTranferHistoryCount();
                        setStartDate({});
                        setEndDate({});
                        setIsFilterSet(false);
                      }
                    }}
                    tableno={tableno}
                  />
                </div> */}

                <DetailedPopup
                  setShowModal={setshow}
                  show1={show}
                  data={Idata}
                />
                <Table
                  responsive="sm"
                  className={`tablestyle ${
                    thememode == "light" ? "tableinlight" : "tableindark"
                  }`}
                  borderless
                  ref={componentRef}
                >
                  {myusertransactionloading && <Loader />}
                  <thead className="hidden-for-mobile">
                    <tr>
                      <th className="trtbleheading">{/* <SquareIcon /> */}</th>
                      <th className="trtbleheading">
                        <span className="heading">{t("TransactionId")}</span>
                        <span className="sorticon">
                          <SortupIcon className="uparrow"
                          onClick ={()=>{
                            setOrderBy('id');
                            setSortBy('ASC');
                            if (isFilterSet) {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                startDate,
                                endDate,
                                orderBy: 'id',
                              sort: 'ASC'
                              });
                            } else {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                orderBy: 'id',
                              sort: 'ASC'
                              });
                            }

                          }}
                          />

                          <SortdownIcon className="downarrow"
                          onClick={()=>{
                            setOrderBy('id');
                            setSortBy('DESC');
                            if (isFilterSet) {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                startDate,
                                endDate,
                                orderBy: 'id',
                              sort: 'DESC'
                              });
                            } else {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                orderBy: 'id',
                              sort: 'DESC'
                              });
                            }

                          
                          }}
                          />
                        </span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading">{t("Date")}</span>
                        <span className="sorticon">
                          <SortupIcon className="uparrow"
                          onClick={()=>{
                            setOrderBy('createdOn');
                            setSortBy('ASC');
                            if (isFilterSet) {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                startDate,
                                endDate,
                                orderBy: 'createdOn',
                              sort: 'ASC'
                              });
                            } else {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                orderBy: 'createdOn',
                              sort: 'ASC'
                              });
                            }

                          
                          }}
                          />

                          <SortdownIcon className="downarrow"
                          onClick={()=>{
                            setOrderBy('createdOn');
                            setSortBy('ASC');
                            if (isFilterSet) {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                startDate,
                                endDate,
                                orderBy: 'createdOn',
                              sort: 'DESC'
                              });
                            } else {
                              getTransaction({
                                skip: pages * limit,
                                limit,
                                // type: "send",
                                orderBy: 'createdOn',
                              sort: 'DESC'
                              });
                            }
                          }}
                          />
                        </span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading">{t("From")}</span>
                        <span className="sorticon">
                          <SortupIcon className="uparrow" 
                             onClick={()=>{
                               setObj('sender');
                              setOrderBy('name');
                              setSortBy('ASC');
                              
                              if (isFilterSet) {
                                getTransaction({
                                  skip: pages * limit,
                                  limit,
                                  // type: "send",
                                  startDate,
                                  endDate,
                                  orderBy: 'name',
                                sort: 'ASC',
                                obj : 'sender'
                                });
                              } else {
                                getTransaction({
                                  skip: pages * limit,
                                  limit,
                                  // type: "send",
                                  orderBy: 'name',
                                sort: 'ASC',
                                obj : 'sender'

                                });
                              }
  
                            
                            }} 

                          />

                          <SortdownIcon className="downarrow"
                               onClick={()=>{
                                setObj('sender');
                               setOrderBy('name');
                               setSortBy('DESC');
                               
                               if (isFilterSet) {
                                 getTransaction({
                                   skip: pages * limit,
                                   limit,
                                  //  type: "send",
                                   startDate,
                                   endDate,
                                //    orderBy: 'name',
                                //  sort: 'DESC',
                                //  obj : 'sender'
                                 });
                               } else {
                                 getTransaction({
                                   skip: pages * limit,
                                   limit,
                                  //  type: "send",
                                   orderBy: 'name',
                                 sort: 'DESC',
                                 obj : 'sender'
 
                                 });
                               }
   
                             
                             }} 
                          />
                        </span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading">{t("To")}</span>
                        <span className="sorticon">
                          <SortupIcon className="uparrow" 
                               onClick={()=>{
                                setObj('receiver');
                               setOrderBy('name');
                               setSortBy('ASC');
                               
                               if (isFilterSet) {
                                 getTransaction({
                                   skip: pages * limit,
                                   limit,
                                  //  type: "send",
                                   startDate,
                                   endDate,
                                   orderBy: 'name',
                                 sort: 'ASC',
                                 obj : 'receiver'
                                 });
                               } else {
                                 getTransaction({
                                   skip: pages * limit,
                                   limit,
                                  //  type: "send",
                                   orderBy: 'name',
                                 sort: 'ASC',
                                 obj : 'receiver'
 
                                 });
                               }
   
                             
                             }} 
                          
                          />

                          <SortdownIcon className="downarrow"
                                  onClick={()=>{
                                    setObj('receiver');
                                   setOrderBy('name');
                                   setSortBy('DESC');
                                   
                                   if (isFilterSet) {
                                     getTransaction({
                                       skip: pages * limit,
                                       limit,
                                      //  type: "send",
                                       startDate,
                                       endDate,
                                       orderBy: 'name',
                                     sort: 'DESC',
                                     obj : 'receiver'
                                     });
                                   } else {
                                     getTransaction({
                                       skip: pages * limit,
                                       limit,
                                      //  type: "send",
                                       orderBy: 'name',
                                     sort: 'DESC',
                                     obj : 'receiver'
     
                                     });
                                   }
       
                                 
                                 }} 
                          />
                        </span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading">{t("Coin")}</span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading">{t("Amount")}</span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading">{t("Status")}</span>
                      </th>
                      <th className="trtbleheading"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {myusertransactioninfo &&
                      myusertransactioninfo.map((item, index) => (
                        <tr className="maintdwrapper" key={`trans${index}`}>
                          <td className="">
                            <div className="innerTd ">
                              {item.receiver && item.receiver.id == userID ? (
                                <img src={deposit} className="imgstatus" />
                              ) : (
                                <img src={withdraw} className="imgstatus" />
                              )}
                            </div>
                          </td>
                          <td className="amt">
                            {" "}
                            <div className="innerTd id idtr">
                              <span className="idtxt"> #{item.id} </span>
                              <p className="hidden-for-desktop date">
                                {moment(item.createdOn)
                                  .local()
                                  .format("MM/DD/YYYY hh:mm A")}
                              </p>
                            </div>
                          </td>
                          <td className="amt">
                            <div className="innerTd id datewidth hidden-for-mobile ">
                              {moment(item.createdOn)
                                .local()
                                .format("MM/DD/YYYY hh:mm A")}
                            </div>
                          </td>
                          <td className="amt hidden-for-mobile">
                            <div className="pfname innerTd id datewidth">
                              <span>
                                {item.sender && item.sender.name}{" "}
                                {item.sender && item.sender.subName}
                              </span>
                            </div>
                          </td>
                          <td className="amt alignleft hidden-for-mobile ">
                            <div className="pfname innerTdforprofile id idpr   ">
                              <span>
                                {" "}
                                {(item.receiver &&
                                  item.receiver.profileImage &&
                                  item.receiver.profileImage.xs &&
                                  !item.receiver.profileImage.xs.includes(
                                    "profile-16"
                                  ) && (
                                    <img
                                      src={`${process.env.REACT_APP_IMGLINK}${item.receiver.profileImage.xs}`}
                                      className="realprofileimg"
                                    />
                                  )) || (
                                  <img src={profile} className="profilelogo" />
                                )}
                              </span>{" "}
                              {item.receiver && item.receiver.name}{" "}
                              {item.receiver && item.receiver.subName}
                            </div>
                          </td>
                          {/* //alignleft */}
                          {hideSpec ?  <td className=" hidden-for-mobile ">
                            <div className="colwidth innerTdforprofile idtr id">
                              <CoinList
                                name={item.TypeCurrency ==="Crypto"? item.tOrCName: item.currencyName}
                                logoBgColor={item?.TypeCurrency ==="Crypto"?
                                item && item.coin && item.coin.logoBgColor: 
                                item && item.currency && item.currency.logoBgColor
                              }
                              logo={item?.TypeCurrency ==="Crypto"?
                                item &&
                                item.coin &&
                                item.coin.nonColouredLogo:
                                item &&
                                item.currency &&
                                item.currency.nonColouredLogo
                              }
                              />
                              {(item.type == "TRC20" ||
                                item.type == "BEP20" ||
                                item.type == "ERC20") && (
                                <span className="cointag">{item.type}</span>
                              )}
                            </div>
                          </td> :  <td className=" hidden-for-mobile ">
                            <div className="colwidth innerTdforprofile idtr id">
                             <p>{item.tOrCName}</p>
                            </div>
                          </td>}
                        
                                  {hideSpec ?<td className="amount  ">
                            <div className="innerTd id">
                              <span className="amttxt">
                                {Number(
                                  item && item.coin && item.coin.decimals
                                    ? item.amount / 10 ** item.coin.decimals
                                    : item.amount
                                ).toFixed(5)}
                              </span>
                              <p className="hidden-for-desktop">
                                <CoinList
                                  // name={item.tOrCName}
                                name={item?.TypeCurrency ==="Crypto"? item.tOrCName: item.currencyName}

                                  logoBgColor={item?.TypeCurrency ==="Crypto"?
                                    item && item.coin && item.coin.logoBgColor: 
                                    item && item.currency && item.currency.logoBgColor
                                  }
                                  logo={item?.TypeCurrency ==="Crypto"?
                                    item &&
                                    item.coin &&
                                    item.coin.nonColouredLogo:
                                    item &&
                                    item.currency &&
                                    item.currency.nonColouredLogo
                                  }
                                />
                                {(item.type == "TRC20" ||
                                  item.type == "BEP20" ||
                                  item.type == "ERC20") && (
                                  <span className="cointag">{item.type}</span>
                                )}
                              </p>
                            </div>
                          </td> :<td className="amount  ">
                            <div className="innerTd id">
                              <span className="amttxt">
                                {Number(
                                  item && item.coin && item.coin.decimals
                                    ? item.amount / 10 ** item.coin.decimals
                                    : item.amount
                                ).toFixed(5)}
                              </span>
                              <p className="hidden-for-desktop">
                              {item.tOrCName}
                              </p>
                            </div>
                          </td>}
                          
                          {item?.TypeCurrency ==="Crypto"?
                                    item.status == "completed" ? (
                                      <td className="status completed hidden-for-mobile">
                                        <div className="innerTd id">
                                          {t(`${item.status}`)}
                                        </div>
                                      </td>
                                    ) : item.status == "failed" ? (
                                      <td className="status cancel hidden-for-mobile">
                                        <div className="innerTd id">
                                          {t(`${item.status}`)}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="status pending hidden-for-mobile">
                                        <div className="innerTd id">
                                          {t(`${item.status}`)}
                                        </div>
                                      </td>
                                    )
                                    :
                                    item.fiatTransactionStatus == "Successfull" ? (
                                      <td className="status completed hidden-for-mobile">
                                        <div className="innerTd id">
                                          {t(`${"Completed"}`)}
                                        </div>
                                      </td>
                                    ) : item.status == "failed" ? (
                                      <td className="status cancel hidden-for-mobile">
                                        <div className="innerTd id">
                                          {t(`${item.fiatTransactionStatus}`)}
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="status pending hidden-for-mobile">
                                        <div className="innerTd id">
                                          {t(`${item.fiatTransactionStatus}`)}
                                        </div>
                                      </td>
                                    )
                          }
                
                    {hideSpec ?<td className="completed ">
                            <div className="vertical innerTd">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="threedot"
                                  id="dropdown-basic"
                                >
                                  <VerticleIcon className="arrowclass hidden-for-mobile" />
                                  <Replacementof3dots
                                    className=" arrow hidden-for-desktop"
                                    onClick={() => {
                                      setIdata(item);
                                      setshow(true);
                                    }}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setIdata(item);
                                      setshow(true);
                                    }}
                                  >
                                    {t("Details")}
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    // onClick={() => {
                                    //   openModal(), setname("Delete");
                                    // }}
                                    >
                                    Cancel
                                    </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td> : <td></td>}
                          
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {transferhistoryCount > limit && (
                  <div className="paginationsection hidden-for-mobile">
                    <div className="paginationtxtholer">
                      <span className="noofpagetxt">
                        {t("Showing")} {pages + 1} {t("from")}{" "}
                        {Math.ceil(transferhistoryCount / limit)}
                      </span>
                    </div>
                    <div className="paginationallignment">
                      <ReactPaginate
                        forcePage={pages}
                        previousLabel={
                          <PreviousIcon className="paginationicon" />
                        }
                        nextLabel={<NextIcon className="paginationicon" />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={transferhistoryCount / limit}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={(e) => {
                          // if (tableno == "second") {
                          //   if (isFilterSet) {
                          //     getTransaction({
                          //       skip: e.selected * limit,
                          //       limit,
                          //       type: "rec",
                          //       startDate,
                          //       endDate,
                          //     });
                          //   } else {
                          //     getTransaction({
                          //       skip: e.selected * limit,
                          //       limit,
                          //       type: "rec",
                          //     });
                          //   // }
                          //   setpages(e.selected);
                          // }
                          // // if (tableno == "third") {
                          //   if (isFilterSet) {
                          //     getTransaction({
                          //       skip: e.selected * limit,
                          //       limit,
                          //       type: "send",
                          //       startDate,
                          //       endDate,
                          //     });
                          //   } else {
                          //     getTransaction({
                          //       skip: e.selected * limit,
                          //       limit,
                          //       type: "send",
                          //     });
                          //   // }

                          //   setpages(e.selected);
                          // }
                          // if (tableno == "first") {
                            if (isFilterSet) {
                              getTransaction({
                                skip: e.selected * limit,
                                limit,
                                startDate,
                                endDate,
                                orderBy: orderBy,
                                sort: sortBy,
                                obj: obj
                              });
                            } else {
                              getTransaction({
                                skip: e.selected * limit,
                                limit,
                                orderBy: orderBy,
                                sort: sortBy,
                                obj: obj
                              });
                            }
                            setpages(e.selected);
                          // }
                        }}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Container>
        <FooterCommon />
      </>
    </ScreenLayout>
  );
}
const mapStateToProps = ({
  app = {},
  wallet = {},
  user = {},
  translationLanguage = {},
}) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const userID = _.get(user, "MyProfileInfo.id", 0);

  const myusertransactioninfo = _.get(wallet, "myusertransactioninfo", []);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const myusertransactionloading = _.get(
    wallet,
    "myusertransactionloading",
    false
  );
  const transferhistoryCount = _.get(wallet, "transferhistoryCount.Count", 10);
  const currentLang = _.get(translationLanguage, "language", false);
  const thememode = _.get(app, "thememode", false);
  return {
    mywalletsinfo,
    myusertransactioninfo,
    myaccountandbalance,
    transferhistoryCount,
    myusertransactionloading,
    userID,
    currentLang,
    thememode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTranferHistoryCount: (data) =>
    dispatch({ type: "getTranferHistoryCountCalled", payload: data }),
  getTransaction: (data) =>
    dispatch({ type: "getUserTransactionsCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferHistory);
