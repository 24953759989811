import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Form } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import Branchsuccessful from "@components/branch-successful/Branchsuccessful";
import InputField from "@components/input-field/InputField";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import pprofile from "@images/pprofile.svg";
import { connect } from "react-redux";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import "@features/create-loan-type/CreateLoanType.scss";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import { ReactComponent as Arrow } from "@images/rightarrow.svg";
import BranchCreatedSuccessfulPopup from "@components/branch-created-successful-popup/BranchCreatedSuccessfulPopup"
import AdminLoanSuccesfulPopup from "src/components/admin-loan-template-successfull-popup/AdminLoanSuccesfulPopup";
// import {useHistory} from 'react-router-dom'
import SuccessPopup from "src/components/personal-loan-success-popup/SuccessPopup";


function CreatePersonalLoanType({
  createPersonalLoanError,
  createPersonalLoanLoading,
  createPersonalLoanSagaCalled,
  getplatformTokensinfo,

}) {
  const history = useHistory();
  const [loanName , setLoanName] = useState("")
  const [tenure , setTenure] = useState("")
  const [loanAmount , setLoanAmount] = useState("")
  const [coin , setCoin] = useState("");
  const [loanInterestRate , setLiquidationLTV] = useState("")
  const [validated , setValidated] = useState(false);
  const [coinId , setCoinId] = useState("");
  let [sucess , setShowSuccess] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValid2 , setIsValid2] = useState(true);
  const [isValid3 , setIsValid3] = useState(true);
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    setTenure(e.target.value);
    if (value >=6  && value <= 120){      
      setIsValid(true) && setFlag(true)
  }else{
    setIsValid(false) && setFlag(false)

  }
    
    // setIsValid(value >= 0) && setFlag(true); // Check if the value is non-negative
  };
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setLoanAmount(e.target.value);
    
    setIsValid2(value > 0) && setFlag(true); // Check if the value is non-negative
  };
  const handleInputChange3 = (e) => {
    const value = e.target.value;
    setLiquidationLTV(e.target.value);
    
    if (value >0  && value <= 20){      //Check if the value is non negative and less than 20
        setIsValid3(true) && setFlag(true)
    }else{
      setIsValid3(false) && setFlag(false)

    }
    // setIsValid3(value >= 0) && setFlag(true); // Check if the value is non-negative
  };
  const cancelCreation = () => {
    setLoanName("")
    setTenure("")
    setLoanAmount("")
    setLiquidationLTV("")
    setValidated(false);
    setIsValid(true)
    setIsValid2(true)
    setIsValid3(true)
    history.push('/adminLoanManagement')
  };

  const createLoanType = (form) => {

     const data = {
      loanName : loanName,
      tenureInMonths : tenure,
      loanAmount : loanAmount,
      loanInterestRate : loanInterestRate, 
      openSuccess : ()=>setShowSuccess(true)
     }
     console.log('data' , data)
     createPersonalLoanSagaCalled(data)
  };

  const FormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      // clearErrorMessage();
    } else {
      event.preventDefault();
      event.stopPropagation();
      if(isValid && isValid2 && isValid3){
       createLoanType(form);
      }
      
    }
  };

   // const onSendmsg = (event) => {
  //   event.preventDefault();
  //   if (Image !== null && image !== '') {
  //     uploadImage();
  //   }
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   setValidated(true);
  // };

  useEffect(()=>{
    console.log('coin' ,coinId , getplatformTokensinfo)
  } , [coin])
  

  
  
  const { t } = useTranslation();
  return (
    <ScreenLayout className="hidden">
      <div className="modalPrimaryHeader"></div>
      <div id="CreateLoan">
        {/* <BranchCreatedSuccessfulPopup showModal={createbranchsuccesspopup}  /> */}
        <SuccessPopup
          setShowModal={setShowSuccess}
          showModal={sucess}
          message={"Bank Loan Type created successfully"}
        />
        <Container className="containerallignm">
          {createPersonalLoanLoading && <Loader />}
          <Row>
            <Col lg={12}>
              
              <Form noValidate onSubmit={FormSubmit} validated={validated}>
                <div className="outercontainer">
                  <div className="hideformobile toptitle">
                    <span>{t("Create Bank Loan Type")}</span>
                  </div>
                  <div className="hidefordesktop">
                  <div className="d-flex align-items-center mb-5" onClick={()=>history.goBack()} >
                    <Arrow className="arrowicon" />
                    <p className="addNewText">{t("Create Loan Type")}</p>
                  </div>
                  </div>
                 

                 
                  <div className="titles">
                    <span>{t("Loan Name")}</span>
                    <div className="inputbox">
                      <InputField
                        type="text"
                        onChange={(e) => setLoanName(e.target.value)}
                        value={loanName}
                        isInvalid={validated === true}
                        required
                        error={
                          loanName.length === 0
                            ? "Please enter loan type name"
                            : ""
                        }
                      />
                    </div>
                  </div>
                 

                 
                    {/* <div className="titles">
                    <span>{t("Coin")}</span>
                    <div className="inputbox">
                    <DropdownSearch
                        Selected={coin}
                        options={getplatformTokensinfo}
                        setSelectedIndex={(e) => {setCoin(e)}}
                        setCoinId = {(e)=>setCoinId(e)}
                        scope={"loan"}
                      />
                    </div>
                  </div> */}
                  
                  <div className="titles">
                    <span>{t("Loan Amount")}</span>
                    <div className="inputbox">
                      <InputField
                        type="number"
                        onChange={(e) => handleInputChange2(e)}
                        value={loanAmount}
                        isInvalid={validated === true}
                        required
                        error={
                          loanAmount.length === 0
                            ? "Please enter Loan Amount"
                            : ""
                        }
                      />
                    </div>
                    {!isValid2 && 
                  <span className="errortext">The Amount must be positive and greater than zero.</span>
                }
                  </div>
                  <div className="titles">
                    <span>{t("Interest Rate (%)")}</span>
                    <div className="inputbox">
                      <InputField
                        type="number"
                        onChange={(e) => handleInputChange3(e)}
                        value={loanInterestRate}
                        isInvalid={validated === true}
                        required
                        error={
                          loanInterestRate.length === 0
                            ? "Please enter Interest rate"
                            : ""
                        }
                      />
                    </div>
                    {!isValid3 && 
                  <span className="errortext">Please enter the Interest rate between 1 and 20.</span>
                }
                  </div>

                   <div className="titles">
                    <span>{t("Tenure (In Months)")}</span>
                    <div className="inputbox">
                      <InputField
                        type="number"
                        onChange={(e) => handleInputChange(e)}
                        value={tenure}
                        isInvalid={validated === true}
                        required
                        error={
                          tenure.length === 0
                            ? "Please enter tenure"
                            : ""
                        }
                      />
                    </div>
                    {!isValid && 
                  <span className="errortext">Please enter the Tenure between 6 and 120 months.</span>
                 }
                  </div>
                 

                  <div className="btmbtnoutercontainer">
                    <div className="topbuttons">
                      <CustomButton
                        type="submit"
                        name={t("Create")}
                        filled={true}
                        primary={true}
                        //onClick={createBranch}
                      />
                    </div>
                    <div className="bottombuttons">
                      <CustomButton
                        type="button"
                        name={t("Cancel")}
                        filled={true}
                        primary={true}
                        onClick={cancelCreation}
                      />
                    </div>
                  </div>
                  {/* <p className="error-msg">{t(`${errorMessage}`)}</p> */}
                </div>
              </Form>
            </Col>
          </Row>
          {/* <Branchsuccessful
            showDeleteConfirmation={businessModal}
            setShowDeleteConfirmation={(e)=>{
              setbusinessModals(false)
              history.push('/branchmanagement')
            }}
          /> */}
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}

const mapStateToProps = ({ wallet = {} }) => {
  const createPersonalLoanLoading = _.get(wallet , "addPersonalLoanLoading" , false);
  const createPersonalLoanError = _.get(wallet , "addPersonalLoanError" , undefined);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  

  return {
    createPersonalLoanError,
    createPersonalLoanLoading,
    getplatformTokensinfo
  };
};
const mapDispatchToProps = (dispatch) => ({

    createPersonalLoanSagaCalled : (data)=>dispatch({type : "CreatePersonalLoanAdminSagaCalled" , payload : data})
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePersonalLoanType);
