import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import BankListLayout from "src/components/bank-list-layout/BankListLayout";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import CurrencyTab from "@components/currency-tab/CurrencyTab";
import CustomButton from "@components/custom-button/CustomButton";
import FiatDepositForm from "@components/fiat-form/FiatDepositForm.js";
import "@components/depositfiat-modal/DepositFiatModalStyles.scss";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import FiatDepositModalDropdown from "../fiat-deposit-modal-dropdown/FiatDepositModalDropdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "../loader/Loader";
import FooterCommon from "@components/footer-common/FooterCommon";

const DepositFiatModal = ({
  setShowDeposit,
  show,
  selectedCurrency,
  tableNo,
  setcoinItem,
  setTableno,
  showToggle,
  allplatformbanks
}) => {
  let [tableno, settableno] = useState("second");
  let [showInfo, setshowInfo] = useState(false);
  const [hideThis, setHideThis] = useState(false);
  const [selected, setSelected] = useState('');
  
  let createBankAccountLoading = useSelector(
    (state) => state.fiat && state.fiat.createBankAccountLoading
  );
  const { t } = useTranslation();
  //  useEffect(() => {
  //   console.log('to know data coming from deposit fiat model' , allplatformbanks)
  //   setSelected(
  //    allplatformbanks.findIndex((e) => e.id == allplatformbanks[id])
  //   );
  //  }, []);
 

  return (
    <Modal
      className={`depositfiatmodal ${hideThis == true ? "hidethisexchangemodal" : null
        }`}
      show={show}
      onHide={() => setShowDeposit(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName={`${hideThis == true && "mod-exchangebackdrop"}`}
      centered
    >
      <Modal.Body>
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
        <CloseIcon className="closebtn " onClick={() => setShowDeposit(false)}  />
        <div
          className="back hidden-for-desktop"
          onClick={() => {
            setShowDeposit(false)
          }}
        >
          <ExpandIcon />
        </div>
        <Container>
          <Row>
            <Col lg={6}>
              <span className="toptitle1 ">{t("BankTransfer")}</span>
            </Col>
            <Col lg={6}>
              {showToggle === true && (
                <div className="toptoggle">
                  <CurrencyTab
                    page={"currency"}
                    tableno={tableno}
                    changeTab={(e) => setTableno(e)}
                  />
                </div>
              )}
            </Col>
          </Row>
          {createBankAccountLoading && <Loader />}
          <Row>
            <Col lg={6} className="toprowspaceforsmall ">
              <BankListLayout setshowInfo={setshowInfo} className="hidden-for-mobile" />
              <div className="hidden-for-desktop">
              <FiatDepositModalDropdown 
              setshowInfo={setshowInfo} 
              options={allplatformbanks} 
              scope="FiatDeposit"
               setSelectedIndex={(e) => {
                 setSelected(e);
                // setcoinItem(getplatformTokensinfo[e]);
              }}
              Selected={selected}
              />
              </div>
            </Col>
            <Col lg={6} className=" toprowspace">
              {!showInfo && (
                <div className="info">
                  <h4 className="toptitle">{t("TLWallet")}</h4>
                  <ul>
                    <li>{t("List1")}</li>
                    <li>{t("List2")}</li>
                    <li>{t("List3")}</li>
                    <li>{t("List4")}</li>
                    <li>
                      <a href="" className="alink">
                        {" "}
                        {t("Click")}
                      </a>{" "}
                      {t("DetailsOnTL")}
                    </li>
                  </ul>
                </div>
              )}
              <div className="fiatformarea">
                {showInfo && (
                  <FiatDepositForm
                    items={selectedCurrency}
                    setcoinItem={setcoinItem}
                    setHideThis={(e) => setHideThis(e)}
                    setShowDeposit={(e) => setShowDeposit(e)}
                  />
                )}
              </div>
            </Col>
          </Row>

          {/* <div className="close">
            <CustomButton
              type="submit"
              name="Close"
              filled={true}
              primary={true}
            />
          </div> */}
        </Container>
        <div className="hidden-for-desktop">
            <FooterCommon />
          </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(DepositFiatModal);
