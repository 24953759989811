import React from "react";
import { Tab } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import "@components/currency-tab/CurrencyTabStyles.scss";
import { useTranslation } from "react-i18next";

function CurrencyTab({ children, page, changeTab, tableno }) {
  const { t, i18n, ready } = useTranslation();

  return (
    <div className="CurrencyTab">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={"first"}
        activeKey={tableno}
      >
        {page == "currency" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("Crypto")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Fiat")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {page == "exchge" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {"In House "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {"Decentralized"}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {page == "transaction" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("All")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Received")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third" onClick={() => changeTab("third")}>
                {t("Sent")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {page == "depositewithdraw" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("Crypto")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Fiat")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third" onClick={() => changeTab("third")}>
                {/* {t("Withdrawals")} */}
                {t("All")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {page == "payment" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="fourth" onClick={() => changeTab("fourth")}>
                {t("All")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("Captured")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Pending")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third" onClick={() => changeTab("third")}>
                {t("Cancelled")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}

        {page == "businessrequest" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="third" onClick={() => changeTab("third")}>
                {t("All")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("Approved")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Rejected")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}

        {page == "TransferForm" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("Transfer")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Round")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}

        {page == "userslist" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("All")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Personal")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third" onClick={() => changeTab("third")}>
                {t("Business")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth" onClick={() => changeTab("fourth")}>
                {t("Branch")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {page == "sidebar" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("Business")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Personal")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {page == "settings" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                {t("MyProfile")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                {t("Myaccounts")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        <Tab.Content>{children}</Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default CurrencyTab;
