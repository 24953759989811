export default {
  Branch: 'Şube',
  Management: 'Yönetim',
  No: 'Numara',
  Passive: 'Pasif',
  Active: 'Aktif',
  Account: 'Hesap',
  Phone: 'Telefon',
  Address: 'Adres',
  Edit: 'Düzenle',
  Sorrynoresultsfound: 'Üzgünüz, sonuç bulunamadı',
  Showing: 'Gösteriliyor',
  from: 'itibaren',
  CreateBranch: 'Şube Oluştur',
  MyBranch: "Benim Şubem",
  BranchCreatedSuccessfully: "Şube Başarıyla Oluşturuldu",
  Okay: "Peki",
  DefiLoans: "Kripto Kredisi",
  BankLoan: "Banka borcu",
  Cryptocards: "Kripto Kartları",
  Buysellcrypto: " Kripto Al/Sat",
  Loanrequests: "Kredi Talepleri",
  Borrow: "Borç almak",
  LoanID: "kredi kimliği",
  BorrowedAmount: "Ödünç Alınan Tutar",
  BorrowedCoin: "Ödünç Para",
  Tenure: "görev süresi",
  InDays: "Günlerde",
  InitialLTV: "İlk YBD",
  LiquidationLTV: "Tasfiye LTV",
  CreationDate: "Oluşturulma tarihi",
  Details: "Detaylar",

  BankLoanRequests: "Banka Kredisi Talepleri",
  BorrowedCurrency: "Ödünç Alınan Para Birimi",
  InterestRate: "Faiz oranı",
  Status: "Durum",
  RequestedDate: "Talep edilen tarih",
  ViewDocuments: "Belgeleri Görüntüle",
  ApproveReject: "Reddetmeyi onayla",
  InMonths : "Aylarca",
  LoanAmount: "Kredi miktarı",
  Clicktoborrow : "Ödünç almak için tıklayın",
};
