import * as _ from "lodash";
import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import CustomButton from "src/components/custom-button/CustomButton";
import copy from "@images/copy1.svg";
import share from "@images/share1.svg";
import qr from "@images/qr.png";
import QRCode from "react-qr-code";
import "@components/paymentdetail-popup/PaymentDetailPopup.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";


const PaymentDetailPopup = ({
  thememode,
  settings,
  setShowModal,
  data,
  show1,
  onChange,
  val,
}) => {
  const { amount, acceptAs, exchangeTo, paymentStatus } = data || {};
  let symbol = "";

  if (data.exchangeTo == "Ethereum") {
    symbol = "ETH";
  } else if (data.exchangeTo == "Tether") {
    symbol = "USDT";
  } else if (data.exchangeTo == "CyborCoin") {
    symbol = "CYBOR";
  } else if (data.exchangeTo == "BSC") {
    symbol = "BSC";
  } else if (data.exchangeTo == "Tron") {
    symbol = "TRX";
  } else {
    symbol = "BTC";
  }

  const { t, i18n, ready } = useTranslation();

  return (
    <Modal
      className="paymentdetail"
      show={show1}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modalPrimaryHeader hidden-for-desktop"></div>
        <CloseIcon
          className="closebtn hidden-for-mobile"
          onClick={() => setShowModal(false)}
        />
        <div
          className=" hidden-for-desktop back "
          onClick={() => setShowModal(false)}
        >
          <ExpandIcon />
        </div>
        <Container>
          <div className="number-section">
            <span className="text">{t("PaymentLinkDetails")}</span>
          </div>

          <div className="qrsection">
            <QRCode
              size="200"
              className={"margin"}
              value={`${window.location.origin}/pay/${link}`}
              bgColor={
                thememode == "light"
                  ? settings &&
                    settings.lightTheme &&
                    settings.lightTheme.bgwhite
                  : settings && settings.darkTheme && settings.darkTheme.bgwhite
              }
              fgColor={
                thememode == "light"
                  ? settings &&
                    settings.lightTheme &&
                    settings.lightTheme.textdarker
                  : settings &&
                    settings.darkTheme &&
                    settings.darkTheme.textdarker
              }
            />
          </div>

          <div className="detail ">
            <div className="label">
              <span className="labeltext">{t("Total")}</span>
            </div>
            <div className="value">
              <span className="valuetext">
                {amount} {symbol}
              </span>
            </div>
          </div>

          <div className="detail ">
            <div className="label">
              <span className="labeltext">{t("From")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{data.email}</span>
            </div>
          </div>

          <div className="detail ">
            <div className="label">
              <span className="labeltext">{t("Status")}</span>
            </div>
            <div className="value">
              <span className="valuetext">{t(`${paymentStatus}`)}</span>
            </div>
          </div>

          <div className="buttoncontainer hidden-for-mobile">
            <CustomButton
              type="submit"
              name={t("Close")}
              filled={true}
              primary={true}
              onClick={() => setShowModal(false)}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ app = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  return {
    thememode,
    settings,
  };
};

export default connect(mapStateToProps, null)(React.memo(PaymentDetailPopup));
