export default {
  EMAIL_NOT_FOUND: "Email not found",
  EMAIL_NOT_VERIFIED: "Email not verified",
  MOBILE_NOT_VERIFIED: "Mobile not verified",
  INVALID_PASSWORD: "Invalid Password",
  pass_token_used: "Pass token used",
  pass_token_expire: "Pass token expire",
  password_not_Empty: "Password should not be empty",
  ConfirmTheLogin: "Confirm the login",
  FromNewDevice: "From the new device",
  EmailConfirmationCode: "Email confirmation code",
  Enter6digitCode: "Enter the 6-digit code received on",
  AuthenticationCode: "Authentication code",
  GoogleAuthenticatorCode:
    " Enter the 6-digit code received from Google Authenticator",
  NoAccessSecurityCheck: "No access to security check?",
  Send: "Send",
  Resend: "Resend",
  GetCode: "Get the code",
  //undefined: "Undefined",
  exchangeId_empty: "ExchangeId should not be empty",
  WantToLogout: "Do you want to logout?",
  Yes: "Yes",
  No: "No",
  Invaild_role: "Invaild role",
  SignIn: "Sign in hello",

  //Newly added
  EmailID: "Email ID",
  EnterPassword: "Enter Password",
  Email: "Email",
  Password: "Password",
  DontHaveAccount: "Don't have an account?",
  Register: "Register",
  SignInTo: "Sign In to",
  CyborPay: "PayCrypt",
  CyborPayBusiness: "PayCrypt Business",
  ForgotPassword: "Forgot password ?",
  SignIn: "Sign In",
  SignUp: "Sign Up",
  PasswordNotEmpty: "Password should not be empty",
  ForgotPassword: "Forgot Password",
  ProvideEmail: "Please provide your email address.You will receive an email with a link to update your password",
  YourEmail: "Your Email",
  ResetPassword: "Reset Password",
  PasswordResetEmailSent: "Password Reset Email Sent",
  EmailHasSent: "An email has been sent to your rescue email address. Follow directions in the email to reset your password.",
  OK: "OK",
  CheckProtectYourAccount: " Please check the following to protect your account",
  WelcomeLine1: "Thank you for using PayCrypt payment system",
  WelcomeLine2: "The system is currently in beta testing. You can take part in testing our system.",
  "Invalid Authentication Code": "Invalid Authentication Code",
  InvalidEmailCode: "Invalid Email Code",
  support24: "Our support works for you",
  securitytitle1: "Please, contact our technical ",
  securitytitle2: " support to restore access to your ",
  securitytitle3: "account via email",
  optionselection1: "Please, select from",
  optionselection2: "option below",
  buttonselection1: "Have no access to e-mail",
  buttonselection2: "Have no access to GA",
  MOB_EMPTY: "Mobile number is empty",
  EMAIL_EMPTY: "Email ID is empty",
  PASS_EMPTY: "Password is empty",
  EXCHANGE_EMPTY: "Exchange ID is empty",
  LANG_EMPTY: "Please select language",
  NAME_EMPTY: "Name is empty",
  SUB_EMPTY: "Last Name is empty",
  EMAIL: "Invalid email",
  PASS_STRING: "Password should be a string",
  MIN_LENGTH: "Password should be of minimun 8 characters",
  MAX_LENGTH: "Password should not extend 20 characters",
};
