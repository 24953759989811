import * as _ from "lodash";
import React from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/log-out/LogoutStyles.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { connect } from "react-redux";
import "@components/forgot-password-email-success/Forgotpasswordemailsuccess.scss";
import { useTranslation } from "react-i18next";
import { closecreateBranchSuccessPoup } from "@store/appredux";
const BranchCreatedSuccessfulPopup = ({
  showModal,
  setShowModal,
  onCancel,
  closePopup,
}) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="forgotpasswordemailsuccess"
      show={showModal}
      onHide={closePopup()}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon className="closebtn" onClick={closePopup()} />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="exchangetext passwordtitle">
                Branch Creation Success
              </div>
              <hr />
            </Col>
            <Col lg={12}>
              <div className="success-content passcontent">
                <span className="titleText">
                  Branch has been successfully created and will be added after
                  verification.
                </span>{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="ok-btn">
                <CustomButton
                  name={t("OK")}
                  className="close-btn"
                  filled={true}
                  primary={true}
                  onClick={closePopup()}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closecreateBranchSuccessPoup()),
});

export default connect(
  null,
  mapDispatchToProps
)(React.memo(BranchCreatedSuccessfulPopup));
