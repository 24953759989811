import { createSlice } from "@reduxjs/toolkit";

const fiatslice = createSlice({
  name: "fiat",
  initialState: {
    createBankLoading: false,
    getBankLoading: false,
    fiatBank: [],
    createFiatWithdrawlRequestLoading: undefined,
    createFiatWithdrawlRequestError: undefined,
    createFiatWithdrawlRequestSuccess: undefined,
    createBankFail: "",
    callFiatBalanceLoading: undefined,
    callFiatBalanceError: undefined,
    fiatBalance: [],
    fiatTransactionLoading: undefined,
    fiatTransaction: [],
    fiatTransactionFail: undefined,
    getFiatTransactionCountLoading: undefined,
    getFiatTransactionCountError: undefined,
    getFiatTransactionCount: undefined,
    getAllBankAccountsLoading: undefined,
    getAllBankAccountsError: undefined,
    getAllBankAccountDetails: [],
    selectedBankAccounts: [],
    createBankAccountLoading: false,
    createBankAccountError: undefined,
    createBankAccSuccess: undefined,
    createDepositRequestLoading: undefined,
    createDepositRequestError: undefined,
    createDepositRequestSuccess: undefined,
    getAllOwnerBankLoading: undefined,
    getAllOwnerBankData: [],
    getAllOwnerBankError: undefined,
    selectedFiatCurrencyInfo: {},
    banks: [],
    selectedBank: undefined,
    openBankAccountAddModal: false,
    dynamicallyChangedFiatCurrencies: undefined,
    deleteBankAccountLoading: false,
    deleteBankAccountError: undefined,
  },
  reducers: {
    createBankSuccess: (state, action) => {
      return {
        ...state,
        createBankLoading: false,
      };
    },

    createBankTrigger: (state, action) => {
      return {
        ...state,
        createBankLoading: action.payload,
      };
    },
    createBankFailure: (state, action) => {
      return {
        ...state,
        createBankFail: action.payload,
      };
    },

    getBankSuccess: (state, action) => {
      return {
        ...state,
        fiatBank: action.payload,
      };
    },

    getBankTrigger: (state, action) => {
      return {
        ...state,
        getBankLoading: action.payload,
      };
    },

    getPlatformBankSuccess: (state, action) => {
      return {
        ...state,
        platformBanks: action.payload,
      };
    },
    depositFiatFailure: (state, action) => {
      return {
        ...state,
        createDepositRequestLoading: false,
        createDepositRequestError: action.payload,
        createDepositRequestSuccess: undefined,
      };
    },
    depositFiatSuccess: (state, action) => {
      return {
        ...state,
        createDepositRequestLoading: false,
        createDepositRequestError: undefined,
        createDepositRequestSuccess: true,
      };
    },
    depositFiatTrigger: (state, action) => {
      return {
        ...state,
        createDepositRequestLoading: true,
        createDepositRequestError: undefined,
        createDepositRequestSuccess: undefined,
      };
    },
    depositFiatClearMessages: (state, action) => {
      return {
        ...state,
        createDepositRequestError: undefined,
        createDepositRequestSuccess: undefined,
      };
    },

    createFiatWithDrawlStart: (state, action) => {
      return {
        ...state,
        createFiatWithdrawlRequestLoading: true,
        createFiatWithdrawlRequestError: undefined,
        createFiatWithdrawlRequestSuccess: undefined,
      };
    },

    createFiatWithDrawlSuccess: (state, action) => {
      return {
        ...state,
        createFiatWithdrawlRequestLoading: false,
        createFiatWithdrawlRequestSuccess: true,
        createFiatWithdrawlRequestError: undefined,
      };
    },

    createFiatWithdrawlFailure: (state, action) => {
      return {
        ...state,
        createFiatWithdrawlRequestLoading: false,
        createFiatWithdrawlRequestSuccess: undefined,
        createFiatWithdrawlRequestError: action.payload,
      };
    },

    callFiatBalanceStart: (state, action) => {
      return {
        ...state,
        callFiatBalanceLoading: true,
        callFiatBalanceError: undefined,
      };
    },

    callFiatBalanceSuccess: (state, action) => {
      return {
        ...state,
        callFiatBalanceLoading: false,
        callFiatBalanceError: undefined,
        fiatBalance: action.payload,
      };
    },

    callFiatBalanceFailure: (state, action) => {
      return {
        ...state,
        callFiatBalanceLoading: false,
        callFiatBalanceError: action.payload,
      };
    },

    getAllBankAccountsStart: (state, action) => {
      return {
        ...state,
        getAllBankAccountsLoading: true,
        getAllBankAccountsError: undefined,
      };
    },

    getAllBankAccountsSuccess: (state, action) => {
      return {
        ...state,
        getAllBankAccountsLoading: false,
        getAllBankAccountsError: undefined,
        getAllBankAccountDetails: action.payload,
      };
    },

    getAllBankAccountsFailure: (state, action) => {
      return {
        ...state,
        getAllBankAccountsLoading: false,
        getAllBankAccountsError: action.payload,
        getAllBankAccountDetails: [],
      };
    },

    // all redux actions for fiat transactionstart
    getFiatTransactionTrigger: (state, action) => {
      return {
        ...state,
        fiatTransactionLoading: action.payload,
        fiatTransactionFail: undefined,
        fiatTransaction: [],
      };
    },
    getFiatTransactionSuccess: (state, action) => {
      return {
        ...state,
        fiatTransactionLoading: false,
        fiatTransactionFail: undefined,
        fiatTransaction: action.payload,
      };
    },
    getFiatTransactionFailure: (state, action) => {
      return {
        ...state,
        fiatTransactionLoading: false,
        fiatTransactionFail: action.payload,
        fiatTransaction: [],
      };
    },

    getFiatTransactionCountTrigger: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: action.payload,
        FiatTransactionCount: undefined,
        getFiatTransactionCountError: undefined,
      };
    },
    getFiatTransactionCountSuccess: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: false,
        getFiatTransactionCountError: undefined,
        FiatTransactionCount: action.payload,
      };
    },

    getFiatTransactionCountFailure: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: false,
        FiatTransactionCount: undefined,
        getFiatTransactionCountError: action.payload,
      };
    },
    // all redux actions for fiat transaction end
    addBankAccounts: (state, action) => {
      return {
        ...state,
        selectedBankAccounts: action.payload,
      };
    },

    createBankAccountStart: (state, action) => {
      return {
        ...state,
        createBankAccountLoading: true,
        createBankAccSuccess: undefined,
        createBankAccountError: undefined,
      };
    },

    createBankAccountSuccess: (state, action) => {
      return {
        ...state,
        createBankAccountLoading: false,
        createBankAccSuccess: true,

        createBankAccountError: undefined,
      };
    },

    createBankAccountFailure: (state, action) => {
      return {
        ...state,
        createBankAccountLoading: false,
        createBankAccSuccess: undefined,
        createBankAccountError: action.payload,
      };
    },
    closeBankPopup: (state, action) => {
      return {
        ...state,
        createBankAccSuccess: false,
      };
    },
    clearBankError: (state, action) => {
      return {
        ...state,
        createBankAccountError: undefined,
      };
    },
    getALLTransactionCountFailure: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: false,
        getFiatTransactionCountError: undefined,
      };
    },
    getALLTransactionCountSuccess: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: false,
        getFiatTransactionCountError: undefined,
        AllTransactionCount: action.payload,
      };
    },
    getALLTransactionCountTrigger: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: true,
        getFiatTransactionCountError: undefined,
      };
    },
    getALLTransactionFailure: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: false,
        getFiatTransactionCountError: undefined,
      };
    },
    getALLTransactionTrigger: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: false,
        getFiatTransactionCountError: undefined,
      };
    },
    getALLTransactionSuccess: (state, action) => {
      return {
        ...state,
        getFiatTransactionCountLoading: false,
        getFiatTransactionCountError: undefined,
        AllTransaction: action.payload,
      };
    },

    clearDepositErrorAndSuccessMessages: (state, action) => {
      return {
        ...state,
        createDepositRequestError: undefined,
        createDepositRequestSuccess: undefined,
      };
    },
    clearFiatReduxSuccuss: (state, action) => {
      return {};
    },
    // get all owner bank account
    getAllBankOwnerAccountsStart: (state, action) => {
      return {
        ...state,
        getAllOwnerBankLoading: true,
        getAllOwnerBankData: undefined,
        getAllOwnerBankError: undefined,
      };
    },

    getAllOwnerBankAccountsSuccess: (state, action) => {
      // console.log('action from fiat redux' , action.payload);
      return {
        ...state,
        getAllOwnerBankLoading: false,
        getAllOwnerBankData: action.payload,
        getAllOwnerBankError: undefined,
      };
    },

    getAllBankOwnerAccountsFailure: (state, action) => {
      return {
        ...state,
        getAllOwnerBankLoading: false,
        getAllOwnerBankData: undefined,
        getAllOwnerBankError: true,
      };
    },
    // get all owner bank account

    clearAllFiatError: (state, action) => {
      return {
        ...state,
        createFiatWithdrawlRequestError: "",
        createBankFail: "",
        callFiatBalanceError: "",
        fiatTransactionFail: "",

        getFiatTransactionCountError: "",

        getAllBankAccountsError: "",
        createBankAccountError: "",
        createDepositRequestError: "",
        getAllOwnerBankError: "",
      };
    },

    setCurrentFiatInfo: (state, action) => {
      return {
        ...state,
        selectedFiatCurrencyInfo: action.payload,
      };
    },
    setBanksForSpecificFiat: (state, action) => {
      return {
        ...state,
        banks: action.payload,
      };
    },

    clearFiatWithDrawErrorClear: (state, action) => {
      return {
        ...state,
        createFiatWithdrawlRequestError: undefined,
        createFiatWithdrawlRequestSuccess: undefined,
      };
    },

    setCurrentBankAccount: (state, action) => {
      return {
        ...state,
        selectedBank: action.payload,
      };
    },

    removeOwnerBankOnModalClose: (state, action) => {
      return {
        ...state,
        selectedBank: undefined,
      };
    },
    setBankAccountAddingModal: (state, action) => {
      return {
        ...state,
        openBankAccountAddModal: true,
      };
    },

    removeCurrentBankAccount: (state, action) => {
      return {
        ...state,
        selectedBank: undefined,
        openBankAccountAddModal: false,
      };
    },
    setDynamicFiatCurrency: (state, action) => {
      return {
        ...state,
        dynamicallyChangedFiatCurrencies: action.payload,
      };
    },

    deleteBankAccountStart: (state, action) => {
      return {
        ...state,
        deleteBankAccountLoading: true,
        deleteBankAccountError: undefined,
      };
    },

    deleteBankAccountSuccess: (state, action) => {
      return {
        ...state,
        deleteBankAccountLoading: false,
        deleteBankAccountError: undefined,
      };
    },

    deleteBankAccountFailure: (state, action) => {
      return {
        ...state,
        deleteBankAccountLoading: false,
        deleteBankAccountError: action.payload,
      };
    },
  },
});
export default fiatslice.reducer;

export const {
  createBankTrigger,
  createBankSuccess,
  createBankFailure,
  getBankSuccess,
  getBankTrigger,
  getPlatformBankSuccess,
  depositFiatFailure,
  depositFiatSuccess,
  depositFiatTrigger,
  createFiatWithDrawlStart,
  createFiatWithDrawlSuccess,
  createFiatWithdrawlFailure,
  callFiatBalanceStart,
  callFiatBalanceSuccess,
  callFiatBalanceFailure,
  getAllBankAccountsStart,
  getAllBankAccountsSuccess,
  getAllBankAccountsFailure,
  addBankAccounts,
  createBankAccountStart,
  createBankAccountSuccess,
  createBankAccountFailure,
  clearDepositErrorAndSuccessMessages,
  clearFiatReduxSuccuss,
  depositFiatClearMessages,
  getFiatTransactionTrigger,
  getFiatTransactionSuccess,
  getFiatTransactionFailure,
  getFiatTransactionCountTrigger,
  getFiatTransactionCountSuccess,
  getFiatTransactionCountFailure,
  getALLTransactionCountFailure,
  getALLTransactionCountSuccess,
  getALLTransactionCountTrigger,
  getALLTransactionFailure,
  getALLTransactionTrigger,
  getALLTransactionSuccess,
  clearBankError,
  // get all owner bank accounts
  getAllBankOwnerAccountsStart,
  getAllOwnerBankAccountsSuccess,
  getAllBankOwnerAccountsFailure,
  clearAllFiatError,
  closeBankPopup,
  setCurrentFiatInfo,
  clearFiatWithDrawErrorClear,
  setBanksForSpecificFiat,

  setCurrentBankAccount,
  setBankAccountAddingModal,
  removeCurrentBankAccount,
  setDynamicFiatCurrency,
  removeOwnerBankOnModalClose,

  deleteBankAccountStart,
  deleteBankAccountFailure,
  deleteBankAccountSuccess,
} = fiatslice.actions;
