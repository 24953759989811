import * as _ from "lodash";
import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import { ReactComponent as CopyIcon } from "@images/copy.svg";
import { ReactComponent as ShareIcon } from "@images/share.svg";
import CustomButton from "src/components/custom-button/CustomButton";
import { ToastContainer, toast } from "react-toastify";
import CoinList from "@components/coin-list/CoinList";
import copy from "@images/copy1.svg";
import share from "@images/share1.svg";
import FooterCommon from "@components/footer-common/FooterCommon";
import qr from "@images/qr.png";
//import "./Styles.scss";
import "@components/business-transaction-popup/BusinessTransactionPopupStyles.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
import BigNumber from "bignumber.js";
import QRCode from "react-qr-code";
import { connect } from "react-redux";

const BusinessTransactionPopup = ({
  setShowModal,
  data,
  show1,
  onChange,
  thememode,
  settings,
  val,
}) => {
   const {
    amount,
    commission,
    acceptAs,
    exchangeTo,
    paymentStatus,
    tokenLink,
    id,
    createdOn,
    fromPaymentLinkCoin,
    toPaymentLinkCoin,
    commissionAmount,
    merchant,
    currencyAmount,
    fiatExchangeSymbol,
    userexchange,
    currencyAmountCommission,
    amountWithFee,
  } = data || {};

  const { t, i18n, ready } = useTranslation();
  const lang = localStorage.getItem('lang');
  return (
    <Modal
      className="BusinessTransactionPopup"
      show={show1}
      onHide={() => setShowModal(false)}
      
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modalPrimaryHeader hidden-for-desktop"></div>
        <CloseIcon
          className="closebtn hidden-for-mobile"
          onClick={() => setShowModal(false)}
        />
        <div
          className=" hidden-for-desktop back "
          onClick={() => setShowModal(false)}
        >
          <ExpandIcon />
        </div>
        <Container>
          <div className="number-section">
            {/* <span className="text d-none d-lg-block">0.0000542763 BTC</span> */}
            <span className="text d-block d-lg-none">
              {t("TransactionDetails")}
            </span>
          </div>
          <div className="hrline d-block d-lg-none" />
          <Row className="d-lg-flex d-none justify-content-between">
            <Col lg={6} xs={12}>
              <div className="qrsection">
                <QRCode
                  size="240"
                  className={"margin"}
                  value={`${window.location.origin}/pay/${tokenLink && tokenLink}`}
                  bgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.bgwhite
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.bgwhite
                  }
                  fgColor={
                    thememode == "light"
                      ? settings &&
                        settings.lightTheme &&
                        settings.lightTheme.textdarker
                      : settings &&
                        settings.darkTheme &&
                        settings.darkTheme.textdarker
                  }
                />
              </div>
              <div className="detail ">
                <div className="label">
                  <span className="labeltext">{t("Merchant")}</span>
                </div>
                <div className="value">
                  <span className="valuetext">
                    {" "}
                    {merchant && merchant.name} {merchant && merchant.subName}
                  </span>
                </div>
              </div>

              <div className="detail ">
                <div className="label">
                  <span className="labeltext">{t("Link")}</span>
                </div>
                <div className="value">
                  <span className="valuetext withlink">
                    {`${window.location.origin}/pay/${tokenLink && tokenLink}`}{" "}
                  </span>
                  <span>
                    <CopyIcon
                      className="copyiconinmoal"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/pay/${
                            tokenLink && tokenLink
                          }`
                        );
                        toast.success(t(`${"copied"}`), {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>

            <Col lg={5} xs={12} className="detailsSection">
              <div className="detail ">
                <div className="label">
                  <span className="labeltext">{t("Amount")}</span>
                </div>
                <div className="value">
                  <span className="valuetext">
                    {currencyAmount} {fiatExchangeSymbol}
                  </span>
                </div>
              </div>

              <div className="detail ">
                <div className="label">
                  <span className="labeltext">{t("Commission")}</span>
                </div>
                <div className="value">
                  <span className="valuetext">
                    {commissionAmount} {fiatExchangeSymbol}
                  </span>
                </div>
              </div>
              <div className="detail ">
                <div className="label">
                  <span className="labeltext">{t("Total")}</span>
                </div>
                <div className="value">
                  <span className="valuetext">
                    {currencyAmountCommission} {fiatExchangeSymbol}
                  </span>
                </div>
              </div>

              {
                <div className="detail ">
                  <div className="label">
                    <span className="labeltext">{t("CoinToAccept")}</span>
                  </div>
                  <div className="value">
                    <span className="valuetext">
                      {fromPaymentLinkCoin && fromPaymentLinkCoin.tokenName}
                      {((fromPaymentLinkCoin &&
                        fromPaymentLinkCoin.type == "TRC20") ||
                        (fromPaymentLinkCoin &&
                          fromPaymentLinkCoin.type == "BEP20") ||
                        (fromPaymentLinkCoin &&
                          fromPaymentLinkCoin.type == "ERC20")) &&
                        ` (${fromPaymentLinkCoin.type})`}
                    </span>
                  </div>
                </div>
              }

              <div className="detail ">
                <div className="label">
                  <span className="labeltext">{t("CoinToConvert")}</span>
                </div>
                <div className="value">
                  <span className={ lang == "ru" ?"valuetext top" : "valuetext"}>
                    {toPaymentLinkCoin && toPaymentLinkCoin.tokenName}
                    {((toPaymentLinkCoin &&
                      toPaymentLinkCoin.type == "TRC20") ||
                      (toPaymentLinkCoin &&
                        toPaymentLinkCoin.type == "BEP20") ||
                      (toPaymentLinkCoin &&
                        toPaymentLinkCoin.type == "ERC20")) &&
                      ` (${toPaymentLinkCoin.type})`}
                  </span>
                </div>
              </div>

              {(paymentStatus == "Pending" || paymentStatus == "Cancelled") && (
                <div className="detail ">
                  <div className="label">
                    <span className="labeltext">{t("AmountReceived")}</span>
                  </div>
                  <div className="value">
                    <span className="valuetext">
                      {BigNumber(amountWithFee).toFixed(
                        4,
                        BigNumber.ROUND_FLOOR
                      )}{" "}
                      {toPaymentLinkCoin && toPaymentLinkCoin.exchangeSymbol}
                    </span>
                  </div>
                </div>
              )}

              {(paymentStatus == "Paid" || paymentStatus == "paid") && (
                <div className="detail ">
                  <div className="label">
                    <span className="labeltext">{t("AmountReceived")}</span>
                  </div>
                  <div className="value">
                    {userexchange && userexchange.amount && (
                      <span className="valuetext">
                        {BigNumber(
                          userexchange.total / 10 ** toPaymentLinkCoin.decimals
                        ).toFixed(4, BigNumber.ROUND_FLOOR)}{" "}
                        {toPaymentLinkCoin && toPaymentLinkCoin.exchangeSymbol}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {
                <div className="detail ">
                  <div className="label">
                    <span className="labeltext">{t("Status")}</span>
                  </div>
                  <div className="value">
                    <span className="valuetext">
                      {paymentStatus == "Paid" || paymentStatus == "paid"
                        ? `${t("Completed")}`
                        : paymentStatus == "Cancelled"
                        ? `${t("Cancelled")}`
                        : `${t("Pending")}`}
                    </span>
                  </div>
                </div>
              }

              <div className="buttoncontainer hidden-for-mobile">
                <CustomButton
                  type="submit"
                  name={t("Close")}
                  filled={true}
                  primary={true}
                  onClick={() => setShowModal(false)}
                />
              </div>
            </Col>
          </Row>

          <div className="d-block d-lg-none align-items-center justify-content-center">
            <p className="labeltextM">{t("Status")}</p>
            <p className={`statusTextM ${paymentStatus}`}>{t(`${paymentStatus}`)}</p>
            <div className="detailWrapTableM">
              <div className="detailWrapM">
                <p className="titleM">{t("TransactionID")}</p>
                <p className="valueM">#{id}</p>
              </div>
              <div className="detailWrapM">
                <p className="titleM">{t("Date")}</p>
                <p className="valueM">
                  {moment(createdOn).local().format("MM/DD/YYYY hh:mm A")}
                </p>
              </div>
              <div className="detailWrapM">
                <p className="titleM">{t("Merchant")}</p>
                <p className="valueM">#Merchant1</p>
              </div>
              <div className="detailWrapM">
                <p className="titleM">{t("CoinToAccept")}</p>
                <p className="valueMforcoin">
                  <CoinList
                    name={acceptAs}
                    logoBgColor={
                      fromPaymentLinkCoin && fromPaymentLinkCoin.logoBgColor
                    }
                    logo={
                      fromPaymentLinkCoin && fromPaymentLinkCoin.nonColouredLogo
                    }
                  />
                </p>
              </div>
              <div className="detailWrapM">
                <p className="titleM">{t("CoinToConvert")}</p>
                <p className="valueMforcoin">
                  <CoinList
                    name={exchangeTo}
                    logoBgColor={
                      toPaymentLinkCoin && toPaymentLinkCoin.logoBgColor
                    }
                    logo={
                      toPaymentLinkCoin && toPaymentLinkCoin.nonColouredLogo
                    }
                  />
                </p>
              </div>
              <div className="detailWrapM mt-3">
                <p className="titleM">
                  {t("Amount")}/{t("Currency")}
                </p>
                <p className="valueM2">+$5,553</p>
              </div>
            </div>

            {/* <div className="d-flex align-items-center justify-content-center mt-5">
                    <div className="modalbuttons mx-3">
                        <div>
                            <CopyIcon className="iconfill" />
                        </div>
                        <p>Copy info</p>
                    </div>
                    <div className="modalbuttons mx-3">
                        <div>
                            <ShareIcon className="iconfill" />
                        </div>
                        <p>Share info</p>
                    </div>
                </div> */}
          </div>
        </Container>
        <div className="d-block d-lg-none">
          <FooterCommon />
        </div>
      </Modal.Body>
    </Modal>
  );
};


const mapStateToProps = ({ app = {} }) => {
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);
  return {
    thememode,
    settings,
  };
};
export default  connect(mapStateToProps, null)(BusinessTransactionPopup);
