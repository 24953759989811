import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "@components/wallet-coin-card/WalletCoincard.scss";
import { useTranslation } from "react-i18next";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as DownIcon } from "@images/down.svg";
import { ReactComponent as ExchangeIcon } from "@images/exchange.svg";
import { ReactComponent as Eye } from "@images/eye.svg";
import SmallButton from "@components/small-button/SmallButton";
import { ReactComponent as HideEye } from "@images/hideeye2.svg";
import BigNumber from "bignumber.js";

function WalletCoinCard({
  logo,
  name,
  bgcolor,
  symbol,
  balance,
  decimals,
  coinkey,
  activekey,
  tag,
  setcoinItem,
  setShowWithdraw,
  coin,
  setExchangeModal,
  setDepositModal,
  type,
  eye,
  setEye,
  setCardArray,
  setCardEye,
  cardEye,
  cardArray,
}) {
   const { t } = useTranslation();
  const [currentCoin, setCurrentCoin] = useState({});
  // const [coin]
  let setCoins = () => {
    setCurrentCoin(cardArray.filter((itm) => itm.id === coinkey)[0]);
   };

  return (
    <div className="walletcoincard">
      <div className="wcc-infowrapper">
        <div className="wcc-imgwrapper" style={{ backgroundColor: bgcolor }}>
          <img src={`${process.env.REACT_APP_IMGLINK}${logo}`} alt="" />
        </div>
        <div class="wcc-info">
          <p className="wcc-title">
            {symbol}{" "}
            {(type == "TRC20" || type == "BEP20" || type == "ERC20") && (
              <span className="cointag">{type}</span>
            )}
          </p>
          <p className="wcc-desc">{name}</p>
        </div>
        <div className="wcc-coinBalance">
          <span className="coinAmt">
            { !currentCoin.state
              ? "*****"
              : BigNumber(balance / 10 ** decimals).toFixed(4)}
          </span>
          <span className="coinName">{symbol}</span>
        </div>
        <div
          onClick={(e) => {
            setCardArray((prevState) => {
              let newState = [...prevState];
              newState.forEach((itm) => {
                if (itm.id === coinkey) {
                  itm.state = !itm.state;
                }
              });
              return newState;
            });
            setCoins();

             //setCardEye(prevState => ({...prevState,id: coinkey,state:!prevState.state}))
          }}
        >
          {cardArray && cardArray.length !== 0 && currentCoin.state ? (
            <Eye className="eyeiconwalletcard" />
          ) : (
            <HideEye className="eyeiconwalletcard" />
          )}
        </div>
      </div>
      <div className="wcc-btnwrapper">
        <SmallButton
          type="deposit"
          hideButtonName={true}
          onclick={() => {
            setcoinItem(coin);
            setDepositModal(true);
          }}
        />
        <SmallButton
          type="withdraw"
          hideButtonName={true}
          onclick={() => {
            setcoinItem(coin);
            setShowWithdraw(true);
          }}
        />
        <SmallButton
          type="exchange"
          hideButtonName={true}
          onclick={() => {
            setcoinItem(coin);
            setExchangeModal(true);
          }}
        />
      </div>
    </div>
  );
}

export default WalletCoinCard;
