export default {
  Logo: "Logo ",
  Thesizeofimage: "The size of the image can be taken up to",
  pixels: "pixels",
  sizetothefile: "size to the file",
  upto: " up to",
  inPNGorJPEGformat: "in PNG or JPEG format",
  Change: " Change",
  Account: "Account",
  Branch: "Branch",
  Phone: "Phone",
  email: "Email",
  Address: "Address",
  Password: "Password",
  Confirm: "Confirm",
  AccountName: "Account Name",
  EnterPassword: "Enter Password",
  ReEnterPassword: "Re-enter Password",
  ImageRequired: "Image is required",
  PhoneEmpty: "Phone no cannot be empty",
  Email_empty: "Email cannot be empty",
  Passwordempty: "Password cannot be empty",
  false: "false",
  Cryptocurrency: "Cryptocurrency",
  Fiat: "Fiat",
  "Please enter a valid email address": "Please enter a valid email address",
  "Please enter a valid phone no": "Please enter a valid phone no",
  "Please enter a branch name": "Please enter a branch name",
  "Please enter an account name": "Please enter an account name",
  "EMAIL_ALREADY_IN_USE": "Email already used",
  "MOBILE_ALREADY_IN_USE": "Mobile Number You have Entered is already registered.",
  "INVALID_ROLE": "Invalid Role",
  "Email_empty": "Email empty",
  "NAME_CANNOT_BE_A_NUMBER":"Name cannot be a number",
};
