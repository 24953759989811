export default {
  about: 'Hakkında',
  Downloadext: 'Google Chrome tarayıcısı için PayCrypt Security koruma sistemini indirin',
  ChromeExtension: 'Chrome Uzantısı İndir',
  CyborProtectionSystem: 'PayCrypt Koruma Sistemi',
  applicationforcontrol:
    'finansal ve kripto para işlemlerinizin kontrolü ve güvenliği için bir uygulamadır',
  freeappcyborprotection:
    'Ücretsiz uygulama PayCrypt Protection System, tüm trafiğinizi korumak için güçlü şifreleme kullanır ve gerçek IP adresinizi gizler, böylece engellenen herhangi bir web sitesine erişebilir, verilerinizi koruyabilir ve bant genişliği sınırlaması olmadan internette anonim olarak gezinebilirsiniz.',
  cyborprotectionforchrome:
    "Chrome'dan PayCrypt Protection System, çevrimiçi güvenliğinizi ve gizliliğinizi güvende tutmanın en kolay yoludur. VPN'imiz, VPN sunucularımıza bağlandıktan sonra IP adresinizi gizlemenize ve engellenen içeriğe erişmenize olanak tanır.",
  cyborprotectionvpn:
    'PayCrypt Protection System, dünya çapında milyonlarca kullanıcı tarafından Google Chrome için en iyi VPN olarak kabul edilir. Sınırsız ücretsiz VPN denemek ve engellenen sitelere, akış içeriklerine ve daha fazlasına erişmek için dünyanın her yerinden güvenli sunucularımıza bağlanın.',
  installvpn:
    'Herkese açık Wi-Fi ağına bağlıyken bile sınırsız bant genişliği kullanmaya başlamak, güvenli ve anonim kalmak için Google Chrome için VPN uzantımızı yükleyin.',
  CyborProtectionSystemApp: 'PayCrypt Koruma Sistemi Uygulamasını İndir',
};
