import { createSlice } from "@reduxjs/toolkit";
const adminslice = createSlice({
  name: "admin",
  initialState: {
    requestedAccountVerificationLoading: false,
    requestedBankAccounts: [],
    usersFiatDepositRequestLoading: false,
    usersFiatWithdrawRequestLoading: false,
    businessRequestLoading: false,
    businessRequest: [],
    verifyUserAccountRequestLoading: false,
    verifyUserAccountError: undefined,
    verifyUserAccountSuccess: undefined,
    verifyUserDepositRequestLoading: false,
    verifyUserWithdrawRequestLoading: false,
    approveBusinessRequestLoading: false,
    approveBusinessRequestError: undefined,
    approveBusinessRequestSuccess: undefined,
    businessRequestError: undefined,
    withdrawRequestCountLoading: false,
    depositRequestCountLoading: false,
    businessAccountRequestCount: false,
    businessAccountCount: undefined,
    bankAccountRequestCount: false,
    bankAccountRequest: undefined,
    usersListLoading: false,
    usersListCountLoading: false,
    setUsersStatusLoading: false,
    allPlatformBanks: undefined,
    allPlatformBanksLoading: false,
    allPlatformBanksError: undefined,

    editAdminBankLoading: false,
    editAdminBankError: undefined,
    editAdminBankSuccess: false,

    editAdminBankImagesLoading: false,
    editAdminBankImagesSuccess: undefined,
    editAdminBankImagesError: undefined,

    addAdminBankLoading: false,
    addAdminBankSuccess: false,
    addAdminBankError: undefined,

    deleteAdminBankLoading: false,
    deleteAdminBankError: undefined,

    editColouredLogoLoading: false,

    editColouredLogoError: undefined,

    editNonColouredLogoLoading: false,
    editNonColouredLogoSuccess: undefined,
    editNonColouredLogoError: undefined,
    adminBankCurrencyFilter: undefined,
  },
  reducers: {
    requestedAccountVerificationSuccess: (state, action) => {
      return {
        ...state,
        requestedAccountVerificationLoading: false,
        requestedAccountVerification: action.payload,
        requestedAccountVerificationError: undefined,
      };
    },
    requestedAccountVerificationTrigger: (state, action) => {
      return {
        ...state,
        requestedAccountVerificationLoading: action.payload,
        requestedAccountVerificationError: undefined,
      };
    },

    requestedAccountVerificationError: (state, action) => {
      return {
        ...state,
        requestedAccountVerificationLoading: false,
        requestedAccountError: action.payload,
      };
    },

    bankAccountRequestCountTrigger: (state, action) => {
      return {
        ...state,
        bankAccountRequestCount: true,
        bankAccountRequest: undefined,
      };
    },

    bankAccountRequestCountSuccess: (state, action) => {
      return {
        ...state,
        bankAccountRequestCount: false,
        bankAccountRequest: action.payload,
      };
    },

    usersFiatDepositRequestSuccess: (state, action) => {
      return {
        ...state,
        usersFiatDepositRequest: action.payload,
      };
    },
    usersFiatDepositRequestTrigger: (state, action) => {
      return {
        ...state,
        usersFiatDepositRequestLoading: action.payload,
      };
    },

    usersFiatWithdrawRequestSuccess: (state, action) => {
      return {
        ...state,
        usersFiatWithdrawRequest: action.payload,
      };
    },
    usersFiatWithdrawRequestTrigger: (state, action) => {
      return {
        ...state,
        usersFiatWithdrawRequestLoading: action.payload,
      };
    },

    businessRequestSuccess: (state, action) => {
      return {
        ...state,
        businessRequest: action.payload,
        businessRequestLoading: false,
        businessRequestError: undefined,
      };
    },
    businessRequestTrigger: (state, action) => {
      return {
        ...state,
        businessRequestLoading: action.payload,
        businessRequestError: undefined,
      };
    },
    businessRequestFailure: (state, action) => {
      return {
        ...state,
        businessRequestError: action.payload,
        businessRequestLoading: false,
      };
    },

    // patch requests

    verifyUserAccountRequestSuccess: (state, action) => {
      return {
        ...state,
        verifyUserAccountRequestLoading: false,
        verifyUserAccountError: undefined,
        verifyUserAccountSuccess: "Success",
      };
    },
    verifyUserAccountRequestError: (state, action) => {
      return {
        ...state,
        verifyUserAccountRequestLoading: false,
        verifyUserAccountError: action.payload,
        verifyUserAccountSuccess: undefined,
      };
    },
    verifyUserAccountRequestTrigger: (state, action) => {
      return {
        ...state,
        verifyUserAccountRequestLoading: action.payload,
        verifyUserAccountError: undefined,
        verifyUserAccountSuccess: undefined,
      };
    },

    verifyUserDepositRequest: (state, action) => {
      let temp = state.usersFiatDepositRequest.map((data) => {
        return data;
      });
      return {
        ...state,
        usersFiatDepositRequest: temp,
      };
    },
    verifyUserDepositRequestTrigger: (state, action) => {
      return {
        ...state,
        verifyUserDepositRequestLoading: action.payload,
      };
    },

    verifyUserWithdrawRequest: (state, action) => {
      let temp = state.usersFiatWithdrawRequest.map((data) => {
        return data;
      });
      return {
        ...state,
        usersFiatWithdrawRequest: temp,
      };
    },

    verifyUserWithdrawRequestTrigger: (state, action) => {
      return {
        ...state,
        verifyUserWithdrawRequestLoading: action.payload,
      };
    },

    approveBusinessRequestTrigger: (state, action) => {
      return {
        ...state,
        approveBusinessRequestLoading: action.payload,
        approveBusinessRequestError: undefined,
        approveBusinessRequestSuccess: undefined,
      };
    },

    approveBusinessRequestSuccess: (state, action) => {
      return {
        ...state,
        approveBusinessRequestLoading: false,
        approveBusinessRequestError: undefined,
        approveBusinessRequestSuccess: "Success",
      };
    },

    approveBusinessRequestFailure: (state, action) => {
      return {
        approveBusinessRequestError: action.payload,
        approveBusinessRequestLoading: false,
        approveBusinessRequestSuccess: undefined,
      };
    },

    businessAccountCountTrigger: (state, action) => {
      return {
        ...state,
        businessAccountCount: undefined,
        businessAccountRequestCount: true,
      };
    },

    businessAccountCountSuccess: (state, action) => {
      return {
        ...state,
        businessAccountCount: action.payload,
        businessAccountRequestCount: false,
      };
    },

    withdrawRequestCountTrigger: (state, action) => {
      return {
        ...state,
        withdrawRequestCountLoading: action.payload,
      };
    },

    withdrawRequestCount: (state, action) => {
      return {
        ...state,
        withdrawRequestCount: action.payload,
      };
    },

    depositRequestCount: (state, action) => {
      return {
        ...state,
        depositRequestCount: action.payload,
      };
    },

    depositRequestCountTrigger: (state, action) => {
      return {
        ...state,
        depositRequestCountLoading: action.payload,
      };
    },

    usersListTrigger: (state, action) => {
      return {
        ...state,
        usersListLoading: action.payload,
      };
    },

    usersListSuccess: (state, action) => {
      return {
        ...state,
        usersList: action.payload,
      };
    },
    usersListSuccessDownload: (state, action) => {
      return {
        ...state,
        usersListDownload: action.payload,
      };
    },

    usersListCountTrigger: (state, action) => {
      return {
        ...state,
        usersListCountLoading: action.payload,
      };
    },

    usersListCountSuccess: (state, action) => {
      return {
        ...state,
        usersListCount: action.payload,
      };
    },

    setUsersStatusSuccess: (state, action) => {
      let temp = state.usersList.map((data) => {
        if (data.id == action.payload.id) {
          return action.payload;
        } else return data;
      });
      return {
        ...state,
        usersList: temp,
      };
    },

    setUsersStatusTriggered: (state, action) => {
      return {
        ...state,
        setUsersStatusLoading: action.payload,
      };
    },

    clearAdminRedux: (state, action) => {
      return {
        ...state,

        verifyUserAccountError: "",
        approveBusinessRequestError: "",
        businessRequestError: "",
      };
    },

    callAllPlatFormBanksStart: (state, action) => {
      return {
        ...state,
        allPlatformBanks: undefined,
        allPlatformBanksLoading: true,
        allPlatformBanksError: undefined,
      };
    },

    callAllPlatFormBanksSuccess: (state, action) => {
      return {
        ...state,
        allPlatformBanks: action.payload,
        allPlatformBanksLoading: false,
        allPlatformBanksError: undefined,
      };
    },

    callAllPlatFormBanksFailure: (state, action) => {
      return {
        ...state,
        allPlatformBanks: undefined,
        allPlatformBanksLoading: false,
        allPlatformBanksError: action.payload,
      };
    },

    editAdminBankStart: (state, action) => {
      return {
        ...state,
        editAdminBankLoading: true,
        editAdminBankSuccess: false,
        editAdminBankError: undefined,
      };
    },

    editAdminBankSuccess: (state, action) => {
      return {
        ...state,
        editAdminBankLoading: false,
        editAdminBankSuccess: true,
        editAdminBankError: undefined,
      };
    },

    editAdminBankFailure: (state, action) => {
      return {
        ...state,
        editAdminBankLoading: false,
        editAdminBankSuccess: false,
        editAdminBankError: action.payload,
      };
    },

    addAdminBankStart: (state, action) => {
      return {
        ...state,
        addAdminBankLoading: true,
        addAdminBankSuccess: false,
        addAdminBankError: undefined,
      };
    },

    addAdminBankSuccess: (state, action) => {
      return {
        ...state,
        addAdminBankLoading: false,
        addAdminBankSuccess: true,
        addAdminBankError: undefined,
      };
    },

    addAdminBankFailure: (state, action) => {
      return {
        ...state,
        addAdminBankLoading: false,
        addAdminBankSuccess: false,
        addAdminBankError: action.payload,
      };
    },

    deleteAdminBankStart: (state, action) => {
      return {
        ...state,
        deleteAdminBankLoading: true,
        deleteAdminBankError: undefined,
      };
    },

    deleteAdminBankSuccess: (state, action) => {
      return {
        ...state,
        deleteAdminBankLoading: false,
        deleteAdminBankError: undefined,
      };
    },

    deleteAdminBankFailure: (state, action) => {
      return {
        ...state,
        deleteAdminBankLoading: false,
        deleteAdminBankError: action.payload,
      };
    },
    editBankImagesStart: (state, action) => {
      return {
        ...state,
        editAdminBankImagesLoading: true,
        editAdminBankImagesSuccess: undefined,
        editAdminBankImagesError: undefined,
      };
    },
    editBankImagesSuccess: (state, action) => {
      return {
        ...state,
        editAdminBankImagesLoading: false,
        editAdminBankImagesSuccess: action.payload,
        editAdminBankImagesError: undefined,
      };
    },
    editBankImagesFailure: (state, action) => {
      return {
        ...state,
        editAdminBankImagesLoading: false,
        editAdminBankImagesSuccess: undefined,
        editAdminBankImagesError: action.payload,
      };
    },
    editColouredLogoStart: (state, action) => {
      return {
        ...state,
        editColouredLogoLoading: true,
        editColouredLogoError: undefined,
      };
    },
    editColouredLogoSuccess: (state, action) => {
      return {
        ...state,
        editColouredLogoLoading: false,
        editColouredLogoError: undefined,
      };
    },
    editColouredLogoFailure: (state, action) => {
      return {
        ...state,
        editColouredLogoLoading: false,
        editColouredLogoError: action.payload,
      };
    },
    editNonColouredLogoStart: (state, action) => {
      return {
        ...state,
        editNonColouredLogoLoading: true,
        editNonColouredLogoSuccess: undefined,
        editNonColouredLogoError: undefined,
      };
    },
    editNonColouredLogoSuccess: (state, action) => {
      return {
        ...state,
        editNonColouredLogoLoading: false,
        editNonColouredLogoSuccess: action.payload,
        editNonColouredLogoError: undefined,
      };
    },
    editNonColouredLogoFailure: (state, action) => {
      return {
        ...state,
        editNonColouredLogoLoading: false,
        editNonColouredLogoSuccess: undefined,
        editNonColouredLogoError: action.payload,
      };
    },
    clearEditAddError: (state, action) => {
      return {
        ...state,
        addAdminBankError: undefined,
        editAdminBankError: undefined,
        addAdminBankSuccess: false,
        editAdminBankSuccess: false,
        editAdminBankImagesError: undefined,
        editNonColouredLogoError: undefined,
        editNonColouredLogoSuccess: undefined,
        editAdminBankImagesSuccess: undefined,
      };
    },

    setAdminBankCurrencyFilter: (state, action) => {
      return {
        ...state,
        adminBankCurrencyFilter: action.payload,
      };
    },

    clearAdminBankCurrencyFilter: (state, action) => {
      return {
        ...state,
        adminBankCurrencyFilter: undefined,
      };
    },
  },
});
export default adminslice.reducer;

export const {
  requestedAccountVerificationSuccess,
  requestedAccountVerificationError,
  requestedAccountVerificationTrigger,
  bankAccountRequestCountTrigger,
  bankAccountRequestCountSuccess,
  usersFiatDepositRequestSuccess,
  usersFiatDepositRequestTrigger,
  usersFiatWithdrawRequestSuccess,
  usersFiatWithdrawRequestTrigger,
  businessRequestSuccess,
  businessRequestTrigger,
  businessRequestFailure,
  businessAccountCountTrigger,
  businessAccountCountSuccess,

  verifyUserAccountRequestSuccess,
  verifyUserAccountRequestError,
  verifyUserAccountRequestTrigger,
  verifyUserDepositRequest,
  verifyUserDepositRequestTrigger,
  verifyUserWithdrawRequest,
  verifyUserWithdrawRequestTrigger,
  approveBusinessRequestTrigger,
  approveBusinessRequestFailure,
  approveBusinessRequestSuccess,

  withdrawRequestCount,
  withdrawRequestCountTrigger,
  depositRequestCountTrigger,
  depositRequestCount,
  usersListTrigger,
  usersListSuccess,
  usersListCountTrigger,
  usersListCountSuccess,
  setUsersStatusSuccess,
  setUsersStatusTriggered,
  clearAdminRedux,
  callAllPlatFormBanksStart,
  callAllPlatFormBanksSuccess,
  callAllPlatFormBanksFailure,

  editAdminBankStart,
  editAdminBankSuccess,
  editAdminBankFailure,

  addAdminBankStart,
  addAdminBankSuccess,
  addAdminBankFailure,

  deleteAdminBankStart,
  deleteAdminBankSuccess,
  deleteAdminBankFailure,
  editBankImagesStart,
  editBankImagesSuccess,
  editBankImagesFailure,

  editColouredLogoStart,
  editColouredLogoSuccess,
  editColouredLogoFailure,

  editNonColouredLogoStart,
  editNonColouredLogoSuccess,
  editNonColouredLogoFailure,

  clearEditAddError,

  setAdminBankCurrencyFilter,
  clearAdminBankCurrencyFilter,
  usersListSuccessDownload,
} = adminslice.actions;
