import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import ScreenLayout from "src/components/layout/ScreenLayout";
import Table from "react-bootstrap/esm/Table";
import FooterCommon from "src/components/footer-common/FooterCommon";
import { ReactComponent as SortupIcon } from "@images/sortup.svg";
import { ReactComponent as SortdownIcon } from "@images/sortdown.svg";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PlusIcon from "@images/plus.svg";
import Filter from "@components/filter/Filter.js";
import { ReactComponent as PrinterIcon } from "@images/printer.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import { ReactComponent as RightArrow } from "@images/rightarrow.svg";
import { ReactComponent as FilterIcon } from "@images/currencyicon.svg";
import { ReactComponent as EditLogo } from "@images/branchmanagement/Edit.svg";
import { ReactComponent as DeleteLogo } from "@images/branchmanagement/Delete.svg";
import CustomButton from "src/components/custom-button/CustomButton";
import DropdownSearch from "src/components/dropdown-search/DropdownSearch";
import DeleteAdminBankPopup from "src/components/delete-admin-bank-popup/DeleteAdminBank";
import AdminSuccess from "src/components/admin-bank-operation/AdminBankOperation";
import AddBankPopup from "@components/add-bank-account-popup3/AddBankAccountPopup3";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from "lodash";
import Loader from "@components/loader/Loader";
import "./AdminAllBanksStyles.scss";

import jsPDF from "jspdf";
import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import "jspdf-autotable";
import { ClearSansBase64 } from "src/custom-fonts-base64/index";
import AddNewBankAccount from "../add-new-bank-acount/AddNewBankAccount";
import {
  setAdminBankCurrencyFilter,
  clearAdminBankCurrencyFilter,
} from "@store/adminredux";

const AdminAllBanks = ({
  allPlatformBanks,
  allPlatformBanksLoading,
  callAllAdminBanksAsync,
  plaformcurrencies,
  thememode,
  deleteAdminBank,
  deleteAdminBankLoading,
  addAdminBankSuccess,
  editAdminBankSuccess,
  setAdminBankCurrencyFilterAsync,
  adminBankCurrencyFilter,
  clearAdminBankCurrencyFilterAsync,
  // callAllAdminBanksAsyncDownload,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [selectCurrencyIndex, setSelectCurrencyIndex] = useState();
  const [bankIdToBeUpdated, setBankIdToBeUpdated] = useState();
  const [bankDropDownMapper, setBankDropDownMapper] = useState({});
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [hideSpec, setHideSpec] = useState(true);
  const [compRef, setCompRef] = useState();
  const [language, setLanguage] = useState("en");
  const [hiddenTable, setHiddenTable] = useState(false);
  const componentRef = useRef();
  const [openDeleteBankPopup, setOpenDeleteBankPopup] = useState(false);
  const [currentBankSelected, setCurrentBankSelected] = useState();
  const [openAddEditModal, setAddEditModal] = useState(false);
  const [scope, setScope] = useState();
  const [print, setPrint] = useState(false);
  const [filterCurrencyIndex, setFilterCurrencyIndex] = useState();

  // useEffect(() => {
  //   //setCompRef(componentRef);
   // }, []);
  // useEffect(() => {
  //   setCompRef(componentRef);
  // }, [componentRef]);

  // useEffect(() => {
   // }, [bankDropDownMapper]);
  useEffect(() => {
    // callAllAdminBanksAsyncDownload();
    filterAdminInfoForExcelExport();
  }, []);

  useEffect(() => {
   }, [hideSpec]);
  useEffect(() => {
    if (print) {
      if (allPlatformBanks && allPlatformBanks.length !== 0) {
        if (hideSpec === false) {
          setCompRef(componentRef);
          if (compRef !== undefined) {
            handlePrint();
          }
          // setHideSpec(true);
        }
      }
    } else {
      if (allPlatformBanks && allPlatformBanks.length !== 0) {
        if (hideSpec === false) {
          setCompRef(componentRef);
          if (compRef !== undefined) {
            handlePrint();
          }
          // setHideSpec(true);
        }
      }
    }

    setHideSpec(true);
  }, [print, hideSpec]);
  // useEffect(() => {
  //   if(print){

  //     if (allPlatformBanks && allPlatformBanks.length !== 0) {
  //       if (hideSpec === false) {
  //       setCompRef(componentRef);
  //       if(compRef !== undefined) {
  //         handlePrint();
  //       }
  //       setHideSpec(true);

  //     }

  //     }
  //   }
  //   else{
  //     if (allPlatformBanks && allPlatformBanks.length !== 0) {
  //       if (hideSpec === false) {
  //       setCompRef(componentRef);
  //       if(compRef !== undefined) {
  //         handlePrint();
  //       }
  //       setHideSpec(true);

  //     }

  //     }
  //   }
   // } , [hideSpec])
  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });
  useEffect(() => {
    setLanguage(localStorage.lang);
    filterAdminInfoForExcelExport();
  }, [localStorage.lang]);

  

  useEffect(() => {
    filterAdminInfoForExcelExport();
  }, [allPlatformBanks]);

  const filterAdminInfoForExcelExport = () => {
    let filtered = [];
    let headerList = [];
    if (allPlatformBanks && allPlatformBanks.length !== 0) {
      for (let a = 0 ; a < allPlatformBanks.length ; a++) { 
        let platformBanks = allPlatformBanks[a]?.ownersBankAccount;
      for(let i = 0 ; i < platformBanks.length ; i++) {
        if(platformBanks[i].IBANs !==""){
          let newOBJ = {
            Bank: allPlatformBanks[a]?.bankName,
            Currency: platformBanks[i]?.currencyType,
           
            IBAN:platformBanks[i].IBANs,
             
  
            AccountHolder:  allPlatformBanks[a]?.accountHoldersName,
            // i.ownersBankAccount !== undefined &&
            //   i.ownersBankAccount !== null &&
            //   i.ownersBankAccount.length !== 0
            //   ? i["ownersBankAccount"].filter((bnk) => {
            //     if (bankDropDownMapper[i.id] !== undefined) {
            //       if (
            //         bnk.currencyType ===
            //         plaformcurrencies[bankDropDownMapper[i.id]].exchangeSymbol
            //       ) {
            //         return bnk;
            //       }
            //     }
            //   })[0]?.accountHolderName
            //   : "-",
          };
  
          filtered.push(newOBJ);
        }
    
      }
        
        // let newOBJ = {
        //   Bank: i.bankName,
        //   Currency:
        //     i.ownersBankAccount !== undefined &&
        //     i.ownersBankAccount.length !== 0
        //       ? i["ownersBankAccount"].filter((bnk) => {
        //           if (bankDropDownMapper[i.id] !== undefined) {
        //             if (
        //               bnk.currencyType ===
        //               plaformcurrencies[bankDropDownMapper[i.id]].exchangeSymbol
        //             ) {
        //               return bnk;
        //             }
        //           }
        //         })[0]?.currencyType
        //       : "-",
        //   IBAN:
        //     i.ownersBankAccount !== undefined &&
        //     i.ownersBankAccount !== null &&
        //     i.ownersBankAccount !== undefined &&
        //     i.ownersBankAccount.length !== 0
        //       ? i["ownersBankAccount"].filter((bnk) => {
        //           if (bankDropDownMapper[i.id] !== undefined) {
        //             if (
        //               bnk.currencyType ===
        //               plaformcurrencies[bankDropDownMapper[i.id]].exchangeSymbol
        //             ) {
        //               return bnk;
        //             }
        //           }
        //         })[0]?.IBANs
        //       : "-",

        //   AccountHolder: i?.accountHoldersName,
        //   // i.ownersBankAccount !== undefined &&
        //   //   i.ownersBankAccount !== null &&
        //   //   i.ownersBankAccount.length !== 0
        //   //   ? i["ownersBankAccount"].filter((bnk) => {
        //   //     if (bankDropDownMapper[i.id] !== undefined) {
        //   //       if (
        //   //         bnk.currencyType ===
        //   //         plaformcurrencies[bankDropDownMapper[i.id]].exchangeSymbol
        //   //       ) {
        //   //         return bnk;
        //   //       }
        //   //     }
        //   //   })[0]?.accountHolderName
        //   //   : "-",
        // };

        // filtered.push(newOBJ);
      }

      for (let i of Object.keys(filtered[0])) {
        let newOBJ = {
          title: t(i),
          dataIndex: i,
        };
        headerList.push(newOBJ);
      }

      let pack = {
        data: filtered,
        head: headerList,
      };

      setExcelHeader(pack.head);
      setExcelData(pack.data);

      return pack;
    }
    return filtered;
  };
  const generatePdf = () => {
    var doc = new jsPDF();
    doc.addFileToVFS("ClearSans-Regular.ttf", ClearSansBase64);
    doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
    doc.setFont("ClearSans", "Regular");
    var col = [
      `${t("Bank")}`,
      `${t("Currency")}`,
      `${t("IBAN")}`,
      `${t("AccountHolder")}`,
    ];

    var rows = [];

    if (allPlatformBanks && allPlatformBanks.length !== 0) {
       for(let a = 0 ; a < allPlatformBanks.length ; a++) {
        
        let platFormOwners = allPlatformBanks[a]?.ownersBankAccount
        for(let i = 0 ; i<platFormOwners.length ; i++){
        
          if(platFormOwners[i]?.IBANs !== ""){
        
            var temp = [
              allPlatformBanks[a]?.bankName,
              // element.ownersBankAccount !== undefined &&
              // element.ownersBankAccount.length !== 0
              //   ? element["ownersBankAccount"].filter((bnk) => {
              //       if (bankDropDownMapper[element.id] !== undefined) {
              //         // console.log(
              //         //   plaformcurrencies[bankDropDownMapper[element.id]]
              //         // );
              //         // console.log("Bank currency type -> ", bnk);
              //         console.log(
              //           `Bank name -> ${element.bankName} Bank filtered -> ${bnk}`
              //         );
              //         if (
              //           bnk.currencyType ===
              //           plaformcurrencies[bankDropDownMapper[element.id]]
              //             .exchangeSymbol
              //         ) {
              //           console.log(
              //             `Bank name -> ${element.bankName} Bank filtered -> ${bnk}`
              //           );
              //           return bnk;
              //         }
              //       }
              //     })[0]?.currencyType
              //   : "-",
             
              platFormOwners[i]?.currencyType,
               platFormOwners[i]?.IBANs,
              allPlatformBanks[a]?.accountHoldersName,
            ];
            rows.push(temp);
          }
         
        }
        
         
      };
    }

    doc.autoTable(col, rows, {
      startY: 5,
      styles: {
        font: "ClearSans",
        fontSize: 8,
      },
    });
    doc.save(`${t("ListOfBanks")}.pdf`);
  };

  useEffect(() => {
     if (adminBankCurrencyFilter !== undefined) {
      let bankDrop = {};
      if (allPlatformBanks && allPlatformBanks.length !== 0) {
        allPlatformBanks.forEach((bnk) => {
          bankDrop[bnk.id] = adminBankCurrencyFilter;
        });
        setBankDropDownMapper(bankDrop);
      }
      setSelectCurrencyIndex(0);
      callAllAdminBanksAsync();
    } else {
      let bankDrop = {};
      if (allPlatformBanks && allPlatformBanks.length !== 0) {
        allPlatformBanks.forEach((bnk) => {
          bankDrop[bnk.id] = 0;
        });
        setBankDropDownMapper(bankDrop);
      }
      setSelectCurrencyIndex(0);
      callAllAdminBanksAsync();
    }

    return () => {
      clearAdminBankCurrencyFilterAsync();
    };
  }, []);

  useEffect(() => {
    if (adminBankCurrencyFilter !== undefined) {
      let bankDrop = {};
      if (allPlatformBanks && allPlatformBanks.length !== 0) {
        allPlatformBanks.forEach((bnk) => {
          bankDrop[bnk.id] = adminBankCurrencyFilter;
        });
        setBankDropDownMapper(bankDrop);
      }
      setSelectCurrencyIndex(0);
    } else {
      let bankDrop = {};
      if (allPlatformBanks && allPlatformBanks.length !== 0) {
        allPlatformBanks.forEach((bnk) => {
          bankDrop[bnk.id] = 0;
        });
        setBankDropDownMapper(bankDrop);
      }
      setSelectCurrencyIndex(0);
    }
  }, [allPlatformBanks]);

  useEffect(() => {
    if (adminBankCurrencyFilter !== undefined) {
      let bankDrop = {};
      if (allPlatformBanks && allPlatformBanks.length !== 0) {
        allPlatformBanks.forEach((bnk) => {
          bankDrop[bnk.id] = adminBankCurrencyFilter;
        });
        setBankDropDownMapper(bankDrop);
      }
      setSelectCurrencyIndex(0);
    }
  }, [adminBankCurrencyFilter]);

 

  const returnTableContent = () => {
    let content =
      allPlatformBanks &&
      allPlatformBanks.map((bank) => (
        <>
          <tr className="trtbleheading maintdwrapper">
            {hideSpec && (
              <td className="innercontent">
                <div className="innerTd d-lg-flex ">
                  <span className="BankListItem">
                    {" "}
                    <img
                      className="bankimgs"
                      src={`${process.env.REACT_APP_IMGLINK}${
                        thememode != "light"
                          ? bank && bank.colouredLogo && bank.colouredLogo
                          : bank && bank.nonColouredLogo && bank.nonColouredLogo
                      }`}
                    />
                    <span className="text">{bank?.bankName}</span>
                  </span>
                </div>
              </td>
            )}
            {!hideSpec && (
              <td className="innercontent">
                <div className="innerTd d-lg-flex ">
                  <span className="BankListItem">
                    {" "}
                    {/* <img
                    className="bankimgs"
                    src={`${process.env.REACT_APP_IMGLINK}${
                      thememode != "light"
                        ? bank && bank.colouredLogo && bank.colouredLogo
                        : bank && bank.nonColouredLogo && bank.nonColouredLogo
                    }`}
                  /> */}
                    <span className="text">{bank?.bankName}</span>
                  </span>
                </div>
              </td>
            )}

            <td className="innercontent">
              <div className="innerTd d-lg-flex">
                {bank.ownersBankAccount !== undefined &&
                bank.ownersBankAccount.length !== 0 ? (
                  plaformcurrencies &&
                  plaformcurrencies.length !== 0 && (
                    <div
                      className="fiatcurrencydropdown"
                      onClick={() => {
                        //setCoinFromDropdown();
                        // getBanksBasedOnCurrency();
                      }}
                    >
                      <DropdownSearch
                        options={plaformcurrencies && plaformcurrencies}
                        exchangeSymbolshow={true}
                        setSelectedIndex={(e) => {
                          setBankIdToBeUpdated(bank.id);
                          //setSelectCurrencyIndex(e);
                          setBankDropDownMapper((prevState) => {
                            return {
                              ...prevState,
                              [bank.id]: e,
                            };
                          });
                        }}
                        Selected={bankDropDownMapper[bank.id]}
                        //Selected={selectCurrencyIndex}
                        // scope="FiatDeposit"
                      />

                      {/* {!hideSpec &&   <span>{plaformcurrencies !==undefined && bankDropDownMapper !==undefined && plaformcurrencies[bankDropDownMapper[bank.id]]?.exchangeSymbol}</span> } */}
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            </td>

            <td className="innercontent">
              <div className="innerTd d-lg-flex">
                <span className="text">
                  {bank.ownersBankAccount !== undefined &&
                  bank.ownersBankAccount.length !== 0 ? (
                    <>
                      {
                        bank["ownersBankAccount"].filter((bnk) => {
                          if (bankDropDownMapper[bank.id] !== undefined) {
                            if (
                              bnk.currencyType ===
                              plaformcurrencies[bankDropDownMapper[bank.id]]
                                ?.exchangeSymbol
                            ) {
                              return bnk;
                            }
                          }
                        })[0]?.IBANs
                      }
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </td>
            <td className="innercontent">
              <div className="innerTd d-lg-flex ">
                {/* {bank.ownersBankAccount !== undefined &&
                bank.ownersBankAccount.length !== 0 ? (
                  <>
                    {
                      bank["ownersBankAccount"].filter((bnk) => {
                        if (bankDropDownMapper[bank.id] !== undefined) {
                          if (
                            bnk.currencyType ===
                            plaformcurrencies[bankDropDownMapper[bank.id]]
                              .exchangeSymbol
                          ) {
                            return bnk;
                          }
                        }
                      })[0]?.accountHolderName
                    }
                  </>
                ) : (
                  <></>
                )} */}
                {bank?.accountHoldersName}
              </div>
            </td>
            {hideSpec && (
              <td className="innercontent">
                <div className="buttonwrapper innerTd  d-lg-flex">
                  <EditLogo
                    alt="Edit Logo"
                    className="editbutton"
                    //onClick={() => EditBranch(branch.id, branch)}
                    onClick={() => {
                      setCurrentBankSelected(bank);
                      setScope("BankEdit");
                      //setShowAddBankForm(true)
                      setAddEditModal(true);
                    }}
                  />
                  <DeleteLogo
                    alt="Delete Logo"
                    className="editbutton"
                    //onClick={() => DeleteBranch(branch.id)}
                    onClick={() => {
                      setCurrentBankSelected(bank.id);
                      setOpenDeleteBankPopup(true);
                    }}
                  />
                </div>
                <div className="innerTd d-flex d-none">
                  <RightArrow
                    className="rightarrow"
                    //onClick={() => EditBranch(branch.id, branch)}
                  />
                </div>
              </td>
            )}
          </tr>
        </>
      ));
    return content;
  };
  return (
    <>
      <ScreenLayout hideLoaderOnStart={true}>
        <div id="AdminAllBanks">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="trouter">
                  <div className="transactiontitle">
                    <span className=" title">{t("ListOfBanks")}</span>
                    <div className="filtersection ">
                      <div className="addbank">
                        <div
                          className="textfield"
                          onClick={() => {
                            setScope("BankAdd");
                            setAddEditModal(true);
                          }}
                        >
                          <span className="infotext">
                            {t("AddBankAccount")}
                          </span>
                          <div className="logoholder">
                            <img src={PlusIcon} className="addicon" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filtersection">
                      <div className="filtercurrency">
                        <Dropdown align="end" autoClose="inside">
                          <Dropdown.Toggle>
                            <span className="filtericon">
                              {adminBankCurrencyFilter !== undefined ? (
                                ""
                              ) : (
                                <FilterIcon />
                              )}
                            </span>
                            {/* <span className="filtertext">Filter Periode</span> */}
                            <span className="currencyfiltertext">
                              {adminBankCurrencyFilter !== undefined
                                ? plaformcurrencies[adminBankCurrencyFilter]
                                    ?.exchangeSymbol
                                : t("FilterCurrency")}
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {plaformcurrencies &&
                              plaformcurrencies.map((currency, index) => (
                                <>
                                  <Dropdown.Item
                                    eventKey={currency.exchangeSymbol}
                                    key={currency.exchangeSymbol}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setAdminBankCurrencyFilterAsync(index);
                                      //setFilterCurrencyIndex(index);
                                    }}
                                  >
                                    {currency.exchangeSymbol}
                                  </Dropdown.Item>
                                </>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="actions">
                  <div className="rightsection">
                    <div className="thethreebutton">
                      <div className="buttoncontainersm">
                        <ReactToPrint
                          trigger={() => (
                            <CustomButton
                              type="submit"
                              name={t("Print")}
                              testvalue={
                                <PrinterIcon className="iconcolor mleft" />
                              }
                              text={true}
                              image={true}
                              onClick={() => {
                                setPrint(true);
                                setHideSpec(false);
                              }}
                            />
                          )}
                          content={() => componentRef.current}
                          onBeforeGetContent={() => {
                            orientation: "landscape";
                            setHideSpec(false);
                          }}
                        />
                      </div>

                      <div className="buttoncontainersm">
                        <CustomButton
                          type="submit"
                          name="PDF"
                          testvalue={<PdfIcon className="mleft" />}
                          text={true}
                          image={true}
                          onClick={() => {
                            generatePdf();
                          }}
                        />
                      </div>

                      <div className="buttoncontainersm mr-0">
                        <ExportSheet
                          header={excelHeader}
                          fileName={`download`}
                          dataSource={excelData}
                          xlsx={XLSX}
                        >
                          <CustomButton
                            type="submit"
                            name={t("Excel")}
                            testvalue={<ExcelIcon className="mleft" />}
                            text={true}
                            image={true}
                            onClick={() => {
                              //filterPaymentInfoForExcelExport();
                            }}
                          />
                        </ExportSheet>
                      </div>
                    </div>
                  </div>
                </div>
                <DeleteAdminBankPopup
                  show1={openDeleteBankPopup}
                  setShowModal={(val) => setOpenDeleteBankPopup(val)}
                  msg="Doyouwanttodeletethisbank"
                  onClick={() => {
                    deleteAdminBank(currentBankSelected);
                    setOpenDeleteBankPopup(false);
                  }}
                />

                <AdminSuccess
                  show={addAdminBankSuccess || editAdminBankSuccess}
                  msg={
                    editAdminBankSuccess
                      ? "Bank account updated successfully"
                      : addAdminBankSuccess
                      ? "Bank account added successfully"
                      : ""
                  }
                />

                <AddBankPopup
                  showAddBankConfirmation={openAddEditModal}
                  setShowAddBankConfirmation={(val) => setAddEditModal(val)}
                  scope={scope}
                  setScope={setScope}
                  setCurrentBankSelected={(val) => setCurrentBankSelected(val)}
                  currentBankSelected={currentBankSelected}
                />

                <Table
                  responsive="sm"
                  className={`transfertbl ${
                    thememode == "light" ? "tableinlight" : "tableindark"
                  }`}
                  borderless
                 >
                  <Loader
                    loading={allPlatformBanksLoading || deleteAdminBankLoading}
                  />
                  <thead className="hidden-for-mobile">
                    <tr>
                      <th className="trtbleheading paddingleft">
                        {t("Bank")}
                        {/* <span className="sorticon">
                          <SortupIcon
                            className="uparrow"
                            onClick={() => {
                              // setOrderBy('id');
                              // setSortBy('ASC');
                              // listAllBranches({
                              //   orderBy : 'id',
                              //   sort : 'ASC',
                              // })
                            }}
                          />

                          <SortdownIcon
                            className="downarrow"
                            onClick={() => {
                              //   setOrderBy('id');
                              //   setSortBy('DESC');
                              //   listAllBranches({
                              //     orderBy : 'id',
                              //     sort : 'DESC',
                              //   })
                            }}
                          />
                        </span> */}
                      </th>
                      <th className="trtbleheading">
                        {t("Currency")}{" "}
                        <span className="sorticon">
                          <SortupIcon
                            className="uparrow"
                            onClick={() => {
                              //   setOrderBy('status');
                              //   setSortBy('ASC');
                              //   listAllBranches({
                              //     orderBy : 'status',
                              //     sort : 'ASC',
                              //   })
                            }}
                          />

                          <SortdownIcon
                            className="downarrow"
                            onClick={() => {
                              //   setOrderBy('status');
                              //   setSortBy('DESC');
                              //   listAllBranches({
                              //     orderBy : 'status',
                              //     sort : 'DESC',
                              //   })
                            }}
                          />
                        </span>
                      </th>
                      <th className="trtbleheading">
                        {t("IBAN")}{" "}
                        {/* <span className="sorticon">
                          <SortupIcon
                            className="uparrow"
                            onClick={() => {
                              //   setOrderBy('accountName');
                              //   setSortBy('ASC');
                              //   listAllBranches({
                              //     orderBy : 'accountName',
                              //     sort : 'ASC',
                              //   })
                            }}
                          />

                          <SortdownIcon
                            className="downarrow"
                            onClick={() => {
                              //   setOrderBy('accountName');
                              //   setSortBy('DESC');
                              //   listAllBranches({
                              //     orderBy : 'accountName',
                              //     sort : 'DESC',
                              //   })
                            }}
                          />
                        </span> */}
                      </th>
                      <th className="trtbleheading">
                        {t("AccountHolder")}
                        {/* <span className="sorticon">
                          <SortupIcon
                            className="uparrow"
                            onClick={() => {
                              //   setOrderBy('branchName');
                              //   setSortBy('ASC');
                              //   listAllBranches({
                              //     orderBy : 'branchName',
                              //     sort : 'ASC',
                              //   })
                            }}
                          />

                          <SortdownIcon
                            className="downarrow"
                            onClick={() => {
                              //   setOrderBy('branchName');
                              //   setSortBy('DESC');
                              //   listAllBranches({
                              //     orderBy : 'branchName',
                              //     sort : 'DESC',
                              //   })
                            }}
                          />
                        </span> */}
                      </th>
                      {hideSpec && (
                        <th className="trtbleheading">
                          {t("Edit")}{" "}
                          <span className="sorticon">
                            {/* <SortupIcon className="uparrow" />

                          <SortdownIcon className="downarrow" /> */}
                          </span>
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {allPlatformBanks && allPlatformBanks.length === 0 ? (
                      <p className="trtbleheading">
                        {t("Sorrynoresultsfound")}!
                      </p>
                    ) : (
                      returnTableContent()
                    )}
                  </tbody>
                </Table>
                <div style={{ display: "none" }}>
                  <Table
                    responsive="sm"
                    className={`transfertbl ${
                      thememode == "light" ? "tableinlight" : "tableindark"
                    }`}
                    borderless
                    ref={componentRef}
                  >
                    <Loader
                      loading={
                        allPlatformBanksLoading || deleteAdminBankLoading
                      }
                    />
                    <thead className="hidden-for-mobile">
                      <tr>
                        <th className="trtbleheading paddingleft">
                          {t("Bank")}
                        </th>
                        <th className="trtbleheading">
                          {t("Currency")} <span className="sorticon"></span>
                        </th>
                        <th className="trtbleheading">{t("IBAN")} </th>
                        <th className="trtbleheading">{t("AccountHolder")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {allPlatformBanks && allPlatformBanks.length === 0 ? (
                        <p className="trtbleheading">
                          {t("Sorrynoresultsfound")}!
                        </p>
                      ) : (
                        <>
                          {allPlatformBanks &&
                            allPlatformBanks.map(
                              (bank) =>
                                bank.ownersBankAccount !== undefined &&
                                bank.ownersBankAccount.length !== 0 &&
                                bank.ownersBankAccount.map((itm) => (
                                 <>
                                 { itm.IBANs!=""&&
                                  <tr className="trtbleheading maintdwrapper">
                                    <td className="innercontent">
                                      <div className="innerTd d-lg-flex ">
                                        <span className="BankListItem">
                                          <span className="text">
                                            {bank?.bankName}
                                          </span>
                                        </span>
                                      </div>
                                    </td>

                                    <td className="innercontent">
                                      <div className="innerTd d-lg-flex">
                                        {itm.currencyType}
                                      </div>
                                    </td>

                                    <td className="innercontent">
                                      <div className="innerTd d-lg-flex">
                                        <span className="text">
                                          {itm.IBANs}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="innercontent">
                                      <div className="innerTd d-lg-flex ">
                                        {bank?.accountHoldersName}
                                      </div>
                                    </td>
                                  </tr>}
                                  </>
                                ))
                            )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterCommon />
      </ScreenLayout>
    </>
  );
};

const mapStateToProps = ({ admin, wallet, app }) => {
  const allPlatformBanks = _.get(admin, "allPlatformBanks", undefined);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const deleteAdminBankLoading = _.get(admin, "deleteAdminBankLoading", false);
  const addAdminBankSuccess = _.get(admin, "addAdminBankSuccess", false);
  const editAdminBankSuccess = _.get(admin, "editAdminBankSuccess", false);
  const adminBankCurrencyFilter = _.get(
    admin,
    "adminBankCurrencyFilter",
    undefined
  );
  const allPlatformBanksLoading = _.get(
    admin,
    "allPlatformBanksLoading",
    false
  );
  const thememode = _.get(app, "thememode", false);
  const allPlatformBanksDownload = _.get(
    admin,
    "allPlatformBanksDownload",
    undefined
  );
  return {
    allPlatformBanks,
    allPlatformBanksLoading,
    plaformcurrencies,
    thememode,
    deleteAdminBankLoading,
    addAdminBankSuccess,
    editAdminBankSuccess,
    adminBankCurrencyFilter,
    // allPlatformBanksDownload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAllAdminBanksAsync: () => dispatch({ type: "callAllAdminBanksAsync" }),
  deleteAdminBank: (id) =>
    dispatch({ type: "deleteAdminBank", payload: { id: id } }),
  setAdminBankCurrencyFilterAsync: (index) =>
    dispatch(setAdminBankCurrencyFilter(index)),
  clearAdminBankCurrencyFilterAsync: () =>
    dispatch(clearAdminBankCurrencyFilter()),
  // callAllAdminBanksAsyncDownload: () => dispatch({ type: "callAllAdminBanksAsyncDownload" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAllBanks);
