import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import CustomButton from "@components/custom-button/CustomButton";
import { Modal, Container, Row, Col } from "react-bootstrap";
//import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as CloseIcon } from "@images/close2.svg";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import "@components/exchange-confirmationpopup/ExchangeConfirmationPopupStyles.scss";
import { useTranslation } from "react-i18next";

const ExchangeConfirmationPopup = ({
  show1,
  setShowModal,
  amt1,
  amt2,
  onPress,
  setExchangeModal,
  setHideThis,
  setshowmodal,
  showmodal,
  Commission,
  Total,
  tableno,
  decentraliseEPrice,
}) => {
  const { t, i18n, ready } = useTranslation();

  return (
    <>
      <Modal
        className="exchangeconfirmationpopup"
        show={show1}
        onHide={() => {
          setHideThis(false);
          setShowModal(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <CloseIcon
            className="closebtn"
            onClick={() => {
              setHideThis(false);
              setShowModal(false);
            }}
          />
          <Container>
            <Row>
              <Col lg={12}>
                <div
                  className="rightnav topright hideclose"
                  onClick={() => {
                    setHideThis(false);
                    setShowModal(false);
                  }}
                >
                  {" "}
                  <ExpandIcon />
                </div>
                <div className="exchangetitle">
                  <span>{t("Exchange")}</span>
                </div>
                <div
                  className={`exchangetext ${
                    tableno == "second" ? "mb-4" : "m-0"
                  }`}
                >
                  {`${t("Exchange")}: ${amt1}`}{" "}
                  {`${t("to")}  ${
                    tableno == "second" ? decentraliseEPrice : amt2
                  }`}
                </div>
                {tableno != "second" && (
                  <div className="exchangetext">
                    <span>
                      {t("Commission")}: {Commission}
                    </span>
                  </div>
                )}
                {tableno != "second" && (
                  <div className="exchangeamt">
                    <span>
                      {t("Total")}: {Total}
                    </span>
                  </div>
                )}
              </Col>
              <Col lg={6} sm={6} xs={6}>
                <div className="cancel">
                  <CustomButton
                    type="submit"
                    name={t("Cancel")}
                    filled={true}
                    primary={true}
                    onClick={() => {
                      setHideThis(false);
                      setShowModal(false);
                    }}
                  />
                </div>
              </Col>
              <Col lg={6} sm={6} xs={6}>
                <div className="confirm">
                  <CustomButton
                    type="submit"
                    name={t("Confirm")}
                    filled={true}
                    primary={true}
                    // onClick={onPress}
                    onClick={() => {
                      onPress();
                      setShowModal(false);
                      setHideThis(false);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(ExchangeConfirmationPopup);
