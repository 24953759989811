import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import CoinBalanceDetails from "@components/coin-balance-details/CoinBalanceDetails";
import "@components/my-balances-popup/MyBalancePopupStyles.scss";
import FooterCommon from "@components/footer-common/FooterCommon";
import { ReactComponent as ExpandIcon } from "@images/sidebar/expand1.svg";
import { useTranslation } from "react-i18next";

const MyBalancePopup = (props) => {
    const { show, setShow, setActivekey, setcoinItem, tableno, history } = props;
  return (
    <Modal
      className="mybalancepopup"
      show={show}
      onHide={() =>{
          history.replace(`/mybalances`)
          setShow(false)
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Body>
            <div className="commonModalWrapper">
                <div className="modalPrimaryHeader"></div>
                    <div className="modalContent">
                         <div
                            className="coindetailsnav"
                            onClick={() => {
                                history.replace(`/mybalances`)
                                setShow(false)
                                setActivekey('')
                                setcoinItem('')
                            }}
                        >
                            <ExpandIcon />
                        </div>
                        <CoinBalanceDetails
                            isModal={true}
                            currTableno={tableno}
                            {...props}
                        />
                    </div>
                </div>
                <div className="modalFooter">
                    <div className="mt-3">
                        <FooterCommon />
                    </div>
                </div>
            {/* </div> */}
        </Modal.Body>
    </Modal>
  );
};

export default MyBalancePopup;
