export default {
  IDOTransferHistory: "IDO Transfer History",
  TransactionID: "Transaction ID",
  RoundNo: "Round No",
  BoughtOn: "Bought On",
  LockedFor: "Locked For",
  UnlockDate: "Unlock Date",
  TokensAllocated: "Tokens Allocated",
  Status: "Status",
  Action: "Action",
  days: "days",
  CYBOR: "CYBOR",
};
