export default {
  Logo: "Логотип",
  Thesizeofimage: "Размер изображения должен быть до",
  pixels: "пикселей",
  sizetothefile: "размер файла",
  upto: " до",
  inPNGorJPEGformat: "в PNG или JPEG формате",
  Change: " Изменить",
  Account: "Аккаунт",
  Branch: "Отделение",
  Phone: "Телефон",
  email: "E-mail",
  Address: "Адрес",
  Password: "Пароль",
  Confirm: "Подтвердить",
  AccountName: "Имя аккаунта",
  EnterPassword: "Введите пароль",
  ReEnterPassword: "Повторите пароль",
  ImageRequired: "Требуется изображение",
  PhoneEmpty: "Телефон не может быть пустым",
  Email_empty: "Email не может быть постым",
  Passwordempty: "Пароль не может быть пустым",
  false: "ложный",
  Cryptocurrency: "Криптовалюта",
  Fiat: "Фиатная валюта",
  "Please enter a valid email address": "Пожалуйста, введите действительный адрес электронной почты",
  "Please enter a valid phone no": "Пожалуйста, введите действительный номер телефона",
  "Please enter a branch name": "Пожалуйста, введите название филиала",
  "Please enter an account name": "Пожалуйста, введите имя учетной записи",
  "EMAIL_ALREADY_IN_USE": "E-mail уже использован",
  "MOBILE_ALREADY_IN_USE": "Мобильный номер, который Вы ввели, уже зарегистрирован.",
  "INVALID_ROLE": "Недопустимая роль",
  "Email_empty": "Email пустой",
  "NAME_CANNOT_BE_A_NUMBER":"Имя не может быть числом",
};
