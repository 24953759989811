import * as _ from "lodash";
import Profile from "@components/profile/Profile";
import React, { Fragment, Profiler, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "@components/profilemenuoption/ProfileMenuStyles.scss";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { ReactComponent as SettingIcon } from "@images/setting1.svg";
import { ReactComponent as AccountIcon } from "@images/Accounts.svg";
import { ReactComponent as InviteIcon } from "@images/invite.svg";
import { ReactComponent as LimitIcon } from "@images/limits.svg";
import { ReactComponent as SupportIcon } from "@images/support.svg";
import { ReactComponent as MywalletIcon } from "@images/sidebar/mywallet1.svg";
import { ReactComponent as SettingsIcon } from "@images/sidebar/psetting.svg";
import { ReactComponent as Reports } from "@images/sidebar/reports.svg";
import { ReactComponent as LogoutIcon } from "@images/logout.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import Withdrawal from "../withdrawal/Withdrawal";
import ExchangeModal from "../exchange-modal/ExchangeModal";
import DepositModal from "../deposit-modal/DepositModal";
import WithdrawalFiat from "@components/withdrawal-fiat/WithdrawalFiat";
import DepositFiatModal from "../depositfiat-modal/DepositFiatModal";
import Logout from "src/components/log-out/Logout";
import SmallButton from "@components/small-button/SmallButton";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";

function ProfileMenu({
  MyProfileInfo,
  logouts,
  thememode,
  myaccountandbalance,
  getplatformTokensinfo,
  myEstimatedBal,
  PreferredCurrency
}) {
  let history = useHistory();
  const { t, i18n, ready } = useTranslation();
  const [show, setShowLogout] = useState(false);
  const [value, setvalue] = useState("");
  let [tableNo, setTableNo] = useState("first");
  const [coinItem, setcoinItem] = useState({});

  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showExchangeModal, setShowExchangeModal] = useState(false);
  const [showFiatDepositModal, setShowFiatDepositModal] = useState(false);
  const [showFiatWithdrawModal, setShowFiatWithdrawModal] = useState(false);

  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", " ");

    return address;
  };

  return (
    <div className="profilemenu">
      <Fragment>
        <Dropdown.Menu className="optionlist">
          <div className=" dpitem" href="/">
            <span className="d-lg-block d-none">
              <Profile
                MyProfileInfo={MyProfileInfo}
                classname={"profilename"}
                className="profilea"
                showusername={true}
              />
            </span>
            <span className="d-lg-none d-block">
              <Profile
                MyProfileInfo={MyProfileInfo}
                classname={"profilename"}
                classname2={"drpprofile"}
                className="profilea"
                showoccupation={true}
                showverified={true}
              />
            </span>
          </div>
          <hr className="hrcolor" />
          {/* <div className="aligncenter">
            <div className="inlineblock">
              <div className="mybal">
                <span>{t("MyBalance")} </span>
              </div>
              <div className="amtusd">
                <span className="count">
                  {Number(myEstimatedBal).toFixed(2)}{" "}
                </span>
                <span className="symbol">{PreferredCurrency}</span>
              </div>
            </div> */}
            {/* <div className="amtbox">
              <div className="amount">
                <span>Accounts : </span>
              </div>
              <div className="credotxt">
                <span className="normaltxt normspace">basic</span>
                <span className="credovalue">0</span> 
                <span className="normaltxt">credo</span>
              </div>
              <div className="credotxt">
                <span className="normaltxt normspace1">gift</span>
                <span className="credovalue">0</span>
                <span className="normaltxt">credo</span>
              </div>
            </div> */}
          {/* </div> */}
          <div className="mainbuttonswrapper">
            <SmallButton
              type="deposit"
              onclick={() => {
                setcoinItem(
                  myaccountandbalance &&
                    myaccountandbalance[0] &&
                    myaccountandbalance[0].coin
                );
                setShowDepositModal(true);
              }}
            />
            <SmallButton
              type="withdraw"
              onclick={() => {
                setcoinItem(
                  myaccountandbalance &&
                    myaccountandbalance[0] &&
                    myaccountandbalance[0].coin
                );
                setShowWithdrawModal(true);
              }}
            />
            <SmallButton
              type="exhange"
              onclick={() => {
                setShowExchangeModal(true);
              }}
            />
          </div>
          <hr className="hrcolor" />
          <Dropdown.Item
            className=" dropdownspace"
            onClick={() => {
              MyProfileInfo?.role == "Business"
                ? history.push("/mywallet")
                : history.push("/cryptowallet");
            }}
          >
            <span>
              <MywalletIcon className="profileimgicon seticon" />
            </span>
            <span className="pfname">{t("MyWallet")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            className=" dropdownspace"
            onClick={() => {
              history.push({
                pathname: "/personaldashsetting",
                state: { tab: "second" },
              });
            }}
          >
            <span>
              <AccountIcon
                className={`profileimgicon ${
                  thememode == "dark" ? "myaccounticon" : "myaccountdarkicon"
                } seticon`}
              />
            </span>
            <span className="pfname">{t("MyAccounts")}</span>
          </Dropdown.Item>
          {/* <Dropdown.Item className=" dropdownspace" href="">
            <span>
              <LimitIcon className="profileimgicon seticon" />
            </span>
            <span className="pfname">My Limits</span>
          </Dropdown.Item>
          <Dropdown.Item className=" dropdownspace" href="">
            <span>
              <Reports className="profileimgicon seticon" />
            </span>
            <span className="pfname">Reports</span>
          </Dropdown.Item>
          <Dropdown.Item className=" dropdownspace" href="">
            <span>
              <SupportIcon className="profileimgicon seticon" />
            </span>
            <span className="pfname">Support</span>
          </Dropdown.Item> */}
          <Dropdown.Item
            className=" dropdownspace"
            onClick={() => {
              history.push({
                pathname: "/personaldashsetting",
                state: { tab: "first" },
              });
            }}
          >
            <span>
              <SettingsIcon className="profileimgicon seticon" />
            </span>
            <span className="pfname">{t("Settings")}</span>
          </Dropdown.Item>
          {/* <Dropdown.Item className=" dropdownspace" href="">
            <span>
              <InviteIcon className="profileimgicon seticon" />
            </span>
            <span className="pfname">Invite Your Friend</span>
          </Dropdown.Item> */}
          <hr className="hrcolor" />

          <Dropdown.Item
            className="dropdownspace1"
            //
            onClick={() => {
              setShowLogout(true);
            }}
          >
            <span>
              <LogoutIcon className="profileimgicon logicon" />
            </span>
            <span className="logtxt">{t("LogOut")}</span>{" "}
            <span className="nextarr">
              {" "}
              <NextIcon className="profileimgicon" />
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Fragment>
      <Logout
        onClick={() => logouts({ history })}
        show1={show}
        setShowModal={(e) => setShowLogout(e)}
      />
      <DepositModal
        getAdd={getAdd}
        setcoinItem={setcoinItem}
        value={value}
        coinItem={coinItem}
        setvalue={setvalue}
        getplatformTokensinfo={getplatformTokensinfo}
        getBal={getBal}
        showToggle={true}
        show={showDepositModal}
        setdepositModal={(e) => setShowDepositModal(e)}
        tableno={tableNo}
        settableno={(e) => {
          setTableNo(e);
          setShowDepositModal(false);
          setShowFiatDepositModal(true);
        }}
      />
      <Withdrawal
        getAdd={getAdd}
        setcoinItem={setcoinItem}
        value={value}
        coinItem={coinItem}
        setvalue={setvalue}
        getplatformTokensinfo={getplatformTokensinfo}
        getBal={getBal}
        showToggle={true}
        tableno={tableNo}
        settableno={(e) => {
          setTableNo(e);
          setShowWithdrawModal(false);
          setShowFiatWithdrawModal(true);
        }}
        show1={showWithdrawModal}
        setShowWithdraw={(e) => setShowWithdrawModal(e)}
      />
      <ExchangeModal
        coinItem={coinItem}
        getplatformTokensinfo={getplatformTokensinfo}
        getBal={getBal}
        show2={showExchangeModal}
        setExchangeModal={(e) => setShowExchangeModal(e)}
      />
      <DepositFiatModal
        setShowDeposit={(e) => {
          setShowFiatDepositModal(e);
          setTableNo("first");
        }}
        show={showFiatDepositModal}
        setTableno={(e) => {
          setTableNo(e);
          setShowFiatDepositModal(false);
          setShowDepositModal(true);
        }}
        tableNo={tableNo}
        showToggle={true}
        selectedCurrency={coinItem}
        setcoinItem={setcoinItem}
      />
      <WithdrawalFiat
        setShowWithdraw={(e) => {
          setShowFiatWithdrawModal(e);
          setTableNo("first");
        }}
        show1={showFiatWithdrawModal}
        showToggle={true}
        setTableno={(e) => {
          setTableNo(e);
          setShowFiatWithdrawModal(false);
          setShowWithdrawModal(true);
        }}
        tableNo={tableNo}
        selectedCurrency={coinItem}
      />
    </div>
  );
}

const mapStateToProps = ({ wallet = {}, app = {}, user = {}, fiat = {} }) => {
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);
  const MyProfileInfo = _.get(user, "MyProfileInfo", false);
  const thememode = _.get(app, "thememode", false);
  const myEstimatedBal = _.get(user, "myEstimatedBal", 0);
  const PreferredCurrency = _.get(user, "PreferredCurrency", 'USD');

  return {
    myaccountandbalance,
    getplatformTokensinfo,
    getFiatBalanceinfo,
    MyProfileInfo,
    thememode,
    myEstimatedBal,
    PreferredCurrency
  };
};

const mapDispatchToProps = (dispatch) => ({
  logouts: (data) => dispatch({ type: "logoutCalled", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
