import * as _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  setAuthenticatorModalSuccess,
  setConfirmcodeModalSuccess,
  clearappcacheSuccess,
} from "./appredux";
import { clearBrnachReduxSuccuss } from "./branchmanagementredux";
import { clearFiatReduxSuccuss } from "./fiatredux";
import { clearIdoReduxSuccuss } from "./idoredux";
import { clearPaymentLinkReduxSuccuss } from "./paymentlinkredux";
import {
  clearcacheSuccess,
  getMywalletsSuccess,
  getPlatformTokensSuccess,
  getAccountAndBalanceByWalletIdSuccess,
} from "./platformuserredux";
import {
  loginFail,
  UserloginSuccess,
  loginTrigger,
  logoutSuccess,
  signupFail,
  UsersignupSuccess,
  signUpTrigger,
  forgotPasswordTrigger,
  forgotPasswordFail,
  forgotPasswordSuccess,
  resendotpTrigger,
  otpverifyFail,
  otpverifyerror,
  otpverifyTrigger,
  OTPSuccess,
  createCryptoWalletSuccess,
  createCryptoWalletTrigger,
  createCryptoWalletError,
  createCryptoWalletFail,
  createFiatWalletSuccess,
  createFiatWalletTrigger,
  createFiatWalletError,
  createFiatWalletFail,
  addSuperManagerSuccess,
  addSuperManagerFail,
  getMyprofileSuccess,
  clearErrorMessageSuccess,
  clearResetPopupErrorSuccess,
  getBusinessUserRequestSuccess,
  BusinessRequestTrigger,
  createBusinessFormSuccess,
  createBusinessFormTrigger,
  createBusinessFormFail,
  giveApprovalTrigger,
  giveApprovalSuccess,
  giveApprovalFail,
  userDashboardListSuccess,
  profileImageUploadSuccess,
  profileImageRemoveSuccess,
  profileImageUploadFail,
  updateProfileTrigger,
  updateProfileSuccess,
  updateProfileFail,
  updateProfileImageTrigger,
  updateProfileIamgeTriggerFailure,
  storeAuthSuccess,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  setDisplayedCurrencies,
  setgoogleAuthSuccess,
  setConfirmcodeModalError,
  setgoogleAuthLoading,
  removeProfileImageTrigger,
  removeProfileImageFailure,
  getAddressBookingsTrigger,
  getAddressBookingsSuccess,
  getAddressBookingsFailure,
  addAddressBookingTrigger,
  addAddressBookingSuccess,
  addAddressBookingFailure,
  editAddressBookingTrigger,
  editAddressBookingSuccess,
  editAddressBookingFailure,
  deleteAddressBookingTrigger,
  deleteAddressBookingSuccess,
  deleteAddressBookingFailure,
  storeMyBalanceSuccess,
  storeMyBalanceFailure,
  storePreferredCurrencySuccess,
  setDashboardCurrencies,
  setMarketRatesCurrencies,

  allNfcCardLoadingStart,
  allNfcCardSuccess,
  allNfcCardFail,
  createNfcCardLoadingStart,
  createNfcCardSuccess,
  createNfcCardFailed,
  listNfcCardLoadingStart,
  listNfcCardSuccess,
  listNfcCardFailed,
} from "./userredux";
import {
  getAccessToken,
  getfiatWalletId,
  getUserRole,
  getWalletId,
} from "./utilities";

export function* usersignIn(api, { payload = {} }) {
  yield put(loginTrigger(true));
  const {
    navigation,
    email,
    password,
    loginType,
    mobile,
    exchangeId,
    captchadata,
    locations,
    role,
    lang,
  } = payload;
  let erromsg = "";
  try {
    let data = {
      exchangeId,
      password,
      loginType: "email",
      lang,
    };
    // if (loginType == "mobile") data.mobile = mobile;
    data.email = email;
    let auth = true; /// make it false for authenicator
    const result = yield call(Api.callServer, api.UsersignIn, data, true);
    if (result.role == "Admin") {
      auth = true;
    }

    // if ("Business"
    //   locations == "localhost:3001" ||
    //   locations == "bizcybor.sparkrex.com" ||
    //   locations == "biztest.cyborpay.com"
    // ) {
    if (result.role != "Admin") {
      if (role != result.role) {
        throw { message: "Invaild_role" };
      }
    }

    // yield call(Api.callServer, api.validateCaptcha, { ...captchadata }, true);

    yield put(UserloginSuccess({ userInfo: result, auth }));
    const token = yield getAccessToken();
    const result2 = yield call(
      Api.callServer,
      api.getAccountByWalletId,
      { token, id: result.walletId },
      true
    );
    yield put(getMywalletsSuccess(result2));
    const result3 = yield call(
      Api.callServer,
      api.getMyProfile,
      { token, walletId: result.walletId, fiatWalletId: result.fiatWalletId },
      true
    );

    yield put(getMyprofileSuccess(result3));

    yield put(setConfirmcodeModalError(""));
    yield put(
      setDisplayedCurrencies({
        crpto: result3 && result3.crpto,
        fiat: result3 && result3.fiat,
      })
    );
    yield put(
      setMarketRatesCurrencies({
        crpto: result3 && result3.crpto,
        fiat: result3 && result3.fiat,
      })
    );
    yield put(
      setDashboardCurrencies({
        crpto: result3 && result3.crpto,
        fiat: result3 && result3.fiat,
      })
    );

    const AccountAndBalance = yield call(
      Api.callServer,
      api.getAccountAndBalanceByWalletId,
      { token, id: result.walletId },
      true
    );
    yield put(getAccountAndBalanceByWalletIdSuccess(AccountAndBalance));

    const PlatformToken = yield call(
      Api.callServer,
      api.getPlatformToken,
      { token },
      true
    );

    yield put(getPlatformTokensSuccess(PlatformToken));

    if (result.role == "Admin") {
      // navigation.push("/");
      let query = localStorage.getItem("pay");

      if (query != undefined && query != null && query != "") {
        navigation.push({
          pathname: `${query}`,
          state: { stopLoading: true },
        });
        localStorage.removeItem("pay");
      } else {
        navigation.push({
          pathname: "/",
          state: { stopLoading: true },
        });
      }
    } else {
      //  uncomment this code for authenticator
      if (result.authenticator) {
        yield put(setConfirmcodeModalSuccess(true));
      } else {
        yield put(setgoogleAuthLoading());
        const result4 = yield call(
          Api.callServer,
          api.setupGoogleAuth,
          { token },
          true
        );
        yield put(setgoogleAuthSuccess(result4));
        yield put(setAuthenticatorModalSuccess(true));
      }
    }

    //       //comment this code for authenticator start

    let query = localStorage.getItem("pay");

    if (query != undefined && query != null && query != "") {
      navigation.push({
        pathname: `${query}`,
        state: { stopLoading: true },
      });
      localStorage.removeItem("pay");
    } else {
      navigation.push({
        pathname: "/",
        state: { stopLoading: true },
      });
    }
    //comment this code for authenticator start
  } catch (errorPayload) {
    //console.log(errorPayload);
    if (errorPayload.message == "MOBILE_NOT_VERIFIED") {
      navigation.push("/otp", { mobile });
    } else if (errorPayload.message == "EMAIL_NOT_VERIFIED") {
      navigation.push("/verification");
    } else {
      erromsg = errorPayload.message;
    }

    yield put(loginFail(erromsg));
  } finally {
    yield put(loginTrigger(false));
  }
}

export function* usersignUp(api, { payload = {} }) {
  yield put(signUpTrigger(true));
  let errormsg = "";
  try {
    const {
      navigation,
      subName,
      email,
      name,
      password,
      loginType,
      sendVerification,
      mobile,
      exchangeId,
      role,
      lang,
    } = payload;

    let data = {
      name,
      password,
      loginType,
      sendVerification,
      exchangeId,
      subName,
      email,
      role,
      mobile,
      email,
      lang,
    };
    const result = yield call(Api.callServer, api.UsersignUp, data, true);
    yield put(UsersignupSuccess(result));

    navigation.push("/verification");
  } catch (errorPayload) {
    // if (errorPayload.message == "MOBILE_USED") {
    //   errormsg =
    //     "Mobile Number You have Entered is already registered. \nKindly sign in";
    // } else if (errorPayload.message == "mobile must be a phone number") {
    //   errormsg = "Incorrect phone number";
    // } else if (errorPayload.message == "NAME_CANNOT_START_WITH_NUMBER") {
    //   errormsg =
    //     "First name cannot start with number space and special character";
    // } else if (errorPayload.message == "SECOND_NAME_CANNOT_START_WITH_NUMBER") {
    //   errormsg =
    //     "Last name cannot start with number number space and special character";
    // } else if (errorPayload.message == "email must be an email") {
    //   errormsg = "Please enter valid email address";
    // } else if (errorPayload.message == "EMAIL_USED") {
    //   errormsg = "Email already used";
    // } else {
    errormsg = errorPayload.message;
    // }

    yield put(signupFail(errormsg));
  } finally {
    yield put(signUpTrigger(false));
  }
}

export function* userresendotp(api, { payload = {} }) {
  yield put(resendotpTrigger(true));
  try {
    const { mobile } = payload;
    const result = yield call(
      Api.callServer,
      api.UserResendOtp,
      { mobile },
      true
    );
  } catch (e) {
  } finally {
    yield put(resendotpTrigger(false));
  }
}

export function* requestEmailCode(api, { payload = {} }) {
  try {
    const { email, exchangeId, withdrawal, lang } = payload;
    if (withdrawal) {
      const token = yield getAccessToken();

      const result = yield call(
        Api.callServer,
        api.getwithdrawFA,
        {
          token,
          lang,
        },
        true
      );
    } else {
      yield call(
        Api.callServer,
        api.getVerifyEmailcode,
        {
          email,
          exchangeId,
          lang,
        },
        true
      );
    }
  } catch (e) {
  } finally {
  }
}

export function* ConfirmEmailCode(api, { payload = {} }) {
  try {
    yield put(loginTrigger(true));
    const token = yield getAccessToken();

    const { email, exchangeId, code, navigation, verifyToken } = payload;

    const result2 = yield call(
      Api.callServer,
      api.verifyGoogleAuth,
      {
        verifyToken,
        token,
      },
      true
    );
    if (result2 == true) {
      const result = yield call(
        Api.callServer,
        api.confirmEmailcode,
        {
          email,
          exchangeId,
          code,
          token,
        },
        true
      );
      yield put(setConfirmcodeModalSuccess(false));
      yield put(storeAuthSuccess(true));

      // navigation.push("/");
      let query = localStorage.getItem("pay");

      if (query != undefined && query != null && query != "") {
        navigation.push({
          pathname: `${query}`,
          state: { stopLoading: true },
        });
        localStorage.removeItem("pay");
      } else {
        navigation.push({
          pathname: "/",
          state: { stopLoading: true },
        });
      }
    } else {
      yield put(setConfirmcodeModalError("Invalid Authentication Code"));
    }
  } catch (e) {
    //console.log(e);
    yield put(setConfirmcodeModalError(e.message));
  } finally {
    yield put(loginTrigger(false));
  }
}

export function* forgotPassword(api, { payload = {} }) {
  // console.log("Forgot password saga called");
  yield put(forgotPasswordTrigger(true));
  let erromsg = "";
  try {
    const { loginType, navigation, email, mobile, exchangeId, lang } = payload;
    let data = {
      loginType,
      exchangeId,
      lang,
    };

    if (loginType == "mobile") data.mobile = mobile;
    else data.email = email;
    //console.log(data);
    const result = yield call(
      Api.callServer,
      api.UserForgotpassword,
      {
        data,
      },
      true
    );
    //console.log("Result from forgot password popup", result);
    yield put(forgotPasswordSuccess(result));
    if (loginType == "mobile") navigation.push("/otp", { mobile, reset: true });
  } catch (e) {
    if (e.message == "limitexceed") {
      erromsg = "Limit exceed,try after some time ";
    } else {
      erromsg = e.message;
    }
    yield put(forgotPasswordFail(erromsg));
  }
}

// export function* changePassword(api, { payload = {} }) {
//   yield put(changePasswordTrigger(true));
//   try {
//     const { OldPassword, password, history } = payload;
//     const token = yield getAccessToken();
//     const result = yield call(
//       Api.callServer,
//       api.changepassworduser,
//       { OldPassword, password, token },
//       true
//     );
//     yield put(changePasswordSuccess(result));
//     alert("Password Changed Successfully");
//     history.push("/");
//   } catch (e) {

//     yield put(changePasswordFail(e.message));
//   } finally {
//     yield put(changePasswordTrigger(false));
//   }
// }

export function* usersetpasswordmobile(api, { payload = {} }) {
  yield put(otpverifyTrigger(true));
  try {
    const { mobile, newPassword, otp, navigation } = payload;
    const result = yield call(
      Api.callServer,
      api.UserSetNewPasswordMobile,
      { mobile, newPassword, otp },
      true
    );
    navigation.push("/signin");
  } catch (error) {
    // showToast(error.message);
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* usersetpasswordmob(api, { payload = {} }) {
  // yield put(otpverifyTrigger(true));
  try {
    const { mobile, newPassword } = payload;

    const result = yield call(
      Api.callServer,
      api.UserSetNewPasswordMob,
      { mobile, newPassword },
      true
    );
    navigation.push("/signin");
  } catch (error) {
    // showToast(error.message);
  } finally {
    yield put(otpverifyTrigger(false));
  }
}
export function* logout(api, { payload = {} }) {
  try {
    yield put(logoutSuccess());
    yield put(clearcacheSuccess());
    yield put(clearBrnachReduxSuccuss());
    yield put(clearFiatReduxSuccuss());
    yield put(clearIdoReduxSuccuss());
    yield put(clearPaymentLinkReduxSuccuss());
    yield put(clearappcacheSuccess());
    payload.history.push("/");
  } catch (error) {
    // yield put(resendVerificationFail(error));
  } finally {
    yield put(loginTrigger(false));
  }
}

export function* userVerifyemail(api, { payload = {} }) {
  yield put(otpverifyTrigger(true));
  try {
    const { email, exchangeId } = payload;
    const result = yield call(
      Api.callServer,
      api.UserVerifyEmail,
      { email, exchangeId },
      true
    );
  } catch (e) {
  } finally {
    yield put(otpverifyTrigger(false));
  }
}
export function* VerificationOtp(api, { payload = {} }) {
  let erromsg = "";
  yield put(otpverifyTrigger(true));
  try {
    const { mobile, otp, exchangeId, navigation } = payload;

    const result = yield call(
      Api.callServer,
      api.UserVerifyOTP,
      { mobile, otp, exchangeId },
      true
    );
    yield put(OTPSuccess(true));
    navigation.push("/signin");
  } catch (error) {
    if (error.message === "OtpNotMatched") {
      erromsg = "OTP not matched";
      alert(erromsg);
    } else {
      alert(error.message);
    }
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* userConfirmEmail(api, { payload = {} }) {
  yield put(true);
  try {
    const { email, exchangeId, token } = payload;
    const result = yield call(
      Api.callServer,
      api.UserConfirmEmail,
      { email, exchangeId, token },
      true
    );
  } catch (e) {
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* createCryptowWllet(api, { payload = {} }) {
  yield put(createCryptoWalletTrigger(true));
  try {
    const { navigation, name } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.UserCreateCryptoWalle,
      {
        name,
        token,
      },
      true
    );
    yield put(createCryptoWalletSuccess(result));
  } catch (e) {
    yield put(createCryptoWalletFail(e));
  } finally {
    yield put(createCryptoWalletTrigger(false));
  }
}

export function* createFiatWllet(api, { payload = {} }) {
  yield put(createFiatWalletTrigger(true));
  try {
    const { navigation, name } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.UserCreateFiatWallet,
      {
        name,
        token,
      },
      true
    );
    yield put(createFiatWalletSuccess(result));
    // const result2 = yield call(Api.callServer, api.getMyPost, { token }, true);
    //  navigation.push("/postfeed");

    // yield put(createCryptoWalletSuccess(result));
  } catch (e) {
    yield put(createFiatWalletFail(e));
  } finally {
    yield put(createFiatWalletTrigger(false));
  }
}

export function* managerignIn(api, { payload = {} }) {
  yield put(loginTrigger(true));
  let erromsg = "";
  try {
    const { navigation, email, password, loginType, mobile } = payload;
    let data = {
      password,
      loginType,
    };
    if (loginType == "mobile") data.mobile = mobile;
    else data.email = email;

    const result = yield call(Api.callServer, api.ManagersignIn, data, true);
    yield put(UserloginSuccess(result));
    // const token = yield getAccessToken();
    // const result2 = yield call(Api.callServer, api.getUser, { token }, true);
    // yield put(profileSuccess(result2));
  } catch (errorPayload) {
    if (errorPayload.message === "Invalidpassword") {
      erromsg = "Invaild Password ";
    } else if (errorPayload.message === "mobilenotexist") {
      erromsg = "Mobile does not exist";
    } else if (errorPayload.message === "usernotexist") {
      erromsg = "User does not exist";
    } else if (errorPayload.message == "mobileNotVerified") {
      erromsg = "Your mobile not verified";
    } else {
      erromsg = errorPayload.message;
    }
    yield put(loginFail(erromsg));
  } finally {
    yield put(loginTrigger(false));
  }
}

export function* adminsignUp(api, { payload = {} }) {
  yield put(signUpTrigger(true));
  let errormsg = "";
  try {
    const { navigation, email, name, password, mobile } = payload;

    let data = {
      name,
      password,
      email,
      mobile,
    };

    const result = yield call(
      Api.callServer,
      api.RegisterPlatformAdmin,
      data,
      true
    );
    yield put(UsersignupSuccess(result));
  } catch (errorPayload) {
    if (errorPayload.message == "MobileUsed") {
      errormsg =
        "The Mobile Number You have Entered is already in use. \nKindly sign in";
    } else {
      errormsg = errorPayload.message;
    }

    yield put(signupFail(errormsg));
  } finally {
    yield put(signUpTrigger(false));
  }
}

export function* addSuperManager(api, { payload = {} }) {
  yield put(signUpTrigger(true));
  let errormsg = "";
  try {
    const { navigation, email, name, loginType, mobile, managerName, role } =
      payload;

    let data = {
      name,
      loginType,
      managerName,
      role,
    };

    if (loginType == "mobile") data.mobile = mobile;
    else data.email = email;
    const result = yield call(Api.callServer, api.AddSuperManager, data, true);
    yield put(addSuperManagerSuccess(result));
  } catch (errorPayload) {
    if (errorPayload.message == "MobileUsed") {
      errormsg =
        "The Mobile Number You have Entered is already in use. \nKindly sign in";
    } else {
      errormsg = errorPayload.message;
    }

    yield put(addSuperManagerFail(errormsg));
  } finally {
    yield put(signUpTrigger(false));
  }
}

export function* ManagerReqPasswordReset(api, { payload = {} }) {
  yield put(forgotPasswordTrigger(true));
  let erromsg = "";
  try {
    const { loginType, navigation, email, mobile } = payload;
    let data = {
      loginType,
    };
    if (loginType == "mobile") data.mobile = mobile;
    else data.email = email;
    const result = yield call(
      Api.callServer,
      api.ManagerForgotpassword,
      data,
      true
    );
    yield put(forgotPasswordSuccess(result));
    if (loginType == "mobile") navigation.push("/otp", { mobile, reset: true });
  } catch (e) {
    if (e.message == "limitexceed") {
      erromsg = "Limit exceed,try after some time ";
    }
    yield put(forgotPasswordFail(erromsg));
  } finally {
    yield put(forgotPasswordTrigger(false));
  }
}

export function* managersetpasswordmobile(api, { payload = {} }) {
  yield put(otpverifyTrigger(true));
  try {
    const { mobile, newPassword, otp, navigation } = payload;

    const result = yield call(
      Api.callServer,
      api.ManagerSetNewPasswordMobile,
      { mobile, newPassword, otp },
      true
    );
    navigation.push("/signin");
  } catch (error) {
    // showToast(error.message);
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* managersetpasswordmob(api, { payload = {} }) {
  // yield put(otpverifyTrigger(true));
  try {
    const { mobile, newPassword } = payload;

    const result = yield call(
      Api.callServer,
      api.ManagerSetNewPasswordMob,
      { mobile, newPassword },
      true
    );
    navigation.push("/signin");
  } catch (error) {
    // showToast(error.message);
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* mangresendotp(api, { payload = {} }) {
  yield put(resendotpTrigger(true));
  try {
    const { mobile } = payload;
    const result = yield call(
      Api.callServer,
      api.ManagerResendOtp,
      { mobile },
      true
    );
  } catch (e) {
  } finally {
    yield put(resendotpTrigger(false));
  }
}

export function* managerVerifyOtp(api, { payload = {} }) {
  let erromsg = "";
  yield put(otpverifyTrigger(true));
  try {
    const { mobile, otp } = payload;

    const result = yield call(
      Api.callServer,
      api.ManagerVerifyOTP,
      { mobile, otp },
      true
    );
    yield put(OTPSuccess(true));
  } catch (error) {
    if (error.message === "OtpNotMatched") {
      erromsg = "OTP not matched";
      alert(erromsg);
    } else {
      alert(error.message);
    }
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* clearErrorMessage() {
  yield put(clearErrorMessageSuccess());
}
export function* clearResetPopupError() {
  // //console.log('reset saga called')
  yield put(clearResetPopupErrorSuccess());
}

export function* getAllBusinessRequest(api, { payload = {} }) {
  yield put(BusinessRequestTrigger(true));
  try {
    const { status } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getBusinessRequest,
      { token, status },
      true
    );
    yield put(getBusinessUserRequestSuccess(result));
  } catch (e) {
  } finally {
    yield put(BusinessRequestTrigger(false));
  }
}

export function* businessForm(api, { payload = {} }) {
  yield put(createBusinessFormTrigger(true));
  try {
    const {
      companyAddress,
      companyContact,
      companyEmail,
      companyName,
      contactPerson,
      imageserver,
    } = payload;
    const token = yield getAccessToken();
    const formData = new FormData();
    //  for (let i = 0; i < imageserver.document.length; i++) {
    formData.append("documents", imageserver);
    //  }
    formData.append("companyAddress", companyAddress);
    formData.append("companyContact", companyContact);
    formData.append("companyEmail", companyEmail);
    formData.append("companyName", companyName);
    formData.append("contactPerson", contactPerson);
    const result = yield call(
      Api.callServer,
      api.businessForm,
      {
        formData,
        token,
      },
      true
    );
    let fiatWalletId = yield getfiatWalletId();
    let walletId = yield getWalletId();
    const result3 = yield call(
      Api.callServer,
      api.getMyProfile,
      { token, walletId, fiatWalletId },
      true
    );
    yield put(getMyprofileSuccess(result3));
    yield put(createBusinessFormSuccess(result));
  } catch (e) {
    yield put(createBusinessFormFail(e));
  } finally {
    yield put(createBusinessFormTrigger(false));
  }
}

export function* giveApprovals(api, { payload = {} }) {
  yield put(giveApprovalTrigger(true));
  try {
    const { navigation, status, id } = payload || {};
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.BusinessRequestApproval,
      {
        token,

        id,
        status,
      },
      true
    );
    yield put(giveApprovalSuccess(result));
  } catch (e) {
    yield put(giveApprovalFail(e));
  } finally {
    yield put(giveApprovalTrigger(false));
  }
}

export function* storeuserDashboardList(api, { payload = {} }) {
  yield put(giveApprovalTrigger(true));
  try {
    const { navigation, status, id } = payload || {};

    yield put(userDashboardListSuccess(result));
  } catch (e) {
  } finally {
  }
}

export function* getMyprofile(api, { payload = {} }) {
  yield put(updateProfileImageTrigger(true));
  try {
    const token = yield getAccessToken();

    let fiatWalletId = yield getfiatWalletId();
    let walletId = yield getWalletId();
    const result3 = yield call(
      Api.callServer,
      api.getMyProfile,
      { token, walletId, fiatWalletId },
      true
    );
    yield put(getMyprofileSuccess(result3));
  } catch (errorPayload) {
    yield put(profileImageUploadFail(errorPayload));
  } finally {
    yield put(updateProfileImageTrigger(false));
  }
}

// export function* removeImage(api , { payload ={} }){
//   console.log('removeIamge called')
//   yield put(removeProfileImageTrigger(true));
//   try{
//     const { data } = payload;
//     const token = yield getAccessToken();
//     const result = yield call(
//       Api.callServer ,
//       api.removeProfileImage ,
//       {
//         token ,
//       },
//       true
//     );
//     console.log('result called' , result)
//       yield put(profileImageRemoveSuccess());
//   }
//   catch(errorpayload){
//     yield put(removeProfileImageFailure(errorpayload));
//   }

// }

export function* removeImage(api, { payload = {} }) {
  // yield put(updateProfileImageTrigger(true));
  //console.log("removeIamge called");
  yield put(removeProfileImageTrigger(true));
  try {
    const { data } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.removeprofileImage,
      {
        token,
      },
      true
    );
    //  yield put(profileImageRemoveSuccess());

    // yield put(profileImageUploadSuccess(result));
    let fiatWalletId = yield getfiatWalletId();
    let walletId = yield getWalletId();
    const result3 = yield call(
      Api.callServer,
      api.getMyProfile,
      { token, walletId, fiatWalletId },
      true
    );
    yield put(getMyprofileSuccess(result3));
  } catch (errorPayload) {
    yield put(profileImageUploadFail(errorPayload));
    //  yield put(removeProfileImageFailure(true));
  } finally {
    //  yield put(updateProfileImageTrigger(false));
  }
}

export function* uploadProfileImage(api, { payload = {} }) {
  yield put(updateProfileImageTrigger(true));
  try {
    const { data } = payload;
    //console.log("upload profile image saga", Array.from(data));
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.ProfileImageUpload,
      {
        data,
        token,
      },
      true
    );
    let fiatWalletId = yield getfiatWalletId();
    let walletId = yield getWalletId();
    const result3 = yield call(
      Api.callServer,
      api.getMyProfile,
      { token, walletId, fiatWalletId },
      true
    );
    yield put(getMyprofileSuccess(result3));
    yield put(profileImageUploadSuccess(result));
  } catch (errorPayload) {
    yield put(profileImageUploadFail(errorPayload));
  } finally {
    yield put(updateProfileImageTrigger(false));
  }
}
export function* updateProfile(api, { payload = {} }) {
  yield put(updateProfileTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.UpdateProfile,
      {
        payload,
        token,
      },
      true
    );
    yield put(updateProfileSuccess(result));
  } catch (errorPayload) {
    yield put(updateProfileFail(errorPayload));
  } finally {
    yield put(updateProfileTrigger(false));
  }
}

/* Change Password Saga */
export function* changePasswordAsync(api, { payload }) {
  yield put(changePasswordStart());
  const token = yield getAccessToken();
  try {
    const response = yield call(
      Api.callServer,
      api.ChangePasswordApiCall,
      { ...payload },
      true
    );

    yield put(changePasswordSuccess(response));
  } catch (error) {
    yield put(changePasswordFailure(error.message));
  }
}

/* Getting All Address Booking */
export function* getAllAddressBookingsAsync(api, { payload }) {
  yield put(getAddressBookingsTrigger());
  const token = yield getAccessToken();
  try {
    const response = yield call(Api.callServer, api.getAllAddressBooks, {
      ...payload,
      token,
    });
    yield put(getAddressBookingsSuccess(response));
  } catch (error) {
    yield put(getAddressBookingsFailure(error.message));
  }
}

/* Add Address Bookings */
export function* addAddressBookingAsync(api, { payload }) {
  yield put(addAddressBookingTrigger());
  const token = yield getAccessToken();
  const {
    Name,
    email,
    phone,
    address,
    accountAddress,
    coinId,
    image,
    memo,
    whitelisted,
  } = payload;
  try {
    const response = yield call(Api.callServer, api.addAddressBook, {
      Name,
      email,
      phone,
      address,
      accountAddress,
      coinId,
      image,
      memo,
      whitelisted,
      token,
    });
    yield put(addAddressBookingSuccess());
  } catch (error) {
    yield put(addAddressBookingFailure(error.message));
  }
}

/* Edit Address Booking */
export function* editAddressBookingAsync(api, { payload }) {
  yield put(editAddressBookingTrigger());
  const token = yield getAccessToken();
  const {
    Name,
    email,
    phone,
    address,
    accountAddress,
    setUniversal,
    coinId,
    image,
    memo,
    whitelisted,
    id,
  } = payload;
  try {
    const response = yield call(Api.callServer, api.editAddressBook, {
      Name,
      email,
      phone,
      address,
      accountAddress,
      setUniversal,
      coinId,
      image,
      memo,
      whitelisted,
      id,
      token,
    });
    yield put(editAddressBookingSuccess());
  } catch (error) {
    yield put(editAddressBookingFailure(error.message));
  }
}

/* Delete Address Booking */
export function* deleteAddressBookingAsync(api, { payload }) {
  yield put(deleteAddressBookingTrigger());
  const token = yield getAccessToken();
  const { id } = payload;
  try {
    const response = yield call(Api.callServer, api.deleteAddressBook, {
      id,
      token,
    });
    yield put(deleteAddressBookingSuccess());
  } catch (error) {
    yield put(deleteAddressBookingFailure(error.message));
  }
}

export function* storeMyBalance(api, { payload }) {
  try {
    yield put(storeMyBalanceSuccess(payload));
  } catch (error) {
    yield put(storeMyBalanceFailure(error.message));
  }
}

export function* addNewCoin(api, { payload = {} }) {
  try {
    const { coinId } = payload;
    let walletId = yield getWalletId();
    let data = {
      coinId: coinId,
      walletId: walletId,
    };
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.adduserCoin,
      {
        ...data,
        token,
      },
      true
    );
  } catch (e) {}
}

export function* storePreferredCurrency(api, { payload }) {
  yield put(storePreferredCurrencySuccess(payload));
}

export function* getNfcCardSaga(api, {payload}) {
  yield put(allNfcCardLoadingStart());
  try{
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.Getallnfc,
      { details: payload,
        token 
      },
      true
    );
    yield put(allNfcCardSuccess(result));
    console.log("Api result", result)
  } catch (e) {
    yield put(allNfcCardFail(e.message))
  }
}

export function* createCardSaga(api, { payload = {} }) {
  try {
    const token = yield getAccessToken();
    const walletID = yield getWalletId();

    const { openSuccess, skip, limit } = payload;
    delete payload["openSuccess"];
    delete payload["skip"];
    delete payload["limit"];

    console.log("payload", payload);

    yield put(createNfcCardLoadingStart());

    const result = yield call(
      Api.callServer,
      api.CreateCard,
      { ...payload, token, walletId: walletID },
      true
    );

    // const result2 = yield call(Api.callServer, api.Getallnfc, { token }, true);


    yield put(createNfcCardSuccess(result));
    // yield call(getNfcCardSaga , api , {payload :{ token }})
    openSuccess();
    yield call(listUserNfcCardSage , api , {payload :{ token , skip , limit }})

  } catch (error) {
    console.log(error, 'iinn');
    const { message = '' } = error;
    yield put(createNfcCardFailed(message));
  }
}

export function* listUserNfcCardSage(api, { payload }) {
yield put(listNfcCardLoadingStart());
let token = yield getAccessToken();
try {
  const result = yield call(
    Api.callServer,
    api.GetUserNfc,
    {
      details: payload,
      token,
    },
    true
  );
  yield put(listNfcCardSuccess(result));
} catch (e) {
  yield put(listNfcCardFailed(e.message))
}
}