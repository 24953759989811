import BigCoinCard from "@components/big-coin/BigCoinCard";
import Branch from "@components/branch/Branch";
import CurrencyTab from "src/components/currency-tab/CurrencyTab.js";
import SearchBox from "@components/search-box/SearchBox.js";
import CoinTable from "@components/coin-table/CoinTable.js";
import AddCoinModal from "@components/add-coin-modal/AddCoinModal.js";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "@images/search.svg";
import "@components/navbar/NavBarStyles.scss";
import { connect } from "react-redux";
import { Row, Col, Tab } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/main-dashboard/MainDashboardStyles.scss";
import FiatTable from "../fiat-table/FiatTable";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { setDashboardCurrencies } from "@store/userredux";
const Binance = require("binance-api-node").default;
import moment from "moment";
import { getESymbol } from "src/utils/Currencies";

function MainDashboard(props) {
  let {
    getplatformTokensinfo,
    myaccountandbalance,
    withdramodal,
    setwithdrawModal,
    coinItem,
    setcoinItem,
    exchangemodal,
    setExchangeModal,
    plaformcurrencies,
    getFiatBalanceinfo,
    setFiatDepositModal,
    fiatDepositModal,
    setDepositModal,
    depositModal,
    displayedCurrencies,
    setFiatWithdrawModal,
    role,
    sethideZero,
    hideZeroBalance,
    addCurrency,
    dashboardDisplayCurrencies,
    storeEstimated,
    PreferredCurrency,
    myEstimatedBal,
    setFiatrate,
    fiatrate,
    getBranchpayment,
    BranchpaymentList,
    gpt,
    addNewCoin,
  } = props;
  const [bnbdata, setbnbdata] = useState("");
  const [btcdata, setbtcdata] = useState("");
  const [ethdata, setethdata] = useState("");
  const [bnbpdata, setbnbpdata] = useState("");
  const [btcpdata, setbtcpdata] = useState("");
  const [ethpdata, setethpdata] = useState("");
  const [busddata, setbusddata] = useState("");
  const [busdpdata, setbusdpdata] = useState("");
  const [trxdata, settrxdata] = useState("");
  const [trxpdata, settrxpdata] = useState("");
  const [trycdata, settrycdata] = useState("");
  const [trycpdata, settrycpdata] = useState("");
  let [tableno, settableno] = useState("first");
  const [searchvalue, setSearchValue] = useState("");
  const [market, setmarket] = useState([]);
  const [chartsdata, setcharts] = useState([]);
  const [min, setMin] = useState([]);
  const [max, setMax] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [circularLoader, setCircularLoader] = useState(true);

  const client = Binance();
  useEffect(() => {
    axios
      .get(
        `https://api.currencyapi.com/v3/latest?apikey=${process.env.REACT_APP_FIATKEY}`
      )
      .then((res) => {
        setFiatrate(res.data && res.data.data);
      });

    if (role === "Business") {
      getBranchpayment();
    }

    filterTokens();
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    let prefSymbol = "usdt";
    const msg = {
      method: "SUBSCRIBE",
      params: [
        `eth${prefSymbol}@aggTrade`,
        `btc${prefSymbol}@aggTrade`,
        `bnb${prefSymbol}@aggTrade`,
        `busd${prefSymbol}@aggTrade`,
        `eth${prefSymbol}@ticker`,
        `btc${prefSymbol}@ticker`,
        `bnb${prefSymbol}@ticker`,
        `busd${prefSymbol}@ticker`,
        `trx${prefSymbol}@ticker`,
        `trx${prefSymbol}@aggTrade`,
        `${prefSymbol}try@ticker`,
        `${prefSymbol}try@aggTrade`,
      ],
      id: 1,
    };

    const msg2 = {
      method: `UNSUBSCRIBE`,
      params: [
        `eth${prefSymbol}@aggTrade`,
        `btc${prefSymbol}@aggTrade`,
        `bnb${prefSymbol}@aggTrade`,
        `busd${prefSymbol}@aggTrade`,
        `trx${prefSymbol}@aggTrade`,
        `eth${prefSymbol}@ticker`,
        `btc${prefSymbol}@ticker`,
        `bnb${prefSymbol}@ticker`,
        `busd${prefSymbol}@ticker`,
        `trx${prefSymbol}@ticker`,
        `${prefSymbol}try@ticker`,
        `${prefSymbol}try@aggTrade`,
      ],
      id: 1,
    };
    ws.onopen = () => {
      ws.send(JSON.stringify(msg));
      setTimeout(() => {
        ws.send(JSON.stringify(msg2));
        setTimeout(() => {
          setCircularLoader(false);
        }, 500);
      }, 1000);
    };
    let cSymbol = "USDT";
    ws.onmessage = (e) => {
      let newdata = JSON.parse(e.data);
      if (!_.isUndefined(e.data)) {
        if (newdata.e == "24hrTicker") {
          if (newdata.s === `BNB${cSymbol}`) setbnbpdata(newdata);
          if (newdata.s === `ETH${cSymbol}`) setethpdata(newdata);
          if (newdata.s === `BTC${cSymbol}`) setbtcpdata(newdata);
          if (newdata.s === `BUSD${cSymbol}`) setbusdpdata(newdata);
          if (newdata.s === `TRX${cSymbol}`) settrxpdata(newdata);
          if (newdata.s === `${cSymbol}TRY`) settrycpdata(newdata);
        } else {
          if (newdata.s === `BNB${cSymbol}`) setbnbdata(newdata);
          if (newdata.s === `ETH${cSymbol}`) setethdata(newdata);
          if (newdata.s === `BTC${cSymbol}`) setbtcdata(newdata);
          if (newdata.s === `BUSD${cSymbol}`) setbusddata(newdata);
          if (newdata.s === `TRX${cSymbol}`) settrxdata(newdata);
          if (newdata.s === `${cSymbol}TRY`) settrycdata(newdata);
        }
      }
    };
  }, []);

  useEffect(() => {
    filterTokens();
  }, [dashboardDisplayCurrencies.crpto, searchvalue, getplatformTokensinfo]);

  const [Showaddcointmodal, setShowaddcoinmodal] = useState(false);
  const [value, setvalue] = useState("");

  const filterTokens = () => {
    if (
      dashboardDisplayCurrencies &&
      dashboardDisplayCurrencies.crpto &&
      dashboardDisplayCurrencies.crpto.length !== 0
    ) {
      let filteredTkn = getplatformTokensinfo.map((item) =>
        _.findIndex(
          [...dashboardDisplayCurrencies.crpto].sort(),
          (chr) => chr === item.id
        )
      );
      if (filteredTkn.includes(-1)) {
        filteredTkn = filteredTkn.map((itm) =>
          itm === 0 || itm === -1 ? itm : itm + 1
        );
      }

      let filTKN = [];
      let dataForChecking = [];

      for (let i of [...dashboardDisplayCurrencies.crpto].sort()) {
        let data = getplatformTokensinfo.filter((itm) => itm.id === i)[0];
        let dataWithItem = {
          ...data,
          itemIndex: getplatformTokensinfo.indexOf(data),
        };
        if (data) dataForChecking.push(data);
        if (
          dataWithItem.tokenName
            .toLowerCase()
            .includes(searchvalue.toLowerCase()) &&
          searchvalue != ""
        )
          filTKN.push(dataWithItem);
        if (searchvalue == "") filTKN.push(dataWithItem);
      }
      setFilteredTokens(filTKN);
      dataForChecking &&
        dataForChecking.map((i) => {
          if (i && i.exchangeSymbol !== "CYBOR") {
            getMarketdata(
              i.exchangeSymbol,
              "USDT",
              getplatformTokensinfo.indexOf(i)
            );
          }
        });
    }
  };

  const getMarketdata = (exchangeSymbol, Currency, index) => {
    try {
      let CurrencyT = Currency;
      if (exchangeSymbol == "USDT") {
        client
          .dailyStats({
            symbol: `BUSD${CurrencyT}`,
          })
          .then((time) => {
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `BUSD${CurrencyT}`,
            interval: "1d",
            limit: 20,
            // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
            // endTime: moment(new Date()).valueOf(),
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
          })
          .catch((er) => {
            console.log(er);
          });
      } else if (exchangeSymbol == "TRYC") {
        client
          .dailyStats({
            symbol: `${CurrencyT}TRY`,
          })
          .then((time) => {
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `${CurrencyT}TRY`,
            interval: "1d",
            limit: 20,
            // startTime: moment(moment(new Date()).subtract(7, "days")).valueOf(),
            // endTime: moment(new Date()).valueOf(),
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
          })
          .catch((er) => {
            console.log(er);
          });
      } else {
        client
          .dailyStats({
            symbol: `${exchangeSymbol}${CurrencyT}`,
          })
          .then((time) => {
            market[index] = time;
            setmarket(() => [...market]);
          })
          .catch((er) => {});
        client
          .candles({
            symbol: `${exchangeSymbol}${CurrencyT}`,
            interval: "1d",
            limit: 20,
          })
          .then((time) => {
            let charts = [];
            let mins = time && time[0].close;
            let maxs = time && time[0].close;
            time &&
              time.map((point) => {
                if (point.close < min) {
                  mins = point.close;
                }
                if (point.close > max) {
                  maxs = point.close;
                }
                charts.push({
                  x: moment(point.closeTime).format("MM/DD/YYYY"),
                  y: point.close,
                });
              });
            min[index] = mins;
            setMin(() => [...min]);
            max[index] = maxs;
            setMax(() => [...max]);
            chartsdata[index] = charts;
            setcharts(() => [...chartsdata]);
          })
          .catch((er) => {});
      }
    } catch (err) {}
  };

  const getFiatBal = (cur) => {
    let obj =
      getFiatBalanceinfo &&
      getFiatBalanceinfo.filter((item) => item.currencyId == cur);
    let reelbal = _.get(obj, "[0].balance", 0);
    return reelbal;
  };

  const getBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };
  const getAmount = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].amount", 0);
    return reelbal;
  };
  const getLockBal = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let reelbal = _.get(obj, "[0].lockBalance", 0);
    let decimals = _.get(obj, "[0].coin.decimals", 0);
    return BigNumber(reelbal).dividedBy(10 ** decimals);
  };

  const getAdd = (cur) => {
    let obj = myaccountandbalance.filter((item) => item.coin.id == cur);
    let address = _.get(obj, "[0].address", " ");

    return address;
  };

  const sortUpCoins = () => {
    console.log("sorup called");
    setFilteredTokens(
      filteredTokens.sort(function (a, b) {
        return a.tokenName < b.tokenName ? -1 : 1;
      })
    );
    console.log(filteredTokens);
  };
  const sortDownCoins = () => {
    console.log("sortdowncalled");
    let newArray = filteredTokens.sort(function (a, b) {
      return a.tokenName < b.tokenName ? 1 : -1;
    });
    setFilteredTokens(newArray);
  };

  const { t } = useTranslation();

  return (
    <div className="MainDashboard">
      <BigCoinCard
        storeEstimated={storeEstimated}
        mywalletsinfo={myaccountandbalance}
        bnbdata={bnbdata}
        bnbpdata={bnbpdata}
        setcoinItem={setcoinItem}
        ethdata={ethdata}
        myEstimatedBal={myEstimatedBal}
        ethpdata={ethpdata}
        btcdata={btcdata}
        btcpdata={btcpdata}
        busddata={busddata}
        circularLoader={circularLoader}
        // setCircularLoader={setCircularLoader}
        busdpdata={busdpdata}
        trycpdata={trycpdata}
        trycdata={trycdata}
        getFiatBal={getFiatBal}
        trxdata={trxdata}
        fiatrate={fiatrate}
        getBal={getBal}
        trxpdata={trxpdata}
        sethideZero={sethideZero}
        hideZeroBalance={hideZeroBalance}
        setwithdrawModal={setwithdrawModal}
        setExchangeModal={setExchangeModal}
        setShowDeposit={setDepositModal}
        plaformcurrencies={plaformcurrencies}
        getplatformTokensinfo={getplatformTokensinfo}
        displayedCurrencies={displayedCurrencies}
        setFiatDepositModal={setFiatDepositModal}
        role={role}
        getAmount={getAmount}
        PreferredCurrency={PreferredCurrency}
      />
      {role == "Business" &&
        BranchpaymentList &&
        BranchpaymentList.length != 0 && (
          <div className="topspace">
            <Branch
              BranchpaymentList={BranchpaymentList}
              mywalletsinfo={myaccountandbalance}
              bnbdata={bnbdata}
              bnbpdata={bnbpdata}
              setcoinItem={setcoinItem}
              ethdata={ethdata}
              ethpdata={ethpdata}
              btcdata={btcdata}
              btcpdata={btcpdata}
              busddata={busddata}
              busdpdata={busdpdata}
              getFiatBal={getFiatBal}
              trxdata={trxdata}
              getBal={getBal}
              trxpdata={trxpdata}
              setwithdrawModal={setwithdrawModal}
              setExchangeModal={setExchangeModal}
              setShowDeposit={setDepositModal}
              plaformcurrencies={plaformcurrencies}
              getplatformTokensinfo={getplatformTokensinfo}
              displayedCurrencies={displayedCurrencies}
              setFiatDepositModal={setFiatDepositModal}
              PreferredCurrency={PreferredCurrency}
            />
          </div>
        )}
      <div className="outercointainer d-lg-block d-none">
        <div className="topspace">
          <SearchBox value={searchvalue} setvalues={setSearchValue} />
        </div>
        <div className="buttontopspace">
          <CustomButton
            type="submit"
            name={t("AddCoin")}
            filled={true}
            primary={false}
            className="buttonmain"
            onClick={() => {
              setShowaddcoinmodal(true);
            }}
          />
        </div>
      </div>
      <AddCoinModal
        getAdd={getAdd}
        setcoinItem={setcoinItem}
        value={value}
        coinItem={coinItem}
        setvalue={setvalue}
        getplatformTokensinfo={getplatformTokensinfo}
        plaformcurrencies={plaformcurrencies}
        getBal={getBal}
        addNewCoin={addNewCoin}
        setShowAddcoin={setShowaddcoinmodal}
        show1={Showaddcointmodal}
        setCoinOfState={addCurrency}
        displayedCurrencies={dashboardDisplayCurrencies}
      />
      <div className="topspace align-left">
        <span className="searchMobileFloatright d-block d-lg-none">
          <SearchBox value={searchvalue} setvalues={setSearchValue} />
        </span>
        <CurrencyTab
          page={"currency"}
          className="currencyarea"
          changeTab={(e) => {
            if (e == "second") {
              settableno(e);
            }

            if (e == "first") {
              settableno(e);
            }
          }}
          tableno={tableno}
        >
          <div className="topspace" />
          <Tab.Pane eventKey="first">
            <CoinTable
              hideZeroBalance={hideZeroBalance}
              getplatformTokensinfo={getplatformTokensinfo}
              filteredTokens={filteredTokens}
              setFilteredTokens={setFilteredTokens}
              gpt={gpt}
              getBal={getBal}
              getAdd={getAdd}
              bnbdata={bnbdata}
              bnbpdata={bnbpdata}
              ethdata={ethdata}
              getLockBal={getLockBal}
              ethpdata={ethpdata}
              fiatrate={fiatrate}
              btcdata={btcdata}
              btcpdata={btcpdata}
              trxdata={trxdata}
              trxpdata={trxpdata}
              busddata={busddata}
              withdramodal={withdramodal}
              setwithdrawModal={(e) => setwithdrawModal(e)}
              busdpdata={busdpdata}
              setcoinItem={setcoinItem}
              coinItem={coinItem}
              exchangemodal={exchangemodal}
              depositModal={depositModal}
              setDepositModal={setDepositModal}
              setExchangeModal={(e) => setExchangeModal(e)}
              setFiatDepositModal={setFiatDepositModal}
              setFiatWithdrawModal={setFiatWithdrawModal}
              searchvalue={searchvalue}
              role={role}
              max={max}
              min={min}
              chartData={chartsdata}
              percentChanges={market}
              trycpdata={trycpdata}
              trycdata={trycdata}
              PreferredCurrency={PreferredCurrency}
              sortUpCoins={(e) => sortUpCoins(e)}
              sortDownCoins={sortDownCoins}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <FiatTable
              hideZeroBalance={hideZeroBalance}
              plaformcurrencies={plaformcurrencies}
              getBal={getFiatBal}
              setFiatDepositModal={setFiatDepositModal}
              fiatDepositModal={fiatDepositModal}
              setExchangeModal={(e) => setExchangeModal(e)}
              searchvalue={searchvalue}
              role={role}
              setcoinItem={setcoinItem}
              coinItem={coinItem}
            />
          </Tab.Pane>
        </CurrencyTab>
        <div className="mobileaddbtn d-block d-lg-none">
          <CustomButton
            type="submit"
            name={t("AddCoin")}
            filled={true}
            primary={false}
            className="buttonmain"
            onClick={() => {
              setShowaddcoinmodal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({
  wallet = {},
  app = {},
  user = {},
  fiat = {},
  branchmanagementredux,
}) => {
  const mywalletsinfo = _.get(wallet, "mywalletsinfo", []);
  const withdramodal = _.get(app, "withdramodal", false);
  const exchangemodal = _.get(app, "exchangemodal", false);
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  );
  const BranchpaymentList = _.get(
    branchmanagementredux,
    "BranchpaymentList",
    []
  );
  const fiatDepositModal = _.get(app, "FiatDepositModal", false);
  const role = _.get(user, "userInfo.role", "");
  const depositModal = _.get(app, "DepositModal", false);
  const hideZeroBalance = _.get(app, "hideZeroBalance", false);
  const fiatrate = _.get(app, "fiatrate", false);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const getFiatBalanceinfo = _.get(fiat, "fiatBalance", []);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const displayedCurrencies = _.get(user, "displayedCurrencies", []);
  const dashboardDisplayCurrencies = _.get(
    user,
    "dashboardDisplayCurrencies",
    []
  );
  const PreferredCurrency = _.get(user, "PreferredCurrency", "USD");
  const myEstimatedBal = _.get(user, "myEstimatedBal", 0);
  return {
    mywalletsinfo,
    myaccountandbalance,
    getplatformTokensinfo,
    withdramodal,
    exchangemodal,
    plaformcurrencies,
    fiatDepositModal,
    getFiatBalanceinfo,
    depositModal,
    displayedCurrencies,
    role,
    hideZeroBalance,
    dashboardDisplayCurrencies,
    PreferredCurrency,
    myEstimatedBal,
    fiatrate,
    BranchpaymentList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTheme: (data) => dispatch({ type: "updateTheme", payload: data }),
  setwithdrawModal: (data) =>
    dispatch({ type: "withdramodalcalled", payload: data }),
  setExchangeModal: (data) =>
    dispatch({ type: "setExchangeModalcalled", payload: data }),
  setFiatDepositModal: (data) =>
    dispatch({ type: "FiatDepositModalCalled", payload: data }),
  setDepositModal: (data) =>
    dispatch({ type: "DepositModalCalled", payload: data }),
  setFiatWithdrawModal: (data) =>
    dispatch({ type: "setFiatWithdrawModalCalled", payload: data }),
  sethideZero: (data) => dispatch({ type: "hideZerocalled", payload: data }),
  addCurrency: (data) => dispatch(setDashboardCurrencies(data)),
  storeEstimated: (data) =>
    dispatch({ type: "storeEstimatedCalled", payload: data }),
  setFiatrate: (data) => dispatch({ type: "setFiatrateCalled", payload: data }),
  getBranchpayment: (data) =>
    dispatch({ type: "getBranchpaymentCalled", payload: data }),
  gpt: (data) => dispatch({ type: "getPlatformTokensCalled", payload: data }),
  addNewCoin: (data) => dispatch({ type: "addNewCoinCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
