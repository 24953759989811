import * as _ from "lodash";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackIcon } from "@images/back.svg";
import "@components/edit-loan-type/EditLoanType.scss";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import FooterCommon from "@components/footer-common/FooterCommon";
import moment from "moment";
import { editBranchStart } from "@store/branchmanagementredux";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import pprofile from "@images/pprofile.svg";
import {clearplatformerroSuccess} from '@store/platformuserredux';

const EditLoanType = ({
  
  show1,
  setShowModal , 
  editLoanTypeLoading,
  editLoantypeError,
  editLoanTypeAsyncSagaCalled,
  details,
  successPopup,
  clearBackendError,

}) => {
  
  const [loanTypeName , setLoanTypeName] = useState("")
  const [tenure , setTenure] = useState("")
  const [initialLTV , setInitialLTV] = useState("")
  const [liquidationLTV , setLiquidationLTV] = useState("")
  const [validated , setValidated] = useState(false);
  const [coinId , setCoinId] = useState()
  const [isValid, setIsValid] = useState(true);
  const [isValid2 , setIsValid2] = useState(true);
  const [isValid3 , setIsValid3] = useState(true);
 
 
  const handleInputChange = (e) => {
    const value = e.target.value;
    setTenure(e.target.value);
    
    
    setIsValid(value >= 0) && setFlag(true); 
  };
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInitialLTV(e.target.value);
    
    
    setIsValid2(value >= 0) && setFlag(true); 
  };
  const handleInputChange3 = (e) => {
    const value = e.target.value;
    setLiquidationLTV(e.target.value);
    
    // setInputValue(value);
    setIsValid3(value >= 0) && setFlag(true); 
  };
  useEffect(()=>{
    if(details !== undefined){
      setLoanTypeName(details.loanName)
      setTenure(Number(details.tenureInHours)/24)
      setInitialLTV(details.initialLTV)
      setLiquidationLTV(details.liquidationLTV)
      setCoinId(details.coinId)
    }
    clearBackendError()
  } , [details])
  const createLoanType = (form) => {

    const data = {
      loanName : loanTypeName,
      
      tenureInHours : (tenure * 24).toString(),
    
      
      coinId : Number(coinId),
      
      
      initialLTV : initialLTV,
      
      
      liquidationLTV : liquidationLTV ,
      id: details.id,
      openSuccess : ()=>{
        setShowModal(false)
        successPopup(true)}
    }
    editLoanTypeAsyncSagaCalled(data)
 };

 const FormSubmit = (event) => {
   const form = event.currentTarget;
   if (form.checkValidity() === false) {
     
     event.preventDefault();
     event.stopPropagation();
     setValidated(true);
    //  clearErrorMessage();
   } else {
     event.preventDefault();
     event.stopPropagation();
     if(isValid && isValid2 && isValid3){
      createLoanType(form);
     }
   }
 };


 const handleClose = ()=>{
  if(details !== undefined){
    setLoanTypeName(details.loanName)
    setTenure(Number(details.tenureInHours)/24)
    setInitialLTV(details.initialLTV)
    setLiquidationLTV(details.liquidationLTV)
    setCoinId(details.coinId)
  }
  clearBackendError()
  setShowModal(false);
 }

  

  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="EditLoanTypePopup"
      show={show1}
      onHide={() =>{
        clearBackendError()
        setShowModal(false)
      }
      }
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modalPrimaryHeader"></div>
        <CloseIcon
          className="closebtn"
          onClick={() =>{
            // setShowModal(false)
            handleClose()
          }

          }
        />
        <Container>
          <Form noValidate
           onSubmit={FormSubmit}
            validated={validated}>
            <div className="outercontainer">
              <div className="edittitle hidden">
                {" "}
                <BackIcon
                  className="back"
                  onClick={() =>
                    setShowModal(false)
                  }
                />
                <span className="editspan">
                  {t("Edit")} {t("Branch")}
                </span>
              </div>
              

            {editLoanTypeLoading && <Loader />}

              <div className="titles">
                <span>{t("Loan Type Name")}</span>
                <div className="inputbox">
                <InputField
                        type="text"
                        onChange={(e) => setLoanTypeName(e.target.value)}
                        value={loanTypeName}
                        isInvalid={validated === true}
                        required
                        error={
                          loanTypeName.length === 0
                            ? "Please enter loan type name"
                            : ""
                        }
                      />
                </div>
                
              </div>
              <div className="titles">
                <span>{t("Tenure (In Days)")}</span>
                <div className="inputbox">
                <InputField
                        type="number"
                        onChange={(e) => handleInputChange(e)}
                        value={tenure}
                        isInvalid={validated === true}
                        required
                        error={
                          tenure.length === 0
                            ? "Please enter tenure"
                            : ""
                        }
                      />
                </div>
                {!isValid && 
                  <span className="errortext">Please enter a non-negative number.</span>
                 }
              </div>
              <div className="titles">
                <span>{t("Initial LTV")}</span>
                <div className="inputbox">
                <InputField
                        type="number"
                        onChange={(e) =>handleInputChange2(e)}
                        value={initialLTV}
                        isInvalid={validated === true}
                        required
                        error={
                          initialLTV.length === 0
                            ? "Please enter Initial LTV"
                            : ""
                        }
                      />
                </div>
                {!isValid2 && 
                  <span className="errortext">Please enter a non-negative number.</span>
                }
              </div>
              <div className="titles">
                <span>{t("Liquidation LTV")}</span>
                <div className="inputbox">
                <InputField
                        type="number"
                        onChange={(e) => handleInputChange3(e)}
                        value={liquidationLTV}
                        isInvalid={validated === true}
                        required
                        error={
                          liquidationLTV.length === 0
                            ? "Please enter Liquidation LTV"
                            : ""
                        }
                      />
                </div>
                {!isValid3 && 
                  <span className="errortext">Please enter a non-negative number.</span>
                }
              </div>
              <div className="btmbtnoutercontainer">
                <div className="topbuttons">
                  <CustomButton
                    type="submit"
                    name={t("Save")}
                    filled={true}
                    primary={true}
                    //onClick={() => editBranch()}
                  />
                </div>
                <div className="bottombuttons">
                  <CustomButton
                    name={t("Cancel")}
                    filled={true}
                    primary={true}
                    type="button"
                    onClick={() =>
                      handleClose()
                    }
                  />
                </div>
              </div>
            </div>
          </Form>
        </Container>

        <p className="errortext1">{editLoantypeError}</p>
        <div className="hidden">
          <FooterCommon />
        </div>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ wallet = {} }) => {
  const editLoanTypeLoading = _.get(wallet , "editLoanSupportInPlatformLoading" , false);
  const editLoantypeError = _.get(wallet , "editLoanSupportInPlatformError" , undefined);
  
  return {
  editLoanTypeLoading,
  editLoantypeError
  };
};
const mapDispatchToProps = (dispatch) => ({
  editLoanTypeAsyncSagaCalled : (data)=>dispatch({type : "editLoanSupportInPlatformSagaCalled" , payload : data}),
  clearBackendError : ()=>dispatch(clearplatformerroSuccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLoanType);
