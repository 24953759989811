import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import "@components/dropdown-search/DropdownSearchStyles.scss";
import { Dropdown, FormControl, Form } from "react-bootstrap";
import { ReactComponent as DropdownarrowIcon } from "@images/dropdownsearch/Dropdown.svg";
import { useTranslation } from "react-i18next";
const DropdownSearch = ({
  options,
  Selected,
  setSelectedIndex,
  scope,
  exchangeSymbolshow,
  forFiatDeposit,
  error,
  bity,
  loan,
  setCoinId,
  getLoanTypeId,
  getLoanInitialLTV,
  getLoanLiquiLTV,
  getCollateralCoinName,
}) => {
  const [value, setValue] = useState("");
  const [dropdownshow, setShow] = useState(false);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="dropdownlink"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (!dropdownshow) {
          onClick(e);
          // setShow(true);
        }
      }}
      // show={dropdownshow}
    >
      {children}
      <span className="toogleicon">
        <DropdownarrowIcon className="dropdownarrow" />
      </span>
    </div>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div ref={ref} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.value.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const { t } = useTranslation();

useEffect(()=>{
  console.log('loan from drop' , loan)
} , [loan])
  return (
    <div className="DropdownSearch">
      <Dropdown align="end">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {scope &&
          scope === "FiatDeposit" &&
          Selected === "" &&
          value.length == 0 ? (
            <span className="customDropDrownPlaceholder">
              {t("SelectNewBankAccount")}
            </span>
          ) : (
            <></>
          )}
          { loan !== undefined ?   <span className="selecteditem">
           
           { loan && loan[Selected] ?
            <span className="cointext">
            {(loan && loan[Selected] && loan[Selected].loanName)} 
            </span>
            : <></>
           }
          </span> :
          <span className="selecteditem">
            {!bity &&
              options &&
              options[Selected] &&
              options[Selected].colouredLogo && (
                <span className="coinicon">
                  <img
                    src={
                      options &&
                      options[Selected] &&
                      `${process.env.REACT_APP_IMGLINK}${options[Selected].colouredLogo}`
                    }
                  ></img>
                </span>
              )}

            <span className="cointext">
              {(options && options[Selected] && options[Selected].tokenName) ||
                (bity
                  ? options && options[Selected] && options[Selected].code
                  : exchangeSymbolshow
                  ? options &&
                    options[Selected] &&
                    options[Selected].exchangeSymbol
                  : options &&
                    options[Selected] &&
                    options[Selected].currencyName)}
            </span>
            {((options &&
              options[Selected] &&
              options[Selected].type == "TRC20") ||
              (options &&
                options[Selected] &&
                options[Selected].type == "BEP20") ||
              (options &&
                options[Selected] &&
                options[Selected].type == "ERC20")) && (
              <span className="cointag">
                {options && options[Selected] && options[Selected].type}
              </span>
            )}
          </span>
}
          <FormControl
            autoFocus
            className="search"
            //placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={value}
            autoComplete="off"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {_.isArray(options) && options ?
            options?.map((option, index) => {
              return (
                <Dropdown.Item
                  eventKey={index}
                  key={index + "dropdownitem"}
                  className={Selected == index ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    if(scope !== undefined && scope === "loan")
                    {
                      setCoinId(option.id)
                    setSelectedIndex(index);
                  
                    getCollateralCoinName !== undefined && getCollateralCoinName(option.exchangeSymbol)
                  }else if(scope !== undefined && scope === "personalloan"){
                    setCoinId(option.id)
                    setSelectedIndex(index);
                  }else{
                    setSelectedIndex(index)
                  }
                    setShow(false);
                  }}
                  value={
                    (options && options[index] && options[index].tokenName) ||
                    (exchangeSymbolshow
                      ? options &&
                        options[index] &&
                        options[index].exchangeSymbol
                      : options &&
                        options[index] &&
                        options[index].currencyName)
                  }
                >
                  {!bity &&
                    options &&
                    options[index] &&
                    options[index].colouredLogo && (
                      <span className="coinicon">
                        <img
                          src={
                            options &&
                            options[index] &&
                            `${process.env.REACT_APP_IMGLINK}${options[index].colouredLogo}`
                          }
                        ></img>
                      </span>
                    )}

                  <span className="cointext">
                    {(options && options[index] && options[index].tokenName) ||
                      (bity
                        ? options && options[index] && options[index].code
                        : exchangeSymbolshow
                        ? options &&
                          options[index] &&
                          options[index].exchangeSymbol
                        : options &&
                          options[index] &&
                          options[index].currencyName)}
                  </span>
                  {((options &&
                    options[index] &&
                    options[index].type == "TRC20") ||
                    (options &&
                      options[index] &&
                      options[index].type == "BEP20") ||
                    (options &&
                      options[index] &&
                      options[index].type == "ERC20")) && (
                    <span className="cointag">
                      {options && options[index] && options[index].type}
                    </span>
                  )}
                </Dropdown.Item>
              );
            }
            )
             :loan !== undefined &&  loan.map((item, index)=>{
     
              return(
                <Dropdown.Item
                eventKey={index}
                key={index + "dropdownitem"}
                className={Selected == index ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                 console.log('itm' , item)
                  setSelectedIndex(index)
                  getLoanTypeId(item.id)
                  getLoanLiquiLTV(item.liquidationLTV)
                  getLoanInitialLTV(item.initialLTV)
                  setShow(false);
                }}
                // loan[index].label
                value={
                 `${(loan && loan[index] && loan[index].loanName)}`
                }
                >
                  {`${item.loanName}`}
                </Dropdown.Item>
               )
             }) 
            }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownSearch;
