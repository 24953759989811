export default {
    Payments: 'Ödemeler',
    CreatePaymentLink: 'Ödeme Bağlantısı Oluştur',
    Print: 'Yazdır',
    PaymentID: 'Ödeme İD numarası',
    Date: 'Tarih',
    PaymentLink: 'Ödeme Bağlantısı',
    CoinToAccept: 'Alınacak Coinler',
    Amount: 'Miktar',
    CoinToConvert: 'Döviz edilecek Coinler',
    Status: 'Durum',
    CopyPaymentLink: 'Ödeme Bağlantısını Kopyala',
    Details: 'Detaylar',
    Showing: 'Gösteriliyor',
    From: '',
    Completed: 'Tamamlandı',
    Cancelled: 'İptal edildi',
    Pending: 'Bekleniyor',
    failed: "BAŞARISIZ",
    transfer: "Gönder",
    copied: 'Kopyalandı!',
    YOUR_BUSINESS_ACCOUNT_REQUEST_IS_NOT_APPROVED:'İşletme hesap talebiniz onaylanmadı',
    CurrencyToConvert: 'Döviz edilecek Para Birimi',
    CryptoCurrencyToPay: 'Kripto Para Birimi (ödemek için)',
    CryptoCurrencyToConvert: 'Döviz edilecek Kripto Para Birimi',
    PaymentLinkwillbecreated: 'Oluşturulacak Ödeme Bağlantısı',
    Showonscreen: 'Ekranda göster',
    CopyAddress :"Adresi kopyala",
    LinkCreatedSuccessfully: 'Bağlantı Başarıyla Oluşturuldu',
    WantToCancel: "Bu ödemeyi iptal etmek istiyor musunuz?",
    ClientPays: "Müşterinin Ödeyeceği",
    CurrencytoConvert: "Döviz edilecek Para Birimi",
    CurrencytoReceive: "Alınacak Para Birimi",
    PAYMENT_DETECTED: "Ödeme algılandı",
    PAYMENT_REQUESTED: "Ödeme için bekleniyor",
    PAY_CYWALL: "PayCryptwallet kullanarak ödeme yapın",
    PAY_OTWALL:`Diğer cüzdanı kullanarak ödeme yapın`,
    LPAY_CYWALL: "PayCryptwallet kullanarak ödeme yapmak için giriş yapın",
}