import React, { useEffect, useState } from "react";
import CurrencyTab from "src/components/currency-tab/CurrencyTab.js";
import InputField from "@components/input-field/InputField";
import Loader from "@components/loader/Loader";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/round-form/RoundFormStyle.scss";
import { connect } from "react-redux";
import RoundList from "@components/round-list/RoundList";
import { Form } from "react-bootstrap";
import { ReactComponent as IdoTickmark } from "@images/idotickmark.svg";
import { useTranslation } from "react-i18next";

function RoundForm({
  getplatformTokensinfo,
  logoBgColor,
  createRido,
  getRountido,
  createRoundLoading,
  idorounds,
  EditRoundPrivacy,
  createTransactionLoading,
}) {
  let [tableno, settableno] = useState("first");
  const getLogo = (cur) => {
    let obj = getplatformTokensinfo.filter((item) => item.tokenName == cur);
    let logo = _.get(obj, "[0].nonColouredLogo", 0);
    return logo;
  };
  const getLogoback = (cur) => {
    let obj = getplatformTokensinfo.filter((item) => item.tokenName == cur);
    let logo = _.get(obj, "[0].logoBgColor", 0);
    return logo;
  };
  let logo = "";
  const [validated, setValidated] = useState(false);
  const [exchangerate, setExchangerate] = useState("");
  const [lock, setlock] = useState("");
  const [limit, setlimit] = useState("");
  const [bouns, setbouns] = useState("");
  const [roundname, setround] = useState("");

  const onCreateRound = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      createRido({
        lockingPeriod: `${lock * 86400}`,
        tokenLimit: limit,
        exchangeRate: exchangerate,
        roundName: roundname,
        bonus: bouns,
      });
      setTimeout(() => {
        setExchangerate("");
        setlock("");
        setlimit("");
        setValidated(false);
        settableno("second");
        setbouns("");
      }, 1000);
    }
  };
  const { t, i18n, ready } = useTranslation();

  return (
    <div className={`RoundForm ${tableno == "first" ? "create" : "list"}`}>
      <div className="outercontainerforbtn">
        <div className="tabarea">
          <div className="outerconforbtn1 tabbtn">
            <CustomButton
              type="submit"
              name={t("Create")}
              filled={true}
              primary={true}
              active={tableno == "first"}
              onClick={() => settableno("first")}
              className="tabbtn"
            />
          </div>
          <div className="outerconforbtn1 tabbtn">
            <CustomButton
              type="submit"
              name={t("List")}
              filled={true}
              primary={true}
              active={tableno == "second"}
              onClick={() => {
                settableno("second");
                getRountido();
              }}
            />
          </div>
        </div>
      </div>
      {tableno == "first" && (
        <Form noValidate validated={validated} onSubmit={onCreateRound}>
          <div className="fieldssection fieldssectionmargin">
            <div className="labeloffield">
              <span className="label"> {t("ROUNDNAME")}</span>
            </div>
            <div className="formfield">
              <div className="inputfieldholder">
                <InputField
                  value={roundname}
                  classname={"withoutleftborder"}
                  onChange={(e) => setround(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="fieldssection fieldssectionmargin">
            <div className="labeloffield">
              <span className="label"> {t("EnterTokenLimit")}</span>
            </div>
            <div className="formfield">
              <div className="inputfieldholder">
                <InputField
                  type="number"
                  value={limit}
                  classname={"withoutleftborder"}
                  onChange={(e) => setlimit(e.target.value)}
                  required
                />
              </div>
              <div className="logosection">
                <div
                  className="logoholder"
                  style={{ backgroundColor: getLogoback("CyborCoin") }}
                >
                  <img
                    src={`${process.env.REACT_APP_IMGLINK}${getLogo(
                      "CyborCoin"
                    )}`}
                    className="coinimg"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldssection fieldssectionmargin">
            <div className="labeloffield">
              <span className="label">
                {" "}
                {t("EnterExchangeRate")} 1USDT = CYBOR
              </span>
            </div>
            <div className="formfield">
              <div className="inputfieldholder">
                <InputField
                  value={exchangerate}
                  required
                  type="number"
                  classname={"withoutleftborder"}
                  onChange={(e) => setExchangerate(e.target.value)}
                />
              </div>
              <div className="logosection">
                <div
                  className={`logoholder address  ${
                    exchangerate.length != 0 && "valid"
                  }`}
                >
                  <IdoTickmark className="tickmark" />
                </div>
              </div>
            </div>
            <div className="defaultext">
              <span className="text">1USDT = {exchangerate} CYBOR</span>
            </div>
          </div>

          <div className="fieldssection fieldssectionmargin">
            <div className="labeloffield">
              <span className="label">{t("EnterLockingPeriodInDays")}</span>
            </div>
            <div className="formfield">
              <div className="inputfieldholder">
                <InputField
                  type="number"
                  classname={"withoutleftborder"}
                  onChange={(e) => setlock(e.target.value)}
                  value={lock}
                  required
                />
              </div>
              <div className="logosection">
                <div
                  className={`logoholder address ${
                    lock.length != 0 && "valid"
                  }`}
                >
                  <IdoTickmark className="tickmark" />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldssection fieldssectionmargin">
            <div className="labeloffield">
              <span className="label"> {t("Bonusinpercent")}</span>
            </div>
            <div className="formfield">
              <div className="inputfieldholder">
                <InputField
                  value={bouns}
                  type="number"
                  classname={"withoutleftborder"}
                  onChange={(e) => setbouns(e.target.value)}
                  required
                />
              </div>
              <div className="logosection">
                <div
                  className="logoholder"
                  style={{ backgroundColor: getLogoback("CyborCoin") }}
                >
                  <img
                    src={`${process.env.REACT_APP_IMGLINK}${getLogo(
                      "CyborCoin"
                    )}`}
                    className="coinimg"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="outerconforbtn">
            <CustomButton
              type="submit"
              name={t("CreateNewRound")}
              filled={true}
              primary={true}
              disable={createRoundLoading}
            />
          </div>
        </Form>
      )}
      {(createRoundLoading || createTransactionLoading) && (
        <Loader msg={t("CreatingRoundMsg")} />
      )}
      {tableno == "second" && (
        <RoundList idorounds={idorounds} EditRoundPrivacy={EditRoundPrivacy} />
      )}
    </div>
  );
}
const mapStateToProps = ({ ido, wallet }) => {
  const idorounds = _.get(ido, "idorounds", []);
  const createRoundLoading = _.get(ido, "createRoundLoading", false);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const createTransactionLoading = _.get(
    ido,
    "createTransactionLoading",
    false
  );

  return {
    idorounds,
    createRoundLoading,
    getplatformTokensinfo,
    createTransactionLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createRido: (data) => dispatch({ type: "createRidocalled", payload: data }),
  getRountido: (data) => dispatch({ type: "getRountidocalled", payload: data }),
  createIdoTrans: (data) =>
    dispatch({ type: "createIdoTranscalled", payload: data }),
  EditRoundPrivacy: (data) =>
    dispatch({ type: "EditRoundPrivacycalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoundForm);
