import * as _ from 'lodash'
import { select } from 'redux-saga/effects'

export function * getAccessToken() {
  const user = yield select(({ user }) => user)
  return _.get(user, 'userInfo.accessToken', '')
}

export function * getUserRole() {
  const user = yield select(({ user }) => user)
  return _.get(user, 'userInfo', '')
}
 
export function * getUserId () {
  const user = yield select(({ user }) => user)
  return _.get(user, 'userInfo.id', '')
}
 
export function * getWalletId () {
  const user = yield select(({ user }) => user)
  return _.get(user, 'userInfo.walletId', "")
}
export function * getfiatWalletId () {
  const user = yield select(({ user }) => user)
  return _.get(user, 'userInfo.fiatWalletId', "")
}

export function * getRole () {
  const user = yield select(({ user }) => user)
  return _.get(user, 'MyProfileInfo.role', [])
}

export function * getAccountId (tokenName) {
  const wallet = yield select(({ wallet }) => wallet)
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  )
  let obj = myaccountandbalance.filter(
    (item) => item.coin.id == tokenName
  );
  let id = _.get(obj, "[0].address", '');
   return id;
 }


 export function * getTokenDeciamls (tokenName) {
  const wallet = yield select(({ wallet }) => wallet)
  const myaccountandbalance = _.get(
    wallet,
    "myaccountandbalancebywalletidinfo",
    []
  )
  let obj = myaccountandbalance.filter(
    (item) => item.coin.tokenName == tokenName
  );
  let id = _.get(obj, "[0].coin.decimals", 0);
   return id;
 }

 
