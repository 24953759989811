export default {
  Deposit: "Deposit",
  Withdraw: "Withdraw",
  Exchange: "Exchange",
  Displayedcurrencies: "Displayed currencies",
  TotalBalances: "Total Balances",
  Searchhere: "Search here",
  Coin: "Coin",
  Balance: "Balance",
  Locked: "Locked",
  Price: "Price",
  Showing: "Showing",
  from: "from",
  Currency: "Currency",
  AddCoin: "Add Coin",
  Cryptocurrency: "Cryptocurrency",
  Doyouwanttodeletethisbranch: "Do you want to delete this branch",
  pleasemakepayment:
    "Please make a payment (with mantioned amount) to IBAN number below",
  SelectRound: "Select Round",
  Enterrecipientaddress: "Enter recipient address(PayCrypt address)",
  Enteramounttotransfer: "Enter amount to transfer",
  Amounteqivalenttokentotransfer: "Amount eqivalent token to transfer",
  Createreport: "Create report",
  Yourreporthasbeengenerated: "Your report has been generated",
  Downloadfile: "Download file",
  Details: "Details",
  Transactionreference: "Transaction reference",
  Amount: "Amount",
  amount: "amount",
  Share : "Share",
  Copy : "Copy",
  TransactionFee: "Transaction Fee",
  Status: "Status",
  TransactionID: "Transaction ID",
  DateandTime: "Date and Time",
  ViewinBlockchainExplorer: " View in Blockchain Explorer",
  Acceptas: "Accept as",
  Convertto: "Convert to",
  Sendlinkto: "Send link to",
  EnterEmail: "Enter E-mail",
  Paymentlinkwillbecreated: "Payment link will be created",
  YouwillReceive: "You will Receive",
  PlatformCommission: "Platform Commission",
  Create: "Create",
  From: "From",
  Total: "Total",
  PaymentLinkDetails: "Payment Link Details",
  Completed: "Completed",
  PaymentID: "Payment ID",
  Date: "Date",
  Recepient: "Recepient",
  Copylink: "Copy link",
  ShareLink: "Share Link",
  Print: "Print",
  Close: "Close",
  Logo: "Logo",
  thesizeoftheimage:
    " The size of the image can be taken up to 98 x 98 pixels (size to the file - up to 4 MB) in PNG or JPEG format",
  Change: "Change",
  Exclude: "Exclude",
  Account: "Account",
  Branch: "Branch",
  Phone: "Phone",
  DeleteConfirmation: "Delete Confirmation",
  Doyowanttodeletethisbranch: "Do you want to delete this branch",
  TransferredSuccessfully: "Transferred Successfully !",
  ExchangedSuccessfully: "Exchanged Successfully !",
  Twofactorauthentication: "Two-factor authentication",
  pleaseusegoogleauth:
    "Please, use your Google authentication app to scan this QR code or enter a numeric code in your application",
  Cancel: "Cancel",
  Ok: "Ok",
  GETITFROM: "GET IT FROM",
  GETITON: "GET IT ON",
  DOWNLOADONTHE: "DOWNLOAD ON THE",
  EXPLOREITON: "EXPLORE IT ON",
  EstimatedBalances: "Estimated Balances",
  Hidezerobalance: "Hide zero balance",
  showzerobalance:"Show zero balance ",
  PaymentFrom: "Payment from",
  PaymentTo: "Payment to",
  FromWallet: "From the wallet",
  ToWallet: "To the wallet",
  WithdrawOrder: "Withdraw order",

};
