import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Table from "react-bootstrap/esm/Table";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { Row, Col, Form,Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as RightArrow } from "@images/rightarrow.svg";
import EditBranchpopup from "@components/edit-branchpopup/EditBranchpopup.js";
import EditLogo from "@images/branchmanagement/Edit.svg";
import DeleteLogo from "@images/branchmanagement/Delete.svg";
// import "./BranchManagementStyle.scss";
import DeleteConfirmationPopup from "@components/delete-confirmationpopup/DeleteConfirmationPopup";
import { useTranslation } from "react-i18next";
import Loader from "@components/loader/Loader";
import EditLoanType from "src/components/edit-loan-type/EditLoanType";
import DeleteAdminLoanManagementPopup from "src/components/delete-admin-loan-management-popup/DeleteAdminLoanManagementPopup";
import AdminLoanSuccesfulPopup from "src/components/admin-loan-template-successfull-popup/AdminLoanSuccesfulPopup";
import CoinList from "src/components/coin-list/CoinList";
import EditPersonalLoanType from "src/components/edit-personal-loan-type/EditPersonalLoanType";
import SuccessPopup from "src/components/personal-loan-success-popup/SuccessPopup";
import './AdminPersonalLoanManagement.scss'

function AdminPersonalLoanManagement({
  settings,

  allPersonalLoanLoading,
  allpersonalLoan,
  allPersonalLoanError,
  deleteLoanTypeLoading,
  deletePersonalLoan,
  allAdminPersonalLoan,
}) {
  const history = useHistory();

  let [deleteId, setDeleteId] = useState();

  let [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  let [editBranchPopup, setEditBranchPopup] = useState(false);
  let [pages, setpages] = useState(0);
  let [limit, setlimit] = useState(10);
  let [skip, setskip] = useState(0);
  let [editingDetails, setEditingDetails] = useState();
  let [edited, setEditedSuccesfully] = useState(false);
  let [deleteSuccess, setDeleted] = useState(false);

  const [loanTypeName, setLoanTypeName] = useState("");
  const [tenureInMonths, setTenureInMonths] = useState("");
  const [loanInterestRate, setLoanInterestRate] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [deleteLoanError, setDeleteLoanError] = useState("")

  useEffect(() => {
    allAdminPersonalLoan({ skip, limit });
    setDeleteLoanError("")
  }, []);

  const DeleteLoan = (id) => {
    setShowDeleteConfirmation(true);
    setDeleteId(id);
  };

  const EditPersonalLoan = (branchDetails) => {
    setEditBranchPopup(true);
    setLoanTypeName(branchDetails.loanName);
    setTenureInMonths(branchDetails.tenureInMonths);
    setLoanInterestRate(branchDetails.loanInterestRate);
    setLoanAmount(branchDetails.loanAmount);
    setEditingDetails(branchDetails);
  };

  const handleEditClose = ()=>{
    setEditBranchPopup(false);
    resetState()
  }

  const resetState = () =>{
    setEditingDetails("");
  }

  const handleClose = ()=>{
    setDeleteLoanError("")
  }

  const { t } = useTranslation();

  return (
    <ScreenLayout hideLoaderOnStart={true}>
      <div id="AdminPersonalLoanManagement">
        <DeleteAdminLoanManagementPopup
          showDeleteConfirmation={showDeleteConfirmation}
          setShowDeleteConfirmation={setShowDeleteConfirmation}
          onPress={() => {
            setShowDeleteConfirmation(false);

            deletePersonalLoan({
              id: Number(deleteId),
              openSuccess: () => setDeleted(true),
              setDeleteLoanError,
            });
          }}
          onCancelled={() => {
            setShowDeleteConfirmation(false);
            setDeleteId();
          }}
        />

        <EditPersonalLoanType
          loanTypeName={loanTypeName} tenureInMonths={tenureInMonths} loanAmount={loanAmount} 
          loanInterestRate = {loanInterestRate}
          setLoanAmount={setLoanAmount} setTenureInMonths={setTenureInMonths} setLoanTypeName={setLoanTypeName}
          setLoanInterestRate={setLoanInterestRate}
          setShowModal={setEditBranchPopup}
          show1={editBranchPopup}
          details={editingDetails}
          successPopup={setEditedSuccesfully}
          handleEditClose={handleEditClose}
        />

        <SuccessPopup
          setShowModal={setEditedSuccesfully}
          showModal={edited}
          message={"Edited Successfully"}
        />
        {/* <AdminLoanSuccesfulPopup 
        /> */}

        <SuccessPopup
          setShowModal={setDeleted}
          showModal={deleteSuccess}
          message={"Deleted Successfully"}
        />

        <Container className="containertp">
          <Row>
            <Col lg={12}>
              <div className="trouter">
                <div className="transactiontitle">
                  <span className=" title">{t("Bank Loan Types")}</span>
                  <div className="filtersection d-none d-lg-block">
                    {/* <Filter
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(e) => setStartDate(e)}
                      setEndDate={(e) => setEndDate(e)}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="btmmargin">
                  {/* <SearchBox setvalues={setSearchName} /> */}
                </div>
                <div className="leftsection">
                  <div className="buttoncontainer">
                    <CustomButton
                      type="submit"
                      name={t("Create")}
                      filled={true}
                      primary={true}
                      onClick={() => history.push("/createPersonalLoanType")}
                    />
                  </div>
                </div>
                <div className="filtersection d-block d-lg-none"></div>
              </div>
              {deleteLoanError && (
                <Alert onClose={handleClose} dismissible variant={"danger"}>
                  {deleteLoanError}
                </Alert>
              )}
              <Table responsive="sm" className=" tablestyle" borderless>
                {(allPersonalLoanLoading || deleteLoanTypeLoading) && (
                  <Loader />
                )}
                {/* <Loader loading={allBranchesLoading} /> */}
                <thead className="hidden-for-mobile">
                  <tr>
                    <th className="trtbleheading paddingleft">{t("NO")}</th>
                    <th className="trtbleheading">{t("Name")} </th>
                    {/* <th className="trtbleheading">{t("Coin")} </th> */}
                    <th className="trtbleheading">
                      {t("Tenure (In Months)")}{" "}
                    </th>
                    <th className="trtbleheading">{t("Loan Amount (In USD)")} </th>
                    <th className="trtbleheading">{t("Interest Rate")} </th>
                    <th className="trtbleheading">{t("Edit")} </th>
                    <th className="trtbleheading">{t("Delete")}</th>
                  </tr>
                </thead>

                <tbody>
                  <>
                    {allpersonalLoan !== undefined &&
                    allpersonalLoan.data.length > 0 ? (
                      allpersonalLoan.data.map((item) => (
                        <tr className="trtbleheading maintdwrapper">
                          <td className="innercontent">
                            <div className="innerTd d-lg-flex d-none">
                              {item.id}
                            </div>
                            <div className="innerTd d-lg-none d-flex">
                              <p className="mobUpperline">
                                No{item.id} {item.loanName}
                              </p>
                              <p className="mobLowerline">
                                {item.tenureInMonths} Months
                              </p>
                            </div>
                          </td>
                          <td className="amt d-none d-lg-table-cell">
                            <div className="innerTd id smallwidth">
                              {item.loanName}
                            </div>
                          </td>
                          {/* <td className="amt d-none d-lg-table-cell">
                     
                        <div className="innerTd id smallwidth">{item && item.coin && item.coin.tokenName}</div>
                      </td> */}
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd smallwidth">
                              {item.tenureInMonths}
                            </div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd">{item.loanAmount}</div>
                          </td>
                          <td className="innercontent d-none d-lg-table-cell">
                            <div className="innerTd">
                              {item.loanInterestRate}
                            </div>
                          </td>
                          <td className="innercontent">
                            {/* <div className="buttonwrapper innerTd d-none d-lg-flex"> */}
                            <div className="innerTd passive">
                              <img
                                src={EditLogo}
                                alt="Edit Logo"
                                className="editbutton"
                                onClick={() => EditPersonalLoan(item)}
                              />
                            </div>
                          </td>
                          <td className="innercontent">
                            {/* <div className="buttonwrapper innerTd d-none d-lg-flex"> */}
                            <div className="innerTd passive">
                              <img
                                src={DeleteLogo}
                                alt="Delete Logo"
                                className="editbutton"
                                onClick={() => DeleteLoan(item.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <p className="trtbleheading">
                        {t("Sorrynoresultsfound")}!
                      </p>
                    )}
                  </>
                </tbody>
              </Table>
              {allpersonalLoan !== undefined && allpersonalLoan.count > 10 && (
                <div className="paginationsection">
                  <div className="paginationtxtholer">
                    <span className="noofpagetxt">
                      {t("Showing")} {pages + 1} {t("From")}{" "}
                      {Math.ceil(allpersonalLoan.count / limit)}
                    </span>
                  </div>
                  <div className="paginationallignment">
                    <ReactPaginate
                      previousLabel={
                        <PreviousIcon className="paginationicon" />
                      }
                      nextLabel={<NextIcon className="paginationicon" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allpersonalLoan.count / limit}
                      // pageCount={2000}
                      forcePage={pages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={(e) => {
                        setskip(e.selected * limit);
                        allAdminPersonalLoan({
                          skip: e.selected * limit,
                          limit,
                        });

                        setpages(e.selected);
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({ app = {}, wallet = {} }) => {
  const deleteLoanTypeLoading = _.get(
    wallet,
    "deletePersonalLoanLoading",
    false
  );
  const allPersonalLoanLoading = _.get(
    wallet,
    "adminAllPersonalLoanLoading",
    false
  );
  const allpersonalLoan = _.get(wallet, "adminAllPersonalLoanList", undefined);
  const allPersonalLoanError = _.get(
    wallet,
    "adminAllPersonalLloanError",
    undefined
  );
  const thememode = _.get(app, "thememode", false);
  const settings = _.get(app, "settings", false);

  return {
    thememode,
    settings,
    deleteLoanTypeLoading,
    allPersonalLoanLoading,
    allpersonalLoan,
    allPersonalLoanError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  allAdminPersonalLoan: (data) =>
    dispatch({ type: "adminAllPersonalLoanSagaCalled", payload: data }),
  deletePersonalLoan: (data) =>
    dispatch({ type: "deletePersonalLoanAdminSagaCalled", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPersonalLoanManagement);
