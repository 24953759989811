export default{
trademark: " © 2023 Trademark PayCrypt",
CyborexPlatform: "Cyborex platform",
CyborPayPaymentSystem: "PayCrypt payment system",
CyborcoinCryptocurrency: "Cyborcoin cryptocurrency",
OwnedBy: "is owned by PayCrypt Pİ BİLİŞİM TEKNOLOJİLERİ VE TİCARET ANONİM ŞİRKETİ",
FooterPara1: " / All rights reserved PayCrypt is a product of the company PayCrypt Cryptocurrency investment activities including the purchase of PayCrypt Coin (CYBOR) are subject to market risk. PayCrypt provides you with easy",
FooterPara2: "and convenient ways to instantly buy PayCrypt Coin (CYBOR). We make every effort to comprehensively inform users about the cryptocurrencies offered on the exchange, but we are not responsible for the results that may arise as",
ResultPurchase: "a result of the purchase.",
Cyborcoin: "Cyborcoin",
cryptocurrency: "cryptocurrency",
RightReserved: " / All rights reserved",
FAQs: "FAQs",
CommissionsFees: "Commissions & Fees",
TermsCondition: "Terms & Condition",
PrivacyPolicy: "Privacy & Policy",
 };