export default {
  Deposit: "Пополнение",
  Withdraw: "Вывод",
  Exchange: "Обмен",
  Displayedcurrencies: "Отображаемые Валюты",
  TotalBalances: "Общий Баланс",
  Searchhere: "Поиск",
  Coin: "Монета",
  Balance: "Баланс",
  Locked: "Заблокировано",
  Price: "Цена",
  Showing: "Показаны",
  from: "от",
  Currency: "Валюта",
  AddCoin: "Добавить Монету",
  Cryptocurrency: "Криптовалюта",
  Doyouwanttodeletethisbranch: "Хотите удалить это отделение",
  pleasemakepayment:
    "Пожалуйста, совершите платеж (с указанной суммой) на IBAN номер, указанный ниже",
  SelectRound: "Выберите Раунд",
  Enterrecipientaddress: "Введите адрес получателя(PayCrypt адрес)",
  Enteramounttotransfer: "Введите сумму перевода",
  Amounteqivalenttokentotransfer: "Эквивалент в токенах для перевода",
  Createreport: "Создать отчет",
  Yourreporthasbeengenerated: "Ваш отчет был сгенерирован",
  Downloadfile: "Скачать файл",
  Details: "Детали",
  Transactionreference: "Ссылка на транзакцию",
  Amount: "Сумма",
  amount: "сумма",
  TransactionFee: "Комиссия",
  Status: "Статус",
  TransactionID: "ID Транзакции",
  DateandTime: "Дата и Время",
  ViewinBlockchainExplorer: " Просмотреть в Blockchain Explorer",
  Acceptas: "Принять в",
  Convertto: "Конвертировать в",
  Sendlinkto: "Отправить ссылку",
  EnterEmail: "Введите E-mail",
  Paymentlinkwillbecreated: "Ссылка на платеж будет создана",
  YouwillReceive: "Вы Получите",
  PlatformCommission: "Комиссия Платформы",
  Create: "Создать",
  From: "От",
  Total: "Итог",
  PaymentLinkDetails: "Детали Ссылки на Платеж",
  Completed: "Завершено",
  PaymentID: "ID Платежа",
  Date: "Дата",
  Recepient: "Получатель",
  Copylink: "Копировать ссылку",
  ShareLink: "Поделиться ссылкой",
  Print: "Печать",
  Close: "Закрыть",
  Logo: "Логотип",
  thesizeoftheimage:
    "Размер изображения должен быть до 98 х 98 пикселей (размер файла - до 4 МБ) в формате PNG или JPEG.",
  Change: "Изменить",
  Exclude: "Удалить",
  Account: "Аккаунт",
  Branch: "Отделение",
  Phone: "Телефон",
  DeleteConfirmation: "Подтверждение удаления",
  Doyowanttodeletethisbranch: "Вы хотите удалить это отделение",
  TransferredSuccessfully: "Перевод совершен!",
  ExchangedSuccessfully: "Обмен совершен!",
  Twofactorauthentication: "Двух-факторная аутентификация",
  pleaseusegoogleauth:
    "Пожалуйста, используйте приложение аутентификации Google для сканирования этого QR-кода или введите числовой код в свое приложение.",
  Cancel: "Отмена",
  Ok: "ОК",
  GETITFROM: "Получить на",
  GETITON: "Получить на",
  DOWNLOADONTHE: "Скачать на",
  EXPLOREITON: "Просмотреть на",
  EstimatedBalances: "Расчетный баланс",
  Hidezerobalance: "Скрыть нулевой баланс",
  showzerobalance:"Показать нулевой баланс ",
  PaymentFrom: "Оплата от",
  PaymentTo: "Оплата Для",
  FromWallet: "Из кошелька",
  ToWallet: "К кошельку",
  WithdrawOrder: "Снять заказ",

};
