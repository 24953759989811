import * as _ from "lodash";
import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import { ReactComponent as ExpandIcon } from "@images/Arrow3.svg";
import "@components/withdraw-smallpopup/WithdrawSmallpopupStyles.scss";
const WithdrawSmallpopup = ({
  show1,
  setShowModal,
  onPress,
  address,
  amount,
  setHideThis,
  from,
  MyProfileInfo
}) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="withdrawsmallpopup"
      show={show1}
      onHide={() => {
        setHideThis(false);
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalPrimaryHeader hidden-for-desktop"></div>
      <CloseIcon
        className="closebtn hidden-for-mobile"
        onClick={() => setShowModal(false)}
      />
      <div
        className="back hidden-for-desktop"
        onClick={() => setShowModal(false)}
      >
        <ExpandIcon />
      </div>
      <Modal.Header>
        <div className="transectiontype">
          <span className="transectiontext uppercase">
          {t("WithdrawOrder")}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
      <Container>  
        <div className="detail">
            <div className="labelcls">
              <span className="labeltext">{t("PaymentFrom")}:</span>
            </div>
            <div className="value">
              <span className="valuetext emailtext">
              {MyProfileInfo.name} {MyProfileInfo.subName}
              </span>
            </div>
          </div>
          <div className="detail">
            <div className="labelcls">
              <span className="labeltext">{t("FromWallet")}: </span>
            </div>
            <div className="value">
              <span className="valuetext addresstext">{from}</span>
            </div>
          </div>
          <div className="detail">
            <div className="labelcls">
              <span className="labeltext">{t("ToWallet")}: </span>
            </div>
            <div className="value">
              <span className="valuetext addresstext">{address}</span>
            </div>
          </div>
          <div className="detail">
            <div className="labelcls">
              <span className="labeltext"> {t("Amount")} / {t("Currency")}: </span>
            </div>
            <div className="value">
              <span className="valuetext emailtext">{amount}</span>
            </div>
          </div>

          <Row className="rowspace">
            <Col lg={6} sm={6} xs={6}>
              <div className="cancel">
                <CustomButton
                  type="submit"
                  name={t("Cancel")}
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setHideThis(false);
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name={t("Submit")}
                  filled={true}
                  primary={true}
                  onClick={onPress}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(WithdrawSmallpopup);


<>
<div className="exchangetitle">
          {" "}
          <span></span>
        </div>
        
</>