export default {
  IDOTransferHistory: 'İDO İşlem Geçmişi',
  TransactionID: 'İşlemin İD numarası',
  RoundNo: 'Tur Numarası',
  BoughtOn: 'Alınan',
  LockedFor: "'e kadar Kilitli",
  UnlockDate: 'kilit açma tarihi',
  TokensAllocated: 'Tahsis Edilen Tokenler',
  Status: 'Durum',
  Action: 'Eylem',
  days: 'günler',
  CYBOR: 'CYBOR',
};
