import * as _ from "lodash";
import React from "react";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/no-access-popup-security/NoaccesspopupSecurityStyle.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "@images/Default.svg";
import help1 from "@images/help1.svg";
import help2 from "@images/help2.svg";

import { useTranslation } from "react-i18next";

const NoaccesspopupSecurity = ({
  setShowModal,
  showsecurity,
  setshowmodalsecurity,
  onPress,
}) => {
  const { t, i18n, ready } = useTranslation();
  return (
    <Modal
      className="noacesssecurity"
      show={showsecurity}
      onHide={() => {
        setshowmodalsecurity(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(true)
            setshowmodalsecurity(false);
          }}
        />

        <Container>
          <div className="outercls">
            <div className="labelcls">
              <span>{t("securitytitle1")}</span>
            </div>
          </div>

          <div className="outercls">
            <div className="labelcls">
              <span>{t("securitytitle2")}</span>
            </div>
          </div>
          <div className="outercls">
            <div className="labelcls">
              <span>{t("securitytitle3")}:</span>
            </div>
          </div>

          <div className="outercls rowspace">
            <span className="text1"> {t("support24")} </span>{" "}
            <span className="text2">24/7!</span>
          </div>

          <div className="rowspace">
            <div className="outercls">
              <a href="https://t.me/Cybor_Holding" target="_blank">
                <img src={help2} className="helpimg" />
              </a>

              <a
                href="https://api.whatsapp.com/send/?phone=905338301530&text&app_absent"
                target="_blank"
              >
                <img src={help1} className="helpimg" />
              </a>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(NoaccesspopupSecurity);
