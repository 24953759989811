export default {
  PaymentReport: "Ödeme Raporu",
  PaymentID: "Ödeme İD numarası",
  InvalidPaymentID: "Geçersiz Ödeme İD numarası",
  Date: "Tarih",
  InvalidDate: "Geçersiz tarih",
  Merchant: "Pazarlamacı",
  InvalidMerchant: "Geçersiz Pazarlamacı",
  CoinToAccept: "Alınacak Coin",
  InvalidCoin: "Alınacak Coin Geçersiz",
  Amount: "Miktar",
  InvalidAmount: "Geçersiz miktar",
  CoinToConvert: "Döviz edilecek Coin",
  InvalidCoinToConvert: "Döviz edilecek Geçersiz Coin",
  Status: "Durum",
  InvalidSatus: "Geçersiz",
  CreateReport: "Rapor oluştur",
  TransactionID: "İşlem İD numarası",
  AmountTransaction: "Tutar İşlemi",
  Сurrency: "Para birimi",
  Convertedamount: "Döviz miktarı",
  Сurrencyinformation: "Para birimi bilgileri",
  Commission: "Komisyon",
  Recipient: "Alıcı",
  Dealdate: "Anlaşma tarihi",
};
