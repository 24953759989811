import ScreenLayout from "src/components/layout/ScreenLayout";
import FooterCommon from "src/components/footer-common/FooterCommon";
import Reporttable from "src/components/report-table/Reporttable";
import NavBar from "@components/navbar/NavBar";
import * as _ from "lodash";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import "jspdf-autotable";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as PrinterIcon } from "@images/printer.svg";
import { ReactComponent as PdfIcon } from "@images/pdf.svg";
import { ReactComponent as ExcelIcon } from "@images/excel.svg";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import LeftSidebar from "@components/left-sidebar/LeftSidebar";import { Row, Col, Form } from "react-bootstrap";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import CreateReportModal from "src/components/create-report-modal/CreateReportModal";
import { ReactComponent as Calender } from "@images/calender.svg";
import { useTranslation } from "react-i18next";
import "@features/reports/PaymentReport.scss";
import DropdownSearch from "@components/dropdown-search/DropdownSearch";
import moment from "moment";
import Loader from "@components/loader/Loader";
import { ClearSansBase64 } from "src/custom-fonts-base64/index";

function PaymentReports({
  plaformcurrencies,
  getplatformTokensinfo,
  paymentReport,
  paymentReports,
  userInfo,
  paymentReportCallLoading,
  hideLoaderOnRefresh,
}) {
  const [paymentId, setPaymentId] = useState("");
  const [date, setDate] = useState("");
  const [merchant, setMerchant] = useState(userInfo && userInfo.name);
  const [amount, setAmount] = useState("");
  const [coinToAccept, setCoinToAccept] = useState("");
  const [status, setStatus] = useState("");
  const [coinToConvert, setCoinToConvert] = useState("");
  const [showTables, setShowTables] = useState(false);
  const [pdfObject, setPdfObject] = useState();
  const [statusIndex, setStatusIndex] = useState();
  const [showExportOptions, setExportOptions] = useState(false);
  const [exportedTableData, setExportedTableData] = useState([]);
  const [currentLang, setCurrentlang] = useState("en");

  const [checkboxes, setCheckboxState] = useState({
    paymentId: false,
    amount: false,
    date: false,
    cointoconvert: false,
    merchant: false,
    status: false,
    cointoaccept: false,
  });
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [modal, setModal] = useState(false);
  const [fiatoptions, setfiatoptions] = useState([]);
  const [FiatSelectedIndex, setFiatSelectedIndex] = useState();
  const [CoinSelectedIndex, setCoinSelectedIndex] = useState();
  const [statusSelector, setStatusSelector] = useState([
    { tokenName: "Pending" },
    { tokenName: "Cancelled" },
    { tokenName: "Paid" },
  ]);
  const [tableQuery, setTableQuery] = useState({});
  const [selectedStatus, setSelectedStatus] = useState();
  const [fiatIndex, setFiatIndex] = useState("");
  const [coinIndex, setCoinIndex] = useState("");
  const [apiParams, setApiParams] = useState({});
  const [filterReportTable, setFilterReportTable] = useState({
    paymentId: true,
    date: true,
    amount: true,
    cointoaccept: true,
    cointoconvert: true,
    merchant: true,
    status: true,
    convertedamount: true,
  });

  const componentRef = useRef();

  useEffect(() => {
    generateExcelDataAndHeader();
    generatePdf();
  }, [filterReportTable]);

  var re = /[^a-zA-Z]+/;
  const onSendmsg = () => {};
  const createReport = () => {
    setModal(true);
  };

  const createReportCall = () => {
    if (showTables !== true) {
      setShowTables(true);
    }
    const data = {
      skip: 0,
      limit: 10,
      coinToConvert: CoinSelectedIndex,
      currencyId: FiatSelectedIndex,
      paymentStatus: selectedStatus,
      currencyAmount: amount,
      paymentId: paymentId,
    };
    setApiParams(data);
    if (date !== "") {
      data.startDate = moment(date).subtract(1, "days").local().toISOString();
      data.endDate = moment(date).add(1, "days").local().toISOString();
    }
    paymentReport(data);
  };

  const setCheckboxes = (e) => {
    const data = e.target.checked;
    const key = e.target.name;
    setCheckboxState({
      ...checkboxes,
      [key]: data,
    });
  };

  const childCallBack = (data) => {
    setExportedTableData(data);
    generateExcelDataAndHeader();
    generatePdf();
  };

  useEffect(() => {
    console.log(localStorage.lang);
    setCurrentlang(localStorage.lang);
    childCallBack();
  }, [localStorage.lang]);
  const generateExcelDataAndHeader = () => {
    let header = [];
    let newTable = paymentReports.map((itm) => ({
      ...itm,
      toPaymentLinkCoin: itm?.toPaymentLinkCoin?.tokenName,
      currency: itm?.currency?.currencyName,
      merchant: itm?.merchant.name,
      updatedOn: moment(itm?.updatedOn).local().format("MM/DD/YYYY hh:mm A"),
      paymentStatus:t(itm?.paymentStatus)
    }));

    setExcelData(newTable);
    let tableColumnMapper = {
      paymentId: "TransactionID",
      date: "Dealdate",

      // commission: 'Commission',

      convertedamount: "Convertedamount",
      cointoaccept: "Currency",

      amount: "Amount",

      cointoconvert: "Сurrency",

      merchant: "Merchant",

      status: "Status",
    };

    let tableBody = {
      paymentId: "id",
      date: "updatedOn",
      status: "paymentStatus",
      cointoaccept: "toPaymentLinkCoin",
      amount: "currencyAmount",
      cointoconvert: "currency",
      merchant: "merchant",
      convertedamount: "currencyAmountCommission",
    };

    for (let i of Object.keys(filterReportTable)) {
      if (filterReportTable[i]) {
        let data = {
          title: t(tableColumnMapper[i]),

          dataIndex: tableBody[i],
        };
        header.push(data);
      }
    }
    setExcelHeader(header);
  };
  const genExcel = () => {};

  const genPDF = () => {
    pdfObject.save(`${t("PaymentReport")}.pdf`);
  };

  const generatePdf = () => {
    var doc = new jsPDF();
    doc.addFileToVFS("ClearSans-Regular.ttf", ClearSansBase64);
    doc.addFont("ClearSans-Regular.ttf", "ClearSans", "Regular");
    doc.setFont("ClearSans", "Regular");
    var col = [];
    let tableBody = {
      paymentId: "id",
      date: "updatedOn",
      status: "paymentStatus",
      cointoaccept: "toPaymentLinkCoin",
      amount: "currencyAmount",
      cointoconvert: "currency",
      merchant: "merchant",
      convertedamount: "currencyAmountCommission",
    };
    let tableColumnMapper = {
      paymentId: "TransactionID",
      date: "Dealdate",

      // commission: 'Commission',

      convertedamount: "Convertedamount",
      cointoaccept: "Currency",

      amount: "Amount",

      cointoconvert: "Currency",

      merchant: "Merchant",

      status: "Status",
    };

    let newTable = paymentReports.map((itm) => ({
      ...itm,
      toPaymentLinkCoin: itm?.toPaymentLinkCoin?.tokenName,
      currency: itm?.currency?.currencyName,
      merchant: itm?.merchant?.name,
      updatedOn: moment(itm?.updatedOn).local().format("MM/DD/YYYY hh:mm A"),
    }));

    for (let i of Object.keys(filterReportTable)) {
      if (filterReportTable[i]) {
        col.push(t(tableColumnMapper[i]));
      }
    }

    var rows = [];
    if (newTable && newTable.length !== 0) {
      newTable.forEach((element) => {
        let temp = [];
        for (let i of Object.keys(filterReportTable)) {
          if (filterReportTable[i]) {
            temp.push(t(element[tableBody[i]]));
          }
        }

        rows.push(temp);
      });
    }
    doc.autoTable(col, rows, {
      startY: 5,
      styles: {
        font: "ClearSans",
        fontSize: 8,
      },
    });
    setPdfObject(doc);
    //doc.save('Payment Report.pdf')

    // let tableColumnMapper = {
    //   paymentId: "Transaction ID",
    //   date: "Deal Date",

    //   // commission: 'Commission',

    //   convertedamount: "Converted Amount",
    //   cointoaccept: "Currency",

    //   amount: "Amount",

    //   cointoconvert: "Currency Information",

    //   merchant: "Merchant",

    //   status: "Status",
    // };

    // let tableBody = {
    //   paymentId: 'id',
    //   date: 'updatedOn',
    //   status: 'paymentStatus',
    //   cointoaccept: 'toPaymentLinkCoin',
    //   amount: 'currencyAmount',
    //   cointoconvert:'currency',
    //   merchant: 'merchant',
    //   convertedamount: 'currencyAmountCommission'
    // }
  };

  const { t } = useTranslation();
  useEffect(() => {
    let allcurrenc = [];
    plaformcurrencies &&
      plaformcurrencies.map((items) => {
        allcurrenc.push({
          tokenName: items?.currencyName,
          ...items,
        });
        setfiatoptions(allcurrenc);
      });
  }, []);

  useEffect(() => {
    if (paymentReports.length !== 0) {
      setExportOptions(true);
    } else {
      setExportOptions(false);
    }
  }, [paymentReports]);

  return (
    <ScreenLayout loaderOnRefresh={hideLoaderOnRefresh}>
      <div id="paymentreport">
        <div className="outercontainer">
          {paymentReportCallLoading && <Loader />}
          <Container className="containertp">
            <Row>
              <Col lg={12}>
                <div className="trouter trasactiontitle">
                  <div className="reporthederwrapper">
                    <div className="title">{t("PaymentReport")}</div>
                  </div>
                </div>
                <div className="paymentreportwrapper">
                  <Form noValidate validated={validated} onSubmit={onSendmsg}>
                    <Row>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="formfieldholder">
                          <p className="label">{t("PaymentID")}</p>
                          <InputField
                            type="text"
                            placeholder={""}
                            onChange={(e) => setPaymentId(e.target.value)}
                            value={paymentId}
                            classname={"roundedborderfield"}
                            required
                            isInvalid={validated}
                            error={t("InvalidPaymentID")}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="formfieldholder">
                          <p className="label">{t("Date")}</p>
                          <InputField
                            type="date"
                            icon
                            iconsrc={<Calender className="calendericon" />}
                            placeholder={""}
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                            classname={"roundedborderfield"}
                            required
                            isInvalid={validated}
                            error={t("InvalidDate")}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="formfieldholder">
                          <p className="label">{t("Merchant")}</p>
                          <InputField
                            type="text"
                            placeholder={""}
                            onChange={(e) => setMerchant(e.target.value)}
                            value={merchant}
                            classname={"roundedborderfield"}
                            required
                            disabled={true}
                            isInvalid={validated}
                            error={t("InvalidMerchant")}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="formfieldholder">
                          <p className="label">{t("CoinToAccept")}</p>
                          <DropdownSearch
                            Selected={coinIndex}
                            options={getplatformTokensinfo}
                            setSelectedIndex={(e) => {
                              setCoinIndex(e);
                              setCoinSelectedIndex(getplatformTokensinfo[e].id);
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="formfieldholder">
                          <p className="label">{t("Amount")}</p>
                          <InputField
                            type="number"
                            placeholder={""}
                            onChange={(e) => setAmount(e.target.value)}
                            value={amount}
                            classname={"roundedborderfield"}
                            required
                            isInvalid={validated}
                            error={t("InvalidAmount")}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="formfieldholder">
                          <p className="label">{t("CurrencyToConvert")}</p>
                          <DropdownSearch
                            options={fiatoptions}
                            setSelectedIndex={(e) => {
                              setFiatIndex(e);
                              setFiatSelectedIndex(fiatoptions[e].id);
                            }}
                            Selected={fiatIndex}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="formfieldholder">
                          <p className="label">{t("Status")}</p>
                          <DropdownSearch
                            Selected={statusIndex}
                            options={statusSelector}
                            setSelectedIndex={(e) => {
                              setStatusIndex(e);
                              setSelectedStatus(statusSelector[e].tokenName);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col lg={8}>
                        <Row>
                          <Col>
                            <div className="checkboxholder">
                              <Form.Check
                                type={"checkbox"}
                                id={"1"}
                                label={t(`PaymentID`)}
                                name="paymentId"
                                onChange={(e) => {
                                  setFilterReportTable((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.checked,
                                  }));
                                  setCheckboxes(e);
                                }}
                                checked={filterReportTable.paymentId}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="checkboxholder">
                              <Form.Check
                                type={"checkbox"}
                                id={"2"}
                                label={t(`Amount`)}
                                name="amount"
                                onChange={(e) => {
                                  setFilterReportTable((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.checked,
                                  }));
                                  setCheckboxes(e);
                                }}
                                checked={filterReportTable.amount}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="checkboxholder">
                              <Form.Check
                                type={"checkbox"}
                                id={"3"}
                                label={t(`Date`)}
                                name="date"
                                onChange={(e) => {
                                  setFilterReportTable((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.checked,
                                  }));
                                  setCheckboxes(e);
                                }}
                                checked={filterReportTable.date}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="checkboxholder">
                              <Form.Check
                                type={"checkbox"}
                                id={"4"}
                                label={t(`CoinToConvert`)}
                                name="cointoconvert"
                                onChange={(e) => {
                                  setFilterReportTable((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.checked,
                                  }));
                                  setCheckboxes(e);
                                }}
                                checked={filterReportTable.cointoconvert}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="checkboxholder">
                              <Form.Check
                                type={"checkbox"}
                                id={"5"}
                                label={t(`Merchant`)}
                                name="merchant"
                                onChange={(e) => {
                                  setFilterReportTable((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.checked,
                                  }));
                                  setCheckboxes(e);
                                }}
                                checked={filterReportTable.merchant}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="checkboxholder">
                              <Form.Check
                                type={"checkbox"}
                                id={"6"}
                                label={t(`Status`)}
                                name="status"
                                onChange={(e) => {
                                  setFilterReportTable((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.checked,
                                  }));
                                  setCheckboxes(e);
                                }}
                                checked={filterReportTable.status}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="checkboxholder">
                              <Form.Check
                                type={"checkbox"}
                                id={"7"}
                                label={t(`CoinToAccept`)}
                                name="cointoaccept"
                                onChange={(e) => {
                                  setFilterReportTable((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.checked,
                                  }));
                                  setCheckboxes(e);
                                }}
                                checked={filterReportTable.cointoaccept}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-5">
                      <Col lg={4} className="d-none d-lg-block">
                        <CustomButton
                          filled
                          name={t("CreateReport")}
                          type="button"
                          primary
                          //onClick={createReport}
                          classname={"paymentreportcustombutton"}
                          onClick={() => createReportCall()}
                        />
                      </Col>
                      <Col lg={4} className="d-block d-lg-none">
                        <CustomButton
                          filled
                          name={t("CreateReport")}
                          type="button"
                          primary
                          //onClick={createReport}
                          classname={"paymentreportcustombutton"}
                          onClick={() => {
                            createReport();
                            createReportCall();
                          }}
                        />
                      </Col>
                    </Row>
                  </Form>
                  <CreateReportModal
                    modal={modal}
                    setModal={() => setModal(false)}
                    filterReportTable={filterReportTable}
                    componentRef={componentRef}
                    parentCallback={childCallBack}
                    apiParams={apiParams}
                    onClickPdf={() => genPDF()}
                    excelHeader={excelHeader}
                    download={`download`}
                    excelData={excelData}
                    XLSX={XLSX}
                    onClickExcel={() => generateExcelDataAndHeader()}
                  />
                </div>
                {showTables && (
                  <div className="margintop hidden">
                    <Reporttable
                      filterReportTable={filterReportTable}
                      componentRef={componentRef}
                      parentCallback={childCallBack}
                      apiParams={apiParams}
                    />
                  </div>
                )}

                {showExportOptions && showTables && (
                  <div className="margintop hidden ">
                    <div className="rightsection">
                      <div className="thethreebutton">
                        <div className="buttoncontainersm">
                          <ReactToPrint
                            trigger={() => (
                              <CustomButton
                                type="submit"
                                name={t("Print")}
                                testvalue={
                                  <PrinterIcon className="iconcolor mleft" />
                                }
                                text={true}
                                image={true}
                                //   onClick={() => {
                                //     setcoinItem(items);
                                //     setShowDeposit(true)
                                //   }}
                              />
                            )}
                            content={() => componentRef.current}
                          />
                        </div>

                        <div className="buttoncontainersm">
                          <CustomButton
                            type="submit"
                            name="PDF"
                            testvalue={<PdfIcon className="mleft" />}
                            text={true}
                            image={true}
                            onClick={() => {
                              genPDF();
                            }}
                          />
                        </div>

                        <div className="buttoncontainersm mr-0">
                          <ExportSheet
                            header={excelHeader}
                            fileName={`download`}
                            dataSource={excelData}
                            xlsx={XLSX}
                          >
                            <CustomButton
                              type="submit"
                              name={t("Excel")}
                              testvalue={<ExcelIcon className="mleft" />}
                              text={true}
                              image={true}
                            />
                          </ExportSheet>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterCommon />
    </ScreenLayout>
  );
}
const mapStateToProps = ({
  translationLanguage,
  app = {},
  wallet = {},
  paymentlink = {},
  user = {},
}) => {
  const thememode = _.get(app, "thememode", false);
  const currentLang = _.get(translationLanguage, "language", false);
  const plaformcurrencies = _.get(wallet, "plaformcurrencies", []);
  const getplatformTokensinfo = _.get(wallet, "getplatformTokensinfo", []);
  const paymentReports = _.get(paymentlink, "paymentReports", []);
  const userInfo = _.get(user, "MyProfileInfo", undefined);
  const paymentReportCallLoading = _.get(
    paymentlink,
    "paymentReportCallLoading",
    false
  );
  return {
    thememode,
    currentLang,
    plaformcurrencies,
    getplatformTokensinfo,
    paymentReports,
    userInfo,
    paymentReportCallLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  paymentReport: (data) => dispatch({ type: "paymentReport", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReports);
